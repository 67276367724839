/**
 * Attention: This component, exclusively designed for the dashboard's card view, was derived from legacy codebase.
 * Please refrain from utilizing it elsewhere.
 */
import React from 'react';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { css } from '@emotion/css';
import { Layout, Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';

import { DashboardData } from 'src/features/dashboards';

import { DashboardCardIllustration } from './dashboard-card-illustration.component';

import './style.scss';

/**
 * NOTE: The values below are copied from the original component. It seems to work well with the current
 * card dimensions and responsiveness.
 */
const WIDGET_HEIGHT_IN_CELLS = 6;
const WIDGET_WIDTH_IN_CELLS = 4;
const GRID_NUM_COLS = { lg: 12 };
// NOTE: Original comment - formula is ( h * rowHeight + (h - 1) * margin )
const GRID_ROW_HEIGHT = 7;
const GRID_WIDTH = 500;
const GRID_BREAKPOINTS = { lg: GRID_WIDTH };

const defaultLayout = (dashboardLayout: Layout[], key: string) => {
  // NOTE: Original comment - The Widget is put in an empty row at the bottom
  const maxY = dashboardLayout.reduce((max: number, layout: Layout) => (max > layout.y ? max : layout.y), 0);

  return {
    x: 0,
    y: maxY + 1,
    w: WIDGET_WIDTH_IN_CELLS,
    h: WIDGET_HEIGHT_IN_CELLS,
    i: key,
    minW: 3,
    maxW: 12,
    minH: 3,
    maxH: 10,
  };
};

type DashboardCardPreviewProps = {
  dashboard: DashboardData;
};

export const DashboardCardPreview = ({ dashboard }: DashboardCardPreviewProps) => (
  <Box backgroundColor="neutralDisabled" height="10rem" overflow="hidden" position="relative" width="100%">
    {dashboard.widgets.length === 0 ? (
      <Flex alignItems="center" height="100%" justifyContent="center">
        <Text>No items</Text>
      </Flex>
    ) : (
      <SizeMe>
        {({ size }) => (
          <Responsive
            layouts={{ lg: dashboard.layout || [] }}
            breakpoints={GRID_BREAKPOINTS}
            cols={GRID_NUM_COLS}
            rowHeight={GRID_ROW_HEIGHT}
            isDraggable={false}
            isResizable={false}
            margin={[8, 8]}
            containerPadding={[16, 16]}
            width={size.width || 1}
          >
            {dashboard.widgets.map(({ chart, query, widgetId }) => (
              <Box
                backgroundColor="neutral"
                borderRadius="small"
                boxShadow="xsmall"
                key={widgetId}
                data-widget={widgetId}
                data-grid={defaultLayout(dashboard.layout || [], widgetId)}
                padding="xsmall"
              >
                <Box backgroundColor="neutralDisabled" borderRadius="small" height="0.5rem" width="60%" />
                <Flex
                  alignItems="center"
                  className={css({
                    svg: {
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    },
                  })}
                  height="100%"
                  justifyContent="center"
                  paddingY="xsmall"
                >
                  <DashboardCardIllustration displayType={chart?.displayType} hasQuery={!!query?.id} />
                </Flex>
              </Box>
            ))}
          </Responsive>
        )}
      </SizeMe>
    )}
  </Box>
);
