import React from 'react';

export const ColumnChartIllustration = () => (
  <svg
    width={108}
    height={56}
    viewBox="0 0 108 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="55.5" x2="108" y2="55.5" stroke="black" strokeOpacity="0.14" />
    <path d="M50 12C50 10.8954 50.8954 10 52 10H56C57.1046 10 58 10.8954 58 12V55H50V12Z" fill="#3883DA" />
    <path d="M34 2C34 0.895432 34.8954 0 36 0H40C41.1046 0 42 0.89543 42 2V55H34V2Z" fill="#3883DA" />
    <path d="M18 33C18 31.8954 18.8954 31 20 31H24C25.1046 31 26 31.8954 26 33V55H18V33Z" fill="#3883DA" />
    <path d="M66 28C66 26.8954 66.8954 26 68 26H72C73.1046 26 74 26.8954 74 28V55H66V28Z" fill="#3883DA" />
    <path d="M82 19C82 17.8954 82.8954 17 84 17H88C89.1046 17 90 17.8954 90 19V55H82V19Z" fill="#3883DA" />
  </svg>
);
