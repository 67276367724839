import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'elmo-elements';
import { noop } from 'lodash';

import { DropdownItemData } from '../../shared-components/Dropdown';
import SplitButtonDropdown from '../../shared-components/SplitButtonDropdown';
import { SavedQuery } from '../../types/savedQueryModel';
import { reportSharedWithMe } from '../../services/shareReportService';
import { REPORT_TYPE_SUGGESTION } from '../../constants';
import { UserCapabilitiesType } from '../../services/reportService';

export interface SaveQueryProps {
  onSaveClicked: (saveQueryFormData: SaveQueryFormData, saveAs: boolean) => void;
  saving: boolean;
  showSaveError: boolean;
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
  disabled?: boolean;
  canSaveSuggestions: boolean;
  loadedQuery?: SavedQuery;
  userCapabilities?: UserCapabilitiesType;
}

export interface SaveQueryFormData {
  name: string;
  description: string;
  userReport: boolean;
  suggestedReport: boolean;
  tempReport?: boolean;
  dashboardReport?: boolean;
}

interface SaveQueryState {
  formData: SaveQueryFormData;
  formError: {
    name: string;
    reportType: string;
  };
  firstSave: boolean;
  saveAs: boolean;
}

function getInitialSaveQueryState(): SaveQueryState {
  return {
    formData: {
      name: '',
      description: '',
      userReport: true,
      suggestedReport: false
    },
    formError: {
      name: '',
      reportType: ''
    },
    firstSave: true,
    saveAs: true
  };
}

class SaveQuery extends React.Component<SaveQueryProps, SaveQueryState> {

  constructor(props: SaveQueryProps) {
    super(props);
    this.state = getInitialSaveQueryState();
  }

  componentDidUpdate(prevProps: SaveQueryProps) {
    if (this.props.showModal && (prevProps.showModal !== this.props.showModal)) {
      let prepopulatedState = getInitialSaveQueryState();
      prepopulatedState.firstSave = this.state.firstSave;
      prepopulatedState.saveAs = this.state.saveAs;

      // if the save as controls are hidden, then save as a user report
      prepopulatedState.formData.userReport = !this.showSaveAsControls();
      prepopulatedState.formData.suggestedReport = false;

      if (this.props.loadedQuery) {
        prepopulatedState.formData.name = this.props.loadedQuery.name;
        prepopulatedState.formData.description = this.props.loadedQuery.description;
      }

      this.setState(prepopulatedState);
    }

    if (this.props.loadedQuery !== prevProps.loadedQuery) {
      let firstSave = !this.props.loadedQuery ||
        (this.props.loadedQuery && !this.props.canSaveSuggestions &&
          this.props.loadedQuery.reportType === REPORT_TYPE_SUGGESTION);

      this.setState({
        firstSave: firstSave
      });
    }
  }

  handleFormChange = (property: string) => (event: React.FormEvent<HTMLSelectElement|HTMLInputElement>) => {
    let formData: SaveQueryFormData = this.state.formData;
    formData[property] = event.currentTarget.type === 'checkbox' ?
      event.currentTarget['checked'] : event.currentTarget.value;
    this.setState({formData: formData});
  }

  protected onSaveClicked = () => {
    if (!this.formIsValid()) {
      return;
    }

    this.props.onSaveClicked(this.state.formData, this.state.saveAs);
  }

  protected formIsValid() {
    let isValid: boolean = true;

    let errors = {
      name: '',
      reportType: ''
    };

    if (this.state.formData.name.trim().length === 0) {
      errors.name = 'Please provide a valid name.';
      isValid = false;
    }

    if (this.showSaveAsControls() && !this.state.formData.suggestedReport && !this.state.formData.userReport) {
      errors.reportType = 'Please select a report type.';
      isValid = false;
    }

    this.setState({
      formError: errors,
    });

    return isValid;
  }

  toggle = () => {
    this.props.setShowModal(!this.props.showModal);
  }

  render() {
    const { loadedQuery, userCapabilities } = this.props;
    let showSaveAsControls = this.showSaveAsControls();
    const showSharedWithUsersAlert = this.showSharedWithUsersAlert();
    const isReportSharedWithMe = !!loadedQuery && reportSharedWithMe(loadedQuery);
    const showSaveButton = loadedQuery && loadedQuery.permissions && loadedQuery.permissions.canClone
      && loadedQuery.permissions.canEdit;
    return (
      <div className="save-query d-inline-block" role="menu">
        {
          (this.state.firstSave || isReportSharedWithMe) &&
          (userCapabilities && userCapabilities.report.canCreateReport) && 
          this.renderFirstSaveButton()
        }
        {(!this.state.firstSave && !isReportSharedWithMe && showSaveButton) && this.renderSaveButton()}
        <Modal isOpen={this.props.showModal} toggle={noop}>
          <ModalHeader toggle={this.toggle}>
            {this.state.saveAs && <span>Save Report As...</span>}
            {!this.state.saveAs && <span>Save Report</span>}
          </ModalHeader>
          <ModalBody>
            {this.props.showSaveError &&
              <div className="alert alert-danger" role="alert">
                Query could not be saved.
              </div>
            }
            <form
              id="save-query-form"
              data-testid="save-report-form"
            >
              <div className={'form-group ' + (this.state.formError.name ? ' invalid ' : '')}>
                <label
                  htmlFor="name"
                  className="required font-weight-bold"
                >
                  Title:
                </label>
                <input
                  type="text"
                  className={'form-control'}
                  id="name"
                  placeholder="The title of your report"
                  value={this.state.formData.name}
                  onChange={this.handleFormChange('name')}
                  required={true}
                  data-testid="save-report-form-name"
                />
                <div className="error-message">
                  {this.state.formError.name}
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="description"
                  className="font-weight-bold"
                >
                  Description:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="What does this report show?"
                  value={this.state.formData.description}
                  onChange={this.handleFormChange('description')}
                  data-testid="save-report-form-description"
                />
              </div>
              {showSaveAsControls && this.renderSaveAsControls()}
              {showSharedWithUsersAlert &&
                <Alert
                  message="If this report has been scheduled or shared with other users, any saved changes will be applied accordingly."
                  type="info"
                  isCloseable={false}
                />
              }
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="outline-dark"
              onClick={this.cancel}
              disabled={this.props.saving}
              className="save-report-form-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={this.onSaveClicked}
              disabled={this.props.saving}
              className="save-report-form-save-btn"
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  showSaveAsControls = () => this.state.saveAs && this.props.canSaveSuggestions;

  showSharedWithUsersAlert = () => !this.state.saveAs && this.props.loadedQuery;

  renderSaveAsControls() {
    return (
      <div className={'form-group ' + (this.state.formError.reportType ? ' invalid ' : '')}>
        <label
          className="required font-weight-bold"
        >
          Save to:
        </label>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="userReport"
            name="userReport"
            onChange={this.handleFormChange('userReport')}
            checked={this.state.formData.userReport}
            data-testid="save-report-form-user-report-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="userReport"
          >
            My Reports
          </label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="suggestedReport"
            name="suggestedReport"
            onChange={this.handleFormChange('suggestedReport')}
            checked={this.state.formData.suggestedReport}
            data-testid="save-report-form-suggested-report-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="suggestedReport"
          >
            Suggested Reports
          </label>
        </div>
        {this.state.formError.reportType &&
        <div className="error-message">
          {this.state.formError.reportType}
        </div>
        }
      </div>
    );
  }

  cancel = () => {
    this.props.setShowModal(false);
  }

  renderSaveButton = () => {
    let buttonItems: DropdownItemData[] = [{
      label: 'Save',
      color: 'outline-dark',
      className: 'save-btn',
      onClick: () => {
        this.props.setShowModal(true);
        this.setState({
          saveAs: false
        });
      }
    }];
    let dropdownItems: DropdownItemData[] = [
      {
        label: 'Save Report As',
        color: 'outline-dark',
        className: 'save-as-btn',
        onClick: () => {
          this.props.setShowModal(true);
          this.setState({
            saveAs: true
          });
        }
      }
    ];
    return (
        <SplitButtonDropdown
          buttonItems={buttonItems}
          dropdownItems={dropdownItems}
          disabled={this.props.disabled}
        />
    );
  }

  renderFirstSaveButton = () => {
    return (
      <button
        className="btn btn-outline-dark"
        data-testid="save-as-btn"
        onClick={() => {
          this.props.setShowModal(true);
          this.setState({
            saveAs: true
          });
        }}
        disabled={this.props.disabled}
      >
        Save Report As
      </button>
    );
  }
}

export default SaveQuery;
