import { InferType, boolean, object, string } from 'yup';

export type DashboardCreateEditFieldValues = InferType<typeof dashboardCreateEditSchema>;

export const dashboardCreateEditSchema = object({
  description: string().defined(),
  id: string().defined(),
  isSuggested: boolean().defined(),
  name: string().required('Please enter a name'),
}).required();
