import React, { FC, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { AppState } from 'src/redux';
import { ConfigurationType } from 'src/types/transformationModel';
import { TranslationConfiguration } from 'src/types/transformationModel';

type DeprecatedConfigurations = {
  dimension: string;
};

type DeprecatedCustomColumns = {
  name: string;
  join?: string;
};

type DeprecatedDimensions = {
  name: string;
  join?: string;
  view: string;
};

type DeprecationContextType = {
  customColumnDeprecated: (value: string, joinName?: string) => boolean;
  deprecatedConfigurations: DeprecatedConfigurations[];
  deprecatedCustomColumns: DeprecatedCustomColumns[];
  deprecatedDimensions: DeprecatedDimensions[];
  hasDeprecatedColumns: boolean;
  hasDeprecatedFilters: boolean;
};

/**
 * NOTE: This context is used for deprecation of fields. The goal is to encourage the user to manually remove selected
 * dimensions, filters, custom columns, and/or configurations associated with deprecated fields.
 */
export const DeprecationContext = createContext<DeprecationContextType | null>(null);

export const DeprecationContextProvider: FC = ({ children }) => {
  const [deprecatedConfigurations, setDeprecatedConfigurations] = useState<DeprecatedConfigurations[]>([]);
  const [deprecatedCustomColumns, setDeprecatedCustomColumns] = useState<DeprecatedCustomColumns[]>([]);
  const [deprecatedDimensions, setDeprecatedDimensions] = useState<DeprecatedDimensions[]>([]);
  const [hasDeprecatedColumns, setHasDeprecatedColumns] = useState(false);
  const [hasDeprecatedFilters, setHasDeprecatedFilters] = useState(false);
  const { columnMeta, customColumns, filters, filterGroups, transformations, view, views } = useSelector(
    (state: AppState) => state
  );

  const customColumnDeprecated = useCallback<(value: string, joinName?: string) => boolean>(
    (value, joinName = '') => {
      let foundViewName = !joinName ? view.name : '';
      const extractedJoinName = joinName.split('.').pop();

      !foundViewName &&
        views.some(({ joins, name }) => {
          const isCurrentView = joins.some(join => {
            if (extractedJoinName !== join.name) return false;

            foundViewName = name;
            return true;
          });

          return isCurrentView;
        });

      const currentView = views.find(({ name }) => foundViewName === name);

      if (!currentView) return false;

      const foundDimension = currentView.dimensions.find(({ name }) => value.split('.').pop() === name);

      return !!foundDimension?.deprecated;
    },
    [view, views]
  );

  useEffect(() => {
    const dimensions = columnMeta
      .filter(({ dimension }) => dimension?.deprecated)
      .map(({ dimension, join, view }) => ({ name: dimension?.name || '', join: join?.fullJoinName, view: view.name }));

    const custom = customColumns
      .filter(({ value, joinName, type }) => type === 3 && customColumnDeprecated(value || '', joinName))
      .map(({ value, joinName }) => ({ name: (value?.split('.') || []).pop() || '', join: joinName }));

    setDeprecatedDimensions(dimensions);
    setDeprecatedCustomColumns(custom);
    setHasDeprecatedColumns(dimensions.length + custom.length > 0);
  }, [columnMeta, customColumns]);

  useEffect(() => {
    const deprecatedFilters = filters.length
      ? filters.filter(({ dimensionName, joinName, viewName }) =>
          deprecatedDimensions.some(({ name, join, view }) =>
            name === dimensionName && joinName ? join === joinName : !join === !joinName && view === viewName
          )
        )
      : filterGroups.map(filterGroup =>
          filterGroup.filter(({ dimensionName, joinName, viewName }) =>
            deprecatedDimensions.some(({ name, join, view }) =>
              name === dimensionName && joinName ? join === joinName : !join === !joinName && view === viewName
            )
          )
        );

    setHasDeprecatedFilters(_.flatten(deprecatedFilters).length > 0);
  }, [deprecatedDimensions, filters, filterGroups]);

  useEffect(() => {
    const configurations = _.flatten(
      transformations.map(({ configuration, type }) =>
        configuration
          .map(({ dimension, ...configs }) => {
            let deprecated = deprecatedDimensions.some(
              ({ name, join }) => `${join ? `${join}.` : ''}${name}` === dimension
            );

            if (type === ConfigurationType.Translation) {
              deprecated =
                deprecated ||
                (configs as TranslationConfiguration).rules.some(rule =>
                  deprecatedDimensions.some((name, join) => `${join ? `${join}.` : ''}${name}` === rule.dimension)
                );
            }

            // @ts-expect-error NOTE: Inaccurate typing causes type to not recognize ConfigurationType.Concatenation
            if (type === ConfigurationType.Concatenation) {
              deprecated =
                deprecated ||
                (configs as unknown as { items: string[] }).items.some(item =>
                  deprecatedDimensions.some(
                    (name, join) => `${join ? `${join}.` : ''}${name}` === item.split('dimension:').pop()
                  )
                );
            }

            return { dimension, deprecated };
          })
          .filter(({ deprecated }) => deprecated)
      )
    );

    setDeprecatedConfigurations(configurations);
  }, [deprecatedDimensions, transformations]);

  return (
    <DeprecationContext.Provider
      value={{
        customColumnDeprecated,
        deprecatedConfigurations,
        deprecatedCustomColumns,
        deprecatedDimensions,
        hasDeprecatedColumns,
        hasDeprecatedFilters,
      }}
    >
      {children}
    </DeprecationContext.Provider>
  );
};

export const useDeprecationContext = () => useContext(DeprecationContext);
