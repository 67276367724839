import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ScheduledReport, fetchScheduledReportsList, scheduledReportsKeys } from 'src/features/scheduled-reports';

const deleteScheduledReport = async (scheduledReportId: string) => {
  const response = await axios.delete(`/api/schedule/${scheduledReportId}`);
  return response.data;
};

export const useDeleteScheduledReportMutation = (callback: { onSettled: () => void; onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>({
    mutationFn: scheduledReportId => {
      // NOTE: If there's any existing request to fetch the list, it must be cancelled to avoid race condition
      queryClient.cancelQueries({ queryKey: scheduledReportsKeys.lists() });
      queryClient.setQueriesData<Array<ScheduledReport>>(scheduledReportsKeys.lists(), previous =>
        (previous || []).filter(({ id }) => id !== scheduledReportId)
      );

      return deleteScheduledReport(scheduledReportId);
    },
    onSettled: () => callback.onSettled(),
    onSuccess: () => callback.onSuccess(),
  });
};

export const useScheduledReportNameQuery = (scheduledReportId: string) =>
  useQuery({
    enabled: false, // NOTE: No need to fetch, just retrieve the cached data
    queryKey: scheduledReportsKeys.lists(),
    queryFn: ({ signal }) => fetchScheduledReportsList(signal),
    select: data => data.find(scheduledReport => scheduledReportId === scheduledReport.id)?.scheduleName,
  });
