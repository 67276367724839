import * as React from 'react';
import { Button, CheckIcon, AutorenewIcon } from 'elmo-elements';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { AppState } from '../../../redux';
import { Dashboard } from '../../../types/dashboardModel';
import { DashboardService } from '../../../services/dashboardService';
import { NotificationService } from '../../../services/notificationService';
import { refreshDashboardCacheFailedCallback } from '../../../components/Dashboard/helpers';
import DashboardsDropdown from './DashboardsDropdown';
import { ExportPdfIcon } from '../../../components/Icons/ExportPdfIcon';

type GridButtonsProps = {
  dashboard: Dashboard;
  dashboards: Dashboard[];
  isEditable: boolean;
  saved: boolean;
  saveClicked: () => void;
  initExportToPdf?: () => void;
  isDashboardNotEmpty: boolean;
  isDashboardLoaded: boolean;
  isWidgetsLoaded: boolean;
  isExportToPdfInProgress: boolean;
};

const GridButtons = (props: GridButtonsProps) => {
  const notificationService: NotificationService | null = useSelector((state: AppState) => state.notificationService);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const canRefresh = props.isDashboardLoaded ? props.dashboard.cache.canRefresh : undefined;
  const refreshTimeout = props.isDashboardLoaded ? props.dashboard.cache.refreshTimeout : undefined;
  const updatedAt = props.isDashboardLoaded ? props.dashboard.cache.updatedAt : undefined;
  const timeAgoMsg = !!updatedAt ? ` Last refreshed ${moment.unix(updatedAt).fromNow()}` : '';

  const onExportToPdfClick = () => {
    if (!!props.initExportToPdf) {
      props.initExportToPdf();
    }
  };

  const onRefresh = async () => {
    try {
      setRefreshing(true);
      await DashboardService.apiDeleteDashboardCache(props.dashboard.dashboardId);
      window.location.reload();
    } catch (e) {
      setRefreshing(false);
      refreshDashboardCacheFailedCallback(notificationService);
    }
  };

  const getFormattedDuration = () => {
    const duration = moment.duration(refreshTimeout, 'minutes');
    const hours = duration.hours();
    const minutes = duration.minutes();

    const formattedHours = hours > 1 ? `${hours} hours` : `${hours} hour`;
    const formattedMinutes = minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;

    if (hours > 0 && minutes > 0) {
      return `${formattedHours} ${formattedMinutes}`;
    } else if (hours > 0) {
      return formattedHours;
    } else if (minutes > 0) {
      return formattedMinutes;
    } else {
      return `${refreshTimeout} minutes`;
    }
  };

  const renderExportToPdfButton = () => {
    return props.isExportToPdfInProgress ?
      <div className="exportPdfStatus">Export in progress...</div> : (
      <Button
        className={cn('exportPdfButton', {
          'exportPdfButton--active': props.isWidgetsLoaded,
        })}
        icon={<ExportPdfIcon />}
        ariaLabel="Export as PDF"
        onClick={onExportToPdfClick}
        tooltip={{
          title: 'Export PDF',
        }}
        isDisabled={!props.isWidgetsLoaded}
      />
    );
  };

  return (
    <div className="dashboard-buttons">
      {props.isDashboardLoaded && <div className="dashboard-left-buttons">
        <DashboardsDropdown dashboards={props.dashboards} dashboard={props.dashboard}/>
      </div>}
      {props.isDashboardNotEmpty && 
        <div className="dashboard-right-buttons">
          <Button
            className={cn('saveLayoutButton', {
              'saveLayoutButton--notSaved': !!canRefresh,
            })}
            isUppercase={false}
            icon={<AutorenewIcon />}
            onClick={onRefresh}
            ariaLabel="Refresh"
            isDisabled={!canRefresh}
            isLoading={refreshing}
            tooltip={{
              title: `This dashboard can be refreshed once per ${getFormattedDuration()}.${timeAgoMsg}`
            }}
          >
            Refresh
          </Button>
          {props.isEditable &&
            <>
              <div className="divider" />
              <Button
                className={cn('saveLayoutButton', {
                  'saveLayoutButton--notSaved': !props.saved,
                })}
                isUppercase={false}
                icon={<CheckIcon />}
                onClick={props.saveClicked}
                ariaLabel="Save"
                isDisabled={props.saved}
              >
                {!props.saved ? 'Save Layout' : 'Layout Saved'}
              </Button>
              <div className="divider" />
            </>
          }
          {renderExportToPdfButton()}
        </div>
      }
    </div>
  );
};

export default GridButtons;
