import { Route } from '../services/routingService';
import { ModulesListingPage } from '../components/ModulesListingPage';
import EditConfigViewsPageContainer from '../containers/Config/EditConfigViewsPage';
import EditConfigRelationsPage from '../containers/Config/EditConfigRelationsPage';
import CheckConfigPage from '../containers/Config/CheckConfigPage';

export const configRoutes: {[key: string]: Route} = {
  modulesListing: {
    path: '/configs',
    component: ModulesListingPage,
    private: true,
    exact: true
  },
  editViews: {
    path: '/config/:configId/edit-views',
    component: EditConfigViewsPageContainer,
    private: true,
    exact: true
  },
  editRelations: {
    path: '/config/:configId/edit-relations',
    component: EditConfigRelationsPage,
    private: true,
    exact: true
  },
  check: {
    path: '/check-config/:configId?',
    component: CheckConfigPage,
    private: true
  }
};