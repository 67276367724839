import { LOAD_SUGGESTIONS, STORE_SUGGESTIONS, TOGGLE_SUGGESTIONS } from '../constants';
import { SavedQuery } from '../types/savedQueryModel';

export interface SuggestionsAction {
  type: STORE_SUGGESTIONS | LOAD_SUGGESTIONS;
  suggestions: SavedQuery[];
  tags: string[]; // the tags sent to the backend
  tagLabels: string[]; // the tags displayed to the user
}

export interface SuggestionsModalAction {
  type: TOGGLE_SUGGESTIONS;
  isOpen?: boolean;
}

export function storeSuggestions(suggestions: SavedQuery[], tags: string[]): SuggestionsAction {
  return {
    type: STORE_SUGGESTIONS,
    suggestions: suggestions,
    tags: tags,
    tagLabels: []
  };
}

export function loadSuggestions(tags: string[], tagLabels: string[]): SuggestionsAction {
  return {
    type: LOAD_SUGGESTIONS,
    suggestions: [],
    tags: tags,
    tagLabels: tagLabels
  };
}

export function toggleSuggestions(): SuggestionsModalAction {
  return {
    type: TOGGLE_SUGGESTIONS
  };
}

export function setSuggestionsOpen(isOpen: boolean): SuggestionsModalAction {
  return {
    type: TOGGLE_SUGGESTIONS,
    isOpen: isOpen
  };
}