import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Modal, Heading, FormItem, Button } from 'elmo-elements';

import { AppState } from '../../redux';
// Types
import { SavedQuery } from '../../types/savedQueryModel';
import { UserSelectOption } from '../../types/scheduler/userSelectOption';
import { SchedulerUser } from '../../types/scheduler/schedulerUser';
import { isScheduleOptionsValid } from '../../types/scheduler/scheduleOptions';
import { ScheduledQuery } from '../../types/scheduler/scheduledQueryModel';
// Services
import { NotificationService } from '../../services/notificationService';
import { apiCreateSchedule, apiEditSchedule, apiGetSchedulerUsers } from '../../services/schedulerService';
import { AuthService } from '../../services/authService';
// Constants
import { NOTIFICATION_SUCCESS } from '../../constants';
import {
  OCCURRENCE_ONCE,
  OccurrenceOption,
  SCHEDULE_DISPATCH_TYPE_OUTBOUND,
  SCHEDULE_NOTIFICATION_TYPE_EMAIL,
  SCHEDULE_VALIDATION_TYPE_USERS,
  SCHEDULE_VALIDATION_TYPE_OUTBOUND_DESTINATION,
  SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS,
  SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS_EMAIL,
  SCHEDULE_VALIDATION_TYPE_START_DATE,
  SCHEDULE_VALIDATION_TYPE_END_DATE,
  SCHEDULE_VALIDATION_TYPE_NAME,
  SCHEDULE_VALIDATION_TYPE_DAYS_OF_THE_WEEK,
  SCHEDULE_VALIDATION_TYPE_CALENDAR_DAYS,
  SCHEDULE_VALIDATION_TYPE_TIME
} from '../../constants/addSchedule';
import { Moment } from '../../constants/moment';
import { ReportingRoutes } from '../../constants/routes';
// Components
import LoadingIcon from '../../shared-components/LoadingIcon';
import ModalTitle from '../../components/AddScheduleModal/ModalTitle';
import OutboundDestinationForm from '../../components/AddScheduleModal/OutboundDestinationForm';
import UserSelectForm from '../../components/AddScheduleModal/UserSelectForm';
import OutboundToggle from '../../components/AddScheduleModal/OutboundToggle';
import AddScheduleForm from '../../components/AddScheduleModal/AddScheduleForm';
import UserSelectLabel from '../../components/AddScheduleModal/UserSelect/UserSelectLabel';

import {
  getScheduleDataFromState,
  getStateFromScheduledQuery, getUserStateFromScheduledQuery,
  getRecipientUserStateFromScheduledQuery, getRecipientEmailStateFromScheduledQuery
} from './helper';

import './style.css';

type Props = {
  loadedQuery: SavedQuery;
  isOpen: boolean;
  toggle: () => void;
  scheduledQuery?: ScheduledQuery;
  onSaveSuccess?: (scheduledQuery: ScheduledQuery) => void;
  /* From redux */
  notificationService: NotificationService;
  scheduleLocation: string;
};

export type State = {
  scheduleName: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  time: moment.Moment | undefined;
  timezone: string;
  occurrence: OccurrenceOption;
  daysOfWeek: boolean[];
  dayOfMonth: string;
  users: UserSelectOption[];
  userList: UserSelectOption[];
  isUserListLoading: boolean;
  isUserListTyping: boolean;
  isSaving: boolean;
  errors: Object;
  outboundDestinationId: string | null;
  isOutbound: boolean;
  notificationRecipients: UserSelectOption[];
  recipientEmail: string;
  isOutboundEmailNotification: boolean;
};

const initialState: State = {
  scheduleName: '',
  startDate: moment(),
  endDate: moment().add(1, 'year'),
  time: moment().minute(0),
  timezone: '',
  occurrence: OCCURRENCE_ONCE as OccurrenceOption,
  daysOfWeek: [],
  dayOfMonth: '',
  users: [],
  isSaving: false,
  errors: {},
  outboundDestinationId: null,
  isOutbound: false,
  userList: [],
  isUserListLoading: false,
  isUserListTyping: false,
  notificationRecipients: [],
  recipientEmail: '',
  isOutboundEmailNotification: false
};

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService
});

class AddScheduleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = Object.assign({}, initialState);
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen } = this.props;
    const modalOpened = isOpen && (prevProps.isOpen !== isOpen);

    if (modalOpened) {
      this.onModalOpened();
    }
  }

  componentDidMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      this.onModalOpened();
    }
  }

  onModalOpened = async () => {
    const { scheduledQuery } = this.props;

    if (scheduledQuery) {
      const newState = getStateFromScheduledQuery(scheduledQuery);

      this.setState(newState);
    }

    if (scheduledQuery) {
      let userList = [];

      try {
        this.setState({
          isUserListLoading: true
        });

        userList = await this.getSchedulerUsers({ ids: scheduledQuery.targetUserIds });
      } finally {
        this.setState({
          isUserListLoading: false
        });
      }

      const users = getUserStateFromScheduledQuery(scheduledQuery, userList);
      const notificationRecipients = getRecipientUserStateFromScheduledQuery(scheduledQuery, userList);
      const recipientEmail = getRecipientEmailStateFromScheduledQuery(scheduledQuery);
      const isOutboundEmailNotification = (recipientEmail !== '' && recipientEmail != null);

      this.setState({
        userList: [],
        users: users,
        notificationRecipients: notificationRecipients,
        recipientEmail: recipientEmail,
        isOutboundEmailNotification: isOutboundEmailNotification
      });
    }
  }

  getSchedulerUsers = async ({ text, ids }: { text?: string, ids?: Array<string>}) => {
    const users = await apiGetSchedulerUsers({ text, ids });

    const userSelectOptions: UserSelectOption[] = users.map((u: SchedulerUser) => {
      const name: string = u.firstName + ' ' + u.lastName;
      const role: string = u.role;
      const label = (
        <UserSelectLabel
          name={name}
          email={u.email}
        />
      );

      const data = Object.assign({}, u, {
        name: name,
        role: role
      });

      return {
        value: u.id,
        label: label,
        data: data
      };
    });

    return userSelectOptions;
  }

  onDateChange = (isStartDate: boolean) => (d: Moment | null) => {
    const { errors } = this.state;

    let newState: State = Object.assign({}, this.state);
    const newErrors = Object.assign({}, errors);

    if (isStartDate) {
      newState.startDate = d;
      delete(newErrors[SCHEDULE_VALIDATION_TYPE_START_DATE]);
    } else {
      newState.endDate = d;
      delete(newErrors[SCHEDULE_VALIDATION_TYPE_END_DATE]);
    }

    newState['errors'] = newErrors;

    this.setState(newState);
  }

  onTimeChange = (d: Moment | null) => {
    const { errors } = this.state;

    let newState: State = Object.assign({}, this.state);
    const newErrors = Object.assign({}, errors);

    newState.time = d as any;
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_TIME]);

    newState['errors'] = newErrors;

    this.setState(newState);
  }

  onOccurrenceSelect = (option: OccurrenceOption) => () => {
    this.setState({
      occurrence: option,
      errors: {}
    });
  }

  onScheduleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { errors } = this.state;
    const scheduleName = event.target.value;
    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_NAME]);

    this.setState({
      scheduleName,
      errors: newErrors
    });
  }

  onRecipientEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { errors } = this.state;
    const recipientEmail = event.target.value;
    const newErrors = Object.assign({}, errors);

    delete(newErrors[SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS_EMAIL]);

    this.setState({
                    recipientEmail,
                    errors: newErrors
                  });
  }

  onDaysOfWeekToggle = (option: number) => () => {
    const { daysOfWeek, errors } = this.state;
    const selected = Object.assign({}, daysOfWeek);
    const newErrors = Object.assign({}, errors);

    selected[option] = !selected[option];
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_DAYS_OF_THE_WEEK]);
    this.setState({
      daysOfWeek: selected,
      errors: newErrors
    });
  }

  onDayOfMonthSelect = (option: string) => () => {
    const { errors } = this.state;

    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_CALENDAR_DAYS]);

    this.setState({
      dayOfMonth: option,
      errors: newErrors
    });
  }

  onUserSelect = (user: UserSelectOption) => {
    const { users, errors } = this.state;

    // If the user exists, don't add it
    if (this.getSelectedUserIndex(user) !== -1) {
      return;
    }

    const newUsers = users.slice();
    newUsers.push(user);

    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_USERS]);

    this.setState({
      users: newUsers,
      errors: newErrors
    });
  }

  getSchedulerUsersList = async (text?: string) => {
    this.setState({
      isUserListTyping: true
    });

    const userList = await this.getSchedulerUsers({ text });

    this.setState({
      userList: userList,
      isUserListTyping: false
    });
  }

  onUserSelectChange = (text: string) => {
    if (!!text) {
      this.getSchedulerUsersList(text);
    }
  }

  onUserDeselect = (user: UserSelectOption) => () => {
    const { users } = this.state;
    const selectedUserIndex = this.getSelectedUserIndex(user);
    const newUsers = users.slice();
    newUsers.splice(selectedUserIndex, 1);
    this.setState({
      users: newUsers
    });
  }

  onResetUserList = () => {
    this.setState({
      userList: []
    });
  }

  onNotificationRecipientsSelect = (notificationRecipient: UserSelectOption) => {
    const { errors } = this.state;

    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS]);

    this.setState({
      notificationRecipients: [notificationRecipient],
      errors: newErrors
    });
  }

  getSelectedUserIndex = (user: UserSelectOption) => {
    const { users } = this.state;
    return users.findIndex((u: UserSelectOption) => {
      return !!(u.data && user.data && u.data.id === user.data.id);
    });
  }

  getSelectedNotificationRecipientIndex = (notificationRecipient: UserSelectOption) => {
    const { notificationRecipients } = this.state;
    return notificationRecipients.findIndex((u: UserSelectOption) => {
      return !!(u.data && notificationRecipient.data && u.data.id === notificationRecipient.data.id);
    });
  }

  onCreateScheduleClick = () => {
    this.toggleScheduleButtonDisabled();
    this.createSchedule();
  }

  renderScheduleListingButton = () => {
      return (
        <button
          className="btn btn-primary"
          onClick={() => window.open(ReportingRoutes.scheduledReports, '_self')}
          style={{marginTop: 5}}
        >
          Schedules
        </button>
      );
  }

  createSchedule = async () => {
    const { loadedQuery, notificationService, scheduledQuery, onSaveSuccess } = this.props;
    const { isOutbound, isOutboundEmailNotification} = this.state;
    const data = getScheduleDataFromState(this.state, loadedQuery, scheduledQuery);

    // Validate
    const validationErrors = await isScheduleOptionsValid(data, isOutbound, isOutboundEmailNotification);

    if (Object.keys(validationErrors).length > 0) {
      this.setState(
        {
          errors: validationErrors
        },
        () => this.toggleScheduleButtonDisabled()
      );

      return;
    }

    // Send the request to the backend
    try {
      let response = scheduledQuery ? await apiEditSchedule(data) : await apiCreateSchedule(data);

      if (onSaveSuccess) {
        onSaveSuccess(response);
      }
      if (scheduledQuery) {
        notificationService.addNotification(
          NOTIFICATION_SUCCESS,
          'Schedule saved successfully.'
        );
      } else {
        notificationService.addNotification(
          NOTIFICATION_SUCCESS,
          'Report scheduled successfully.',
          this.renderScheduleListingButton()
        );
      }
      this.toggle();
    } catch (error) {
      this.toggleScheduleButtonDisabled();
    }
  }

  toggle = () => {
    const { toggle } = this.props;
    toggle();
    this.setState(Object.assign({}, initialState));
  }

  toggleScheduleButtonDisabled = () => {
    const { isSaving } = this.state;
    this.setState({
      isSaving: !isSaving
    });
  }

  onOutboundDestinationChange = (outboundDestinationId: string) => {
    const { errors } = this.state;
    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_OUTBOUND_DESTINATION]);

    this.setState({
      outboundDestinationId,
      errors: newErrors
    });
  }

  toggleOutbound = (value: any) => {
    const isOutbound = (value === SCHEDULE_DISPATCH_TYPE_OUTBOUND);

    const { errors } = this.state;
    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_USERS]);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_OUTBOUND_DESTINATION]);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS]);

    this.setState({
      isOutbound,
      errors: newErrors
    });
  }

  toggleOutboundNotification = (value: any) => {
    const isOutboundEmailNotification = (value === SCHEDULE_NOTIFICATION_TYPE_EMAIL);

    const { errors } = this.state;
    const newErrors = Object.assign({}, errors);
    delete(newErrors[SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS]);

    this.setState({
       isOutboundEmailNotification,
       errors: newErrors
    });
  }

  render() {
    const {
      isOpen,
      loadedQuery,
      scheduledQuery
    } = this.props;

    const {
      scheduleName,
      timezone,
      startDate,
      endDate,
      time,
      occurrence,
      daysOfWeek,
      dayOfMonth,
      users,
      isSaving,
      errors,
      outboundDestinationId,
      isOutbound,
      userList,
      isUserListLoading,
      isUserListTyping,
      notificationRecipients,
      recipientEmail,
      isOutboundEmailNotification

    } = this.state;

    const scheduleButtonText = scheduledQuery ? 'Save' : 'Schedule';

    return (
      <Modal
        id="AddScheduleModal"
        className="add-schedule-modal"
        type="medium"
        title={(
          <ModalTitle
            title="Schedule export"
            subtitle="A CSV file will be generated and made available in the Downloads list."
          />
        )}
        ariaLabel="Schedule export"
        isOpened={isOpen}
        closeModal={this.toggle}
        primaryButton={(
          <Button
            className="btn btn-primary"
            onClick={this.onCreateScheduleClick}
            isDisabled={isSaving}
            data-testid="schedule-save-btn"
          >
            {scheduleButtonText} {isSaving && <LoadingIcon isSmall={true} iconOnly={true} />}
          </Button>
        )}
      >
          <form
            className="add-schedule-form"
            data-testid="add-schedule-form"
          >
            <FormItem>
              <Heading htmlTag="h2" type="title" size="xs">Set up export</Heading>
            </FormItem>
            <AddScheduleForm
              loadedQuery={loadedQuery}
              scheduleName={scheduleName}
              scheduleTimezone={timezone}
              onScheduleNameChange={this.onScheduleNameChange}
              startDate={startDate}
              endDate={endDate}
              onDateChange={this.onDateChange}
              time={time}
              onTimeChange={this.onTimeChange}
              occurrence={occurrence}
              onOccurrenceSelect={this.onOccurrenceSelect}
              daysOfWeek={daysOfWeek}
              onDaysOfWeekToggle={this.onDaysOfWeekToggle}
              dayOfMonth={dayOfMonth}
              onDayOfMonthSelect={this.onDayOfMonthSelect}
              errors={errors}
            />
            <FormItem>
              <Heading htmlTag="h2" type="title" size="xs">Send to</Heading>
            </FormItem>
            <FormItem>
              {AuthService.getInstance().isSuperAdmin() && <OutboundToggle
                  isOutbound={isOutbound}
                  onChange={this.toggleOutbound}
              />}
            </FormItem>
            <UserSelectForm
              userList={userList}
              selectedUsers={users}
              onUserSelect={this.onUserSelect}
              onUserSelectChange={this.onUserSelectChange}
              onUserDeselect={this.onUserDeselect}
              onResetUserList={this.onResetUserList}
              errors={errors}
              isVisible={!isOutbound}
              isLoading={isUserListLoading}
              isUserListTyping={isUserListTyping}
            />
            <OutboundDestinationForm
              outboundDestinationId={outboundDestinationId}
              onOutboundDestinationChange={this.onOutboundDestinationChange}
              errors={errors}
              isVisible={isOutbound}
              isSuperAdmin={AuthService.getInstance().isSuperAdmin()}
              userList={userList}
              selectedUsers={notificationRecipients}
              onUserSelect={this.onNotificationRecipientsSelect}
              onUserSelectChange={this.onUserSelectChange}
              onResetUserList={this.onResetUserList}
              isLoading={isUserListLoading}
              recipientEmail={recipientEmail}
              onRecipientEmailChange={this.onRecipientEmailChange}
              isOutboundEmailNotification={isOutboundEmailNotification}
              toggleOutboundNotification={this.toggleOutboundNotification}
            />
          </form>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps)(AddScheduleModal);
