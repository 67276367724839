import React, { useEffect } from 'react';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { Divider } from '@eds/divider';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { OptionProps, SelectDropdown } from '@eds/select-dropdown';
import { Text } from '@eds/text';
import { space } from '@eds/core';
import { css } from '@emotion/css';
import { get } from 'lodash';

import CollapseCard from 'src/components/NewReportBuilder/CollapseCard';
import { DelimiterIcon, SelectIcon, SelectInput, TextFieldIcon, TextInput, cssOverrides } from 'src/features/ui';

import { ConcatenationConfigFieldType } from './concatenation-config-setup.validation';
import {
  ConcatenationConfiguration,
  delimiterTokenLabelOverride,
  useConcatenationConfigForm,
  useConcatenationConfigSubmit,
  useFieldOptions,
  useFormValuesInitializer,
} from './concatenation-config-setup.hooks';
import { useConcatenationConfigDelimiterTokensQuery } from './concatenation-config-setup.queries';

type ConcatenationConfigFieldTypeOption = {
  label: string;
  value: ConcatenationConfigFieldType;
};

const CustomItem = ({ data }: OptionProps<ConcatenationConfigFieldTypeOption>) => (
  <Flex alignItems="center" gap="small" lineHeight="nospace">
    <span>
      {data.value === 'delimiter' && <DelimiterIcon size="xsmall" />}
      {data.value === 'dimension' && <SelectIcon size="xsmall" />}
      {data.value === 'string' && <TextFieldIcon size="xsmall" />}
    </span>
    <span>{data.label}</span>
  </Flex>
);

const concatenationConfigFieldTypeOptions: ConcatenationConfigFieldTypeOption[] = [
  { label: 'Field', value: 'dimension' },
  { label: 'Delimiter', value: 'delimiter' },
  { label: 'String', value: 'string' },
];

type ConcatenationConfigSetupProps = {
  data?: ConcatenationConfiguration;
  onClose: () => void;
  open: boolean;
  reportId?: string;
};

export const ConcatenationConfigSetup = ({ data, onClose, open, reportId }: ConcatenationConfigSetupProps) => {
  const [{ control, formState, handleSubmit, reset, trigger, watch }, { append, fields, remove }] =
    useConcatenationConfigForm();

  const {
    errors: { concatenatedValues, targetField },
    isSubmitSuccessful,
    isSubmitted,
  } = formState;

  const watchFieldArray = watch('concatenatedValues');
  const controlledFields = fields.map((field, index) => ({ ...field, ...watchFieldArray[index] }));

  const { data: delimiterTokens = [], isLoading } = useConcatenationConfigDelimiterTokensQuery(open);

  const [concatenatedValuesFieldOptions, targetFieldOptions] = useFieldOptions();

  const onAdd = (type?: ConcatenationConfigFieldType) => {
    if (!type) return;

    if (type === 'string') {
      append({ type, value: '' });
    } else {
      append({ type, value: null });
    }

    if (isSubmitted) trigger();
  };

  const onSubmit = useConcatenationConfigSubmit(reportId, data?.id);

  useFormValuesInitializer({ data, open, reset });

  useEffect(() => {
    if (isSubmitSuccessful) onClose();
  }, [isSubmitSuccessful]);

  return (
    <CollapseCard className={open ? css({ marginBottom: space.large }) : ''} isOpen={open}>
      <Flex
        as="form"
        containerType="inline-size"
        flexDirection="column"
        gap="large"
        onSubmit={handleSubmit(onSubmit)}
        padding="large"
      >
        <Text fontWeight="bold" lineHeight="tight">
          Concatenation
        </Text>
        <Box>
          <Box width={{ container: { small: '33%' } }}>
            <Field
              description="Select the field to which you would like to apply concatenation"
              invalid={!!targetField}
              label="Field"
              message={targetField?.message}
              required
            >
              <SelectInput control={control} items={targetFieldOptions} name="targetField" size="small" />
            </Field>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Field
            description="Provide values to be concatenated by clicking 'Add' and selecting from the list"
            label="Concatenated values"
            required
          >
            <></>
          </Field>
          <Flex columnGap="medium" containerType="inline-size" flexWrap="wrap">
            {controlledFields.map(({ id, type }, index) => (
              <Box key={id} width={{ container: { xsmall: '100%', small: '12.5rem' } }}>
                <Field
                  invalid={!!get(concatenatedValues, index)}
                  label={type === 'delimiter' ? 'Delimiter' : type === 'dimension' ? 'Field' : 'String'}
                  message={get(concatenatedValues, `${index}.value.message`, '')}
                  required
                >
                  {type === 'delimiter' && (
                    <SelectInput
                      control={control}
                      isLoading={isLoading}
                      items={delimiterTokens.map(item => ({
                        ...item,
                        label: delimiterTokenLabelOverride[item.value] || item.label,
                      }))}
                      name={`concatenatedValues.${index}.value`}
                      size="small"
                    />
                  )}
                  {type === 'dimension' && (
                    <SelectInput
                      control={control}
                      items={concatenatedValuesFieldOptions}
                      name={`concatenatedValues.${index}.value`}
                      size="small"
                    />
                  )}
                  {type === 'string' && (
                    <TextInput
                      control={control}
                      name={`concatenatedValues.${index}.value`}
                      placeholder="Enter alphanumeric..."
                      size="small"
                    />
                  )}
                </Field>
              </Box>
            ))}
            <Flex className={css(cssOverrides.button('neutral'))} marginTop="auto">
              <Field
                invalid={!!concatenatedValues?.message}
                labelVisible={false}
                label="Add or remove"
                message={concatenatedValues?.message}
              >
                <Flex gap="xxsmall">
                  <SelectDropdown<ConcatenationConfigFieldTypeOption>
                    hideSearchInput
                    itemOverride={CustomItem}
                    items={concatenationConfigFieldTypeOptions}
                    label="Add"
                    onChange={item => onAdd(item?.value)}
                  />
                  <Button label="Remove" onClick={() => remove(fields.length - 1)} size="small" tone="neutral" />
                </Flex>
              </Field>
            </Flex>
          </Flex>
          <Divider />
        </Box>
        <Flex gap="small">
          <Box className={css(cssOverrides.button('neutral'))}>
            <Button label="Cancel" tone="neutral" size="small" onClick={onClose} />
          </Box>
          <Box className={css(cssOverrides.button('primary'))}>
            <Button label="Apply" type="submit" size="small" />
          </Box>
        </Flex>
      </Flex>
    </CollapseCard>
  );
};
