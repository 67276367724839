import {
  FILTER_TYPE,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_NOT_BLANK,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_PERIOD_DAY,
  FILTER_PERIOD_MONTH,
  FILTER_PERIOD_QUARTER,
  FILTER_PERIOD_WEEK,
  FILTER_PERIOD_YEAR,
  FILTER_DATE_CONTEXT_CURRENT,
  FILTER_DATE_CONTEXT_LAST,
  FILTER_DATE_CONTEXT_NEXT
} from '../constants/filters';

// These are the Filter types and the correct order when displayed
export const FILTER_TYPES: FILTER_TYPE[] = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK,
];

export const FILTER_DATE_PERIODS = [
  FILTER_PERIOD_DAY,
  FILTER_PERIOD_WEEK,
  FILTER_PERIOD_MONTH,
  FILTER_PERIOD_QUARTER,
  FILTER_PERIOD_YEAR,
];

export const FILTER_DATE_PERIODS_LIMITED = [
  FILTER_PERIOD_DAY,
  FILTER_PERIOD_MONTH,
  FILTER_PERIOD_QUARTER,
];

export const FILTER_DATE_CONTEXTS = [
  FILTER_DATE_CONTEXT_CURRENT,
  FILTER_DATE_CONTEXT_NEXT,
  FILTER_DATE_CONTEXT_LAST
];

/**
 * Filters are directly used for querying and rendering the list of Filters in the Filter section
 * Drilldowns also use Filters for the WHERE clause.
 */
export class Filter {
  viewName: string;
  dimensionName?: string;
  dimensionOrMeasureType?: string;
  measureName?: string;
  joinName?: string;
  type: FILTER_TYPE;
  value: Array<{ value: string, label: string }> | string | number;
  low?: string | number;
  high?: string | number;
  relativeDate?: string;
  periodCount?: string;
  nowAsDefaultReferencePoint?: boolean;
  source?: string;
}
