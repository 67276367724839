import * as React from 'react';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';

type Props = {
  color?: string;
  size?: string;
  disabled?: boolean;
};

export function SplitButtonDropdownToggle({ color, size, disabled }: Props) {
  return (
    <DropdownToggle
      size={size ? size : ''}
      caret={true}
      color={color ? color : 'primary'}
      disabled={disabled}
    />
  );
}