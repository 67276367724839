import * as React from 'react';

export interface ConfigMenuProps {
}

class ConfigMenu extends React.Component<ConfigMenuProps> {

  constructor(props: ConfigMenuProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <a href="/configs"><i className="fa fa-angle-left" /> Back to Config Listing</a>
      </div>
    );
  }
}

export default ConfigMenu;