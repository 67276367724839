export class LocalStorageService {
  static setItem(key: string, value: any) {
    try {
      const item = JSON.stringify(value);
      localStorage.setItem(key, item);
      return true;
    } catch (e) {
      return false;
    }
  }

  static getItem(key: string) {
    try {
      const item = localStorage.getItem(key);
      return !!item && JSON.parse(item);
    } catch (e) {
      return false;
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  static clear() {
    try {
      localStorage.clear();
      return true;
    } catch {
      return false;
    }
  }
}
