import { ADD_MEASURES, REMOVE_MEASURES } from '../constants';
import { MeasuresAction } from '../actions/measure';
import { Measure } from '../types/measureModel';
import * as _ from 'lodash';
import { ReportBuilderAction } from '../actions/reportBuilder';

export function measuresReducer(state: Measure[] = [],
                                action: MeasuresAction | ReportBuilderAction): Measure[] {
  switch (action.type) {
    case ADD_MEASURES:
      return state.concat(action.measures).slice();
    case REMOVE_MEASURES:
      return _.differenceBy(state, action.measures, 'name').slice();
    default:
      return state;
  }
}