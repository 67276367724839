import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CheckConfigForm from '../../components/Config/CheckConfigForm';
import { apiCheckExistingConfiguration } from '../../services/configurationCheckService';
import Alert from '../../shared-components/Alert';
import Page from '../../shared-components/Page';

interface CheckConfigPageContainerProps extends RouteComponentProps<any> {
}

interface CheckConfigPageContainerState {
  showForm: boolean;
  configErrors: string;
  showSuccess: boolean;
  checkingConfig: boolean;
}

class CheckConfigPageContainer extends React.Component<CheckConfigPageContainerProps, CheckConfigPageContainerState> {
  constructor(props: CheckConfigPageContainerProps) {
    super(props);

    this.state = {
      showForm: false,
      configErrors: '',
      showSuccess: false,
      checkingConfig: false
    };
  }

  componentDidMount() {
    let configId = this.props.match.params['configId'];
    if (!configId) {
      this.setState({
        showForm: true
      });
    } else {
      this.checkExistingConfiguration(configId);
    }
  }

  async checkExistingConfiguration(configId: string) {
    this.setState({
      checkingConfig: true
    });

    let errors = await apiCheckExistingConfiguration(configId);
    this.onCheckConfigurationDone(errors);
  }

  onCheckConfigurationDone = (errors: string) => {
    if (errors) {
      this.setState({
        showSuccess: false,
        configErrors: errors,
        checkingConfig: false
      });
    } else {
      this.setState({
        showSuccess: true,
        configErrors: '',
        checkingConfig: false
      });
    }
  }

  renderConfigErrors() {
    if (!this.state.configErrors) {
      return null;
    }

    return (
      <Alert
        type="danger"
      >
        {this.state.configErrors}
      </Alert>
    );
  }

  renderConfigSuccess() {
    if (!this.state.showSuccess) {
      return null;
    }

    return (
      <Alert
        type="success"
      >
        Configuration is valid!
      </Alert>
    );
  }

  render() {
    return (
      <Page title="Check Configuration" className="configuration-layout check-config-page">
        {this.state.checkingConfig &&
        <div>Checking configuration...</div>
        }
        {this.renderConfigErrors()}
        {this.renderConfigSuccess()}
        {this.state.showForm &&
        <CheckConfigForm
          onCheckConfigurationDone={this.onCheckConfigurationDone}
        />
        }
      </Page>
    );
  }
}

const CheckConfigPage = withRouter(CheckConfigPageContainer);

export default CheckConfigPage;
