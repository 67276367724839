import { Filter } from '../types/filterModel';
import { FilterGroupsAction } from '../actions/filterGroup';
import { REPLACE_FILTER_GROUPS } from '../constants';

export function filterGroupsReducer(state: Array<Array<Filter>> = [], action: FilterGroupsAction) {
  switch (action.type) {
    case REPLACE_FILTER_GROUPS:
      return action.filterGroups.map(filters => filters.map(filter => ({ ...filter })));
    default:
      return state;
  }
}