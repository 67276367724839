import * as React from 'react';
import {
  DATE_DIMENSION_TYPES,
} from '../../constants';
import {
  FILTER_DATE_CONTEXT_DATE,
  FILTER_TYPE,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_IN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_NOT_EQUALS,
} from '../../constants/filters';
import { Filter } from '../../types/filterModel';
import { ViewJoinAndDimensionOrMeasure } from '../../redux';
import { getColumnType } from '../../services/filterService';
import FilterDateRangeInput from './FilterDateRangeInput';
import FilterDatePeriodInput from './FilterDatePeriodInput';
import { FILTERS_FOR_TIME_CONTEXT } from './index';
import FilterRelativeDateInput from './FilterRelativeDateInput';
import FilterDatepickerInput from './FilterDatepickerInput';
import FilterTextSelect from './FilterTextSelect';
import FilterMultiCreatableSelect from './FilterMultiCreatableSelect';

type FilterValueInputProps = {
  displayFilter: Filter;
  filterOption: ViewJoinAndDimensionOrMeasure;
  filterIndex: number;
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
 };

class FilterValueInput extends React.Component<FilterValueInputProps> {

  static dateRangeInputRequired(fieldType: string, filterType: FILTER_TYPE) {
    return DATE_DIMENSION_TYPES.includes(fieldType)
        && filterType === FILTER_TYPE_BETWEEN;
  }

  static multiCreatableSelectRequired(fieldType: string, filterType: FILTER_TYPE) {
    return !DATE_DIMENSION_TYPES.includes(fieldType)
      && [FILTER_TYPE_EQUALS, FILTER_TYPE_IN].includes(filterType);
  }

  static textInputRequired(fieldType: string, filterType: FILTER_TYPE) {
    return !DATE_DIMENSION_TYPES.includes(fieldType) && [
      FILTER_TYPE_NOT_EQUALS,
      FILTER_TYPE_LIKE,
      FILTER_TYPE_NOT_LIKE,
      FILTER_TYPE_LESS_THAN,
      FILTER_TYPE_GREATER_THAN
    ].includes(filterType);
  }

  static datePeriodInputRequired(fieldType: string, filterType: FILTER_TYPE) {
    return DATE_DIMENSION_TYPES.includes(fieldType)
        && FILTERS_FOR_TIME_CONTEXT.includes(filterType);
  }

  static datePickerInputRequired(
    fieldType: string,
    filter: Filter
  ) {
    return DATE_DIMENSION_TYPES.includes(fieldType)
        && FILTERS_FOR_TIME_CONTEXT.includes(filter.type)
      && filter.relativeDate === FILTER_DATE_CONTEXT_DATE;
  }

  renderFormControl() {
    const { displayFilter, displayFilters, filterOption, filterIndex, saveDisplayFilter } = this.props;
    let columnType: string = getColumnType(filterOption);
    if (FilterValueInput.dateRangeInputRequired(columnType, displayFilter.type)) {
      return (
        <div className="col-sm-4 filter-value">
          <FilterDateRangeInput
            displayFilter={displayFilter}
            filterIndex={filterIndex}
            displayFilters={displayFilters}
            saveDisplayFilter={saveDisplayFilter}
          />
        </div>
      );
    } else if (FilterValueInput.multiCreatableSelectRequired(columnType, displayFilter.type)) {
      return (
        <FilterMultiCreatableSelect
          displayFilter={displayFilter}
          filterIndex={filterIndex}
          displayFilters={displayFilters}
          saveDisplayFilter={saveDisplayFilter}
          filterOption={filterOption}
        />
      ) ;
    } else if (FilterValueInput.textInputRequired(columnType, displayFilter.type)) {
      return (
        <FilterTextSelect
            displayFilter={displayFilter}
            filterIndex={filterIndex}
            displayFilters={displayFilters}
            saveDisplayFilter={saveDisplayFilter}
            filterOption={filterOption}
        />
      ) ;
    } else if (FilterValueInput.datePeriodInputRequired(columnType, displayFilter.type)) {
      return (
        <>
          <div className="col-sm-2 filter-value">
            <FilterRelativeDateInput
              displayFilter={displayFilter}
              filterIndex={filterIndex}
              displayFilters={displayFilters}
              saveDisplayFilter={saveDisplayFilter}
            />
          </div>
          <div className="col-sm-3 filter-value">
          {FilterValueInput.datePickerInputRequired(columnType, displayFilter) &&
            <FilterDatepickerInput
              displayFilter={displayFilter}
              filterIndex={filterIndex}
              displayFilters={displayFilters}
              saveDisplayFilter={saveDisplayFilter}
            />
          }
          {!FilterValueInput.datePickerInputRequired(columnType, displayFilter) &&
            <FilterDatePeriodInput
              displayFilter={displayFilter}
              filterIndex={filterIndex}
              displayFilters={displayFilters}
              saveDisplayFilter={saveDisplayFilter}
            />
          }
          </div>
        </>
        );
    }

    return null;
  }

  render() {
    return this.renderFormControl();
  }
}

export default FilterValueInput;
