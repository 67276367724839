import React, { useEffect, useState } from 'react';
import { Action } from '@eds/actions';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { Pagination } from '@eds/pagination';
import { css } from '@emotion/css';
import { useDebounce } from '@eds/utils';

import { AuthService } from 'src/services/authService';
import { cssOverrides } from 'src/features/ui';
import { useDashboardHeaderContext } from 'src/features/dashboards';

import { DashboardsListCardView } from './dashboards-list-card-view.component';
import { DashboardsListFilters } from './dashboards-list-filters.component';
import { DashboardsListTableView } from './dashboards-list-table-view.component';
import { useDashboardsList } from './dashboards-list.hooks';
import {
  DashboardData,
  DashboardsListQueryParams,
  useDashboardsListQuery,
  useDashboardsListUserCapabilitiesQuery,
} from './dashboards-list.queries';

type DashboardsListProps = {
  onCopy: (id: string) => void;
  onDelete: ({ id, isUnsharing, name }: { id: string; isUnsharing: boolean; name: string }) => void;
  onEdit: ({ description, id, name }: { description: string; id: string; name: string }) => void;
  onShare: ({ id, name }: { id: string; name: string }) => void;
};

export const DashboardsList = ({ onCopy, onDelete, onEdit, onShare }: DashboardsListProps) => {
  const {
    currentItemsPerPage,
    currentPage,
    dashboardTypeFilter,
    keywordFilter,
    onPageUpdate,
    onSort,
    rangeStart,
    setDashboardTypeFilter,
    setKeywordFilter,
    sortData,
  } = useDashboardsList();
  const userId = AuthService.getInstance().getUserData()?.id || 0;

  const { viewMode } = useDashboardHeaderContext();

  const [query, setQuery] = useState<DashboardsListQueryParams>({
    currentItemsPerPage,
    currentPage,
    dashboardTypeFilter,
    keywordFilter,
    rangeStart,
    sortData,
  });
  const [debouncedQuery] = useDebounce(query, 300);

  const { data: userCapabilities } = useDashboardsListUserCapabilitiesQuery(userId);

  const displayDashboardsList = userCapabilities?.canViewDashboardList;

  const {
    data = { dashboards: [], total: 0 },
    isFetching,
    isLoading,
    isPreviousData,
  } = useDashboardsListQuery(!!displayDashboardsList, debouncedQuery);

  const getActions = ({ dashboardId, description, title, permissions }: DashboardData) => {
    const { canClone, canDelete, canEdit, canShare, canUnshare } = permissions;

    const actions: Action[] = [];

    if (canEdit) {
      actions.push({
        label: 'Edit',
        onClick: () => onEdit({ description, id: dashboardId, name: title }),
      });
    }

    if (canClone) {
      actions.push({
        label: 'Copy',
        onClick: () => onCopy(dashboardId),
      });
    }

    if (canShare) {
      actions.push({
        label: 'Share',
        onClick: () => onShare({ id: dashboardId, name: title }),
      });
    }

    if (canDelete || canUnshare) {
      actions.push({
        label: 'Delete',
        onClick: () => onDelete({ id: dashboardId, isUnsharing: !canDelete && canUnshare, name: title }),
        tone: 'critical',
      });
    }

    return actions;
  };

  const dependencies = `${currentItemsPerPage}${currentPage}${dashboardTypeFilter.join()}${keywordFilter}${rangeStart}${
    sortData.columnName
  }${sortData.sortDirection}`;

  useEffect(() => {
    setQuery({
      currentItemsPerPage,
      currentPage,
      dashboardTypeFilter,
      keywordFilter,
      rangeStart,
      sortData,
    });
  }, [dependencies]);

  return displayDashboardsList ? (
    <Flex gap="medium" flexDirection="column">
      <DashboardsListFilters
        dashboardTypeFilter={dashboardTypeFilter}
        keywordFilter={keywordFilter}
        loading={isLoading || (isFetching && isPreviousData)}
        setDashboardTypeFilter={setDashboardTypeFilter}
        setKeywordFilter={setKeywordFilter}
      />
      {viewMode === 'grid' ? (
        <DashboardsListCardView dashboards={data.dashboards} getActions={getActions} />
      ) : (
        <DashboardsListTableView
          dashboards={data.dashboards}
          getActions={getActions}
          onSort={onSort}
          sortData={sortData}
        />
      )}
      <Box className={css(cssOverrides.button('ghost'))}>
        <Pagination
          currentPage={currentPage}
          currentItemsPerPage={currentItemsPerPage}
          itemsCount={data.total}
          onPageUpdate={onPageUpdate}
        />
      </Box>
    </Flex>
  ) : null;
};
