import { sortBy } from 'lodash';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';

import { convertImageSrcToDataURL } from '../utils';
import { LocalStorageService } from './localStorageService';
import { APP_MENU_STORAGE_KEY } from '../constants';
import { Dashboard as DashboardModel } from '../types/dashboardModel';
import { AuthService } from './authService';

// Common constants
const DIV_ELEMENT_TYPE = 'DIV';
const IMAGE_ELEMENT_TYPE = 'IMG';

// Pdf doc params
const PDF_DOC_DIMENSION = 'mm';
const PDF_DOC_ORIENTATION = 'portrait';
const PDF_DOC_FORMAT = 'a4';
const PDF_DOC_PADDING = {
  top: 10,
  left: 10,
};
const PDF_DOC_IMAGE_FORMAT = 'JPEG';
const PDF_DOC_COMPRESSION_LEVEL = 'MEDIUM';
const PDF_DATE_FORMAT = 'dddd DD MMMM, YYYY';

// selectors constants
const widgetSelector = '[data-widget]';
const dashboardContainerSelector = '.react-grid-layout';
const scrollableDashboardContainerSelector = '.elmo-layout__main-content-wrapper';
const pdfTemplateContainerClassname = 'pdfTemplateContainer';
const pdfTemplatePageClassname = 'pdfTemplatePage';
const pdfTemplatePageHeaderClassname = 'pdfTemplatePageHeader';
const pdfTemplatePageLogoClassname = 'pdfTemplatePageLogo';
const pdfTemplatePageInfoBlockClassname = 'pdfTemplatePageInfoBlock';
const pdfTemplatePageTitleClassname = 'pdfTemplatePageTitle';
const pdfTemplatePageDateClassname = 'pdfTemplatePageDate';
const pdfTemplateWidgetClassname = 'pdfTemplateWidget';

// Styles
const pdfTemlateHeaderHeight = 150;
const pdfTemplateHeaderMarginBottom = 20;
const pdfFirstPageNumber = 1;

interface PdfPageData {
  pageBottomCoord: number;
  widgets: Array<PdfWidgetData>;
}

interface PdfWidgetData {
  dimensions: ClientRect | DOMRect;
  element: Element;
}

const getCompanyLogoUrl = () => {
  const authService: AuthService = AuthService.getInstance();
  const user = authService.getUserData();

  if (!user) {
    // due to side effect of getUserData, the session should invalidate
    return null;
  }

  const cachedNavigationData = LocalStorageService.getItem(APP_MENU_STORAGE_KEY)
    ? LocalStorageService.getItem(APP_MENU_STORAGE_KEY)[`${user.uuid}_${user.id}`]
    : undefined;

  if (!cachedNavigationData || !cachedNavigationData.branding) {
    return null;
  }

  return cachedNavigationData.branding.logo;
};

const filterLoadedWidgetElements = (widgetElements: Array<HTMLElement>, loadedWidgetsIds: Array<string>) => {
  return widgetElements.filter((element => {
    const widgetElementId = element.dataset.widget;
    return loadedWidgetsIds.some((loadedWidgetId) => widgetElementId === loadedWidgetId);
  }));
};

const calculateTemplatePageDimensions = (
  dashboardPageElement: Element,
  pdfPageWidth: number,
  pdfPageHeight: number
) => {
  const dashboardPageDimensions = dashboardPageElement.getBoundingClientRect();
  let templatePageWidth = dashboardPageDimensions.width;
  const dimensionsRatio = templatePageWidth / pdfPageWidth;
  const templatePageHeight = (pdfPageHeight - 2 * PDF_DOC_PADDING.top) * dimensionsRatio;
  templatePageWidth = templatePageWidth - (2 * PDF_DOC_PADDING.top * dimensionsRatio);

  return {
    dimensionsRatio,
    templatePageHeight,
    templatePageWidth,
    dashboardPageDimensions,
  };
};

const calculateDashboardWidgetsDimensions = (loadedWidgetsIds: Array<string>) => {
  const widgetElements: NodeList = document.querySelectorAll(widgetSelector);
  const loadedWidgetElements = filterLoadedWidgetElements(
    Array.from(widgetElements) as Array<HTMLElement>, loadedWidgetsIds
  );
  // @ts-expect-error
  const widgetsDimensionsData: Array<PdfWidgetData> =
  loadedWidgetElements.map((element: HTMLElement) => {
      const {top, right, bottom, left, width, height } = element.getBoundingClientRect();

      return {
        element,
        dimensions: {
          top: top + pdfTemlateHeaderHeight,
          bottom: bottom + pdfTemlateHeaderHeight,
          right,
          left,
          width,
          height,
        },
      };
    });
  return widgetsDimensionsData;
};

const calculateTemplatePagesLayout = (
  sortedWidgetsDimensionsData: Array<PdfWidgetData>,
  dashboardPageDimensions: ClientRect | DOMRect,
  templatePageHeight: number,
) => {
  const templatePagesLayout: Record<number, PdfPageData> = {};

  let currentPdfDocPage = pdfFirstPageNumber;
  if (!!sortedWidgetsDimensionsData && !!sortedWidgetsDimensionsData.length) {
    let currentPageWidgets: Array<PdfWidgetData> = [];
    let currentPageFirstWidget: PdfWidgetData;

    sortedWidgetsDimensionsData.forEach((widgetItem, widgetIndex) => {
      if (!templatePagesLayout[currentPdfDocPage]) {
        templatePagesLayout[currentPdfDocPage] = {
          widgets: [],
          pageBottomCoord: currentPdfDocPage * templatePageHeight,
        };
      }

      const headerDelta = currentPdfDocPage === pdfFirstPageNumber ? pdfTemlateHeaderHeight : 0;
      let updatedWidgetData: PdfWidgetData;

      if (currentPageWidgets.length === 0) {
        // As this widget is first on the page, we need to update it's bottom coord with 
        // value equals to sum of pages height, placed before this widget.
        currentPageFirstWidget = widgetItem;
        updatedWidgetData = {
          ...widgetItem,
          dimensions: {
            ...widgetItem.dimensions,
            bottom: ((currentPdfDocPage - 1) * templatePageHeight) + widgetItem.dimensions.height + headerDelta,
          }
        };
        currentPageWidgets.push(updatedWidgetData);
      } else {
        updatedWidgetData = {
          ...widgetItem,
          dimensions: {
            ...widgetItem.dimensions,
            bottom: currentPageWidgets[0].dimensions.bottom + 
              (widgetItem.dimensions.bottom - currentPageFirstWidget.dimensions.bottom),
          }
        };
        currentPageWidgets.push(updatedWidgetData);
      }

      const isWidgetDoesntFitPage = 
        updatedWidgetData.dimensions.bottom >= templatePagesLayout[currentPdfDocPage].pageBottomCoord;
      const isLastWidget = widgetIndex === (sortedWidgetsDimensionsData.length - 1);

      if (isWidgetDoesntFitPage || isLastWidget) {
        const firstWidgetOnPage = currentPageWidgets[0];
        templatePagesLayout[currentPdfDocPage].widgets = currentPageWidgets
          .map((widget) => {
            const widgetTopCoord = widget.dimensions.top - firstWidgetOnPage.dimensions.top;
            const updatedDimensions = currentPdfDocPage !== pdfFirstPageNumber ? {
                ...widget.dimensions,
                top: widgetTopCoord,
                left: widget.dimensions.left - dashboardPageDimensions.left,
              } : {
              ...widget.dimensions,
              top: widget.dimensions.top - dashboardPageDimensions.top,
              left: widget.dimensions.left - dashboardPageDimensions.left,
            };

            return {
              dimensions: updatedDimensions,
              element: widget.element,
            };
          });

        if (isWidgetDoesntFitPage) {
          // Remove from template current widgets last widget, which doesn't fit page size.
          // It should be added to the next page
          templatePagesLayout[currentPdfDocPage].widgets.pop();
        }

        currentPdfDocPage += 1;
        currentPageWidgets = [];

        if (isWidgetDoesntFitPage) {
          const nextPageWidget = {
            ...widgetItem,
            dimensions: {
              ...widgetItem.dimensions,
              bottom: ((currentPdfDocPage - 1) * templatePageHeight) + widgetItem.dimensions.height,
            }
          };
          currentPageWidgets.push(nextPageWidget);
          currentPageFirstWidget = widgetItem;

          if (isLastWidget) {
            const updatedWidgetDimensions = {
              ...widgetItem.dimensions,
              top: 0,
              left: widgetItem.dimensions.left - dashboardPageDimensions.left,
              bottom: widgetItem.dimensions.top + widgetItem.dimensions.height,
            };
            const updatedWidget = {
              dimensions: updatedWidgetDimensions,
              element: widgetItem.element,
            };
            // if widget doesn't fit page and this is the last widget at all, 
            // it shoud be added to the template's next page
            templatePagesLayout[currentPdfDocPage] = {
              widgets: [updatedWidget],
              pageBottomCoord: currentPdfDocPage * templatePageHeight,
            };
          }
        }
      }
    });
  }
  return templatePagesLayout;
};

const clearTemplateLayout = () => {
  const previousTemplateContainerElement = document.querySelector(`.${pdfTemplateContainerClassname}`);

  if (!!previousTemplateContainerElement) {
    previousTemplateContainerElement.innerHTML = '';
    previousTemplateContainerElement.remove();
  }
};

const renderTemplatePageElement = (
  templateContainerElement: Element,
  dashboardPageDimensions: ClientRect | DOMRect,
  templatePageHeight: number
) => {
  const templatePageElement = document.createElement(DIV_ELEMENT_TYPE);
  templatePageElement.classList.add(pdfTemplatePageClassname);
  templatePageElement.style.width = `${String(dashboardPageDimensions.width)}px`;
  templatePageElement.style.height = `${String(templatePageHeight)}px`;
  templateContainerElement.appendChild(templatePageElement);
  return templatePageElement;
};

const createTemplateLogoElement = async () => {
  const pageLogoElement = document.createElement(IMAGE_ELEMENT_TYPE);
  const logoUrl = getCompanyLogoUrl();
  const logoDataUrl = await convertImageSrcToDataURL(logoUrl);

  (pageLogoElement as HTMLImageElement).src = logoDataUrl;
  pageLogoElement.classList.add(pdfTemplatePageLogoClassname);
  return pageLogoElement;
};

const renderTemplateInfoBlockElement = (templateHeaderElement: Element, dashboard: DashboardModel) => {
  const infoBlockElement = document.createElement(DIV_ELEMENT_TYPE);
  infoBlockElement.classList.add(pdfTemplatePageInfoBlockClassname);
  templateHeaderElement.appendChild(infoBlockElement);

  const templateDateElement = document.createElement(DIV_ELEMENT_TYPE);
  templateDateElement.classList.add(pdfTemplatePageDateClassname);
  const currentDateString = moment().format(PDF_DATE_FORMAT);
  templateDateElement.innerHTML = currentDateString;
  infoBlockElement.appendChild(templateDateElement);

  const templateTitleElement = document.createElement(DIV_ELEMENT_TYPE);
  templateTitleElement.classList.add(pdfTemplatePageTitleClassname);
  templateTitleElement.innerHTML = dashboard.title;
  infoBlockElement.appendChild(templateTitleElement);
};

const renderTemplateHeaderElement = async (
  dashboard: DashboardModel,
  templatePageElement: Element,
  logoElement: Element
) => {
  const templateHeaderElement = document.createElement(DIV_ELEMENT_TYPE);
  templateHeaderElement.style.marginBottom = `${pdfTemplateHeaderMarginBottom}px`;
  templateHeaderElement.style.minHeight = `${pdfTemlateHeaderHeight}px`;
  templateHeaderElement.classList.add(pdfTemplatePageHeaderClassname);
  templatePageElement.appendChild(templateHeaderElement);

  templateHeaderElement.appendChild(logoElement);
  renderTemplateInfoBlockElement(templateHeaderElement, dashboard);
  return true;
};

const renderTemplateWidgetElement = (
  currentContainerScrollTop: number,
  templatePageElement: Element
) => (
  item: PdfWidgetData
) => {
  const widgetElement = item.element.cloneNode(true);
  (widgetElement as HTMLElement).classList.add(pdfTemplateWidgetClassname);
  templatePageElement.appendChild(widgetElement);
  (widgetElement as HTMLElement).style.width = String(item.dimensions.width);
  (widgetElement as HTMLElement).style.height = String(item.dimensions.height);
  (widgetElement as HTMLElement).style.top = 
    `${currentContainerScrollTop + item.dimensions.top}px`;
  (widgetElement as HTMLElement).style.left = `${item.dimensions.left}px`;
  (widgetElement as HTMLElement).style.transform = '';
};

export const renderPdfDashboardTemplate = async (
  dashboard: DashboardModel,
  pdfPageWidth: number,
  pdfPageHeight: number,
  loadedWidgetsIds: Array<string>
) => {
  const dashboardPageElement = document.querySelector(dashboardContainerSelector);
  const scrollableDashboardContainerElement = document.querySelector(scrollableDashboardContainerSelector);
  clearTemplateLayout();

  if (!!dashboardPageElement && !!scrollableDashboardContainerElement) {
    const {
      templatePageHeight,
      dashboardPageDimensions
    } = calculateTemplatePageDimensions(dashboardPageElement, pdfPageWidth, pdfPageHeight);

    const widgetsDimensionsData = calculateDashboardWidgetsDimensions(loadedWidgetsIds);
    // Here we sort widgets by their horizontal and vertical placement, to get the strict order
    // for the future building layout in pdf document
    const sortedWidgetsDimensionsData: Array<PdfWidgetData> = 
      sortBy(widgetsDimensionsData, 'dimensions.top', 'dimensions.left');

    const templatePages = calculateTemplatePagesLayout(
      sortedWidgetsDimensionsData,
      dashboardPageDimensions,
      templatePageHeight
    );

    const templateContainerElement = document.createElement(DIV_ELEMENT_TYPE);
    templateContainerElement.classList.add(pdfTemplateContainerClassname);
    const currentContainerScrollTop = scrollableDashboardContainerElement.scrollTop;

    const logoElement = await createTemplateLogoElement();

    Object.keys(templatePages).forEach((templatePageKey) => {
      const templatePageElement = renderTemplatePageElement(
        templateContainerElement,
        dashboardPageDimensions,
        templatePageHeight
      );

      if (Number(templatePageKey) === pdfFirstPageNumber) {
        // header with logo only for the first page
        renderTemplateHeaderElement(dashboard, templatePageElement, logoElement);
      }

      templatePages[templatePageKey].widgets.forEach(
        renderTemplateWidgetElement(currentContainerScrollTop, templatePageElement)
      );
    });

    dashboardPageElement.appendChild(templateContainerElement);
  }
  return true;
};

const exportHtmlPagesToCanvases = async (pageElements: NodeListOf<Element>): Promise<HTMLCanvasElement[]> => {
  const canvasPromises: Array<Promise<HTMLCanvasElement>> = [];

  pageElements.forEach(async (pageElement, pageIndex) => {
    const pageCanvasPromise = html2canvas(pageElement as HTMLElement, {
      logging: false,
      onclone: (clonedDocument: any) => {
        const widgetElements = clonedDocument.querySelectorAll(
          `.${pdfTemplatePageClassname} ${widgetSelector}`
        );
        widgetElements.forEach((item: HTMLElement) => item.style.overflow = 'visible');
      }
    });
    canvasPromises.push(pageCanvasPromise);
  });

  return Promise.all(canvasPromises);
};

const exportCanvasesToPdf = (
  canvases: HTMLCanvasElement[],
  pdfDoc: jsPDF,
  dashboardTitle: string,
  pageWidth: number,
  pageHeight: number
) => {
  canvases.forEach((canvas, index) => {
    if (index > 0) {
      pdfDoc.addPage();
    }

    pdfDoc
    .addImage(
      canvas,
      PDF_DOC_IMAGE_FORMAT,
      PDF_DOC_PADDING.left,
      PDF_DOC_PADDING.top,
      pageWidth,
      pageHeight,
      `imageAlias_${index}`,
      PDF_DOC_COMPRESSION_LEVEL,
    );
  });

  pdfDoc.save(dashboardTitle);
};

export const exportDashboardToPdf = async (dashboard: DashboardModel, loadedWidgetsIds: Array<string>) => {
  const pdfDoc = new jsPDF(PDF_DOC_ORIENTATION, PDF_DOC_DIMENSION, PDF_DOC_FORMAT);
  const width = pdfDoc.internal.pageSize.getWidth() - PDF_DOC_PADDING.left * 2;
  const height = pdfDoc.internal.pageSize.getHeight() - PDF_DOC_PADDING.top * 2;
  await renderPdfDashboardTemplate(dashboard, width, height, loadedWidgetsIds);

  const pageElements = document.querySelectorAll(`.${pdfTemplatePageClassname}`);

  if (!!pageElements && !!pageElements.length) {
    const canvases: Array<HTMLCanvasElement> = await exportHtmlPagesToCanvases(pageElements);
    exportCanvasesToPdf(canvases, pdfDoc, dashboard.title, width, height);
    clearTemplateLayout();
  }

  return true;
};
