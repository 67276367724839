import React from 'react';
import { Badge, BadgeProps } from '@eds/badge';
import { Box } from '@eds/box';
import { ButtonLink } from '@eds/button';
import { ColumnSort, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@eds/table';
import { DownloadIcon } from '@eds/icon';
import { EmptyFolderSpot } from '@eds/illustration';
import { EmptyState } from '@eds/empty-state';
import { Flex } from '@eds/flex';
import { LoadingSpinner } from '@eds/loading';
import { css } from '@emotion/css';
import { get } from 'lodash';

import { cssOverrides } from 'src/features/ui';

import { DownloadData } from './downloads-list.queries';
import { defaultTableColumns, tableColumnsWithScheduleName } from './downloads-list.hooks';

type DownloadsListTableProps = {
  displayScheduleName: boolean;
  downloads: DownloadData[];
  loading: boolean;
  onSort: (sort: ColumnSort) => void;
  sortData: ColumnSort;
};

const columnSettings = {
  created: { width: '20%' },
  expires: { width: '20%' },
  status: { width: '7rem' },
  action: { width: '3.5rem' },
};

const badgeTone: Record<DownloadData['status'], BadgeProps['tone']> = {
  Failed: 'critical',
  Processing: 'caution',
  Pending: 'neutral',
  Ready: 'positive',
};

export const DownloadsListTable = ({
  displayScheduleName,
  downloads,
  loading,
  onSort,
  sortData,
}: DownloadsListTableProps) => {
  const tableColumns = displayScheduleName ? tableColumnsWithScheduleName : defaultTableColumns;

  return (
    <Box className={css({ ...cssOverrides.theadButton(), ...cssOverrides.tableScrollWrapButton() })}>
      <Table columnSettings={columnSettings} minWidth="50rem" tableBorder truncate width="100%">
        <TableHead>
          <TableRow>
            {tableColumns.map(({ columnName, label }) => (
              <TableHeaderCell key={columnName} columnName={columnName} onSortChange={onSort} sort={sortData}>
                {label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell columnName="action">
              <></>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {downloads.length > 0 ? (
            downloads.map(item => (
              <TableRow key={item.id}>
                {tableColumns.map(({ columnName, format, key }) => (
                  <TableCell key={`${item.id}-${columnName}`} columnName={columnName}>
                    {columnName === 'status' ? (
                      <Flex>
                        <Badge
                          tone={badgeTone[item.status] || 'neutral'}
                          label={format(item.status)}
                          weight={['Failed', 'Ready'].includes(item.status) ? 'bold' : 'subtle'}
                        />
                      </Flex>
                    ) : (
                      format(get(item, key, ''))
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  <Flex padding={item.status === 'Ready' ? 'none' : 'medium'}>
                    {item.status === 'Ready' && (
                      <ButtonLink
                        href={`/api/users/${item.userId}/reports/${item.jobKey}/download`}
                        iconOnly
                        icon={DownloadIcon}
                        label="Download"
                        size="small"
                        target="_blank"
                        tone="ghost"
                      />
                    )}
                  </Flex>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={displayScheduleName ? 6 : 5}>
                <Box marginTop="large" marginBottom="large">
                  {loading ? (
                    <Flex justifyContent="center">
                      <LoadingSpinner />
                    </Flex>
                  ) : (
                    <EmptyState
                      description="Try a different search value."
                      spotIllustration={EmptyFolderSpot}
                      title="No results found"
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
