import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const CollapseReportBuilderClosedIcon = ({
  width = 20,
  height = 21
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_391_24500)">
        <path
          d="M10.491 6.17555L13.4743 9.16722L1.66602 9.16722L1.66602 10.8339L13.4743 10.8339L10.4827
          13.8255L11.666 15.0006L16.666 10.0006L11.666 5.00055L10.491 6.17555ZM17.4993 5.00055L17.4993
          15.0006L19.166 15.0006L19.166 5.00055L17.4993 5.00055Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_391_24500">
          <rect width={width} height={height} fill="white" transform="translate(20 20.0005) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};
