import { Dimension } from '../types/dimensionModel';
import { Module } from '../types/moduleModel';
import { Measure } from '../types/measureModel';
import { ColumnMetaQuery, Query } from '../types/queryModel';
import { Join } from '../types/joinModel';
import { GroupBy } from '../types/groupByModel';
import { View } from '../types/viewModel';
import { ColumnMeta } from '../types/columnMetaModel';
import { findDimensionByName, findMeasureByName, getViewJoinAndDimensionOrMeasureObjs } from './viewService';
import { GroupByForQuery, memoizeApiGetModules } from './reportService';
import { getResetState } from '../reducers';
import { loadQuerySuccess } from '../actions/savedQueries';
import { addJoinDimension, addJoinMeasure } from '../actions/joinColumn';
import { AppState, SuggestedReportState } from '../redux';
import { addGroupBy } from '../actions/groupBy';
import { measuresReducer } from '../reducers/measures';
import { SavedQuery } from '../types/savedQueryModel';
import { Dispatch } from 'redux';
import { dimensionsReducer } from '../reducers/dimensions';
import { addDimension } from '../actions/dimension';
import { joinDimensionsReducer } from '../reducers/joinDimensions';
import { joinMeasuresReducer } from '../reducers/joinMeasures';
import { memoizeApiGetViews } from './viewApiService';
import { addMeasure } from '../actions/measure';
import { hydrateStore } from '../actions/report';
import {
  CHART_TYPE,
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES,
  MODULE_TYPES,
  NOTIFICATION_ERROR,
  SOURCE_WIZARD,
} from '../constants';
import { find, isNil } from 'lodash';
import { Chart } from '../types/chartModel';
import { columnMetaYCompactToColumnMeta, columnMetaXCompactToColumnMeta } from './columnMetaService';
import { NotificationService } from './notificationService';
import { QueryError } from '../types/errors/QueryError';
import { groupBysReducer } from '../reducers/groupBys';
import { AuthService } from './authService';

/**
 * The minimal Query object is what is sent and retrieved from the backend.
 * This service gets the objects for the parts of the Query.
 */
export class QueryLoaderService {
  async loadQuery(
    savedQuery: SavedQuery,
    chart: Chart | null,
    onError: (error: string) => void,
    stateModules?: Module[],
  ) {
    let queryData = JSON.parse(savedQuery.query);
    let query: Query = new Query();
    Object.assign(query, queryData);

    let newAppState: AppState = getResetState();
    const moduleName: string = query.module;

    let modules: Module[] = [];
    let moduleViews: View[] = [];
    let translations: any | null = null;
    let module: Module | null = null;

    const getModulesAndViewsPromises: Promise<any>[] = [];

    let getModulesPromise: Promise<any>;

    if (stateModules) {
      getModulesPromise = Promise.resolve(stateModules);
    } else {
      // add a request to get modules
      getModulesPromise = memoizeApiGetModules(savedQuery.id);
    }

    getModulesPromise.then((m: Module[]) => {
      modules = m;
      // find the module object with the name specified in the query
      const foundModule = find(modules, {name: moduleName});
      if (foundModule) {
        module = foundModule;
      }
      return m;
    });

    getModulesAndViewsPromises.push(getModulesPromise);

    const countries = savedQuery.countries;
    const reportId = savedQuery.id;
    // add a request to get views
    const getViewsPromise = memoizeApiGetViews(moduleName, countries, reportId).then((v: View[]) => {
      moduleViews = v['views'];
      translations = v['translations'];
      return v;
    });

    getModulesAndViewsPromises.push(getViewsPromise);

    // wait for modules and views to come back before continuing
    await Promise.all(getModulesAndViewsPromises);

    // @todo: We commented the check below as part of the CRT-3263
    // The backend will be process situation when module is empty
    // if (!module) {
    //   onError('Could not find module with name ' + moduleName);
    //   return null;
    // }

    let selectedView = this.getView(query, moduleViews);
    if (!selectedView) {
      onError('Could not load selected view');
      return null;
    }

    if (query.dataType !== DATA_TYPE_DIMENSIONS && query.dataType !== DATA_TYPE_MEASURES) {
      onError('Invalid data type');
      return null;
    }

    if (module) {
      newAppState.module = module;
    } else {
      newAppState.module = {
        id: '',
        canImport: false,
        name: query.module,
        label: '',
        type: query.module.includes('_v2') ? MODULE_TYPES.redshift : MODULE_TYPES.tms
      }
    }

    newAppState.dataType = query.dataType;
    newAppState.view = selectedView;
    newAppState.views = moduleViews;
    newAppState.translations = translations;
    newAppState.orderBys = query.orderBys;
    this.getDimensions(query, selectedView).forEach((dimension: Dimension) => {
      newAppState.dimensions = dimensionsReducer(
        newAppState.dimensions,
        addDimension(dimension, selectedView as View, SOURCE_WIZARD));
    });

    this.getJoinDimensions(query, selectedView.joins, newAppState.views).forEach((result) => {
      newAppState.joinDimensions = joinDimensionsReducer(
        newAppState.joinDimensions,
        addJoinDimension(
          result.join,
          result.dimension,
          selectedView as View,
          SOURCE_WIZARD));
    });

    this.getMeasures(query, selectedView).forEach((measure: Measure) => {
      newAppState.measures = measuresReducer(
        newAppState.measures,
        addMeasure(measure, selectedView as View, SOURCE_WIZARD));
    });

    // get the join measures and add them
    this.getJoinMeasures(query, selectedView.joins, newAppState.views)
      .forEach((joinAndMeasure) => {
        newAppState.joinMeasures = joinMeasuresReducer(
          newAppState.joinMeasures,
          addJoinMeasure(
            joinAndMeasure.join,
            joinAndMeasure.measure,
            selectedView as View,
            SOURCE_WIZARD));
      });

    this.getGroupBys(query, newAppState.views).forEach((groupBy: GroupBy) => {
      newAppState.groupBys = groupBysReducer(
        newAppState.groupBys,
        addGroupBy(groupBy, selectedView as View, SOURCE_WIZARD));
    });

    // load the columnmetas
    newAppState.columnMeta = this.getColumnMetas(
      query,
      selectedView,
      newAppState.views
    );

    // display the filters
    newAppState.filterOptions = getViewJoinAndDimensionOrMeasureObjs(
      newAppState.dimensions,
      newAppState.joinDimensions,
      newAppState.measures,
      newAppState.joinMeasures,
      newAppState.view,
      newAppState.views,
      newAppState.groupBys);

    // push the filters to appState (not for display)
    newAppState.filters = query.filters;
    newAppState.filterGroups = query.filterGroups;

    const enableAndOrFilter = AuthService.getInstance().enableAndOrFilter();

    if (
      enableAndOrFilter &&
      newAppState.module.type === MODULE_TYPES.redshift &&
      newAppState.dataType === DATA_TYPE_DIMENSIONS &&
      newAppState.filterGroups.length === 0 &&
      newAppState.filters.length > 0
    ) {
      // NOTE: For existing reports that are not yet using filterGroups, move their existing filters to filterGroups
      newAppState.filterGroups = [newAppState.filters];
      newAppState.filters = [];
    }

    // Push the reportTitle
    newAppState.reportTitle = savedQuery.name;

    if (chart) {
      chart.saved = true;
      if (chart.selectedXCompact) {
        chart.selectedX
          = columnMetaXCompactToColumnMeta(chart.selectedXCompact, newAppState.columnMeta) as ColumnMeta[];
      }

      if (chart.selectedYCompact) {
        chart.selectedY
          = columnMetaYCompactToColumnMeta(chart.selectedYCompact, newAppState.columnMeta) as ColumnMeta;
      }
      newAppState.chart = chart;
    }

    if (!newAppState.chart.chartId) {
      // Push the chart data if there is an associated chart
      let chartFromQuery = this.getChartFromQuery(savedQuery, newAppState.columnMeta);
      if (chartFromQuery) {
        newAppState.chart = chartFromQuery;
      }
    }

    newAppState.savedQuery = savedQuery;
    
    newAppState.transformations = query.transformations || [];

    let { customColumns , labelAliases = {} } = query;

    if (!customColumns) {
      return { ...newAppState, customColumns: [] };
    }
    
    customColumns = customColumns.map(customColumn => {
      const matchingId = ({ dimensionName }: ColumnMetaQuery) => customColumn.id === dimensionName;
      const columnMetaQuery = query.columnOrder.find(matchingId);

      if (!columnMetaQuery) {
        return customColumn;
      }

      const { baseViewName, joinName } = columnMetaQuery;
      const alias = labelAliases[`${joinName || baseViewName}.${customColumn.id}`];

      return { ...customColumn, joinName, alias, index: query.columnOrder.findIndex(matchingId)};
    });

    return { ...newAppState, customColumns };
  }

  /**
   * Loads query into the appstate by running multiple dispatches
   */
  async loadQueryIntoWidget(savedQuery: SavedQuery, chart: Chart | null) {
    return this.loadQuery(
      savedQuery,
      chart,
      (error: string) => {
        throw new QueryError(error);
      });
  }

  /**
   * Loads query into the appstate by running multiple dispatches
   * @param savedQuery
   * @param {Dispatch<any>} dispatch
   * @param {() => AppState} getState
   */
  async loadQueryIntoAppState(savedQuery: SavedQuery,
                              dispatch: Dispatch<any>,
                              getState: () => AppState,
                              isReportSuggestion?: boolean): Promise<void | SavedQuery> {
    let appState: AppState = getState();
    let existingSuggestionsState: SuggestedReportState = appState.suggestedReports;
    let notificationService = appState.notificationService ?
      appState.notificationService : new NotificationService(dispatch);
    const stateModules = isReportSuggestion ? appState.modules : undefined;

    let newAppState = await this.loadQuery(
      savedQuery,
      null,
      (error: string) => {
        notificationService.addNotification(
          NOTIFICATION_ERROR,
          error);
      },
      stateModules,
    );

    if (!newAppState) {
      return;
    }

    newAppState.suggestedReports = existingSuggestionsState; // don't reset suggestions

    dispatch(hydrateStore(newAppState));
    dispatch(loadQuerySuccess(savedQuery));

    return savedQuery;
  }

  /**
   * Get View object
   * @param query
   * @param {View[]} availableViews
   * @returns {View | undefined}
   */
  protected getView(query: Query, availableViews: View[]): View | undefined {
    return availableViews.find((view: View) => {
      return view.name === query.view;
    });
  }

  /**
   * Get Join objects from the join names
   * @param query
   * @param {Join[]} availableJoins
   * @returns {Join[]}
   */
  protected getJoins(query: Query, availableJoins: Join[]): Join[] {
    return query.joins.map((joinName: string) => {
      return availableJoins.find((join: Join) => {
        return join.name === joinName;
      });
    }).filter((join: Join) => {
      return !isNil(join);
    }) as Join[];
  }

  /**
   * Get Measure objects from the measure names
   * @returns {Measure[]}
   * @param query
   * @param view
   */
  protected getMeasures(query: Query, view: View): Measure[] {
    return query.measures.map((measureName: string) => {
      return findMeasureByName(view, measureName);
    }).filter((measure: Measure) => {
      return !isNil(measure);
    }) as Measure[];
  }

  /**
   * Get Join and Measure objects from the joinMeasures
   * @param query
   * @param {Join[]} availableJoins
   * @param {View[]} availableViews
   * @returns {Array<{join: Join; measure: Measure}>}
   */
  protected getJoinMeasures(query: Query, availableJoins: Join[], availableViews: View[]):
    Array<{ join: Join, measure: Measure }> {

    let results: Array<{ join: Join, measure: Measure }> = [];
    query.joinMeasures.forEach((join: { [joinName: string]: Measure[] }) => {
      Object.keys(join).forEach((joinName: string) => {

        let selectedMeasures = join[joinName];

        let foundJoinAndView = this.getJoinAndViewForJoinPath(joinName, availableJoins, availableViews);
        let foundView = foundJoinAndView.view;
        let foundJoin = foundJoinAndView.join;

        // iterate through all the selected join dimensions
        selectedMeasures.forEach((joinMeasure: Measure) => {
          if (!foundView) {
            return;
          }

          let foundJoinMeasure = foundView.measures.find((m: Measure) => {
            return m.name === joinMeasure.name;
          });

          if (foundJoin && foundJoinMeasure) {
            results.push({
              join: foundJoin,
              measure: foundJoinMeasure
            });
          }
        });
      });
    });

    return results;
  }

  /**
   * For the joinPath, get the join and view for it
   * For example, for a joinPath user.user_properties,
   * the join returned will be user_properties and its corresponding view
   *
   * @param {string} joinPathString
   * @param {Join[]} availableJoins
   * @param {View[]} availableViews
   * @returns {{join: Join; view: View}}
   */
  protected getJoinAndViewForJoinPath(joinPathString: string, availableJoins: Join[], availableViews: View[]) {
    let joinPath: Join[] = this.getJoinsForJoinPath(joinPathString, availableJoins, availableViews);

    let foundJoinOriginal = joinPath[joinPath.length - 1];
    let foundJoin = new Join();
    Object.assign(foundJoin, foundJoinOriginal);

    foundJoin.parents = (joinPath.length > 1) ? joinPath.slice(0, joinPath.length - 1) : [];

    let foundView = availableViews.find((view: View) => {
      return view.name === foundJoin.viewName;
    });

    return {
      join: foundJoin,
      view: foundView
    };
  }

  /**
   * Get GroupBy objects
   * @param query
   * @param {View[]} availableViews
   * @returns {GroupBy[]}
   */
  protected getGroupBys(query: Query, availableViews: View[]): GroupBy[] {
    return query.groupBys.map((groupBy: GroupByForQuery) => {
      let groupByObject = this.getGroupByObjectFromGroupByForQuery(groupBy, availableViews);
      return groupByObject ? groupByObject : null;
    }).filter((groupBy: GroupBy) => {
      return groupBy !== null;
    }) as GroupBy[];
  }

  /**
   * Creates a GroupBy object from a GroupByForQuery object
   * @param {GroupByForQuery} groupBy
   * @param views
   */
  protected getGroupByObjectFromGroupByForQuery(groupBy: GroupByForQuery, views: View[]): GroupBy | null {
    // look for the base view for the groupBy
    let baseView = views.find((v: View) => {
      return v.name === groupBy.viewName;
    });

    if (!baseView) {
      return null;
    }

    let viewContainingDimensions: View | undefined = baseView;

    let join = null;
    // look for the join for the groupBy. This must be the join with parents and fullJoinName as it is used to
    // build the report.
    if (groupBy.joinName) {
      ({join} = this.getJoinAndViewForJoinPath(groupBy.joinName, baseView.joins, views));
      if (join) {
        viewContainingDimensions = find(views, {name: join.viewName});
      }
    }

    // look for the view with the dimensions for the query

    // look for the dimension for the groupBy
    if (!viewContainingDimensions) {
      return null;
    }

    let dimension = viewContainingDimensions.dimensions.find((d: Dimension) => {
      return d.name === groupBy.dimensionName && d.label === groupBy.dimensionLabel;
    });

    if (!dimension) {
      return null;
    }

    let groupByObject: GroupBy = {
      view: baseView,
      dimension: dimension,
      name: dimension.name,
      label: dimension.label
    };

    if (join) { // not all GroupBys are for a join table, so not all GroupBys will have a join property set
      groupByObject.join = join;
    }

    return groupByObject;
  }

  /**
   * Get Join and Dimension objects from joinDimensions
   * @param query
   * @param {Join[]} availableJoins
   * @param {View[]} availableViews
   * @returns {Array<{join: Join; dimension: Dimension}>}
   */
  protected getJoinDimensions(query: Query,
                              availableJoins: Join[],
                              availableViews: View[]): Array<{ join: Join, dimension: Dimension }> {
    let result: Array<{ join: Join, dimension: Dimension }> = [];

    query.joinDimensions.forEach((join: { [joinName: string]: Dimension[] }) => {
      Object.keys(join).forEach((joinName: string) => {
        let selectedDimensions = join[joinName];

        let foundJoinAndView = this.getJoinAndViewForJoinPath(joinName, availableJoins, availableViews);
        let foundView = foundJoinAndView.view;
        let foundJoin = foundJoinAndView.join;

        // iterate through all the selected join dimensions
        selectedDimensions.forEach((joinDimension: Dimension) => {
          if (!foundView) {
            return;
          }

          let foundJoinDimension = foundView.dimensions.find((d: Dimension) => {
            return d.name === joinDimension.name;
          });

          if (foundJoin && foundJoinDimension) {
            result.push({
              join: foundJoin,
              dimension: foundJoinDimension
            });
          }
        });
      });
    });

    return result;
  }

  /**
   * Get the joins involved in a join path name
   * For example, for user.user_properties, an array with the User join and User_properties join will be returned
   * @param {string} joinPath
   * @param joinsToSearch
   * @param {View[]} availableViews
   * @returns {Join[]}
   */
  protected getJoinsForJoinPath(joinPath: string, joinsToSearch: Join[], availableViews: View[]): Join[] {
    let joinPathArray = joinPath.split('.');

    return joinPathArray.map((joinName: string) => {
      let found = joinsToSearch.find(
        (availableJoin: Join) => {
          return availableJoin.name === joinName;
        });

      if (!found) {
        return new Join();
      }

      let joinView = availableViews.find((view: View) => {
        return !!found && (view.name === found.viewName);
      });

      joinsToSearch = joinView ? joinView.joins : [];
      return found;
    });
  }

  /**
   * Get Dimensions objects from dimension names
   * @param query
   * @param {View} selectedView
   * @returns {Dimension[]}
   */
  protected getDimensions(query: Query, selectedView: View): Dimension[] {
    return query.dimensions.map((dimensionName: string) => {
      return findDimensionByName(selectedView, dimensionName);
    }).filter((dimension) => {
      return !isNil(dimension);
    }) as Dimension[];
  }

  /**
   * Get columnMetas from the query's columnOrder
   * @param {Query} query
   * @param {View} baseView
   * @param {View[]} views
   * @returns {ColumnMeta[]}
   */
  protected getColumnMetas(query: Query,
                           baseView: View,
                           views: View[]): ColumnMeta[] {
    return query.columnOrder.map((c: ColumnMetaQuery) => {
      return this.columnMetaQueryToColumnMeta(
        c,
        baseView,
        views,
        query.labelAliases);
    }).filter((columnMeta: ColumnMeta | null) => {
      return !isNil(columnMeta);
    }) as ColumnMeta[];
  }

  /**
   * Converts saved query's columnMeta to a ColumnMeta object
   *
   * @param {ColumnMetaQuery} columnMetaQuery
   * @param {View} baseView
   * @param {View[]} views
   * @returns {ColumnMeta | null}
   */
  protected columnMetaQueryToColumnMeta(columnMetaQuery: ColumnMetaQuery,
                                        baseView: View,
                                        views: View[],
                                        labelAliases: Query['labelAliases']): ColumnMeta | null {
    let columnMeta: ColumnMeta = {
      view: baseView,
      forGroupBy: columnMetaQuery.forGroupBy
    };

    let viewToSearch = baseView;

    if (columnMetaQuery.joinName) {
      // look for the join and view based on the join name
      let {join: foundJoin, view: foundView} = this.getJoinAndViewForJoinPath(
        columnMetaQuery.joinName,
        baseView.joins,
        views);

      if (!foundJoin || !foundView) {
        return null;
      }

      columnMeta.join = foundJoin;

      viewToSearch = foundView;
    }

    // look for the dimension/measure
    if (columnMetaQuery.dimensionName) {
      let foundDimension = findDimensionByName(viewToSearch, columnMetaQuery.dimensionName);
      if (foundDimension) {
        columnMeta.dimension = foundDimension;
      }
    } else if (columnMetaQuery.measureName) {
      let foundMeasure = findMeasureByName(viewToSearch, columnMetaQuery.measureName);
      if (foundMeasure) {
        columnMeta.measure = foundMeasure;
      }
    }

    // if no dimension/measure was found, return null.
    if (!columnMeta.dimension && !columnMeta.measure) {
      return null;
    }

    if (!labelAliases) {
      return columnMeta;
    }

    const leftKey = columnMetaQuery.joinName || columnMetaQuery.baseViewName;
    const rightKey = columnMetaQuery.dimensionName || columnMetaQuery.measureName;

    columnMeta.alias = labelAliases[`${leftKey}.${rightKey}`];

    return columnMeta;
  }

  dispatchLoadQueryIntoAppState(savedQuery: SavedQuery, isReportSuggestion?: boolean):
    (dispatch: Dispatch<any>, getState: () => AppState) => Promise<void | SavedQuery> {
    return (dispatch, getState) => {
      return this.loadQueryIntoAppState(savedQuery, dispatch, getState, isReportSuggestion);
    };
  }

  getChartFromQuery(savedQuery: SavedQuery, columnMeta: ColumnMeta[]): Chart | undefined {
    let chart;
    if (savedQuery.charts && savedQuery.charts.length > 0) {
      // Note: The current assumption is that a query only has one chart
      let savedChart: Chart = savedQuery.charts[0];
      chart = Object.assign(new Chart(), savedChart);
      chart.type = savedChart.displayType as CHART_TYPE;
      chart.queryId = savedQuery.id;
      chart.saved = true;
      if (savedChart.selectedXCompact) {
        chart.selectedX
          = columnMetaXCompactToColumnMeta(savedChart.selectedXCompact, columnMeta) as ColumnMeta[];
      }

      if (savedChart.selectedYCompact) {
        chart.selectedY
          = columnMetaYCompactToColumnMeta(savedChart.selectedYCompact, columnMeta) as ColumnMeta;
      }
    }
    return chart;
  }
}
