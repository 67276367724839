import { Dispatch } from 'redux';
import { doCancel } from '../actions/report';
import { queueCsv } from '../services/reportService';

export class ExportService {

  static handleDownload =
      (dispatch: Dispatch<any>, query: string, reportTitle: string, queryId: string, jobId?: number) => {
    if (!query) {
      return;
    }

    // Cancel any API calls prior to export eg GET /report
    dispatch(doCancel('Report queued for export.'));

    return queueCsv(query, reportTitle, queryId, jobId);
  }
}
