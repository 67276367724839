import React from 'react';
import { createIcon } from '@eds/icon';

export const TextFieldIcon = createIcon(
  <>
    <path d="M12.9999 3V5L10.9999 5L10.9999 18.6199H12.9999V20.6199L6.99988 20.6199V18.6199H8.99988L8.99988 5L6.99988 5V3H12.9999Z" />
    <path d="M6.04959 6H2.00001L2 18L6.04957 18V16L3.99989 16L3.99989 8L6.04959 8V6Z" />
    <path d="M21.9998 6H13.9998V8L19.9998 8L19.9998 16L13.9998 16V18L21.9998 18L21.9998 6Z" />
  </>,
  'TextFieldIcon'
);
