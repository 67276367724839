/**
 * The main Report entry point component.
 * This component controls the Report context available to the components in the Report Results component
 */
import * as React from 'react';
import { Dispatch } from 'redux';
import ReportContext from './ReportContext';
import { ReportDetails } from '../../redux';
import { ColumnMeta } from '../../types/columnMetaModel';
import { OrderBy } from '../../types/orderByModel';
import { View } from '../../types/viewModel';
import {
  ReorderFunction, ReportSortFunction
} from '../../containers/Report';
import './style.css';
import { SavedQuery } from '../../types/savedQueryModel';
import { ReportTable } from '../../types/reportTableModel';
import { Query } from '../../types/queryModel';
import { ReportContextProps } from './ReportContextConsumer';
import ReportComponent from './ReportComponent';

export interface ReportProps {
  report: ReportTable;
  query: Query;
  columnMeta: ColumnMeta[];
  views: View[];
  reportTitle: string;

  sort: ReportSortFunction;
  orderBys: OrderBy[];
  reorder: ReorderFunction;
  removeColumn: (columnMeta: ColumnMeta) => void;
  reportDetails: ReportDetails;
  loadedQuery?: SavedQuery;
  readOnly: boolean;
  isDashboardPage?: boolean;
  printView?: boolean;
  renderPagination: () => any;

  dispatch: Dispatch<any>;
}

class Report extends React.Component<ReportProps, ReportContextProps> {

  constructor(props: ReportProps) {
    super(props);

    const {
      columnMeta,
      report,
      views,
      query,
      reportTitle
    } = this.props;

    this.state = {
      columnMeta: columnMeta,
      report: report,
      views: views,
      query: query,
      reportTitle: reportTitle
    };
  }

  componentDidUpdate(prevProps: ReportProps) {
    const nextContextState = this.getContextStateUpdate(prevProps);

    if (Object.keys(nextContextState).length !== 0) {
      this.setState(nextContextState);
    }
  }

  /**
   * Get the next context state with the values from props
   * Does not update the state
   * @param prevProps
   */
  getContextStateUpdate = (prevProps: any) => {
    const props: any = this.props;
    const propsToUpdate = [
      'report',
      'query',
      'columnMeta',
      'views',
      'reportTitle'
    ];

    const newState: any = {};

    // Check if the props have changed, if they have, add it to the new state to be updated
    propsToUpdate.forEach((key: string) => {
      if (prevProps[key] !== props[key]) {
        newState[key] = props[key];
      }
    });

    return newState;
  }

  render() {
    const {
      sort,
      orderBys,
      reorder,
      removeColumn,
      reportDetails,
      loadedQuery,
      reportTitle,
      readOnly,
      printView,
      renderPagination,
      isDashboardPage
    } = this.props;

    return (
      <ReportContext.Provider value={this.state}>
        <ReportComponent
          sort={sort}
          orderBys={orderBys}
          reorder={reorder}
          removeColumn={removeColumn}
          reportDetails={reportDetails}
          loadedQuery={loadedQuery}
          reportTitle={reportTitle}
          readOnly={readOnly}
          printView={printView}
          renderPagination={renderPagination}
          dispatch={this.props.dispatch}
          isDashboardPage={isDashboardPage}
        />
      </ReportContext.Provider>
    );
  }
}

export default Report;
