import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@eds/box';
import { Field } from '@eds/field';
import { SelectInput } from '@eds/select-input';

import { Filter } from '../../../types/filterModel';
import { AppState, ViewJoinAndDimensionOrMeasure } from '../../../redux';
import {
  apiGetFilterValues,
  GetFilterValuesResponseType,
  getColumnName,
  getColumnViewName,
  FilterValueType,
} from '../../../services/filterService';
import { MODULE_TYPES } from '../../../constants';
import { usePopulateOptions } from './hooks';
import numberValidationSchema from './validation';

interface FilterMultiCreatableSelectContainerProps {
  displayFilter: Filter;
  enableNumberValidation: boolean;
  filterIndex: number;
  filterOption: ViewJoinAndDimensionOrMeasure;
  isDisabled: boolean;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
}

const FilterMultiCreatableSelectContainer = (props: FilterMultiCreatableSelectContainerProps) => {
  const { displayFilter, enableNumberValidation, filterIndex, filterOption, saveDisplayFilter, isDisabled } = props;
  const { module } = useSelector((state: AppState) => state);

  const [autocompleteValues, setAutocompleteValues] = useState<GetFilterValuesResponseType>([]);
  const [isAutocompleteValuesLoading, setIsAutocompleteValuesLoading] = useState(false);
  const [autocompleteFilterValue, setAutocompleteFilterValue] = useState('');

  const fieldIdentifier = `${displayFilter.dimensionName}${displayFilter.measureName}${displayFilter.joinName}`;
  
  useEffect(
    () => {
      setAutocompleteValues([]);
      setAutocompleteFilterValue('');
    },
    [fieldIdentifier]
  );

  const onChange = (store?: boolean) => (selectOptions: any) => {
    const filter = { ...displayFilter };
    (filter as any).value = selectOptions;
    saveDisplayFilter(filter, filterIndex, store);
  };

  const getSelectValue = () => {
    const filterValue = displayFilter.value as any;

    if (Array.isArray(filterValue)) {
      return filterValue.map(v => {
        if (v.hasOwnProperty('value') && v.hasOwnProperty('label')) {
          return v;
        }
        // NOTE: not sure how this works, just leave it as is for now
        return { label: v, value: v };
      });
    }

    if (filterValue) {
      return [{ label: filterValue, value: filterValue }];
    }
    
    return [];
  };

  const apiGetFilterAutocompleteValues = async (search: string) => {
    const dimensionName = getColumnName(filterOption);
    const viewName = getColumnViewName(filterOption);

    if (!dimensionName || module.type !== MODULE_TYPES.redshift) {
      return;
    }

    setIsAutocompleteValuesLoading(true);
    setAutocompleteValues([]);
    
    const values = await apiGetFilterValues({
      moduleName: module.name,
      viewName,
      dimensionName,
      search
    });

    let createdOption: FilterValueType | null = { label: search, value: search };
    
    if (enableNumberValidation && !numberValidationSchema.isValidSync(search)) {
      createdOption = null;
    }

    values.forEach(opt => {
      if (opt.value.localeCompare(search, undefined, { sensitivity: 'accent' }) === 0) {
        createdOption = null;
      }
    });

    setAutocompleteValues([...values, createdOption].filter(Boolean) as Array<FilterValueType>);
    setIsAutocompleteValuesLoading(false);
  };

  const handleAutocompleteInputChange = (val: string) => {
    if (val && val !== autocompleteFilterValue) {
      apiGetFilterAutocompleteValues(val);
      setAutocompleteFilterValue(val);
    }
  };

  const isRedshiftModule = module.type === MODULE_TYPES.redshift;
  const populateOptions = usePopulateOptions();
  const options: any = isRedshiftModule ? autocompleteValues : populateOptions(filterOption);
  const value = getSelectValue();
  const hasTranslations = populateOptions(filterOption).length > 0;

  return (
    <>
      <Box flexBasis="100%" display={!hasTranslations ? 'none' : undefined}>
        <Field label="Value" keepSpaceForMessage={false}>
          <SelectInput
            disabled={isDisabled}
            isClearable={false}
            isMulti={true}
            items={options}
            onChangeMulti={onChange()}
            size="small"
            value={value}
          />
        </Field>
      </Box>
      <Box flexBasis="100%" display={hasTranslations ? 'none' : undefined}>
        <Field label="Value" keepSpaceForMessage={false}>
          <SelectInput
            disabled={isDisabled}
            isClearable={false}
            isLoading={isAutocompleteValuesLoading}
            isMulti={true}
            items={autocompleteValues}
            onChangeMulti={onChange()}
            onInputChange={
              isRedshiftModule
                ? handleAutocompleteInputChange
                : (val: string) => {
                    val ? setAutocompleteValues([{ label: val, value: val }]) : setAutocompleteValues([]);
                  }
            }
            placeholder={enableNumberValidation ? 'Enter a number' : 'Enter a value'}
            size="small"
            value={value}
          />
        </Field>
      </Box>
    </>
  );
};

export default FilterMultiCreatableSelectContainer;