import * as React from 'react';
import classnames from 'classnames';
import { Select } from 'elmo-elements';
import { SelectProps } from 'elmo-elements/Select';
import './style.css';

const ElmoSelect = ({ children, className, ...props }: SelectProps) => {
  return (
    <Select className={classnames('elmo-select--override', className)} {...props}>{children}</Select>
  );
};

export default ElmoSelect;
