import { GroupBy } from '../types/groupByModel';
import { AppState, JoinAndDimensions, JoinAndMeasures } from '../redux';
import {
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES, GENERATE_REPORT_CANCELED,
  GENERATE_REPORT_POLL_DELAY, GENERATE_WIDGET_POLL_DELAY,
  MODULE_TYPES,
  ORDER_ASC,
  ORDER_DESC
} from '../constants';
import { View } from '../types/viewModel';
import { SavedQuery } from '../types/savedQueryModel';
import { QueryError } from '../types/errors/QueryError';
import { Dispatch } from 'redux';
import { Join } from '../types/joinModel';
import { ColumnMetaQuery, Query } from '../types/queryModel';
import { storeOrderBy } from '../actions/orderBys';
import { ColumnMeta } from '../types/columnMetaModel';
import { OrderBy } from '../types/orderByModel';
import { getReportJson } from '../actions/report';
import * as _ from 'lodash';
import axios, { AxiosResponse, CancelTokenSource, AxiosRequestConfig } from 'axios';
import { map } from 'lodash';
import { Module } from '../types/moduleModel';
import { QueryForApi } from '../redux';
import { storeModules } from '../actions/module';
import { ReportTable } from '../types/reportTableModel';
import memoize from 'memoizee';
import { sleep } from './utilityService';
import {JOB_STATUS_CANCELED, JOB_STATUS_SUCCESS} from '../constants/jobStatus';
import { ReportListingPaginationDataType, ReportListingTableSortData } from '../containers/ReportListingPage';
import { ReportListingFilterData } from '../components/ReportListingFilter';
import { SOURCE_TYPE_DASHBOARD } from '../constants/dashboard';
import { Dashboard as DashboardModel } from '../types/dashboardModel';
import { ScheduledQuery } from '../types/scheduler/scheduledQueryModel';
import { CustomColumn, CustomColumnType } from '../types/customColumnModel';
import { AuthService } from './authService';
import { bytesToBase64 } from 'src/features/utils';

export interface GroupByForQuery {
  viewName: string;
  joinName?: string;
  dimensionName: string;
  dimensionLabel: string;
}

export type SavedReports = {
    queries: SavedQuery[],
    count: number
};

/**
 * Get report result for query
 * @param encodedQuery
 * @param query
 *
 */
export async function apiGetReportWithJobId(
    encodedQuery: string,
    query: Query,
    reportId?: string): Promise<ReportTable> {

  // If there is no cached data, get fresh data
  const jobId = await apiQueueFreshReport(encodedQuery, query.limit, query.offset, '', undefined, reportId);
  const reportData = new ReportTable();
  reportData.setJobId(jobId);

  return reportData;
}

/**
 * Get report result for query
 * @param query
 * @param limit
 * @param offset
 * @param cancelTokenSource
 * @param noCache
 */
export async function apiGetReport(query: string,
                                   limit: number,
                                   offset: number,
                                   cancelTokenSource?: CancelTokenSource
                                   ): Promise<ReportTable> {
  // If request for cache data, try getting it
  // if (!noCache) {
  //   const cachedData = await apiGetCachedReport(query, limit, offset, cancelTokenSource);
  //
  //   if (cachedData) {
  //     return cachedData;
  //   }
  // }

  // If there is no cached data, get fresh data
  const jobId = await apiQueueFreshReport(query, limit, offset);
  // @ts-ignore
  return pollGenerateReportStatus(jobId);
}

export type ReportRelationsResponsePayloadType = {
  dashboards: DashboardModel[];
  schedules: ScheduledQuery[];
};

export async function apiGetReportRelations(queryId: string): Promise<ReportRelationsResponsePayloadType> {
  const response = await axios.get(`/api/queries/${queryId}/relations `);

  const responseData = response.data;
  return responseData;
}

export async function apiGetReportForDashboard(
    query: string,
    limit: number,
    offset: number,
    dispatch: Dispatch,
    reportId: string,
    widgetId?: string,
): Promise<ReportTable> {

  const jobId = await apiQueueFreshReport(query, limit, offset , SOURCE_TYPE_DASHBOARD, widgetId, reportId);
  //@ts-ignore
  return await dispatch(pollGenerateReportStatus(jobId, reportId));
}

/**
 * Poll for the completion status of the fresh job with jobId. If the job is complete,
 * the result will be returned by the endpoint as well. This function should be used with apiQueueFreshReport
 * @param jobId
 */
export function pollGenerateReportStatus(jobId: number, queryId: string = '') {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    let shouldPoll = true;
    // Store the URL of the page where the Generate Report was clicked. If the user navigates away, stop polling.
    const triggerUrl = window.location.href;
    return new Promise<ReportTable>(async (resolve, reject) => {
      try {
        while (shouldPoll) {
          // Get the current state of the property (generateReportStatus) from Redux
          const generateReportStatus = getState().generateReportStatus;
          if (generateReportStatus === GENERATE_REPORT_CANCELED) {
            shouldPoll = false;
            resolve(new ReportTable().setJobId(jobId));
            break;
          }

          // If the user navigates away, stop polling.
          if (triggerUrl !== window.location.href) {
            shouldPoll = false;
            break;
          }

          const queueResult = await apiGetQueuedReportStatus(jobId, queryId);
          if (queueResult.status === JOB_STATUS_SUCCESS && queueResult.reportTable) {
            shouldPoll = false;
            queueResult.reportTable.setJobId(jobId);
            resolve(queueResult.reportTable);
          } else if (queueResult.status === JOB_STATUS_CANCELED) {
            shouldPoll = false;
            resolve(new ReportTable().setJobId(jobId));
          }
          const pollDelay = queryId ? GENERATE_WIDGET_POLL_DELAY : GENERATE_REPORT_POLL_DELAY;
          await sleep(pollDelay);
        }
      } catch (e) {
        reject(e);
      }
    });
  };
}

/**
 * Generate a fresh report by queueing it as a job.
 * Returns the jobId, not the report results. This function should be used together with pollGenerateReportStatus.
 * @param query
 * @param limit
 * @param offset
 */
export async function apiQueueFreshReport(query: string,
                                          limit: number,
                                          offset: number,
                                          source: string = '',
                                          widgetId?: string,
                                          reportId?: string,
                                          ) {
  const response = await axios.post(
    '/api/report/queue',
    { query, source, widgetId, reportId },
    {
      headers: {
        'x-limit': limit,
        'x-offset': offset
      }
    }
  );

  return response.data.jobId;
}

export async function apiGetQueuedReportStatus(jobId: number , queryId: string = '') {
  let params = {};
  if (queryId) {
    params = { params: { queryId } };
  }
  const response = await axios.get('/api/report/' + jobId + '/status', params );

  const responseData = response.data;
  const reportTable = responseData.result ?
    new ReportTable(responseData.result, undefined, responseData.result.links) : null;

  return {
    status: responseData.status,
    progress: responseData.progress,
    reportTable: reportTable
  };
}

export async function apiGetCachedReport(queryId: string) {
  // Look for a cached report instead of generating a report
  const response = await axios.post(
    '/api/report/cached',
    { queryId }
  );

  // Successful request
  if (response.status === 200) {
    return new ReportTable(response.data, response.headers['x-report-cached-date'], response.data.links);
  }

  return null;
}

export async function apiQueueCsv(query: string, reportTitle: string, queryId: string, jobId?: number):
    Promise<string | null> {
  try {
    let response: AxiosResponse = await axios.post(
      '/api/csv',
      {
        query: query,
        reportTitle: reportTitle,
        queryId: queryId,
        jobId: jobId
      },
      {
        headers: {
          'Accept': 'text/csv'
        }
      });
    return response.data;
  } catch (error) {
    return null;
  }
}

export function getEncodedQuery(query: Query): string {
  return bytesToBase64(new TextEncoder().encode(JSON.stringify(query)));
}

export function getGroupBysForQuery(groupBys: GroupBy[], baseView: View): GroupByForQuery[] {
  return groupBys.map((groupBy: GroupBy) => {
    return {
      viewName: baseView.name,
      joinName: (groupBy.join) ? groupBy.join.fullJoinName : undefined,
      dimensionName: groupBy.dimension.name,
      dimensionLabel: groupBy.dimension.label,
    };
  });
}

export function getNameForQuery(objsWithName: any[]): string[] {
  return objsWithName.map(function(obj: any) { return obj.name; });
}

/**
 * Create the object that will be sent to the server to generate a report
 * @returns {Query}
 */
export function getQuery(appState: QueryForApi, itemsPerPage?: number): Query {
  if (appState.dataType !== DATA_TYPE_DIMENSIONS && appState.dataType !== DATA_TYPE_MEASURES) {
    throw new QueryError('Invalid datatype');
  }

  const columnMetaQueriesWithAliases = columnMetaForQuery(appState.columnMeta, appState.view);

  const columnOrder = columnMetaQueriesWithAliases.map((columnMetaQuery): ColumnMetaQuery => {
    const { alias, ...filteredColumnMetaQuery } = columnMetaQuery;
    return { ...filteredColumnMetaQuery };
  });

  const customColumns: Array<CustomColumn> = appState.customColumns.map(
    ({ joinName, index, alias, ...customColumn }) => customColumn
  );

  if (customColumns.length) {
    const customColumnsColumnOrder = appState.customColumns.map(({ id, type, joinName, index = 0 }) => {
      const columnMetaQuery: ColumnMetaQuery = {
        baseViewName: appState.view.name,
        dimensionName: id
      };

      if (type === CustomColumnType.Dimension && joinName) {
        return { ...columnMetaQuery, joinName, index };
      }

      return { ...columnMetaQuery, index };
    });

    customColumnsColumnOrder.forEach(({ index, ...column }) => columnOrder.splice(index, 0, column));
  }

  let query: Query = new Query();
  Object.assign(query, {
    module: appState.module.name,
    dataType: appState.dataType,
    view: appState.view.name,
    dimensions: getNameForQuery(appState.dimensions),
    joinDimensions: [],
    measures: getNameForQuery(appState.measures),
    joinMeasures: [],
    groupBys: getGroupBysForQuery(appState.groupBys, appState.view),
    filterGroups: appState.filterGroups,
    filters: appState.filters,
    orderBys: appState.orderBys,
    limit: appState.reportLimit,
    offset: appState.offset,
    columnOrder,
    customColumns,
    transformations: appState.transformations
  });

  const enableAndOrFilter = AuthService.getInstance().enableAndOrFilter();

  if (
    enableAndOrFilter &&
    appState.module.type === MODULE_TYPES.redshift &&
    appState.dataType === DATA_TYPE_DIMENSIONS &&
    appState.filterGroups.length === 0 &&
    appState.filters.length > 0
  ) {
    // NOTE: For existing reports that are not yet using filterGroups, move their existing filters to filterGroups
    query.filterGroups = [appState.filters];
    query.filters = [];
  }

  let joins: Join[] = getJoinsForQuery(appState.joinDimensions, appState.joinMeasures, appState.groupBys);
  query.joins = getNameForQuery(joins);

  // NOTE: Due to complex filters functionality, joins must be added respectively
  query.filterGroups.forEach(filterGroup => {
    filterGroup.forEach(({ joinName = '' }) => {
      const baseJoinName = joinName.split('.').shift();
      if (baseJoinName && !query.joins.includes(baseJoinName)) {
        query.joins.push(baseJoinName);
      }
    });
  });

  query.filters.forEach(({ joinName = '' }) => {
    const baseJoinName = joinName.split('.').shift();
    if (baseJoinName && !query.joins.includes(baseJoinName)) {
      query.joins.push(baseJoinName);
    }
  });

  if (appState.dataType === DATA_TYPE_DIMENSIONS) {
    query.joinDimensions = appState.joinDimensions.map((jd: JoinAndDimensions) => {
      return {[jd.join.fullJoinName]: jd.dimensions};
    });
  } else if (appState.dataType === DATA_TYPE_MEASURES) {
    query.joinMeasures = appState.joinMeasures.map((jd: JoinAndMeasures) => {
      return {[jd.join.fullJoinName]: jd.measures};
    });
  }

  const labelAliases = labelAliasesForQuery(columnMetaQueriesWithAliases, appState.view.name, appState.customColumns);

  if (labelAliases && query.dataType === DATA_TYPE_DIMENSIONS) {
    query.labelAliases = labelAliases;
  }

  return query;
}

export function queryHasSelectedColumns(query: Query): boolean {
  let numColumns: number = query.dimensions.length +
    query.joinDimensions.length +
    query.measures.length +
    query.joinMeasures.length;
  return numColumns > 0;
}

/**
 * Get a small version of ColumnMeta to send with the query
 * @param {ColumnMeta[]} columnMetas
 * @param {View} baseView
 * @returns {(ColumnMetaQuery & { alias?: string })[]}
 */
function columnMetaForQuery(columnMetas: ColumnMeta[], baseView: View): (ColumnMetaQuery & { alias?: string })[] {
  return map(columnMetas, (cm: ColumnMeta) => {
    let result: ColumnMetaQuery & { alias?: string } = {
      baseViewName: baseView.name
    };

    if (cm.dimension) {
      result.dimensionName = cm.dimension.name;
    } else if (cm.measure) {
      result.measureName = cm.measure.name;
    }

    if (cm.join) {
      result.joinName = cm.join.fullJoinName;
    }

    if (cm.forGroupBy) {
      result.forGroupBy = cm.forGroupBy;
    }

    if (cm.alias) {
      result.alias = cm.alias;
    }

    return result;
  });
}

function labelAliasesForQuery(
    columnMetaQueriesWithAliases: (ColumnMetaQuery & { alias?: string })[],
    baseViewName: string,
    customColumns: Array<CustomColumn>
  ) {
  const hasAliases =
    columnMetaQueriesWithAliases.some(({ alias }) => !!alias) || customColumns.some(({ alias }) => !!alias);

  if (!hasAliases) {
    return undefined;
  }

  const labelAliases: Record<string, string> = {};

  columnMetaQueriesWithAliases.forEach(({ alias, dimensionName, joinName, measureName }) => {
    if (!alias) {
      return;
    }

    const leftKey = joinName || baseViewName;
    const rightKey = dimensionName || measureName;

    labelAliases[`${leftKey}.${rightKey}`] = alias;
  });

  customColumns.forEach(({ alias, id, joinName }) => {
    if (!alias) {
      return;
    }

    if (joinName) {
      labelAliases[`${joinName}.${id}`] = alias;
    }

    labelAliases[`${baseViewName}.${id}`] = alias;
  });

  return labelAliases;
}

export function getJoinsForQuery(joinDimensions: JoinAndDimensions[],
                                 joinMeasures: JoinAndMeasures[],
                                 groupBys: GroupBy[]) {
  let selectedJoins: Join[] = [];

  joinDimensions.forEach((joinAndDimensions: JoinAndDimensions) => {
    selectedJoins.push(getJoinToAddToQuery(joinAndDimensions.join));
  });

  joinMeasures.forEach((joinAndMeasures: JoinAndMeasures) => {
    selectedJoins.push(getJoinToAddToQuery(joinAndMeasures.join));
  });

  groupBys.forEach((groupBy: GroupBy) => {
    if (!groupBy.join) {
      return;
    }

    selectedJoins.push(getJoinToAddToQuery(groupBy.join));
  });

  return _.uniqBy(selectedJoins, (item: Join) => {
    return item.name;
  });
}

/**
 * Helper function to get the join to add to the query.
 *
 * @param {Join} join
 * @returns {Join}
 */
export function getJoinToAddToQuery(join: Join) {
  // if the join has parents, add the base parent to the query
  if (join.parents && join.parents[0]) {
    return join.parents[0];
  }

  // otherwise add the join itself
  return join;
}

export async function apiSaveQuery(savedQuery: SavedQuery[]): Promise<SavedQuery[]> {
  let response: AxiosResponse = await axios.post('/api/queries', savedQuery);
  return map(response.data, (res: any) => {
    return Object.assign(new SavedQuery(), {
      id: res.id,
      query: res.query,
      name: res.name,
      description: res.description,
      moduleName: res.moduleName,
      userId: res.userId,
      created: res.created.date,
      modified: res.modified.date,
      reportType: res.reportType,
      charts: res.charts,
      sharedBy: res.sharedBy,
      sharedWith: res.sharedWith,
      permissions: res.permissions,
      countries: res.countries,
    });
  });
}

export async function apiGetUserAndSuggestedReports(userId: number,
                                                    tableSortVal: ReportListingTableSortData,
                                                    paginationVal: ReportListingPaginationDataType,
                                                    tableFilterVal: ReportListingFilterData): Promise<SavedReports> {
  let axiosConfig: AxiosRequestConfig = {
    headers: {
      'x-limit': paginationVal.limit,
      'x-offset': paginationVal.offset,
    }
  };

  let startDate = null;
  let endDate = null;
  if (tableFilterVal.startDate != null) {
    startDate = tableFilterVal.startDate.format('YYYY-MM-DD 00:00:00');
  }
  if (tableFilterVal.endDate != null) {
    endDate = tableFilterVal.endDate.format('YYYY-MM-DD 23:59:59');
  }

  const dataToPost = {
      sortByDimensionName: tableSortVal.currentSortProperty,
      sortByDimensionDirection: tableSortVal.currentSortMode,
      reportType: tableFilterVal.selectedReportType,
      searchText : tableFilterVal.searchText,
      startDate: startDate,
      endDate: endDate,
  };

  try {
    let response = await axios.post(
        '/api/users/' + userId + '/queries',
        { data: dataToPost },
        axiosConfig
    );

    if (response.data) {
      return response.data;
    }

  } catch (e) {
    return {
      queries: [],
      count: 0
    };
  }

  return {
    queries: [],
    count: 0
  };
}

export async function apiGetQuery(queryId: string) {
  let response: AxiosResponse = await axios.get('/api/queries/' + queryId);

  let savedQuery: SavedQuery = new SavedQuery();
  Object.assign(savedQuery, response.data);
  return savedQuery;
}

export async function apiDeleteSavedQuery(savedQueryId: string) {
  let response: AxiosResponse = await axios.delete('/api/queries/' + savedQueryId);
  if (response) {
    return response.data;
  }
  return null;
}

/**
 * @returns {Promise<Module[]>}
 */
export async function apiGetModules(reportId?: string): Promise<Module[]> {
  try {
    const headers = {
      'Cache-Control': 'max-age=60'
    };
    let response: AxiosResponse;
    if (reportId) {
      response = await axios.get(`/api/report/${reportId}/modules`, { headers });
    } else {
      response = await axios.get('/api/modules', { headers });
    }

    return map(response.data, (moduleData: any) => {
      return {
        id: moduleData['id'],
        canImport: moduleData['canImport'],
        name: moduleData['name'],
        label: moduleData['label'],
        type: moduleData['type'],
      };
    });
  } catch (e) {
    return [];
  }
}

const memoizeApiGetModules = memoize(apiGetModules, {promise: true, maxAge: 60 * 60 * 1000});
export { memoizeApiGetModules };

export function fetchModules(reportId?: string): (dispatch: Dispatch<any>) => void {
  return async (dispatch) => {
    let modules: Module[] = await memoizeApiGetModules(reportId);
    dispatch(storeModules(modules));
  };
}

/**
 * Is orderBy equal to (i.e. created from) columnMeta
 *
 * @param {OrderBy} orderBy
 * @param {ColumnMeta} columnMeta
 * @returns {boolean}
 */
export function orderByEqualsColumnMeta(orderBy: OrderBy, columnMeta: ColumnMeta): boolean {
  // check if the joins are equal
  let equalJoin: boolean = (!orderBy.joinName && !columnMeta.join) ||
    !!columnMeta.join && (orderBy.joinName === columnMeta.join.fullJoinName);

  // check if the view and dimension / measure are the same
  let equalView: boolean = orderBy.viewName === columnMeta.view.name;

  if (columnMeta.dimension) {
    return equalJoin && equalView && orderBy.dimensionName === columnMeta.dimension.name;
  } else if (columnMeta.measure) {
    return equalJoin && equalView && orderBy.measureName === columnMeta.measure.name;
  }

  return false;
}

// Store an OrderBy object in the state
export function sort(columnMeta: ColumnMeta, orderBys: OrderBy[], reportId?: string)
: (dispatch: Dispatch<any>, getState: () => AppState) => void {
  return (dispatch, getState) => {
    let appState: AppState = getState();
    let orderBy = orderBys.find((ob: OrderBy) => {
      return orderByEqualsColumnMeta(ob, columnMeta);
    });

    if (orderBy) {
      // OrderBy exists, switch the direction
      orderBy.direction = (orderBy.direction === ORDER_ASC) ? ORDER_DESC : ORDER_ASC;
    } else {
      // Create a new OrderBy
      let newOrderBy: OrderBy = {
        dimensionName: (columnMeta.dimension) ? columnMeta.dimension.name : undefined,
        viewName: columnMeta.view.name,
        direction: ORDER_DESC,
        joinName: (columnMeta.join) ? columnMeta.join.fullJoinName : undefined,
        measureName: (columnMeta.measure) ? columnMeta.measure.name : undefined,
      };
      orderBy = newOrderBy;
    }

    dispatch(storeOrderBy(orderBy));

    // There was a timing issue here so it is updated manually
    // Basically storeOrderBy changes the state and that change is needed for the next step
    appState.orderBys = [orderBy];

    // sorting should not change the number of results, so don't run the count request.
    dispatch(getReportJson(false, reportId));
  };
}

export function queueCsv(encodedQuery: string, reportTitle: string, queryId: string, jobId?: number): Promise<boolean> {
  return apiQueueCsv(encodedQuery, reportTitle, queryId, jobId)
    .then((data: string) => {
      if (data === null) {
        return Promise.resolve(false);
      }

      return Promise.resolve(true);
    });
}

export async function apiCancelQueueReport(jobId: number|undefined) {
  return await axios.put(`/api/report/${jobId}/cancel`);
}

export function getQueryForApiByAppState(appState: AppState): QueryForApi {

  return Object.assign({}, {
    module: appState.module,
    dataType: appState.dataType as (DATA_TYPE_MEASURES | DATA_TYPE_DIMENSIONS),
    view: appState.view,
    dimensions: appState.dimensions,
    joinDimensions: appState.joinDimensions,
    measures: appState.measures,
    joinMeasures: appState.joinMeasures,
    groupBys: appState.groupBys,
    filterGroups: appState.filterGroups,
    filters: appState.filters,
    orderBys: appState.orderBys,
    offset: appState.offset,
    columnMeta: appState.columnMeta,
    reportLimit: appState.reportLimit,
    customColumns: appState.customColumns,
    transformations: appState.transformations
  });
}

export type UserCapabilitiesType = {
  dashboard: null;
  report: {
    canCreateReport: boolean;
    canFilterReportsByType: boolean;
    canSelectCountry: boolean;
    canManageCustomColumn: boolean;
    canManageColumnTransformation: boolean;
  }
};
export const defaultUserCapabilities: UserCapabilitiesType = {
  dashboard: null,
  report: {
    canCreateReport: false,
    canFilterReportsByType: false,
    canSelectCountry: false,
    canManageCustomColumn: false,
    canManageColumnTransformation: false
  },
};

export async function apiGetUserCapabilitiesForReport(userId: number): Promise<UserCapabilitiesType> {
  const response = await axios.get(`/api/users/${userId}/capabilities?scope=report`);
  return response.data;
}