export class Join {
  viewName: string;
  label: string;
  name: string;
  inverse: string;
  showUnder: string[];
  hideUnder: string[];
  isExperimental: boolean;
  description: string;
  protected _parents: Join[]; // when nested joins are rendered, they are assigned parent joins which lead to this join
  protected _fullJoinName: string = '';
  protected _parentJoinNames: string = '';
  protected _parentJoinNamesArray: string[] = [];

  /**
   * Returns true if join1 and join2 are equal
   *
   * This is a static function so that it can be called independently without Join objects having to have it
   * @param {Join} join1
   * @param {Join} join2
   * @returns {boolean}
   */
  public static equals(join1: Join, join2: Join) {
    return (join1.name === join2.name) && (join1.parentJoinNames === join2.parentJoinNames);
  }

  get parents(): Join[] {
    return this._parents;
  }

  set parents(p: Join[]) {
    this._parents = p;
    this._parentJoinNamesArray = !this._parents ? [] : this._parents.map((parentJoin: Join) => {
      return parentJoin.name;
    });

    this._parentJoinNames = this._parentJoinNamesArray.join('.');

    this._fullJoinName = (!this._parents || (this._parents.length === 0)) ?
      this.name : (this._parentJoinNames + '.' + this.name);
  }

  /**
   * Get string of parent join names
   * e.g. if this join is A.B.C, returns A.B
   * @returns {string}
   */
  get parentJoinNames(): string {
    return this._parentJoinNames;
  }

  /**
   * Get array of parent join names leading up to this join
   * e.g. if this join is A.B.C, returns [A, B]
   * @returns {string[]}
   */
  get parentJoinNamesArray(): string[] {
    // slice to return a copy of the array in case it gets manipulated and the one in this object will also be changed
    return this._parentJoinNamesArray.slice();
  }

  /**
   * Get full join name with dot notation
   * @returns {string}
   */
  get fullJoinName() {
    return this._fullJoinName;
  }
}