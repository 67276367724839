import React, { FC } from 'react';
import { Box } from '@eds/box';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

import { AppContextProvider, useAppContext } from './app-context.component';

const AppWrapper: FC = ({ children }) => {
  const { isModalOpen } = useAppContext();
  return <Box className={isModalOpen ? css(cssOverrides.modal()) : undefined}>{children}</Box>;
};

export const AppProvider: FC = ({ children }) => {
  return (
    <AppContextProvider>
      <AppWrapper>{children}</AppWrapper>
    </AppContextProvider>
  );
};
