import { Record } from 'immutable';

export interface ConfigJoin {
  inverse: string;
  label: string;
  name: string;
  sqlOn: string;
  type: string;
  view: string;
  parents: any;
}

export const ConfigJoinRecord =
  Record<ConfigJoin>({
    inverse: '',
    label: '',
    name: '',
    sqlOn: '',
    type: '',
    view: '',
    parents: ''
  });
