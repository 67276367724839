import * as React from 'react';
import moment from 'moment';

import { DATETIME_FORMAT } from '../../../constants';
import DateRange from '../DateRange';
import { Filter } from '../../../types/filterModel';
import { Moment } from '../../../constants/moment';

import FormField from './FormField';

interface FilterDateRangeInputProps {
  displayFilter: Filter;
  filterIndex: number;
  isDisabled: boolean;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
}

const FilterDateRangeInput = (props: FilterDateRangeInputProps) => {
  const { displayFilter, filterIndex, isDisabled, saveDisplayFilter } = props;

  const handleDateRangeChange = (startDateChanged: boolean) => (d: Moment | null) => {
    const filter = { ...displayFilter };
    filter.value = '';
    const newDate = d ? d.format(DATETIME_FORMAT) : '';

    if (startDateChanged) {
      filter.low = newDate;
    } else {
      filter.high = newDate;
    }
    
    saveDisplayFilter(filter, filterIndex);
  };

  return (
    <div className="daterangepicker-override filter-value">
      <FormField label="Value">
        <DateRange
          startDate={moment(displayFilter.low, DATETIME_FORMAT)}
          onStartDateChange={handleDateRangeChange(true)}
          endDate={moment(displayFilter.high, DATETIME_FORMAT)}
          onEndDateChange={handleDateRangeChange(false)}
          isClearable={false}
          isDisabled={isDisabled}
        />
      </FormField>
    </div>
  );
};

export default FilterDateRangeInput;
