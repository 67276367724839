import React from 'react';
import { createIcon } from '@eds/icon';

export const SelectIcon = createIcon(
  <>
    <path d="M3 5V17.4951H11.5902V15.4101H5.08508L5.08508 7.08508L18.6186 7.08508V10.4667H20.7037V5.00019L3 5Z" />
    <path d="M17.1268 15.5178L20.6258 12.0286L22 13.4028L17.1268 18.276L12.2536 13.4028L13.6278 12.0286L17.1268 15.5178Z" />
  </>,
  'SelectIcon'
);
