import { Field } from '@eds/field';
import { TextInput } from '@eds/text-input';
import * as React from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import * as _ from 'lodash';

type TextInputFieldProps<T extends FieldValues> = {
    errors: Record<string, unknown>;
    id: string;
    label: string;
    register: UseFormRegister<T>;
    description?: string;
    initialValue?: string | null;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    type?: 'text' | 'email' | 'number';
    adornmentEnd?: string;
    step?: string;
    data?: T,
};

export const TextInputField = <T extends FieldValues, >({
   errors,
   id,
   label,
   description,
   placeholder,
   register,
   type,
   required = false,
   disabled = false,
   adornmentEnd,
   step,
   data,
}: TextInputFieldProps<T>) => {
    const error = _.get(errors, id) as Error;
    const isInvalid = !!error;
    const errorMessage = error && error.message.toString();
    const initialValue = (data || {})[id];

    return (
        <Field
            disabled={disabled}
            label={label}
            description={description}
            invalid={isInvalid}
            message={errorMessage}
            required={required}
        >
            <TextInput
                size="small"
                type={type}
                invalid={isInvalid}
                placeholder={placeholder}
                adornmentEnd={adornmentEnd}
                {...(type === 'number' && { step })}
                {...register(id as Path<T>, {
                    value: initialValue,
                })}
            />
        </Field>
    );
};
