import * as React from 'react';

const NoDataFound = () => {
  return (
    <div className="chart-widget__no-data-found">
      <h3 className="no-data-found__title">No data found</h3>
      <p className="no-data-found__message">Please try a different combination</p>
    </div>
  );
};

export default NoDataFound;
