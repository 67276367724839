import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { DeleteIcon, EditIcon } from '@eds/icon';
import { Text } from '@eds/text';
import { Tooltip } from '@eds/tooltip';
import { space } from '@eds/core';
import classnames from 'classnames';
import { css } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';

import { AlertModal, cssOverrides } from 'src/features/ui';
import {
  CONFIGURATION_DEPRECATION_MESSAGE,
  ConcatenationConfiguration,
  DeprecationIcon,
  useDeprecationContext
} from 'src/features/report-builder';
import useDimensionLabel from 'src/components/hooks/useDimensionLabel';

import CollapseCard from '../CollapseCard';
import { AppState } from '../../../redux';
import useBoolean from '../../../components/hooks/useBoolean';
import { deleteTransformation } from '../../../actions/transformations';
import { getReportJson } from '../../../actions/report';

import './style.scss';
import {
  ConfigurationType,
  DatetimeConfigurations,
  TranslationConfiguration,
  DatetimeConfiguration,
} from '../../../types/transformationModel';

interface Props {
  isOpen: boolean;
  onEdit: (
    type: ConfigurationType,
    transformationData:
      | DatetimeConfiguration
      | TranslationConfiguration
      | ConcatenationConfiguration['configuration'][number],
    transformationId: string
  ) => void;
  toggle: () => void;
}

const ReportTransformation = ({ isOpen, toggle, onEdit }: Props) => {
  const dispatch = useDispatch();
  const { savedQuery, transformations } = useSelector((state: AppState) => state);

  const [idForDeletion, setIdForDeletion] = useState('');
  const [dimensionForDeletion, setDimensionForDeletion] = useState('');
  const { value: modalOpened, setTrue, setFalse } = useBoolean(false);

  const transformationTypes = [, 'Translation', 'Date Handling', 'Concatenation'];

  const onDeleteClick = (id: string, dimension: string) => () => {
    setIdForDeletion(id);
    setDimensionForDeletion(dimension);
    setTrue();
  };

  const onConfirmDelete = () => {
    dispatch(deleteTransformation(idForDeletion, dimensionForDeletion));
    setFalse();
    dispatch(getReportJson(false, savedQuery ? savedQuery.id : undefined));
  };

  const controls = {
    confirm: { label: 'Delete', onClick: onConfirmDelete, tone: 'critical' as 'critical' },
    cancel: { label: 'Cancel', onClick: setFalse, tone: 'ghost' as 'ghost' },
  };

  useEffect(() => {
    if (isOpen && transformations.length === 0) {
      toggle();
    }
  }, [transformations.length, isOpen]);

  const getDimensionLabel = useDimensionLabel();
  const [nameForDeletion, aliasForDeletion] = getDimensionLabel(dimensionForDeletion);

  const { deprecatedConfigurations = [] } = useDeprecationContext() || {};

  return (
    <CollapseCard
      className={classnames('report-transformation', { [css({ marginBottom: space.large })]: isOpen })}
      isOpen={isOpen}
    >
      <Box gap="large" padding="large">
        <Text fontWeight="bold" lineHeight="tight">
          Saved Configuration
        </Text>
        <Box className="report-transformation-table" marginY="large">
          <div className="table-header">
            <div>Dimension</div>
            <div>Type</div>
            <div>{/* placeholder div */}</div>
          </div>
          <div className="table-body">
            {transformations.reduce(
              (result, transform: DatetimeConfigurations) => [
                ...result,
                ...transform.configuration.map((configuration, index) => {
                  const [name, alias] = getDimensionLabel(configuration.dimension);
                  return (
                    <div key={`${transform.id}-${configuration.dimension}`} className="table-row">
                      <div>{alias || name || 'Label missing'}</div>
                      <div>{transformationTypes[transform.type]}</div>
                      {
                        deprecatedConfigurations.find(({ dimension }) => dimension === configuration.dimension) ? (
                          <Box className={css(cssOverrides.button('ghost'))}>
                            <Tooltip ariaHidden={false} label={CONFIGURATION_DEPRECATION_MESSAGE} side="left">
                              <Button
                                iconOnly
                                icon={DeprecationIcon}
                                label=""
                                onClick={() => {}}
                                size="small"
                                tone="ghost"
                              />
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box className={css(cssOverrides.button('ghost'))}>
                            <Button
                              iconOnly
                              icon={EditIcon}
                              label="Edit"
                              onClick={() => onEdit(transform.type, configuration, transform.id)}
                              size="small"
                              tone="ghost"
                            />
                          </Box>
                        )
                      }
                      <Box className={css(cssOverrides.button('ghost'))}>
                        <Button
                          iconOnly
                          icon={DeleteIcon}
                          label="Delete"
                          onClick={onDeleteClick(transform.id, configuration.dimension)}
                          size="small"
                          tone="ghost"
                        />
                      </Box>
                    </div>
                  );
                }),
              ],
              []
            )}
          </div>
        </Box>
        <Box className={css(cssOverrides.button('neutral'))}>
          <Button label="Cancel" onClick={toggle} size="small" tone="neutral" />
        </Box>
      </Box>
      <AlertModal
        title="Delete configuration?"
        description={
          <div className="delete-transformation-modal-content">
            <ul>
              <li>{aliasForDeletion || nameForDeletion || 'Label missing'}</li>
            </ul>
            Once you delete the configuration, any associated rules will be deleted as well.
          </div>
        }
        controls={controls}
        isOpen={modalOpened}
        onClose={setFalse}
      />
    </CollapseCard>
  );
};

export default ReportTransformation;
