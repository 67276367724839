import * as React from 'react';
import { Layout } from 'react-grid-layout';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';

import { Widget } from '../../types/widgetModel';
import Grid from '../../shared-components/Grid';
import { Dashboard as DashboardModel } from '../../types/dashboardModel';
import { DashboardService } from '../../services/dashboardService';
import EditWidgetTitleModal from './components/EditChartTitleModal';
import { WidgetService } from '../../services/widgetService';
import { AppState } from '../../redux';
import { NotificationService } from '../../services/notificationService';
import {
  removeWidgetSuccessCallback,
  saveLayoutSuccessCallback,
  saveLayoutFailedCallback,
  changeWidgetTitleSuccessCallback,
  changeWidgetTitleFailedCallback,
} from './helpers';

import './style.css';

interface DashboardProps {
  dashboard: DashboardModel;
  dashboards: DashboardModel[];
  isEditable?: boolean;
  enableDashboardLazyLoading: boolean;
}

const Dashboard = ({isEditable = false, ...props}: DashboardProps) => {
  const notificationService: NotificationService | null = useSelector((state: AppState) => state.notificationService);
  const [ widgets, setWidgets ] = React.useState<Widget[]>([]);
  const [ showEditWidgetTitleModal, setShowEditWidgetTitleModal ] = React.useState<boolean>(false);
  const [editWidgetTitleLoading, setEditWidgetTitleLoading] = React.useState<boolean>(false);
  const [ selectedWidget, setSelectedWidget ] = React.useState<Widget | undefined>(undefined);

  React.useEffect(
    () => {
      setWidgets(props.dashboard.widgets);
    },
    [props.dashboard]
  );

  const removeWidget = async (widget: Widget) => {
    const remaining = filter(widgets, function(w: Widget) {
      return widget.widgetId !== w.widgetId;
    });
    setWidgets(remaining);
    const result = await WidgetService.removeWidget(props.dashboard.dashboardId, widget.widgetId);
    if (result) {
      removeWidgetSuccessCallback(notificationService);
    }
  };

  const updateWidgetTitle = (widget: Widget, title: string) => {
    const updatedWidgets = widgets.map((w: Widget) => {
      if (widget.widgetId === w.widgetId) {
        w.title = title;
      }
      return w;
    });
    setWidgets(updatedWidgets);
  };

  const getWidgetTitle = () => selectedWidget ? selectedWidget.title : '';

  const saveLayout = async (layout: Layout[]) => {
    const dashboard: DashboardModel = Object.assign({}, props.dashboard);
    if (layout.length > 0) {
      dashboard.layout = layout;
    }

    try {
      await DashboardService.apiSaveDashboard(dashboard);
      saveLayoutSuccessCallback(notificationService);
    } catch (e) {
      saveLayoutFailedCallback(notificationService);
    }
  };

  const saveStatus = async (status: number) => {
    const dashboard: DashboardModel = Object.assign({}, props.dashboard);
    dashboard.status = status;
    try {
      await DashboardService.apiSaveDashboard(dashboard);
      saveLayoutSuccessCallback(notificationService);
    } catch (e) {
      saveLayoutFailedCallback(notificationService);
    }
  };

  const saveWidgetTitle = async (title: string, widget?: Widget) => {
    try {
      if (widget) {
        setEditWidgetTitleLoading(true);
        await WidgetService.editWidget(widget, title);
        updateWidgetTitle(widget, title);
        setSelectedWidget(undefined);
        setShowEditWidgetTitleModal(false);
        changeWidgetTitleSuccessCallback(notificationService);
      } else {
        changeWidgetTitleFailedCallback(notificationService);
      }
    } catch (e) {
      changeWidgetTitleFailedCallback(notificationService);
    } finally {
      setEditWidgetTitleLoading(false);
    }
  };

  const openEditWidgetTitleModal = (widget: Widget) => {
    setSelectedWidget(widget);
    setShowEditWidgetTitleModal(true);
  };

  const closeEditWidgetTitleModal = () => {
    setSelectedWidget(undefined);
    setShowEditWidgetTitleModal(false);
  };

  // TODO: Grid can be refactored to only take in renderWidgets. Widget id can be accessed via renderWidget.props
  return(
    <>
      <Grid
        widgets={widgets}
        saveLayout={saveLayout}
        saveStatus={saveStatus}
        dashboard={props.dashboard}
        dashboards={props.dashboards}
        isEditable={isEditable}
        removeWidget={removeWidget}
        openEditWidgetTitleModal={openEditWidgetTitleModal}
        enableDashboardLazyLoading={props.enableDashboardLazyLoading}
      />
      <EditWidgetTitleModal
        currentWidgetTitle={getWidgetTitle()}
        showModal={showEditWidgetTitleModal}
        saveWidgetTitle={(title) => saveWidgetTitle(title, selectedWidget)}
        closeModal={() => closeEditWidgetTitleModal()}
        editWidgetTitleLoading={editWidgetTitleLoading}
      />
    </>
  );
};

Dashboard.defaultProps = {
  isEditable: false,
};

export default Dashboard;
