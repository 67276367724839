import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchModules } from '../services/reportService';
import { fetchedViews, storeModule } from '../actions/module';
import { AppState } from '../redux';
import { getViewsAndStore } from '../actions/report';
import { Module } from '../types/moduleModel';
import { SuggestedReportService } from '../services/suggestedReportService';
import { Tree, TreeItem } from '../components/Tree';
import { setSuggestionsOpen } from '../actions/suggestions';
import { AuthService } from '../services/authService';

export interface ModuleSelectProps {
  dispatch: Dispatch<any>;
  selectedModule: Module;
  modules: Module[];
  onModuleSelect?: (module: Module) => void;
  reportId?: string;
  authService: AuthService | null;
}

const mapStateToProps = (state: AppState) => ({
  selectedModule: state.module,
  modules: state.modules,
  authService: state.authService,
});

export interface ModuleSelectState {
  rootNode: TreeItem;
}

class ModuleSelectComponent extends React.Component<ModuleSelectProps, ModuleSelectState> {
  protected suggestedReportService: SuggestedReportService;

  constructor(props: ModuleSelectProps) {
    super(props);

    let rootItem = new TreeItem();
    rootItem.isOpen = true;

    this.state = {
      rootNode: rootItem
    };

    this.suggestedReportService = SuggestedReportService.getInstance();
  }

  componentDidUpdate(prevProps: ModuleSelectProps) {
    const { modules, selectedModule } = this.props;
    const { rootNode } = this.state;
    const isCrtSuperAdmin = (this.props.authService) ? this.props.authService.isCrtSuperAdmin() : false;

    // if the modules list has updated, create the tree
    const isModulesUpdated = modules !== prevProps.modules;
    const isTreeOld = modules.length !== rootNode.children.length;
    const isSelectedModuleChanged = selectedModule.name !== prevProps.selectedModule.name;
    const shouldRebuildTree = isModulesUpdated || isTreeOld || isSelectedModuleChanged;
    if (shouldRebuildTree) {
      let root: TreeItem = new TreeItem();

      let children: TreeItem[] = [];

      this.props.modules.forEach((module: Module) => {
        // Don't render modules with no display label - they have not been seeded for some reason
        if (!module.label) {
          return;
        }

        const isNewModule = module.type === 'redshift';

        const item = Object.assign(new TreeItem(),  {
          data: module.name,
          content: isCrtSuperAdmin && isNewModule ? this.getModuleLabel(module.label) : module.label,
          onItemClick: this.onModuleSelect(module),
          children: [],
          isSelected: this.props.selectedModule.name === module.name,
          parent: root,
          showSelectedIcon: true
        });

        children.push(item);
      });

      root = Object.assign(root, {
        data: 'module',
        content: 'Module',
        children: children,
        isOpen: true
      });

      this.setState({
        rootNode: root
      });
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchModules(this.props.reportId));
  }

  onModuleSelect = (module: Module) => () => {
    if (module.name !== this.props.selectedModule.name) {
      this.props.dispatch(storeModule(module));
      // clear the views in the store as they are no longer relevant, since the selected module has changed
      this.props.dispatch(fetchedViews([]));
      this.props.dispatch(getViewsAndStore());
    }

    // When a module is clicked, always open the suggestions modal and get the suggestions from API
    this.props.dispatch(setSuggestionsOpen(true));
    this.props.dispatch(this.suggestedReportService.getSuggestionsModule());

    if (this.props.onModuleSelect) {
      this.props.onModuleSelect(module);
    }
  }

  getModuleLabel = (label: string) => {
    return (
      <div className="contentContainer">
        <span>{label}</span>
        <span className="contentStatus">New</span>
      </div>
    );
  }

  render() {
    if (this.props.modules.length === 0) {
      return null;
    }

    return (
      <div className="module-select-container">
        <Tree
          rootNode={this.state.rootNode}
          dontIndentFirstChildren={true}
        />
      </div>
    );
  }
}

const ModuleSelect = connect(
  mapStateToProps
)(ModuleSelectComponent);

export default ModuleSelect;
