import { REPORT_TYPE } from '../constants';
import { Chart } from './chartModel';
import { BaseModel } from '../types/baseModel';
import { SupportedCountriesType } from '../services/authService';

export class SavedQuery extends BaseModel {
  protected properties: string[] = [
    'id',
    'created',
    'modified',
    'query',
    'name',
    'description',
    'moduleName',
    'userId',
    'reportType',
    'charts',
    'errorMessage',
    'deleted',
    'sharedBy',
    'sharedWith',
    'permissions',
    'countries',
  ];
  /**  
   * This refers to the id of the current report, autogenerated upon first time save/save as and included in the
   * payload
   */
  id: string;
  /** 
   * When the report is created from existing report via "Save As," this reportId contains the id of the original
   * report, and included in the payload
   */
  reportId?: string; 
  query: string;
  name: string;
  description: string;
  moduleName: string;
  userId: number;
  created?: Date;
  modified?: Date;
  reportType: REPORT_TYPE;
  charts?: Chart[];
  errorMessage?: string;
  deleted?: boolean;
  sharedBy?: string;
  sharedWith?: Array<string>;
  countries?: SupportedCountriesType;
  permissions: {
    canShare: boolean; // Share report to other user
    canSchedule: boolean;
    canView: boolean;
    canEdit: boolean;
    canExport: boolean;
    canDelete: boolean;
    canClone: boolean; // Save As (clone)
    canAddToDashboard: boolean; // Share to Dashboard
    canManageFilter: boolean; // Manage report filter
    canManageChart: boolean; // Generate/Edit chart
    canSave: boolean; // Save report
  };
}
