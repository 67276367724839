import { useDispatch, useSelector } from 'react-redux';

import { removeNotification } from 'src/actions/notification';

import { AppState } from 'src/redux';

export const useNotifications = () => {
  const { notifications } = useSelector((state: AppState) => state);
  return notifications;
};

export const useNotificationHandler = () => {
  const dispatch = useDispatch();
  return (id: number) => dispatch(removeNotification(id));
};
