import * as React from 'react';
import { FormItem, Input } from 'elmo-elements';

import { getClassNames } from '../../../services/utilityService';
import {
  SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS_EMAIL,
  SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS
} from '../../../constants/addSchedule';

type Props = {
  errors: any;
  isVisible: boolean;
  recipientEmail: string;
  onRecipientEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function NotificationRecipientEmailForm(props: Props) {
  const {
    errors,
    isVisible,
    recipientEmail,
    onRecipientEmailChange
  } = props;

  const className = getClassNames({
                                    'd-none': !isVisible
                                  });

  const isInvalid: boolean =
    errors[SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS_EMAIL] ||
    errors[SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS];
  const errorMsg = `${errors['notificationRecipients[0].email']} ${errors['notificationRecipients']}`;
  return (
    <div className={className}>
      <FormItem
        label="Recipient email:"
        message={isInvalid ? errorMsg : undefined}
        status={isInvalid ? 'error' : undefined}
        data-testid="recipient-user-select"
      >
        <Input
          // @ts-ignore
          type="text"
          className="form-control"
          id="recipient-email"
          data-testid="recipient-email"
          value={recipientEmail}
          onChange={onRecipientEmailChange}
          required={true}
        />
      </FormItem>
    </div>
  );
}

export default NotificationRecipientEmailForm;
