import * as React from 'react';
import cn from 'classnames';
import { Spinner } from 'elmo-elements';
import { Dispatch } from 'redux';
import { noop } from 'lodash';
import { AppState, ReportDetails } from '../../redux';
import { Query } from '../../types/queryModel';
import { ReportTable } from '../../types/reportTableModel';
import { apiGetReportForDashboard } from '../../services/reportService';
import { QueryLoaderService } from '../../services/queryLoaderService';
import { SavedQuery } from '../../types/savedQueryModel';
import { connect } from 'react-redux';
import { NotificationService } from '../../services/notificationService';
import SimplePagination from '../SimplePagination';
import Report from '../Report';
import { bytesToBase64 } from 'src/features/utils';

export interface ReportWidgetProps {
  dispatch: Dispatch<any>;
  savedQuery: SavedQuery;
  notificationService: NotificationService;
  reportOffset: number;
  reportLimit: number;
  widgetId: string;
  updateWidgetStatus: (widgetId: string, status: boolean) => void;
}

interface ReportWidgetState {
  subState?: AppState;
  report?: ReportTable;
  query?: Query;
  reportDetails: ReportDetails | null;
  loading: boolean;
  isLoaded: boolean;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  reportOffset: state.offset,
  reportLimit: state.reportLimit
});

// The amount of results that will show up in the Report in the widget
export const NUM_WIDGET_RESULTS = 100;

class ReportWidgetComponent extends React.Component<ReportWidgetProps, ReportWidgetState> {

  constructor(props: ReportWidgetProps) {
    super(props);
    this.state = {
      reportDetails: null,
      loading: false,
      isLoaded: false,
    };
  }

  getReportForWidget = async (offset: number) => {
    let queryLoaderService: QueryLoaderService = new QueryLoaderService();
    let subState: AppState | null = await queryLoaderService.loadQueryIntoWidget(this.props.savedQuery, null);

    if (!subState) {
      return;
    }

    let report = await apiGetReportForDashboard(
        bytesToBase64(new TextEncoder().encode(this.props.savedQuery.query)),
        NUM_WIDGET_RESULTS,
        offset,
        this.props.dispatch,
        this.props.savedQuery.id,
        this.props.widgetId);  // this will be updated from the state as user can hit refresh
    let queryData = JSON.parse(this.props.savedQuery.query);
    let query: Query = new Query();
    Object.assign(query, queryData);
    this.props.updateWidgetStatus(this.props.widgetId, true);

    this.setState({
      subState: subState,
      report: report,
      query: query,
      isLoaded: true,
    });
  }

  async componentDidMount() {
    await this.getReportForWidget(0);
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="widget-loading-container">
          <Spinner size="m" />
        </div>
      );
    }

    if (!this.state.subState || !this.state.report || !this.state.query) {
      return null;
    }

    return (
      <div className={cn('report-widget-container', { 'report-not-found-style': this.state.report.rows.length === 0})}>
        {this.state.loading &&
          <div className="overlay" />
        }
        <Report
          report={this.state.report}
          query={this.state.query}
          columnMeta={this.state.subState.columnMeta}
          sort={noop}
          orderBys={this.state.subState.orderBys}
          views={this.state.subState.views}
          reorder={noop}
          removeColumn={noop}
          reportDetails={{numResults: NUM_WIDGET_RESULTS, numPages: 1}}
          loadedQuery={this.props.savedQuery}
          reportTitle={this.getReportTitle()}
          readOnly={true}
          renderPagination={this.renderPagination}
          dispatch={this.props.dispatch}
          isDashboardPage={true}
        />
      </div>
    );
  }

  getReportTitle = () => {
    return this.props.savedQuery ? this.props.savedQuery.name : '';
  }

  renderPagination = () => {
    if (!this.state.subState) {
      return null;
    }

    return (
        this.state.report &&
        (
          <SimplePagination
            handlePageClick={this.onPageChange}
            limit={this.props.reportLimit}
            offset={this.props.reportOffset}
            nextEnabled={!!this.state.report.links && this.state.report.links.next}
            prevEnabled={!!this.state.report.links && this.state.report.links.prev}
          />
        )
    );
  }

  onPageChange = async (pageIndex: number, offset: number, limit: number) => {
    // Limit is currently hardcoded to NUM_WIDGET_RESULTS and can be ignored
    this.setState({loading: true});
    await this.getReportForWidget(offset);
    this.setState({loading: false});
  }

}

const ReportWidget = connect(
  mapStateToProps,
)(ReportWidgetComponent);

export default ReportWidget;
