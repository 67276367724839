import * as React from 'react';
import { BasicModal } from '../../shared-components/BasicModal';
import { Module } from '../../types/moduleModel';
import { EditModuleFormData } from './ModulesListing';

type Props = {
  module: Module | null;
  isOpen: boolean;
  toggle: () => void;
  onSave: (moduleId: string, data: EditModuleFormData) => void;
};

type State = {
  displayLabel: string;
};

class EditModuleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayLabel: ''
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    const { isOpen, module } = this.props;

    if (isOpen && (isOpen !== prevProps.isOpen) && module) {
      this.setState({
        displayLabel: module.label
      });
    }
  }

  save = () => {
    const { onSave, module } = this.props;
    const { displayLabel } = this.state;
    const data = {
      displayLabel: displayLabel
    };

    if (!module) {
      return;
    }

    onSave(
      module.id,
      data
    );
  }

  onInputChange = (event: React.FormEvent<HTMLSelectElement|HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    this.setState(
      {
        displayLabel: newValue
      });
  }

  render() {
    const { isOpen, toggle } = this.props;
    const { displayLabel } = this.state;

    return (
      <div className="add-config">
        <BasicModal
          title="Edit Module"
          className="edit-module-modal"
          confirmButtonText={'Save'}
          onConfirmClicked={this.save}
          loading={false}
          isOpen={isOpen}
          toggle={toggle}
        >
          Display Label:
          <input
            type="text"
            className="form-control"
            value={displayLabel}
            onChange={this.onInputChange}
          />
        </BasicModal>
      </div>
    );
  }
}

export default EditModuleModal;