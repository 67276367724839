import * as React from 'react';
import { Filter, FILTER_DATE_CONTEXTS } from '../../types/filterModel';
import { FILTER_DATE_CONTEXT, FILTER_DATE_CONTEXT_DATE } from '../../constants/filters';

type FilterTextInputProps = {
  displayFilter: Filter;
  filterIndex: number;
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
};

class FilterRelativeDateInput extends React.Component<FilterTextInputProps> {

  render() {
    const { displayFilter } = this.props;
    return (
      <select
        name="filter-time-context-select"
        onChange={this.selectRelativeDateFilter(this.props.filterIndex)}
        className="form-control"
        data-testid="filter-time-context-select"
        value={displayFilter.relativeDate}
      >
        {
          FILTER_DATE_CONTEXTS.map(
            (val: FILTER_DATE_CONTEXT, k: number) => {
              return (
                <option key={k} value={val}>
                  {val}
                </option>
              );
            }
          )
        }
        <option key={FILTER_DATE_CONTEXT_DATE.length} value={FILTER_DATE_CONTEXT_DATE}>
          {FILTER_DATE_CONTEXT_DATE}
        </option>
      </select>
    );
  }

  selectRelativeDateFilter = (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filter = this.props.displayFilters[index];
    filter.relativeDate = event.currentTarget.value;
    if (!this.props.saveDisplayFilter) {
      console.log('Do not saveDisplayFilter');
      return;
    }

    this.props.saveDisplayFilter(filter, index);
  }
}

export default FilterRelativeDateInput;
