import * as React from 'react';
import { Button } from '@eds/button';
import { DashboardIcon } from '@eds/icon';
import { Flex } from '@eds/flex';
import { css } from '@emotion/css';
import { Checkbox, Input, TextArea, FormItem, RadioGroup, Radio, Select, Alert } from 'elmo-elements';
import { find } from 'lodash';
import cn from 'classnames';
import { cssOverrides } from 'src/features/ui';

import ElmoButton from '../ElmoButton';
import { Dashboard } from '../../../types/dashboardModel';
import { AuthService } from '../../../services/authService';
import ElmoModal from '../ElmoModal';
import './style.css';

export interface ShareButtonProps {
  share: (dashboard: Dashboard) => void;
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
  disabled?: boolean;
  dashboards: Dashboard[];
  createDashboard?: (name?: string, description?: string, type?: number, status?: number) => Promise<Dashboard | null>;
  shareNotification: (dashboard: Dashboard | undefined) => void;
  shareErrorNotification: () => void;
  widgetType: 'chart' | 'report';
}

export interface ShareButtonFormData {
  name?: string;
  description?: string;
  dashboardId?: string;
  suggestedDashboard?: boolean;
}

interface ShareButtonState {
  sharing: boolean;
  newDashboard: number;
  formData: ShareButtonFormData;
  formError: {
    name?: string;
    dashboardId?: string;
  };
}

function getInitialShareButtonState(isNewDashboard: number = 1): ShareButtonState {
  return {
    formData: {
      name: '',
      description: '',
      suggestedDashboard: false,
    },
    newDashboard: isNewDashboard,
    formError: {},
    sharing: false
  };
}

const DASHBOARD_TYPE = {
  USER: 1,
  SUGGESTED: 2,
};

const DASHBOARD_STATUS = {
  DRAFT: 1,
  PUBLISHED: 2,
};

class ShareButtonComponent extends React.Component<ShareButtonProps, ShareButtonState> {

  constructor(props: ShareButtonProps) {
    super(props);
    this.state = getInitialShareButtonState();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.dashboards !== this.props.dashboards) {
      this.setState({newDashboard: Number(!this.isDashboards())});
    }
  }

  isDashboards(): boolean {
    return !(this.props.dashboards && this.props.dashboards.length === 0);
  }

  render() {
    return (
      <Flex className={css(cssOverrides.button('ghost'))} data-tracking="add-to-dashboard">
        <Button
          icon={DashboardIcon}
          label="Add to dashboard"
          onClick={this.toggle}
          size="small"
          tone="ghost"
        />
        {this.renderModal()}
      </Flex>
    );
  }

  renderModal() {
    const { formData, formError } = this.state;
    return (
      <ElmoModal
        id="ModalDialog"
        type="medium"
        className={cn('elmo-modal--fs16', {
          'elmo-modal--not-scrollable': this.state.newDashboard === 0,
        })}
        isOpened={this.props.showModal}
        title="Add to Dashboard"
        closeModal={this.cancel}
        primaryButton={(
          <>
            <ElmoButton type="text" onClick={this.cancel}>Cancel</ElmoButton>
            <ElmoButton type="primary" onClick={this.share} isDisabled={this.state.sharing}>
              Save
            </ElmoButton>
          </>
        )}
      >
        <FormItem
            label={'Choose your dashboard:'}
        >
          <RadioGroup
              selected={this.state.newDashboard}
              direction="vertical"
              onChange={
                (value: number) => {
                  this.setState({newDashboard: value });
                }
              }
          >
            <Radio value={0} disabled={!this.isDashboards()}>
              Add to existing dashboard
            </Radio>
            <Radio value={1}>{`Create new dashboard with ${this.props.widgetType}`}</Radio>
          </RadioGroup>
        </FormItem>
        {this.props.widgetType === 'report' &&
          <FormItem>
            <Alert
              message="Reports added to dashboard are limited to displaying 100 records"
              type="info"
              isCloseable={false}
            />
          </FormItem>
        }
        {this.state.newDashboard === 0 && (
          <FormItem
              label={'Choose dashboard'}
              message={formError.dashboardId}
              status={!!formError.dashboardId ? 'error' : undefined}
          >
            <Select
                onChange={this.handleFormDashboardChange()}
                options={
                  (this.props.dashboards.map((dashboard: Dashboard) => {
                    return ({ value: dashboard.dashboardId, label: dashboard.title });
                  }))
                }
            />
          </FormItem>
        )}
        {this.state.newDashboard === 1 && (
          <>
            <FormItem
                label={'Dashboard Title'}
                message={formError.name}
                status={!!formError.name ? 'error' : undefined}
            >
              <Input
                  value={formData.name}
                  id="name"
                  onChange={this.handleFormChange('name')}
                  placeholder="My New Dashboard"
              />
            </FormItem>
            <FormItem
                label={'Description'}
            >
              <TextArea
                  name="description"
                  id="description"
                  placeholder="What does this Dashboard show?"
                  onChange={this.handleFormChange('description')}
                  value={formData.description}
              >
                Text area
              </TextArea>
            </FormItem>
            {AuthService.getInstance().isCrtSuperAdmin() && (
              <Checkbox
                  id="make-suggested-dashboard"
                  label="Make this a Suggested Dashboard"
                  ariaLabel="Make this a Suggested Dashboard"
                  onChange={this.handleFormChange('suggestedDashboard')}
              />
            )}
          </>
        )}
      </ElmoModal>
    );
  }

  toggle = () => {
    this.props.setShowModal(!this.props.showModal);
  }

  handleFormChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let formData: ShareButtonFormData = this.state.formData;
    formData[property] = event.currentTarget.type === 'checkbox' ?
      event.currentTarget['checked'] : event.currentTarget.value;
    this.setState({formData: formData});
  }

  handleFormDashboardChange = () => (value: any) => {
    let formData: ShareButtonFormData = this.state.formData;
    formData.dashboardId = value.value;
    const selectedDashboard = this.props.dashboards.find((dashboard: Dashboard) =>
      dashboard.dashboardId === formData.dashboardId);
    const canAddWidgets = selectedDashboard && selectedDashboard.permissions.canAddWidgets;
    this.setState({
      formData: formData,
      formError: {
        ...this.state.formError,
        dashboardId: !canAddWidgets ?
          'The dashboard selected has exceeded the maximum number of charts which can be added' : undefined,
      },
      sharing: !canAddWidgets,
    });
  }

  cancel = () => {
    // Clear the form data before closing
    this.setState(getInitialShareButtonState(Number(!this.isDashboards())));
    this.props.setShowModal(false);
  }

  setSharingProgress = (status: boolean) => {
    this.setState({
      sharing: status
    });
  }

  share = async () => {
    if (this.state.sharing) {
      return;
    }

    this.setSharingProgress(true);

    let dashboard = find(this.props.dashboards, (dash: Dashboard) => {
      return dash.dashboardId === this.state.formData.dashboardId;
    });

    if (dashboard) {
      await this.props.share(dashboard);
      this.props.shareNotification(dashboard);
    } else if (this.state.formData.name && this.state.formData.name.length > 0 && this.props.createDashboard) {
      // User has opted to create a dashboard first before associating
      const dashboardType = this.state.formData.suggestedDashboard ? DASHBOARD_TYPE.SUGGESTED : DASHBOARD_TYPE.USER;
      const dashboardStatus =
          this.state.formData.suggestedDashboard ? DASHBOARD_STATUS.DRAFT : DASHBOARD_STATUS.PUBLISHED;
      let newDashboard = await this.props.createDashboard(
          this.state.formData.name, this.state.formData.description, dashboardType, dashboardStatus
      );
      if (newDashboard) {
        await this.props.share(newDashboard);
        this.props.shareNotification(newDashboard);
      }
    } else {
      // Should never get here, unless the form is not set correctly
      this.props.shareErrorNotification();
    }

    this.setSharingProgress(false);
  }
}

export default ShareButtonComponent;
