import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const SortByIcon = ({ className, width = 16, height = 18 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1381 6.0287L7.99999 2.89062L4.86192 6.0287L5.80473 6.97151L7.99999 4.77624L10.1953 6.97151L11.1381 
        6.0287ZM4.86182 10.9716L7.99989 14.1097L11.138 10.9716L10.1951 10.0288L7.99989 12.2241L5.80463 10.0288L4.86182 
        10.9716Z"
      />
    </svg>
  );
};
