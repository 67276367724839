import * as yup from 'yup';
import {
  DateFormats,
  DatetimeFormats,
  DatetimeWithSecondsFormats,
} from '../../../../types/transformationModel';

const schema = yup.object({
  dimension: yup.string().required('Field is required'),
  format: yup.string()
    .oneOf([
      ...Object.values(DateFormats),
      ...Object.values(DatetimeFormats),
      ...Object.values(DatetimeWithSecondsFormats)
    ]).required(({ path }) => 'Date format is required')
    .nullable(),
}).required();

export default schema;
