import React from 'react';
import { AlertSpot } from '@eds/illustration';
import { EmptyState } from '@eds/empty-state';
import { Flex } from '@eds/flex';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

export const ServerError = () => {
  return (
    <Flex
      className={css(cssOverrides.errorPage)}
      width="100%"
      flexDirection="column"
      backgroundColor="neutral"
      padding="xxlarge"
    >
      <Flex alignSelf="center" height="100%">
        <EmptyState
          spotIllustration={AlertSpot}
          title="Server error"
          description="Oops! Something went wrong. Please try again later."
          headingLevel={3}
        />
      </Flex>
    </Flex>
  );
};
