import { Chart as ChartModel } from '../types/chartModel';
import { apiQueueFreshReport } from './reportService';
import { JOB_STATUS_SUCCESS } from '../constants/jobStatus';
import { sleep } from './utilityService';
import { GENERATE_WIDGET_POLL_DELAY } from '../constants';
import axios from 'axios';
import { ReportTable } from '../types/reportTableModel';
import * as _ from 'lodash';

/**
 * Get chart result for query
 * @param query
 * @param limit
 * @param offset
 * @param chartId
 * @param reportId
 */
export async function apiGetChart(query: string,
                                  limit: number,
                                  offset: number,
                                  chartId: string,
                                  reportId: string,
                                  source: string = '',
                                  widgetId?: string,
): Promise<ChartModel> {
  // If there is no cached data, get fresh data
  const jobId = await apiQueueFreshReport(query, limit, offset, source, widgetId, reportId);
  return pollGenerateChartStatus(jobId, chartId, reportId);
}

/**
 * Poll for the completion status of the fresh job with jobId. If the job is complete,
 * the result will be returned by the endpoint as well. This function should be used with apiQueueFreshReport
 * @param jobId
 * @param  chartId
 */
export async function pollGenerateChartStatus(jobId: number, chartId: string , queryId: string) {
  let shouldPoll: boolean = true;
  // Store the url of the page where Generate Report was clicked. If the user navigates away, stop polling.
  const triggerUrl = window.location.href;

  return new Promise<ChartModel>(async (resolve, reject) => {
    try {
      while (shouldPoll) {
        // If the user navigates away, stop polling.
        if (triggerUrl !== window.location.href) {
          shouldPoll = false;
          break;
        }
        // partial chart contain the payload data like  limit etc
        const queueResult = await apiGetQueuedChartStatus(jobId, chartId, queryId);

        if (queueResult.status === JOB_STATUS_SUCCESS && queueResult.reportTable) {
          shouldPoll = false;
          queueResult.reportTable.setJobId(jobId);
          resolve(queueResult.reportChart);
        }
        await sleep(GENERATE_WIDGET_POLL_DELAY);
      }
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * Get the latest job status
 * the result will return both report and chart as JOB is associated to report , not chart
 * @param jobId
 * @param  chartId
 */
export async function apiGetQueuedChartStatus(jobId: number, chartId: string , queryId: string) {
  const response = await axios.post('/api/chart/' + jobId + '/status', { chartId, queryId });
  const responseData = response.data;
  const reportTable = responseData.result ?
      new ReportTable(responseData.result['report'], undefined, responseData.result.links) : null;
  const partialChart = new ChartModel();
  const reportChart = responseData.result ?
      Object.assign({}, partialChart, responseData.result['chart']) : null ;

  return {
    status: responseData.status,
    progress: responseData.progress,
    reportTable: reportTable,
    reportChart: reportChart
  };
}
