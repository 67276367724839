import * as React from 'react';
import { BasicModal, withModal } from '../../../shared-components/BasicModal';
import { apiCreateNewConfig } from '../../../services/configurationService';
import LoadingIcon from '../../../shared-components/LoadingIcon';
import { Module } from '../../../types/moduleModel';
import ModuleSelectInput from '../../../components/Config/ModuleSelectInput';
import { handleFormChange } from '../../../services/formUtilService';

interface ConfigAddConfigButtonModalProps {
  onAddSuccess?: () => void;
  toggle: () => void;
  isOpen: boolean;
}

interface ConfigAddConfigButtonModalState {
  selectedModule: Module | null;
  selectedClientName: string;
  loading: boolean;
  saving: boolean;
  confirmButtonText: string;
}

const SAVE_TEXT = 'Save';
const SAVING_TEXT = 'Saving...';

class ConfigAddConfigButtonModal
  extends React.Component<ConfigAddConfigButtonModalProps, ConfigAddConfigButtonModalState> {

  constructor(props: ConfigAddConfigButtonModalProps) {
    super(props);

    this.state = {
      selectedModule: null,
      selectedClientName: '',
      loading: false,
      saving: false,
      confirmButtonText: SAVE_TEXT
    };
  }

  /**
   * Toggles the modal's open/close sate
   */
  toggle = () => {
    this.setState({
      selectedModule: null,
      selectedClientName: ''
    });

    this.props.toggle();
  }

  /**
   * When the Add button is clicked
   */
  onAdd = async () => {
    if (!this.state.selectedModule) {
      return;
    }

    this.setState({
      saving: true,
      confirmButtonText: SAVING_TEXT
    });

    let data = {
      moduleId: this.state.selectedModule.id,
      client: this.state.selectedClientName
    };

    try {
      await apiCreateNewConfig(data);

      if (this.props.onAddSuccess) {
        this.props.onAddSuccess();
      }
      
      // close the modal
      this.toggle();
    } finally {
      this.setState({
        saving: false,
        confirmButtonText: SAVE_TEXT
      });
    }
  }

  onModuleSelected = (module: Module) => {
    this.setState({
      selectedModule: module
    });
  }

  handleInputChange = (property: string) => (value: any) => {
    let newState = {};
    newState[property] = value;
    this.setState(newState);
  }

  onModulesLoading = (isLoading: boolean) => {
    this.setState({
      loading: isLoading
    });
  }

  renderContent() {
    return (
      <div className={this.state.loading ? 'd-none' : ''}>
        <form>
          <div className="form-group">
            <label htmlFor="module">Module: </label>
            <ModuleSelectInput
              selectedModule={this.state.selectedModule}
              onChange={this.onModuleSelected}
              onLoading={this.onModulesLoading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="client">Client: </label>
            <input
              type="text"
              name="client"
              id="client"
              value={this.state.selectedClientName}
              className="form-control"
              onChange={handleFormChange(this.handleInputChange('selectedClientName'))}
            />
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="add-config">
        <button
          className="btn btn-primary mr-2 add-config-button"
          onClick={this.toggle}
        >
          New Draft Configuration
        </button>
        <BasicModal
          title="Add Draft Configuration"
          className="config-add-modal"
          confirmButtonText={this.state.confirmButtonText}
          onConfirmClicked={this.onAdd}
          loading={this.state.loading}
          disableConfirmButton={this.state.saving}
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          {this.state.loading &&
            <LoadingIcon />
          }
          {this.renderContent()}
        </BasicModal>
      </div>
    );
  }
}

export default withModal(ConfigAddConfigButtonModal);