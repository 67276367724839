import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const EditChartIcon = ({ className, width = 24, height = 24 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H11V20.364V19H4V5H18V11H20V5C20 3.9 19.1 3 18 3ZM14.0055 17L16
         15.0055V13H14V17H14.0055ZM6 10H8V17H6V10ZM10 7H12V17H10V7ZM21.13 15.99L21.84 15.28C22.23 14.89 22.23 14.26
          21.84 13.87L21.13 13.16C20.74 12.77 20.11 12.77 19.72 13.16L19.01 13.87L21.13 15.99ZM20.42 16.7L18.3 14.58L13
           19.88V22H15.12L20.42 16.7Z"
        fill="black"
        fillOpacity="0.67"
      />
    </svg>
  );
};
