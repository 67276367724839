import React, { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react';

type ViewMode = 'grid' | 'list';

type DashboardHeaderContextType = {
  isCreating: boolean;
  setViewMode: Dispatch<SetStateAction<ViewMode>>;
  startCreating: () => void;
  stopCreating: () => void;
  viewMode: ViewMode;
};

const DashboardHeaderContext = createContext<DashboardHeaderContextType | undefined>(undefined);

export const DashboardHeaderContextProvider: FC = ({ children }) => {
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [isCreating, setIsCreating] = useState(false);

  const value = {
    isCreating,
    setViewMode,
    startCreating: () => setIsCreating(true),
    stopCreating: () => setIsCreating(false),
    viewMode,
  };

  return <DashboardHeaderContext.Provider value={value}>{children}</DashboardHeaderContext.Provider>;
};

export const useDashboardHeaderContext = () => {
  const context = useContext(DashboardHeaderContext);

  if (!context) {
    throw new Error('useDashboardHeaderContext must be used within a DashboardHeaderContextProvider');
  }

  return context;
};
