import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { dashboardsKeys } from 'src/features/dashboards';

const copyDashboard = async (id: string) => {
  const response = await axios.post(`api/dashboards/${id}/clone`);
  return response.data;
};

export const useCopyDashboardMutation = ({ onError, onSuccess }: { onError: () => void; onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>({
    mutationFn: id => copyDashboard(id),
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: dashboardsKeys.lists() });
      onSuccess();
    },
  });
};
