import { SET_CUSTOM_COLUMNS } from '../constants/customColumns';
import { CustomColumn } from '../types/customColumnModel';

export interface CustomColumnsAction {
  type: SET_CUSTOM_COLUMNS;
  customColumns: Array<CustomColumn>;
}

export function setCustomColumns(customColumns: Array<CustomColumn>): CustomColumnsAction {
  return { type: SET_CUSTOM_COLUMNS, customColumns };
}