import * as React from 'react';
import { findIndex } from 'lodash';

import { AppState, JoinAndDimensions } from '../../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Join } from '../../types/joinModel';
import { View } from '../../types/viewModel';
import { getViewFromJoin } from '../../services/viewService';
import { Dimension } from '../../types/dimensionModel';
import { ColumnSelect, ColumnSelectItemGroup } from '../../components/NewReportBuilder/ColumnSelect';
import {
  addJoinDimension,
  addJoinDimensions,
  removeJoinDimension,
  removeJoinDimensions
} from '../../actions/joinColumn';
import { storeFilterOptions } from '../../actions/filter';
import { SOURCE_WIZARD } from '../../constants';
import { Column } from '../../types/columnModel';

export interface JoinDimensionSelectProps {
  dispatch: Dispatch<any>;
  join: Join;
  views: View[];
  baseView: View;
  joinDimensions: JoinAndDimensions[];

}

export interface JoinDimensionSelectState {
  view: View | null;
  itemGroup: ColumnSelectItemGroup;
  allSelected: boolean;
}

const mapStateToProps = (state: AppState) => ({
  views: state.views,
  joinDimensions: state.joinDimensions,
  baseView: state.view
});

class JoinDimensionSelectComponent extends React.Component<JoinDimensionSelectProps, JoinDimensionSelectState> {
  constructor(props: JoinDimensionSelectProps) {
    super(props);

    this.state = {
      view: null,
      itemGroup: {
        label: '',
        items: []
      },
      allSelected: false
    };
  }

  componentDidMount() {
    this.onJoinChanged(this.props.join, this.props.views, this.props.joinDimensions);
  }

  componentDidUpdate(prevProps: JoinDimensionSelectProps, prevState: JoinDimensionSelectState) {
    // if the join has changed
    if (!Join.equals(this.props.join, prevProps.join)) {
      this.onJoinChanged(this.props.join, this.props.views, this.props.joinDimensions);
    } else if ((this.props.joinDimensions !== prevProps.joinDimensions) && this.state.view) {
      // get the dimensions for the selected join and rerender only if the selected dimensions has changed
      let selectedDimensions: Dimension[] = this.getSelected(this.props.joinDimensions);
      let prevSelectedDimensions: Dimension[] = this.getSelected(prevProps.joinDimensions);

      if (!Column.arrayEquals(selectedDimensions, prevSelectedDimensions)) {
        this.createColumnSelectItems(this.state.view, this.props.joinDimensions);
      }
    }
  }

  onJoinChanged(join: Join, views: View[], selectedDimensions: JoinAndDimensions[]) {
    // Get the view data for the join to render. This contains the dimensions
    let view = getViewFromJoin(views, join);

    if (view) {
      this.setState({
        view: view
      });

      this.createColumnSelectItems(view, selectedDimensions);
    }
  }

  createColumnSelectItems(view: View, joinDimensions: JoinAndDimensions[]) {
    const selectedDimensions: Dimension[] = this.getSelected(joinDimensions);

    const visibleDimensions = view.dimensions.filter(dimension => dimension.visible);

    const nonDeprecatedDimensions = visibleDimensions.filter(dimension => {
      const selected = this.isSelected(dimension, selectedDimensions);
      // NOTE: Remove deprecated dimensions that are not selected
      return !(dimension.deprecated && !selected);
    });

    const items = nonDeprecatedDimensions.map(dimension => {
      const selected = this.isSelected(dimension, selectedDimensions);

      return {
        label: dimension.label,
        isDeprecated: dimension.deprecated,
        isSelected: selected,
        onClick: selected ? this.onRemove(dimension) : this.onAdd(dimension),
      };
    });

    this.setState({
      allSelected: selectedDimensions.length === nonDeprecatedDimensions.length,
      itemGroup: {
        label: view.label,
        items: items
      }
    });
  }

  render() {
    return (
      <ColumnSelect
        title={this.props.join.label}
        isAggregate={false}
        itemGroups={[this.state.itemGroup]}
        onSelectAllClick={this.toggleSelectAll}
        allSelected={this.state.allSelected}
      />
    );
  }

  toggleSelectAll = () => {
    let allSelected = !this.state.allSelected;

    if (allSelected) {
      this.onSelectAll();
    } else {
      this.onRemoveAll();
    }

    this.setState({
      allSelected: allSelected
    });
  }

  protected onAdd = (item: Dimension) => () => {
    this.props.dispatch(addJoinDimension(this.props.join, item, this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onRemove = (item: Dimension) => () => {
    this.props.dispatch(removeJoinDimension(this.props.join, item, this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());

    this.setState({
      allSelected: false
    });
  }

  protected onSelectAll = () => {
    if (!this.state.view || !this.state.view.dimensions) {
      return;
    }

    let selectedDimensions: Dimension[] = this.getSelected(this.props.joinDimensions);

    const visibleDimensions = this.state.view.dimensions.filter(dimension => dimension.visible);

    let toAdd: Dimension[] = visibleDimensions.filter((dim: Dimension) => {
      return !this.isSelected(dim, selectedDimensions);
    });

    if (toAdd.length === 0) {
      return;
    }

    this.props.dispatch(addJoinDimensions(this.props.join, toAdd, this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onRemoveAll = () => {
    this.props.dispatch(removeJoinDimensions(
      this.props.join,
      this.getSelected(this.props.joinDimensions),
      this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected isSelected(dimension: Dimension, selectedDimensions: Dimension[]): boolean {
    return (findIndex(selectedDimensions, ['name', dimension.name]) !== -1);
  }

  protected getSelected(joinAndDimensions: JoinAndDimensions[]): Dimension[] {
    let joinDimensions = joinAndDimensions.find((jd: JoinAndDimensions) => {
      return Join.equals(jd.join, this.props.join);
    });

    return joinDimensions ? joinDimensions.dimensions : [];
  }
}

const JoinDimensionSelect = connect(
  mapStateToProps
)(JoinDimensionSelectComponent);

export default JoinDimensionSelect;
