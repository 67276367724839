var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass, uniqueId } from '../_lib/helper';
import DropdownItem from './components/DropdownItem';
import DropdownButton from './components/DropdownButton';
import DropdownBody from './components/DropdownBody';
import DropdownDivider from './components/DropdownDivider';
import DropdownLabel from './components/DropdownLabel';
import { DropdownKeyConstants } from './type';
import './Dropdown.scss';
import PopoverBase from '../_lib/component/PopoverBase';
var Dropdown = /** @class */ (function (_super) {
    __extends(Dropdown, _super);
    function Dropdown(props) {
        var _this = _super.call(this, props) || this;
        _this.dropdownBodyRef = React.createRef();
        /**
         * Toggles the state of the menu
         */
        _this.toggleMenu = function () {
            _this.setState(function (_a) {
                var isOpen = _a.isOpen;
                return ({ isOpen: !isOpen });
            }, _this.afterStateChange);
        };
        /**
         * Sets the menu's open state
         *
         * @param isOpen
         */
        _this.setMenuIsOpen = function (isOpen) {
            _this.setState({ isOpen: isOpen }, _this.afterStateChange);
        };
        _this.afterStateChange = function () {
            var _a = _this.props, onToggle = _a.onToggle, onOpen = _a.onOpen, onClose = _a.onClose, isFocusedOnOpen = _a.isFocusedOnOpen;
            var isOpen = _this.state.isOpen;
            if (isOpen && isFocusedOnOpen) {
                _this.focusOnFirstItem();
            }
            if (onToggle) {
                onToggle();
            }
            if (isOpen && onOpen) {
                onOpen();
            }
            if (!isOpen && onClose) {
                onClose();
            }
        };
        /**
         * Focus on the first item in the menu
         */
        _this.focusOnFirstItem = function () {
            var menuItems = _this.getMenuItems();
            var firstActive = menuItems.find(_this.isElementEnabled);
            if (firstActive) {
                if (firstActive.setActive) {
                    firstActive.setActive();
                }
                else {
                    firstActive.focus({
                        preventScroll: true,
                    });
                }
            }
        };
        _this.isElementEnabled = function (element) {
            return element.getAttribute('aria-disabled') !== 'true';
        };
        /**
         * When keyup events occur within the component
         *
         * @param event
         */
        _this.handleKeyboardEventsInside = function (event) {
            var _a;
            var isOpen = _this.state.isOpen;
            // When the menu is closed, open the menu, then focus on the first one
            if (isOpen) {
                var callbacks = (_a = {},
                    _a[DropdownKeyConstants.escapeKey] = _this.closeDropdown,
                    _a[DropdownKeyConstants.enterKey] = _this.focusOnFirstItem,
                    _a[DropdownKeyConstants.arrowDownKey] = _this.focusOnNext,
                    _a[DropdownKeyConstants.arrowUpKey] = _this.focusOnPrev,
                    _a);
                var callbackToBeCalled = callbacks[event.key];
                if (callbackToBeCalled) {
                    callbackToBeCalled(event);
                }
            }
        };
        _this.closeDropdown = function () {
            _this.setMenuIsOpen(false);
        };
        _this.focusOn = function (direction) { return function (event) {
            event.preventDefault(); // prevent page from scrolling;
            var menuItems = _this.getMenuItems();
            var currentIndex = menuItems.indexOf(event.target);
            var nextIndexToFocusOn = _this.getNextActiveIndex(menuItems, currentIndex, direction);
            var nextElement = menuItems[nextIndexToFocusOn];
            if (nextElement) {
                nextElement.focus();
            }
        }; };
        _this.focusOnNext = _this.focusOn('next');
        _this.focusOnPrev = _this.focusOn('prev');
        _this.directionStep = {
            next: 1,
            prev: -1,
        };
        _this.getNextActiveIndex = function (menuItems, currentIndex, direction) {
            var nextIndex = currentIndex + _this.directionStep[direction];
            var nextElement = menuItems[nextIndex];
            if (!nextElement) {
                return -1;
            }
            if (_this.isElementEnabled(nextElement)) {
                return nextIndex;
            }
            return _this.getNextActiveIndex(menuItems, nextIndex, direction);
        };
        // generate a unique ID for the component
        _this.bodyId = uniqueId('elmo-dropdown');
        _this.state = {
            isOpen: !!props.isOpen,
        };
        return _this;
    }
    Dropdown.prototype.componentDidMount = function () {
        var isFocusedOnOpen = this.props.isFocusedOnOpen;
        var isOpen = this.state.isOpen;
        if (isOpen && isFocusedOnOpen) {
            this.focusOnFirstItem();
        }
    };
    /**
     * Get items in the menu and returns as an array of elements
     */
    Dropdown.prototype.getMenuItems = function () {
        var current = this.dropdownBodyRef.current;
        if (current) {
            return [].slice.call(current.querySelectorAll('li'));
        }
        return [];
    };
    Dropdown.prototype.render = function () {
        var _a = this.props, ariaLabel = _a.ariaLabel, text = _a.text, icon = _a.icon, children = _a.children, id = _a.id, className = _a.className, position = _a.position, isDisabled = _a.isDisabled, isButtonBlock = _a.isButtonBlock, isInline = _a.isInline, isFullWidth = _a.isFullWidth, isBordered = _a.isBordered, isWidthAuto = _a.isWidthAuto, hasTooltip = _a.hasTooltip;
        var isOpen = this.state.isOpen;
        return (React.createElement("div", { onKeyDown: this.handleKeyboardEventsInside },
            React.createElement(PopoverBase, { id: id, testId: "elmo-dropdown-" + (id || 'default'), className: getClass('elmo-dropdown', className, {
                    open: isOpen,
                    block: isButtonBlock,
                    'full-width': isFullWidth,
                    inline: isInline,
                }), position: position, isOpen: isOpen, setIsOpen: this.setMenuIsOpen, isInline: isInline, mode: "click", tabIndex: -1 },
                React.createElement(PopoverBase.Target, null,
                    React.createElement(DropdownButton, { id: id, toggleMenu: this.toggleMenu, ariaLabel: ariaLabel, text: text, icon: icon, isDisabled: isDisabled, isBlock: isButtonBlock, isFullWidth: isFullWidth, isBordered: isBordered, isOpenDropdown: isOpen, hasTooltip: hasTooltip })),
                React.createElement(PopoverBase.Content, { className: className + ' elmo-elements', role: "presentation" },
                    React.createElement(DropdownBody, { id: this.bodyId, toggleMenu: this.toggleMenu, parentId: id, ref: this.dropdownBodyRef, isWidthAuto: isWidthAuto }, children)))));
    };
    Dropdown.Item = DropdownItem;
    Dropdown.Divider = DropdownDivider;
    Dropdown.Label = DropdownLabel;
    Dropdown.defaultProps = {
        position: 'bottom-start',
        isOpen: false,
        isFocusedOnOpen: false,
    };
    return Dropdown;
}(Component));
export default Dropdown;
