import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';

import { useCopyDashboardMutation } from './copy-dashboard.queries';

type CopyDashboardProps = {
  id: string;
  onSettled: () => void;
};

export const CopyDashboard = ({ id, onSettled }: CopyDashboardProps) => {
  const [errorMessage, setErrorMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { mutate } = useCopyDashboardMutation({
    onError: () =>
      setErrorMessage(toastMessage('An issue occurred during the dashboard copying process, please try again later')),
    onSuccess: () => {
      setSuccessMessage(toastMessage('Dashboard copied'));
    },
  });

  useEffect(() => {
    if (id) {
      setSuccessMessage(toastMessage('Copying dashboard, it may take a moment'));
      mutate(id);
      onSettled();
    }
  }, [id]);

  return (
    <Box>
      {errorMessage && <Toast message={errorMessage} tone="critical" />}
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
