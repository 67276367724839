import {
  ADD_DIMENSIONS, ADD_GROUP_BY, ADD_JOIN_DIMENSIONS, ADD_JOIN_MEASURES, ADD_MEASURES, REMOVE_DIMENSIONS,
  REMOVE_GROUP_BY,
  REMOVE_JOIN_DIMENSIONS,
  REMOVE_JOIN_MEASURES,
  REMOVE_MEASURES,
  STORE_COLUMN_META
} from '../constants';
import { ColumnMeta } from '../types/columnMetaModel';
import { ColumnMetasAction } from '../actions/columnMeta';
import { DimensionsAction } from '../actions/dimension';
import { forEach, findIndex, filter } from 'lodash';
import { Dimension } from '../types/dimensionModel';
import { JoinDimensionsAction, JoinMeasuresAction } from '../actions/joinColumn';
import { Measure } from '../types/measureModel';
import { MeasuresAction } from '../actions/measure';
import { GroupByAction } from '../actions/groupBy';
import {
  columnMetaEqual,
  getColumnMetaForDimension, getColumnMetaForGroupBy,
  getColumnMetaForMeasure
} from '../services/columnMetaService';
import { ReportBuilderAction } from '../actions/reportBuilder';

export function columnMetaReducer(
  state: ColumnMeta[] = [],
  action: ColumnMetasAction | DimensionsAction | JoinDimensionsAction |
    MeasuresAction | JoinMeasuresAction | GroupByAction | ReportBuilderAction
): ColumnMeta[] {
  switch (action.type) {
    case STORE_COLUMN_META: {
      return action.columnMetas;
    }

    case ADD_DIMENSIONS: {
      let nextState = state.slice();

      forEach(action.dimensions, (dimension: Dimension) => {
        nextState.push(getColumnMetaForDimension(dimension, action.view));
      });

      return nextState;
    }

    case REMOVE_DIMENSIONS: {
      let nextState: Array<ColumnMeta | null> = state.slice();
      forEach(action.dimensions, (dimension: Dimension) => {
        let toFind: ColumnMeta = getColumnMetaForDimension(dimension, action.view);
        let removeIndex = findEqualDimensionColumnMeta(state, toFind);

        if (removeIndex !== -1) {
          nextState[removeIndex] = null;
        }
      });

      return filter(nextState) as ColumnMeta[];
    }

    case ADD_JOIN_DIMENSIONS: {
      let nextState = state.slice();

      forEach(action.columns, (dimension: Dimension) => {
        nextState.push(getColumnMetaForDimension(dimension, action.view, action.join));
      });

      return nextState;
    }

    case REMOVE_JOIN_DIMENSIONS: {
      let nextState: Array<ColumnMeta | null> = state.slice();
      forEach(action.columns, (dimension: Dimension) => {
        let toFind: ColumnMeta = getColumnMetaForDimension(dimension, action.view, action.join);
        let removeIndex = findEqualDimensionColumnMeta(state, toFind);

        if (removeIndex !== -1) {
          nextState[removeIndex] = null;
        }
      });

      return filter(nextState) as ColumnMeta[];
    }

    case ADD_MEASURES: {
      let nextState = state.slice();

      forEach(action.measures, (measure: Measure) => {
        nextState.push(getColumnMetaForMeasure(measure, action.view));
      });
      return nextState;
    }

    case REMOVE_MEASURES: {
      let nextState: Array<ColumnMeta | null> = state.slice();

      forEach(action.measures, (measure: Measure) => {
        let toFind: ColumnMeta = getColumnMetaForMeasure(measure, action.view);
        let removeIndex = findEqualMeasureColumnMeta(state, toFind);
        if (removeIndex !== -1) {
          nextState[removeIndex] = null;
        }
      });
      return filter(nextState) as ColumnMeta[];
    }

    case ADD_JOIN_MEASURES: {
      let nextState = state.slice();

      forEach(action.columns, (measure: Measure) => {
        nextState.push(getColumnMetaForMeasure(measure, action.view, action.join));
      });

      return nextState;
    }

    case REMOVE_JOIN_MEASURES: {
      let nextState: Array<ColumnMeta | null> = state.slice();

      forEach(action.columns, (measure: Measure) => {
        let toFind: ColumnMeta = getColumnMetaForMeasure(measure, action.view, action.join);
        let removeIndex: number = findEqualMeasureColumnMeta(state, toFind);
        if (removeIndex !== -1) {
          nextState[removeIndex] = null;
        }
      });

      return filter(nextState) as ColumnMeta[];
    }

    case ADD_GROUP_BY: {
      let nextState = state.slice();
      nextState.push(getColumnMetaForGroupBy(action.groupBy, action.baseView));
      return nextState;
    }

    case REMOVE_GROUP_BY: {
      let nextState = state.slice();
      let toFind: ColumnMeta = getColumnMetaForGroupBy(action.groupBy, action.baseView);

      let removeIndex: number = findEqualDimensionColumnMeta(state, toFind);
      if (removeIndex !== -1) {
        nextState.splice(removeIndex, 1);
      }

      return nextState;
    }

    default:
      return state;
  }
}

function findEqualDimensionColumnMeta(columnMetas: ColumnMeta[], toFind: ColumnMeta) {
  return findIndex(columnMetas, (columnMeta: ColumnMeta) => {
    return columnMetaEqual(columnMeta, toFind);
  });
}

// Caution: poorly implemented function - we should delegate an identifier for the objects and
// use that for comparison. Comparing object literals (i.e view, join, measure) is problematic
function findEqualMeasureColumnMeta(columnMetas: ColumnMeta[], toFind: ColumnMeta) {
  return findIndex(columnMetas, (columnMeta: ColumnMeta) => {
    // NOTE: Using the join name as identifier, the logic also works if both joins are undefined
    const matchingJoinName = columnMeta.join?.name === toFind.join?.name;
    return columnMeta.view === toFind.view && matchingJoinName && columnMeta.measure === toFind.measure;
  });
}
