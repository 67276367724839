import { CHART_TYPE } from '../constants';
import { BaseModel } from '../types/baseModel';

export interface SeriesDataSlice {
  name: string;
  y: number;
}

export interface HighChartSeriesRecord {
  name: string;
  data: number[];
  color?: string;
}

export interface XAxis {
  categories: string[]|CategoryItem[];
}

export interface CategoryItem {
  name: string;
  categories: (string|null)[];
}

export class ChartData extends BaseModel {
  protected properties: string[] = [
    'type',
    'seriesData',
    'seriesName',
    'xAxis',
    'aggregateDataByColumn',
    'text',
    'value'
  ];

  type: CHART_TYPE;
  seriesData?: SeriesDataSlice[];
  seriesName?: string;
  xAxis?: XAxis;
  aggregateDataByColumn?: HighChartSeriesRecord[];
  text?: string;
  value?: number;

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}
