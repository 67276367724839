import axios, { AxiosResponse } from 'axios';

import { GetFormsRequestParamsType, GetFormsResponseType, ImportFormsRequestPayloadType } from '../types/forms';

export const getForms = async (params?: GetFormsRequestParamsType): Promise<GetFormsResponseType> => {
  const response: AxiosResponse<GetFormsResponseType> = await axios.get('/api/forms', { params });
  return response.data;
};

export const importForms = async (payload: ImportFormsRequestPayloadType): Promise<{}> => {
  const response: AxiosResponse<{}> = await axios.post('/api/forms/import', payload);
  return response.data;
};
