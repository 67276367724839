import * as React from 'react';
import { ReactNode } from 'react';
import './style.css';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import { Spinner, Heading } from 'elmo-elements';
import { Layout } from 'react-grid-layout';
import { QueryForApi } from '../../redux';
import { ColumnMeta } from '../../types/columnMetaModel';
import {
  CHART_TYPE,
  CHART_TYPE_COLUMN,
  CHART_TYPE_TEXT,
  EVENT_REDRAW
} from '../../constants';
import { SavedQuery } from '../../types/savedQueryModel';
import { Chart as ChartModel } from '../../types/chartModel';
import { ReportTable } from '../../types/reportTableModel';
import { ChartService } from '../../services/chartService';
import { getEncodedQuery, getQuery } from '../../services/reportService';
import { reportSharedWithMe } from '../../services/shareReportService';
import TextWidget from '../../components/Widget/TextWidget';
import ShareButton from '../../containers/ShareButton';
import { NUM_WIDGET_RESULTS } from '../Widget/ReportWidget';
import { apiGetChart } from '../../services/chartWorkerService';
import { SOURCE_TYPE_DASHBOARD } from '../../constants/dashboard';
import NoDataFound from './NoDataFound';
import { bytesToBase64 } from 'src/features/utils';

export type DispatchChartFunction = (chart: ChartModel) => void;

export interface ChartProps {
  query?: string;
  report: ReportTable;
  columnMeta: ColumnMeta[];
  loadedQuery?: SavedQuery;
  queryForApi: QueryForApi;
  dispatchChart: DispatchChartFunction;
  chart: ChartModel;
  onChartCreated: () => void;
  readOnly?: boolean;
  isDashboardPage?: boolean;
  shareEnabled?: boolean;
  listenerElement?: Element;
  printView?: boolean;
  layout?: Layout;
  source?: string;
  handleChangeRefChart?: (hcRef: Highcharts.Chart) => void;
  currentGridWidth?: number;
  widgetId?: string;
  updateWidgetStatus?: (widgetId: string, status: boolean) => void;
}

interface ChartState {
  chartReport: ReportTable;
  chartType: CHART_TYPE;
  chartConfiguration: any; // ie HighCharts
  showShareModal: boolean;
  isLoaded: boolean;
}

const GRID_ROW_HEIGHT = 48; // 27
const CHART_ROW_HEIGHT_MARGIN = 80;

class Chart extends React.Component<ChartProps, ChartState> {

  protected highchartsRef: any;
  protected pageChanged: boolean;
  protected internalChart: Highcharts.Chart | null;
  constructor(props: ChartProps) {
    super(props);
    this.state = {
      chartReport: this.props.report,
      chartType: CHART_TYPE_COLUMN,
      chartConfiguration: null,
      showShareModal: false,
      isLoaded: false,
    };
    this.highchartsRef = React.createRef();
    this.pageChanged = false;
    this.internalChart = null;
  }

  async componentDidUpdate(prevProps: ChartProps, prevState: ChartState) {
    const isChartRefExists = !!this.highchartsRef && !!this.highchartsRef.current && !!this.highchartsRef.current.chart;

    if (this.props.handleChangeRefChart && isChartRefExists) {
      this.props.handleChangeRefChart(this.highchartsRef.current.chart);
    }
    if (prevProps.chart.chartId && !this.props.chart.chartId) {
      // Clean up state if chart is removed eg when clearing form
      this.setState({
        chartReport: new ReportTable(),
        chartType: CHART_TYPE_COLUMN,
        chartConfiguration: null,
      });
    }

    if (isChartRefExists) {
      // Update the height so it scales vertically as well. Leave some room for the buttons.
      if (this.props.layout && this.props.layout.i) {
        const widgetCell = document.querySelector('[data-widget=\'' + this.props.layout.i + '\']');
        if (widgetCell) {
          const cellHeight = widgetCell.clientHeight - CHART_ROW_HEIGHT_MARGIN;
          const cellWidth = widgetCell.clientWidth - 32;
          this.highchartsRef.current.container.current.style.height = cellHeight + 'px';
          this.highchartsRef.current.container.current.style.width = cellWidth + 'px';

          if (this.internalChart) {
            this.internalChart.setSize(cellWidth, cellHeight, true);
            this.internalChart.reflow();
          }
        }
      }
      this.highchartsRef.current.chart.reflow();
    }

    if (this.props.chart.type && this.props.chart.type !== prevProps.chart.type) {
      this.setState({
        chartConfiguration: null,
      });
    }

    // create the chart data when the Generate Chart button has been clicked, or if the user
    // clicked on a different page, and the API call for that has completed
    const generateChartClicked = this.props.chart.shouldGenerateChart && !prevProps.chart.shouldGenerateChart;

    // track whether the page has changed, this is not enough to run the charts as we still don't have the result data
    // at this point
    if (this.reportPageChanged(prevProps.queryForApi, this.props.queryForApi)) {
      this.pageChanged = true;
    }

    // if the page has changed and the report has changed after, then we have the data as a result of the page change,
    // so create the chart data.
    const reportGeneratedForPageChange = this.pageChanged && this.props.report !== prevProps.report;
    const queryChangedAndChartDataPresent = (prevProps.query !== this.props.query
      && !!this.props.chart.chartId
      && typeof this.props.query !== 'undefined');
    if (generateChartClicked || reportGeneratedForPageChange || queryChangedAndChartDataPresent) {
      this.pageChanged = false; // done actioning the page change, set the flag to false.
      this.createHighchartsData();
    }
  }

  componentDidMount() {
    this.createHighchartsData();
    this.addRedrawListener();
    if (
      this.props.handleChangeRefChart &&
      this.highchartsRef && this.highchartsRef.current && this.highchartsRef.current.chart
    ) {
      this.props.handleChangeRefChart(this.highchartsRef.current.chart);
    }
  }

  async createHighchartsData() {
    const { chart, widgetId, updateWidgetStatus, queryForApi, printView, onChartCreated, source } = this.props;
    if (!chart.chartId) {
      return;
    }

    this.setState({ isLoaded: false });

    const query = getQuery(queryForApi);
    const encodedQuery = getEncodedQuery(query);
    const partialChart = Object.assign(chart, {query: encodedQuery});
    const apiChart = await this.getChartWithChartData(partialChart);

    if (apiChart.chartData && apiChart.chartData.type) {
      const configuration = ChartService.chartDataToConfiguration(
        '', apiChart.chartData.type, apiChart.chartData, source === SOURCE_TYPE_DASHBOARD);

      // Print specific configuration
      if (printView && configuration) {
        const a4PageHeight = 1118;
        configuration['chart']['height'] = a4PageHeight / 2; // set it to half A4 height
      }

      this.setState({
        chartConfiguration: configuration,
        chartType: apiChart.chartData.type,
        isLoaded: true,
      });
      if (!!updateWidgetStatus && !!widgetId) {
        updateWidgetStatus(widgetId, true);
      }
      onChartCreated();
    }
  }

  getChartWithChartData = async (partialChart: ChartModel): Promise<ChartModel> => {
    const { loadedQuery, source } = this.props;
    let chart = new ChartModel();
    if (loadedQuery && loadedQuery.query && source === SOURCE_TYPE_DASHBOARD) {
      // get the chart data  from worker
      chart = await apiGetChart(
          bytesToBase64(new TextEncoder().encode(loadedQuery.query)),
          NUM_WIDGET_RESULTS,
          0,
          this.props.chart.chartId,
          loadedQuery.id,
          source,
          this.props.widgetId
    );
    } else {
      // do not impose workers for single query , i.e when it is not running from dashboard
      const apiChartWithChartData = await ChartService.apiNewChart(partialChart);
      const chartWithChartData = Object.assign({}, this.props.chart, apiChartWithChartData);
      // Use the original chart_id to do a a save as
      chartWithChartData.chartId = this.props.chart.chartId;
      return chartWithChartData;
    }
    return chart;
  }

  render() {
    highchartsExporting(Highcharts);
    highchartsExportData(Highcharts);

    if (!this.props.chart || !this.props.chart.chartId) {
      return null;
    }

    if (!this.state.isLoaded) {
      return (
        <div className="widget-loading-container">
          <Spinner size="m" />
        </div>
      );
    }

    const widgetChart = (
      <>
        {this.state.chartConfiguration && this.state.chartConfiguration.type !== CHART_TYPE_TEXT &&
        <HighchartsReact
            ref={this.highchartsRef}
            highcharts={Highcharts}
            options={this.state.chartConfiguration}
            oneToOne={true}
            callback={(chart: Highcharts.Chart) => {
              this.internalChart = chart;
              if (this.props.layout) {
                chart.setSize(
                    null,
                    this.props.layout.h * GRID_ROW_HEIGHT - CHART_ROW_HEIGHT_MARGIN,
                    false
                );
              }
            }}
        />
        }
        {this.state.chartConfiguration && this.state.chartConfiguration.type === CHART_TYPE_TEXT &&
        <TextWidget title={this.props.chart.title} value={this.state.chartConfiguration.value} />
        }
      </>
    );

    if (this.props.isDashboardPage && this.state.chartConfiguration) {
      return (
          <>{widgetChart}</>
      );
    }

    if (this.props.readOnly && this.state.chartConfiguration) {
      return (
        <div className="row">
          <div className="col">
            <div className="chart-select-container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="chart-widget-container" id="chart-widget-container">
                    <Heading htmlTag="h4">{this.props.chart.title}</Heading>
                  </div>
                  <div className="chart-widget-container">{widgetChart}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.readOnly && !this.state.chartConfiguration) {
      return (<NoDataFound className="no-data-found-dashboard"/>);
    }

    return (
      <div className="row">
        <div className="col">
          <div className="chart-select-container">
            {!this.props.chart.deleted && this.renderVisualisationBody()}
          </div>
        </div>
      </div>
    );
  }

  renderVisualisationBody(): ReactNode {
    const { loadedQuery, readOnly, shareEnabled } = this.props;
    const showShareButton = !readOnly && !!shareEnabled && (!loadedQuery || (!!loadedQuery &&
      !reportSharedWithMe(loadedQuery) && loadedQuery.permissions && loadedQuery.permissions.canAddToDashboard));
    const showRemoveButton = !readOnly && (!loadedQuery || (!!loadedQuery && !reportSharedWithMe(loadedQuery) &&
      loadedQuery.permissions && loadedQuery.permissions.canManageChart));
    return (
      <div className="row">
        <div className="col-sm-12">
          {showRemoveButton &&
            <button
              className={'btn btn-outline-dark pull-right btn--small-action'}
              onClick={this.removeChart}
              data-testid="remove-chart"
            >
              <i className={'icon icon-bin'}/>
            </button>
          }
          {showShareButton &&
            <div className="chart-select-share-container">
              <ShareButton
                loadedQuery={this.props.loadedQuery}
                chart={this.props.chart}
              />
            </div>
          }
          <div className="chart-widget-container" id="chart-widget-container">
            <Heading htmlTag="h4">{this.props.chart.title}</Heading>
          </div>
          <div className="chart-widget-container">
            {!this.state.chartConfiguration && <NoDataFound className="no-data-found-report-builder" />}
            {this.state.chartConfiguration && this.state.chartConfiguration.type !== CHART_TYPE_TEXT &&
              <HighchartsReact
                ref={this.highchartsRef}
                highcharts={Highcharts}
                options={this.state.chartConfiguration}
                oneToOne={true}
              />
            }
            {this.state.chartConfiguration && this.state.chartConfiguration.type === CHART_TYPE_TEXT &&
              <TextWidget title={this.props.chart.title} value={this.state.chartConfiguration.value} />
            }
          </div>
        </div>
      </div>
    );
  }

  reportPageChanged(prevQuery: QueryForApi, query: QueryForApi) {
    return prevQuery.offset !== query.offset;
  }

  removeChart = async () => {
    const chart = Object.assign({}, this.props.chart);
    chart.deleted = true;
    this.props.dispatchChart(chart);
  }

  // Have an Element listen so it can redraw its Chart
  addRedrawListener = () => {
    if (this.props.listenerElement) {
      this.props.listenerElement.addEventListener(EVENT_REDRAW, (e: CustomEvent) => {
        if (this.highchartsRef && this.highchartsRef.current && this.highchartsRef.current.chart) {
          this.highchartsRef.current.chart.reflow();
        }
      });
    }
  }

}

export default Chart;
