import * as React from 'react';

const { createContext, useContext, useRef, useState } = React;

export interface SuggestedReportsPopoverRefHandler {
  buttonRef: React.RefObject<HTMLButtonElement>;
  contentRef: React.RefObject<HTMLDivElement>;
}

interface SuggestedReportsPopoverContextType {
  popoverRef: React.RefObject<SuggestedReportsPopoverRefHandler>;
  treeNodeRef: React.RefObject<HTMLDivElement>;
  shouldClose: boolean;
  shouldOpen: boolean;
  setShouldClose: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuggestedReportsPopoverContext = createContext<SuggestedReportsPopoverContextType | undefined>(undefined);

export const SuggestedReportsPopoverProvider: React.FC = ({ children }) => {
  const popoverRef = useRef(null);
  const treeNodeRef = useRef(null);
  
  const [shouldClose, setShouldClose] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  
  const value = { popoverRef, treeNodeRef, shouldClose, shouldOpen, setShouldClose, setShouldOpen };

  return <SuggestedReportsPopoverContext.Provider value={value}>{children}</SuggestedReportsPopoverContext.Provider>;
};

export function useSuggestedReportsPopover() {
  const context = useContext(SuggestedReportsPopoverContext);

  if (context === undefined) {
    throw new Error('useSuggestedReportsPopover must be used within a SuggestedReportsPopoverProvider');
  }
  
  return context;
}

export default SuggestedReportsPopoverContext;
