import React from 'react';
import { backgroundColor, borderColor, color } from '@eds/core';
import { css } from '@emotion/css';
import { Flex } from '@eds/flex';
import { Notice } from '@eds/notice';

function ReportErrorBoundary() {
  const errorDescription = 'An error occurred while working on this report.';

  return (
    <Flex
      backgroundColor="neutral"
      height="100%"
      justifyContent="center"
      alignItems="center"
      className={
        css({
          'button:hover': {
            color: color.neutralBold
          },
          'button:active': {
            color: color.neutralBold
          },
          'button:focus': {
            color: color.neutralBold,
            backgroundColor: backgroundColor.neutral,
            borderColor: borderColor.neutralSubtle,
            outline: 'none'
          },
          'button:focus-visible': {
            outline: 'var(--eds-focus-ring-default)'
          }
        })
      }
    >
      <Notice
        title="Something went wrong"
        description={`${errorDescription} If the problem continues, please contact support.`}
        primaryActionLabel="Refresh this page"
        primaryAction={() => window.location.reload()}
      />
    </Flex>
  );
}

export default ReportErrorBoundary;
