import * as React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Select, FormItem, Button, Loader, CancelIcon, Alert, Spinner } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import { debounce } from 'lodash';
import axios, { CancelTokenSource } from 'axios';

import { UserListCard } from './components/UserListCard';
import { NotificationService } from '../../services/notificationService';
import { AppState } from '../../redux';
import { NOTIFICATION_SUCCESS } from '../../constants';
import {
  apiShareReport,
  apiGetShareReportConfig,
  apiGetShareReportUsersList,
  ShareReportUserType,
  ShareReportResponseType,
  ShareReportUsersListParamsType,
} from '../../services/shareReportService';
import { AuthService } from '../../services/authService';
import { User } from '../../types/userModel';
import defaultUserAvatar from '../../assets/image/defaultUserImage.jpeg';

import './style.scss';

interface ShareReportModalProps {
  showModal: boolean;
  closeModal: (updatedQuery: ShareReportResponseType) => void;
  reportId: string;
}

const userRolesMapping = {
  ROLE_CRT_SUPER_ADMIN: 'ELMO Super Admin',
  ROLE_SUPER: 'Company Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_EMPLOYEE: 'Employee',
};

const DEBOUNCE_INPUT_CHANGE_TIMEOUT = 500;

const ShareReportModal = (props: ShareReportModalProps) => {
  const userListKey = 'userList';
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [isSharingReportInProgress, setIsSharingReportInProgress] = React.useState<boolean>(false);
  const [usersList, setUsersList] = React.useState<Array<ShareReportUserType>>([]);
  const [userListCancelSource, setUserListCancelSource] = React.useState<CancelTokenSource>();
  const [isUsersListLoaded, setIsUsersListLoaded] = React.useState<boolean>(false);
  const [isSharedWithUserListLoaded, setIsSharedWithUserListLoaded] = React.useState<boolean>(false);
  const [sharedWithUserList, setSharedWithUserList] = React.useState<Array<ShareReportUserType>>([]);
  const [isReportSharingFirstTime, setIsReportSharingFirstTime] = React.useState<boolean>(false);
  const [selectInputValue, setSelectInputValue] = React.useState<string>('');
  const isReportDataLoaded = isUsersListLoaded && isSharedWithUserListLoaded;

  const notificationService: NotificationService | null = useSelector((state: AppState) => state.notificationService);
  const authService: AuthService | null = useSelector((state: AppState) => state.authService);

  const getUserById = (id: string) => {
    return usersList.find(user => user.id === id);
  };

  const getProfileImage = (url: string | null) => url || defaultUserAvatar;

  const cancelPreviousUserListRequest = () => {
    if (!isUsersListLoaded && userListCancelSource) {
      userListCancelSource.cancel('Cancelled');
    }
  };

  const getUserList = async (params?: ShareReportUsersListParamsType) => {
    setIsUsersListLoaded(false);
    const usersListData = await apiGetShareReportUsersList(params);
    setIsUsersListLoaded(true);

    return usersListData;
  };

  const updateUserList = async (params?: ShareReportUsersListParamsType) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cancelPreviousUserListRequest();

    setUserListCancelSource(source);
    setIsUsersListLoaded(false);

    try {
      const usersListData = await apiGetShareReportUsersList(params, source.token);
      setIsUsersListLoaded(true);
      setUsersList(usersListData);
      return usersList;
    } catch (e) {
      console.log(e);
    }
    return [];
  };

  const getSharedWithUserList = async () => {
    setIsSharedWithUserListLoaded(false);

    const shareReportConfig = await apiGetShareReportConfig(props.reportId);
    const sharedWithUserListData = shareReportConfig.sharedWith && shareReportConfig.sharedWith.length ?
      await getUserList({ ids: shareReportConfig.sharedWith }) :
      [];

    if (shareReportConfig) {
      setIsReportSharingFirstTime(!shareReportConfig.sharedWith.length);
    }
    setIsSharedWithUserListLoaded(true);

    return sharedWithUserListData;
  };

  const getReportData = async () => {
    updateUserList();
    const shareWithUserList = await getSharedWithUserList();

    if (shareWithUserList) {
      setSharedWithUserList(shareWithUserList);
    }
  };

  const showSuccessNotification = () => {
    const firstTimeSharingNotificationMessage = 'Report shared successfully';
    const updateSharedUsersNotificationMessage = 'Report updated successfully';
    const successNotificationMessage = isReportSharingFirstTime ?
      firstTimeSharingNotificationMessage :
      updateSharedUsersNotificationMessage;

    if (notificationService) {
      notificationService.addNotification(NOTIFICATION_SUCCESS, successNotificationMessage);
    }
  };

  const shareReportWithUsers = async () => {
    if (isSharingReportInProgress) {
      return;
    }

    const sharedWithUserListIds = sharedWithUserList.map(user => user.id);
    
    setIsSharingReportInProgress(true);
    const updatedQuery = await apiShareReport(props.reportId, sharedWithUserListIds);
    setIsSharingReportInProgress(false);
    props.closeModal(updatedQuery);
    showSuccessNotification();
  };

  const onSelect = (option: SelectPropsOption) => {
    setErrors({});
    const sharedUser = getUserById(option.value);

    if (sharedUser) {
      setSharedWithUserList([...sharedWithUserList, sharedUser]);
    }
  };

  const unshareUser = (userId: string) => () => {
    setSharedWithUserList([...sharedWithUserList].filter(user => user.id !== userId));
  };

  const onSelectInputChange = (value: string) => {
    if (value !== selectInputValue) {
      setSelectInputValue(value);

      updateUserList({ text: value });
    }
  };

  const debouncedSelectInputChange = debounce(onSelectInputChange, DEBOUNCE_INPUT_CHANGE_TIMEOUT);

  const getSelectUsersOptions = () => {
    const currentUserData: User | null = authService ? authService.getUserData() : null;
    const currentUserUuid = currentUserData ? currentUserData.uuid : '';

    if (!currentUserData) {
      return [];
    }

    if (!isReportDataLoaded) {
      return [
        {
          value: 'loading',
          label: <Loader type="spinner" />
        }
      ];
    }

    return usersList
      .filter(user => (
        !sharedWithUserList.some(sharedWithUser => sharedWithUser.id === user.id) &&
        currentUserUuid !== user.id)
      )
      .map(user => ({
        value: user.id,
        label: (
          <UserListCard
            fullName={`${user.firstName} ${user.lastName}`}
            email={user.email}
            role={userRolesMapping[user.role]}
            avatarUrl={getProfileImage(user.profileImage)}
          />
        ),
      }));
  };

  const renderSharedWithUsersList = () => {
    return isSharedWithUserListLoaded ? (
      <div className="usersList">
        {
          sharedWithUserList.map(user => {
            return user ? (
              <div className="usersListItem" key={user.id}>
                <UserListCard
                  fullName={`${user.firstName} ${user.lastName}`}
                  email={user.email}
                  role={userRolesMapping[user.role]}
                  avatarUrl={getProfileImage(user.profileImage)}
                  rightPanel={
                    <Button
                      icon={<CancelIcon />}
                      onClick={unshareUser(user.id)}
                      isDisabled={isSharingReportInProgress}
                    />
                  }
                />
              </div>
            ) : null;
          })
        }
      </div>
    ) : <Loader type="spinner" />;
  };

  React.useEffect(
    () => { 
      getReportData();
      return () => {
        cancelPreviousUserListRequest();
      };
    },
    []
  );

  return props.reportId ? (
    <div className="shareReportModal">
      <Modal
        type="medium"
        isOpened={props.showModal}
        title="Share Report With"
        closeModal={props.closeModal}
        closeLabel="Close"
        primaryButton={(
          <Button
            type="primary"
            onClick={shareReportWithUsers}
            isUppercase={false}
            isDisabled={!isReportDataLoaded}
          >
            {isSharingReportInProgress ? <Loader type="spinner" /> : 'Save'}
          </Button>
        )}
      >
        <Alert
          className="shareReportModal__alert"
          message="All data displayed in the report will be shared with all users"
          type="info"
          isCloseable={false}
        />
        <FormItem
          label="Add Users"
          message={!!errors[userListKey] ? errors[userListKey] : undefined}
          status={!!errors[userListKey] ? 'error' : undefined}
          data-testid="share-report-user-select"
          labelAddon={isUsersListLoaded ? null : <Spinner size="s" />}
        >
          <Select
            className="usersSelect"
            options={getSelectUsersOptions()}
            value={null}
            onChange={onSelect}
            placeholder={'Add Users by name or email'}
            onInputChange={debouncedSelectInputChange}
            isDisabled={isSharingReportInProgress}
            // Workaround for correct render Select options, after async update options prop
            filterOption={() => true}
          />
        </FormItem>
        {renderSharedWithUsersList()}
      </Modal>
    </div>
  ) : null;
};

export default ShareReportModal;
