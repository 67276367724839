import { Dashboards, Downloads, Reports, ScheduledReports } from 'src/pages';

import ReportEditOnly from '../containers/ReportEditOnlyPage';
import NewReportEditOnly from '../containers/NewReportBuilder/ReportEditOnlyPage';
import ReportingToolPage from '../containers/ReportingToolPage';
import NewReportBuilder from '../containers/NewReportBuilder';
import ReportViewOnlyPage from '../containers/ReportViewOnlyPage';
import NewReportViewOnlyPage from '../containers/NewReportBuilder/ReportViewOnlyPage';
import ReportPrintPage from '../containers/ReportPrintPage';
import DashboardPage from '../containers/Dashboard/DashboardPage';
import LogoutPage from '../containers/LogoutPage';
import { ReportingRoutes } from '../constants/routes';

const managerLandingRoute = {
    downloads: {
        path: ReportingRoutes.root,
        component: Downloads,
        private: true,
        exact: true
    }
};

const adminLandingRoute =  {
    listing: {
        path: ReportingRoutes.root,
        component: Reports,
        private: true,
        exact: true
    }
};

const dashboardPageRoute = {
    path: ReportingRoutes.dashboard,
    component: DashboardPage,
    private: true,
};

const managerRoutes = {
    reportDownloads: {
        path: ReportingRoutes.reportDownloads,
        component: Downloads,
        private: true
    },
    logout: {
        path: ReportingRoutes.logout,
        component: LogoutPage,
        private: true,
        hideHeader: true
    }
};

const adminRoutes  = {
    edit: {
        path: ReportingRoutes.editReport,
        component: ReportEditOnly,
        private: true
    },
    reportingTool: {
        path: ReportingRoutes.reportingTool,
        component: ReportingToolPage,
        private: true
    },
    view: {
        path: ReportingRoutes.viewReport,
        component: ReportViewOnlyPage,
        private: true
    },
    print: {
        path: ReportingRoutes.printReport,
        component: ReportPrintPage,
        private: true
    },
    dashboard: dashboardPageRoute,
    dashboardsList: {
        path: ReportingRoutes.dashboardsList,
        component: Dashboards,
        private: true,
    }
};

const newReportBuilderAdminRoutes = {
    view: {
        path: ReportingRoutes.viewReport,
        component: NewReportViewOnlyPage,
        private: true
    },
    edit: {
        path: ReportingRoutes.editReport,
        component: NewReportEditOnly,
        private: true
    },
    reportingTool: {
        path: ReportingRoutes.reportingTool,
        component: NewReportBuilder,
        private: true
    },
};

export const scheduleRoutes = {
    scheduledReport: {
        path: ReportingRoutes.scheduledReports,
        component: ScheduledReports,
        private: true,
    }
};

const sharingManagerRoutes = {
    listing: {
        path: ReportingRoutes.root,
        component: Reports,
        private: true,
        exact: true
    },
    view: {
        path: ReportingRoutes.viewReport,
        component: ReportViewOnlyPage,
        private: true
    },
};

const newReportBuilderManagerRoutes = {
    view: {
        path: ReportingRoutes.viewReport,
        component: NewReportViewOnlyPage,
        private: true
    },
};

export const getManagerReportingRoutes = (
    isNewReportBuilderEnabled: boolean,
    enableDashboardSharing: boolean,
    enableReportBuilder: boolean,
) => 
    ({
        ...managerRoutes,
        ...sharingManagerRoutes,
        ...managerLandingRoute,
        ...isNewReportBuilderEnabled ? newReportBuilderManagerRoutes : {},
        ...(enableDashboardSharing
            ? {
                dashboard: dashboardPageRoute,
                dashboardsList: {
                  path: ReportingRoutes.dashboardsList,
                  component: Dashboards,
                  private: true,
                },
              }
            : {}),
        ...enableReportBuilder ? newReportBuilderAdminRoutes : {},
    });

export const getAdminReportingRoutes = (isNewReportBuilderEnabled: boolean) => 
    ({
        ...managerRoutes,
        ...adminRoutes,
        ...adminLandingRoute,
        ...isNewReportBuilderEnabled ? newReportBuilderAdminRoutes : {},
    });
