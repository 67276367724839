import React, { useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';

import { DashboardCreateEdit } from 'src/features/dashboards';

type EditDashboardProps = {
  description: string;
  id: string;
  name: string;
  onClose: () => void;
};

export const EditDashboard = (props: EditDashboardProps) => {
  const [errorMessage, setErrorMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const onError = () =>
    setErrorMessage(toastMessage('An issue occurred during the dashboard update, please try again later'));
  const onSuccess = () => setSuccessMessage(toastMessage('Dashboard updated'));

  return (
    <Box>
      <DashboardCreateEdit isOpen={!!props.id} mode="edit" onError={onError} onSuccess={onSuccess} {...props} />
      {errorMessage && <Toast message={errorMessage} tone="critical" />}
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
