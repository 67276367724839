import * as React from 'react';
import './style.css';
import { getDimensionTypes } from '../../../services/viewService';
import { map, cloneDeep } from 'lodash';
import { BasicModal, withModal } from '../../../shared-components/BasicModal';
import { ConfigDimension } from '../../../types/config/configDimensionModel';

interface ConfigEditDimensionModalProps {
  column: ConfigDimension | null;
  onDone: (column: ConfigDimension) => void;
  toggle: () => void;
  isOpen: boolean;
}

interface ConfigEditDimensionModalState {
  typeOptions: string[];
  column: ConfigDimension | null;
}

class ConfigEditDimensionModal extends React.Component<ConfigEditDimensionModalProps, ConfigEditDimensionModalState> {

  constructor(props: ConfigEditDimensionModalProps) {
    super(props);

    this.state = {
      typeOptions: [],
      column: null
    };
  }

  componentWillReceiveProps(newProps: ConfigEditDimensionModalProps) {
    if (newProps.column && this.props.column !== newProps.column) {
      let typeOptions: string[] = getDimensionTypes();

      this.setState({
        typeOptions: typeOptions,
        column: cloneDeep(newProps.column) // clone the object so the original is not altered.
      });
    }
  }
  public toggle = () => {
    this.props.toggle();
  }

  onDone = () => {
    if (this.state.column) {
      this.props.onDone(this.state.column);
    }

    this.toggle();
  }

  handleInputChange = (property: string) => (event: React.FormEvent<HTMLSelectElement|HTMLInputElement>) => {
    if (!this.state.column) {
      return;
    }

    let columnCopy = this.state.column;
    columnCopy[property] = event.currentTarget.type === 'checkbox'  ?
      event.currentTarget['checked'] : event.currentTarget.value;

    this.setState({
      column: columnCopy
    });
  }

  renderColumnDetails() {
    if (!this.state.column) {
      return null;
    }

    let column: ConfigDimension = this.state.column;

    return (
      <div>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name: </label>
            <input
              type="text"
              name="name"
              id="name"
              value={column.name}
              className="form-control"
              disabled={true}
            />
          </div>

          <div className="form-group">
            <label htmlFor="dbColumnName">Database Column: </label>
            <input
              type="text"
              name="dbColumnName"
              id="dbColumnName"
              value={column.sql}
              className="form-control"
              disabled={true}
            />
          </div>

          <div className="form-group">
            <label htmlFor="label">Label: </label>
            <input
              type="text"
              name="label"
              id="label"
              className="form-control"
              value={column.label}
              onChange={this.handleInputChange('label')}
            />
          </div>

          <div className="form-group">
            <label htmlFor="type">Type: </label>
            <select
              className="form-control"
              value={column.type}
              onChange={this.handleInputChange('type')}
            >
              {map(this.state.typeOptions, (typeOption: string, key: number) => {
                return (
                  <option key={key}>
                    {typeOption}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group row">
            <label htmlFor="groupable" className="col-3">Groupable: </label>
            <div className="form-check col-9">
              <input
                className="form-check-input"
                type="checkbox"
                checked={column.groupable}
                onChange={this.handleInputChange('groupable')}
                id="groupable"
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="visible" className="col-3">Visible: </label>
            <div className="form-check col-9">
              <input
                className="form-check-input"
                type="checkbox"
                checked={column.visible}
                onChange={this.handleInputChange('visible')}
                id="visible"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="config-edit-dimension">
        <BasicModal
          title="Edit Dimension"
          className="config-edit-dimension-modal"
          confirmButtonText="OK"
          onConfirmClicked={this.onDone}
          toggle={this.props.toggle}
          isOpen={this.props.isOpen}
          size="lg"
        >
          {this.renderColumnDetails()}
        </BasicModal>
      </div>
    );
  }
}

export default withModal(ConfigEditDimensionModal);