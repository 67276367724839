import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const ArrowDownIcon = ({ className, width = 16, height = 18 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M8.00198 13.6333L3.53058 9.1619L4.47339 8.21909L7.33532 11.081L7.33532 3.35716L8.66865 3.35716L8.66865 
        11.081L11.5306 8.21909L12.4734 9.1619L8.00198 13.6333Z"
      />
    </svg>
  );
};
