import React from 'react';
import { Box } from '@eds/box';
import { Pagination, PaginationState } from '@eds/pagination';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

type ReportsListPaginationProps = {
  currentItemsPerPage: number;
  currentPage: number;
  onPageUpdate: (data: PaginationState) => void;
  totalItems: number;
};

export const ReportsListPagination = ({
  currentItemsPerPage,
  currentPage,
  onPageUpdate,
  totalItems,
}: ReportsListPaginationProps) => {
  return (
    <Box className={css(cssOverrides.button('ghost'))}>
      <Pagination
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        itemsCount={totalItems}
        onPageUpdate={onPageUpdate}
      />
    </Box>
  );
};
