import * as React from 'react';
import ReportingToolPage from './ReportingToolPage';

interface ReportingToolPageProps {
  match: any;
}

class ReportViewOnly extends React.Component<ReportingToolPageProps> {

  render() {
    let queryId: string = this.props.match.params.queryId;
    return (
      <ReportingToolPage queryId={queryId} readOnly={true} />
    );
  }
}

export default ReportViewOnly;