import React from 'react';
import { Button } from '@eds/button';
import { Flex } from '@eds/flex';
import { Tooltip } from '@eds/tooltip';
import { css } from '@emotion/css';

import { AuthService } from 'src/services/authService';
import { SaveIcon, cssOverrides } from 'src/features/ui';
import { useExperimentalViewsAndJoinsChecker } from 'src/features/report-builder';

type SaveReportButtonProps = {
  isEditing: boolean;
  tooltipLabel: string;
} & (
  | {
      checkForExperimentalViews?: false;
      disabled?: never;
      onClick?: never;
    }
  | {
      checkForExperimentalViews: true;
      disabled?: boolean;
      onClick: () => void;
    }
);

/**
 * NOTE: This is supposed to be a dumb component but because the one that uses it is a class component, we will
 * offload some logic here just because this is the only place we can use hooks. We can do HOC or prop drilling ofc
 * but this is more convenient
 */
export const SaveReportButton = ({
  checkForExperimentalViews,
  disabled,
  isEditing,
  onClick,
  tooltipLabel,
}: SaveReportButtonProps) => {
  const reportHasExperimentalViewsOrJoins = useExperimentalViewsAndJoinsChecker();
  const displayTooltip = !AuthService.getInstance().isCrtSuperAdmin() && reportHasExperimentalViewsOrJoins;

  if (!checkForExperimentalViews || displayTooltip) {
    return (
      <Flex className={css(cssOverrides.button('primary'))}>
        <Tooltip ariaHidden={false} label={tooltipLabel} nonInteractiveChild>
          <Button
            data-testid="save-btn"
            data-tracking={isEditing ? 'save-report' : 'save-report-as'}
            disabled
            icon={SaveIcon}
            label={isEditing ? 'Save' : 'Save As'}
            size="small"
          />
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Flex className={css(cssOverrides.button('primary'))}>
      <Button
        data-testid="save-btn"
        data-tracking={isEditing ? 'save-report' : 'save-report-as'}
        disabled={disabled}
        icon={SaveIcon}
        label={isEditing ? 'Save' : 'Save As'}
        onClick={onClick}
        size="small"
      />
    </Flex>
  );
};
