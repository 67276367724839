import * as React from 'react';

type PropsType = {
  className?: string;
  width?: number;
  height?: number;
};

export const NoDataImage = ({ className, width = 134, height = 134 }: PropsType) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 134 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_823:16839" maskUnits="userSpaceOnUse" x="0" y="0" width={width} height={height}>
      <circle cx="67" cy="67" r="67" fill="#DBDBDB"/>
      </mask>
      <g mask="url(#mask0_823:16839)">
      <circle cx="67" cy="67" r="67" fill="#8C8C8C"/>
      <g filter="url(#filter0_d_823:16839)">
        <rect x="14.6182" y="36.5454" width="103.545" height="109.636" rx="3" fill="#E6E6E7"/>
      </g>
      <path
        d="M14.7182 39.5454C14.7182 37.9438 16.0165 36.6454 17.6182 36.6454H115.164C116.765 36.6454 118.064 37.9438 
        118.064 39.5454V44.9727H14.7182V39.5454Z"
        fill="white"
        stroke="#ABABAB"
        strokeWidth="0.2"
      />
      <g filter="url(#filter1_d_823:16839)">
        <path
          d="M20.709 69.2183C20.709 68.666 21.1567 68.2183 21.709 68.2183H63.5635C64.1158 68.2183 64.5635 68.666 64.5635
          69.2183V97.6728C64.5635 98.2251 64.1158 98.6728 63.5635 98.6728H21.709C21.1567 98.6728 20.709 98.2251 20.709 
          97.6728V69.2183Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_d_823:16839)">
        <path
          d="M20.709 105.764C20.709 105.211 21.1567 104.764 21.709 104.764H63.5635C64.1158 104.764 64.5635 105.211 
          64.5635 105.764V134.218C64.5635 134.771 64.1158 135.218 63.5635 135.218H21.709C21.1567 135.218 20.709 134.771 
          20.709 134.218V105.764Z"
          fill="white"
        />
      </g>
      <path 
        d="M24.3638 124.255L29.8456 118.773L33.5001 122.427H40.2001L43.2456 118.773L46.9001 116.945L49.9456 
        118.773L56.6456 116.945L59.082 113.291L60.9092 112.073"
        stroke="#ABABAB"
      />
      <path
        d="M29.8456 118.773L24.3638 124.255V133.391H60.9092V112.682L59.082 113.291L56.6456 116.946L49.9456
        118.773L46.9001 116.946L43.2456 118.773L40.2001 122.427H33.5001L29.8456 118.773Z"
        fill="url(#paint0_linear_823:16839)"
      />
      <g filter="url(#filter3_d_823:16839)">
        <path
          d="M69.4365 69.2183C69.4365 68.666 69.8842 68.2183 70.4365 68.2183H112.291C112.843 68.2183 113.291
          68.666 113.291 69.2183V97.6728C113.291 98.2251 112.843 98.6728 112.291 98.6728H70.4365C69.8842 98.6728 69.4365
          98.2251 69.4365 97.6728V69.2183Z"
          fill="white"
        />
      </g>
      <rect x="71.873" y="86.4907" width="3.65455" height="9.74545" fill="url(#paint1_linear_823:16839)"/>
      <rect x="92.582" y="86.4907" width="3.65455" height="9.74545" fill="url(#paint2_linear_823:16839)"/>
      <rect x="79.1821" y="77.9634" width="3.65455" height="18.2727" fill="url(#paint3_linear_823:16839)"/>
      <rect x="99.8911" y="77.9634" width="3.65455" height="18.2727" fill="url(#paint4_linear_823:16839)"/>
      <rect x="86.4917" y="91.3633" width="3.65455" height="4.87273" fill="url(#paint5_linear_823:16839)"/>
      <rect x="107.201" y="91.3633" width="3.65455" height="4.87273" fill="url(#paint6_linear_823:16839)"/>
      <g filter="url(#filter4_d_823:16839)">
        <path
          d="M69.4365 105.764C69.4365 105.211 69.8842 104.764 70.4365 104.764H112.291C112.843 104.764 113.291
          105.211 113.291 105.764V134.218C113.291 134.771 112.843 135.218 112.291 135.218H70.4365C69.8842 135.218
          69.4365 134.771 69.4365 134.218V105.764Z"
          fill="white"
        />
      </g>
      <rect
        x="80.4004"
        y="113.291"
        width="3.65455"
        height="8.52727"
        transform="rotate(90 80.4004 113.291)"
        fill="url(#paint7_linear_823:16839)"
      />
      <rect
        x="95.0181"
        y="131.564"
        width="3.65455"
        height="23.1455"
        transform="rotate(90 95.0181 131.564)"
        fill="url(#paint8_linear_823:16839)"
      />
      <rect
        x="97.4546"
        y="119.382"
        width="3.65455"
        height="25.5818"
        transform="rotate(90 97.4546 119.382)"
        fill="url(#paint9_linear_823:16839)"
      />
      <rect
        x="85.2729"
        y="125.473"
        width="3.65455"
        height="13.4"
        transform="rotate(90 85.2729 125.473)"
        fill="url(#paint10_linear_823:16839)"
      />
      <rect x="23.1455" y="70.6545" width="20.7091" height="1.21818" rx="0.609091" fill="#ABABAB"/>
      <rect x="23.1455" y="107.2" width="20.7091" height="1.21818" rx="0.609091" fill="#ABABAB"/>
      <rect x="71.8726" y="70.6545" width="18.2727" height="1.21818" rx="0.609091" fill="#ABABAB"/>
      <rect x="71.8726" y="107.2" width="18.2727" height="1.21818" rx="0.609091" fill="#ABABAB"/>
      <g filter="url(#filter5_d_823:16839)">
        <path
          d="M20.709 52.1638C20.709 51.6115 21.1567 51.1638 21.709 51.1638H39.1999C39.7522 51.1638 40.1999
          51.6115 40.1999 52.1638V61.1275C40.1999 61.6797 39.7522 62.1275 39.1999 62.1275H21.709C21.1567 62.1275
          20.709 61.6797 20.709 61.1275V52.1638Z"
          fill="white"
        />
      </g>
      <rect x="21.7817" y="53.0562" width="11.0709" height="1.30246" rx="0.651229" fill="#76777A"/>
      <rect x="26.7998" y="57.6147" width="7.81475" height="2.60492" rx="1.30246" fill="#ABABAB"/>
      <rect x="18" y="39" width="18" height="4" rx="2" fill="#D9D9D9"/>
      <g filter="url(#filter6_d_823:16839)">
        <path
          d="M45.0728 52.1638C45.0728 51.6115 45.5205 51.1638 46.0728 51.1638H63.5637C64.1159 51.1638 64.5637 51.6115
          64.5637 52.1638V61.1275C64.5637 61.6797 64.1159 62.1275 63.5637 62.1275H46.0728C45.5205 62.1275 45.0728
          61.6797 45.0728 61.1275V52.1638Z"
          fill="white"
        />
        <rect x="46.145" y="53.0562" width="11.0709" height="1.30246" rx="0.651229" fill="#76777A"/>
        <rect x="51.3555" y="57.6147" width="7.81475" height="2.60492" rx="1.30246" fill="#ABABAB"/>
      </g>
      <g filter="url(#filter7_d_823:16839)">
        <path
          d="M69.4365 52.1638C69.4365 51.6115 69.8842 51.1638 70.4365 51.1638H87.9274C88.4797 51.1638 88.9274 51.6115
          88.9274 52.1638V61.1275C88.9274 61.6797 88.4797 62.1275 87.9274 62.1275H70.4365C69.8842 62.1275 69.4365 
          61.6797 69.4365 61.1275V52.1638Z"
          fill="white"
        />
        <rect x="70.5088" y="53.0562" width="11.0709" height="1.30246" rx="0.651229" fill="#76777A"/>
        <rect x="75.7188" y="57.6147" width="7.81475" height="2.60492" rx="1.30246" fill="#ABABAB"/>
      </g>
      <g filter="url(#filter8_d_823:16839)">
        <path
          d="M93.7998 52.1638C93.7998 51.6115 94.2475 51.1638 94.7998 51.1638H112.291C112.843 51.1638 113.291 51.6115 
          113.291 52.1638V61.1275C113.291 61.6797 112.843 62.1275 112.291 62.1275H94.7998C94.2475 62.1275 93.7998 
          61.6797 93.7998 61.1275V52.1638Z"
          fill="white"
        />
        <rect x="94.8726" y="53.0562" width="11.0709" height="1.30246" rx="0.651229" fill="#76777A"/>
        <rect x="100.083" y="57.6147" width="7.81475" height="2.60492" rx="1.30246" fill="#ABABAB"/>
      </g>
      <path
        d="M51.4377 89.2893C50.2791 91.3639 48.3667 92.9131 46.0968 93.6159C43.8268 94.3186 41.3736 94.121 39.2454 
        93.064C37.1172 92.007 35.4774 90.1716 34.6658 87.9383C33.8541 85.705 33.933 83.2451 34.8859 81.0683C35.8388
        78.8916 37.5928 77.165 39.7843 76.2463C41.9757 75.3277 44.4366 75.2876 46.6569 76.1342C48.8772 76.9809 50.6865
        78.6494 51.71 80.7939C52.7334 82.9384 52.8924 85.3945 52.154 87.6531L48.2164 86.3658C48.6241 85.1186 48.5363 
        83.7624 47.9712 82.5781C47.406 81.3939 46.4069 80.4726 45.1809 80.0051C43.9548 79.5376 42.5959 79.5597 41.3858 
        80.067C40.1756 80.5742 39.2071 81.5277 38.6809 82.7297C38.1547 83.9317 38.1111 85.2901 38.5593 86.5233C39.0075 
        87.7566 39.913 88.7701 41.0882 89.3538C42.2634 89.9374 43.6181 90.0466 44.8716 89.6585C46.125 89.2704 47.1811 
        88.4149 47.8209 87.2693L51.4377 89.2893Z"
        fill="#76777A"
      />
      <path
        d="M51.4377 89.2893C50.604 90.7821 49.3729 92.0147 47.8811 92.8501C46.3892 93.6856 44.695 94.0913 42.9866 
        94.0222C41.2781 93.953 39.6223 93.4117 38.2028 92.4585C36.7834 91.5052 35.6559 90.1771 34.9455 88.6218C34.2352 
        87.0665 33.9698 85.3448 34.1787 83.6477C34.3877 81.9507 35.0629 80.3448 36.1294 79.0082C37.1958 77.6717 38.6118 
        76.6569 40.2201 76.0765C41.8285 75.496 43.5662 75.3727 45.2404 75.7201L44.3987 79.7764C43.4742 79.5845 42.5146 
        79.6526 41.6265 79.9732C40.7383 80.2937 39.9564 80.8541 39.3675 81.5921C38.7786 82.3301 38.4058 83.2169 38.2904 
        84.154C38.175 85.0911 38.3215 86.0419 38.7138 86.9008C39.1061 87.7596 39.7287 88.4929 40.5125 89.0194C41.2963 
        89.5458 42.2107 89.8447 43.1541 89.8828C44.0975 89.921 45.0331 89.697 45.8569 89.2356C46.6807 88.7743 47.3605 
        88.0937 47.8209 87.2693L51.4377 89.2893Z"
        fill="#D9D9D9"
      />
      <path
        d="M51.4377 89.2893C50.8059 90.4206 49.9434 91.4067 48.9062 92.1834C47.8689 92.9602 46.6801 93.5104 45.4166 
        93.7984C44.1532 94.0864 42.8433 94.1057 41.572 93.8552C40.3006 93.6046 39.096 93.0897 38.0363 92.3439C36.9765 
        91.5981 36.0853 90.638 35.4203 89.5258C34.7554 88.4136 34.3315 87.1741 34.1761 85.8876C34.0207 84.6011 34.1374 
        83.2963 34.5185 82.0577C34.8996 80.8192 35.5366 79.6745 36.3883 78.6979L39.5105 81.4207C39.0402 81.96 38.6884 
        82.5921 38.478 83.276C38.2676 83.96 38.2031 84.6805 38.2889 85.3909C38.3747 86.1013 38.6088 86.7858 38.976 
        87.3999C39.3432 88.0141 39.8353 88.5443 40.4205 88.9561C41.0057 89.368 41.6709 89.6523 42.3729 89.7906C43.075 
        89.929 43.7983 89.9183 44.496 89.7593C45.1937 89.6002 45.8502 89.2964 46.4229 88.8675C46.9957 88.4386 47.472 
        87.8941 47.8209 87.2693L51.4377 89.2893Z"
        fill="#ABABAB"
      />
      </g>
      <defs>
        <filter
          id="filter0_d_823:16839"
          x="13.6182"
          y="36.5454"
          width="105.545"
          height="111.636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter1_d_823:16839"
          x="19.709"
          y="68.2183"
          width="45.8545"
          height="32.4546"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter2_d_823:16839"
          x="19.709"
          y="104.764"
          width="45.8545"
          height="32.4546"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter3_d_823:16839"
          x="68.4365"
          y="68.2183"
          width="45.8545"
          height="32.4546"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter4_d_823:16839"
          x="68.4365"
          y="104.764"
          width="45.8545"
          height="32.4546"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter5_d_823:16839" 
          x="19.709"
          y="51.1638"
          width="21.4907"
          height="12.9636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter6_d_823:16839"
          x="44.0728"
          y="51.1638"
          width="21.4907"
          height="12.9636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter7_d_823:16839"
          x="68.4365"
          y="51.1638"
          width="21.4907"
          height="12.9636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <filter
          id="filter8_d_823:16839"
          x="92.7998"
          y="51.1638"
          width="21.4907"
          height="12.9636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823:16839"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823:16839" result="shape"/>
        </filter>
        <linearGradient
          id="paint0_linear_823:16839"
          x1="42.6365"
          y1="112.073"
          x2="42.6365"
          y2="133.391"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5A5A5" stopOpacity="0.27"/>
          <stop offset="1" stopColor="#AAB1BB" stopOpacity="0"/>
        </linearGradient>
        <linearGradient
          id="paint1_linear_823:16839"
          x1="73.7003"
          y1="86.4907"
          x2="73.7003"
          y2="96.2362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#989898"/>
          <stop offset="1" stopColor="#D1D1D1"/>
        </linearGradient>
        <linearGradient
          id="paint2_linear_823:16839"
          x1="94.4093"
          y1="86.4907"
          x2="94.4093"
          y2="96.2362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#989898"/>
          <stop offset="1" stopColor="#D1D1D1"/>
        </linearGradient>
        <linearGradient
          id="paint3_linear_823:16839"
          x1="81.0094"
          y1="77.9634"
          x2="81.0094"
          y2="96.2361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#989898"/>
          <stop offset="1" stopColor="#D1D1D1"/>
        </linearGradient>
        <linearGradient
          id="paint4_linear_823:16839"
          x1="101.718"
          y1="77.9634"
          x2="101.718"
          y2="96.2361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#989898"/>
          <stop offset="1" stopColor="#D1D1D1"/>
        </linearGradient>
        <linearGradient
          id="paint5_linear_823:16839"
          x1="88.319"
          y1="91.3633"
          x2="88.319"
          y2="96.236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#989898"/>
          <stop offset="1" stopColor="#D1D1D1"/>
        </linearGradient>
        <linearGradient
          id="paint6_linear_823:16839"
          x1="109.028"
          y1="91.3633"
          x2="109.028"
          y2="96.236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#989898"/>
          <stop offset="1" stopColor="#D1D1D1"/>
        </linearGradient>
        <linearGradient
          id="paint7_linear_823:16839"
          x1="82.2277"
          y1="113.291"
          x2="82.2277"
          y2="121.818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABABAB"/>
          <stop offset="1" stopColor="#D9D9D9"/>
        </linearGradient>
        <linearGradient
          id="paint8_linear_823:16839"
          x1="96.8453"
          y1="131.564"
          x2="96.8453"
          y2="154.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABABAB"/>
          <stop offset="1" stopColor="#D9D9D9"/>
        </linearGradient>
        <linearGradient
          id="paint9_linear_823:16839"
          x1="99.2819"
          y1="119.382"
          x2="99.2819"
          y2="144.963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABABAB"/>
          <stop offset="1" stopColor="#D9D9D9"/>
        </linearGradient>
        <linearGradient
          id="paint10_linear_823:16839"
          x1="87.1002"
          y1="125.473"
          x2="87.1002"
          y2="138.873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABABAB"/>
          <stop offset="1" stopColor="#D9D9D9"/>
        </linearGradient>
      </defs>
    </svg>
  );
};
