import { View } from '../types/viewModel';
import { Join } from '../types/joinModel';
import {
  JoinAndDimensions, JoinAndMeasures, ViewJoinAndDimensionOrMeasure
} from '../redux/index';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { GroupBy } from '../types/groupByModel';
import {
  DIMENSION_TYPE_DATETIME, DIMENSION_TYPE_NUMBER, DIMENSION_TYPE_STRING, DIMENSION_TYPE_DATEINTERVAL,
  MEASURE_TYPE_AVERAGE, MEASURE_TYPE_COUNT,
  MEASURE_TYPE_CUSTOM,
  MEASURE_TYPE_SUM
} from '../constants';

export function getViewFromJoin(views: View[], join: Join): View | undefined {
  return views.find((v: View) => {
    return v.name === join.viewName;
  });
}

/**
 * Will return an array of objects, the object having a View, a Dimension or Measure, and (optionally) a Join
 * Generally used for the Filter dropdown
 */
export function getViewJoinAndDimensionOrMeasureObjs(
  dimensions: Dimension[], joinDimensions: JoinAndDimensions[],
  measures: Measure[], joinMeasures: JoinAndMeasures[],
  baseView: View, views: View[], groupBys: GroupBy[])
  : ViewJoinAndDimensionOrMeasure[] {

  // Go through the currentView and return an array of ViewJoinAndDimensionOrMeasure objects
  // As there is no Join or Measure each object will only contain the View and a Dimension
  const viewAndDimensions: ViewJoinAndDimensionOrMeasure[] = dimensions.map((dimension: Dimension) => {
    return { view: baseView, dimension: dimension };
  });

  // Go through the Dimensions that have been Joined to return an array of ViewJoinAndDimensionOrMeasure objects
  const viewDimensionJoinObj: ViewJoinAndDimensionOrMeasure[] = joinDimensions.reduce(
    (accumulator: any[], joinAndDimension: JoinAndDimensions) => {
      let vd = joinAndDimension.dimensions.map((dimension: Dimension) => {
        return {view: baseView, dimension: dimension, join: joinAndDimension.join};
      });
      return accumulator.concat(vd);
    },
    []);

  // Do the same process for Measures
  const viewAndMeasures: ViewJoinAndDimensionOrMeasure[] = measures.map((measure: Measure) => {
    return { view: baseView, measure: measure };
  });

  const viewMeasureJoinObj: ViewJoinAndDimensionOrMeasure[] = joinMeasures.reduce(
    (accumulator: any[], joinAndMeasure: JoinAndMeasures) => {
      let vd = joinAndMeasure.measures.map((measure: Measure) => {
        return {view: baseView, measure: measure, join: joinAndMeasure.join};
      });
      return accumulator.concat(vd);
    },
    []);

  // For Measures, we want add the Dimensions can be used in a Group By
  // This is so that we can use them as a Filter eg Group By UserId and also Filter on it
  const viewGroupDimensionJoinObj: ViewJoinAndDimensionOrMeasure[] = groupBys.map((groupBy: GroupBy) => {
    return {
      view: baseView,
      join: groupBy.join,
      dimension: groupBy.dimension
    };
  });

  // Combine and return our data
  return viewAndDimensions.concat(viewDimensionJoinObj)
    .concat(viewAndMeasures)
    .concat(viewMeasureJoinObj)
    .concat(viewGroupDimensionJoinObj);
}

export function findMeasureByName(view: View, measureName: string): Measure | undefined {
  return view.measures.find((viewMeasure: Measure) => {
    return viewMeasure.name === measureName;
  });
}

export function findDimensionByName(view: View, dimensionName: string): Dimension | undefined {
  return view.dimensions.find((viewDimension: Dimension) => {
    return viewDimension.name === dimensionName;
  });
}

export function getDimensionTypes() {
  return [
    DIMENSION_TYPE_NUMBER,
    DIMENSION_TYPE_STRING,
    DIMENSION_TYPE_DATETIME,
    DIMENSION_TYPE_DATEINTERVAL
  ];
}

export function getColumnMeasureTypes() {
  return [
    MEASURE_TYPE_AVERAGE,
    MEASURE_TYPE_SUM,
    MEASURE_TYPE_COUNT,
    MEASURE_TYPE_CUSTOM
  ];
}

export function getTableMeasureTypes() {
  return [
    MEASURE_TYPE_COUNT
  ];
}