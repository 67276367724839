import { AppNotificationAction, AppNotificationServiceAction } from '../actions/notification';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_NOTIFICATION_SERVICE } from '../constants';
import * as _ from 'lodash';
import { NotificationService } from '../services/notificationService';

import { AppNotification } from 'src/features/api-feedback';

export function notificationsReducer(state: AppNotification[] = [], action: AppNotificationAction): AppNotification[] {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return [...state, action.notification];
    }

    case REMOVE_NOTIFICATION: {
      let idToRemove: number = action.notification.id;
      let indexToRemove: number = _.findIndex(state, ['id', idToRemove]);
      let newState = state.slice();
      newState.splice(indexToRemove, 1);
      return newState;
    }

    default:
      return state;
  }
}

export function notificationServiceReducer(state: NotificationService | null = null,
                                           action: AppNotificationServiceAction) {
  switch (action.type) {
    case SET_NOTIFICATION_SERVICE: {
      return action.notificationService;
    }

    default:
      return state;
  }
}