import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import uuidv4 from 'uuid/v4';
import { noop } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';

import { AppState } from '../redux';
import { saveQueryAndChart } from '../actions/report';
import { Chart } from '../types/chartModel';
import { SavedQuery } from '../types/savedQueryModel';
import { NotificationService } from '../services/notificationService';
import { ExportService } from '../services/exportService';
import { AuthService } from '../services/authService';
import { getClassNames } from '../services/utilityService';
import { reportSharedWithMe } from '../services/shareReportService';
import { SaveQueryFormData } from '../components/SaveQuery';
import ShareReportModal from '../components/ShareReportModal';
import SplitButtonDropdown from '../shared-components/SplitButtonDropdown';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, REPORT_TYPE_USER } from '../constants';
import { CRT_SCHEDULE_LOCATION_REPORT_BUILDER } from '../constants/addSchedule';
import AddScheduleModal from './AddScheduleModal';

export interface ActionButtonsContainerProps {
  dispatch: Dispatch<any>;
  notificationService: NotificationService;
  chart: Chart;
  loadedQuery?: SavedQuery;
  disabled?: boolean;
  tempQuery: SavedQuery;
  query: string;
  authService: AuthService;
  reportTitle: string;
  queryId: string;
  jobId?: number;
}

export interface ActionButtonsContainerState {
  exporting: boolean;
  isAddScheduleModalOpen: boolean;
  isShareReportModalOpen: boolean;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  chart: state.chart,
  tempQuery: state.tempQuery,
  query: state.query,
  authService: state.authService,
  reportTitle: state.reportTitle,
  jobId: state.report.jobId
});

class ActionButtonsContainer extends React.Component<ActionButtonsContainerProps, ActionButtonsContainerState> {

  constructor(props: ActionButtonsContainerProps) {
    super(props);
    this.state = {
      exporting: false,
      isAddScheduleModalOpen: false,
      isShareReportModalOpen: false,
    };
  }

  doPrint = async () => {

    let formData: SaveQueryFormData = this.getTempFormData();

    try {
      // loadedQueryToSave is only for when we want to save an existing query
      // Pass in an undefined loadedQueryToSave as print does not ever save an existing query
      await this.props.dispatch(
        saveQueryAndChart(false, undefined, this.props.chart, formData)
      );

      // Navigate to temp query print page
      window.open('/print/' + this.props.tempQuery.id);

    } catch (e) {
      this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'Could not load report to print.');
    }

  }

  isScheduleExportBtnDisabled = () => {
    const { loadedQuery } = this.props;
    return !(loadedQuery && loadedQuery.reportType === REPORT_TYPE_USER);
  }

  setShareReportModalFlag = (showModal: boolean) => () => {
    this.setState({ isShareReportModalOpen: showModal });
  }

  getSplitButtonItems = () => {
    const { authService, disabled, loadedQuery } = this.props;
    const isScheduleExportBtnDisabled = this.isScheduleExportBtnDisabled();
    const showShareReport = !!loadedQuery && loadedQuery.permissions &&
      loadedQuery.permissions.canShare && !reportSharedWithMe(loadedQuery);
    const showScheduleExport = authService.isSchedulerEnabled() && !!loadedQuery && !reportSharedWithMe(loadedQuery)
      && loadedQuery.permissions && loadedQuery.permissions.canSchedule;

    return [
      {
        label: this.state.exporting ? 'Exporting...' : 'Export Now',
        className: 'export-btn',
        onClick: this.doCsvExport,
        disabled: disabled,
        color: 'outline-dark'
      },
      ...showShareReport ? [
        {
          label: 'Share report',
          onClick: this.setShareReportModalFlag(true),
          disabled: disabled,
          color: 'outline-dark'
        }
      ] : [],
      ...showScheduleExport ? [
        {
          label: 'Schedule Export',
          className: getClassNames('schedule-btn', {disabled: isScheduleExportBtnDisabled}),
          onClick: this.toggleAddScheduleModal,
          color: 'outline-dark',
          id: 'schedule-btn',
          disabled: disabled
        }
      ] : []
    ];
  }

  render() {
    const { loadedQuery, disabled, authService } = this.props;
    const { isAddScheduleModalOpen, isShareReportModalOpen } = this.state;

    const showScheduleTooltip = this.isScheduleExportBtnDisabled() && !disabled;
    return (
      <>
        {authService.isSchedulerEnabled() && !!loadedQuery && !reportSharedWithMe(loadedQuery) &&
          loadedQuery.permissions && loadedQuery.permissions.canSchedule &&
          <UncontrolledTooltip
            placement="top"
            target="schedule-btn"
            className={getClassNames({'d-none': !showScheduleTooltip})}
          >
            Save your report first to schedule exports
          </UncontrolledTooltip>
        }
        <SplitButtonDropdown
          className="action-btn-group"
          buttonItems={this.getSplitButtonItems()}
          dropdownItems={[]}
          right={true}
          disabled={this.props.disabled}
        />
        {loadedQuery &&
          <AddScheduleModal
            loadedQuery={loadedQuery}
            isOpen={isAddScheduleModalOpen}
            toggle={this.toggleAddScheduleModal}
            scheduleLocation={CRT_SCHEDULE_LOCATION_REPORT_BUILDER}
          />
        }
        {loadedQuery && isShareReportModalOpen &&
          <ShareReportModal
            showModal={isShareReportModalOpen}
            closeModal={this.setShareReportModalFlag(false)}
            reportId={loadedQuery.id}
          />
        }
      </>
    );
  }

  getTempFormData(): SaveQueryFormData {
    return {
      name: uuidv4(),
      description: 'This is a temporary report record',
      userReport: false,
      suggestedReport: false,
      tempReport: true,
    };
  }

  doCsvExport = async () => {
    if (this.state.exporting) {
      return;
    }
    
    this.setState({
      exporting: true
    });

    try {
      let result = await ExportService.handleDownload(
        this.props.dispatch,
        this.props.query,
        this.props.reportTitle,
        this.props.queryId,
        this.props.jobId
      );
      this.onExportSuccess(!!result);
    } finally {
      this.onExportFinally();
    }
  }

  onExportSuccess = (result: boolean) => {
    if (result) {
      let reportTitle = (this.props.reportTitle) ? '<' + this.props.reportTitle + '>'
        : '<untitled>';
      this.props.notificationService.addNotification(
        NOTIFICATION_SUCCESS,
        'Your ' + reportTitle + ' report is being created. To see its progress go to the downloads page.',
        this.renderDownloadsButton()
      );
    } else {
      this.props.notificationService.addNotification(
        NOTIFICATION_ERROR,
        'There was an issue creating the report.'
      );
    }

  }

  onExportFinally = () => {
    this.setState({
      exporting: false
    });
  }

  componentWillUnmount() {
    this.onExportFinally = noop;
  }

  renderDownloadsButton() {
    return (
      <button
        className="btn btn-primary"
        onClick={() => window.open('/downloads', '_self')}
        style={{marginTop: 5}}
      >
        Downloads
      </button>
    );
  }

  toggleAddScheduleModal = () => {
    // Need to do this as we can't add the disabled attribute to the button and also show the tooltip.
    if (this.isScheduleExportBtnDisabled()) {
      return;
    }

    this.setState(({ isAddScheduleModalOpen }) => ({ isAddScheduleModalOpen: !isAddScheduleModalOpen }));
  }
}

const ActionButtons = connect(
  mapStateToProps)(ActionButtonsContainer);
export default ActionButtons;
