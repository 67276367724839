import React, { ComponentProps, useEffect } from 'react';
import { ContentModal as Modal, ControlsProps } from '@eds/modal';

import { useAppContext } from 'src/features/common';

import { CustomHeader } from './custom-header.component';
import { CustomFooter } from './custom-footer.component';

const noop = () => {};

type ContentModalProps = Omit<ComponentProps<typeof Modal>, 'controls'> & {
  controls: ControlsProps;
  dataTracking?: string;
  preventAutoClose?: boolean;
};

export const ContentModal = ({
  children,
  controls,
  dataTracking,
  header,
  isOpen,
  onClose,
  preventAutoClose,
  ...props
}: ContentModalProps) => {
  const { setIsModalOpen } = useAppContext();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen, setIsModalOpen]);

  return (
    <Modal
      footer={<CustomFooter controls={controls} dataTracking={dataTracking} />}
      header={<CustomHeader header={header} onClose={onClose} />}
      isOpen={isOpen}
      onClose={preventAutoClose ? noop : onClose}
      {...props}
    >
      {children}
    </Modal>
  );
};
