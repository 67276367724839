import * as React from 'react';
import cn from 'classnames';

import { IconProps } from './types';

export const ReportBuilderIcon = ({
  color = 'currentColor',
  className,
  width = 24,
  height = 24,
}: IconProps) => {
  return (
    <span className="elmo-icon">
      <svg
        className={cn('elmo-icon_svg', { [className as string]: !!className })}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.61 18.99L13.53 9.90997C14.46 7.56997 13.98 4.80997 12.09 2.90997C9.78996 0.609971 6.20996 0.399971
          3.65996 2.25997L7.49996 6.10997L6.07996 7.51997L2.24996 3.68997C0.389961 6.22997 0.599962 9.81997 2.89996
          12.11C4.75996 13.97 7.46996 14.46 9.78996 13.59L18.9 22.7C19.29 23.09 19.92 23.09 20.31 22.7L22.61
          20.4C23.01 20.02 23.01 19.39 22.61 18.99ZM19.61 20.59L10.15 11.13C9.53996 11.58 8.85996 11.85 8.14996
          11.95C6.78996 12.15 5.35996 11.74 4.31996 10.7C3.36996 9.75997 2.92996 8.49997 2.99996 7.25997L6.08996
          10.35L10.33 6.10997L7.23996 3.01997C8.47996 2.94997 9.72996 3.38997 10.68 4.32997C11.76 5.40997 12.17
          6.89997 11.92 8.28997C11.8 8.99997 11.5 9.65997 11.04 10.25L20.49 19.7L19.61 20.59Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
