import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AppState, ViewJoinAndDimensionOrMeasure } from '../../../redux';

export function usePopulateOptions() {
  const { translations } = useSelector((state: AppState) => state);

  return useCallback(
    ({ dimension, join, view }: ViewJoinAndDimensionOrMeasure) => {
      const dimensionName = dimension ? dimension.name : '';
      const viewName = join ? join.viewName : view.name;
      const translationIndex = [viewName, dimensionName].join('.');
      const translationsFound = translations[translationIndex];
      
      const mappedTranslations: Array<{ label: string, value: unknown }> = [];
    
      if (translationsFound && translationsFound.hasOwnProperty('value')) {
        const translationsFoundValues = translationsFound.value;
        const translationsFoundTranslated = translationsFound.translated;
        
        if (translationsFoundValues) {
          Object.entries(translationsFoundValues).forEach(
            ([t, i]) => mappedTranslations.push({ value: i, label: translationsFoundTranslated[t] })
          );
        }
      }
    
      return mappedTranslations;
    },
    [translations]
  );
}