import * as React from 'react';
import useOnClickOutsideSuggestedReportsPopover from '../../components/hooks/useOnClickOutsideSuggestedReportsPopover';
import { useSuggestedReportsPopover } from './SuggestedReportsPopoverContext';

const { useCallback, useEffect } = React;

const SuggestedReportsPopoverController: React.FC = ({ children }) => {
  const { 
    popoverRef,
    treeNodeRef,
    shouldClose, 
    shouldOpen, 
    setShouldClose, 
    setShouldOpen 
  } = useSuggestedReportsPopover();

  const handler = useCallback(() => setShouldClose(true), []);

  // Reset the values immediately
  useEffect(() => shouldClose ? setShouldClose(false) : undefined, [shouldClose]);
  useEffect(() => shouldOpen ? setShouldOpen(false) : undefined, [shouldOpen]);

  useOnClickOutsideSuggestedReportsPopover(popoverRef, treeNodeRef, handler);

  return <>{children}</>;
};

export default SuggestedReportsPopoverController;
