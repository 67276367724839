import { AppNotification } from 'src/features/api-feedback';

import {
  ADD_NOTIFICATION, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS,
  REMOVE_NOTIFICATION, SET_NOTIFICATION_SERVICE
} from '../constants';
import { NotificationService } from '../services/notificationService';

export interface AppNotificationAction {
  type: ADD_NOTIFICATION | REMOVE_NOTIFICATION;
  notification: AppNotification;
}

export function addNotification(
  notificationLevel: NOTIFICATION_SUCCESS | NOTIFICATION_ERROR,
  text: string,
  id: number,
  children?: React.ReactNode): AppNotificationAction {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      type: notificationLevel,
      text: text,
      id: id,
      children: children
    }
  };
}

export const addDashboardItemAddedNotification = (id: number, dashboardId: string): AppNotificationAction => {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      type: 'NOTIFICATION_ADD_TO_DASHBOARD_SUCCESS',
      dashboardId,
      id,
    },
  };
};

export function removeNotification(id: number): AppNotificationAction {
  return {
    type: REMOVE_NOTIFICATION,
    notification: {
      id: id
    }
  };
}

export interface AppNotificationServiceAction {
  type: SET_NOTIFICATION_SERVICE;
  notificationService: NotificationService;
}

export function setNotificationService(notificationService: NotificationService) {
  return {
    type: SET_NOTIFICATION_SERVICE,
    notificationService: notificationService
  };
}