import { useState } from 'react';

const defaultReportToSchedule = { id: '', name: '' };
const defaultReportToShare = { id: '', name: '', sharedWith: [] as string[] };

export const useReportsPage = () => {
  const [legacyReportInfoToggle, setLegacyReportInfoToggle] = useState(false);
  const [reportToDelete, setReportToDelete] = useState('');
  const [reportToExport, setReportToExport] = useState('');
  const [reportToSchedule, setReportToSchedule] = useState(defaultReportToSchedule);
  const [reportToShare, setReportToShare] = useState(defaultReportToShare);

  const showLegacyReportInfo = () => setLegacyReportInfoToggle(true);
  const startDeleting = setReportToDelete;
  const startScheduling = (id: string, name: string) => setReportToSchedule({ id, name });
  const startExporting = setReportToExport;
  const startSharing = (id: string, name: string, sharedWith?: string[]) =>
    setReportToShare({ id, name, sharedWith: sharedWith || [] });

  const hideLegacyReportInfo = () => setLegacyReportInfoToggle(false);
  const stopDeleting = () => setReportToDelete('');
  const stopExporting = () => setReportToExport('');
  const stopScheduling = () => setReportToSchedule(defaultReportToSchedule);
  const stopSharing = () => setReportToShare(defaultReportToShare);

  return {
    hideLegacyReportInfo,
    legacyReportInfoToggle,
    reportToDelete,
    reportToExport,
    reportToSchedule,
    reportToShare,
    showLegacyReportInfo,
    startDeleting,
    startExporting,
    startScheduling,
    startSharing,
    stopDeleting,
    stopExporting,
    stopScheduling,
    stopSharing,
  };
};
