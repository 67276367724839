import { RESET_REPORT_BUILDER, RESET_REPORT_BUILDER_COLUMNS } from '../constants';

export interface ReportBuilderAction {
  type: RESET_REPORT_BUILDER | RESET_REPORT_BUILDER_COLUMNS;
}

export function resetReportAction(): ReportBuilderAction {
  return {
    type: RESET_REPORT_BUILDER
  };
}

export function resetReportColumnsAction(): ReportBuilderAction {
  return {
    type: RESET_REPORT_BUILDER_COLUMNS
  };
}