import * as React from 'react';
import { BasicModal } from '../../../shared-components/BasicModal';
import { Record } from 'immutable';
import { ConfigJoin } from '../../../types/config/configJoinModel';

interface ConfigEditJoinModalProps {
  toggle: () => void;
  isOpen: boolean;
  join: Record<ConfigJoin>;
  onDone: (c: ConfigEditJoinForm) => void;
}

interface ConfigEditJoinModalState {
  form: ConfigEditJoinForm;
}

export interface ConfigEditJoinForm {
  label: string;
}

class ConfigEditJoinModal extends
  React.Component<ConfigEditJoinModalProps, ConfigEditJoinModalState> {

  constructor(props: ConfigEditJoinModalProps) {
    super(props);

    this.state = {
      form: {
        label: ''
      }
    };
  }

  componentDidUpdate(prevProps: ConfigEditJoinModalProps) {
    if (!this.props.join.equals(prevProps.join)) {
      this.setState({
        form: {
          label: this.props.join.get('label', '')
        }
      });
    }
  }

  handleInputChange = (property: string) => (event: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => {
    let form = Object.assign({}, this.state.form);

    form[property] = event.currentTarget.type === 'checkbox' ?
      event.currentTarget['checked'] : event.currentTarget.value;

    this.setState({
      form: form
    });
  }

  renderContent() {
    return (
      <form>
        <div className="form-group row">
          <label
            htmlFor="label"
            className="col-3 col-form-label"
          >
            Label:
          </label>
          <div className="col">
            <input
              type="text"
              value={this.state.form.label}
              onChange={this.handleInputChange('label')}
              className="form-control"
            />
          </div>
        </div>
      </form>
    );
  }

  onDone = () => {
    this.props.onDone(this.state.form);
    this.props.toggle();
  }

  render() {
    return (
      <div id="edit-join">
        <BasicModal
          title="Edit Join"
          className="edit-join-modal"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          confirmButtonText="OK"
          onConfirmClicked={this.onDone}
        >
          {this.renderContent()}
        </BasicModal>
      </div>
    );
  }
}

export default ConfigEditJoinModal;