import React from 'react';

import { ChartDisplayType } from 'src/features/dashboards';

import { BarChartIllustration } from './bar-chart-illustration.component';
import { ColumnChartIllustration } from './column-chart-illustration.component';
import { LineChartIllustration } from './line-chart-illustration.component';
import { PieChartIllustration } from './pie-chart-illustration.component';
import { ReportIllustration } from './report-illustration.component';
import { TextIllustration } from './text-illustration.component';

type DashboardCardIllustrationProps = {
  displayType?: ChartDisplayType;
  hasQuery: boolean;
};

export const DashboardCardIllustration = ({ displayType, hasQuery }: DashboardCardIllustrationProps) => {
  if (!displayType && hasQuery) {
    return <ReportIllustration />;
  }

  switch (displayType) {
    case 'bar':
      return <BarChartIllustration />;
    case 'column':
      return <ColumnChartIllustration />;
    case 'line':
      return <LineChartIllustration />;
    case 'pie':
      return <PieChartIllustration />;
    case 'text':
      return <TextIllustration />;
    default:
      return null;
  }
};
