import React, { useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';

import {
  ScheduledReportFieldValues,
  ScheduledReportsModal,
  useScheduledReportMutation,
} from 'src/features/scheduled-reports';

import { useScheduledReportDetailsQuery } from './edit-scheduled-report.queries';

interface EditScheduledReportProps {
  isOpen: boolean;
  onClose: () => void;
  scheduledReportId: string;
}

export const EditScheduledReport = ({ isOpen, onClose, scheduledReportId }: EditScheduledReportProps) => {
  const [successMessage, setSuccessMessage] = useState<symbol>();
  const { data: scheduledReport, refetch } = useScheduledReportDetailsQuery(scheduledReportId);

  const { isLoading: loading, mutate } = useScheduledReportMutation('edit', () => {
    onClose();
    setSuccessMessage(toastMessage('Schedule updated'));
    /**
     * NOTE: useScheduledReportDetailsQuery uses the same query key as useScheduledReportsListQuery and will
     * effectively refetch the scheduled reports list
     */
    refetch();
  });

  const onSubmit = (values: ScheduledReportFieldValues) => mutate(values);

  return (
    <Box>
      <ScheduledReportsModal
        isOpen={isOpen}
        loading={loading}
        mode="edit"
        onClose={onClose}
        onSubmit={onSubmit}
        scheduledReport={scheduledReport}
      />
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
