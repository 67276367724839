import axios, { AxiosResponse } from 'axios';
import memoize from 'memoizee';
import { View } from '../types/viewModel';
import { SupportedCountriesType } from './authService';

/**
 * Memoized version of apiGetViews. maxAge is in ms - fetch every hour.
 */
const memoizeApiGetViews = memoize(apiGetViews, { promise: true, maxAge: 60 * 60 * 1000 });
export { memoizeApiGetViews };

export async function apiGetViews(
  moduleName: string,
  countries?: SupportedCountriesType,
  reportId?: string
): Promise<View[]> {
  const response: AxiosResponse = await axios.get(`/api/modules/${moduleName}/views`, {
    headers: {
      'Cache-Control': 'max-age=60',
    },
    params: { countries, reportId },
  });

  return response.data;
}
