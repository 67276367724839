import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const ColumnChartTypeIcon = ({ className, width = 14, height = 14, color = '#424448' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 4.2H3V14H0V4.2ZM5.6 0H8.4V14H5.6V0ZM11.2 8H14V14H11.2V8Z" fill={color}/>
    </svg>
  );
};
