import * as React from 'react';
import { Select, FormItem, Heading, Row, Col } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import { isArray, compact } from 'lodash';

import { colMetaOptions } from '../../services/columnMetaService';
import { ColumnMeta } from '../../types/columnMetaModel';
import {
  CHART_TYPE_BAR,
  CHART_TYPE_LINE,
  CHART_TYPE_COLUMN,
  CHART_TYPE_PIE,
  CHART_TYPE_TEXT,
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES
} from '../../constants';
import { ChartForm, FormError } from './types';

import './style.css';

type ChartQueryGroupProps = {
  columnMeta: ColumnMeta[];
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  formData: ChartForm;
  formError: FormError;
  columnMetaForGroupBy: (forGroupBy: boolean) => ColumnMeta[];
  handleFormDataChange: (formData: ChartForm) => void;
};

const ChartQueryGroup = (props: ChartQueryGroupProps) => {
  const isMultiGroupBySelect = props.formData.type === CHART_TYPE_COLUMN;

  const getChartQueryTypeTitle = () => {
    switch (props.formData.type) {
      case CHART_TYPE_COLUMN:
        return 'Column';
      case CHART_TYPE_BAR:
        return 'Bar';
      case CHART_TYPE_PIE:
        return 'Pie';
      case CHART_TYPE_LINE:
        return 'Line';
      case CHART_TYPE_TEXT:
        return 'Text';
      default:
        return '';
    }
  };

  const getGroupByMessage = () => isMultiGroupBySelect ? 'Max 2' : undefined;

  const renderGroupByOptions = () => {
    const colMetas: ColumnMeta[] = colMetaOptions(props.columnMeta, props.dataType, true);

    if (isMultiGroupBySelect && props.formData.selectedXAxisOption && props.formData.selectedXAxisOption.length === 2) {
      return [];
    }

    return colMetas.map((colMeta: ColumnMeta, i: number) => {
      return { value: i.toString(), label: colMeta.displayLabel };
    });
  };

  const selectGroupBy = (event: Array<SelectPropsOption> | SelectPropsOption) => {
    const groupByValue: Array<SelectPropsOption> = isArray(event) ? event : [event];
    let formData = Object.assign({}, props.formData);

    formData.xAxis = undefined;
    if (groupByValue.length > 0) {
      const filteredColMetaOptions = colMetaOptions(props.columnMeta, props.dataType, true);
      const xAxis = groupByValue.map((v: any) => {
        return filteredColMetaOptions.find((colMeta: ColumnMeta) => v.label === colMeta.displayLabel);
      });
      formData.xAxis = compact(xAxis);
    }

    formData.selectedXAxisOption = groupByValue;
    props.handleFormDataChange(formData);
  };

  const renderMeasuresOption = () => {
    const columnMetas: ColumnMeta[] = props.columnMetaForGroupBy(false);
    return columnMetas.map((colMeta: ColumnMeta, i: number) => {
      return { value: i.toString(), label: colMeta.displayLabel };
    });
  };

  const getMeasureOptionValue = () => {
    const columnMetas: ColumnMeta[] = props.columnMetaForGroupBy(false);
    const measureOption = columnMetas.find(
      (colMeta: ColumnMeta, i: number) => i.toString() === props.formData.selectedYAxisOption
    );
    return measureOption && props.formData.selectedYAxisOption ?
      { value: props.formData.selectedYAxisOption, label: measureOption.displayLabel } : undefined;
  };

  const selectMeasure = (event: SelectPropsOption) => {
    const formData = Object.assign({}, props.formData);

    formData.yAxis = event.value ? colMetaOptions(
      props.columnMeta,
      props.dataType,
      false)[event.value] : null;

    formData.selectedYAxisOption = event.value;
    props.handleFormDataChange(formData);
  };

  return (
    <Row>
      <Col>
        <Heading htmlTag="h4" type="title" size="sm">{getChartQueryTypeTitle()} Chart Query</Heading>
      </Col>
      {(props.formData.type === CHART_TYPE_PIE || props.formData.type === CHART_TYPE_TEXT) &&
        <Col>
          <FormItem
            label="Measure"
            message={!!props.formError.yAxis ? props.formError.yAxis : undefined}
            status={!!props.formError.yAxis ? 'error' : undefined}
          >
            <Select
              ariaLabel="Measure"
              name="measure"
              onChange={selectMeasure}
              options={renderMeasuresOption()}
              value={getMeasureOptionValue()}
              placeholder="Choose one measure"
              data-testid="chart-measure-select"
            />
          </FormItem>
        </Col>
      }
      {props.formData.type !== CHART_TYPE_TEXT &&
        <Col>
          <FormItem
            label="Group By"
            message={!!props.formError.xAxis ? props.formError.xAxis : getGroupByMessage()}
            status={!!props.formError.xAxis ? 'error' : undefined}
          >
            <Select
              ariaLabel="Group By"
              name="groupBy"
              onChange={selectGroupBy}
              isMulti={isMultiGroupBySelect}
              options={renderGroupByOptions()}
              value={props.formData.selectedXAxisOption}
              placeholder={isMultiGroupBySelect ? 'Choose up to 2' : 'Chose one'}
              data-testid="chart-group-by-select"
            />
          </FormItem>
        </Col>
      }
    </Row>
  );
};

export default ChartQueryGroup;
