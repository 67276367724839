import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const GenerateChartButtonIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20009 4H11.8001V17H9.20009V4ZM4 7.89995H6.78571V17H4V7.89995ZM17 11.4286H14.4V17H17V11.4286Z"
      />
    </svg>
  );
};
