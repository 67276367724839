import React from 'react';
import { Box } from '@eds/box';
import { Pagination, PaginationState } from '@eds/pagination';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

interface ScheduledReportsListPaginationProps {
  onPageUpdate: ({ currentItemsPerPage, currentPage, rangeStart }: PaginationState) => void;
  paginationData: Pick<PaginationState, 'currentPage' | 'currentItemsPerPage' | 'rangeStart'>;
  totalItems: number;
}

export const ScheduledReportsListPagination = ({
  onPageUpdate,
  paginationData,
  totalItems,
}: ScheduledReportsListPaginationProps) => {
  return (
    <Box className={css(cssOverrides.button('ghost'))}>
      <Pagination
        currentPage={paginationData.currentPage}
        currentItemsPerPage={paginationData.currentItemsPerPage}
        itemsCount={totalItems}
        onPageUpdate={onPageUpdate}
      />
    </Box>
  );
};
