import Report from '../../../containers/NewReportBuilder/Report';
import * as React from 'react';
import './style.css';
import { SavedQuery } from '../../../types/savedQueryModel';
import ChartSelect from '../../../containers/NewReportBuilder/ChartSelect';
import { View } from '../../../types/viewModel';
import { GENERATE_ACTION } from '../../../constants';
import { ReportTable } from '../../../types/reportTableModel';
import { Chart } from '../../../types/chartModel';
import ReportBuilderPanel from '../../../containers/NewReportBuilder/ReportBuilderPanel';
import ReportDataProcessing from '../ReportDataProcessing';

interface ReportViewOnlyPageProps {
  loadedQuery?: SavedQuery;
  encodedQuery: string | undefined;
  selectedView: View;
  suggestionsOpen: boolean;
  generateReportStatus: GENERATE_ACTION;
  reportResult: ReportTable;
  chart: Chart;
  queryId: string;
  reportTitle: string;
}

export class ReportViewOnlyPage extends React.Component<ReportViewOnlyPageProps> {

  protected filterSelectRef: any;

  bindFilterSelectRef = (instance: any) => {
    this.filterSelectRef = instance;
  }

  render() {
    const {
      encodedQuery,
      queryId,
      loadedQuery,
      chart,
      reportTitle
    } = this.props;
    
    return (
      <div className="reporting-read-only-page">
        <ReportBuilderPanel
          title={reportTitle}
          encodedQuery={encodedQuery}
          queryId={queryId}
          loadedQuery={loadedQuery}
          readOnly={true}
        />
        <div className="row no-gutters">
          <div className="col right-col">
            <div className="scroll">
              <ReportDataProcessing />
              <div className="reportArea">
                {chart.chartId &&
                  <ChartSelect loadedQuery={loadedQuery} readOnly={true}/>
                }
                <Report
                  loadedQuery={loadedQuery}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
