import * as React from 'react';
import { FormItem, Radio, RadioButtons } from 'elmo-elements';

import { ColumnChartTypeIcon } from '../Icons/ColumnChartTypeIcon';
import { BarChartTypeIcon } from '../Icons/BarChartTypeIcon';
import { PieChartTypeIcon } from '../Icons/PieChartTypeIcon';
import { LineChartTypeIcon } from '../Icons/LineChartTypeIcon';
import { TextChartTypeIcon } from '../Icons/TextChartTypeIcon';
import {
  CHART_TYPE,
  CHART_TYPE_COLUMN,
  CHART_TYPE_BAR,
  CHART_TYPE_LINE,
  CHART_TYPE_PIE,
  CHART_TYPE_TEXT
} from '../../constants';
import { ColumnMeta } from '../../types/columnMetaModel';
import { ChartForm } from './types';

type ChartTypesRadioButtonsProps = {
  formData: ChartForm;
  handleFormDataChange: (formData: ChartForm) => void;
  columnMetaForGroupBy: (forGroupBy: boolean) => ColumnMeta[];
};

const SELECTED_RADIO_BTN_COLOR = '#19579F';
const ACTIVE_RADIO_BTN_COLOR = '#424448';
const INACTIVE_RADIO_BTN_COLOR = '#76777A';

const ChartTypesRadioButtons = (props: ChartTypesRadioButtonsProps) => {
  const columnMetas: ColumnMeta[] = props.columnMetaForGroupBy(true);
  const isGroupByDimensionsSelected = !!columnMetas.length;

  const selectChart = (chartType: CHART_TYPE) => {
    let formData = Object.assign({}, props.formData);
    formData.type = chartType;
    formData.xAxis = undefined;
    formData.selectedXAxisOption = [];
    if (chartType === CHART_TYPE_TEXT) {
      formData = { ...formData, limit: undefined};
    }
    if (chartType !== CHART_TYPE_TEXT && chartType !== CHART_TYPE_PIE) {
      formData = { ...formData, yAxis: undefined, selectedYAxisOption: undefined };
    }

    props.handleFormDataChange(formData);
  };

  const getIconColor = (chartType: CHART_TYPE) => {
    if (props.formData.type === chartType) {
      return SELECTED_RADIO_BTN_COLOR;
    } else if (!isGroupByDimensionsSelected) {
      return INACTIVE_RADIO_BTN_COLOR;
    }
    return ACTIVE_RADIO_BTN_COLOR;
  };

  React.useEffect(
    () => {
      if (!isGroupByDimensionsSelected) {
        selectChart(CHART_TYPE_TEXT);
      }
    },
    []
  );

  return (
    <FormItem label="Chart Type">
      <RadioButtons
        type="solid"
        selected={props.formData.type}
        onChange={selectChart}
      >
        <Radio
          value={CHART_TYPE_COLUMN}
          tooltip="Basic Column"
          id="basiccol-btn"
          data-testid="chart-type-col"
          disabled={!isGroupByDimensionsSelected}
        >
          <ColumnChartTypeIcon color={getIconColor(CHART_TYPE_COLUMN)}/>
        </Radio>
        <Radio
          value={CHART_TYPE_BAR}
          tooltip="Basic Bar"
          id="basicbar-btn"
          data-testid="chart-type-bar"
          disabled={!isGroupByDimensionsSelected}
        >
          <BarChartTypeIcon color={getIconColor(CHART_TYPE_BAR)}/>
        </Radio>
        <Radio
          value={CHART_TYPE_PIE}
          tooltip="Pie"
          id="pie-btn"
          data-testid="chart-type-pie"
          disabled={!isGroupByDimensionsSelected}
        >
          <PieChartTypeIcon color={getIconColor(CHART_TYPE_PIE)}/>
        </Radio>
        <Radio
          value={CHART_TYPE_LINE}
          tooltip="Basic Line"
          id="basicline-btn"
          data-testid="chart-type-line"
          disabled={!isGroupByDimensionsSelected}
        >
          <LineChartTypeIcon color={getIconColor(CHART_TYPE_LINE)}/>
        </Radio>
        <Radio
          value={CHART_TYPE_TEXT}
          tooltip="Text Widget"
          id="text-btn"
          data-testid="chart-type-text"
        >
          <TextChartTypeIcon color={getIconColor(CHART_TYPE_TEXT)}/>
        </Radio>
      </RadioButtons>
    </FormItem>
  );
};

export default ChartTypesRadioButtons;
