import * as React from 'react';
import { Button, Modal } from 'elmo-elements';
import { DrilldownReport } from '../../redux';
import './style.css';
import LoadingIcon from '../../shared-components/LoadingIcon';
import { UncontrolledAlert } from 'reactstrap';
import { ReportRow } from '../../types/reportRowModel';
import { ReportCell } from '../../types/reportCellModel';
import SimplePagination from '../../components/SimplePagination';

interface DrilldownDisplayModalProps {
  data: DrilldownReport | null;
  showModal: boolean;
  toggleModal: () => void;
  onPageChange: (offset: number, limit: number) => void;
  showFanOutMessage: boolean;
  export: any;
}

interface DrilldownDisplayModalState {
  loading: boolean;
}

class DrilldownDisplayModal extends React.Component<DrilldownDisplayModalProps, DrilldownDisplayModalState> {
  constructor(props: DrilldownDisplayModalProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(newProps: DrilldownDisplayModalProps) {
    if (!newProps.data) {
      this.setState({
        loading: true
      });
    }

    if (!this.props.data && newProps.data) {
      this.setState({
        loading: false
      });
    }
  }

  renderHeader(rowData: ReportRow) {
    if (!rowData) {
      return null;
    }

    return (
      <tr className="sub-report-row">
        {
          rowData.cells.map((cell: ReportCell, i: number) => {
            return <th key={i}>{cell.data.label}</th>;
          })
        }
      </tr>
    );
  }

  renderCell(cell: ReportCell, key: number) {
    return (
      <td key={'c_' + key}>
        {cell.data.value}
      </td>
    );
  }

  renderRow(row: ReportRow, key: number) {
    return (
      <tr key={'r_' + key}>
        {
          row.cells.map((cell: ReportCell, i: number) => {
            return this.renderCell(cell, i);
          })
        }
      </tr>
    );
  }

  renderDrilldownData() {
    if (!this.props.data) {
      return null;
    }

    return (
      <div className="table-responsive">
        <table className="table table-sm table-striped table-hover">
          <thead>
          {this.renderHeader(this.props.data.report.rows[0])}
          </thead>
          <tbody>
          {
            this.props.data.report.rows.map((row: ReportRow, i: number) => {
              return this.renderRow(row, i);
            })
          }
          </tbody>
        </table>
      </div>
    );
  }

  onPageChange = (pageNumber: number, offset: number, limit: number) => {
    this.props.onPageChange(offset, limit);
  }

  render() {
    let displayDataClassName: string = !this.state.loading ? '' : 'd-none';
    return (
        <Modal
            id="drilldown-modal"
            isOpened={this.props.showModal}
            closeModal={this.props.toggleModal}
            type="x-large"
            title="Drilldown"
            className="drilldown-modal"
            primaryButton={(
                  <Button
                      type="primary"
                      onClick={this.props.export}
                      isDisabled={this.state.loading}
                  >
                    Export
                  </Button>
              )}
        >
          {this.state.loading && <LoadingIcon/>}
          <div className={displayDataClassName}>
            {this.props.showFanOutMessage &&
            <UncontrolledAlert color="info">
              A fan out occurred. This occurs when a joining table causes the base table to have more
              rows than originally. Eg there may be been one row per UserId but then a table is
              joined where there is multiple rows for a UserId.
            </UncontrolledAlert>
            }

            <div className="drilldown-modal-data" data-testid="drilldown-modal-data">
              {this.renderDrilldownData()}
            </div>
            {this.props.data &&
            <SimplePagination
                handlePageClick={this.onPageChange}
                limit={this.props.data.limit ? this.props.data.limit : 0}
                offset={this.props.data.offset ? this.props.data.offset : 0}
                nextEnabled={!!this.props.data.report.links && this.props.data.report.links.next}
                prevEnabled={!!this.props.data.report.links && this.props.data.report.links.prev}
            />
            }
          </div>
        </Modal>
    );
  }
}

export default DrilldownDisplayModal;
