import { useSelector } from 'react-redux';

import { AppState } from 'src/redux';

export const useExperimentalViewsAndJoinsChecker = () => {
  const { columnMeta = [], views } = useSelector((state: AppState) => state);

  const experimentalViewsAndJoins = views.reduce<string[]>((items, { isExperimental, joins, name }) => {
    const result = [...items];

    if (isExperimental) result.push(name);

    joins.forEach(join => {
      if (join.isExperimental) result.push(join.name);
    });

    return result;
  }, []);

  return columnMeta.some(
    ({ join, view }) =>
      view.isExperimental ||
      (join &&
        [join.fullJoinName, join.parentJoinNamesArray[0]].some(joinName =>
          experimentalViewsAndJoins.includes(joinName)
        ))
  );
};
