var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import ToggleContext from './components/ToggleContext';
import ToggleOn from './components/ToggleOn';
import ToggleOff from './components/ToggleOff';
import ToggleButton from './components/ToggleButton';
var Toggle = /** @class */ (function (_super) {
    __extends(Toggle, _super);
    function Toggle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggle = function () {
            return _this.setState(function (_a) {
                var isOn = _a.isOn;
                return ({ isOn: !isOn });
            }, function () { return !_this.props.onClick(_this.state.isOn); });
        };
        // eslint-disable-next-line  @typescript-eslint/member-ordering
        _this.state = { isOn: false, toggle: _this.toggle };
        _this.getChildren = function () {
            var children = _this.props.children;
            if (children === undefined || children === null) {
                return React.createElement(ToggleButton, null);
            }
            return children;
        };
        return _this;
    }
    Toggle.prototype.componentDidMount = function () {
        var _a = this.props, isOn = _a.isOn, id = _a.id;
        this.setState(__assign(__assign({}, this.state), { isOn: isOn, id: id }));
    };
    Toggle.prototype.render = function () {
        return (React.createElement(ToggleContext.Provider, { value: this.state }, this.getChildren()));
    };
    Toggle.defaultProps = {
        isOn: false,
    };
    Toggle.On = ToggleOn;
    Toggle.Off = ToggleOff;
    Toggle.Button = ToggleButton;
    return Toggle;
}(Component));
export default Toggle;
