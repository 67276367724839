export const calendarDays = [1, -1, ...[...Array(29).keys()].splice(2)].map(value => {
  if (value === 1) return { label: 'First', value };
  if (value === -1) return { label: 'Last', value };
  return { label: `${value}`, value };
});

export const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
  (label, index) => ({
    label,
    value: index + 1,
  })
);

export const timeOptions = [...Array(24).keys()].map(value => ({
  label: `${`${value}`.padStart(2, '0')}:00`,
  value: `${`${value}`.padStart(2, '0')}:00:00`,
}));
