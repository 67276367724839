import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { getUserUuid } from 'src/features/utils';
import { reportsKeys } from 'src/features/reports';

type ShareReportUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string | null;
  role: string;
};

export type ShareReportUserOption = {
  avatar: string;
  email: string;
  id: string;
  label: string;
  role: string;
  value: string;
};

type ShareReportUsersParam = {
  ids?: string[];
  text?: string;
};

export const shareReportKeys = {
  all: ['share-report'] as const,
  users: (filters: ShareReportUsersParam) => [...shareReportKeys.all, 'users', { filters }] as const,
};

const fetchShareReportUsers = async (params?: ShareReportUsersParam) => {
  const response = await axios.get<ShareReportUser[]>('/api/share-report/users', { params });
  return response.data;
};

const updateReportSharing = async (id: string, sharedWith: string[]) => {
  const response = await axios.post(`/api/share-report/${id}`, { sharedWith });
  return response.data;
};

const userRoles = {
  ROLE_CRT_SUPER_ADMIN: 'ELMO Super Admin',
  ROLE_SUPER: 'Company Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_EMPLOYEE: 'Employee',
};

export const useShareReportUsersQuery = (enabled: boolean, shareReportUsersParam: ShareReportUsersParam) =>
  useQuery({
    enabled,
    queryKey: shareReportKeys.users(shareReportUsersParam),
    queryFn: () => fetchShareReportUsers(shareReportUsersParam),
    select: data =>
      data
        .map<ShareReportUserOption>(user => ({
          avatar: user.profileImage || '',
          email: user.email,
          id: user.id,
          label: `${user.firstName} ${user.lastName}`.trim(),
          role: userRoles[user.role],
          value: user.id,
        }))
        .filter(({ id }) => id !== getUserUuid()),
  });

export const useShareReportMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const query = queryClient.getQueryCache().findAll(reportsKeys.lists(), { type: 'active' }).shift();

  return useMutation<unknown, unknown, { id: string; targetUsers: string[] }>({
    mutationFn: ({ id, targetUsers }) => {
      return updateReportSharing(id, targetUsers);
    },
    onSettled: () => query && queryClient.invalidateQueries({ queryKey: query.queryKey }),
    onSuccess,
  });
};
