import * as React from 'react';
import { AddIcon } from 'elmo-elements';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { Flex } from '@eds/flex';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

import CollapseCard from '../CollapseCard';
import ElmoButton from '../ElmoButton';
import GenerateReportButton from '../../../containers/NewReportBuilder/GenerateReportButton';

interface FilterCollapseProps {
  addFilterGroup?: () => void;
  canManageFilter: boolean;
  filterGroupsEnabled: boolean;
  isOpen: boolean;
  toggle: () => void;
}

const FilterCollapse: React.FC<FilterCollapseProps> = props => {
  const { addFilterGroup, canManageFilter, children, filterGroupsEnabled, isOpen, toggle } = props;

  return (
    <Box className="new-filter-select-container" marginBottom="large">
      <CollapseCard className="filter-section" isOpen={isOpen}>
        <Flex flexDirection="column" gap="large" padding="large">
          <Flex flexDirection="column">
            {children}
            {canManageFilter && filterGroupsEnabled && (
              <Flex
                className="add-filter-group-wrapper"
                data-tracking="add-filter-group"
                padding="xlarge"
                justifyContent="center"
                borderColor="neutralBold"
                borderStyle="dashed"
                borderWidth="thin"
                borderRadius="large"
              >
                <ElmoButton icon={<AddIcon size="sm" />} onClick={addFilterGroup} type="text">
                  Add filter group
                </ElmoButton>
              </Flex>
            )}
          </Flex>
          <Flex data-tracking="filter-controls" gap="small">
            <Box className={css(cssOverrides.button('neutral'))}>
              <Button
                data-tracking="cancel-filter-button"
                label="Cancel"
                onClick={toggle}
                size="small"
                tone="neutral"
              />
            </Box>
            {canManageFilter && (
              <GenerateReportButton isDisabled={false}>
                Apply Filter
              </GenerateReportButton>
            )}
          </Flex>
        </Flex>
      </CollapseCard>
    </Box>
  );
};

export default FilterCollapse;
