import { useState } from 'react';
import { ColumnSort } from '@eds/table';
import { PaginationState } from '@eds/pagination';

import { DashboardType } from './dashboards-list.queries';

export type TableColumn = {
  columnName: 'dashboard' | 'description' | 'modified' | 'owner';
  label: string;
  sortingKey?: string;
};

export const tableColumns: TableColumn[] = [
  { columnName: 'dashboard', label: 'Dashboard', sortingKey: 'title' },
  { columnName: 'owner', label: 'Owner' },
  { columnName: 'modified', label: 'Last modified', sortingKey: 'modified' },
  { columnName: 'description', label: 'Description', sortingKey: 'description' },
];

export const useDashboardsList = () => {
  const [dashboardTypeFilter, setDashboardTypeFilter] = useState<DashboardType[]>([]);
  const [keywordFilter, setKeywordFilter] = useState('');
  const [paginationState, setPaginationState] = useState({ currentItemsPerPage: 10, currentPage: 1, rangeStart: 1 });
  const [sortData, setSortData] = useState<ColumnSort>({ columnName: 'modified', sortDirection: 'descending' });

  const onPageUpdate = ({ currentItemsPerPage, currentPage, rangeStart }: PaginationState) => {
    setPaginationState({ currentItemsPerPage, currentPage, rangeStart });
  };

  return {
    currentItemsPerPage: paginationState.currentItemsPerPage,
    currentPage: paginationState.currentPage,
    dashboardTypeFilter,
    keywordFilter,
    onPageUpdate,
    onSort: setSortData,
    rangeStart: paginationState.rangeStart,
    setDashboardTypeFilter,
    setKeywordFilter,
    sortData,
  };
};
