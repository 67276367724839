export class User {
  id: number;
  firstName: string;
  lastName: string;
  type: number;
  email: string;
  roles: string[];
  branding: {
    logo: string;
    theme: string;
    'vertical-theme': string;
    'vertical-theme-logo': string;
    'profile-image': string;
  };
  capabilities: any;
  isAdmin: boolean;
  // Temp feature flags
  enablePdfExport: boolean;
  enableTour: boolean;
  enableDashboards: boolean;
  enableSchedulingOutbounder: boolean;
  permissions: {
    dashboard: {
      canCreateDashboard: boolean;
      canCreateSuggestedDashboard: boolean;
      canAddWidget: boolean;
    },
    navigation?: {
      canAccessReportBuilder: boolean;
    }
  };
  timezone: string;
  uuid: string;
  userCountry: string;
  clientCountries: string;
}
