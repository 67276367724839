import * as React from 'react';

export interface SelectAllButtonProps {
  onClick: () => void;
  active: boolean;
}

class SelectAllButton extends React.Component<SelectAllButtonProps> {

  constructor(props: SelectAllButtonProps) {
    super(props);
  }

  onClick = () => {
    this.props.onClick();
  }

  render() {

    return (
      <button
        type="button"
        className="check-button"
        onClick={this.onClick}
        role="checkbox"
        aria-checked={this.props.active}
        data-testid="select-all"
      >
        {this.props.active &&
        <i className="fa fa-check-square-o" />
        }
        {!this.props.active &&
        <i className="fa fa-square-o" />
        }
      </button>
    );
  }
}

export default SelectAllButton;