import React from 'react';
import { Flex } from '@eds/flex';

import { ScheduledReportsListFilters } from './scheduled-reports-list-filters.component';
import { ScheduledReportsListPagination } from './scheduled-reports-list-pagination.component';
import { ScheduledReportsListTable } from './scheduled-reports-list-table.component';
import { useScheduledReportsList } from './scheduled-reports-list.hooks';
import { useScheduledReportsListQuery } from './scheduled-reports-list.queries';

interface ScheduledReportsListProps {
  onDelete: (scheduledReportId: string) => void;
  onEdit: (scheduledReportId: string) => void;
}

export const ScheduledReportsList = (props: ScheduledReportsListProps) => {
  const { data, isLoading } = useScheduledReportsListQuery();

  const { filterData, onFilter, onPageUpdate, paginationData, totalItems, ...tableProps } = useScheduledReportsList(
    isLoading,
    data
  );

  return (
    <Flex
      width="100%"
      gap="medium"
      flexDirection="column"
      backgroundColor="neutral"
      padding="xxlarge"
    >
      <ScheduledReportsListFilters filterData={filterData} loading={isLoading} onFilter={onFilter} />
      <ScheduledReportsListTable {...props} {...tableProps} />
      <ScheduledReportsListPagination
        onPageUpdate={onPageUpdate}
        paginationData={paginationData}
        totalItems={totalItems}
      />
    </Flex>
  );
};
