import * as React from 'react';
import { connect } from 'react-redux';
import { Select } from 'elmo-elements';
import { Dispatch } from 'redux';
import { isArray } from 'lodash';
import { SelectPropsOption, ValueType } from 'elmo-elements/Select/type';

import { Filter } from '../../types/filterModel';
import { AppState, ViewJoinAndDimensionOrMeasure } from '../../redux';

type FilterMultiCreatableSelectContainerProps = {
  displayFilter: Filter;
  filterIndex: number;
  displayFilters: Filter[];
  filterOption: ViewJoinAndDimensionOrMeasure;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
  /* From redux */
  translations: any;
  dispatch: Dispatch<any>;
};

const mapStateToProps = (state: AppState) => ({
  translations: state.translations
});

class FilterMultiCreatableSelectContainer extends React.Component<FilterMultiCreatableSelectContainerProps> {

  /**
   * Triggered when text is typed
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {number} index
   */
  handleInputChange = (index: number) => (event: Array<SelectPropsOption>) => {
    let filter: any = this.props.displayFilters[index];
    filter.value = event;
    this.props.saveDisplayFilter(filter, index, false);
  }

  handleInputBlur = (index: number) => () => {
    // push the changes to the filters in the appstate
    this.props.saveDisplayFilter(this.props.displayFilters[index], index);
  }

  selectFilter = (index: number) => (select: Array<SelectPropsOption>) => {
    const filter: any = this.props.displayFilters[index];
    this.setState({
      selectedFilter: select
    });
    filter.value = select;
    this.props.saveDisplayFilter(filter, index);
  }

  getSelectValue = (filterValue: any) => {
    if (isArray(filterValue)) {
      return filterValue.map((v, i) => {
        return v.hasOwnProperty('value') && v.hasOwnProperty('label') ? v : { label: v, value: v };
      });
    } else if (!!filterValue) {
      return [{ label: filterValue, value: filterValue}];
    } else {
      return [];
    }
  }

  render() {
    const {displayFilter, translations, filterIndex, filterOption} = this.props;
    const value: ValueType = this.getSelectValue(displayFilter.value);
    const options: any = this.populateOptions(filterOption, translations);
    const hasTranslations: boolean = (options.length > 0);
    return (
      <>
        <div hidden={!hasTranslations} className="col-sm-4 filter-value">
          <Select
            name="filter-in-select"
            className="form-control"
            value={value}
            isMulti={true}
            data-testid="filter-in-select"
            options={options}
            onChange={this.selectFilter(filterIndex)}
          />
        </div>
        <div hidden={hasTranslations} className="col-sm-4 filter-value">
          <Select
            name="value"
            isCreatable={true}
            isMulti={true}
            value={value}
            placeholder="Enter a value"
            onChange={this.handleInputChange(filterIndex)}
            onBlur={this.handleInputBlur(filterIndex)}
          />
        </div>
      </>
    );
  }

  populateOptions(filterOption: ViewJoinAndDimensionOrMeasure, translations: any) {
    const dimensionName: string = filterOption.dimension ? filterOption.dimension.name : '';
    const viewName: string = filterOption.join ? filterOption.join.viewName : filterOption.view.name;
    const translationIndex: string = [viewName, dimensionName].join('.');
    const translationsFound = translations[translationIndex];
    let mappedTranslations: object[] = [];

    if (translationsFound && translationsFound.hasOwnProperty('value')) {
      const translationsFoundValues = translationsFound.value;
      const translationsFoundTranslated: any = translationsFound.translated;
      if (translationsFoundValues) {
        Object.entries(translationsFoundValues).forEach(
          ([t, i]) => mappedTranslations.push({value: i, label: translationsFoundTranslated[t]})
        );
      }
    }

    return mappedTranslations;
  }
}

const FilterTextSelect = connect(
  mapStateToProps)(FilterMultiCreatableSelectContainer);
export default FilterTextSelect;
