import { List, Record, Map } from 'immutable';
import { reduce } from 'lodash';

export const ORDER_MAX = 9999;
export interface ConfigTranslationModel {
  value: any;
  translated: any;
}

interface ConfigColumnTranslationModel {
  columnName: string;
  values: List<ConfigTranslationModel>;
  transformerKey: string;
  order: number;
}

export type ConfigColumnTranslationRecord = Record<ConfigColumnTranslationModel>;

interface ConfigViewTranslationModel {
  viewName: string;
  dimensionTranslations: Map<string, ConfigColumnTranslationRecord>;
  order: number;
}

export type ConfigViewTranslationRecord = Record<ConfigViewTranslationModel>;

export const ConfigColumnTranslationRecordFactory: Record.Factory<ConfigColumnTranslationModel> =
  Record<ConfigColumnTranslationModel>({
    columnName: '',
    values: List<ConfigTranslationModel>(),
    transformerKey: '',
    order: ORDER_MAX
  });

export const ConfigViewTranslationsRecordFactory: Record.Factory<ConfigViewTranslationModel> =
  Record<ConfigViewTranslationModel>({
    viewName: '',
    dimensionTranslations: Map<string, ConfigColumnTranslationRecord>(),
    order: ORDER_MAX
  });

export class ConfigTranslationsHydrator {
  static hydrateTranslationsMap(data: any): Map<string, ConfigViewTranslationRecord> {
    return reduce(
      Object.keys(data),
      (result: Map<string, ConfigViewTranslationRecord>, viewName: string, index: number) => {
        return result.set(
          viewName,
          ConfigTranslationsHydrator.hydrateConfigViewTranslation(data[viewName], viewName, index));
      },
      Map<string, ConfigViewTranslationRecord>());
  }

  static hydrateConfigViewTranslation(data: any, viewName: string, order: number): ConfigViewTranslationRecord {
    let dimensionTranslations = reduce(
      Object.keys(data['dimension']),
      (result: Map<string, ConfigColumnTranslationRecord>, dimensionName: string, index: number) => {

        data['dimension'][dimensionName] = {
          dimensionName: dimensionName,
          ...data['dimension'][dimensionName]
        };

        let columnRecord = ConfigTranslationsHydrator.hydrateConfigColumnTranslation(
          data['dimension'][dimensionName],
          index
        );

        return result.set(
          dimensionName,
          columnRecord
        );
      },
      Map<string, ConfigColumnTranslationRecord>());

    return new ConfigViewTranslationsRecordFactory({
      viewName: viewName,
      dimensionTranslations: dimensionTranslations,
      order: order
    });
  }

  static hydrateConfigColumnTranslation(data: any, order: number): ConfigColumnTranslationRecord {
    const values = data['values'] ? List(data['values']) : List();

    return new ConfigColumnTranslationRecordFactory({
      columnName: data['dimensionName'],
      values: values,
      transformerKey: data['transformerKey'],
      order: order
    } as any);
  }
}
