import { ADD_GROUP_BY, REMOVE_GROUP_BY } from '../constants/index';
import { GroupBy } from '../types/groupByModel';
import { View } from '../types/viewModel';
import { SOURCE_REPORT_HEADER, SOURCE_WIZARD } from '../constants';

export interface GroupByAction {
  type: ADD_GROUP_BY | REMOVE_GROUP_BY;
  groupBy: GroupBy;
  baseView: View;
  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD;
}

export function addGroupBy(groupBy: GroupBy,
                           baseView: View,
                           source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): GroupByAction {
  return {
    type: ADD_GROUP_BY,
    groupBy: groupBy,
    baseView: baseView,
    source: source
  };
}

export function removeGroupBy(groupBy: GroupBy,
                              baseView: View,
                              source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): GroupByAction {
  return {
    type: REMOVE_GROUP_BY,
    groupBy: groupBy,
    baseView: baseView,
    source: source
  };
}