import { truncate } from 'lodash';

export const truncateTextWithEllipsis = (text: string, length: number) => {
  return truncate(text, { length, separator: ' ' });
};

export const convertImageSrcToDataURL = (url: string, outputFormat?: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    if (img.complete || img.complete === undefined) {
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = url;
    }

    img.onload = function(this: HTMLImageElement) {
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const ctx = (canvas as HTMLCanvasElement).getContext('2d');

      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      let dataURL = '';

      if (!!ctx) {
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
      }
      resolve(dataURL);
    };
  });
};
