import * as React from 'react';

import { ColumnMeta } from '../../../types/columnMetaModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES } from '../../../constants';
import { ChartForm, FormError } from './types';
import ChartQueryGroup from './ChartQueryGroup';
import ConfigurationGroup from './ConfigurationGroup';
import ChartTypesRadioButtons from './ChartTypesRadioButtons';
import ChartTitleInput from './ChartTitleInput';
import ElmoModal from '../ElmoModal';
import ElmoButton from '../ElmoButton';

type GenerateChartModalProps = {
  showModal: boolean;
  showSaveError: boolean;
  columnMeta: ColumnMeta[];
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  formData: ChartForm;
  formError: FormError;
  toggle: () => void;
  generate: () => void;
  handleFormDataChange: (formData: ChartForm) => void;
};

const GenerateChartModal = (props: GenerateChartModalProps) => {
  const columnMetaForGroupBy = (forGroupBy: boolean) => {
    return props.columnMeta.filter(function (colMeta: ColumnMeta) {
      if (forGroupBy) {
        return colMeta.forGroupBy === forGroupBy;
      } else {
        return (typeof colMeta.forGroupBy === 'undefined');
      }

    });
  };

  return (
    <ElmoModal
      id="GenerateChartModal"
      type="medium"
      isOpened={props.showModal}
      title="Generate Chart"
      closeModal={props.toggle}
      primaryButton={(
        <>
          <ElmoButton type="text" onClick={props.toggle}>Cancel</ElmoButton>
          <ElmoButton type="primary" onClick={props.generate} data-testid="generate-chart">
            Generate Chart
          </ElmoButton>
        </>
      )}
    >
      {props.showSaveError &&
        <div className="alert alert-danger" role="alert">
          Chart could not be saved.
        </div>
      }
      <form id="chart-form">
        <ChartTitleInput formData={props.formData} handleFormDataChange={props.handleFormDataChange} />
        <ChartTypesRadioButtons
          formData={props.formData}
          handleFormDataChange={props.handleFormDataChange}
          columnMetaForGroupBy={columnMetaForGroupBy}
        />
        <ChartQueryGroup
          columnMeta={props.columnMeta}
          dataType={props.dataType}
          formData={props.formData}
          formError={props.formError}
          handleFormDataChange={props.handleFormDataChange}
          columnMetaForGroupBy={columnMetaForGroupBy}
        />
        <ConfigurationGroup
          formData={props.formData}
          formError={props.formError}
          handleFormDataChange={props.handleFormDataChange}
        />
      </form>
    </ElmoModal>
  );
};

export default GenerateChartModal;
