import axios from 'axios';
import { ColumnSort } from '@eds/table';
import { useQuery } from '@tanstack/react-query';

import { tableColumns } from './reports-list.hooks';

type ReportPermissions = {
  canDelete: boolean;
  canEdit: boolean;
  canExport: boolean;
  canSchedule: boolean;
  canShare: boolean;
  canView: boolean;
};

export type ReportData = {
  creator?: {
    name: string;
    profilePicture: string | null;
  };
  description: string;
  id: string;
  modified: string;
  moduleName: string;
  moduleType: 'redshift' | 'tms';
  name: string;
  permissions: ReportPermissions;
  reportType: number;
  sharedBy?: string;
  sharedWith?: string[];
  userId: number;
};

export type ReportType = 1 | 2 | 5 | 6;

type ReportFilters = {
  currentItemsPerPage: number;
  keywordFilter: string;
  moduleFilter: string[];
  rangeStart: number;
  reportTypeFilter: ReportType[];
  sortData: ColumnSort;
};

export type ReportsListQueryParams = {
  currentPage: number;
} & ReportFilters;

type ReportUserCapabilities = {
  canCreateReport: boolean;
  canFilterReportsByType: boolean;
};

export const reportsKeys = {
  all: ['reports'] as const,
  lists: () => [...reportsKeys.all, 'list'] as const,
  list: (filters: ReportFilters) => [...reportsKeys.lists(), { filters }] as const,
  relations: (reportId: string) => [...reportsKeys.all, 'relations', reportId],
  userCapabilities: () => [...reportsKeys.all, 'user-capabilities'] as const,
};

const fetchReportsList = async (
  {
    currentItemsPerPage,
    keywordFilter,
    moduleFilter,
    rangeStart,
    reportTypeFilter,
    sortData,
  }: Omit<ReportsListQueryParams, 'currentPage'>,
  signal?: AbortSignal
) => {
  const currentTableColumn = tableColumns.find(({ columnName }) => columnName === sortData.columnName);

  const data = {
    endDate: null,
    modules: moduleFilter,
    reportType: reportTypeFilter,
    searchText: keywordFilter,
    sortByDimensionDirection:
      sortData.sortDirection === 'ascending' ? 'ASC' : sortData.sortDirection === 'descending' ? 'DESC' : undefined,
    sortByDimensionName:
      sortData.sortDirection === 'default'
        ? undefined
        : currentTableColumn?.sortingKey || currentTableColumn?.key || sortData.columnName,
    startDate: null,
  };

  const source = axios.CancelToken.source();
  const promise = axios.post<{ count: number; queries: ReportData[] }>(
    '/api/user/queries',
    { data },
    {
      cancelToken: source.token,
      headers: {
        'x-limit': currentItemsPerPage,
        'x-offset': rangeStart - 1,
      },
    }
  );

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query');
  });

  const response = await promise;
  return response.data;
};

const fetchUserCapabilitiesForReportsList = async (userId: number) => {
  const response = await axios.get<{ report: ReportUserCapabilities }>(
    `/api/users/${userId}/capabilities?scope=report`
  );
  return response.data.report;
};

export const useReportsListQuery = ({ currentPage, ...filters }: ReportsListQueryParams) =>
  useQuery({
    // NOTE: Allow EDS <Pagination> to self-correct and recompute invalid pages
    enabled: currentPage > 0 || filters.rangeStart > 0,
    keepPreviousData: true,
    queryKey: reportsKeys.list(filters),
    queryFn: ({ signal }) => fetchReportsList({ ...filters }, signal),
  });

export const useReportsListUserCapabilitiesQuery = (userId: number) =>
  useQuery({
    queryKey: reportsKeys.userCapabilities(),
    queryFn: () => fetchUserCapabilitiesForReportsList(userId),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
