import axios from 'axios';
import { ColumnSort } from '@eds/table';
import { Layout } from 'react-grid-layout';
import { useQuery } from '@tanstack/react-query';

import { tableColumns } from './dashboards-list.hooks';

export type ChartDisplayType = 'bar' | 'column' | 'line' | 'pie' | 'text';

export type DashboardData = {
  creator: {
    name: string;
    profilePicture: string | null;
    uuid: string;
  };
  dashboardId: string;
  description: string;
  layout: Layout[] | null;
  modified: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  permissions: Record<'canAddWidgets' | 'canClone' | 'canDelete' | 'canEdit' | 'canShare' | 'canUnshare', boolean>;
  status: 1 /** Draft */ | 2 /** Published */;
  title: string;
  type: 1 /** User dashboard */ | 2 /** Suggested dashboard */;
  widgets: {
    chart?: { displayType: ChartDisplayType };
    query?: { id: string };
    widgetId: string;
  }[];
};

export type DashboardType =
  | 2 // Creadted by you
  | 8 // Suggested by ELMO
  | 16 // Shared with you
  | 32; // Shared by you

type DashboardsListFilters = {
  currentItemsPerPage: number;
  keywordFilter: string;
  dashboardTypeFilter: DashboardType[];
  rangeStart: number;
  sortData: ColumnSort;
};

export type DashboardsListQueryParams = { currentPage: number } & DashboardsListFilters;

type DashboardUserCapabilities = {
  canCreateDashboard: boolean;
  canCreateSuggestedDashboard: boolean;
  canAddWidget: boolean;
  canViewDashboardList: boolean;
};

export const dashboardsKeys = {
  all: ['dashboards'] as const,
  lists: () => [...dashboardsKeys.all, 'list'] as const,
  list: (filters: DashboardsListFilters) => [...dashboardsKeys.lists(), { filters }] as const,
  userCapabilities: () => [...dashboardsKeys.all, 'user-capabilities'] as const,
};

const fetchDashboardList = async (
  {
    currentItemsPerPage,
    dashboardTypeFilter,
    keywordFilter,
    rangeStart,
    sortData,
  }: Omit<DashboardsListQueryParams, 'currentPage'>,
  signal?: AbortSignal
) => {
  const currentTableColumn = tableColumns.find(({ columnName }) => columnName === sortData.columnName);

  const query = {
    filters: dashboardTypeFilter,
    limit: currentItemsPerPage,
    offset: rangeStart - 1,
    search: keywordFilter,
    sortBy: sortData.sortDirection === 'default' ? undefined : currentTableColumn?.sortingKey || sortData.columnName,
    sortDirection:
      sortData.sortDirection === 'ascending' ? 'ASC' : sortData.sortDirection === 'descending' ? 'DESC' : undefined,
  };

  const source = axios.CancelToken.source();
  const promise = axios.get<{ dashboards: DashboardData[]; total: number }>('/api/user/dashboards', {
    cancelToken: source.token,
    params: { query },
  });

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query');
  });

  const response = await promise;
  return response.data;
};

const fetchUserCapabilitiesForReportsList = async (userId: number) => {
  const response = await axios.get<{ dashboard: DashboardUserCapabilities }>(
    `/api/users/${userId}/capabilities?scope=dashboard`
  );
  return response.data.dashboard;
};

export const useDashboardsListQuery = (enabled: boolean, { currentPage, ...filters }: DashboardsListQueryParams) =>
  useQuery({
    // NOTE: Allow EDS <Pagination> to self-correct and recompute invalid pages
    enabled: enabled && (currentPage > 0 || filters.rangeStart > 0),
    keepPreviousData: true,
    queryKey: dashboardsKeys.list(filters),
    queryFn: ({ signal }) => fetchDashboardList(filters, signal),
    select: ({ dashboards, total }) => ({
      dashboards: dashboards.map(dashboard => ({ ...dashboard, description: dashboard.description || '' })),
      total,
    }),
  });

export const useDashboardsListUserCapabilitiesQuery = (userId: number) =>
  useQuery({
    enabled: !!userId,
    queryKey: dashboardsKeys.userCapabilities(),
    queryFn: () => fetchUserCapabilitiesForReportsList(userId),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
