import { ADD_JOIN_MEASURES, REMOVE_JOIN_MEASURES } from '../constants';
import { JoinAndMeasures } from '../redux';
import { Join } from '../types/joinModel';
import { JoinMeasuresAction } from '../actions/joinColumn';
import * as _ from 'lodash';
import { ReportBuilderAction } from '../actions/reportBuilder';

function findJoinAndMeasuresIndex(join: Join, joinAndMeasures: JoinAndMeasures[]): number {
  return joinAndMeasures.findIndex((j: JoinAndMeasures) => {
    return Join.equals(j.join, join);
  });
}

export function joinMeasuresReducer(state: JoinAndMeasures[] = [],
                                    action: JoinMeasuresAction | ReportBuilderAction): JoinAndMeasures[] {
  switch (action.type) {
    case ADD_JOIN_MEASURES: {
      let newState = state.slice();
      const index = findJoinAndMeasuresIndex(action.join, state);

      // If join found, append the new dimensions
      if (newState[index]) {
        let copy = Object.assign({}, newState[index]);
        copy.measures = newState[index].measures.concat(action.columns);
        newState[index] = copy;
        return newState;
      }

      return [...state, {
        join: action.join,
        measures: action.columns.slice()
      }];
    }
    case REMOVE_JOIN_MEASURES: {
      let newState = state.slice();
      const index = findJoinAndMeasuresIndex(action.join, state);

      if (newState[index]) {
        let copy = Object.assign({}, newState[index]);
        copy.measures = _.differenceBy(newState[index].measures, action.columns, 'name');
        newState[index] = copy;
      }

      // if none left, remove from state
      if (newState[index].measures.length === 0) {
        newState.splice(index, 1);
      }

      return newState;
    }

    default:
      return state;
  }
}