import classNames from 'classnames';

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export function removeItem<T>(array: Array<T>, key: string, val: any): Array<T> {
  return array.filter((item) => {
    return item[key] !== val;
  });
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Redirects the user to a specific URL
 * @param {string} url
 */
export function redirect(url: string) {
  window.location.href = url;
}

export function getClassNames(...classes: any[]) {
  return classNames(classes);
}

/**
 * IE compatible way to save a blob
 * URL.createObjectURL does not work with IE.
 * @param blob
 */
export function saveBlob(blob: Blob) {
  // IE
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(blob);
  } else {
    //  Build a URL from the file
    const fileURL = URL.createObjectURL(blob);
    // Open the URL on new Window
    window.open(fileURL);
  }
}

const httpProtocolRegexp = /http[s]?\:\/\//i;
export const isUrlHasProtocolPart = (url: string) => {
  return httpProtocolRegexp.test(url);
};
export const getProtocolPartFromUrl = (url: string) => {
  const matchResult = url.match(httpProtocolRegexp);
  return !!matchResult ? matchResult[0] : '';
};
