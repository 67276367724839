import * as React from 'react';
import cn from 'classnames';

import { IconProps } from './types';

export const MyReportsIcon = ({
  color = 'currentColor',
  className,
  width = 24,
  height = 24,
}: IconProps) => {
  return (
    <span className="elmo-icon">
      <svg
        className={cn('elmo-icon_svg', { [className as string]: !!className })}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.82 4H18C19.1 4 20 4.9 20 6V11.68C19.38 11.39 18.71 11.18 18 11.08V6H16V9H8V6H6V21H8V23H6C4.9
          23 4 22.1 4 21V6C4 4.9 4.9 4 6 4H9.18C9.6 2.84 10.7 2 12 2C13.3 2 14.4 2.84 14.82 4ZM11 5C11 5.55
          11.45 6 12 6C12.55 6 13 5.55 13 5C13 4.45 12.55 4 12 4C11.45 4 11 4.45 11 5ZM19 15V13H10V15H19ZM19
          21V23H10V21H19ZM19 19V17H10V19H19Z"
          fill={color}
          fillOpacity="0.87"
        />
      </svg>
    </span>
  );
};
