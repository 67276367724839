import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getReportJson } from '../actions/report';
import { AppState, JoinAndDimensions, JoinAndMeasures } from '../redux';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { Module } from '../types/moduleModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES } from '../constants';
import { setSuggestionsOpen } from '../actions/suggestions';
import { noop } from 'lodash';
import { storeOffset } from '../actions/reportOffsetLimit';
import { SavedQuery } from '../types/savedQueryModel';

export interface GenerateReportButtonProps {
  dispatch: Dispatch<any>;
  onGenerateStart?: () => void;
  onGenerateSuccess?: () => void;
  className?: string;
  module: Module;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  selectedDimensions: Dimension[];
  selectedMeasures: Measure[];
  joinDimensions: JoinAndDimensions[];
  joinMeasures: JoinAndMeasures[];
  noCache?: boolean;
  isDisabled?: boolean;
  loadedQuery?: SavedQuery;
  children?: React.ReactNode;
}

const mapStateToProps = (state: AppState) => ({
  module: state.module,
  dataType: state.dataType,
  selectedDimensions: state.dimensions,
  selectedMeasures: state.measures,
  joinDimensions: state.joinDimensions,
  joinMeasures: state.joinMeasures
});

export interface GenerateReportButtonState {
  loading: boolean;
  noColumnsSelected: boolean;
}

class GenerateReportButtonContainer extends React.Component<GenerateReportButtonProps, GenerateReportButtonState> {
  constructor(props: GenerateReportButtonProps) {
    super(props);
    this.state = {
      loading: false,
      noColumnsSelected: this.hasNoSelectedColumns(),
    };
  }

  componentDidUpdate(prevProps: GenerateReportButtonProps, prevState: GenerateReportButtonState) {
    // if there are no selected dimensions/measures, disable the Generate button
    let noColumnsSelected = this.hasNoSelectedColumns();
    if (prevState.noColumnsSelected !== noColumnsSelected) {
      this.setState({
        noColumnsSelected: noColumnsSelected
      });
    }
  }

  protected generateReportClick = () => async () => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    // When a report is generated, update the offset to 0 (first page)
    this.props.dispatch(storeOffset(0));

    this.setState({
      loading: true
    });
    if (this.props.onGenerateStart) {
      this.props.onGenerateStart();
    }

    try {
      const noCache = !!(this.props.noCache);
      await this.props.dispatch(getReportJson(noCache, reportId));
      this.onGenerateSuccess();
    } finally {
      this.onGenerateFinally();
    }
  }

  componentWillUnmount() {
    this.onGenerateSuccess = noop;
    this.onGenerateFinally = noop;
  }

  onGenerateSuccess = () => {
    if (this.props.onGenerateSuccess) {
      this.props.dispatch(setSuggestionsOpen(false));
      this.props.onGenerateSuccess();
    }
  }

  onGenerateFinally = () => {
    this.setState({
      loading: false
    });
  }

  hasNoSelectedColumns(): boolean {
    let noDimensionsMeasuresSelected = (this.props.selectedMeasures.length +
      this.props.selectedDimensions.length +
      this.props.joinDimensions.length +
      this.props.joinMeasures.length) === 0;

    return (!this.props.module ||
      !this.props.dataType || noDimensionsMeasuresSelected);
  }

  render() {
    const isDisabled = this.state.loading || this.state.noColumnsSelected || this.props.isDisabled;
    const isDisabledClass = (isDisabled) ? ' disabled' : '';
    return (
      <button
        className={this.props.className + isDisabledClass}
        onClick={this.generateReportClick()}
        disabled={isDisabled}
        data-testid="generate-report"
      >
        {this.state.loading &&
        <span>{this.props.children ? this.props.children : 'Generating'}</span>
        }
        {!this.state.loading &&
        <span>{this.props.children ? this.props.children : 'Generate Report'}</span>
        }
      </button>
    );
  }

}

const GenerateReportButton = connect(
  mapStateToProps,
)(GenerateReportButtonContainer);
export default GenerateReportButton;
