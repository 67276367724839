import React from 'react';
import { Flex } from '@eds/flex';

import { AuthService } from 'src/services/authService';

import { DownloadsListFilters } from './downloads-list-filters.component';
import { DownloadsListPagination } from './downloads-list-pagination.component';
import { DownloadsListTable } from './downloads-list-table.component';
import { useDownloadsListQuery } from './downloads-list.queries';
import { useDownloadsList } from './downloads-list.hooks';
import { getCsvExpiryHoursText } from "../../utils";

export const DownloadsList = () => {
  const { data = [], isLoading } = useDownloadsListQuery(AuthService.getInstance().getUserData()?.id || 0);

  const displayScheduleName = !!AuthService.getInstance().isSchedulerEnabled();
  const csvExpiryHoursText = getCsvExpiryHoursText(AuthService.getInstance().getDownloadsCsvExpiryHours());

  const { onPageUpdate, onSearch, paginationData, search, totalItems, ...tableProps } = useDownloadsList(
    displayScheduleName,
    isLoading,
    data
  );

  return (
    <Flex
      containerType="inline-size"
      width="100%"
      gap="medium"
      flexDirection="column"
      backgroundColor="neutral"
      padding="xxlarge"
    >
      <DownloadsListFilters headerDescription={csvExpiryHoursText} displayScheduleName={displayScheduleName} onSearch={onSearch} search={search} />
      <DownloadsListTable displayScheduleName={displayScheduleName} loading={isLoading} {...tableProps} />
      <DownloadsListPagination onPageUpdate={onPageUpdate} paginationData={paginationData} totalItems={totalItems} />
    </Flex>
  );
};
