import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const SaveAsIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10.3333V5.83333L14.1667 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167
        17.5 4.16667 17.5H10.3333L12 15.8333H4.16667V4.16667H13.475L15.8333 6.525V12L17.5 10.3333ZM12.5 12.5C12.5
        13.8833 11.3833 15 10 15C8.61667 15 7.5 13.8833 7.5 12.5C7.5 11.1167 8.61667 10 10 10C11.3833 10 12.5 11.1167
         12.5 12.5ZM5 5H12.5V8.33333H5V5ZM16.6583 13.5417L18.1333 15.0167L13.975 19.1667H12.5V17.6917L16.6583
          13.5417ZM19.375 13.7583L18.6667 14.4667L17.1917 12.9917L17.9 12.2833C18.0667 12.1167 18.325 12.1167
           18.4917 12.2833L19.375 13.1667C19.5417 13.3333 19.5417 13.6 19.375 13.7583Z"
        fill="#212121"
      />
    </svg>
  );
};
