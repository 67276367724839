import {
  ADD_JOIN_DIMENSIONS, ADD_JOIN_MEASURES,
  REMOVE_JOIN_DIMENSIONS,
  REMOVE_JOIN_MEASURES, SOURCE_REPORT_HEADER, SOURCE_WIZARD
} from '../constants';
import { Join } from '../types/joinModel';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { View } from '../types/viewModel';

export interface JoinDimensionsAction {
  type: ADD_JOIN_DIMENSIONS | REMOVE_JOIN_DIMENSIONS;
  join: Join;
  columns: Dimension[];
  view: View;
  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD;
}

export interface JoinMeasuresAction {
  type: ADD_JOIN_MEASURES | REMOVE_JOIN_MEASURES;
  join: Join;
  columns: Measure[];
  view: View;
  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD;
}

export function addJoinDimension(join: Join,
                                 dimension: Dimension,
                                 view: View,
                                 source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinDimensionsAction {
  return {
    type: ADD_JOIN_DIMENSIONS,
    join: join,
    columns: [dimension],
    view: view,
    source: source
  };
}

export function removeJoinDimension(join: Join,
                                    dimension: Dimension,
                                    view: View,
                                    source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinDimensionsAction {
  return {
    type: REMOVE_JOIN_DIMENSIONS,
    join: join,
    columns: [dimension],
    view: view,
    source: source
  };
}

export function addJoinDimensions(join: Join,
                                  dimensions: Dimension[],
                                  view: View,
                                  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinDimensionsAction {
  return {
    type: ADD_JOIN_DIMENSIONS,
    join: join,
    columns: dimensions,
    view: view,
    source: source
  };
}

export function removeJoinDimensions(join: Join,
                                     dimensions: Dimension[],
                                     view: View,
                                     source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinDimensionsAction {
  return {
    type: REMOVE_JOIN_DIMENSIONS,
    join: join,
    columns: dimensions,
    view: view,
    source: source
  };
}

export function addJoinMeasure(join: Join,
                               measure: Measure,
                               view: View,
                               source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinMeasuresAction {
  return {
    type: ADD_JOIN_MEASURES,
    join: join,
    columns: [measure],
    view: view,
    source: source
  };
}

export function removeJoinMeasure(join: Join,
                                  measure: Measure,
                                  view: View,
                                  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinMeasuresAction {
  return {
    type: REMOVE_JOIN_MEASURES,
    join: join,
    columns: [measure],
    view: view,
    source: source
  };
}

export function addJoinMeasures(join: Join,
                                measures: Measure[],
                                view: View,
                                source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinMeasuresAction {
  return {
    type: ADD_JOIN_MEASURES,
    join: join,
    columns: measures,
    view: view,
    source: source
  };
}

export function removeJoinMeasures(join: Join,
                                   measures: Measure[],
                                   view: View,
                                   source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): JoinMeasuresAction {
  return {
    type: REMOVE_JOIN_MEASURES,
    join: join,
    columns: measures,
    view: view,
    source: source
  };
}
