import * as React from 'react';
import classnames from 'classnames';
import { Dropdown } from 'elmo-elements';
import { DropdownProps } from 'elmo-elements/Dropdown';
import './style.css';

interface Props {
  leadingIcon?: true;
}

type ElmoDropdownProps = Props & DropdownProps;

const ElmoDropdown: React.FC<ElmoDropdownProps> = ({ children, className, leadingIcon, ...props }) => {
  return (
    <Dropdown
      className={classnames('elmo-dropdown--override', className, { 'leading-icon': leadingIcon })}
      {...props}
    >
      {children}
    </Dropdown>
  );
};

export default ElmoDropdown;
