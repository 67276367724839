import * as React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Field } from '@eds/field';
import { TextInput } from '@eds/text-input';

import ElmoButton from '../ElmoButton';
import { DeleteIcon } from '../Icons/DeleteIcon';
import { DragVerticleIcon } from '../Icons/DragVerticleIcon';
import { ColumnMeta } from '../../../types/columnMetaModel';
import { CustomColumn } from '../../../types/customColumnModel';

import { getColumnId, isCustomColumn, itemIdPrefix } from './hooks';
import { FIELD_DEPRECATION_MESSAGE } from 'src/features/report-builder';

interface Props {
  provided: DraggableProvided;
  columnMeta: ColumnMeta;
  renameEnabled: boolean;
  index: number;
  consolidatedColumns: Array<CustomColumn | ColumnMeta>;
  setConsolidatedColumns: React.Dispatch<React.SetStateAction<Props['consolidatedColumns']>>;
  setIsChangedTrue: () => void;
}

const PredefinedColumnCard = ({ provided, columnMeta, renameEnabled, index, ...props }: Props) => {
  const { consolidatedColumns, setConsolidatedColumns, setIsChangedTrue } = props;

  const onDeleteButtonClick = () => {
    const adjustedColumns = [...consolidatedColumns];
    adjustedColumns.splice(index, 1);
    setConsolidatedColumns(adjustedColumns);
  };
  
  const id = getColumnId(columnMeta);

  const onRenameColumn: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const alias = target.value.substring(0, 255);

    setConsolidatedColumns(
      consolidatedColumns.map(column => {
        if (isCustomColumn(column)) {
          return column;
        }

        if (getColumnId(column) === id) {
          return { ...column, alias };
        }
        
        return column;
      })
    );

    setIsChangedTrue();
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className="report-column__list-item"
      id={`${itemIdPrefix}${id}`}
    >
      <div {...provided.dragHandleProps} className="report-column__list-item-handle">
        <div>
          <DragVerticleIcon />
        </div>
        <div className="report-column__list-item-content">
          <Field
            caution={columnMeta.dimension?.deprecated}
            keepSpaceForMessage={false}
            label={columnMeta.displayLabel || ''}
            message={columnMeta.dimension?.deprecated ? FIELD_DEPRECATION_MESSAGE : ''}
          >
            {renameEnabled && (
              <TextInput
                onChange={onRenameColumn}
                placeholder="Rename table column header"
                size="small"
                value={columnMeta.alias || ''}
              />
            )}
          </Field>
        </div>
        <ElmoButton
          icon={<DeleteIcon />}
          isIcon={true}
          type="text"
          onClick={onDeleteButtonClick}
        />
      </div>
    </div>
  );
};

export default PredefinedColumnCard;
