import { User } from '../types/userModel';
import { VISITOR_ROLES } from '../constants/permissions';

const getVisitorRole = (roles: string[]) => {
  if (roles.length > 0) {
    switch (roles[0]) {
      case VISITOR_ROLES.roleElmoSuper:
        return 'Elmo Super Admin';
      case VISITOR_ROLES.roleElmoAdmin:
        return 'Elmo Admin';
      case VISITOR_ROLES.roleSuper:
        return 'Company Admin';
      case VISITOR_ROLES.roleManager:
        return 'Manager';
      case VISITOR_ROLES.roleEmployee:
        return 'Employee';
      default:
        return '';
    }
  } else {
    return '';
  }
};

// Call this whenever information about your visitors becomes available
// Please use Strings, Numbers, or Bools for value types.
export const pendoInitialization = (user: User | null) => {
  if (window.pendo && !!user) {
    const locationParts = window.location.hostname.split('.');
    const tenantIdentifier = locationParts[0];
    const visitorRole = getVisitorRole(user.roles);
    const visitorUserType = user.type === 0 ? 'User' : 'Vendor';

    // NOTE: If you do not have data for a field omit sending that field.
    // TODO: I commented fields below because they are necessary in future
    window.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: `${user.firstName} ${user.lastName}`,
        // userCreationDate: '',
        role: visitorRole,
        userType: visitorUserType,
        // country: '',
      },
      account: {
        id: tenantIdentifier,
        // companyName: '',
        // enabledModules: '',
      },
    });
  }
};
