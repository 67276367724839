import * as React from 'react';
import { FormItem, Heading } from 'elmo-elements';

import { getClassNames } from '../../../services/utilityService';
import { UserSelectOption } from '../../../types/scheduler/userSelectOption';

import NotificationRecipientEmailForm from '../NotificationRecipientEmailForm';
import NotificationRecipientSelectForm from '../NotificationRecipientSelectForm';
import OutboundNotificationToggle from '../OutboundNotificationToggle';
import OutboundSelect from '../OutboundSelect';

type Props = {
  outboundDestinationId: string | null;
  onOutboundDestinationChange: (outboundDestination: any) => void;
  errors: any;
  isVisible: boolean;
  isSuperAdmin: boolean;
  userList: UserSelectOption[];
  selectedUsers: UserSelectOption[];
  onUserSelect: (user: UserSelectOption) => void;
  isLoading: boolean;
  recipientEmail: string;
  onRecipientEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggleOutboundNotification: (value: any) => void;
  isOutboundEmailNotification: boolean;
  onResetUserList: () => void;
  onUserSelectChange: (text: string) => void;
};

function OutboundDestinationForm(props: Props) {
  const {
    outboundDestinationId,
    onOutboundDestinationChange,
    errors,
    isVisible,
    isSuperAdmin,
    selectedUsers,
    onUserSelect,
    isLoading,
    userList,
    recipientEmail,
    onRecipientEmailChange,
    isOutboundEmailNotification,
    toggleOutboundNotification,
    onUserSelectChange,
    onResetUserList,
  } = props;

  const className = getClassNames({
    'd-none': !isVisible
  });

  return (
    <div className={className}>
      {isSuperAdmin && <OutboundSelect
        onChange={onOutboundDestinationChange}
        selectedId={outboundDestinationId}
        errors={errors}
      />}
      <FormItem>
        <Heading htmlTag="h2" type="title" size="xs">Notify by</Heading>
      </FormItem>
      <FormItem>
        {isSuperAdmin && <OutboundNotificationToggle
            isOutboundEmailNotification={isOutboundEmailNotification}
            onChange={toggleOutboundNotification}
        />}
      </FormItem>
      {isSuperAdmin && <NotificationRecipientSelectForm
          errors={errors}
          isVisible={!isOutboundEmailNotification}
          userList={userList}
          selectedUsers={selectedUsers}
          onUserSelect={onUserSelect}
          onUserSelectChange={onUserSelectChange}
          onResetUserList={onResetUserList}
          isLoading={isLoading}
      />}
      {isSuperAdmin && <NotificationRecipientEmailForm
          errors={errors}
          isVisible={isOutboundEmailNotification}
          recipientEmail={recipientEmail}
          onRecipientEmailChange={onRecipientEmailChange}
      />}
    </div>
  );
}

export default OutboundDestinationForm;
