import * as React from 'react';
import { apiGetModules } from '../../services/reportService';
import { Module } from '../../types/moduleModel';
import Table from '../../shared-components/Table';
import { map } from 'lodash';
import EditModuleModal from './EditModuleModal';
import { apiUpdateModule } from '../../services/moduleService';
import LoadingIcon from '../../shared-components/LoadingIcon';

export type EditModuleFormData = {
  displayLabel: string;
};

type Props = {

};

type State = {
  isLoading: boolean;
  modules: Module[];
  modalIsOpen: boolean;
  editingModule: Module | null;
};

class ModulesListing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      modules: [],
      modalIsOpen: false,
      editingModule: null
    };
  }

  async componentDidMount() {
    this.loadModules();
  }

  renderHeader = () => {
    return (
      <tr>
        <th>Module</th>
        <th>Display Name</th>
        <th />
      </tr>
    );
  }

  toggle = () => {
    this.setState(({ modalIsOpen }: State) => {
      return {
        modalIsOpen: !modalIsOpen,
      };
    });
  }

  selectModule = (m: Module) => () => {
    this.setState(
      {
        editingModule: m
      },
      this.toggle);
  }

  onSave = async (moduleId: string, data: EditModuleFormData) => {
    await apiUpdateModule(moduleId, data);

    this.toggle();

    // Load the modules again just to make sure we are always editing the latest version of the modules
    this.loadModules();
  }

  loadModules = async () => {
    this.setState({
      isLoading: true
    });

    const modules = await apiGetModules();

    this.setState({
      modules: modules,
      isLoading: false
    });
  }

  renderRow = () => {
    const { modules } = this.state;

    return map(modules, (module: Module, key: number) => {
      return (
        <tr key={key}>
          <td>
            {module.name}
          </td>
          <td>
            {module.label}
          </td>
          <td>
            <a href="#" onClick={this.selectModule(module)}>Edit</a>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { modalIsOpen, editingModule, isLoading } = this.state;
    if (isLoading) {
      return (
        <div>
          <LoadingIcon/>
        </div>);
    }

    return (
      <>
        <h2>Modules</h2>
        <EditModuleModal
          isOpen={modalIsOpen}
          toggle={this.toggle}
          module={editingModule}
          onSave={this.onSave}
        />
        <Table
          renderTableHeaders={this.renderHeader}
          renderTableData={this.renderRow}
        />
      </>
    );
  }
}

export default ModulesListing;