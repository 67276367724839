import * as React from 'react';
import { map, reduce } from 'lodash';
import { Responsive, ResponsiveProps, ItemCallback } from 'react-grid-layout';
import { Layout, Layouts } from 'react-grid-layout';
import { useDispatch } from 'react-redux';

import { closeDrilldownModal } from '../../actions/drilldownModal';
import { Widget } from '../../types/widgetModel';
import { CHART_TYPE_TEXT } from '../../constants';
import { Dashboard } from '../../types/dashboardModel';
import DrilldownModal from '../../containers/ReportBuilder/DrilldownModal';
import WidgetWithButtons from './components/WidgetWithButtons';
import GridButtons from './components/GridButtons';
import StatusSelect from './components/StatusSelect';
import NoDataComponent from '../../components/Dashboard/components/NoDataComponent';

import './styles.css';
import './grid-styles.css';
import './resizable-styles.css';
import { SizeMeProps, withSize } from 'react-sizeme';
import { exportDashboardToPdf } from '../../services/exportDashboardToPdfService';

type GridProps = {
  widgets: Widget[];
  saveLayout: (layout: Layout[]) => void;
  saveStatus: (status: number) => void;
  dashboard: Dashboard;
  dashboards: Dashboard[];
  isEditable: boolean;
  removeWidget: (widget: Widget) => void;
  openEditWidgetTitleModal: (widget: Widget) => void;
  enableDashboardLazyLoading: boolean;
};

const WIDGET_HEIGHT_IN_CELLS = 6;
const WIDGET_WIDTH_IN_CELLS = 4;
const GRID_WIDTH = 1776;
const GRID_BREAKPOINTS = { lg: GRID_WIDTH };
const GRID_NUM_COLS = { lg: 12 };
const GRID_ROW_HEIGHT = 27; // NOTE: 48 formula is ( h * rowHeight + (h - 1) * margin )
const DEFAULT_GRID_WIDTH = 1;

type ResponsiveGridLayoutHOCProps = SizeMeProps & ResponsiveProps &
  {children: React.ReactNode; onGridWidthChange: (newWidth: number) => void};
const ResponsiveGridLayout = withSize()(
  ({ size, onGridWidthChange, children, ...rest }: ResponsiveGridLayoutHOCProps) => {
    onGridWidthChange(size.width || DEFAULT_GRID_WIDTH);
    return <Responsive {...rest} width={size.width || DEFAULT_GRID_WIDTH}>{children}</Responsive>;
  }
);

type WidgetStatusesType = Record<string, boolean>;
type WidgetStatusActionType = {
  widgetId: string,
  status: boolean,
};

const widgetStatusesReducer = (state: WidgetStatusesType, action: WidgetStatusActionType) => {
  return {
    ...state,
    [action.widgetId]: action.status,
  };
};

const Grid = (props: GridProps) => {
  const dispatch = useDispatch();
  const [ layout, setLayout ] = React.useState<Layout[]>([]);
  const [ saved, setSaved ] = React.useState<boolean>(true);
  const [ initialLoad, setInitialLoad ] = React.useState<boolean>(true);
  const [ currentGridWidth, setCurrentGridWidth ] = React.useState<number>(DEFAULT_GRID_WIDTH);
  const [ widgetStatuses, updateWidgetStatuses ] = React.useReducer(widgetStatusesReducer, {});
  const [ isWidgetsLoaded, setIsWidgetsLoaded ] = React.useState<boolean>(false);
  const [isExportToPdfInProgress, setIsExportToPdfInProgress] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      return () => {
        dispatch(closeDrilldownModal());
      };
    },
    []
  );

  React.useEffect(
    () => {
      const allWidgetsLoaded = Object.values(widgetStatuses).every(status => status);
      setIsWidgetsLoaded(allWidgetsLoaded);
    },
    [widgetStatuses]
  );

  // Every Widget has a Layout which determines where on the Grid it goes and its size
  // When a Widget gets added for the first time it has no Layout so we need to determine this for ourselves
  const defaultLayout = (widget: Widget) => {
    // The Widget is put in an empty row at the bottom
    const chartType = widget.chart && widget.chart.displayType;
    const maxY = reduce(
      props.dashboard.layout,
      function(max: number, l: Layout) {
        return max > l.y ? max : l.y;
      },
      0);

    return {
      x: 0,
      y: maxY + 1,
      w: WIDGET_WIDTH_IN_CELLS,
      h: WIDGET_HEIGHT_IN_CELLS,
      i: widget.widgetId,
      minW: chartType === CHART_TYPE_TEXT ? 3 : 4,
      maxW: 12,
      minH: chartType === CHART_TYPE_TEXT ? 3 : 6,
      maxH: 10,
    };
  };

  const buildWidgetLayout = (widget: Widget, layouts?: Layout[]) => {
    let newLayout = undefined;
    if (layouts) {
      newLayout = layouts.find(item => item.i === widget.widgetId);
    }

    if (!newLayout) {
      return defaultLayout(widget);
    }

    if (widget && widget.chart && widget.chart.displayType === CHART_TYPE_TEXT) {
      return Object.assign(newLayout, {minW: 3, maxW: 12, minH: 3, maxH: 10});
    }

    return Object.assign(newLayout, {minW: 4, maxW: 12, minH: 6, maxH: 10});
  };

  const onLayoutChange = (l: Layout[], layouts: Layouts) => {
    // This code is fixed issue - onLayoutChange fires multiple times on load
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      setLayout(l);
      setSaved(false);
    }
  };

  const onGridWidthChange = (newWidth: number) => {
    setCurrentGridWidth(newWidth);
  };

  const widgetOnDragStart: ItemCallback = (layuot, oldItem, newItem, placeholder, event, element) => {
    element.style.cursor = 'grabbing';
  };

  const widgetOnDragStop: ItemCallback = (layuot, oldItem, newItem, placeholder, event, element) => {
    element.style.cursor = 'default';
  };

  const saveClicked = () => {
    props.saveLayout(layout);
    setSaved(true);
  };

  const updateWidgetStatus = (widgetId: string, status: boolean) => {
    updateWidgetStatuses({ widgetId, status });
  };

  const initExportToPdf = async () => {
    // Provide to export service only loaded widgets ids
    const loadedWidgetsIds = Object.keys(widgetStatuses).filter(key => widgetStatuses[key]);

    if (isExportToPdfInProgress) {
      return;
    }

    if (props.dashboard && props.dashboard.title) {
      setIsExportToPdfInProgress(true);
      await exportDashboardToPdf(props.dashboard, loadedWidgetsIds);
      setIsExportToPdfInProgress(false);
    }
  };

  if (!props.dashboard) {
    // No layout yet
    return null;
  }

  const isDashboardNotEmpty = !!props.widgets && props.widgets.length > 0;
  const isDashboardLoaded = !!props.dashboard && !!props.dashboard.dashboardId;

  return (
    <div className="dashboard-container">
      <GridButtons
        isDashboardNotEmpty={isDashboardNotEmpty}
        isDashboardLoaded={isDashboardLoaded}
        dashboard={props.dashboard}
        dashboards={props.dashboards}
        saved={saved}
        saveClicked={saveClicked}
        isEditable={props.isEditable}
        initExportToPdf={initExportToPdf}
        isWidgetsLoaded={isWidgetsLoaded}
        isExportToPdfInProgress={isExportToPdfInProgress}
      />
      {isDashboardNotEmpty &&
        <>
          <ResponsiveGridLayout
            className="layout grid-container"
            breakpoints={GRID_BREAKPOINTS}
            cols={GRID_NUM_COLS}
            rowHeight={GRID_ROW_HEIGHT}
            isDraggable={props.isEditable}
            isResizable={props.isEditable}
            isRearrangeable={props.isEditable}
            onLayoutChange={onLayoutChange}
            margin={[24, 24]}
            containerPadding={[0, 0]}
            onGridWidthChange={onGridWidthChange}
            onDragStart={widgetOnDragStart}
            onDragStop={widgetOnDragStop}
          >
            {map(props.widgets, (widget: Widget, i: number) => {
              // This is the default Layout for a Widget
              const l = buildWidgetLayout(widget, props.dashboard.layout);
              return (
                <div
                  key={widget.widgetId}
                  data-widget={widget.widgetId}
                  className="widget-cell"
                  data-grid={l}
                >
                  <WidgetWithButtons
                    isEditable={props.isEditable}
                    removeWidget={props.removeWidget}
                    openEditWidgetTitleModal={props.openEditWidgetTitleModal}
                    widget={widget}
                    layout={l}
                    currentGridWidth={currentGridWidth}
                    enableDashboardLazyLoading={props.enableDashboardLazyLoading}
                    updateWidgetStatus={updateWidgetStatus}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
          <StatusSelect dashboard={props.dashboard} saveStatus={props.saveStatus}/>
          <DrilldownModal />
        </>
      }
      {
        isDashboardLoaded && !isDashboardNotEmpty &&
        <NoDataComponent />
      }
      </div>
  );
};

export default Grid;
