import { ADD_DIMENSIONS, REMOVE_DIMENSIONS, SOURCE_REPORT_HEADER, SOURCE_WIZARD } from '../constants/index';
import { Dimension } from '../types/dimensionModel';
import { View } from '../types/viewModel';

export interface DimensionsAction {
  type: ADD_DIMENSIONS | REMOVE_DIMENSIONS;
  dimensions: Dimension[];
  view: View;
  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD;
}

export function addDimension(dimension: Dimension,
                             view: View,
                             source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): DimensionsAction {
  return {
    type: ADD_DIMENSIONS,
    dimensions: [dimension],
    view: view,
    source: source
  };
}

export function removeDimension(dimension: Dimension,
                                view: View,
                                source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): DimensionsAction {
  return {
    type: REMOVE_DIMENSIONS,
    dimensions: [dimension],
    view: view,
    source: source
  };
}

export function addDimensions(dimensions: Dimension[],
                              view: View,
                              source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): DimensionsAction {
  return {
    type: ADD_DIMENSIONS,
    dimensions: dimensions,
    view: view,
    source: source
  };
}

export function removeDimensions(dimensions: Dimension[],
                                 view: View,
                                 source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): DimensionsAction {
  return {
    type: REMOVE_DIMENSIONS,
    dimensions: dimensions,
    view: view,
    source: source
  };
}