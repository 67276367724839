import * as React from 'react';
import Collapse from 'reactstrap/lib/Collapse';
import classnames from 'classnames';

import './style.css';

interface Props {
  className?: string;
  isOpen: boolean;
}

const CollapseCard: React.FC<Props> = ({ isOpen, children, className }) => {
  return (
    <div className={classnames('new-collapse-card', 'card', className)}>
      <Collapse isOpen={isOpen}>
        {isOpen &&
          <div className="card-body">
            {children}
          </div>
        }
      </Collapse>
    </div>
  );
};

export default CollapseCard;
