import { BaseModel } from './baseModel';
import { Chart } from '../types/chartModel';
import { SavedQuery } from '../types/savedQueryModel';

export class Widget extends BaseModel {
  protected properties: string[] = [
    'chart',
    'type',
    'widgetId',
    'created',
    'modified',
    'dashboardId',
    'title',
    'query',
    'readAccess',
    'canRead',
    'canEdit',
    'canDelete'
  ];

  chart?: Chart;
  type: number;
  widgetId: string;
  created?: Date;
  modified?: Date;
  dashboardId: string;
  title: string;
  query?: SavedQuery;
  readAccess: boolean;
  canRead: boolean;
  canEdit: boolean;
  canDelete: boolean;

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }

  getQueryFromWidget() {
    let query;
    // Widget can have an attached Chart (Chart Widget) or SavedQuery (for Table Widget)
    if (this.chart && this.chart.query) {
      query = this.chart.query;
    } else if (this.query) {
      query = this.query;
    }

    return query;
  }

}
