import React, { ChangeEventHandler } from 'react';
import { Field } from '@eds/field';
import { Grid } from '@eds/grid';
import { SelectInput } from '@eds/select-input';
import { TextInput } from '@eds/text-input';

import { FILTER_DATE_CONTEXT_CURRENT } from 'src/constants/filters';
import {
    Filter,
} from '../../../types/filterModel';
import { getFilterDatePeriodOptions } from "../../../services/filterService";

type FilterTextInputProps = {
  displayFilter: Filter;
  filterIndex: number;
  isDisabled: boolean;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
};

type Option = {
  label: string;
  nowAsDefaultReferencePoint: boolean;
  value: string;
};

const FilterDatePeriodInput = (props: FilterTextInputProps) => {
  const { displayFilter, filterIndex, isDisabled, saveDisplayFilter } = props;
  const { nowAsDefaultReferencePoint, periodCount, relativeDate } = displayFilter;
  // NOTE: should be updated to array.flat() when target is updated to ES2019
  const options: Option[] = getFilterDatePeriodOptions(relativeDate, displayFilter.dimensionOrMeasureType);

  const onChange = (option: Option) => {
    const filter = { ...displayFilter };
    filter.value = option.nowAsDefaultReferencePoint ? option.value.replace('RELATIVE_', '') : option.value;
    filter.nowAsDefaultReferencePoint = option.nowAsDefaultReferencePoint || undefined;
    saveDisplayFilter(filter, filterIndex);
  };

  const onTextChange: ChangeEventHandler<HTMLInputElement> = e => {
    const filter = { ...displayFilter };
    filter.periodCount = e.target.value;
    saveDisplayFilter(filter, filterIndex);
  };

  const gridTemplateColumns =
    relativeDate === FILTER_DATE_CONTEXT_CURRENT ? '1fr' : 'minmax(3.5rem, 0.5fr) minmax(10.5rem, 1.5fr)';

  return (
    <Grid gridTemplateColumns={gridTemplateColumns} gap="medium">
      {relativeDate !== FILTER_DATE_CONTEXT_CURRENT && (
        <Field label="Value" keepSpaceForMessage={false}>
          <TextInput
            disabled={isDisabled}
            onChange={onTextChange}
            size="small"
            type="number"
            value={periodCount || ''}
          />
        </Field>
      )}
      <Field label="Period" keepSpaceForMessage={false}>
        <SelectInput
          disabled={isDisabled}
          isClearable={false}
          items={options}
          onChange={onChange}
          size="small"
          value={options.find(option =>
            nowAsDefaultReferencePoint
              ? option.value === `RELATIVE_${displayFilter.value}`
              : option.value === displayFilter.value
          )}
        />
      </Field>
    </Grid>
  );
};

export default FilterDatePeriodInput;
