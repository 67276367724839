import { DrilldownModalAction } from '../actions/drilldownModal';
import { DrilldownModalType, initialDrilldownModalState } from '../types/drilldownModalModel';
import { SHOW_DRILLDOWN_MODAL, CLOSE_DRILLDOWN_MODAL } from '../constants';

export function drilldownModalReducer(
  state: DrilldownModalType = initialDrilldownModalState,
  action: DrilldownModalAction
): DrilldownModalType {
  switch (action.type) {
    case SHOW_DRILLDOWN_MODAL: {
      return {...state, ...action.drilldownModal};
    }

    case CLOSE_DRILLDOWN_MODAL: {
      return initialDrilldownModalState;
    }

    default:
      return state;
  }
}
