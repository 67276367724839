import * as React from 'react';
import { withModal, BasicModal } from '../../../shared-components/BasicModal';
import { map, filter, findIndex } from 'lodash';
import './style.css';
import { ConfigView } from '../../../types/config/configViewModel';

interface ConfigAddViewButtonModalProps {
  availableViews: ConfigView[];
  selectedViews: ConfigView[];
  onAddViews: (viewsToAdd: ConfigView[]) => void;
  toggle: () => void;
  isOpen: boolean;
}

interface ConfigAddViewButtonModalState {
  viewsToAdd: any;
  filterText: string;
}

class ConfigAddViewButtonModal extends React.Component<ConfigAddViewButtonModalProps, ConfigAddViewButtonModalState> {
  constructor(props: ConfigAddViewButtonModalProps) {
    super(props);

    this.state = {
      viewsToAdd: [],
      filterText: ''
    };
  }

  componentDidUpdate(prevProps: ConfigAddViewButtonModalProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        filterText: '',
        viewsToAdd: []
      });
    }
  }
  /**
   * Toggles the modal's open/close state
   */
  toggle = () => {
    this.props.toggle();
  }

  /**
   * Called when the checkbox for each view is changed
   *
   * @param {number} index
   * @returns {() => void}
   */
  selectedOnChange = (index: number) => () => {
    let toAdd = this.state.viewsToAdd.slice();

    if (toAdd[index] === undefined) {
      // the view has not been selected/deselected yet, so there is no entry for the view in the checkbox array
      toAdd[index] = true;
    } else {
      // the view has been selected/deselected, toggle its checked state
      toAdd[index] = !toAdd[index];
    }

    this.setState({
      viewsToAdd: toAdd
    });
  }

  renderFilterInput() {
    return (
      <input
        type="text"
        className="form-control form-control-sm"
        id="description"
        placeholder="Which view are you looking for?"
        value={this.state.filterText}
        onChange={this.onFilterTextChange}
      />
    );
  }

  /**
   * Render the views that can be added. These do not display on the list of selected views
   * @returns {any[]}
   */
  renderAvailableViews() {
    let filterText = this.state.filterText.trim().toLowerCase();
    return map(this.props.availableViews, (v: ConfigView, key: number) => {
      // if the view exists in the array of selected views, don't render it
      let alreadySelected = findIndex(this.props.selectedViews, {tableName: v.tableName}) !== -1;
      let doesNotMatchFilter = (filterText.length > 0) && !v.tableName.toLowerCase().includes(filterText);
      if (alreadySelected || doesNotMatchFilter) {
        return null;
      }

      return (
        <div key={key} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id={'view_' + key}
            value={this.state.viewsToAdd[key]}
            onChange={this.selectedOnChange(key)}
          />
          <label
            className="form-check-label"
            htmlFor={'view_' + key}
          >
            {v.tableName}
          </label>
        </div>
      );
    });
  }

  /**
   * When the Add button is clicked
   */
  onAdd = () => {

    // iterate through the viewsToAdd array which contains true/false values (because checkbox)
    // and get the actual view object
    let selected: Array<ConfigView | null> = map(this.state.viewsToAdd, (isSelected: boolean, key: number) => {
      if (!isSelected) {
        return null;
      }

      // the view that will be added will not have any selected dimensions or measures
      return Object.assign({}, this.props.availableViews[key], {dimensions: [], measures: []});
    });

    selected = filter(selected); // remove null values from the array of selected views
    this.props.onAddViews(selected as Array<ConfigView>); // send the final list of selected views to the props function

    // clear the viewsToAdd array
    this.setState({
      viewsToAdd: []
    });

    // close the modal
    this.toggle();
  }

  render() {
    return (
      <div className="config-add-view">
        <button
          className="btn btn-primary mr-2 add-view-button"
          onClick={this.toggle}
        >
          <i className="fa fa-plus" /> Add View
        </button>
        <BasicModal
          title="Add View"
          className="config-add-view-modal"
          confirmButtonText="Add"
          onConfirmClicked={this.onAdd}
          toggle={this.props.toggle}
          isOpen={this.props.isOpen}
        >
          {this.renderFilterInput()}
          {this.renderAvailableViews()}
        </BasicModal>
      </div>
    );
  }

  onFilterTextChange = (event: React.FormEvent<HTMLInputElement>) => {
    let value: string = event.currentTarget.value;
    this.setState({
      filterText: value
    });
  }
}

export default withModal(ConfigAddViewButtonModal);