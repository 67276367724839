import * as React from 'react';
import uuidv4 from 'uuid/v4';
import { findIndex, compact } from 'lodash';

import {
  CHART_TYPE_COLUMN,
  CHART_TYPE_PIE,
  CHART_TYPE_TEXT,
  DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES
} from '../../../constants';
import { ColumnMeta } from '../../../types/columnMetaModel';
import { Chart as ChartModel } from '../../../types/chartModel';
import { colMetaOptions, columnMetaEqual } from '../../../services/columnMetaService';
import { GenerateChartButtonIcon } from '../../Icons/GenerateChartButtonIcon';
import { ChartForm, FormError } from './types';
import GenerateChartModal from './GenerateChartModal';
import ElmoButton from '../ElmoButton';
import { EditChartIcon } from '../Icons/EditChartIcon';

import './style.css';

export type DispatchChartFunction = (chart: ChartModel) => void;

interface GenerateChartButtonProps {
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
  columnMeta: ColumnMeta[];
  dispatchChart: DispatchChartFunction;
  chart: ChartModel;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  disabled?: boolean;
}

interface GenerateChartButtonState {
  formData: ChartForm;
  formError: FormError;
  generating: boolean;
  showSaveError: boolean;
}

function getGenerateChartFormResetState(): GenerateChartButtonState {
  return {
    formData: {
      title: '',
      type: CHART_TYPE_COLUMN
    },
    formError: {
      title: '',
      type: '',
      xAxis: '',
      yAxis: '',
      limit: '',
    },
    generating: false,
    showSaveError: false
  };
}

export class GenerateChart extends React.Component<GenerateChartButtonProps, GenerateChartButtonState> {
  constructor(props: GenerateChartButtonProps) {
    super(props);
    this.state = getGenerateChartFormResetState();
  }

  toggle = () => {
    let nextShowModal = !this.props.showModal;
    // when the modal is opened, populate the form data.
    if (nextShowModal && this.props.chart.chartId) {
      this.populateFormData();
    }

    // when the modal is closed, reset the form.
    if (!nextShowModal) {
      this.setState(getGenerateChartFormResetState());
    }

    this.props.setShowModal(nextShowModal);
  }

  populateFormData() {
    let formData: ChartForm = {
      title: this.props.chart.title,
      type: this.props.chart.type
    };

    // populate the selected X axis
    if (this.props.chart.selectedX) {
      let xAxisOptions = colMetaOptions(this.props.columnMeta, this.props.dataType, true);
      let filteredXAxisOptions = this.props.chart.selectedX.map((v: any) => {
        return xAxisOptions.find((option: ColumnMeta) => columnMetaEqual(option, v));
      });
      filteredXAxisOptions = compact(filteredXAxisOptions);
      formData.selectedXAxisOption = filteredXAxisOptions.map((colMeta: ColumnMeta, i) => {
        return { value: i.toString(), label: colMeta.displayLabel };
      });
      formData.xAxis = this.props.chart.selectedX;
    }

    if (this.props.chart.selectedY) {
      let yAxisOptions = colMetaOptions(this.props.columnMeta, this.props.dataType, false);
      let index = findIndex(yAxisOptions, (option: ColumnMeta) => {
        return !!this.props.chart.selectedY && columnMetaEqual(option, this.props.chart.selectedY);
      });

      if (index !== -1) {
        formData.selectedYAxisOption = index.toString();
        formData.yAxis = this.props.chart.selectedY;
      }
    }

    if (this.props.chart.limit) {
      formData.limit = this.props.chart.limit;
    }

    this.setState({
      formData: formData
    });
  }

  validGenerate(formData: ChartForm) {
    let isValid = true;
    let formError = {
      title: '',
      type: '',
      xAxis: '',
      yAxis: '',
      limit: '',
    };

    if (!formData.xAxis && formData.type !== CHART_TYPE_TEXT) {
      isValid = false;
      formError.xAxis = 'Please select a value for the x-axis';
    }

    if (formData.xAxis && formData.xAxis.length > 2 && formData.type !== CHART_TYPE_TEXT) {
      isValid = false;
      formError.xAxis = 'Please select max 2 values';
    }

    if (!formData.type) {
      isValid = false;
      formError.type = 'Please select a type';
    }

    if ((formData.type === CHART_TYPE_PIE || formData.type === CHART_TYPE_TEXT) && !formData.yAxis) {
      isValid = false;
      formError.yAxis = 'Please select a value for the y-axis';
    }

    this.setState({
      formError: formError
    });
    return isValid;
  }

  generate = () => {
    if (!this.validGenerate(this.state.formData)) {
      return;
    }

    let chart = new ChartModel();
    chart.chartId = (this.props.chart.chartId) ? this.props.chart.chartId : uuidv4();
    chart.title = this.state.formData.title;
    if (this.state.formData.xAxis) {
      chart.selectedX = this.state.formData.xAxis;
    }
    if (this.state.formData.yAxis) {
      chart.selectedY = this.state.formData.yAxis;
    }
    if (this.state.formData.limit) {
      chart.limit = this.state.formData.limit;
    }
    chart.type = this.state.formData.type;

    // Used for when we delete a chart, if hasn't been saved then no API call is necessary
    chart.saved = !!this.props.chart.chartId;

    // Associate the currently loaded query with this chart
    chart.queryId = this.props.chart.queryId;
    chart.shouldGenerateChart = true;

    this.props.dispatchChart(chart);
    this.toggle(); // close the modal
  }

  handleFormDataChange = (formData: ChartForm) => {
    this.setState({formData});
  }

  render() {
    const displayEditChart = this.props.chart.chartId && !this.props.chart.deleted;

    return (
      <div className="new-generate-chart d-inline-block" data-tracking="generate-chart">
        <ElmoButton
          onClick={this.toggle}
          isDisabled={this.props.disabled}
          data-testid="generate-chart-modal"
          icon={displayEditChart ? <EditChartIcon /> : <GenerateChartButtonIcon />}
        >
          {(!this.props.chart.chartId || this.props.chart.deleted) &&
          <span>Generate Chart</span>
          }
          {displayEditChart && <span>Edit Chart</span>}
        </ElmoButton>
        <GenerateChartModal
          showModal={this.props.showModal}
          showSaveError={this.state.showSaveError}
          columnMeta={this.props.columnMeta}
          dataType={this.props.dataType}
          formData={this.state.formData}
          formError={this.state.formError}
          toggle={this.toggle}
          generate={this.generate}
          handleFormDataChange={this.handleFormDataChange}
        />
      </div>
    );
  }
}
