import { backgroundColor, borderColor, color } from '@eds/core';
import { ButtonProps } from '@eds/button';

export const button = (tone: ButtonProps['tone'] | 'date-input' | 'toggle' = 'neutral', disabled = false) => {
  const ghost = {
    'button:hover': {
      color: color.neutralBold,
      backgroundColor: backgroundColor.neutral,
    },
    'button:focus': {
      color: color.neutralBold,
      backgroundColor: backgroundColor.neutral,
      borderColor: 'transparent',
      outline: 'none',
    },
    'button:focus-visible': {
      outline: 'var(--eds-focus-ring-default)',
    },
    'button:disabled': {
      color: color.neutralDisabled,
      backgroundColor: disabled ? backgroundColor.neutralDisabled : undefined,
      borderColor: 'transparent',
    },
  };

  const critical = {
    'button:hover': {
      color: color.neutralBoldInverted,
    },
    'button:focus': {
      color: color.neutralBoldInverted,
      backgroundColor: backgroundColor.criticalBold,
      borderColor: backgroundColor.criticalBold,
      outline: 'none',
    },
    'button:focus-visible': {
      outline: 'var(--eds-focus-ring-default)',
    },
  };

  const dateInput = {
    ...ghost,
    'button:hover': {
      ...ghost['button:hover'],
      backgroundColor: 'transparent',
    },
    'button:focus': {
      ...ghost['button:focus'],
      backgroundColor: 'transparent',
    },
  };

  const neutral = {
    ...ghost,
    'button:focus': {
      ...ghost['button:focus'],
      borderColor: borderColor.neutralSubtle,
    },
  };

  const primary = {
    'button:hover': {
      color: 'var(--eds-button-color)',
    },
    'button:focus': {
      color: 'var(--eds-button-color)',
      backgroundColor: 'var(--eds-button-background-color)',
      borderColor: 'var(--eds-button-border-color)',
      outline: 'none',
    },
    'button:focus-visible': {
      outline: 'var(--eds-focus-ring-default)',
    },
    'button:disabled': {
      color: color.neutralDisabled,
    },
  };

  const toggle = {
    'button:focus[aria-checked="false"]': {
      color: color.neutralBoldInverted,
      backgroundColor: backgroundColor.neutralBold,
      outline: 'none',
    },
    'button:focus[aria-checked="true"]': {
      color: 'var(--eds-button-background-color)',
      backgroundColor: 'var(--eds-button-background-color)',
      borderColor: 'transparent',
      outline: 'none',
    },
    'button:focus[aria-checked="false"]:focus-visible': {
      outline: 'var(--eds-focus-ring-default)',
    },
    'button:focus[aria-checked="true"]:focus-visible': {
      outline: 'var(--eds-focus-ring-default)',
    },
  };

  return { critical, 'date-input': dateInput, ghost, neutral, primary, toggle }[tone];
};
