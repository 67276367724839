import axios, { AxiosResponse } from 'axios';
import { pick as lodashPick } from 'lodash';
import { User } from '../types/userModel';
import { redirect } from './utilityService';
import { errorRoutes } from '../routes/error';
import { Dispatch } from 'redux';
import { setRoutes } from '../actions/routes';
import { getRoutesForUser } from './routingService';
import { setUser } from '../actions/auth';
import {
  CRT_ANALYTICS_CAPABILITIES_KEY,
  ELMO_CRT_ANALYTICS_CAPABILITY,
  CRT_CAPABILITIES_KEY,
  CRT_ADMIN_KEY,
  ELMO_PREDICTIVE_ANALYTICS_CAPABILITY, ANALYTICS_CRT_REPORT_CREATE
} from '../constants/permissions';
import { APP_MENU_STORAGE_KEY, APP_MENU_CURRENT_ITEM_STORAGE_KEY } from '../constants';
import { IS_SELECT_FORM_ALERT_CLOSED } from '../components/SelectFormsModal/helpers';
import { base64ToBytes, bytesToBase64 } from 'src/features/utils';

export const CRT_TOKEN_KEY = 'crt-token';
export const ROLE_ELMO_SUPER =  'ROLE_ELMO_SUPER';
export const ROLE_COMPANY_ADMIN =  'ROLE_SUPER';
export const ROLE_ELMO_MANAGER =  'ROLE_MANAGER';
export const ROLE_ELMO_EMPLOYEE =  'ROLE_EMPLOYEE';

type FeatureFlags = {
  enableDashboardLazyLoading: boolean;
  enableSchedulingOutbounder: boolean;
  enableScheduleTime: boolean;
  enableNewReportBuilder: boolean;
  enableDashboardSharing: boolean;
  enableAndOrFilter: boolean;
};

export type SupportedCountriesType = Array<string>;

export type TokenData = {
  user: any;
  oauthHost: string;
  csvExpiryHours: string;
  featureFlags: FeatureFlags;
  supportedCountries: SupportedCountriesType;
  loginExpiry: number;
};

export class AuthService {
  static instance: AuthService | null;
  loading: boolean;
  backendLoggedInPromise: Promise<boolean>;
  dispatch: Dispatch<any>;
  isClearingCallbackSet: boolean = false;
  isLogoutInProgress: boolean = false;

  public static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  protected constructor() {
    AuthService.instance = null;
  }

  /**
   * Returns true if the user is logged in.
   * @returns {Promise<boolean>}
   */
  async isLoggedIn(): Promise<boolean> {
    // check if a token exists in localStorage
    if (this.isTokenValid()) {
      this.backendLoggedInPromise = Promise.resolve(true);
      return true;
    }

    // if a request to check if the backend is logged in is still loading,
    // then return the current promise, don't create another request
    if (!this.loading) {
      this.backendLoggedInPromise = this.apiIsBackendLoggedIn();
    }

    if (!this.isClearingCallbackSet) {
      this.setClearingTokensBeforeUnloadCallback();
      this.isClearingCallbackSet = true;
    }

    return this.backendLoggedInPromise;
  }

  /**
   * Check if the user is logged in on the backend and set a token
   */
  async apiIsBackendLoggedIn(refresh = false): Promise<boolean> {
    this.loading = true;
    try {
      let response: AxiosResponse = await axios.get(
        '/oauth2-client/auth/check-token'
      );

      if (response.data['authenticated']) {
        let tokenData = lodashPick(
          response.data,
          ['user', 'oauthHost', 'csvExpiryHours', 'featureFlags', 'supportedCountries', 'loginExpiry']
        );
        tokenData['user'] = Object.assign(new User(), tokenData['user']);

        let data = bytesToBase64(new TextEncoder().encode(JSON.stringify(tokenData)));
        sessionStorage.setItem(CRT_TOKEN_KEY, data);

        if (!refresh) {
          // clean up menu local storage
          this.clearLocalStorageData();
          this.configureForUser();
        }
      }

      this.loading = false;
      return response.data['authenticated'];
    } catch (e) {
      this.loading = false;
      if (e.response.status !== 401 && e.response.status !== 403) {
        redirect(errorRoutes.fatalError.path);
      }

      throw e;
    }
  }

  /**
   * Returns true if token is valid
   * @returns {boolean}
   */
  isTokenValid(): boolean {
    const token = this.getToken();
    return !!(token && token.user);
  }

  getToken(): TokenData | null {
    let token = sessionStorage.getItem(CRT_TOKEN_KEY);

    if (token) {
      return JSON.parse(new TextDecoder().decode(base64ToBytes(token)));
    }

    return null;
  }

  getUserData(): User | null {
    let token = this.getToken();

    // if the token exists but there is no user data, then logout. User cannot be logged in without having user data.
    if (token && !token.user) {
      this.logout();
    }

    return token ? token.user : null;
  }

  getUserTimezone(): string | null {
    let user = this.getUserData();
    return !!user ? user.timezone : null;
  }

  getFeatureFlags(): FeatureFlags | null {
    let token = this.getToken();

    return token && token.featureFlags ? token.featureFlags : null;
  }

  getSupportedCountries(): SupportedCountriesType {
    const token = this.getToken();

    return token && token.supportedCountries ? token.supportedCountries : [];
  }

  getClientCountries(): SupportedCountriesType {
    const user = this.getUserData();
    const clientCountries = user && user.clientCountries ? user.clientCountries : '';

    return clientCountries.split('_');
  }

  isManager(): boolean {
    let user = this.getUserData();
    return !!user && user.roles.includes(ROLE_ELMO_MANAGER);
  }

  isSuperAdmin(): boolean {
    const user = this.getUserData();
    return !!user && user.roles.includes(ROLE_ELMO_SUPER);
  }

  isCompanyAdmin(): boolean {
    const user = this.getUserData();
    return !!user && user.roles.includes(ROLE_COMPANY_ADMIN);
  }

  // Check if logged user with crt admin capability
  isCrtSuperAdmin(): boolean {
    return this.hasCapability(CRT_CAPABILITIES_KEY, CRT_ADMIN_KEY);
  }

  // Check if logged user has Create capability
  hasCreateCapability(): boolean {
    return this.hasCapability(CRT_CAPABILITIES_KEY, ANALYTICS_CRT_REPORT_CREATE);
  }

  /**
   * Logs the user out
   * @returns {Promise<void>}
   */
  async logout() {
    if (this.isLogoutInProgress) {
      return;
    }
    try {
      this.isLogoutInProgress = true;
      await this.apiLogoutBackend();
    } finally {
      this.clearLocalStorageData();
      this.clearTokenAndRedirectToLogoutPage();
      this.isLogoutInProgress = false;
    }
  }

  /**
   * Removes the token
   */
  removeToken() {
    sessionStorage.removeItem(CRT_TOKEN_KEY);
    sessionStorage.removeItem(IS_SELECT_FORM_ALERT_CLOSED);
  }

  /**
   * Logs the user out of the backend
   */
  async apiLogoutBackend() {
    return await axios.get(
      '/api/anon/logout'
    );
  }

  /**
   * Returns the login url
   * @returns {string}
   */
  getLoginUrl(): string {
    return window.location.protocol + '//' + window.location.hostname +
      '/oauth2-client/redirect-to-secure-frontend?frontend-url=' + window.location.href;
  }

  /**
   * Returns the logout url
   * @returns {string}
   */
  getLogoutUrl(): string {
    const oauthHost = this.getOauthHost();
    return oauthHost ? oauthHost + '/logout' : '/404';
  }

  /**
   * Removes the token and redirects to the login page
   */
  clearTokenAndRedirectToLoginPage() {
    this.removeToken();
    redirect(this.getLoginUrl());
  }

  /**
   * Removes the token and redirects to the logout page
   */
  clearTokenAndRedirectToLogoutPage() {
    // get the logout url before clearing the token as it grabs it from the token
    let logoutUrl = this.getLogoutUrl();
    this.removeToken();
    redirect(logoutUrl);
  }

  applyTheme() {
    const userData = this.getUserData();

    if (!userData) {
      return;
    }

    let head: HTMLHeadElement | null = document.head;

    if (!head) {
      return;
    }

    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = this.getOauthHost() + userData.branding['vertical_theme'];

    head.appendChild(link);
  }

  hasCapability(capabilityKey: string, capability: string) {
    let userData = this.getUserData();

    if (!userData || !userData.capabilities || !userData.capabilities[capabilityKey]) {
      return false;
    }

    return !!userData.capabilities[capabilityKey][capability];
  }

  getCapabilityValue(capabilityKey: string, capability: string) {
    let userData = this.getUserData();

    if (!userData
      || !userData.capabilities
      || !userData.capabilities[capabilityKey]
      || !userData.capabilities[capabilityKey][capability]
    ) {
      return null;
    }

    return userData.capabilities[capabilityKey][capability];
  }

  // Set theme and user specific app information
  configureForUser() {
    const routes = getRoutesForUser(this);
    this.dispatch(setRoutes(routes));
    this.applyTheme();
    let user = this.getUserData();
    if (user) {
      this.dispatch(setUser(user));
    }
  }

  // Get the oauth host with the protocol
  getOauthHost() {
    const token = this.getToken();
    return token ? window.location.protocol + '//' + token.oauthHost : null;
  }

  getDownloadsCsvExpiryHours() {
    const token = this.getToken();
    return token ? token.csvExpiryHours : null;
  }

  async getMenuItems() {
    const response = await axios.get('/api/navigation');
    return response.data;
  }

  isSchedulerEnabled() {
    const user = this.getUserData();
    return user && user.enableSchedulingOutbounder;
  }

  /**
   * Check if app is running on prod instance by checking oauthHost
   * Will returns true if oauthHost is not dev or staging host otherwise false.
   */
  isProd(): boolean {
    const token = this.getToken();
    const productionDomains = [
      'elmotalent',
      'elmosoftware',
      'elmogov'
    ];

    if (!token || !token.oauthHost) {
      return false;
    }

    return productionDomains.some(item => token.oauthHost.includes(item));
  }

  /**
   * Dashboard capabilities
   *
   * @see CRT-2371
   * @see CRT-2324
   */
  /************************** Dashboard capabilities START *************************************/
  canViewDashboardOverviewPage(): boolean {
    return this.canViewPredictiveAnalytics() || this.canManageDashboard();
  }

  canViewPredictiveAnalytics(): boolean {
    const isCapable = this.hasCapability(CRT_ANALYTICS_CAPABILITIES_KEY, ELMO_PREDICTIVE_ANALYTICS_CAPABILITY);
    return isCapable && (!this.isManager() || this.hasCreateCapability());
  }

  canManageDashboard(): boolean {
    const isCapable = this.hasCapability(CRT_ANALYTICS_CAPABILITIES_KEY, ELMO_CRT_ANALYTICS_CAPABILITY);
    return isCapable && (!this.isManager() || this.canViewDashboardAsManager() || this.hasCreateCapability());
  }

  canViewDashboardAsManager(): boolean {
    return this.isManager() && this.enableDashboardSharing();
  }

  canCreateDashboard(): boolean {
    const user = this.getUserData();
    return !!user && !!user.permissions && user.permissions.dashboard.canCreateDashboard;
  }

  canCreateSuggestedDashboard(): boolean {
    const user = this.getUserData();
    return !!user && !!user.permissions && user.permissions.dashboard.canCreateSuggestedDashboard;
  }

  enableDashboardLazyLoading(): boolean {
    let featureFlags = this.getFeatureFlags();

    return !!featureFlags && featureFlags.enableDashboardLazyLoading;
  }

  enableScheduleTime(): boolean {
    return true;
  }

  enableNewReportBuilder(): boolean {
    return true;
  }

  enableDashboardSharing(): boolean {
    return true;
  }

  /************************** Dashboard capabilities END ************************************/

  /**
   * TODO: cleanup this feature flag once feature is permanently enabled
   */
  enableAndOrFilter(): boolean {
    return true;
  }

  canViewShareChartButton() {
    const user = this.getUserData();
    return !!user && !!user.permissions && user.permissions.dashboard.canAddWidget;
  }

  canAccessReportBuilder() {
    const user = this.getUserData();
    return !!user?.permissions.navigation?.canAccessReportBuilder;
  }

  clearLocalStorageData() {
    localStorage.removeItem(APP_MENU_STORAGE_KEY);
    localStorage.removeItem(APP_MENU_CURRENT_ITEM_STORAGE_KEY);
  }

  setClearingTokensBeforeUnloadCallback() {
    window.addEventListener('beforeunload', () => {
      if (!this.isLogoutInProgress) {
        return;
      }
      this.removeToken();
    });
  }
}
