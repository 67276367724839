// @ts-ignore
(function() {
  // @ts-ignore
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  function CustomEvent(event: any, params: any) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    let evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  // @ts-ignore
  window.CustomEvent = CustomEvent;
})();