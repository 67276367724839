import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const LineChartTypeIcon = ({ className, width = 20, height = 14, color = '#424448' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 13.49L7.5 7.48001L11.5 11.48L20 1.92001L18.59 0.51001L11.5 8.48001L7.5 4.48001L0 11.99L1.5 13.49Z"
        fill={color}
      />
    </svg>
  );
};
