import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const BarChartTypeIcon = ({ className, width = 14, height = 14, color = '#424448' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8 -1.83588e-07L9.8 3L-1.31134e-07 3L0 -6.11959e-07L9.8 -1.83588e-07ZM14 5.6L14 8.4L-3.67176e-07
       8.4L-2.44784e-07 5.6L14 5.6ZM6 11.2L6 14L-6.11959e-07 14L-4.89568e-07 11.2L6 11.2Z"
        fill={color}
      />
    </svg>
  );
};
