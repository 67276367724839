import React, { MouseEvent } from 'react';
import { Action, ActionsDropdown } from '@eds/actions';
import { Avatar } from '@eds/avatar';
import { Box } from '@eds/box';
import { Card, CardBody, CardHeader } from '@eds/card';
import { CustomButton } from '@eds/button';
import { DateTime } from 'luxon';
import { EmptyFolderSpot } from '@eds/illustration';
import { EmptyState } from '@eds/empty-state';
import { Flex } from '@eds/flex';
import { Grid } from '@eds/grid';
import { Heading } from '@eds/heading';
import { Text } from '@eds/text';
import { backgroundColor } from '@eds/core';
import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';

import { cssOverrides } from 'src/features/ui';
import { DashboardCardPreview } from 'src/features/dashboards';
import { getUserUuid } from 'src/features/utils';

import { DashboardData } from './dashboards-list.queries';

type DashboardsCardViewProps = {
  dashboards: DashboardData[];
  getActions: (dashboard: DashboardData) => Action[];
};

const CARD_HEIGHT = '24.5rem';
const DISPLAY_DATE_FORMAT = 'd LLLL yyyy';

export const DashboardsListCardView = ({ dashboards, getActions }: DashboardsCardViewProps) => {
  const history = useHistory();

  const dashboardCreatedByCurrentUser = (uuid: string) => uuid === getUserUuid();

  return dashboards.length === 0 ? (
    <Box paddingY="large">
      <EmptyState
        description="Try a different filter or search value."
        spotIllustration={EmptyFolderSpot}
        title="No results found"
      />
    </Box>
  ) : (
    <Grid
      gap="large"
      gridTemplateColumns={{
        container: {
          xlarge: 'repeat(4, 1fr)',
          large: 'repeat(3, 1fr)',
          medium: 'repeat(2, 1fr)',
          xsmall: '100%',
        },
      }}
    >
      {dashboards.map(dashboard => {
        const { creator, dashboardId, description, modified, title, type } = dashboard;
        const modifiedDate = DateTime.fromSQL(modified.date).toFormat(DISPLAY_DATE_FORMAT);

        return (
          <Grid className={css(cssOverrides.button('neutral'))} key={`dashboard_${dashboardId}`}>
            <CustomButton
              className={css({
                height: '100%',
                width: '100%',
              })}
              onClick={() => history.push(`/dashboards/${dashboardId}`)}
              paddingLeft="none"
              paddingRight="none"
            >
              <Card
                className={css({
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: backgroundColor.neutralSubtle,
                  },
                })}
                height={CARD_HEIGHT}
                hideBorder
                width="100%"
              >
                <CardHeader divider spacing="none">
                  <DashboardCardPreview dashboard={dashboard} />
                </CardHeader>
                <CardBody spacing="large">
                  <Flex flex={1} flexDirection="column" gap="medium" width="100%">
                    <Flex alignItems="center" gap="xsmall">
                      <Avatar
                        name={type === 2 ? 'ELMO' : ''}
                        size="xsmall"
                        src={type === 1 && creator.profilePicture ? creator.profilePicture : ''}
                      />
                      <Text fontSize="xsmall">
                        {type === 2 ? 'ELMO' : dashboardCreatedByCurrentUser(creator.uuid) ? 'Me' : creator.name}
                      </Text>
                    </Flex>
                    <Flex flex={1} flexDirection="column" gap="xsmall" overflow="hidden">
                      <Heading
                        as="h4"
                        className={css({
                          maxHeight: '45px',
                          textAlign: 'left',
                          textWrap: 'wrap',
                          overflow: 'hidden',
                        })}
                        level={4}
                      >
                        {title}
                      </Heading>
                      <Text
                        className={css({
                          textWrap: 'wrap',
                        })}
                        color="neutralSubtle"
                        fontSize="small"
                        maxHeight="42px"
                        textAlign="left"
                      >
                        {description}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" gap="xsmall" justifyContent="space-between">
                      <Text fontSize="xsmall">{`Last modified ${modifiedDate}`}</Text>
                      <Box
                        className={css(cssOverrides.button('neutral'))}
                        onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                      >
                        <ActionsDropdown actions={getActions(dashboard)} size="small" tone="neutral" />
                      </Box>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </CustomButton>
          </Grid>
        );
      })}
    </Grid>
  );
};
