import React, { ComponentProps } from 'react';
import { Box } from '@eds/box';
import { ControlsProps } from '@eds/modal';
import { Divider } from '@eds/divider';
import { Fieldset } from '@eds/fieldset';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';

import { AuthService } from 'src/services/authService';
import { ContentModal, Radio } from 'src/features/ui';
import { ReportInfo, ScheduledReport } from 'src/features/scheduled-reports';

import { DestinationOutbound } from './destination-outbound.component';
import { DestinationUser } from './destination-user.component';
import { ScheduledReportFieldValues } from './scheduled-reports-modal.validation';
import { SchedulerSection } from './scheduler-section.component';
import { useFormValuesInitializer, useScheduledReportForm } from './scheduled-reports-modal.hooks';

const USERS_DESCRIPTION = `Selected users will receive an email notification and can access all report data through a downloadable CSV file on the downloads page.`;

interface CreateMode {
  mode: 'create';
  reportInfo?: ReportInfo;
  scheduledReport?: never;
}

interface EditMode {
  mode: 'edit';
  reportInfo?: never;
  scheduledReport?: ScheduledReport;
}

type ScheduledReportsModalProps = {
  loading: boolean;
  onSubmit: (values: ScheduledReportFieldValues) => void;
} & (CreateMode | EditMode) &
  Omit<ComponentProps<typeof ContentModal>, 'children' | 'controls'>;

export const ScheduledReportsModal = ({
  isOpen,
  loading,
  mode,
  onClose,
  onSubmit,
  reportInfo,
  scheduledReport,
}: ScheduledReportsModalProps) => {
  const { control, formState, getValues, handleSubmit, reset, setValue, trigger, watch } = useScheduledReportForm();
  const { errors, isSubmitted } = formState;

  const canScheduleOutbound = AuthService.getInstance().isSuperAdmin();

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: { label: 'Save', loading, onClick: handleSubmit(onSubmit), tone: 'primary' },
  };

  useFormValuesInitializer(reset, reportInfo, scheduledReport);

  return (
    <ContentModal
      controls={controls}
      dataTracking="scheduled-reports-modal"
      header="Schedule export"
      isOpen={isOpen}
      onClose={onClose}
      preventAutoClose
    >
      <Flex flexDirection="column" gap="large">
        <Box backgroundColor="neutralSubtle" borderRadius="large" padding="medium">
          <Flex flexDirection="column" gap="xxsmall">
            <Text color="neutralSubtle" fontSize="small" fontWeight="medium">
              Report
            </Text>
            <Text>{mode === 'create' ? reportInfo?.reportName : scheduledReport?.query.name}</Text>
          </Flex>
        </Box>
        <Divider />
        <Flex
          as="form"
          containerType="inline-size"
          flexDirection="column"
          gap="large"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SchedulerSection
            control={control}
            errors={errors}
            isSubmitted={isSubmitted}
            legend={mode === 'create' ? 'Create scheduler' : 'Edit scheduler'}
            setValue={setValue}
            timezone={scheduledReport?.timezone}
            trigger={trigger}
            watch={watch}
          />
          <Fieldset
            description={canScheduleOutbound ? undefined : USERS_DESCRIPTION}
            legend="Set-up scheduler"
            legendStyle="group"
            removeSpaceForLastFieldMessage
          >
            {canScheduleOutbound && (
              <Flex
                borderColor="neutralSubtle"
                borderRadius="large"
                borderStyle="solid"
                borderWidth="thin"
                marginBottom="large"
              >
                <Box flex={1} padding="medium">
                  <Radio control={control} name="destination" value="users">
                    <Flex flexDirection="column" gap="xxsmall">
                      <Text>Users</Text>
                      <Text fontSize="small" color="neutralSubtle">
                        {USERS_DESCRIPTION}
                      </Text>
                    </Flex>
                  </Radio>
                </Box>
                <Divider vertical />
                <Box flex={1} padding="medium">
                  <Radio control={control} name="destination" value="outbound">
                    <Flex flexDirection="column" gap="xxsmall">
                      <Text>Outbound</Text>
                      <Text fontSize="small" color="neutralSubtle">
                        Selected destinations will generate a CSV file sent to the designated outbound destination,
                        along with an email notification sent to the specified recipient.
                      </Text>
                    </Flex>
                  </Radio>
                </Box>
              </Flex>
            )}
          </Fieldset>
          {watch('destination') === 'users' ? (
            <DestinationUser
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
          ) : (
            <DestinationOutbound
              control={control}
              errors={errors}
              getValues={getValues}
              isSubmitted={isSubmitted}
              setValue={setValue}
              watch={watch}
            />
          )}
        </Flex>
      </Flex>
    </ContentModal>
  );
};
