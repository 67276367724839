import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import uuidv4 from 'uuid/v4';

import { DashboardData, dashboardsKeys } from 'src/features/dashboards';
import { getUserId } from 'src/features/utils';

import { DashboardCreateEditFieldValues } from './dashboard-create-edit.validation';

const createDashboard = async (
  userId: number,
  data: Pick<DashboardData, 'dashboardId' | 'description' | 'status' | 'title' | 'type'>
) => {
  const response = await axios.post(`api/users/${userId}/dashboards`, data);
  return response.data;
};

const updateDashboard = async (data: DashboardData) => {
  const response = await axios.post(`api/dashboards/${data.dashboardId}`, data);
  return response.data;
};

export const useDashboardCreateEditMutation = (
  mode: 'create' | 'edit',
  callback: {
    onError: () => void;
    onSettled: () => void;
    onSuccess: () => void;
  }
) => {
  const queryClient = useQueryClient();
  const query = queryClient.getQueryCache().findAll(dashboardsKeys.lists(), { type: 'active' }).shift();

  return useMutation<unknown, unknown, DashboardCreateEditFieldValues>({
    mutationFn: async ({ description, id, isSuggested, name }) => {
      if (mode === 'create') {
        const status: 1 | 2 = isSuggested ? 1 : 2;
        const type: 1 | 2 = isSuggested ? 2 : 1;

        const data = {
          dashboardId: uuidv4(),
          description,
          status,
          title: name,
          type,
        };

        return createDashboard(getUserId(), data);
      }

      if (!query) return;

      const { dashboards = [] } = queryClient.getQueryData<{ dashboards: DashboardData[] }>(query.queryKey) || {};
      const dashboard = dashboards.find(({ dashboardId }) => dashboardId === id);

      if (!dashboard) return;

      return updateDashboard({ ...dashboard, description, title: name });
    },
    onError: callback.onError,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: dashboardsKeys.lists() });
      callback.onSettled();
    },
    onSuccess: callback.onSuccess,
  });
};
