import React from 'react';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';

import {
  CopyDashboard,
  CreateDashboard,
  DashboardsList,
  DeleteDashboard,
  EditDashboard,
  PredictiveAnalytics,
  ShareDashboard,
  useDashboardHeaderContext,
} from 'src/features/dashboards';

import { useDashboardsPage } from './dashboards-page.hooks';

export const DashboardsPage = () => {
  const {
    dashboardToCopy,
    dashboardToDelete,
    dashboardToEdit,
    dashboardToShare,
    startCopying,
    startDeleting,
    startEditing,
    startSharing,
    stopCopying,
    stopDeleting,
    stopEditing,
    stopSharing,
  } = useDashboardsPage();

  const { isCreating, stopCreating } = useDashboardHeaderContext();

  return (
    <Box>
      <Flex
        containerType="inline-size"
        width="100%"
        gap={{ large: 'xxlarge', small: 'large', xsmall: 'medium' }}
        flexDirection="column"
        backgroundColor="neutral"
        padding={{ large: 'xxlarge', small: 'large', xsmall: 'medium' }}
      >
        <PredictiveAnalytics />
        <DashboardsList onCopy={startCopying} onDelete={startDeleting} onEdit={startEditing} onShare={startSharing} />
      </Flex>
      <EditDashboard
        description={dashboardToEdit.description}
        id={dashboardToEdit.id}
        name={dashboardToEdit.name}
        onClose={stopEditing}
      />
      <CopyDashboard id={dashboardToCopy} onSettled={stopCopying} />
      <ShareDashboard id={dashboardToShare.id} name={dashboardToShare.name} onClose={stopSharing} />
      <DeleteDashboard
        id={dashboardToDelete.id}
        name={dashboardToDelete.name}
        onClose={stopDeleting}
        unsharingMode={dashboardToDelete.isUnsharing}
      />
      <CreateDashboard isOpen={isCreating} onClose={stopCreating} />
    </Box>
  );
};
