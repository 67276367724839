import * as React from 'react';
import { AppState } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { View } from '../types/viewModel';
import { storeFilterOptions } from '../actions/filter';
import { Measure } from '../types/measureModel';
import { addMeasure, addMeasures, removeMeasure, removeMeasures } from '../actions/measure';
import { findIndex, filter } from 'lodash';
import { SOURCE_WIZARD } from '../constants';
import { GroupBy } from '../types/groupByModel';
import { ColumnSelect, ColumnSelectItemGroup } from '../components/ColumnSelect';
import { MeasureSelectService } from '../services/measureSelectService';
import { Column } from '../types/columnModel';

export interface MeasureSelectProps {
  dispatch: Dispatch<any>;
  view: View;
  selectedMeasures: Measure[];
  selectedGroupBys: GroupBy[];
}

const mapStateToProps = (state: AppState) => ({
  view: state.view,
  selectedMeasures: state.measures,
  selectedGroupBys: state.groupBys
});

export interface MeasureSelectState {
  allSelected: boolean;
  itemGroups: ColumnSelectItemGroup[];
}

/**
 * Renders the measures for a view
 */
class MeasureSelectComponent extends React.Component<MeasureSelectProps, MeasureSelectState> {

  constructor(props: MeasureSelectProps) {
    super(props);

    this.state = {
      allSelected: false,
      itemGroups: []
    };
  }

  componentDidMount() {
    this.createColumnSelectItems(this.props.view, this.props.selectedMeasures, this.props.selectedGroupBys);
  }

  componentDidUpdate(prevProps: MeasureSelectProps) {
    // if the base view has changed
    if (this.props.view.name !== prevProps.view.name) {
      this.createColumnSelectItems(this.props.view, this.props.selectedMeasures, this.props.selectedGroupBys);
      return;
    }

    let measuresChanged: boolean = !Column.arrayEquals(this.props.selectedMeasures, prevProps.selectedMeasures);
    let groupBysChanged: boolean = !GroupBy.arrayEquals(this.props.selectedGroupBys, prevProps.selectedGroupBys);

    if (measuresChanged || groupBysChanged) {
      let itemGroups = this.state.itemGroups.slice();

      if (measuresChanged) {
        itemGroups[0] = MeasureSelectService.createMeasureItems(
          this.props.view,
          this.props.selectedMeasures,
          this.onMeasureAdd,
          this.onMeasureRemove);
      }

      if (groupBysChanged) {
        itemGroups[1] = MeasureSelectService.createGroupByItems(
          this.props.view,
          this.props.view,
          this.props.selectedGroupBys,
          this.props.dispatch);
      }

      this.setState({
        allSelected: this.props.selectedMeasures.length === this.props.view.measures.length,
        itemGroups: itemGroups
      });
    }
  }

  createColumnSelectItems(view: View, selectedMeasures: Measure[], selectedGroupBys: GroupBy[]) {
    let itemGroups: ColumnSelectItemGroup[] = [];
    itemGroups.push(MeasureSelectService.createMeasureItems(
      view,
      selectedMeasures,
      this.onMeasureAdd,
      this.onMeasureRemove));
    itemGroups.push(MeasureSelectService.createGroupByItems(
      view,
      view,
      selectedGroupBys,
      this.props.dispatch));

    this.setState({
      itemGroups: itemGroups
    });
  }

  render() {
    return (
      <ColumnSelect
        title={this.props.view.label}
        isAggregate={true}
        itemGroups={this.state.itemGroups}
        onSelectAllClick={this.toggleSelectAll}
        allSelected={this.state.allSelected}
      />
    );
  }

  protected toggleSelectAll = () => {
    if (this.state.allSelected) {
      this.setState({
        allSelected: false
      });

      this.props.dispatch(removeMeasures(this.props.selectedMeasures, this.props.view, SOURCE_WIZARD));
      this.props.dispatch(storeFilterOptions());
    } else {
      this.setState({
        allSelected: true
      });

      let toAdd: Measure[] = filter(this.props.view.measures, (m: Measure) => {
        return findIndex(this.props.selectedMeasures, ['name', m.name]) === -1;
      });

      this.props.dispatch(addMeasures(toAdd, this.props.view, SOURCE_WIZARD));
      this.props.dispatch(storeFilterOptions());
    }
  }

  protected onMeasureAdd = (item: Measure) => () => {
    this.props.dispatch(addMeasure(item, this.props.view, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onMeasureRemove = (item: Measure) => () => {
    this.props.dispatch(removeMeasure(item, this.props.view, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());

    this.setState({
      allSelected: false
    });
  }
}

const MeasureSelect = connect(
  mapStateToProps
)(MeasureSelectComponent);

export default MeasureSelect;
