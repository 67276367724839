import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { EmptyState } from '@eds/empty-state';

import { useViews } from 'src/features/report-builder';

import { AuthService } from '../../../services/authService';
import { Filter } from '../../../types/filterModel';
import { AppState } from '../../../redux';
import './style.css';
import { createFilterFromFilterOption, getIndexInFilterOptions } from '../../../services/filterService';
import usePrevious from '../../hooks/usePrevious';
import FilterRule from './FilterRule';
import FilterCollapse from './FilterCollapse';

export interface FilterSelectProps {
  isOpen: boolean;
  toggle: () => void;
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
  removeFilter: (index: number) => void;
  canManageFilter: boolean;
}

const FilterSelect = (props: FilterSelectProps) => {
  const { canManageFilter, displayFilters, isOpen, removeFilter, saveDisplayFilter, toggle } = props;
  const { filterOptions } = useSelector((state: AppState) => state);
  const { availableViews, currentView } = useViews();

  const prevIsOpen = usePrevious(isOpen);
  const prevDisplayFiltersLength = usePrevious(displayFilters.length);
  
  const newFilter = () => {
    const defaultFilterOption = filterOptions[0];
    const filter = createFilterFromFilterOption(defaultFilterOption);
    
    saveDisplayFilter(filter, displayFilters.length);
  };

  useEffect(
    () => {
      if (!canManageFilter || displayFilters.length) {
        return;
      }

      if (!prevIsOpen && isOpen && filterOptions.length) {
        newFilter();
      }

      if (!isOpen) {
        return;
      }

      if ((prevDisplayFiltersLength && displayFilters.length === 0) || filterOptions.length === 0) {
        toggle();
      }
    },
    [canManageFilter, displayFilters.length, filterOptions.length, isOpen]
  );

  // disable filter only when they are manager
  // TODO : replace this once BE feature flag is ready
  const disableFilterClassname = AuthService.getInstance().isManager() ? 'disable-filters' : '';

  if (filterOptions.length === 0) {
    return null;
  }

  return (
    <FilterCollapse canManageFilter={canManageFilter} filterGroupsEnabled={false} isOpen={isOpen} toggle={toggle}>
      <Flex
        flexDirection="column"
        gap="medium"
        padding="large"
        borderColor="neutralSubtle"
        borderStyle="solid"
        borderWidth="thin"
        borderRadius="large"
      >
        {displayFilters.length ? (
          displayFilters.map((displayFilter, index) => {
            const displayFilterIndexInFilterOptions = getIndexInFilterOptions(filterOptions, displayFilter);
            const foundView = availableViews.find(({ name }) => displayFilter.viewName === name);
            const foundDimension = foundView?.dimensions.find(({ name }) => displayFilter.dimensionName === name);

            const filterOption =
              displayFilterIndexInFilterOptions === -1
                ? { dimension: foundDimension, view: currentView }
                : filterOptions[displayFilterIndexInFilterOptions];

            const filterRuleProps = {
              canManageFilter,
              displayFilter,
              displayFilterIndexInFilterOptions,
              displayFilters,
              filterIndex: index,
              filterOption,
              newFilter,
              removeFilter,
              saveDisplayFilter,
            };

            return (
              <Box className={!canManageFilter ? disableFilterClassname : ''} key={`${index}`}>
                <FilterRule {...filterRuleProps} />
              </Box>
            );
          })
        ) : (
          <EmptyState
            title="No filters available"
            description="You currently do not have permission to add filters to this report."
          />
        )}
      </Flex>
    </FilterCollapse>
  );
};

export default FilterSelect;
