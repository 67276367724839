import { UPDATE_APP_MENU_COLLAPSE_STATUS, SET_CURRENT_SUBMENU_ITEM } from '../constants';
import { SubmenuItemType } from '../types/elmoMenuModel';

export type UpdateAppMenuCollapseStatusActionType = {
  type: UPDATE_APP_MENU_COLLAPSE_STATUS;
  status: boolean;
};

export function updateAppMenuCollapseStatus(status: boolean): UpdateAppMenuCollapseStatusActionType {
  return {
    type: UPDATE_APP_MENU_COLLAPSE_STATUS,
    status,
  };
}

export type setCurrentSubmenuItemActionType = {
  type: SET_CURRENT_SUBMENU_ITEM;
  submenuItem: SubmenuItemType;
};

export function setCurrentSubmenuItem(submenuItem: SubmenuItemType): setCurrentSubmenuItemActionType {
  return {
    type: SET_CURRENT_SUBMENU_ITEM,
    submenuItem,
  };
}
