import * as React from 'react';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Badge } from '@eds/badge';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import classnames from 'classnames';
import { EmptyState } from '@eds/empty-state';

import { useViews } from 'src/features/report-builder';

import { AppState } from '../../../redux';
import { AuthService } from '../../../services/authService';
import { createFilterFromFilterOption, getIndexInFilterOptions } from '../../../services/filterService';
import { Filter } from '../../../types/filterModel';
import FilterCollapse from '../FilterSelect/FilterCollapse';
import FilterRule from '../FilterSelect/FilterRule';
import usePrevious from '../../hooks/usePrevious';

import '../FilterSelect/style.css';

export interface FilterGroupSelectProps {
  canManageFilter: boolean;
  displayFilterGroups: Array<Array<Filter>>;
  isOpen: boolean;
  removeFilter: (groupIndex: number) => (index: number) => void;
  saveDisplayFilter: (groupIndex: number) => (filter: Filter, index: number, store?: boolean) => void;
  toggle: () => void;
}

const MAX_FILTER_GROUPS = 3;

const FilterGroupSelect = (props: FilterGroupSelectProps) => {
  const { canManageFilter, displayFilterGroups, isOpen, removeFilter, saveDisplayFilter, toggle } = props;
  const { filterOptions } = useSelector((state: AppState) => state);
  const { availableViews, currentView } = useViews();

  const prevIsOpen = usePrevious(isOpen);
  const prevDisplayFilterGroupsLength = usePrevious(displayFilterGroups.length);
  
  const newFilter = (groupIndex: number) => () => {
    const defaultFilterOption = filterOptions[0];
    const filter = createFilterFromFilterOption(defaultFilterOption);
    
    saveDisplayFilter(groupIndex)(filter, (displayFilterGroups[groupIndex] || []).length);
  };

  useEffect(
    () => {
      if (!canManageFilter || displayFilterGroups.length) {
        return;
      }

      if (!prevIsOpen && isOpen && filterOptions.length) {
        newFilter(0)();
      }

      if (!isOpen) {
        return;
      }

      if ((prevDisplayFilterGroupsLength && displayFilterGroups.length === 0) || filterOptions.length === 0) {
        toggle();
      }
    },
    [canManageFilter, displayFilterGroups.length, filterOptions.length, isOpen]
  );

  // disable filter only when they are manager
  // TODO : replace this once BE feature flag is ready
  const disableFilterClassname = AuthService.getInstance().isManager() ? 'disable-filters' : '';
  const displayFilterCount = ([] as Array<Filter>).concat(...displayFilterGroups).length;
  const filterGroupsEnabled = displayFilterGroups.length < MAX_FILTER_GROUPS;

  if (filterOptions.length === 0) {
    return null;
  }

  return (
    <FilterCollapse
      addFilterGroup={newFilter(displayFilterGroups.length)}
      canManageFilter={canManageFilter}
      filterGroupsEnabled={filterGroupsEnabled}
      isOpen={isOpen}
      toggle={toggle}
    >
      {(displayFilterGroups.length ? displayFilterGroups : [[]]).map((displayFilters, groupIndex) => {
        return (
          <Fragment key={`${groupIndex}`}>
            {groupIndex > 0 &&
              <Flex className="or-filter-group-badge" flexDirection="column" alignItems="center">
                <Badge label="OR" tone="neutral" weight="bold" />
              </Flex>
            }
            <Flex
              className={classnames('filter-groups-wrapper', {
                'border-bottom-radius-large':
                  groupIndex + 1 === MAX_FILTER_GROUPS ||
                  (!canManageFilter && groupIndex + 1 === displayFilterGroups.length),
              })}
              flexDirection="column"
              gap="medium"
              padding="large"
              borderColor="neutralSubtle"
              borderStyle="solid"
              borderWidth="thin"
              borderRadius={groupIndex === 0 ? 'large' : 'none'}
            >
              {displayFilters.length ? (
                displayFilters.map((displayFilter, filterIndex) => {
                  const displayFilterIndexInFilterOptions = getIndexInFilterOptions(filterOptions, displayFilter);
                  const foundView = availableViews.find(({ name }) => displayFilter.viewName === name);
                  const foundDimension = foundView?.dimensions.find(({ name }) => displayFilter.dimensionName === name);

                  const filterOption =
                    displayFilterIndexInFilterOptions === -1
                      ? { dimension: foundDimension, view: currentView }
                      : filterOptions[displayFilterIndexInFilterOptions];

                  const filterRuleProps = {
                    canManageFilter,
                    displayFilter,
                    displayFilterCount,
                    displayFilterIndexInFilterOptions,
                    displayFilters,
                    filterIndex,
                    filterOption,
                    newFilter: newFilter(groupIndex),
                    removeFilter: removeFilter(groupIndex),
                    saveDisplayFilter: saveDisplayFilter(groupIndex),
                  };

                  return (
                    <Box className={!canManageFilter ? disableFilterClassname : ''} key={`${filterIndex}`}>
                      <FilterRule {...filterRuleProps} />
                    </Box>
                  );
                })
              ) : (
                <EmptyState
                  title="No filters available"
                  description="You currently do not have permission to add filters to this report."
                />
              )}
            </Flex>
          </Fragment>
        );
      })}
    </FilterCollapse>
  );
};

export default FilterGroupSelect;
