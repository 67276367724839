import { MutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ScheduledReport, scheduledReportsKeys } from 'src/features/scheduled-reports';

export const fetchScheduledReportsList = async (signal?: AbortSignal) => {
  const source = axios.CancelToken.source();

  const promise = axios.get<{ schedules: Array<ScheduledReport> }>('/api/schedules', {
    cancelToken: source.token,
  });

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query');
  });

  const response = await promise;
  return response.data.schedules;
};

const runScheduledReport = async (scheduledReportId: string) => {
  const response = await axios.post('api/schedule/run', {
    schedule_ids: [scheduledReportId],
  });
  return response.data;
};

export const useRunScheduledReportMutation = (onSuccess: MutationOptions<unknown, unknown, string>['onSuccess']) =>
  useMutation<unknown, unknown, string>({
    mutationFn: scheduledReportId => runScheduledReport(scheduledReportId),
    onSuccess,
  });

export const useScheduledReportsListQuery = () =>
  useQuery({
    queryKey: scheduledReportsKeys.lists(),
    queryFn: ({ signal }) => fetchScheduledReportsList(signal),
  });
