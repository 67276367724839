import React, { ReactNode } from 'react';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { CloseIcon } from '@eds/icon';
import { Flex } from '@eds/flex';
import { Heading } from '@eds/heading';
import { headingSize, space } from '@eds/core';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

interface CustomHeaderProps {
  header: ReactNode;
  onClose: () => void;
  type?: 'alert' | 'content';
}

export const CustomHeader = ({ header, onClose, type = 'content' }: CustomHeaderProps) => {
  const isAlert = type === 'alert';

  return (
    <Flex
      alignItems="center"
      className={
        isAlert
          ? css({
              '& ~ div[role="separator"]': {
                display: 'none', // NOTE: Hide <Divider />
              },
              '& + div + div': {
                // NOTE: This is the wrapper <Box /> for ContentModal children
                paddingTop: space.small,
                paddingBottom: space.small,
              },
            })
          : undefined
      }
      justifyContent="space-between"
      padding="large"
      paddingBottom={isAlert ? 'none' : 'large'}
    >
      <Heading
        level={2}
        className={css({
          fontSize: isAlert ? headingSize['5'] : headingSize['3'],
        })}
      >
        {header}
      </Heading>
      {isAlert ? undefined : (
        <Box className={css(cssOverrides.button('ghost'))}>
          <Button icon={CloseIcon} iconOnly label="Close" onClick={onClose} size="small" tone="ghost" />
        </Box>
      )}
    </Flex>
  );
};
