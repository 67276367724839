import * as React from 'react';
import moment from 'moment';

import { DATETIME_FORMAT } from '../../../constants';
import { Filter } from '../../../types/filterModel';
import { Moment } from '../../../constants/moment';
import Datepicker from '../Datepicker';

import FormField from './FormField';

interface FilterDatepickerInputProps {
  displayFilter: Filter;
  filterIndex: number;
  isDisabled: boolean;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
}

const FilterDatepickerInput = (props: FilterDatepickerInputProps) => {
  const { displayFilter, filterIndex, isDisabled, saveDisplayFilter } = props;

  const onChange = (d: Moment | null) => {
    const filter = { ...displayFilter };
    filter.value = d ? d.format(DATETIME_FORMAT) : '';
    saveDisplayFilter(filter, filterIndex);
  };

  const momentValue = moment(displayFilter.value as string | number, DATETIME_FORMAT);

  return (
    <div className="datepicker-override filter-value">
      <FormField label="Value">
        <Datepicker
          value={momentValue}
          onChange={onChange}
          isClearable={false}
          isDisabled={isDisabled}
        />
      </FormField>
    </div>
  );
};

export default FilterDatepickerInput;
