import * as React from 'react';
import { Alert, FormItem, Input, Checkbox, TextArea } from 'elmo-elements';

import { SaveReportButton } from 'src/features/report-builder';
import { getPermissions } from 'src/features/utils';

import { SavedQuery } from '../../../types/savedQueryModel';
import { REPORT_TYPE_SUGGESTION } from '../../../constants';
import { UserCapabilitiesType } from '../../../services/reportService';
import ElmoButton from '../ElmoButton';
import ElmoModal from '../ElmoModal';

export interface SaveQueryProps {
  onSaveClicked: (saveQueryFormData: SaveQueryFormData, saveAs: boolean) => void;
  saving: boolean;
  showSaveError: boolean;
  setShowModal: (showModal: boolean, saveAs: boolean) => void;
  showModal: boolean;
  disabled?: boolean;
  canSaveSuggestions: boolean;
  loadedQuery?: SavedQuery;
  userCapabilities?: UserCapabilitiesType;
  saveAs: boolean;
  readOnly: boolean;
}

export interface SaveQueryFormData {
  name: string;
  description: string;
  userReport: boolean;
  suggestedReport: boolean;
  tempReport?: boolean;
  dashboardReport?: boolean;
}

interface SaveQueryState {
  formData: SaveQueryFormData;
  formError: {
    name: string;
    reportType: string;
  };
  firstSave: boolean;
}

function getInitialSaveQueryState(): SaveQueryState {
  return {
    formData: {
      name: '',
      description: '',
      userReport: true,
      suggestedReport: false
    },
    formError: {
      name: '',
      reportType: ''
    },
    firstSave: true
  };
}

class SaveQuery extends React.Component<SaveQueryProps, SaveQueryState> {

  constructor(props: SaveQueryProps) {
    super(props);
    this.state = getInitialSaveQueryState();
  }

  componentDidUpdate(prevProps: SaveQueryProps) {
    if (this.props.showModal && (prevProps.showModal !== this.props.showModal)) {
      let prepopulatedState = getInitialSaveQueryState();
      prepopulatedState.firstSave = this.state.firstSave;
      // prepopulatedState.saveAs = this.state.saveAs;

      // if the save as controls are hidden, then save as a user report
      // prepopulatedState.formData.userReport = !this.showSaveAsControls();
      prepopulatedState.formData.suggestedReport = false;

      if (this.props.loadedQuery) {
        prepopulatedState.formData.name = this.props.loadedQuery.name;
        prepopulatedState.formData.description = this.props.loadedQuery.description;
      }

      this.setState(prepopulatedState);
    }

    if (this.props.loadedQuery !== prevProps.loadedQuery) {
      let firstSave = !this.props.loadedQuery || !this.props.loadedQuery.id ||
        (this.props.loadedQuery && !this.props.canSaveSuggestions &&
          this.props.loadedQuery.reportType === REPORT_TYPE_SUGGESTION);

      this.setState({
        firstSave: firstSave
      });
    }
  }

  handleFormChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let formData: SaveQueryFormData = this.state.formData;
    if (event.currentTarget.type === 'checkbox') {
      formData.userReport = !event.currentTarget['checked'];
      formData.suggestedReport = event.currentTarget['checked'];
    } else {
      formData[property] = event.currentTarget.value;
    }
    this.setState({formData: formData});
  }

  protected onSaveClicked = () => {
    if (!this.formIsValid()) {
      return;
    }

    this.props.onSaveClicked(this.state.formData, this.props.saveAs);
  }

  protected formIsValid() {
    let isValid: boolean = true;

    let errors = {
      name: '',
      reportType: ''
    };

    if (this.state.formData.name.trim().length === 0) {
      errors.name = 'Please provide a valid name.';
      isValid = false;
    }

    if (this.showSaveAsControls() && !this.state.formData.suggestedReport && !this.state.formData.userReport) {
      errors.reportType = 'Please select a report type.';
      isValid = false;
    }

    this.setState({
      formError: errors,
    });

    return isValid;
  }

  toggle = () => {
    this.props.setShowModal(!this.props.showModal, false);
  }

  render() {
    const { loadedQuery, userCapabilities, disabled, showModal, saveAs, saving, showSaveError, readOnly } = this.props;
    const { formData, formError } = this.state;
    const showSharedWithUsersAlert = this.showSharedWithUsersAlert();
    const canSave = loadedQuery && loadedQuery.permissions && loadedQuery.permissions.canSave;
    const canSaveAs = loadedQuery && loadedQuery.permissions && loadedQuery.permissions.canClone;
    const firstSave = (this.state.firstSave) && (userCapabilities && userCapabilities.report.canCreateReport);
    const existSave = (!this.state.firstSave && canSave);

    const displaySaveAsSuggestedReport = saveAs && getPermissions().report.canSaveSuggestedReport;
    
    // TODO: Revisit this logic for Save As button
    const displaySaveButton = firstSave || existSave || canSaveAs;

    return (
      <div className="save-query d-inline-block" role="menu">
        {readOnly && !canSaveAs ? (
          <SaveReportButton isEditing={false} tooltipLabel="You do not have permission to clone this report." />
        ) : (
          displaySaveButton && (
            <SaveReportButton
              checkForExperimentalViews
              disabled={disabled}
              isEditing={!readOnly && !!existSave}
              onClick={() => {
                this.props.setShowModal(true, !!firstSave);
              }}
              tooltipLabel="This report cannot be saved because it contains experimental fields."
            />
          )
        )}
        <ElmoModal
          id="saveQueryModal"
          type="dialog"
          isOpened={showModal}
          title={!readOnly && existSave ? 'Save' : 'Save As'}
          closeModal={this.cancel}
          primaryButton={
            <>
              <ElmoButton type="text" onClick={this.cancel}>
                Cancel
              </ElmoButton>
              <ElmoButton type="primary" onClick={this.onSaveClicked} isDisabled={saving} isLoading={saving}>
                Save
              </ElmoButton>
            </>
          }
          className="saveReportModal"
        >
          <div data-tracking="report-title">
            <FormItem label="Title (required)" message={formError.name} status={formError.name ? 'error' : undefined}>
              <Input
                value={formData.name}
                id="name"
                onChange={this.handleFormChange('name')}
                placeholder="The title of your report"
                data-testid="save-report-form-name"
              />
            </FormItem>
          </div>
          <FormItem label="Description">
            <TextArea
              id="description"
              name="description"
              placeholder="Write here..."
              value={formData.description}
              onChange={this.handleFormChange('description')}
              data-testid="save-report-form-description"
            />
          </FormItem>
          {displaySaveAsSuggestedReport && (
            <Checkbox
              id="suggestedReport"
              name="suggestedReport"
              label="Suggested Report"
              ariaLabel="Suggested Report"
              onChange={this.handleFormChange('suggestedReport')}
              isChecked={this.state.formData.suggestedReport}
              data-testid="save-report-form-suggested-report-checkbox"
            />
          )}
          {showSharedWithUsersAlert && (
            <Alert
              message="If this report has been scheduled or shared with other users, any saved changes will be applied accordingly."
              type="info"
              isCloseable={false}
            />
          )}
          {showSaveError && <Alert message="Query could not be saved." type="danger" />}
        </ElmoModal>
      </div>
    );
  }

  showSaveAsControls = () => {
    const { saveAs, canSaveSuggestions } = this.props;
    return saveAs && canSaveSuggestions;
  }

  showSharedWithUsersAlert = () => !this.props.saveAs && this.props.loadedQuery;

  cancel = () => {
    this.props.setShowModal(false, false);
  }
}

export default SaveQuery;
