import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const DragVerticleIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg 
      className={className}
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill="currentColor" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.3333 8H3V9.66667H16.3333V8ZM3 13H16.3333V11.3333H3V13Z" />
    </svg>
  );
};
