import { getResetState } from '../reducers/index';
import { AppState } from '../redux';

export function resetReportBuilderColumns(state: AppState) {
  let resetState = getResetState();
  resetState.module = state.module;
  resetState.view = state.view;
  resetState.views = state.views;
  resetState.dataType = state.dataType;
  resetState.suggestedReports = state.suggestedReports;

  return Object.assign(state, resetState);
}