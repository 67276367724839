import * as React from 'react';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, SuggestedReportState } from '../redux';
import { QueryLoaderService } from '../services/queryLoaderService';
import { SavedQuery } from '../types/savedQueryModel';
import SuggestedReportsPopoverComponent from '../components/SuggestedReportsPopover';
import { setSuggestionsOpen, toggleSuggestions } from '../actions/suggestions';
import { View } from '../types/viewModel';
import { SuggestedReportService } from '../services/suggestedReportService';
import { AuthService } from '../services/authService';
import { REPORT_TYPE_DASHBOARD } from '../constants';

interface SuggestedReportsProps {
  dispatch: Dispatch<any>;
  suggestedReports: SuggestedReportState;
  views: View[];
  view: View;
  authService: AuthService;
}

const mapStateToProps = (state: AppState) => ({
  suggestedReports: state.suggestedReports,
  views: state.views,
  view: state.view,
  authService: state.authService,
});

class SuggestedReportsContainer extends React.Component<SuggestedReportsProps> {
  protected queryLoaderService: QueryLoaderService;
  protected renderTimeout: NodeJS.Timer;
  protected suggestedReportService: SuggestedReportService;

  constructor(props: SuggestedReportsProps) {
    super(props);

    this.queryLoaderService = new QueryLoaderService();
    this.suggestedReportService = SuggestedReportService.getInstance();
  }

  componentDidUpdate(prevProps: SuggestedReportsProps) {
    if (!prevProps.view.name && this.props.view.name !== prevProps.view.name) {
      // force a re-render when the suggestion is clicked so that the position of the modal is correct
      // This requires a setTimeout because of the open/close animation of the report wizard
      this.renderTimeout = setTimeout(
        () => {
          this.forceUpdate();
        },
        200
      );
    }
  }

  render() {
    return (
      <SuggestedReportsPopoverComponent
        suggestedReports={this.props.suggestedReports}
        onSuggestionClick={this.onSuggestionClick}
        toggle={this.toggle}
        setOpen={this.setOpen}
        loading={this.props.views.length === 0}
      />
    );
  }

  onSuggestionClick = async (suggestion: SavedQuery) => {
    const userData = this.props.authService.getUserData();
    const userId = userData ? userData.id : '';
    this.props.dispatch(this.queryLoaderService.dispatchLoadQueryIntoAppState(
        // CRT-3853. If report was not saved, reportType should have 4 value (REPORT_TYPE_DASHBOARD),
        // userId should be changed as is for current user
        {...omit(suggestion, ['permissions']), reportType: REPORT_TYPE_DASHBOARD, userId, id: '' } as SavedQuery,
        true,
      ),
    );
  }

  componentWillUnmount() {
    // close the suggestions modal before unmounting
    this.props.dispatch(setSuggestionsOpen(false));
    clearInterval(this.renderTimeout);
  }

  toggle = async () => {
    await this.props.dispatch(toggleSuggestions());

    // after the suggestions modal has been toggled open, if there are selected tags in the redux store,
    // but no results, do an api request to get the suggestions
    if (this.props.suggestedReports.isOpen && this.props.suggestedReports.suggestions.length === 0) {
      this.props.dispatch(this.suggestedReportService.getSuggestionsForTagsInStore());
    }
  }

  setOpen = (isOpen: boolean) => () => {
    this.props.dispatch(setSuggestionsOpen(isOpen));
  }
}

const SuggestedReports = connect(
  mapStateToProps,
)(SuggestedReportsContainer);

export default SuggestedReports;
