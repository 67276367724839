import * as React from 'react';
import { Dropdown } from 'elmo-elements';
import { map } from 'lodash';

import { Dashboard } from '../../../types/dashboardModel';
import { redirect } from '../../../services/utilityService';

type DashboardsDropdownProps = {
  dashboard: Dashboard;
  dashboards: Dashboard[];
};

const DashboardsDropdown = (props: DashboardsDropdownProps) => {
  const dashboardDropdownChange = (link: string) => () => {
    redirect(link);
  };

  return (
    <Dropdown
      text={props.dashboard ? props.dashboard.title : 'Select dashboard'}
      ariaLabel="Select dashboard"
      position={'bottom-start'}
      className="dashboardsDropdown"
    >
      {map(props.dashboards, (dashboard: Dashboard, i: number) => {
        return (
          <React.Fragment key={i}>
            <Dropdown.Item
              ariaLabel="View dashboard"
              value={i}
              onClick={dashboardDropdownChange('/dashboards/' + dashboard.dashboardId)}
              key={i}
            >
              {dashboard.title}
            </Dropdown.Item>
          </React.Fragment>
        );
      })}
    </Dropdown>
  );
};

export default DashboardsDropdown;
