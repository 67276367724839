import React, { useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';

import { DashboardCreateEdit } from 'src/features/dashboards';

type CreateDashboardProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateDashboard = (props: CreateDashboardProps) => {
  const [errorMessage, setErrorMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const onError = () =>
    setErrorMessage(toastMessage('An issue occurred during the dashboard creation, please try again later'));
  const onSuccess = () => setSuccessMessage(toastMessage('Dashboard created'));

  return (
    <Box>
      <DashboardCreateEdit mode="create" onError={onError} onSuccess={onSuccess} {...props} />
      {errorMessage && <Toast message={errorMessage} tone="critical" />}
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
