import Report from '../../../containers/NewReportBuilder/Report';
import * as React from 'react';
import './style.css';
import { SavedQuery } from '../../../types/savedQueryModel';
import { View } from '../../../types/viewModel';
import { GENERATE_ACTION } from '../../../constants';
import { ReportTable } from '../../../types/reportTableModel';
import { Chart } from '../../../types/chartModel';
import ChartSelect from '../../../containers/NewReportBuilder/ChartSelect';

interface ReportPrintPagePageProps {
  loadedQuery?: SavedQuery;
  encodedQuery: string | undefined;
  selectedView: View;
  suggestionsOpen: boolean;
  generateReportStatus: GENERATE_ACTION;
  reportResult: ReportTable;
  chart: Chart;
}

export class ReportPrintPage extends React.Component<ReportPrintPagePageProps> {

  render() {
    return (
      <div className="reporting-print-only-page">
        <button
          className="btn btn-primary print-btn"
          onClick={function() { window.print(); }}
        >
          <i className="fa fa-print"/>
          Print
        </button>
        {this.props.chart.chartId &&
        <div className="chart">
          <ChartSelect loadedQuery={this.props.loadedQuery} readOnly={true} printView={true} />
        </div>
        }
        <Report
          loadedQuery={this.props.loadedQuery}
          readOnly={true}
          printView={true}
        />
      </div>
    );
  }

}
