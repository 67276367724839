import { FILTER_SOURCE_DRILLDOWN, FILTER_TYPE_EQUALS } from '../constants/filters';
import { ReportCell } from '../types/reportCellModel';
import { Filter } from '../types/filterModel';
import { ReportRow } from '../types/reportRowModel';
import { filter, map } from 'lodash';
import { ColumnMeta } from '../types/columnMetaModel';
import { Join } from '../types/joinModel';
import { View } from '../types/viewModel';
import { Measure } from '../types/measureModel';
import axios, { AxiosResponse } from 'axios';

/**
 * Create the filters which will be used to get the drilldown data for a row.
 * These are used as the WHERE clause in the generated SQL on the backend
 *
 * @param {ReportRow} currentRowData
 * @param {ColumnMeta[]} columnMeta
 * @returns {any[]}
 */
export function createFiltersForDrilldownQuery(currentRowData: ReportRow, columnMeta: ColumnMeta[]) {
  return filter(map(currentRowData.cells, (cell: ReportCell, cellIndex: number) => {
    let columnMetaDimension = columnMeta[cellIndex].dimension;
    if (!columnMeta[cellIndex].forGroupBy || !columnMetaDimension) {
      return null;
    }

    let groupByFilter = new Filter();
    groupByFilter.type = FILTER_TYPE_EQUALS;
    groupByFilter.dimensionName = columnMetaDimension.name;
    groupByFilter.value = cell.data.value;
    groupByFilter.viewName = columnMeta[cellIndex].view.name; // filters use the base view
    groupByFilter.source = FILTER_SOURCE_DRILLDOWN;

    let columnMetaJoin = columnMeta[cellIndex].join;
    if (columnMetaJoin) {
      groupByFilter.joinName = columnMetaJoin.fullJoinName; // filters need the full dot notation join name
    }

    return groupByFilter;
  }));
}

export async function apiQueueReportDrilldown(query: string,
                                              measure: Measure,
                                              measureView: View,
                                              measureJoin?: Join,
                                              jobId?: number) {
  const data = {
    query: query,
    measure: measure.name,
    measureView: measureView.name,
    measureJoin: measureJoin ? measureJoin.fullJoinName : '',
    jobId: jobId
  };

  const response: AxiosResponse = await axios.post(
    '/api/report/drill-down/queue',
    data);

  return response.data.jobId;
}

export async function apiQueueDrilldownCsv(query: string,
                                           measure: Measure,
                                           measureView: View,
                                           measureJoin?: Join,
                                           reportTitle?: string,
                                           jobId?: number) {
  let data = {
    query: query,
    measure: measure.name,
    measureView: measureView.name,
    measureJoin: measureJoin ? measureJoin.fullJoinName : '',
    reportTitle: reportTitle ? reportTitle : null,
    jobId: jobId
  };

  try {
    let response: AxiosResponse = await axios.post(
      '/api/csv/drill-down',
      data);

    return response.data;
  } catch (error) {
    return null;
  }

}
