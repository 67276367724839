import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

import { SelectInput } from 'src/features/ui';

import { OutboundEncryption } from './outbound-encryption.component';
import { OutboundFilename } from './outbound-filename.component';
import { OutboundNotification } from './outbound-notification.component';
import { ScheduledReportFieldValues } from './scheduled-reports-modal.validation';
import { useOutboundDestinationsQuery } from './scheduled-reports-modal.queries';

type DestinationOutboundProps = {
  errors: FieldErrors<ScheduledReportFieldValues>;
  isSubmitted: boolean;
} & Pick<UseFormReturn<ScheduledReportFieldValues>, 'control' | 'getValues' | 'setValue' | 'watch'>;

export const DestinationOutbound = ({
  control,
  errors,
  getValues,
  isSubmitted,
  setValue,
  watch,
}: DestinationOutboundProps) => {
  const [outboundDestinationInput, setOutboundDestinationInput] = useState('');
  const { data: outboundDestinations = [], isLoading: isOutboundDestinationsLoading } = useOutboundDestinationsQuery();

  useEffect(() => {
    if (!outboundDestinations.length) return;

    const selectedOutboundDestination = outboundDestinations.find(
      ({ value }) => getValues('outboundDestination.value') === value
    );

    if (selectedOutboundDestination) setValue('outboundDestination', selectedOutboundDestination);
  }, [outboundDestinations]);

  return (
    <Flex flexDirection="column" gap="large">
      <Box
        borderColor="neutralSubtle"
        borderRadius="large"
        borderStyle="solid"
        borderWidth="thin"
        padding="large"
        paddingBottom="none"
      >
        <Field
          invalid={!!errors.outboundDestination}
          label="Outbound destination"
          message={errors.outboundDestination?.message}
          required
        >
          <SelectInput
            control={control}
            inputValue={outboundDestinationInput}
            isLoading={isOutboundDestinationsLoading}
            items={outboundDestinations}
            menuPlacement="top"
            name="outboundDestination"
            onInputChange={inputValue => setOutboundDestinationInput(inputValue)}
          />
        </Field>
      </Box>
      {watch('outboundDestination') && (
        <Flex flexDirection="column" gap="large">
          <OutboundNotification
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <OutboundEncryption control={control} errors={errors} watch={watch} />
          <OutboundFilename
            control={control}
            errors={errors}
            isSubmitted={isSubmitted}
            setValue={setValue}
            watch={watch}
          />
        </Flex>
      )}
    </Flex>
  );
};
