import * as React from 'react';
import { Grid } from '@eds/grid';
import { connect } from 'react-redux';

import { AppState } from '../../../redux';
import {
  DIMENSION_TYPE_DATETIME,
  DIMENSION_TYPE_DATEINTERVAL,
  DIMENSION_TYPE_NUMBER,
  DATE_DIMENSION_TYPES
} from '../../../constants';
import {
  FILTER_DATE_CONTEXT_DATE,
  FILTER_TYPE,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_IN
} from '../../../constants/filters';
import { Filter } from '../../../types/filterModel';
import { ViewJoinAndDimensionOrMeasure } from '../../../redux';
import { getColumnType } from '../../../services/filterService';
import FilterDateRangeInput from './FilterDateRangeInput';
import FilterDatePeriodInput from './FilterDatePeriodInput';
import FilterRelativeDateInput from './FilterRelativeDateInput';
import FilterDatepickerInput from './FilterDatepickerInput';
import FilterTextSelect from './FilterTextSelect';
import FilterMultiCreatableSelect from './FilterMultiCreatableSelect';
import { Module } from '../../../types/moduleModel';
import { View } from '../../../types/viewModel';

export const FILTERS_FOR_TIME_CONTEXT = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN
];

type FilterValueInputProps = {
  displayFilter: Filter;
  filterOption: ViewJoinAndDimensionOrMeasure;
  filterIndex: number;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
  isDisabled: boolean;
  module: Module;
  view: View;
 };

const mapStateToProps = (state: AppState) => ({
  module: state.module,
  view: state.view
});

class FilterValueInput extends React.Component<FilterValueInputProps> {
  static dateRangeInputRequired(fieldType: string, filterType: FILTER_TYPE) {
    return [DIMENSION_TYPE_DATETIME, DIMENSION_TYPE_DATEINTERVAL].includes(fieldType)
        && filterType === FILTER_TYPE_BETWEEN;
  }

  static multiCreatableSelectRequired(fieldType: string, filterType: FILTER_TYPE) {
    return !DATE_DIMENSION_TYPES.includes(fieldType)
      && [FILTER_TYPE_EQUALS, FILTER_TYPE_NOT_EQUALS, FILTER_TYPE_IN].includes(filterType);
  }

  static textInputRequired(fieldType: string, filterType: FILTER_TYPE) {
    return !DATE_DIMENSION_TYPES.includes(fieldType) && [
      FILTER_TYPE_LIKE,
      FILTER_TYPE_NOT_LIKE,
      FILTER_TYPE_LESS_THAN,
      FILTER_TYPE_GREATER_THAN
    ].includes(filterType);
  }

  static datePeriodInputRequired(fieldType: string, filterType: FILTER_TYPE) {
    return DATE_DIMENSION_TYPES.includes(fieldType)
        && FILTERS_FOR_TIME_CONTEXT.includes(filterType);
  }

  static datePickerInputRequired(
    fieldType: string,
    filter: Filter
  ) {
    return [DIMENSION_TYPE_DATETIME, DIMENSION_TYPE_DATEINTERVAL].includes(fieldType)
        && FILTERS_FOR_TIME_CONTEXT.includes(filter.type)
      && filter.relativeDate === FILTER_DATE_CONTEXT_DATE;
  }

  clearFilterAutocompleteValues = () => {
    this.setState({ autocompleteValues: [] });
  }

  renderFormControl() {
    const { displayFilter, filterOption, filterIndex, saveDisplayFilter, isDisabled } = this.props;
    let columnType: string = getColumnType(filterOption);
    if (FilterValueInput.dateRangeInputRequired(columnType, displayFilter.type)) {
      return (
        <FilterDateRangeInput
          displayFilter={displayFilter}
          filterIndex={filterIndex}
          saveDisplayFilter={saveDisplayFilter}
          isDisabled={isDisabled}
        />
      );
    } else if (FilterValueInput.multiCreatableSelectRequired(columnType, displayFilter.type)) {
      return (
        <FilterMultiCreatableSelect
          displayFilter={displayFilter}
          enableNumberValidation={columnType === DIMENSION_TYPE_NUMBER}
          filterIndex={filterIndex}
          saveDisplayFilter={saveDisplayFilter}
          filterOption={filterOption}
          isDisabled={isDisabled}
        />
      ) ;
    } else if (FilterValueInput.textInputRequired(columnType, displayFilter.type)) {
      return (
        <FilterTextSelect
          displayFilter={displayFilter}
          enableNumberValidation={columnType === DIMENSION_TYPE_NUMBER}
          filterIndex={filterIndex}
          saveDisplayFilter={saveDisplayFilter}
          filterOption={filterOption}
          isDisabled={isDisabled}
        />
      ) ;
    } else if (FilterValueInput.datePeriodInputRequired(columnType, displayFilter.type)) {
      return (
        <Grid gridTemplateColumns="minmax(7rem, 1fr) minmax(15rem, 1fr)" gap="medium">
          <FilterRelativeDateInput
            displayFilter={displayFilter}
            filterIndex={filterIndex}
            saveDisplayFilter={saveDisplayFilter}
            isDisabled={isDisabled}
          />
          {FilterValueInput.datePickerInputRequired(columnType, displayFilter) &&
            <FilterDatepickerInput
              displayFilter={displayFilter}
              filterIndex={filterIndex}
              saveDisplayFilter={saveDisplayFilter}
              isDisabled={isDisabled}
            />
          }
          {!FilterValueInput.datePickerInputRequired(columnType, displayFilter) &&
            <FilterDatePeriodInput
              displayFilter={displayFilter}
              filterIndex={filterIndex}
              saveDisplayFilter={saveDisplayFilter}
              isDisabled={isDisabled}
            />
          }
        </Grid>
      );
    }

    return null;
  }

  render() {
    return this.renderFormControl();
  }
}

export default connect(mapStateToProps)(FilterValueInput);
