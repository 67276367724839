import React, { FC } from 'react';
import { Layout, Loader, Header as ElmoHeader } from 'elmo-elements';
import { HeaderTabLinkProps, BackButtonType, PrimaryButtonType } from 'elmo-elements/Header';
import { useHistory } from 'react-router-dom';

export type HeaderPropsType = {
  isLoading?: boolean;
  title?: string;
  tabs?: HeaderTabLinkProps[];
  isBackButton?: boolean;
  className?: string;
  titleAddon?: React.ReactNode;
  primaryButton?: PrimaryButtonType;
};

const LayoutHeader: FC<HeaderPropsType> = ({
  isLoading = false,
  title,
  tabs,
  isBackButton = false,
  className,
  titleAddon,
  primaryButton,
  children,
}) => {
  const history = useHistory();

  const backButton: BackButtonType = {
    title: 'back',
    onClick: () => {
      history.goBack();
    },
    ariaLabel: 'back',
  };

  return (
    <Layout.Header>
      <div className={className}>
        {!isLoading && !!title ? (
          <ElmoHeader
            title={title}
            backButton={isBackButton ? backButton : undefined}
            titleAddon={titleAddon}
            tabs={tabs}
            primaryButton={primaryButton}
          >
            {children}
          </ElmoHeader>
        ) : (
          <ElmoHeader>
            <Loader type="text" />
          </ElmoHeader>
        )}
      </div>
    </Layout.Header>
  );
};

export default LayoutHeader;
