import * as React from 'react';
import { Radio, RadioButtons } from 'elmo-elements';
import { SCHEDULE_NOTIFICATION_TYPE_EMAIL, SCHEDULE_NOTIFICATION_TYPE_USERS } from '../../../constants/addSchedule';

type Props = {
  isOutboundEmailNotification: boolean;
  onChange: (value: any)  => void;
};

const ScheduleDispatchOption = [
  SCHEDULE_NOTIFICATION_TYPE_USERS,
  SCHEDULE_NOTIFICATION_TYPE_EMAIL
];

function OutboundNotificationToggle(props: Props) {
  const {
    isOutboundEmailNotification,
    onChange
  } = props;

  const selected = isOutboundEmailNotification ? SCHEDULE_NOTIFICATION_TYPE_EMAIL : SCHEDULE_NOTIFICATION_TYPE_USERS;

  return (
    <RadioButtons
      type="solid"
      selected={selected}
      onChange={onChange}
      id="schedule-outbound-toggle"
    >
      {ScheduleDispatchOption.map((label: string, key: number) => {
        return (
          <Radio
            value={label}
            key={key}
          >
            {label}
          </Radio>
        );
      })}
    </RadioButtons>
  );
}

export default OutboundNotificationToggle;