import * as React from 'react';
import { Dropdown, FormItem } from 'elmo-elements';

import { OCCURRENCE_MONTHLY, OCCURRENCE_QUARTERLY, OccurrenceOption } from '../../../constants/addSchedule';

import './style.css';

type Props = {
  occurrence: OccurrenceOption;
  selected: string;
  onSelect: (option: string) => () => void;
  errors: any;
};

export const FIRST_DAY_OF_MONTH = 'First';
export const LAST_DAY_OF_MONTH = 'Last';

function renderItem(value: string, onSelect: Function, divider: boolean = false) {
  return (
    <Dropdown.Item
      key={value}
      value={value}
      onClick={onSelect(value)}
      {...divider ? { dividerType: 'default' } : {}}
    >
      {value}
    </Dropdown.Item>
  );
}

function DaysOfMonthSelect({ selected, onSelect, occurrence, errors }: Props) {
  const isVisible = (occurrence === OCCURRENCE_MONTHLY) ||
    (occurrence === OCCURRENCE_QUARTERLY);

  if (!isVisible) {
    return null;
  }

  const items: React.ReactNode[] = [];
  const MONTH_START = 2;
  const MONTH_END = 28;

  [FIRST_DAY_OF_MONTH, LAST_DAY_OF_MONTH].map((value) => {
    const hasDivider: boolean = value === LAST_DAY_OF_MONTH;
    items.push(renderItem(value.toString(), onSelect, hasDivider));
  });

  for (let value = MONTH_START; value <= MONTH_END; value++) {
    items.push(renderItem(value.toString(), onSelect));
  }

  const selectedText = selected ? selected : 'Day of the month';

  return (
    <FormItem
      label="Day of the month:"
      message={!!errors['calendarDays'] ? errors['calendarDays'] : undefined}
      status={!!errors['calendarDays'] ? 'error' : undefined}
      data-testid="schedule-day-of-month"
    >
      <Dropdown
        text={selectedText}
        isBordered={true}
        data-testid="days-of-month"
      >
        {items}
      </Dropdown>
    </FormItem>
  );
}

export default DaysOfMonthSelect;
