/**
 * Please do not use this component directly, instead use the Report component (Report/index.tsx)
 */
import * as React from 'react';
import { Button } from '@eds/button';
import { Flex } from '@eds/flex';
import { ShiftIcon } from '@eds/icon';
import { css } from '@emotion/css';
import moment from 'moment';
import { Heading, LaunchIcon } from 'elmo-elements';
import { map } from 'lodash';
import { Dispatch } from 'redux';
import cn from 'classnames';
import { cssOverrides } from 'src/features/ui';
import { DeprecationContext, DeprecationIcon } from 'src/features/report-builder';

import { ReportDetails } from '../../../redux';
import { showDrilldownModal } from '../../../actions/drilldownModal';
import { ColumnMeta } from '../../../types/columnMetaModel';
import { OrderBy } from '../../../types/orderByModel';
import { SavedQuery } from '../../../types/savedQueryModel';
import { ReportTable } from '../../../types/reportTableModel';
import { ReportRow } from '../../../types/reportRowModel';
import { ReportCell } from '../../../types/reportCellModel';
import { ORDER_ASC, ORDER_DESC } from '../../../constants';
import { getColumnMetaWithLabels } from '../../../services/columnMetaService';
import { orderByEqualsColumnMeta, UserCapabilitiesType } from '../../../services/reportService';
import { reportSharedWithMe } from '../../../services/shareReportService';
import { ReorderFunction, ReportSortFunction } from '../../../containers/NewReportBuilder/Report';
import GenerateReportButton from '../../../containers/NewReportBuilder/GenerateReportButton';
import ShareButton from '../../../containers/NewReportBuilder/ShareButton';
import { withReportContext } from './ReportContextConsumer';
import { View } from '../../../types/viewModel';
import { Query } from '../../../types/queryModel';
import ManageColumnsModal from '../ManageColumnsModal';
import { SortByIcon } from '../Icons/SortByIcon';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { ArrowUpIcon } from '../Icons/ArrowUpIcon';
import { ReportHeader } from '../../../types/reportHeaderModel';
import { isValidUuid } from '../../../utils';
import ElmoDivider from '../ElmoDivider';

import 'font-awesome/css/font-awesome.min.css';
import './style.css';

export type ReportProps = {
  sort: ReportSortFunction;
  orderBys: OrderBy[];
  reorder: ReorderFunction;
  removeAndReorderColumns: (removedColumns: ColumnMeta[], reorderedColumns: ColumnMeta[]) => void;
  reportDetails: ReportDetails;
  loadedQuery?: SavedQuery;
  reportTitle: string;
  readOnly: boolean;
  isDashboardPage?: boolean;
  printView?: boolean;
  renderPagination: () => any;
  dispatch: Dispatch<any>;

  // From ReportContext
  columnMeta: ColumnMeta[];
  // From ReportContext
  report: ReportTable;
  // From ReportContext
  views: View[];
  // From ReportContext
  query: Query;
  userCapabilities: UserCapabilitiesType;
};

export type ReportStateType = {
  isManageColumnsModalOpened: boolean;
};

class ReportComponent extends React.Component<ReportProps, ReportStateType> {

  constructor(props: ReportProps) {
    super(props);
    this.state = {
      isManageColumnsModalOpened: false,
    };
  }

  static contextType = DeprecationContext;

  render() {
    const { report: { rows, jobId, links }} = this.props;
    const isNoData = rows.length === 0 && jobId && links;

    return (
      <div className={cn('new-report-container', { 'no-data-container': isNoData })}>
        {!this.props.isDashboardPage &&
          <div className="report-header-container">
            <Heading htmlTag="h4" data-testid="report-results-title">
              {!!this.props.reportTitle && this.props.reportTitle}
            </Heading>
            {!this.props.readOnly && this.renderHeaderButtons()}
          </div>
        }
        {this.renderReport()}
        {this.props.report.rows.length > 0 && !this.props.printView &&
          <div className="row no-gutters">
            <div className="col">
              {this.props.renderPagination()}
            </div>
          </div>
        }
        {!this.props.readOnly && !!this.props.columnMeta.length && this.state.isManageColumnsModalOpened &&
        <ManageColumnsModal
          showModal={this.state.isManageColumnsModalOpened}
          toggle={this.toggleManageColumnsModal}
          manageColumns={this.manageReportColumns}
          columnMetas={this.props.columnMeta}
          userCapabilities={this.props.userCapabilities}
        />
        }
      </div>
    );
  }

  renderHeaderButtons() {
    const { loadedQuery, readOnly, report: {jobId}} = this.props;
    const showShareButton = !readOnly && (!loadedQuery || (!!loadedQuery && !reportSharedWithMe(loadedQuery) &&
      loadedQuery.permissions && loadedQuery.permissions.canAddToDashboard));
    const { hasDeprecatedColumns } = this.context;

    return jobId ? (
      <div className="report-header-buttons" data-tracking="report-header-controls">
        {!readOnly &&
          <Flex className={css(cssOverrides.button('ghost'))} data-tracking="manage-columns">
            <Button
              icon={hasDeprecatedColumns ? DeprecationIcon : ShiftIcon}
              label="Manage columns"
              onClick={this.toggleManageColumnsModal}
              size="small"
              tone="ghost"
            />
          </Flex>
        }
        {showShareButton &&
          <>
            <ElmoDivider ariaOrientation="vertical" height="24" />
            <ShareButton loadedQuery={loadedQuery} />
          </>
        }
      </div>
    ) : null;
  }

  toggleManageColumnsModal = () => {
    this.setState({
      isManageColumnsModalOpened: !this.state.isManageColumnsModalOpened,
    });
  }

  manageReportColumns = (reorderedColumns: ColumnMeta[], removedColumns?: ColumnMeta[]) => {
    if (removedColumns) {
      this.props.removeAndReorderColumns(removedColumns, reorderedColumns);
      return;
    }

    this.props.reorder(reorderedColumns);
  }

  getListStyle = () => ({
    background: 'none',
    display: 'flex',
    padding: 0,
    overflow: 'visible',
  })

  sort = (columnMeta: ColumnMeta) => () => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    this.props.sort(columnMeta, this.props.orderBys, reportId);
  }

  renderHeader(columnMetas: ColumnMeta[], headers: Array<ReportHeader>): any {
    // headers data is not available during initial report creation
    const useColumnMeta = headers.length === 0;
    const columnMetasCopy = [...columnMetas];

    return (
      <tr
        style={this.getListStyle()}
        className={'header-row'}
        role="rowheader"
        data-testid="header-row"
      >
        {
          useColumnMeta && columnMetas.map((cm: ColumnMeta, index: number) => {
            return (
              <th
                key={index}
                className={'header-col'}
                data-testid={'header-col-' + index}
                onClick={this.sort(cm)}
                data-tracking="report-table-column-header"
              >
                <span
                  className="column-title"
                  role="columnheader"
                  data-testid={'column-title-' + index}
                >
                  {cm.displayLabel}
                </span>
                {this.renderSortStatus(this.props.orderBys, cm)}
              </th>
            );
          })
        }
        {
          !useColumnMeta && headers.map((header, index: number) => {
            const isCustomColumn = isValidUuid(header.dimensionName);
            let cm: ColumnMeta | undefined;

            /**
             * NOTE: Due to the addition of deprecated fields, we can no longer rely that headers[index] corresponds
             * to columnMeta[index]. To solve it, we could just use the next non-deprecated columnMeta item.
             */
            const getNonDeprecatedColumnMeta = (): ColumnMeta | undefined => {
              const potentiallyDeprecatedField = columnMetasCopy.shift();

              return potentiallyDeprecatedField?.dimension?.deprecated
                ? getNonDeprecatedColumnMeta()
                : potentiallyDeprecatedField;
            };

            if (!isCustomColumn) {
              // assuming that the headers are correctly sorted
              cm = getNonDeprecatedColumnMeta();
            }

            const onClick = isCustomColumn ? undefined : cm && this.sort(cm);
            
            return (
              <th
                key={index}
                className={cn('header-col', { 'no-pointer': !onClick })}
                data-testid={'header-col-' + index}
                onClick={onClick}
                data-tracking="report-table-column-header"
              >
                <span
                  className="column-title"
                  role="columnheader"
                  data-testid={'column-title-' + index}
                >
                  {isCustomColumn && header.displayName}
                  {cm && (cm.alias || cm.displayLabel)}
                </span>
                {cm && this.renderSortStatus(this.props.orderBys, cm)}
              </th>
            );
          })
        }
      </tr>
    );
  }

  renderReport() {
    const { columnMeta, report } = this.props;
    const columnMetaWithLabels: ColumnMeta[] = getColumnMetaWithLabels(columnMeta, report.headers);

    if (columnMetaWithLabels.length === 0) {
      return (
        <React.Fragment>
          <div className="no-result__container">
            <div className="no-result__body">
              <h3 className="no-result__heading">Please select views/fields</h3>
              <p className="no-result__message">To start building report</p>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="new-report-result">
        <table>
          <thead>
            {this.renderHeader(columnMetaWithLabels, report.headers)}
          </thead>
          <tbody>
            {this.props.report.rows.length > 0 ?
              map(this.props.report.rows, (row: ReportRow, key: number) => {
                return this.renderRow(row, key);
              }) :
              <tr>
                {this.props.report.jobId && this.props.report.links &&
                  <td className="no-data-found-cell">
                    <div className="no-data-found">
                      <span className="header">No data found </span>
                      <span className="description">No data found for the selected fields</span>
                    </div>
                  </td>
                }
              </tr>
            }
            {this.props.reportDetails && (this.props.reportDetails.numResults === 0) &&
              <div className="no-result">
                There is no data for this view.
              </div>
            }
          </tbody>
        </table>
      </div>
    );
  }

  renderSortStatus(orderBys: OrderBy[], columnMeta: ColumnMeta) {
    let orderBy = orderBys.find((ob: OrderBy) => {
      return orderByEqualsColumnMeta(ob, columnMeta);
    });

    return (
      <span
        className="order"
        role="button"
        aria-roledescription="Sorts the Column"
      >
        {orderBy && orderBy.direction === ORDER_ASC && <ArrowUpIcon />}
        {orderBy && orderBy.direction === ORDER_DESC && <ArrowDownIcon />}
        {!orderBy && <SortByIcon />}
      </span>
    );
  }

  renderRow(row: ReportRow, rowIndex: number) {
    return (
      <tr className="result-row" key={rowIndex} role="row">
        {map(row.cells, (cell: ReportCell, columnIndex: number) => {
            return this.renderCell(cell, rowIndex, columnIndex);
        })}
      </tr>
    );
  }

  renderCell = (cell: ReportCell, rowIndex: number, columnIndex: number) => {
    const measure = (this.props.columnMeta[columnIndex]) ? this.props.columnMeta[columnIndex].measure : null;
    const cellIsEmpty = (cell.data.value === null || cell.data.value === '');
    return (
      <th
        key={columnIndex}
        className="result-col"
        role="cell"
      >
        {cell.data.value}
        {this.canDrillDown(measure) && !cellIsEmpty &&
          <div
            className="drilldown-icon"
            onClick={() => {
              this.props.dispatch(showDrilldownModal({
                showDrilldownModal: true,
                currentDrilldownCol: columnIndex,
                currentDrilldownRow: rowIndex,
                currentDrilldownValue: Number(cell.data.value),
                columnMeta: this.props.columnMeta,
                report: this.props.report,
                views: this.props.views,
                query: this.props.query,
                reportTitle: this.props.reportTitle,
              }));
            }}
          >
            <LaunchIcon
              title="Drill down into this aggregate"
              aria-label="Drill down into this aggregate"
              data-testid="drilldown-icon"
            />
          </div>
        }
      </th>
    );
  }

  canDrillDown(measure: any) {
    return measure && measure.type && measure.hasDrilldown;
  }

  renderCachedTime = () => {

    if (!this.hasReport() && !this.props.report.cachedDate) {
      return null;
    }

    let message = <>Up to date</>;
    if (this.props.report.cachedDate) {
      // If we want consistency we can switch to format 'D-M-YYYY, h:mma'
      const cachedDate = moment(this.props.report.cachedDate).fromNow();
      message = (
        <>
          Cached at {cachedDate}
          &nbsp;
          <GenerateReportButton noCache={true} className="refresh-report-btn btn btn-outline-dark">
            <i className="fa fa-refresh" />
          </GenerateReportButton>
        </>
      );
    }

    return (
      <span className={'cached-time'}>
        {message}
      </span>
    );
  }

  hasReport(): boolean {
    return this.props.report && this.props.report.rows.length > 0;
  }

}

export default withReportContext(ReportComponent);
