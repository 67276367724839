import React, { useState } from 'react';
import { Box } from '@eds/box';
import { ControlsProps } from '@eds/modal';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { Toast, toastMessage } from '@eds/toast';

import { AlertModal } from 'src/features/ui';

import { useDeleteDashboardMutation } from './delete-dashboard.queries';

type DeleteDashboardProps = {
  id: string;
  name: string;
  onClose: () => void;
  unsharingMode: boolean;
};

export const DeleteDashboard = ({ id, name, onClose, unsharingMode }: DeleteDashboardProps) => {
  const [errorMessage, setErrorMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { mutate } = useDeleteDashboardMutation({
    onError: () =>
      setErrorMessage(toastMessage('An issue occurred during the dashboard deletion, please try again later')),
    onSuccess: () => setSuccessMessage(toastMessage('Dashboard deleted')),
  });

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: {
      label: `Yes, ${unsharingMode ? 'proceed' : 'delete'}`,
      onClick: () => {
        mutate({ id, unsharingMode });
        // NOTE: Optimistic update - close the modal immediately without waiting for response
        onClose();
      },
      tone: 'critical',
    },
  };

  return (
    <Box>
      <AlertModal
        controls={controls}
        description={
          <Flex flexDirection="column" gap="small">
            {unsharingMode ? (
              <Text overflowY="auto" color="neutralSubtle" lineHeight="loose">
                {`This action will remove your access to ${
                  name
                } by unsharing it. The dashboard itself will not be deleted and will remain accessible to other users.`}
              </Text>
            ) : (
              <Flex flexDirection="column" gap="small">
                <Text overflowY="auto" color="neutralSubtle" lineHeight="loose">
                  {`This action will delete ${name} permanently.`}
                </Text>
              </Flex>
            )}
          </Flex>
        }
        isOpen={!!id}
        onClose={onClose}
        title="Delete dashboard?"
      />
      {errorMessage && <Toast message={errorMessage} tone="critical" />}
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
