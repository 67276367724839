import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ReportData, reportsKeys } from 'src/features/reports';

type ReportRelations = {
  dashboards: {
    dashboardId: string;
    title: string;
  }[];
  schedules: {
    id: string;
    scheduleName: string;
  }[];
};

const deleteReport = async (reportId: string) => {
  const response = await axios.delete(`api/queries/${reportId}`);
  return response.data;
};

const fetchReportRelations = async (reportId: string) => {
  const response = await axios.get<ReportRelations>(`/api/queries/${reportId}/relations `);
  return response.data;
};

const removeCurrentUserFromSharedReport = async (reportId: string) => {
  const response = await axios.delete(`/api/share-report/${reportId}`);
  return response.data;
};

export const useDeleteReportMutation = (
  reportIsSharedWithCurrentUser: boolean,
  callback: { onSettled: () => void; onSuccess: () => void }
) => {
  const queryClient = useQueryClient();
  const query = queryClient.getQueryCache().findAll(reportsKeys.lists(), { type: 'active' }).shift();

  return useMutation<unknown, unknown, string>({
    mutationFn: reportId => {
      if (query) {
        // NOTE: If there's any existing request to fetch the list, it must be cancelled to avoid race condition
        queryClient.cancelQueries({ queryKey: query.queryKey });
        // NOTE: Immediately remove the report being deleted as we are implementing optimistic update
        queryClient.setQueriesData<{ count: number; queries: ReportData[] }>(query.queryKey, previous => {
          const data = {
            count: (previous?.count ?? 1) - 1,
            queries: previous?.queries.filter(({ id }) => id !== reportId) || [],
          };
          return data;
        });
      }

      return reportIsSharedWithCurrentUser ? removeCurrentUserFromSharedReport(reportId) : deleteReport(reportId);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reportsKeys.lists() });
      callback.onSettled();
    },
    onSuccess: () => callback.onSuccess(),
  });
};

export const useReportRelationsQuery = (reportId: string, reportIsSharedWithCurrentUser: boolean) =>
  useQuery({
    enabled: !!reportId && reportIsSharedWithCurrentUser === false,
    queryKey: reportsKeys.relations(reportId),
    queryFn: () => fetchReportRelations(reportId),
  });

export const useReportDetailsQueryData = (reportId: string): ReportData | undefined => {
  const queryClient = useQueryClient();
  const query = queryClient.getQueryCache().findAll(reportsKeys.lists(), { type: 'active' }).shift();

  if (!query) return;

  const queryData = queryClient.getQueryData<{ count: number; queries: ReportData[] }>(query.queryKey);
  return queryData ? queryData.queries.find(report => reportId === report.id) : undefined;
};
