import * as React from 'react';
import { ChangeEvent } from 'react';
import { Box } from '@eds/box';
import { Field } from '@eds/field';
import { SelectInput } from '@eds/select-input';
import { TextInput } from '@eds/text-input';

import { ViewJoinAndDimensionOrMeasure } from '../../../redux';
import { Filter } from '../../../types/filterModel';
import numberValidationSchema from './validation';

import { usePopulateOptions } from './hooks';

interface FilterTextSelectContainerProps {
  displayFilter: Filter;
  enableNumberValidation: boolean;
  filterIndex: number;
  filterOption: ViewJoinAndDimensionOrMeasure;
  isDisabled: boolean;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
}

const FilterTextSelectContainer = (props: FilterTextSelectContainerProps) => {
  const { displayFilter, enableNumberValidation, filterIndex, filterOption, isDisabled, saveDisplayFilter } = props;
  
  const handleInputChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const filter = { ...displayFilter };
    filter.value = currentTarget.value;
    saveDisplayFilter(filter, filterIndex, false);
  };

  const handleInputBlur = () => {
    const filter = { ...displayFilter };

    if (enableNumberValidation && !numberValidationSchema.isValidSync(filter.value)) {
      filter.value = '';
    }
    
    saveDisplayFilter(filter, filterIndex);
  };

  const selectFilter = (select: any) => {
    const filter = { ...displayFilter };
    filter.value = select;
    saveDisplayFilter(filter, filterIndex);
  };

  const populateOptions = usePopulateOptions();
  const options: any = populateOptions(filterOption);
  const hasTranslations = options.length > 0;

  return (
    <>
      <Box flexBasis="100%" display={!hasTranslations ? 'none' : undefined}>
        <Field label="Value" keepSpaceForMessage={false}>
          <SelectInput
            disabled={isDisabled}
            isClearable={false}
            isMulti={true}
            items={options}
            onChangeMulti={selectFilter}
            size="small"
            value={displayFilter.value as any}
          />
        </Field>
      </Box>
      <Box flexBasis="100%" display={hasTranslations ? 'none' : undefined}>
        <Field label="Value" keepSpaceForMessage={false}>
          <TextInput
            disabled={isDisabled}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            placeholder={enableNumberValidation ? 'Enter a number' : 'Enter a value'}
            size="small"
            type="text"
            value={displayFilter.value as string}
          />
        </Field>
      </Box>
    </>
  );
};

export default FilterTextSelectContainer;