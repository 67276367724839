import { Join } from './joinModel';
import { Dimension } from './dimensionModel';
import { Column } from './columnModel';
import { View } from './viewModel';

export class GroupBy extends Column {
  view: View;
  join?: Join;
  dimension: Dimension;

  /**
   *
   * This is a static function so that it can be called independently without GroupBy objects having to have it
   */
  public static groupByIsEqual(a: GroupBy, b: GroupBy): boolean {
    let isEqual = false;

    if ((a.join && !b.join) || (!a.join && b.join)) {
      return false;
    }

    if (a.join && b.join) {
      isEqual = Join.equals(a.join, b.join) && (a.dimension.name === b.dimension.name);
    } else if (a.view && b.view) {
      isEqual = (a.view.name === b.view.name) && (a.dimension.name === b.dimension.name);
    }

    return isEqual;
  }

  public static arrayEquals(a1: GroupBy[], a2: GroupBy[]): boolean {
    if (a1.length !== a2.length) {
      return false;
    }

    for (let i: number = 0; i < a1.length; i++) {
      if (!GroupBy.groupByIsEqual(a1[i], a2[i])) {
        return false;
      }
    }

    return true;
  }
}