import * as React from 'react';
import { DataTable, Loader, Link, Tooltip } from 'elmo-elements';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import { getPermissions } from 'src/features/utils';

import { FormsListType, FormType } from '../../../types/forms';
import { getIsSelectFormAlertClosed, mapPaginationDataToRequestParams, IS_SELECT_FORM_ALERT_CLOSED } from './helpers';
import { getForms, importForms } from '../../../services/formsApiService';
import { AppState } from '../../../redux';
import { getViewsAndStore } from '../../../actions/report';
import { NotificationService } from '../../../services/notificationService';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../../constants';
import ElmoButton from '../ElmoButton';
import ElmoModal from '../ElmoModal';
import ElmoAlert from '../ElmoAlert';
import ElmoSearch from '../ElmoSearch';
import { SecureFormIcon } from '../Icons/SecureFormIcon';
import ElmoCheckbox from '../ElmoCheckbox';
import ElmoDataTable from '../ElmoDataTable';

import './style.scss';

export type SelectFormsModalProps = {
  isOpened: boolean;
  toggleModal: () => void;
};

const t1Cols =   [
  {
    title: '',
    width: '45px',
  },
  {
    title: 'Form Name',
    textAlign: 'left'
  },
  {
    title: 'Category',
    textAlign: 'left'
  }
];

const MAX_SELECTED_FORMS_NUMBER = 20;

export type PaginationComponentDataType = {
  currentPage: number;
  pageSize: number;
  offset: number; // number of pages
  totalItems: number;
};

export const defaultFormsPagination: PaginationComponentDataType = {
  currentPage: 1,
  pageSize: 10,
  offset: 0,
  totalItems: 0,
};

function SelectFormsModal({
  isOpened,
  toggleModal,
}: SelectFormsModalProps) {
  const dispatch = useDispatch();
  const selectedModule = useSelector((state: AppState) => state.module);
  const notificationService: NotificationService | null = useSelector((state: AppState) => state.notificationService);
  const [searchText, setSearchText] = React.useState('');
  const [currentForms, setCurrentForms] = React.useState<FormsListType>([]);
  const [selectedForms, setSelectedForms] = React.useState<FormsListType>([]);
  const [isFormsListLoading, setIsFormsListLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<PaginationComponentDataType>(defaultFormsPagination);
  const [isDisplayAllItems, setIsDisplayAllItems] = React.useState<boolean>(true);
  const [isAlertClosed, setIsAlertClosed] = React.useState<boolean>(getIsSelectFormAlertClosed());
  const [isFormsImporting, setIsFormsImporting] = React.useState<boolean>(false);

  const closeModal = () => {
    toggleModal();
  };

  const closeAlert = () => {
    sessionStorage.setItem(IS_SELECT_FORM_ALERT_CLOSED, 'true');
    setIsAlertClosed(true);
  };

  const getFormsData = async (text?: string | null, paginationData?: PaginationComponentDataType) => {
    setIsFormsListLoading(true);
    const newPagination = paginationData ? paginationData : pagination;
    const searchParam = text === null || !!text ? text : searchText;
    const formsData = await getForms({ text: searchParam, ...mapPaginationDataToRequestParams(newPagination) });
    setIsFormsListLoading(false);
    setCurrentForms(formsData.forms);
    setPagination({ ...newPagination, totalItems: formsData.total });
  };

  const onSearchChange = (value: string) => {
    setSearchText(value);
    if (!value && value !== searchText) {
      getFormsData(null, { ...pagination, offset: 0, currentPage: 1 });
    }
  };

  const onSearchSubmit = async () => {
    if (isFormsListLoading) {
      return;
    }
    await getFormsData(searchText, { ...pagination, offset: 0, currentPage: 1 });
  };

  const onPageChange = (pageNumber: number, startItem: number) => {
    const newPaginationData = { ...pagination, offset: startItem - 1, currentPage: pageNumber };
    setPagination(newPaginationData);
    getFormsData(null, newPaginationData);
  };

  const onPageSizeChange = (pageSize: number) => {
    const newPaginationData = { ...pagination, pageSize, offset: 0, currentPage: 1 };
    setPagination(newPaginationData);
    getFormsData(null, newPaginationData);
  };

  const onFormSelect = (form: FormType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedForms([...selectedForms, form]);
    }

    if (!e.target.checked) {
      setSelectedForms(selectedForms.filter((selectedForm) => selectedForm.id !== form.id));
    }

    setCurrentForms(currentForms.map(currentForm => ({
      ...currentForm, isChecked: e.target.checked,
    })));
  };

  const onFormsImportClick = async () => {
    if (isFormsImporting) {
      return;
    }
    const formIds = selectedForms.map((selectedForm) => selectedForm.id);

    try {
      setIsFormsImporting(true);
      await importForms({ module: selectedModule.name, formIds });
      setIsFormsImporting(false);
      if (notificationService) {
        notificationService.addNotification(NOTIFICATION_SUCCESS, 'Forms imported successfully');
      }
      closeModal();
      dispatch(getViewsAndStore(true));
    } catch (e) {
      if (notificationService) {
        notificationService.addNotification(NOTIFICATION_ERROR, 'Forms import error');
      }
    }
  };

  const getPrimaryButton = () => (
    <>
      <ElmoButton type="text" onClick={closeModal}>Cancel</ElmoButton>
      <ElmoButton
        type="primary"
        onClick={onFormsImportClick}
        isLoading={isFormsImporting}
        isDisabled={isFormsImporting}
      >
        Import
      </ElmoButton>
    </>
  );

  const isSelectedFormsLimitReached = selectedForms.length === MAX_SELECTED_FORMS_NUMBER;

  const canViewSecuredForm = getPermissions().configuration.canViewSecuredForm;

  const renderRow = () => {
    const targetForms = isDisplayAllItems ? currentForms : selectedForms;
    return targetForms.map(form => {
      const isFormSelected = !!selectedForms.find((selectedForm) => selectedForm.id === form.id);
      const getRowCheckboxComponent = () => (
        <DataTable.Td textAlign="center">
          <ElmoCheckbox
            id={form.id.toString()}
            isChecked={isFormSelected}
            isDisabled={
              form.isImported ||
              (isSelectedFormsLimitReached && !isFormSelected) ||
              (!canViewSecuredForm && form.isSecured)
            }
            onChange={onFormSelect(form)}
          />
        </DataTable.Td>
      );

      const getRowCheckBoxWithTooltip = (isSelectedFormsLimitReached && !isFormSelected) ? (
        <Tooltip title="You've reached maximum limit of form selection" placement="right">
          {getRowCheckboxComponent()}
        </Tooltip>
        ) : getRowCheckboxComponent();

      return (
        <DataTable.Tr
          key={form.id}
          className={form.isImported || (!canViewSecuredForm && form.isSecured) ? 'importedForm' : 'notImportedForm'}
        >
          {getRowCheckBoxWithTooltip}
          <DataTable.Td>
            <span>{form.title}</span>
            {
              form.isSecured && 
              <Tooltip className="secure-form-tooltip" title="This is a secure form" placement="bottom">
                <SecureFormIcon className="secure-form-icon" />
              </Tooltip>
            }
          </DataTable.Td>
          <DataTable.Td>{form.category}</DataTable.Td>
        </DataTable.Tr>
      );
    });
  };

  const onToggleViewMode = () => {
    setIsDisplayAllItems(!isDisplayAllItems);
  };

  React.useEffect(() => { getFormsData(); }, []);

  return (
    <ElmoModal
      id="selectFormModal"
      className="selectFormModal"
      type="x-large"
      title="Select Form"
      ariaLabel="Select Form Modal"
      isOpened={isOpened}
      closeModal={closeModal}
      primaryButton={getPrimaryButton()}
    >
      {!isAlertClosed &&
      <ElmoAlert
        isCloseable={false}
        message="Once a form has been selected from the list below, the form view
        and fields will be available for you to build a report"
      >
        <ElmoButton onClick={closeAlert}>OK, got it</ElmoButton>
      </ElmoAlert>}
      <div className="panelContainer">
        <div>{`${selectedForms.length}/${MAX_SELECTED_FORMS_NUMBER} Forms Selected`}</div>
        {selectedForms.length > 0 &&
          <div className="btn-view-wrapper">
            <Link onClick={onToggleViewMode}>
              {isDisplayAllItems ? 'View Selected' : 'View All'}
            </Link>
          </div>
        }
        {isDisplayAllItems && 
        <ElmoSearch
          className="search"
          isVisible={true}
          placeholder={'Search by name or category...'}
          onChange={onSearchChange}
          value={searchText}
          onSubmit={onSearchSubmit}
          isSubmitEnabledAlways={true}
        />}
      </div>
      {isFormsListLoading && <Loader type="spinner" />}
      {currentForms.length > 0 && !isFormsListLoading &&
        <ElmoDataTable
          id="t1"
          isFullWidth={true}
          className={classnames({
            'elmo-alert--is-closed': isAlertClosed
          })}
        >
          <DataTable.Header columns={t1Cols} />
          <DataTable.Body>{renderRow()}</DataTable.Body>
          {isDisplayAllItems && 
          <DataTable.Pagination
            pageSize={pagination.pageSize}
            currentPage={pagination.currentPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            totalResults={pagination.totalItems}
          />}
        </ElmoDataTable>
      }
    </ElmoModal>
  );
}

export default SelectFormsModal;
