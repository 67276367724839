import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const ArrowUpIcon = ({ className, width = 16, height = 18 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M8.00485 3.35693L12.4763 7.82834L11.5334 8.77115L8.67152 5.90922V13.6331H7.33818L7.33818 5.90922L4.47626 
        8.77115L3.53345 7.82834L8.00485 3.35693Z" 
      />
    </svg>
  );
};
