import * as React from 'react';
import { Button } from '@eds/button';
import { Flex } from '@eds/flex';
import { InformativeIcon } from '@eds/icon';
import { Text } from '@eds/text';
import { css } from '@emotion/css';
import { AppState, JoinAndDimensions, JoinAndMeasures } from '../../../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Select } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import * as Countries from 'i18n-iso-countries';

import { cssOverrides } from 'src/features/ui';
import { ViewsInfo } from 'src/features/report-builder';

import ModuleSelect from '../ModuleSelect';
import { Module } from '../../../types/moduleModel';
import ViewSelect from '../ViewSelect';
import { View } from '../../../types/viewModel';
import { Join } from '../../../types/joinModel';
import {
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES,
  MODULE_TYPES,
} from '../../../constants';
import JoinDimensionSelect from '../JoinDimensionSelect';
import ResetReportButton from '../ResetReportButton';
import GenerateReportButton from '../GenerateReportButton';
import DimensionSelect from '../DimensionSelect';
import JoinMeasureSelect from '../JoinMeasureSelect';
import MeasureSelect from '../MeasureSelect';
import SuggestedReports from '../SuggestedReports';
import { setSuggestionsOpen } from '../../../actions/suggestions';
import { AuthService } from '../../../services/authService';
import { UserCapabilitiesType } from '../../../services/reportService';
import { storeSelectedCountries } from '../../../actions/savedQueries';
import { SavedQuery } from 'src/types/savedQueryModel';

import './ReportWizard.css';

Countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

interface ReportWizardProps {
  dispatch: Dispatch<any>;
  module: Module;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  view: View;
  views: View[];
  checkedJoinDimensions: JoinAndDimensions[];
  checkedJoinMeasures: JoinAndMeasures[];
  onResetClicked: () => void;
  onGenerateSuccess: () => void;
  authService: AuthService;
  toggleSelectFormModal: () => void;
  userCapabilities: UserCapabilitiesType;
  savedQuery: SavedQuery;
  reportId?: string;
}

const mapStateToProps = (state: AppState) => ({
  module: state.module,
  dataType: state.dataType,
  view: state.view,
  views: state.views,
  checkedJoinDimensions: state.joinDimensions,
  checkedJoinMeasures: state.joinMeasures,
  savedQuery: state.savedQuery,
  authService: state.authService,
});

interface ReportWizardState {
  selectedJoin: Join | null;
  selectedView: View | null;
  stepIndex: number;
  demoMode: boolean;
  isViewsInfoOpen: boolean;
}

class ReportWizardComponent extends React.Component<ReportWizardProps, ReportWizardState> {
  protected joins: Join[];

  constructor(props: ReportWizardProps) {
    super(props);

    this.state = {
      selectedJoin: null,
      selectedView: null,
      stepIndex: 0,
      demoMode: false,
      isViewsInfoOpen: false,
    };
  }

  componentDidUpdate(prevProps: ReportWizardProps) {
    // only update the joins to render when props have been updated, for efficiency
    if (this.props.view.name !== prevProps.view.name) {
      this.onBaseViewSelect(this.props.view);
    }
    if (this.props.module.name && !prevProps.module.name && this.props.module.name.length > 0 && this.state.demoMode) {
      this.props.dispatch(setSuggestionsOpen(false));
    }
  }

  onBaseViewSelect = (selectedView: View) => {
    this.setState({
      selectedView: selectedView,
      selectedJoin: null
    });
  }

  onJoinSelect = (selectedJoin: Join) => {
    this.setState({
      selectedView: null,
      selectedJoin: selectedJoin
    });
  }

  onResetClicked = () => {
    this.props.dispatch(setSuggestionsOpen(false));
    this.props.onResetClicked();
  }

  renderColumnSelect() {
    if (this.props.dataType === DATA_TYPE_DIMENSIONS && this.state.selectedJoin) {
      return (
        <JoinDimensionSelect
          join={this.state.selectedJoin}
        />
      );
    } else if (this.props.dataType === DATA_TYPE_DIMENSIONS && this.state.selectedView) {
      return (
        <DimensionSelect />
      );
    } else if (this.props.dataType === DATA_TYPE_MEASURES && this.state.selectedJoin) {
      return (
        <JoinMeasureSelect
          join={this.state.selectedJoin}
        />
      );
    } else if (this.props.dataType === DATA_TYPE_MEASURES && this.state.selectedView) {
      return (
        <MeasureSelect />
      );
    }

    return null;
  }

  hasSelectedBaseView() {
    return this.props.view.name;
  }

  getCountriesOptions = (countries: Array<string>): Array<SelectPropsOption> => {
    return countries.map((countryCode: string) => {
      const countryName = Countries.getName(countryCode, 'en');
      return {
        label: countryName || countryCode,
        value: countryCode,
      };
    });
  }

  onCountrySelectChange = (value: SelectPropsOption[]) => {
    const countriesValues = value.map((option: SelectPropsOption) => option.value);
    this.props.dispatch(storeSelectedCountries(countriesValues));
  }

  render() {
    const { userCapabilities, reportId, module } = this.props;
    const { selectedJoin, isViewsInfoOpen } = this.state;
    let showColumnSelect = this.hasSelectedBaseView();
    let tablesWidthClass: string = 'col-sm-12 col-md';
    let fieldsWidthClass: string = showColumnSelect ? 'col-sm-12 col-md' : 'd-none';
    const supportedCountries = this.props.authService.getSupportedCountries();
    const clientCountries = this.props.authService.getClientCountries();

    return (
      <div className="new-report-wizard-container" role="form">
        <div className="wizard-top">
          {userCapabilities.report.canSelectCountry && !reportId &&
            <div className="module-select-container country-select-container">
              <Select
                placeholder="Select Country"
                options={this.getCountriesOptions(supportedCountries)}
                onChange={this.onCountrySelectChange}
                isMulti={true}
                isDisabled={!!module.id}
                defaultValue={this.getCountriesOptions(clientCountries)}
              />
              {module && module.type === MODULE_TYPES.tms &&
                <span className="country-select-message">Countries will only be applied for redshift modules</span>
              }
            </div>
          }
          <div className="module-select-suggested-reports-container">
            <ModuleSelect reportId={this.props.reportId} />
            <SuggestedReports />
          </div>
        </div>
        <div className="row no-gutters wizard-bottom">
          <div className={'wizard-bottom-left ' + tablesWidthClass}>
            {this.props.views.length > 0 && this.props.module.name &&
              <>
                <Flex
                  alignItems="center"
                  className={css(cssOverrides.button('ghost'))}
                  gap="small"
                  height="2.25rem"
                  justifyContent="space-between"
                  paddingBottom="xxsmall"
                >
                  <Text aria-level={4} fontSize="small" fontWeight="medium" role="heading">Select View</Text>
                  <Button
                    iconOnly
                    icon={InformativeIcon}
                    label="Open views info dialog"
                    onClick={() => this.setState({ isViewsInfoOpen: true })}
                    size="small"
                    tone="ghost"
                  />
                </Flex>
                <div className="scroll">
                  <ViewSelect
                    onBaseViewSelect={this.onBaseViewSelect}
                    onJoinSelect={this.onJoinSelect}
                    toggleSelectFormModal={this.props.toggleSelectFormModal}
                    selectedJoin={selectedJoin}
                  />
                </div>
              </>
            }
          </div>
          <div className={'wizard-bottom-right ' + fieldsWidthClass}>
            <Flex alignItems="center" minHeight="2.25rem" paddingBottom="xxsmall">
              <Text aria-level={4} fontSize="small" fontWeight="medium" role="heading">Select Fields</Text>
            </Flex>
            {this.props.view.name &&
              this.renderColumnSelect()
            }
          </div>
        </div>
        <div className="wizzard-bottom-buttons">
          <div className="reset-generate-btn-group" data-tracking="report-builder-bottom-controls">
            <ResetReportButton
              className="clearAllButton"
              onClick={this.onResetClicked}
            />
            <GenerateReportButton
              onGenerateSuccess={this.props.onGenerateSuccess}
              onGenerateStart={this.generateStartCallback}
            />
          </div>
        </div>
        <ViewsInfo isOpen={isViewsInfoOpen} onClose={() => this.setState({ isViewsInfoOpen: false })} />
      </div>
    );
  }

  activateGuide = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.dispatch(setSuggestionsOpen(false));
    this.setState({ demoMode: true });
  }

  generateStartCallback = () => {
    // Stop the guide when Generate button pressed
    this.setState({ demoMode: false, stepIndex: 0 });
  }

  endTour = (index: number, size: number) => () => {
    // Last step so do not restart the guide
    if (index !== size - 1) {
      this.setState({ demoMode: true });
    }
  }
}

const ReportWizard = connect(
  mapStateToProps)(ReportWizardComponent);

export default ReportWizard;
