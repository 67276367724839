import * as React from 'react';
import { AppState, JoinAndDimensions, JoinAndMeasures } from '../../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ModuleSelect from '../ModuleSelect';
import { Module } from '../../types/moduleModel';
import DataTypeSelect from '../DataTypeSelect';
import ViewSelect from '../ViewSelect';
import { View } from '../../types/viewModel';
import './ReportWizard.css';
import { Join } from '../../types/joinModel';
import {
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES,
} from '../../constants';
import JoinDimensionSelect from '../JoinDimensionSelect';
import ResetReportButton from '../ResetReportButton';
import GenerateReportButton from '../GenerateReportButton';
import DimensionSelect from '../DimensionSelect';
import JoinMeasureSelect from '../JoinMeasureSelect';
import MeasureSelect from '../MeasureSelect';
import SuggestedReports from '../SuggestedReports';
import { setSuggestionsOpen } from '../../actions/suggestions';
import ReactJoyride, { ACTIONS, STATUS, EVENTS, CallBackProps as ReactJoyrideCallBackProps } from 'react-joyride';
import { AuthService } from '../../services/authService';
import { Heading } from 'elmo-elements';

interface ReportWizardProps {
  dispatch: Dispatch<any>;
  module: Module;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  view: View;
  views: View[];
  checkedJoinDimensions: JoinAndDimensions[];
  checkedJoinMeasures: JoinAndMeasures[];
  onResetClicked: () => void;
  onGenerateSuccess: () => void;
  authService: AuthService | null;
  toggleSelectFormModal: () => void;
  reportId?: string;
}

const mapStateToProps = (state: AppState) => ({
  module: state.module,
  dataType: state.dataType,
  view: state.view,
  views: state.views,
  checkedJoinDimensions: state.joinDimensions,
  checkedJoinMeasures: state.joinMeasures,
  authService: state.authService
});

interface ReportWizardState {
  selectedJoin: Join | null;
  selectedView: View | null;
  stepIndex: number;
  demoMode: boolean;
}

class ReportWizardComponent extends React.Component<ReportWizardProps, ReportWizardState> {
  protected joins: Join[];
  protected steps = [
    {
      target: '.report-wizard-container',
      content: 'Follow the steps to create a custom report.',
      title: 'Welcome to the guided tour',
      disableBeacon: true,
    },
    {
      target: '.toggle',
      content: 'Switch on \'Aggregate Data\' if there will be data that needs to be calculated over a range of values '
        + 'eg a Sum or Count. If unsure, leave blank.',
      title: 'Step 1: Data mode',
    },
    {
      target: '.module-select-container',
      content: 'Select a Module to create reports from, then click Next.',
      title: 'Step 2: Module selection',
    },
    {
      target: '.view-select-container',
      content: 'Select a base View to report from, then click Next.',
      title: 'Step 3: Base View selection',
    },
    {
      target: '.column-select',
      content: 'Select some Dimensions (fields) or Measures (aggregates), then click Next.',
      title: 'Step 4: Dimensions/Measures selection',
    },
    {
      target: '.reset-generate-btn-group',
      content: 'Generate your report or hit clear all to start again.',
      title: 'Final step: Generate',
    },
  ];

  constructor(props: ReportWizardProps) {
    super(props);

    this.state = {
      selectedJoin: null,
      selectedView: null,
      stepIndex: 0,
      demoMode: false,
    };
  }

  componentDidUpdate(prevProps: ReportWizardProps) {
    // only update the joins to render when props have been updated, for efficiency
    if (this.props.view.name !== prevProps.view.name) {
      this.onBaseViewSelect(this.props.view);
    }
    if (this.props.module.name && !prevProps.module.name && this.props.module.name.length > 0 && this.state.demoMode) {
      this.props.dispatch(setSuggestionsOpen(false));
    }
  }

  onBaseViewSelect = (selectedView: View) => {
    this.setState({
      selectedView: selectedView,
      selectedJoin: null
    });
  }

  onJoinSelect = (selectedJoin: Join) => {
    this.setState({
      selectedView: null,
      selectedJoin: selectedJoin
    });
  }

  onResetClicked = () => {
    this.props.dispatch(setSuggestionsOpen(false));
    this.props.onResetClicked();
  }

  renderColumnSelect() {
    if (this.props.dataType === DATA_TYPE_DIMENSIONS && this.state.selectedJoin) {
      return (
        <JoinDimensionSelect
          join={this.state.selectedJoin}
        />
      );
    } else if (this.props.dataType === DATA_TYPE_DIMENSIONS && this.state.selectedView) {
      return (
        <DimensionSelect />
      );
    } else if (this.props.dataType === DATA_TYPE_MEASURES && this.state.selectedJoin) {
      return (
        <JoinMeasureSelect
          join={this.state.selectedJoin}
        />
      );
    } else if (this.props.dataType === DATA_TYPE_MEASURES && this.state.selectedView) {
      return (
        <MeasureSelect />
      );
    }

    return null;
  }

  hasSelectedBaseView() {
    return this.props.view.name;
  }

  render() {
    let showColumnSelect = this.hasSelectedBaseView();
    let showColumnSelectClass: string = showColumnSelect ? 'show-cols' : 'hide-cols';
    let tablesWidthClass: string = 'col-sm-12 col-md';
    let fieldsWidthClass: string = showColumnSelect ? 'col-sm-12 col-md' : 'd-none';
    const userData = (this.props.authService) ? this.props.authService.getUserData() : null;
    let enableTour: boolean = !!(userData && userData.enableTour);

    return (
      <div className="report-wizard-container" role="form">
        <div className="wizard-top">
          <div
            className={'buttons ' + showColumnSelectClass}
            data-testid="wizard-buttons"
          >
            <div className="reset-generate-btn-group">
              <ResetReportButton
                className="btn btn-outline-dark"
                onClick={this.onResetClicked}
              />
              <GenerateReportButton
                className="btn btn-primary"
                onGenerateSuccess={this.props.onGenerateSuccess}
                onGenerateStart={this.generateStartCallback}
              />
            </div>
            <DataTypeSelect />
            {enableTour &&
            <>
              <ReactJoyride
                steps={this.steps}
                debug={true}
                run={this.state.demoMode}
                stepIndex={this.state.stepIndex}
                continuous={true}
                showProgress={false}
                locale={{back: 'Back', close: 'Close', last: 'Done', next: 'Next', skip: 'Skip'}}
                callback={this.handleJoyrideCallback}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                spotlightClicks={true}
                styles={{
                  options: {
                    primaryColor: '#FFFFFF',
                  },
                  buttonNext: {
                    color: '#000000',
                    border: '1px solid #c5c5c5',
                  }
                }}
              />
              <a className={showColumnSelect ? 'd-none' : ''} href="#" onClick={this.activateGuide}>
                Take the guided tour
              </a>
            </>
            }
          </div>
          <SuggestedReports />
        </div>
        <hr className="divider" />
        <div className="row no-gutters wizard-bottom">
          <div className={'wizard-bottom-left ' + tablesWidthClass}>
            <Heading htmlTag="h4">Browse Tables</Heading>
            <div className="scroll">
              <ModuleSelect reportId={this.props.reportId} />
              {this.props.views.length > 0 && this.props.module.name &&
                <ViewSelect
                  onBaseViewSelect={this.onBaseViewSelect}
                  onJoinSelect={this.onJoinSelect}
                  toggleSelectFormModal={this.props.toggleSelectFormModal}
                />
              }
            </div>
          </div>
          <div className={'wizard-bottom-right ' + fieldsWidthClass}>
            <Heading htmlTag="h4">Select Fields</Heading>
            <div className="scroll">
              {this.props.view.name &&
                this.renderColumnSelect()
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  activateGuide = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.dispatch(setSuggestionsOpen(false));
    this.setState({ demoMode: true });
  }

  handleJoyrideCallback = (data: ReactJoyrideCallBackProps) => {
    const { action, index, status, type, size } = data;
    const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    if (STATUS.SKIPPED === status || STATUS.FINISHED === status || EVENTS.TOOLTIP_CLOSE === status as any) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ demoMode: false, stepIndex: 0 });
    } else if (ACTIONS.CLOSE === action) {
      this.setState({ demoMode: false });
    } else if (EVENTS.STEP_AFTER === type || EVENTS.TARGET_NOT_FOUND === type) {
      this.setState(
        {
          demoMode: false,
          stepIndex
        },
        () => setTimeout(this.endTour(index, size), 400)
      );
    }
  }

  generateStartCallback = () => {
    // Stop the guide when Generate button pressed
    this.setState({ demoMode: false, stepIndex: 0 });
  }

  endTour = (index: number, size: number) => () => {
    // Last step so do not restart the guide
    if (index !== size - 1) {
      this.setState({ demoMode: true });
    }
  }
}

const ReportWizard = connect(
  mapStateToProps)(ReportWizardComponent);

export default ReportWizard;
