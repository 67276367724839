import { STORE_MODULES } from '../constants';
import { Module } from '../types/moduleModel';
import { ModuleAction, ModulesAction } from '../actions/module';
import { getInitialModuleState } from './index';

export function moduleReducer(state: Module = getInitialModuleState(),
                              action: ModuleAction): Module {
  return {...state};
}

export function modulesReducer(state: Module[] = [], action: ModulesAction): Module[] {
  switch (action.type) {
    case STORE_MODULES:
      return action.modules;
    default:
      return state;
  }
}