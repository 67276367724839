import { SavedQuery } from '../types/savedQueryModel';
import { SupportedCountriesType } from '../services/authService';
import {
  SAVE_QUERY_START,
  SAVE_QUERY_SUCCESS,
  SAVE_QUERY_ERROR,
  LOAD_QUERY_START,
  LOAD_QUERY_ERROR,
  LOAD_QUERY_SUCCESS, STORE_TEMP_QUERY, STORE_SAVED_QUERY,
  STORE_SELECTED_COUNTRY
} from '../constants';

export interface SaveQueryAction {
  type: SAVE_QUERY_START | SAVE_QUERY_SUCCESS | SAVE_QUERY_ERROR;
}

export function saveQueryStart(): SaveQueryAction {
  return {
    type: SAVE_QUERY_START
  };
}

export function saveQuerySuccess(): SaveQueryAction {
  return {
    type: SAVE_QUERY_SUCCESS
  };
}

export function saveQueryError(): SaveQueryAction {
  return {
    type: SAVE_QUERY_ERROR
  };
}

export interface LoadQueryAction {
  type: LOAD_QUERY_START | LOAD_QUERY_SUCCESS | LOAD_QUERY_ERROR;
  error?: Error;
  savedQuery?: SavedQuery;
}

export function loadQueryStart(): LoadQueryAction {
  return {
    type: LOAD_QUERY_START
  };
}

export function loadQueryError(error: Error): LoadQueryAction {
  return {
    type: LOAD_QUERY_ERROR,
    error: error
  };
}

export function loadQuerySuccess(savedQuery: SavedQuery): LoadQueryAction {
  return {
    type: LOAD_QUERY_SUCCESS,
    savedQuery: savedQuery
  };
}

export interface TempQueryAction {
  type: STORE_TEMP_QUERY;
  tempQuery: SavedQuery;
}

export function storeTempQuery(tempQuery: SavedQuery): TempQueryAction {
  return {
    type: STORE_TEMP_QUERY,
    tempQuery: tempQuery
  };
}

export interface SavedQueryAction {
  type: STORE_SAVED_QUERY;
  savedQuery: SavedQuery;
}

export function storeSavedQuery(savedQuery: SavedQuery): SavedQueryAction {
  return {
    type: STORE_SAVED_QUERY,
    savedQuery: savedQuery
  };
}

export interface SelectCountryAction {
  type: STORE_SELECTED_COUNTRY;
  selectedCountries: SupportedCountriesType;
}

export function storeSelectedCountries(countries: SupportedCountriesType): SelectCountryAction {
  return {
    type: STORE_SELECTED_COUNTRY,
    selectedCountries: countries,
  };
}
