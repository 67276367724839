import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { ScheduledQuery } from '../types/scheduler/scheduledQueryModel';
import { ScheduleOptions } from '../types/scheduler/scheduleOptions';

export type ScheduledReports = {
  schedules: ScheduledQuery[]
};

export async function apiCreateSchedule(data: ScheduleOptions) {
  const response: AxiosResponse = await axios.post('/api/schedule', data);
  return response.data;
}

export async function apiEditSchedule(data: ScheduleOptions) {
  const scheduleId = data.scheduleId;
  const response: AxiosResponse = await axios.put('/api/schedule/' + scheduleId, data);
  return response.data;
}

export async function apiGetSchedules() {
  const response: AxiosResponse = await axios.get('/api/schedules');
  return response.data;
}

export async function apiDeleteSchedule(scheduleId: string) {
  let response: AxiosResponse = await axios.delete('/api/schedule/' + scheduleId);
  if (response) {
    return response.data;
  }
  return null;
}

let getSchedulerUsersSource: CancelTokenSource | null = null;
export type GetSchedulerUsersParamsType = {
  text?: string;
  ids?: Array<string>;
};
export async function apiGetSchedulerUsers(params: GetSchedulerUsersParamsType) {
  if (getSchedulerUsersSource) {
    getSchedulerUsersSource.cancel('Cancelled');
  }

  const CancelToken = axios.CancelToken;
  getSchedulerUsersSource = CancelToken.source();
  const response: AxiosResponse = await axios.post(
    '/api/scheduler-users',
    params,
    { cancelToken: getSchedulerUsersSource.token }
  );
  return response && response.data ? response.data : [];
}

export async function apiGetOutboundDestinations() {
  const response: AxiosResponse = await axios.get('/api/outbound/providers');
  return response.data;
}
