import { BaseModel, NestedEntityMap } from './baseModel';

export class ReportCellData extends BaseModel {
  label: string;
  value: string;

  protected properties: string[] = [
    'label',
    'value'
  ];

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}

export class ReportCellMeta extends BaseModel {
  protected properties: string[] = [
    'dimensionName',
    'measureName',
    'baseViewName',
    'dotNotation',
    'originalValue',
    'canDrilldown'
  ];

  dimensionName: string;
  measureName: string;
  baseViewName: string;
  dotNotation: string;
  originalValue: string;
  canDrilldown: boolean;

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}

export class ReportCell extends BaseModel {
  data: ReportCellData;
  meta: ReportCellMeta;

  protected nestedObjects: NestedEntityMap = {
    data: ReportCellData,
    meta: ReportCellMeta
  };

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}