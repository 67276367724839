import { AuthService } from '../services/authService';
import { SET_AUTH_SERVICE, SET_USER } from '../constants';
import { AuthServiceAction, UserAction } from '../actions/auth';
import { User } from '../types/userModel';

export function authServiceReducer(state: AuthService | null = null, action: AuthServiceAction) {
  switch (action.type) {
    case SET_AUTH_SERVICE: {
      return action.authService;
    }
    default: {
      return state;
    }
  }
}

export function userReducer(state: User | null = null, action: UserAction) {
  switch (action.type) {
    case SET_USER: {
      return action.user;
    }

    default: {
      return state;
    }
  }
}