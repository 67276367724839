import * as React from 'react';
import LoadingIcon from '../../../shared-components/LoadingIcon';
import './style.css';
import ExportCsvButton from '../../../containers/ExportCsvButton';

type SavedReportLoadingProps = {
  queryId?: string;
  showExport?: boolean;
};

function SavedReportLoading({ showExport, queryId }: SavedReportLoadingProps) {
  return (
    <div className="saved-report-loading">
      <LoadingIcon />
      <h2 className="text-center">Your report is currently being generated.</h2>
      <p className="text-center">
        This may take some time depending on the amount of data that is
        being generated. <br/>
        If the delay experienced is greater than anticipated, please contact support.
      </p>
      {showExport && queryId &&
        <ExportCsvButton
          buttonColourClass="btn-primary"
          queryId={queryId}
        />
      }
    </div>
  );
}

export default SavedReportLoading;