import * as React from 'react';
import {
  apiGetExistingRelations, formatRelationsForExport, downloadData, apiGetPublishedConfig,
  apiSaveRelations, apiGenerateRelations
} from '../../../services/configurationService';
import CollapseCard from '../../../shared-components/CollapseCard';
import ConfigEditRelationsModal, { ConfigEditRelationsForm } from '../../../components/Config/ConfigEditRelationsModal';
import { Record, List } from 'immutable';
import ConfigEditJoinModal, { ConfigEditJoinForm } from '../../../components/Config/ConfigEditJoinModal';
import { CONFIG_STATUS_DRAFT, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../../constants';
import LoadingIcon from '../../../shared-components/LoadingIcon';
import ConfigMenu from '../../../components/Config/ConfigMenu';
import { NotificationService } from '../../../services/notificationService';
import ConfigDetails from '../../../components/Config/ConfigDetails';
import { ConfigFilter, ConfigRelation, ConfigRelationRecord } from '../../../types/config/configRelationModel';
import { ConfigJoin, ConfigJoinRecord } from '../../../types/config/configJoinModel';
import { ConfigData } from '../../../types/config/configDataModel';
import Page from '../../../shared-components/Page';

interface EditConfigRelationsPageProps {
  configId: string;
  notificationService: NotificationService;
}

interface EditConfigRelationsPageState {
  relations: List<Record<ConfigRelation>>;
  editingRelation: Record<ConfigRelation>;
  editingRelationIndex: number;
  editingJoin: Record<ConfigJoin>;
  editingJoinIndex: number;
  relationsModalIsOpen: boolean;
  joinModalIsOpen: boolean;
  config: ConfigData | null;
}

export class EditConfigRelationsPage extends
  React.Component<EditConfigRelationsPageProps, EditConfigRelationsPageState> {

  constructor(props: EditConfigRelationsPageProps) {
    super(props);

    this.state = {
      relations: List(),
      relationsModalIsOpen: false,
      joinModalIsOpen: false,
      editingRelation: new ConfigRelationRecord(),
      editingRelationIndex: -1,
      editingJoin: new ConfigJoinRecord(),
      editingJoinIndex: -1,
      config: null
    };
  }

  async componentDidMount() {
    this.loadConfig(this.props.configId, false);
  }

  async loadConfig(configId: string, loadPublished: boolean) {
    // get the array of views that have been already selected and their dimensions / measures
    let existingConfig = await apiGetExistingRelations(configId);
    let relations = existingConfig.relations;

    this.setState({
      relations: relations,
      config: !loadPublished ? existingConfig.config : this.state.config
    });
  }

  onSaveClick = async () => {
    if (!this.state.config) {
      this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'No configuration loaded.');
      return;
    }

    // convert the selected views data into a PHP array format
    await apiSaveRelations(this.state.config.id, this.state.relations);
    this.props.notificationService.addNotification(NOTIFICATION_SUCCESS, 'Configuration saved successfully.');
  }

  openRelationsModal = (relationIndex: number) => () => {
    this.setState({
      editingRelationIndex: relationIndex,
      editingRelation: new ConfigRelationRecord(this.state.relations.get(relationIndex)),
      relationsModalIsOpen: true
    });
  }

  onEditRelationDone = (form: ConfigEditRelationsForm) => {
    let newRelations = this.state.relations;
    let newRelation = this.state.editingRelation;
    newRelation = newRelation.merge({
      label: form.label,
      base: form.base
    });

    newRelations = newRelations.set(this.state.editingRelationIndex, newRelation);

    this.setState({
      relations: newRelations
    });
  }

  openJoinModal = (joinIndex: number, relationIndex: number) => () => {
    let editingRelation = this.state.relations.get(relationIndex, new ConfigRelationRecord());
    this.setState({
      editingRelationIndex: relationIndex,
      editingRelation: editingRelation,
      editingJoinIndex: joinIndex,
      editingJoin: editingRelation.get('joins', []).get(joinIndex, new ConfigJoinRecord()),
      joinModalIsOpen: true
    });
  }

  onEditJoinDone = (form: ConfigEditJoinForm) => {
    let newJoin = this.state.editingJoin;
    newJoin = newJoin.merge({
      label: form.label
    });

    let newRelations: List<Record<ConfigRelation>> = this.state.relations;
    let newRelation: Record<ConfigRelation> =
      newRelations.get(this.state.editingRelationIndex, new ConfigRelationRecord());
    let newJoins = newRelation.get('joins', []);
    newJoins = newJoins.set(this.state.editingJoinIndex, newJoin);
    newRelation = newRelation.set('joins', newJoins);
    newRelations = newRelations.set(this.state.editingRelationIndex, newRelation);

    this.setState({
      relations: newRelations
    });
  }

  onGenerateRelationsClick = async () => {
    let relations = await apiGenerateRelations(this.props.configId);

    this.setState({
      relations: relations
    });
  }

  toggleRelationsModal = () => {
    this.setState({
      relationsModalIsOpen: !this.state.relationsModalIsOpen
    });
  }

  toggleJoinModal = () => {
    this.setState({
      joinModalIsOpen: !this.state.joinModalIsOpen
    });
  }

  onExportRelationsClick = () => {
    if (!this.state.relations) {
      return;
    }

    let exportData = formatRelationsForExport(this.state.relations);
    // send it to the browser
    downloadData(exportData, 'application/octet-stream');
  }

  onLoadPublishedConfigClick = async () => {
    if (!this.state.config) {
      this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'No configuration loaded.');
      return;
    }

    let publishedConfig = await apiGetPublishedConfig(this.state.config.module, this.state.config.clientName);
    if (!publishedConfig) {
      return;
    }

    this.loadConfig(publishedConfig.id, true);
  }

  render() {
    if (!this.state.config) {
      return <LoadingIcon />;
    }

    return(
      <Page title="Edit Relations Configuration" className="configuration-layout edit-module-config-page">
        <ConfigMenu />
        {this.renderButtons()}
        <ConfigDetails config={this.state.config} />
        <div>
          {this.state.relations.map(this.renderRelation)}
        </div>
        <ConfigEditRelationsModal
          toggle={this.toggleRelationsModal}
          isOpen={this.state.relationsModalIsOpen}
          relation={this.state.editingRelation}
          onDone={this.onEditRelationDone}
        />
        <ConfigEditJoinModal
          toggle={this.toggleJoinModal}
          isOpen={this.state.joinModalIsOpen}
          join={this.state.editingJoin}
          onDone={this.onEditJoinDone}
        />
      </Page>
    );
  }

  renderButtons = () => {
    if (!this.state.config) {
      return null;
    }

    return (
      <div className="mb-2">
        <button
          className="btn btn-primary mr-2"
          onClick={this.onExportRelationsClick}
        >
          <i className="fa fa-download" /> Export Relations
        </button>
        <button
          className="btn btn-primary mr-2"
          onClick={this.onGenerateRelationsClick}
        >
          <i className="fa fa-sitemap" /> Generate New Relations
        </button>
        {this.state.config.status === CONFIG_STATUS_DRAFT &&
        <React.Fragment>
          <button
            className="btn btn-primary mr-2"
            onClick={this.onLoadPublishedConfigClick}
          >
            <i className="fa fa-repeat" /> Load Existing Published Config
          </button>
          <button
            className="btn btn-primary mr-2"
            onClick={this.onSaveClick}
          >
            <i className="fa fa-save" /> Save to Database
          </button>
        </React.Fragment>}
      </div>
    );
  }

  renderRelation = (relation: Record<ConfigRelation>, key: number) => {
    let cardTitle: string = relation.get('label', '') + ' '
      +  '(' + relation.get('tableName', '') + ')';

    let rules = relation.get('rules', null);
    let filters = rules && rules.filters ? rules.filters : null;
    return (
      <CollapseCard
        title={cardTitle}
        key={key}
      >
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary"
              onClick={this.openRelationsModal(key)}
            >
              <i className="fa fa-pencil" /> Edit Details
            </button>
          </div>
        </div>
        <h4>Joins:</h4>
        <div className="row">
          {this.renderJoins(relation, key)}
        </div>
        {filters &&
          <React.Fragment>
            <h4>Filters:</h4>
            <div className="row">
              {this.renderFilters(filters)}
            </div>
          </React.Fragment>
        }
      </CollapseCard>
    );
  }

  renderFilters(filters: List<Record<ConfigFilter>>) {
    return filters.map((filter: Record<ConfigFilter>, key: number) => {
      let dimensionName: string = filter.get('dimensionName', '');
      let type: string = filter.get('type', '');
      let value: any = filter.get('value', '');
      return (
        <div
          key={key}
          className="col-12"
        >
          {dimensionName + ' ' + type + ' ' + value}
        </div>);
    });
  }

  renderJoins(relation: Record<ConfigRelation>, relationIndex: number) {
    let joins = relation.get('joins' , '');
    return joins.map((join: Record<ConfigJoin>, key: number) => {
      return (
        <div
          key={key}
          className="col-3"
        >
          {join.get('label', '')}
          <span
            className="pl-2"
            onClick={this.openJoinModal(key, relationIndex)}
          >
            <i className="fa fa-pencil" />
          </span>
        </div>
      );
    });
  }
}