import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const LightBulbIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50008 17.5C7.50008 17.9583 7.87508 18.3333 8.33341 18.3333H11.6667C12.1251 18.3333 12.5001 17.9583 12.5001
         17.5V16.6666H7.50008V17.5ZM10.0001 1.66663C6.78341 1.66663 4.16675 4.28329 4.16675 7.49996C4.16675 9.48329
          5.15841 11.225 6.66675 12.2833V14.1666C6.66675 14.625 7.04175 15 7.50008 15H12.5001C12.9584 15 13.3334 14.625
           13.3334 14.1666V12.2833C14.8417 11.225 15.8334 9.48329 15.8334 7.49996C15.8334 4.28329 13.2167 1.66663
            10.0001 1.66663ZM12.3751 10.9166L11.6667 11.4166V13.3333H8.33341V11.4166L7.62508 10.9166C6.50008 10.1333
             5.83341 8.85829 5.83341 7.49996C5.83341 5.19996 7.70008 3.33329 10.0001 3.33329C12.3001 3.33329 14.1667
              5.19996 14.1667 7.49996C14.1667 8.85829 13.5001 10.1333 12.3751 10.9166Z"
      />
    </svg>
);
};
