import * as React from 'react';
import { AppState } from '../redux';
import { connect } from 'react-redux';
import { AuthService } from '../services/authService';

export interface Props {
  authService: AuthService;
}

const mapStateToProps = (state: AppState) => ({
  authService: state.authService
});

class LogoutPage extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.props.authService.logout();
  }

  render() {
    return null;
  }
}

export default connect(
  mapStateToProps)(LogoutPage);