import * as React from 'react';
import { Button, HighlightOffIcon } from 'elmo-elements';
import './style.css';

export interface ClearButtonProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

class ClearButton extends React.Component<ClearButtonProps> {

  constructor(props: ClearButtonProps) {
    super(props);
  }

  onClick = (e: React.MouseEvent<HTMLElement>) => {
    this.props.onClick(e);
  }

  render() {
    let className = this.props.className ? this.props.className : '';

    return (
      <Button
        className={'clearBtn ' + className}
        onClick={this.onClick}
        icon={<HighlightOffIcon size="sm" className="clearIcon" />}
      />
    );
  }
}

export default ClearButton;
