import * as React from 'react';
import { useEffect } from 'react';
import classnames from 'classnames';
import { Flex } from '@eds/flex';
import { Button } from '@eds/button';
import { Fieldset } from '@eds/fieldset';
import { FieldColumns } from '@eds/field';
import { yupResolver } from '@hookform/resolvers/yup';

import CollapseCard from '../../CollapseCard';

import './style.scss';
import { SelectInputField } from '../../formComponents/selectInputField';
import { useForm } from 'react-hook-form';
import TranslationRules from './TranslationRules';
import { TextInputField } from '../../formComponents/textInputField';
import { Box } from '@eds/box';
import * as _ from 'lodash';
import {
  DefaultValueType,
  TranslationConfiguration,
  TranslationConfigurations,
  TranslationRuleItem,
} from '../../../../types/transformationModel';
import translationConfigurationValidationSchema from './validation';
import { useDispatch, useSelector } from 'react-redux';
import { setTranslationConfiguration } from '../../../../actions/transformations';
import { getReportJson } from '../../../../actions/report';
import { AppState } from '../../../../redux';

interface Props {
  isOpen: boolean;
  isCreateNew?: boolean;
  reportId: string | null;
  readOnly?: boolean;
  fieldOptions: Array<{
    label: string;
    type: string;
    value: string;
  }>;
  toggle: () => void;
  data?: TranslationConfiguration;
}

const TranslationConfigurationCreateEdit = ({
  isOpen,
  isCreateNew,
  readOnly,
  data: _data,
  fieldOptions,
  reportId,
  toggle,
}: Props) => {
  const dispatch = useDispatch();

  /**
   * For new form submission we always want undefined for data,
   * as no data should exist
   */
  const data = isCreateNew ? undefined : _data;

  const { transformations } = useSelector((state: AppState) => state);

  const usedDimensions = transformations.reduce(
    (result, transformation: TranslationConfigurations) => {
      return [
        ...result,
        ...transformation.configuration.map((configuration) => configuration.dimension),
      ];
    },
    [],
  );
  const availableFieldOptions = fieldOptions
    .filter((item) => (data && data.dimension === item.value) || !usedDimensions.includes(item.value));

  const {
    control,
    formState: {
      errors: clientSideErrors,
      isSubmitting,
    },
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
  } = useForm<TranslationConfiguration>({
    mode: 'onBlur',
    defaultValues: isCreateNew ? new TranslationConfiguration() : undefined,
    resolver: yupResolver(translationConfigurationValidationSchema),
  });

  const formValues = watch();

  useEffect(
    () => {
      if (isOpen) {
        reset(data || new TranslationConfiguration());
      }
    },
    [reset, data, isOpen],
  );

  useEffect(
    () => {
      if (formValues.dimension) {
        if (_.get(formValues, 'rules.length') === 0) {
          setValue('rules.0', new TranslationRuleItem());
        }
      }
    },
    [formValues.dimension, _.get(formValues, 'rules.length'), setValue],
  );

  useEffect(
    () => {
      if (formValues.rules) {
        formValues.rules.forEach((rule, index) => {
          if (index !== 0) {
            setValue(`rules.${index}.dimension`, _.get(formValues, 'rules.0.dimension'));
          }
        });

        if (_.get(formValues, 'defaultValue.type') === DefaultValueType.FIELD) {
          setValue('defaultValue.value', _.get(formValues, 'rules.0.dimension'));
        } else if (_.get(formValues, 'defaultValue.type') === DefaultValueType.BLANK) {
          setValue('defaultValue.value', undefined);
        }
      }
    },
    [
      formValues.rules,
      _.get(formValues, 'defaultValue.type'),
      _.get(formValues, 'rules.0.dimension'),
      setValue,
    ],
  );

  return (
    <CollapseCard className={classnames('report-transformation', { 'is-open': isOpen })} isOpen={isOpen}>
      <form
        onSubmit={handleSubmit(async (submission) => {
          dispatch(setTranslationConfiguration(submission));
          dispatch(getReportJson(false, reportId as any));
          reset();
          toggle();
        })}
      >
        <Flex flexDirection="column" padding="large">
          <Fieldset legend="Translation" legendStyle="group">
            <Box width="33%">
              <SelectInputField
                required={true}
                id="dimension"
                label="Field to apply translations to"
                control={control}
                errors={clientSideErrors}
                items={availableFieldOptions}
              />
            </Box>
            { _.get(formValues, 'rules.length') > 0 && (
              <TranslationRules
                setValue={setValue}
                formValues={formValues}
                fieldOptions={fieldOptions}
                control={control}
                register={register}
                errors={clientSideErrors}
                watch={watch}
                id="rules"
              />
            )}
            { formValues.dimension && (
              <Box width="66%">
                <FieldColumns>
                  <SelectInputField
                    required={true}
                    id="defaultValue.type"
                    label="If condition is not met"
                    control={control}
                    errors={clientSideErrors}
                    items={[
                      { label: 'Blank', value: DefaultValueType.BLANK },
                      { label: 'Field', value: DefaultValueType.FIELD },
                      { label: 'Text', value: DefaultValueType.TEXT },
                    ]}
                  />
                  { _.get(formValues, 'defaultValue.type') === DefaultValueType.FIELD && (
                    <SelectInputField
                      required={true}
                      id="defaultValue.value"
                      label="Future value"
                      control={control}
                      errors={clientSideErrors}
                      items={fieldOptions}
                      disabled={true}
                    />
                  )}
                  { _.get(formValues, 'defaultValue.type') === DefaultValueType.TEXT && (
                    <TextInputField
                      register={register}
                      errors={clientSideErrors}
                      id="defaultValue.value"
                      label="Future value"
                      required={true}
                      disabled={readOnly}
                    />
                  )}
                </FieldColumns>
              </Box>
            )}
          </Fieldset>
          <Flex gap="small" justifyContent="start" marginTop="small">
            <>
              <Button
                label="Cancel"
                tone="neutral"
                size="small"
                onClick={() => {
                  reset();
                  toggle();
                }}
                disabled={isSubmitting}
              />
              <Button
                label="Apply"
                type="submit"
                size="small"
                loading={isSubmitting}
              />
            </>
          </Flex>
        </Flex>
      </form>
    </CollapseCard>
  );
};

export default TranslationConfigurationCreateEdit;
