import * as React from 'react';
import { BasicModal } from '../../../shared-components/BasicModal';
import { Record } from 'immutable';
import { ConfigRelation } from '../../../types/config/configRelationModel';

interface ConfigEditRelationsModalProps {
  toggle: () => void;
  isOpen: boolean;
  relation: Record<ConfigRelation>;
  onDone: (c: ConfigEditRelationsForm) => void;
}

interface ConfigEditRelationsModalState {
  form: ConfigEditRelationsForm;
}

export interface ConfigEditRelationsForm {
  label: string;
  base: boolean;
}

class ConfigEditRelationsModal extends
  React.Component<ConfigEditRelationsModalProps, ConfigEditRelationsModalState> {

  constructor(props: ConfigEditRelationsModalProps) {
    super(props);

    this.state = {
      form: {
        label: '',
        base: false
      }
    };
  }

  componentDidUpdate(prevProps: ConfigEditRelationsModalProps) {
    if (!this.props.relation.equals(prevProps.relation)) {
      this.setState({
        form: {
          label: this.props.relation.get('label', ''),
          base: this.props.relation.get('base', false)
        }
      });
    }
  }

  handleInputChange = (property: string) => (event: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => {
    let form = Object.assign({}, this.state.form);

    form[property] = event.currentTarget.type === 'checkbox' ?
      event.currentTarget['checked'] : event.currentTarget.value;

    this.setState({
      form: form
    });
  }

  renderContent() {
    return (
      <form>
        <div className="form-group row">
          <label
            htmlFor="label"
            className="col-3 col-form-label"
          >
            Label:
          </label>
          <div className="col">
            <input
              type="text"
              value={this.state.form.label}
              onChange={this.handleInputChange('label')}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <label
            htmlFor="baseView"
            className="col-3 col-form-label"
          >
            Base View (shows up in first level of tree):
          </label>
          <div className="col">
            <input
              name="baseView"
              type="checkbox"
              checked={this.state.form.base}
              onChange={this.handleInputChange('base')}
            />
          </div>
        </div>
      </form>
    );
  }

  onDone = () => {
    this.props.onDone(this.state.form);
    this.props.toggle();
  }

  render() {
    return (
      <div className="config-edit-relations">
        <BasicModal
          title="Edit Relations"
          className="config-edit-relations-modal"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          confirmButtonText="OK"
          onConfirmClicked={this.onDone}
        >
          {this.renderContent()}
        </BasicModal>
      </div>
    );
  }
}

export default ConfigEditRelationsModal;