import { useEffect } from 'react';
import { UseFieldArrayReturn, UseFormReset, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { yupResolver } from '@hookform/resolvers/yup';

import { AppState } from 'src/redux';
import { CustomColumnType } from 'src/types/customColumnModel';
import { TranslationConfigurations } from 'src/types/transformationModel';
import { addConcatenationConfiguration, updateConcatenationConfiguration } from 'src/actions/transformations';
import { getReportJson } from 'src/actions/report';
import useDimensionLabel from 'src/components/hooks/useDimensionLabel';

import { ConcatenationConfigFieldValues, concatenationConfigSchema } from './concatenation-config-setup.validation';

export type ConcatenationConfiguration = {
  id: string;
  type: 3;
  configuration: {
    customColumn: boolean;
    dimension: string;
    items: string[];
    label: string;
  }[];
};

type FieldOption = {
  custom: boolean;
  label: string;
  value: string;
};

const defaultValues: ConcatenationConfigFieldValues = {
  concatenatedValues: [],
  targetField: null,
};

export const delimiterTokenLabelOverride: Record<string, string> = {
  'concatenation-comma': ', (comma)',
  'concatenation-hyphen': '- (hyphen)',
  'concatenation-slash': '/ (slash)',
  'concatenation-space': '(space)',
};

export const useConcatenationConfigForm = (): [
  UseFormReturn<ConcatenationConfigFieldValues>,
  UseFieldArrayReturn<ConcatenationConfigFieldValues>
] => {
  const form = useForm<ConcatenationConfigFieldValues>({
    defaultValues,
    resolver: yupResolver(concatenationConfigSchema),
  });

  return [form, useFieldArray({ control: form.control, name: 'concatenatedValues' })];
};

export const useConcatenationConfigSubmit = (reportId?: string, transformationId?: string) => {
  const dispatch = useDispatch();

  return ({ concatenatedValues, targetField }: ConcatenationConfigFieldValues) => {
    const concatenationConfig: ConcatenationConfiguration = {
      id: transformationId || uuidv4(),
      type: 3,
      configuration: [
        {
          customColumn: targetField!.custom,
          dimension: targetField!.value,
          items: concatenatedValues.map(({ type, value }) => {
            if (type === 'string') return value as string;
            if (type === 'dimension') return `dimension:${(value as { value: string }).value}`;
            return (value as { value: string }).value;
          }),
          label: 'Concatenated label',
        },
      ],
    };

    if (transformationId) {
      dispatch(updateConcatenationConfiguration(concatenationConfig));
    } else {
      dispatch(addConcatenationConfiguration(concatenationConfig));
    }

    dispatch(getReportJson(false, reportId));
  };
};

export const useFieldOptions = () => {
  const { columnMeta, customColumns, transformations } = useSelector((state: AppState) => state);
  const getDimensionLabel = useDimensionLabel();

  const usedDimensions = transformations.reduce(
    (result, { configuration }: TranslationConfigurations) => [
      ...result,
      ...configuration.map(({ dimension }) => dimension),
    ],
    []
  );

  const standardFieldOptions: FieldOption[] = columnMeta
    .filter(({ dimension }) => dimension && dimension.type !== 'datetime')
    .map(({ join, dimension }) => {
      const dimensionIdentifier = `${join ? `${join.fullJoinName}.` : ''}${dimension!.name}`;
      const [name, alias] = getDimensionLabel(dimensionIdentifier);

      return {
        custom: false,
        label: alias || name || 'Label missing',
        value: dimensionIdentifier,
      };
    });

  const standardFieldOptionsCopy = [...standardFieldOptions];

  const customFieldOptions = [...Array(standardFieldOptions.length + customColumns.length).keys()]
    .map(index => {
      const customColumnAtIndex = customColumns.find(customColumn => customColumn.index === index);

      if (!customColumnAtIndex) return standardFieldOptionsCopy.shift();

      const [customColumnName, customColumnAlias] = getDimensionLabel(customColumnAtIndex.id);

      // NOTE: It's nice to maintain the original ordering of fields according to the report
      // but we will not include those with CustomColumnType.Dimension, to be filtered out below
      return customColumnAtIndex.type !== CustomColumnType.Dimension
        ? {
            custom: true,
            label: customColumnAlias || customColumnName || 'Label missing',
            value: customColumnAtIndex.id,
          }
        : undefined;
    })
    .filter((option): option is FieldOption => !!option && !usedDimensions.includes(option.value));

  return [standardFieldOptions, customFieldOptions];
};

type InitializerOptions = {
  data?: ConcatenationConfiguration;
  open: boolean;
  reset: UseFormReset<ConcatenationConfigFieldValues>;
};

export const useFormValuesInitializer = ({ data, open, reset }: InitializerOptions) => {
  const getDimensionLabel = useDimensionLabel();
  const [targetFieldName, targetFieldAlias] = getDimensionLabel(data ? data.configuration[0].dimension : '');

  return useEffect(() => {
    if (!open || !data) {
      reset(defaultValues);
      return;
    }

    reset({
      ...defaultValues,
      concatenatedValues: data.configuration[0].items.map(value => {
        if (value.includes('dimension:')) {
          const dimensionIdentifier = value.replace('dimension:', '');
          const [name, alias] = getDimensionLabel(dimensionIdentifier);

          return {
            type: 'dimension',
            value: { label: alias || name || 'Label missing', value: dimensionIdentifier },
          };
        }

        if (value.includes('concatenation-')) {
          return {
            type: 'delimiter',
            value: { label: delimiterTokenLabelOverride[value], value },
          };
        }

        return { type: 'string', value };
      }),
      targetField: {
        custom: data.configuration[0].customColumn,
        label: targetFieldAlias || targetFieldName || 'Label missing',
        value: data.configuration[0].dimension,
      },
    });
  }, [data, open]);
};
