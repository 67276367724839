import * as React from 'react';
import TreeNode from '../TreeNode';

export class TreeItem {
  data: any; // not used for rendering by the Tree, user defined data can be stored for search etc
  content: string | JSX.Element | ((item: TreeItem) => JSX.Element);
  onItemClick?: (selfItem: TreeItem) => void;
  children: TreeItem[];
  alwaysShowOpenStateIcon?: boolean;
  isOpen?: boolean;
  isSelected?: boolean;
  renderKey: number;
  disabled?: boolean;
  itemClassName?: string;
  className?: string;
  showSelectedIcon?: boolean;
  showAddIcon?: boolean;

  constructor() {
    this.renderKey = 0;
    this.children = [];
    this.showSelectedIcon = true;
  }
}

export interface TreeProps {
  rootNode: TreeItem;
  dontIndentFirstChildren?: boolean;
}

export interface TreeState {
}

export class Tree extends React.Component<TreeProps, TreeState> {
  constructor(props: TreeProps) {
    super(props);
  }

  render() {
    return (
      <TreeNode
        item={this.props.rootNode}
        depth={1}
        dontIndentFirstChildren={this.props.dontIndentFirstChildren}
        key={this.props.rootNode.renderKey}
      />
    );
  }
}
