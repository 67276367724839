import React, { ComponentProps, ReactNode, useEffect } from 'react';
import { AlertModal as Modal, ContentModal } from '@eds/modal';
import { Text } from '@eds/text';

import { useAppContext } from 'src/features/common';

import { CustomHeader } from './custom-header.component';
import { CustomFooter } from './custom-footer.component';

type AlertModalProps = {
  description: ReactNode;
} & Omit<ComponentProps<typeof Modal>, 'description'>;

export const AlertModal = ({ controls, description, isOpen, onClose, title }: AlertModalProps) => {
  const { setIsModalOpen } = useAppContext();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen, setIsModalOpen]);

  return (
    <ContentModal
      footer={<CustomFooter controls={controls} type="alert" />}
      header={<CustomHeader header={title} onClose={onClose} type="alert" />}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="27.5rem"
    >
      {typeof description === 'string' ? (
        <Text overflowY="auto" color="neutralSubtle" lineHeight="loose">
          {description}
        </Text>
      ) : (
        description
      )}
    </ContentModal>
  );
};
