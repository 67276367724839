export const SET_ROUTES = 'SET_ROUTES';
export type SET_ROUTES = typeof SET_ROUTES;

export const ReportingRoutes = {
  root: '/',
  scheduledReports: '/scheduled',
  dashboard: '/dashboards/:dashboardId',
  dashboardsList: '/dashboards',
  printReport: '/print/:queryId',
  viewReport: '/view/:queryId',
  editReport: '/report/:queryId',
  reportingTool: '/report',
  logout: '/logout',
  reportDownloads: '/downloads',
};
