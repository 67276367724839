import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Dropdown } from 'elmo-elements';

import { CreateScheduledReport } from 'src/features/report-builder';

import { AppState } from '../../redux';
import { Chart } from '../../types/chartModel';
import { SavedQuery } from '../../types/savedQueryModel';
import { NotificationService } from '../../services/notificationService';
import { AuthService } from '../../services/authService';
import { reportSharedWithMe } from '../../services/shareReportService';
import ShareReportModal from '../../components/NewReportBuilder/ShareReportModal';
import { REPORT_TYPE_USER } from '../../constants';

import { ShareIcon } from '../../components/NewReportBuilder/Icons/ShareIcon';
import { ScheduleIcon } from '../../components/NewReportBuilder/Icons/ScheduleIcon';
import { SaveAsIcon } from '../../components/NewReportBuilder/Icons/SaveAsIcon';
import ElmoDropdown from '../../components/NewReportBuilder/ElmoDropdown';

export interface ActionButtonsContainerProps {
  dispatch: Dispatch<any>;
  notificationService: NotificationService;
  chart: Chart;
  loadedQuery?: SavedQuery;
  disabled?: boolean;
  tempQuery: SavedQuery;
  query: string;
  authService: AuthService;
  reportTitle: string;
  queryId: string;
  jobId?: number;
  openSaveQueryModal: () => void;
  readOnly: boolean;
}

export interface ActionButtonsContainerState {
  isAddScheduleModalOpen: boolean;
  isShareReportModalOpen: boolean;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  chart: state.chart,
  tempQuery: state.tempQuery,
  query: state.query,
  authService: state.authService,
  reportTitle: state.reportTitle,
  jobId: state.report.jobId
});

class ActionButtonsContainer extends React.Component<ActionButtonsContainerProps, ActionButtonsContainerState> {

  constructor(props: ActionButtonsContainerProps) {
    super(props);
    this.state = {
      isAddScheduleModalOpen: false,
      isShareReportModalOpen: false,
    };
  }

  isScheduleExportBtnDisabled = () => {
    const { loadedQuery } = this.props;
    return !(loadedQuery && loadedQuery.reportType === REPORT_TYPE_USER);
  }

  setShareReportModalFlag = (showModal: boolean) => () => {
    this.setState({ isShareReportModalOpen: showModal });
  }

  render() {
    const { loadedQuery, disabled, authService, openSaveQueryModal, readOnly } = this.props;
    const { isAddScheduleModalOpen, isShareReportModalOpen } = this.state;
    const isScheduleExportBtnDisabled = this.isScheduleExportBtnDisabled();
    const isReportSharedWithMe = !!loadedQuery && reportSharedWithMe(loadedQuery);
    const permissions = !!loadedQuery && loadedQuery.permissions;
    const showShareReport =  permissions && permissions.canShare && !isReportSharedWithMe;
    const showScheduleExport = authService.isSchedulerEnabled() && !isReportSharedWithMe
      && permissions && permissions.canSchedule;

    const showSaveButton = !!loadedQuery && loadedQuery.permissions && loadedQuery.permissions.canSave
      && !isReportSharedWithMe;
    const showSaveAsButton = !!loadedQuery && loadedQuery.permissions && loadedQuery.permissions.canClone
      && !isReportSharedWithMe;

    return (showSaveAsButton && showSaveButton) || showShareReport || showScheduleExport ? (
      <>
        <ElmoDropdown
          text="Action"
          ariaLabel="Action"
          isDisabled={this.props.disabled}
          className="actionDropdown"
        >
          {!readOnly && showSaveButton && showSaveAsButton ?
            <Dropdown.Item
              ariaLabel="Save As"
              value={1}
              onClick={openSaveQueryModal}
              isDisabled={disabled}
            >
              <SaveAsIcon /> Save As
            </Dropdown.Item>
            : <></>
          }
          {showShareReport ?
            <Dropdown.Item
              ariaLabel="Share"
              value={2}
              onClick={this.setShareReportModalFlag(true)}
              isDisabled={disabled}
            >
              <ShareIcon/> Share
            </Dropdown.Item>
            : <></>
          }
          {showScheduleExport ?
            <Dropdown.Item
              ariaLabel="Schedule"
              value={3}
              onClick={this.toggleAddScheduleModal}
              isDisabled={disabled || isScheduleExportBtnDisabled}
            >
              <ScheduleIcon /> Schedule
            </Dropdown.Item>
           : <></>
          }
        </ElmoDropdown>
        <div>
          {loadedQuery && showScheduleExport &&
            <CreateScheduledReport
              isOpen={isAddScheduleModalOpen}
              onClose={this.toggleAddScheduleModal}
              reportId={loadedQuery.id}
              reportName={loadedQuery.name}
            />
          }
          {loadedQuery && isShareReportModalOpen &&
            <ShareReportModal
              showModal={isShareReportModalOpen}
              closeModal={this.setShareReportModalFlag(false)}
              reportId={loadedQuery.id}
            />
          }
        </div>
      </>
    ) : null;
  }

  toggleAddScheduleModal = () => {
    // Need to do this as we can't add the disabled attribute to the button and also show the tooltip.
    if (this.isScheduleExportBtnDisabled()) {
      return;
    }

    this.setState(({ isAddScheduleModalOpen }) => ({ isAddScheduleModalOpen: !isAddScheduleModalOpen }));
  }
}

const ActionButtons = connect(
  mapStateToProps)(ActionButtonsContainer);
export default ActionButtons;
