import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { noop } from 'lodash';

import { AppState } from '../../redux';
import { NotificationService } from '../../services/notificationService';
import { ExportService } from '../../services/exportService';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../constants';
import { ExportIcon } from '../../components/NewReportBuilder/Icons/ExportIcon';
import ElmoButton from '../../components/NewReportBuilder/ElmoButton';

interface Props {
  disabled?: boolean;
  queryId: string;
  dispatch: Dispatch<any>;
  notificationService: NotificationService;
  query: string;
  reportTitle: string;
  jobId?: number;
}

interface State {
  exporting: boolean;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  query: state.query,
  reportTitle: state.reportTitle,
  jobId: state.report.jobId
});

class ExportButtonContainer extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      exporting: false,
    };
  }

  render() {
    const { disabled } = this.props;

    return (
      <ElmoButton
        id="export-btn"
        onClick={this.doCsvExport}
        isDisabled={disabled}
        icon={<ExportIcon />}
      >
        {this.state.exporting ? 'Exporting...' : 'Export'}
      </ElmoButton>
    );
  }

  doCsvExport = async () => {
    if (this.state.exporting) {
      return;
    }

    this.setState({
      exporting: true
    });

    try {
      let result = await ExportService.handleDownload(
        this.props.dispatch,
        this.props.query,
        this.props.reportTitle,
        this.props.queryId,
        this.props.jobId
      );
      this.onExportSuccess(!!result);
    } finally {
      this.onExportFinally();
    }
  }

  onExportSuccess = (result: boolean) => {
    if (result) {
      let reportTitle = (this.props.reportTitle) ? '<' + this.props.reportTitle + '>'
        : '<untitled>';
      this.props.notificationService.addNotification(
        NOTIFICATION_SUCCESS,
        'Your ' + reportTitle + ' report is being created. To see its progress go to the downloads page.',
        this.renderDownloadsButton()
      );
    } else {
      this.props.notificationService.addNotification(
        NOTIFICATION_ERROR,
        'There was an issue creating the report.'
      );
    }

  }

  onExportFinally = () => {
    this.setState({
      exporting: false
    });
  }

  componentWillUnmount() {
    this.onExportFinally = noop;
  }

  renderDownloadsButton() {
    return (
      <button
        className="btn btn-primary"
        onClick={() => window.open('/downloads', '_blank')}
        style={{marginTop: 5}}
      >
        Downloads
      </button>
    );
  }
}

const ExportButton = connect(
  mapStateToProps)(ExportButtonContainer);
export default ExportButton;
