import { ConcatenationConfiguration } from 'src/features/report-builder';

import { 
  ADD_CONCATENATION_CONFIGURATION,
  UPDATE_CONCATENATION_CONFIGURATION,
  SET_TRANSLATION_CONFIGURATION,
  SET_DATETIME_CONFIGURATION,
  DELETE_TRANSFORMATION,
} from '../constants/transformations';
import { DatetimeConfiguration, TranslationConfiguration } from '../types/transformationModel';

export interface AddConcatenationConfigurationAction {
  type: ADD_CONCATENATION_CONFIGURATION;
  concatenationConfiguration: ConcatenationConfiguration;
}

export interface UpdateConcatenationConfigurationAction {
  type: UPDATE_CONCATENATION_CONFIGURATION;
  concatenationConfiguration: ConcatenationConfiguration;
}

export interface SetTranslationConfigurationAction {
  type: SET_TRANSLATION_CONFIGURATION;
  translationConfiguration: TranslationConfiguration;
}

export interface SetDatetimeConfigurationAction {
  type: SET_DATETIME_CONFIGURATION;
  datetimeConfiguration: DatetimeConfiguration;
}

export interface DeleteTransformationAction {
  type: DELETE_TRANSFORMATION;
  transformationId: string;
  dimension: string;
}

export type TransformationsAction =
  | AddConcatenationConfigurationAction
  | UpdateConcatenationConfigurationAction
  | SetTranslationConfigurationAction
  | SetDatetimeConfigurationAction
  | DeleteTransformationAction;

export function addConcatenationConfiguration(
  concatenationConfiguration: ConcatenationConfiguration
): AddConcatenationConfigurationAction {
  return { type: ADD_CONCATENATION_CONFIGURATION, concatenationConfiguration };
}

export function updateConcatenationConfiguration(
  concatenationConfiguration: ConcatenationConfiguration
): UpdateConcatenationConfigurationAction {
  return { type: UPDATE_CONCATENATION_CONFIGURATION, concatenationConfiguration };
}

export function setTranslationConfiguration(
  translationConfiguration: TranslationConfiguration,
): SetTranslationConfigurationAction {
  return { type: SET_TRANSLATION_CONFIGURATION, translationConfiguration };
}

export function setDatetimeConfiguration(
  datetimeConfiguration: DatetimeConfiguration,
): SetDatetimeConfigurationAction {
  return { type: SET_DATETIME_CONFIGURATION, datetimeConfiguration };
}

export function deleteTransformation(transformationId: string, dimension: string): DeleteTransformationAction {
  return { type: DELETE_TRANSFORMATION, transformationId, dimension };
}
