import { useSelector } from 'react-redux';
import { Option } from '@eds/select-input';

import { AppState, ViewJoinAndDimensionOrMeasure } from 'src/redux';
import { View } from 'src/types/viewModel';
import { ColumnMeta } from 'src/types/columnMetaModel';

type GetUnselectedFieldOptionsParams = {
  baseView: View;
  columnMeta: ColumnMeta[];
  currentView: View;
  dataType: 'Individual' | 'Aggregate';
  filterOptions: ViewJoinAndDimensionOrMeasure[];
  views: View[];
  currentOptions?: Option[];
  joinLabel?: string;
  joinName?: string;
  level?: number;
};

export const getUnselectedFieldOptions = ({
  baseView,
  columnMeta,
  currentView,
  dataType,
  filterOptions,
  views,
  currentOptions = [],
  joinLabel = '',
  joinName = '',
  level = 0,
}: GetUnselectedFieldOptionsParams): Option[] => {
  if (level > 2) {
    return currentOptions;
  }

  const unselectedDimensionsForCurrentView = currentView.dimensions
    .filter(
      ({ deprecated, groupable, name, visible }) =>
        (dataType === 'Individual' ? visible : groupable) &&
        !deprecated &&
        !columnMeta.some(
          ({ dimension, displayLabel, join, label }) =>
            /**
             * NOTE: Some dimension names are not unique across views/joins (e.g. 'id' refers to Enrolment Id
             * and Course Id). Currently this is the only way of identifying them and removing dupes.
             */
            dimension?.name === name &&
            displayLabel === `${joinLabel || currentView.label} / ${label}` &&
            (join?.fullJoinName || '') === joinName
        ) &&
        !filterOptions.some(
          /**
           * NOTE: Also check filterOptions using the same logic
           */
          ({ dimension, join }) => dimension?.name === name && (join?.fullJoinName || '') === joinName
        )
    )
    .map(({ label, name }) => ({
      label: `${joinLabel || currentView.label} / ${label}`,
      value: `${joinName || currentView.name}.${name}`,
      viewName: currentView.name,
    }));

  const unselectedDimensions = [...currentOptions, ...unselectedDimensionsForCurrentView];

  return currentView.joins
    .filter(({ hideUnder }) => {
      /**
       * NOTE: Following the same logic used in building the view tree, do not display the option if any of it's
       * parents are listed in 'hideUnder'. 'baseBiew' is the level 0 parent, and 'currentView' is the level 1.
       * Since we are only using these two levels, the condition below is enough.
       */
      return !(hideUnder.includes(baseView.name) || hideUnder.includes(currentView.name));
    })
    .reduce((accumulatedOptions, { label, name, viewName }) => {
      const foundView = views.find(searchedView => searchedView.name === viewName);
      return foundView
        ? getUnselectedFieldOptions({
            baseView,
            columnMeta,
            currentView: foundView,
            dataType,
            filterOptions,
            views,
            currentOptions: accumulatedOptions,
            joinLabel: label,
            joinName: level === 0 ? name : `${joinName}.${name}`,
            level: level + 1,
          })
        : accumulatedOptions;
    }, unselectedDimensions);
};

export const useUnselectedFieldOptions = (view: View, views: View[]) => {
  const { columnMeta, dataType, filterOptions } = useSelector((state: AppState) => state);

  return getUnselectedFieldOptions({ baseView: view, columnMeta, currentView: view, dataType, filterOptions, views });
};

export const useViews = () => {
  // NOTE: The goal is to make the code more modular and easier to migrate away from Redux in the future if needed
  const { view, views } = useSelector((state: AppState) => state);

  return {
    availableViews: views,
    currentView: view,
  };
};
