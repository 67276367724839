import * as constants from '../constants';
import { View } from '../types/viewModel';
import { FETCHED_VIEWS, STORE_VIEW } from '../constants/index';

export interface ViewAction {
  type: STORE_VIEW | FETCHED_VIEWS;
  view: View;
}

export interface ViewsAction {
  type: constants.FETCHED_VIEWS;
  views: View[];
}

export function storeView(view: View): ViewAction {
  return {
    type: constants.STORE_VIEW,
    view: view,
  };
}