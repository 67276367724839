import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button } from 'elmo-elements';
import { AppState } from "../../redux";
import { ReportTable } from "../../types/reportTableModel";
import { apiCancelQueueReport } from "../../services/reportService";
import { generateReportCanceled } from "../../actions/report";
import { Flex } from '@eds/flex';

const mapStateToProps = (state: AppState) => ({
    report: state.report
});

const CancelReportButton: React.FC<{ report: ReportTable }> = ({ report }) => {
    const [isCanceling, setIsCanceling] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const dispatch = useDispatch();

    const doCancel = async () => {
        const jobId = report.jobId;

        setIsCanceling(true);
        setErrorMessage(null);

        try {
            const result = await apiCancelQueueReport(jobId);
            if (result.data.message !== 'The job has been canceled') {
                setShowErrorMessage(true);
                setErrorMessage(result.data.message);
            } else {
                dispatch(generateReportCanceled());
            }
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <Flex flexDirection="column" justifyContent="center">
            <Button
                className="cancelReportButton"
                onClick={doCancel}
                isDisabled={isCanceling}
                isUppercase={false}
            >
                {!isCanceling ? 'Cancel' : 'Canceling report...'}
            </Button>
            {errorMessage && showErrorMessage && <p className="error">{errorMessage}</p>}
        </Flex>
    );
};

export default connect(mapStateToProps)(CancelReportButton);