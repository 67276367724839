export enum CustomColumnType {
  Empty = 1,
  String,
  Dimension
}

export interface CustomColumn {
  id: string;
  title: string;
  type: CustomColumnType;
  value: string | null;
  joinName?: string;
  index?: number;
  alias?: string;
}