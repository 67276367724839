import React, { ComponentProps } from 'react';
import { Toggle as EDSToggle } from '@eds/toggle';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

/**
 * onClick can be overridden to allow custom label composition
 */
type ToggleProps<TFieldValues extends FieldValues> = Omit<ComponentProps<typeof EDSToggle>, 'toggled'> &
  UseControllerProps<TFieldValues>;

export const Toggle = <TFieldValues extends FieldValues>({
  ariaLabelledBy,
  control,
  label,
  name,
  onClick,
  ...props
}: ToggleProps<TFieldValues>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  if (ariaLabelledBy)
    return <EDSToggle ariaLabelledBy={ariaLabelledBy} onClick={onClick || onChange} toggled={value} {...props} />;

  return <EDSToggle label={label} onClick={onClick || onChange} toggled={value} {...props} />;
};
