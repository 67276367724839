import {
  OCCURRENCE_FORTNIGHTLY, OCCURRENCE_MONTHLY,
  OCCURRENCE_QUARTERLY, OCCURRENCE_WEEKLY,
} from '../../constants/addSchedule';
import { string, array, number, boolean, date, object } from 'yup';
import moment from 'moment';
import {
  NOTIFICATION_RECIPIENT_SCHEMA_EMAIL,
  NOTIFICATION_RECIPIENT_SCHEMA_USER
} from './notificationRecipientValidationSchema';

export const SCHEDULE_OPTIONS_VALIDATION_SCHEMA = object().shape({
  scheduleId: string().required('Schedule id is required'),
  name: string().required('Schedule name is required'),
  occurrence: string().required('Please select a frequency.'),
  queryId: string().required(),
  targetUserIds: array().when('$isOutbound', {
    is: (isOutbound) => {
      return !isOutbound;
    },
    then: array().required('Please select users.').of(string())
  }),
  daysOfTheWeek: array().when('occurrence', {
    is: (occurrence) => { return [OCCURRENCE_WEEKLY, OCCURRENCE_FORTNIGHTLY].includes(occurrence); },
    then: array().of(number()).required('Please select days of the week.'),
    otherwise:  array()
  }),
  calendarDays: array().when(['occurrence', 'lastDayOfTheMonth'], {
    is: (occurrence, lastDayOfTheMonth) => {
      return [OCCURRENCE_MONTHLY, OCCURRENCE_QUARTERLY].includes(occurrence) && !lastDayOfTheMonth;
    },
    then: array().of(number()).required('Please select calendar days.'),
    otherwise: array()
  }),
  lastDayOfTheMonth: boolean().required(),
  startDate: date().required().typeError('Start date is required'),
  endDate: date().nullable().when(
    'startDate',
    (startDate: any, s: any) => {
      const startDateMoment = moment(startDate);
      if (startDateMoment.isValid()) {
        return s.min(startDate, 'End date must be after start date.');
      }
    }),
  outboundDestinationId: string().when('$isOutbound', {
    is: (isOutbound) => {
      return isOutbound;
    },
    then: string().required('Please select outbound destination.')
  }),
  notificationRecipients: array()
    .when(['$isOutboundEmailNotification', '$isOutbound'], {
      is: (isOutboundEmailNotification, isOutbound) => {
       return isOutboundEmailNotification && isOutbound;
      },
      then: array().of(NOTIFICATION_RECIPIENT_SCHEMA_EMAIL).required('Please enter an email.'),
    })
    .when(['$isOutboundEmailNotification', '$isOutbound'], {
      is: (isOutboundEmailNotification, isOutbound) => {
        return !isOutboundEmailNotification && isOutbound;
      },
      then: array().of(NOTIFICATION_RECIPIENT_SCHEMA_USER).required('Please select users.'),
    }),
});