import * as React from 'react';
import { UserSelectOption } from '../../../types/scheduler/userSelectOption';
import { CancelIcon } from 'elmo-elements';

type Props = {
  selectedUsers: UserSelectOption[];
  onDeselect: (user: UserSelectOption) => () => void;
};

function SelectedUsers({ selectedUsers, onDeselect }: Props) {
  const items = selectedUsers.map((user: UserSelectOption, index: number) => {
    return (
      <div
        className="selected-user d-flex justify-content-between"
        key={index}
      >
        {user.label}
        <div
          className="remove-button"
          onClick={onDeselect(user)}
        >
          <CancelIcon />
        </div>
      </div>
    );
  });

  return (
    <div className="selected-users">
      {items}
    </div>
  );
}

export default SelectedUsers;