import * as React from 'react';
import classnames from 'classnames';
import { Checkbox } from 'elmo-elements';
import { CheckboxProps } from 'elmo-elements/Checkbox';

import './style.scss';

function ElmoCheckbox({ className, ...props }: CheckboxProps) {
  return <Checkbox className={classnames('elmo-checkbox--override', className)} {...props} />;
}

export default ElmoCheckbox;