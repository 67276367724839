import * as React from 'react';
import { default as ToggleComponent } from 'react-toggle';
import { InputHTMLAttributes } from 'react';
import { ChangeEventHandler } from 'react';
import classnames from 'classnames';
import 'react-toggle/style.css';
import './style.css';

export interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  switchSize?: 'small';
}

class Toggle extends React.Component<ToggleProps> {
  constructor(props: ToggleProps) {
    super(props);
  }

  render() {
    const { switchSize, ...props} = this.props;

    return (
      <div
        className={classnames('toggle', { 'toggle-sm': switchSize === 'small' })}
        role="switch"
        data-testid="toggle"
      >
        <ToggleComponent
          {...props}
          icons={false}
        />
        <div className="toggle-label">{this.props.label}</div>
      </div>
    );
  }
}

export default Toggle;
