import * as React from 'react';
import classnames from 'classnames';
import { Alert } from 'elmo-elements';
import { AlertProps } from 'elmo-elements/Alert';

import { InfoOutlinedIcon } from '../Icons/InfoOutlinedIcon';

import './style.css';

interface Props {
  message?: string;
}

export type ElmoAlertProps = Pick<AlertProps, Exclude<keyof AlertProps, 'message'>> & Props;

const ElmoAlert = ({ children, className, message = '', type = 'info', ...props }: ElmoAlertProps) => {
  return (
    <Alert className={classnames('elmo-alert--override', className)} type={type} {...props}>
      <div className="elmo-alert__message--override">
        {type === 'info' && <InfoOutlinedIcon />}
        <div>
          <p className="elmo-alert__message">{message}</p>
          <div>{children}</div>
        </div>
      </div>
    </Alert>
  );
};

export default ElmoAlert;
