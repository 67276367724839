import * as React from 'react';

type IntersectionObserverOptionsType = {
  root: Element | null;
  rootMargin: string;
  threshold: number | number[];
};

const useOnElementOnScreen = (options: IntersectionObserverOptionsType) => {
  const containerRef: React.RefObject<any> = React.useRef<any>(null);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const callbackFunction: IntersectionObserverCallback = (entries: Array<IntersectionObserverEntry>) => {
    const [ entry ] = entries;
    setIsVisible(entry.isIntersecting);
  };

  React.useEffect(
    () => {
      const observer: IntersectionObserver = new IntersectionObserver(callbackFunction, options);
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    },
    [containerRef, options]
  );

  return {containerRef, isVisible};
};

export default useOnElementOnScreen;
