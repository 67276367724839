import React from 'react';
import { TextInput as Input, TextInputProps as InputProps } from '@eds/text-input';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type TextInputProps<TFieldValues extends FieldValues> = Omit<InputProps, 'onChange' | 'value'> &
  UseControllerProps<TFieldValues>;

export const TextInput = <TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: TextInputProps<TFieldValues>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return <Input onChange={onChange} value={value} {...props} />;
};
