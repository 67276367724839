import { useState } from 'react';
import { ColumnSort } from '@eds/table';
import { PaginationState } from '@eds/pagination';
import { DateTime } from 'luxon';

import { ReportData, ReportType } from './reports-list.queries';

const DISPLAY_DATE_TIME_FORMAT = 'dd/LL/yyyy';

export type TableColumn = {
  columnName: 'description' | 'legacyStatus' | 'modified' | 'module' | 'owner' | 'report';
  format?: (value: string) => string;
  key: Exclude<keyof ReportData, 'id' | 'permissions'> | 'legacyStatus';
  label: string;
  sortingKey?: string;
};

export const tableColumns: TableColumn[] = [
  { columnName: 'report', key: 'name', label: 'Report' },
  { columnName: 'legacyStatus', key: 'legacyStatus', label: '' },
  { columnName: 'module', key: 'moduleName', label: 'Module', sortingKey: 'displayLabel' },
  { columnName: 'owner', key: 'reportType', label: 'Owner' },
  {
    columnName: 'modified',
    format: value => {
      const modified = DateTime.fromISO(value);
      return modified.isValid ? modified.toFormat(DISPLAY_DATE_TIME_FORMAT) : '-';
    },
    key: 'modified',
    label: 'Last edited',
  },
  { columnName: 'description', key: 'description', label: 'Description' },
];

export const useReportsList = () => {
  const [keywordFilter, setKeywordFilter] = useState('');
  const [moduleFilter, setModuleFilter] = useState<string[]>([]);
  const [paginationState, setPaginationState] = useState({ currentItemsPerPage: 10, currentPage: 1, rangeStart: 1 });
  const [reportTypeFilter, setReportTypeFilter] = useState<ReportType[]>([]);
  const [sortData, setSortData] = useState<ColumnSort>({ columnName: 'modified', sortDirection: 'descending' });

  const onPageUpdate = ({ currentItemsPerPage, currentPage, rangeStart }: PaginationState) => {
    setPaginationState({ currentItemsPerPage, currentPage, rangeStart });
  };

  const onSort = (sort: ColumnSort) => {
    setSortData(sort);
  };

  return {
    currentItemsPerPage: paginationState.currentItemsPerPage,
    currentPage: paginationState.currentPage,
    keywordFilter,
    moduleFilter,
    onPageUpdate,
    onSort,
    rangeStart: paginationState.rangeStart,
    reportTypeFilter,
    setKeywordFilter,
    setModuleFilter,
    setReportTypeFilter,
    sortData,
  };
};
