import { Button } from '@eds/button';
import { Box } from '@eds/box';
import { DeleteIcon } from '@eds/icon';
import { TextInput } from '@eds/text-input';
import { TableCell, TableRow } from '@eds/table';
import * as React from 'react';
import { Control, Controller, FieldValues, Path, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { OperatorLabels, OperatorType, TranslationRuleItem } from '../../../../types/transformationModel';
import { SelectInput } from '@eds/select-input';
import * as _ from 'lodash';

type TranslationRuleProps<T extends FieldValues> = {
  errors: Record<string, unknown>;
  control: Control<T>;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  formValues: T;
  fieldOptions: Array<{
    label: string;
    type: string;
    value: string;
  }>;
  id: Path<T>;
  data?: TranslationRuleItem;
  index: number;
  disabled?: boolean;
  noRemove?: boolean;
  remove: () => void;
};

const TranslationRule = <T extends FieldValues, >({
  errors,
  id,
  fieldOptions,
  data,
  index,
  control,
  register,
  setValue,
  formValues,
  disabled,
  noRemove,
  remove,
}: TranslationRuleProps<T>) => {
  const currentDimension = (data && fieldOptions.find((item) => item.value === data.dimension)) || { type: undefined };
  const currentValueType = currentDimension.type === 'number' ? 'number' : undefined;

  React.useEffect(
    () => {
      if (data && (data.operator === OperatorType.NOT_BLANK || data.operator === OperatorType.IS_BLANK)) {
        // @ts-expect-error
        setValue(`rules.${index}.currentValue`, undefined);
      }
    },
    [(data && data.operator), setValue, index],
  );

  return (
    <TableRow>
      <TableCell columnName="field" key="field">
        <Box width="100%">
          <Controller
            // @ts-expect-error
            name={`${id}.dimension`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectInput
                invalid={!!_.get(errors, 'dimension')}
                disabled={disabled || index !== 0}
                items={fieldOptions}
                size="small"
                onChange={(item) => onChange(item && item.value)}
                value={fieldOptions.find((item) => item.value === value)}
              />
            )}
          />
        </Box>
      </TableCell>
      <TableCell columnName="operator" key="operator">
        <Box width="100%">
          <Controller
            // @ts-expect-error
            name={`${id}.operator`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectInput
                invalid={!!_.get(errors, 'operator')}
                disabled={disabled || !(data && data.dimension)}
                items={Object.values(OperatorType)
                  .filter((type) => {
                    const tField = data ? fieldOptions.find((item) => item.value === data.dimension) : undefined;
                    if (tField && tField.type === 'string') {
                      return ![OperatorType.GREATER_THAN, OperatorType.LESS_THAN].includes(type);
                    } else {
                      return true;
                    }
                  })
                  .map((key) => ({
                    value: key,
                    label: OperatorLabels[key],
                  }))
                }
                size="small"
                onChange={(item) => onChange(item && item.value)}
                value={Object.values(OperatorType).map((key) => ({
                  value: key,
                  label: OperatorLabels[key],
                })).find((item) => item.value === value)}
              />
            )}
          />
        </Box>
      </TableCell>
      <TableCell columnName="currentValue" key="currentValue">
        <Box width="100%">
          <TextInput
            size="small"
            type={currentValueType}
            disabled={disabled
              || !(data && data.dimension)
              || [OperatorType.IS_BLANK, OperatorType.NOT_BLANK].includes(data.operator)
            }
            invalid={!!(errors || {}).currentValue}
            placeholder="Current value..."
            {...register(`${id}.currentValue` as Path<T>)}
          />
        </Box>
      </TableCell>
      <TableCell columnName="futureValue" key="futureValue">
        <Box width="100%">
          <TextInput
            size="small"
            disabled={disabled || !(data && data.dimension)}
            invalid={!!(errors || {}).futureValue}
            placeholder="Future value..."
            {...register(`${id}.futureValue` as Path<T>)}
          />
        </Box>
      </TableCell>
      {!disabled && !noRemove && (
        <TableCell columnName="delete">
          <Button
            iconOnly={true}
            tone="ghost"
            icon={DeleteIcon}
            label="Remove translation rule"
            onClick={remove}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default TranslationRule;
