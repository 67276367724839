import { Dispatch } from 'redux';
import { AppState } from '../redux';
import { ColumnMeta, ColumnMetaXCompact, ColumnMetaYCompact, ColumnXDimensionType } from '../types/columnMetaModel';
import { storeColumnMetas } from '../actions/columnMeta';
import { removeDimension } from '../actions/dimension';
import { removeMeasure } from '../actions/measure';
import { removeJoinDimension, removeJoinMeasure } from '../actions/joinColumn';
import { GroupBy } from '../types/groupByModel';
import { View } from '../types/viewModel';
import { removeGroupBy } from '../actions/groupBy';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { Join } from '../types/joinModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES, DIMENSION_TYPE_NUMBER, SOURCE_REPORT_HEADER } from '../constants';
import { findIndex } from 'lodash';
import { ReportHeader } from '../types/reportHeaderModel';
import { isValidUuid } from '../utils';

// Based on the app state, we can determine additional information to associate with columns
// eg ordering, what View and Dimension it represents, what Join was used,
// and then save this information to the state
export function saveColumnMeta(columnMeta: ColumnMeta[]):
  (dispatch: Dispatch<any>, getState: () => AppState) => void {
  return (dispatch) => {
    dispatch(storeColumnMetas(columnMeta));
  };
}

export function getColumnMetaLabels(columnMeta: ColumnMeta[]): string[] {
  return columnMeta.map((cm: ColumnMeta) => {
    if (cm.dimension) {
      return cm.dimension.label;
    } else if (cm.measure) {
      return cm.measure.label;
    } else {
      return '';
    }
  });
}

export function getColumnMetaWithLabels(columnMeta: ColumnMeta[], headers: Array<ReportHeader> = []): ColumnMeta[] {
  const headersWithoutCustomColumns = headers.filter(header => !isValidUuid(header.dimensionName));

  /**
   * NOTE: We expect headers[index] to correspond with columnMeta[index] but due to the possibility of deprecated
   * fields, the headers we receive might have shorter length than our columnMeta. To account for this, we use an
   * offset.
   */
  let offset = 0;

  return columnMeta.map((cm: ColumnMeta, index) => {
    // TODO: fix the unwanted mutations to cm, perform regression afterwards for direct usages of displayLabel
    if (cm.dimension) {
      cm.label = cm.dimension.label;
    } else if (cm.measure) {
      cm.label = cm.measure.label;
    }

    cm.displayLabel = cm.label;
    // add display label
    if (cm.join) {
      cm.displayLabel = cm.join.label + ' / ' + cm.label;
    } else {
      cm.displayLabel = cm.view.label + ' / ' + cm.label;
    }

    // NOTE: If dimension is deprecated, it will not be part of the headers
    if (cm.dimension?.deprecated) {
      offset++;
      return cm;
    }

    // assuming that headersWithoutCustomColumns[index] corresponds to current columnMeta
    const correspondingHeader = headersWithoutCustomColumns[index - offset];

    if (!correspondingHeader) {
      return cm;
    }

    const { alias, ...filteredColumnMeta } = cm;
    filteredColumnMeta.displayLabel = correspondingHeader.originalName;

    if (correspondingHeader.originalName === correspondingHeader.displayName) {
      return filteredColumnMeta;
    }

    return {
      ...filteredColumnMeta,
      alias: correspondingHeader.displayName
    };
  });
}

export function isDuplicated(values: Array<string|number>, value: string|number): boolean {
  return (values.indexOf(value) !== -1
    && values.indexOf(value) !== values.lastIndexOf(value));
}

export function getColumnMetaByLabel(columnMeta: ColumnMeta[], label: string): ColumnMeta | undefined {
  return columnMeta.find((cm) => {
    return cm.label === label;
  });
}

export function getColumnMetaWithLabelAndCellDataIndex(columnMeta: ColumnMeta[]): ColumnMeta[] {
  // For duplicate column labels, the response comes back as an array so we need to know which index to use
  // Store this index with the ColumnMeta
  let labelToIndex: any = [];
  return getColumnMetaWithLabels(columnMeta)
    .map((cm: ColumnMeta) => {
      let label = (cm.label) ? cm.label : 'Untitled';
      let val = labelToIndex[label] !== undefined ? labelToIndex[label] + 1 : 0;
      labelToIndex[label] = val;

      // Store inside ColumnMeta for usage later in renderCell
      cm.cellDataIndex = val;

      return cm;
    });
}

export function getColumnMetaForGroupBy(groupBy: GroupBy,
                                        baseView: View): ColumnMeta {
  return {
    view: baseView,
    join: groupBy.join,
    dimension: groupBy.dimension,
    forGroupBy: true
  };
}

export function getColumnMetaForDimension(dimension: Dimension,
                                          view: View,
                                          join?: Join): ColumnMeta {
  return {
    view: view,
    dimension: dimension,
    join: join
  };
}

export function getColumnMetaForMeasure(measure: Measure,
                                        view: View,
                                        join?: Join): ColumnMeta {
  return {
    view: view,
    measure: measure,
    join: join
  };
}

export function columnMetaFindIndex(columnMetas: ColumnMeta[], toFind: ColumnMeta) {
  return findIndex(columnMetas, (columnMeta: ColumnMeta) => {
    return columnMetaEqual(columnMeta, toFind);
  });
}

/**
 * Are two ColumnMetas equal
 * @param {ColumnMeta} c1
 * @param {ColumnMeta} c2
 * @returns {boolean}
 */
export function columnMetaEqual(c1: ColumnMeta, c2: ColumnMeta): boolean {
  let equalDimensions: boolean = (!c1.dimension && !c1.dimension) ||
    (!!c1.dimension && !!c2.dimension && c1.dimension.name === c2.dimension.name);
  let equalMeasures: boolean = (!c1.measure && !c2.measure) ||
    (!!c1.measure && !!c2.measure && c1.measure.name === c2.measure.name);
  let equalViews: boolean = c1.view.name === c2.view.name;
  let equalJoins: boolean = (!c1.join && !c2.join) ||
    (!!c1.join && !!c2.join && Join.equals(c1.join, c2.join));

  return equalDimensions &&
    equalViews &&
    equalJoins &&
    equalMeasures &&
    (c1.forGroupBy === c2.forGroupBy);
}

export function removeColumnByColumnMeta(columnMeta: ColumnMeta):
(dispatch: Dispatch<any>, getState: () => AppState) => void {
  return (dispatch, getState) => {
    let appState: AppState = getState();

    if (columnMeta.forGroupBy) {
      let foundGroupBy = appState.groupBys.find((groupBy: GroupBy) => {
        return columnMetaEqual(getColumnMetaForGroupBy(groupBy, appState.view), columnMeta);
      });

      if (foundGroupBy) {
        dispatch(removeGroupBy(foundGroupBy, appState.view, SOURCE_REPORT_HEADER));
      }
    } else if (columnMeta.join && columnMeta.dimension) {
      // if removing a join dimension
      dispatch(removeJoinDimension(columnMeta.join, columnMeta.dimension, columnMeta.view, SOURCE_REPORT_HEADER));
    } else if (columnMeta.join && columnMeta.measure) {
      // if removing a join measure
      dispatch(removeJoinMeasure(columnMeta.join, columnMeta.measure, columnMeta.view, SOURCE_REPORT_HEADER));
    } else if (columnMeta.dimension) {
      // if removing a dimension
      dispatch(removeDimension(columnMeta.dimension, columnMeta.view, SOURCE_REPORT_HEADER));
    } else if (columnMeta.measure) {
      // if removing a measure
      dispatch(removeMeasure(columnMeta.measure, columnMeta.view, SOURCE_REPORT_HEADER));
    }
  };
}

// Used to get the selected column for charts
export function columnMetaXCompactToColumnMeta(compact: ColumnMetaXCompact, columnMetas: ColumnMeta[])
  : ColumnMeta[] | undefined {
  const isColMetaDimension = (colMeta: ColumnMeta, dimension: Partial<ColumnXDimensionType>) => (
    typeof colMeta.dimension === 'undefined' ||
      Boolean(dimension.dimensionName === colMeta.dimension.name)
  );

  const isColMetaMeasure = (colMeta: ColumnMeta, dimension: Partial<ColumnXDimensionType>) => (
    typeof colMeta.measure === 'undefined' ||
      Boolean(
        dimension.measureName && dimension.measureName === colMeta.measure.name
      )
  );
  const isColMetaJoin = (colMeta: ColumnMeta, dimension: Partial<ColumnXDimensionType>) => (
    typeof colMeta.join === 'undefined' ||
      Boolean(dimension && dimension.joinName === colMeta.join.fullJoinName)
  );
  const isColMetaViewName = (colMeta: ColumnMeta, dimension: Partial<ColumnXDimensionType>) => (
    Boolean(dimension && dimension.viewName === colMeta.view.name)
  );

  const filteredColumnMetas: ColumnMeta[] = [];

  const findDimensionRelatedColumnMeta = (dimension?: Partial<ColumnXDimensionType>) => {
    if (!dimension) { return; }

    const foundColMeta = columnMetas.find((colMeta: ColumnMeta) => {
      return isColMetaDimension(colMeta, dimension) &&
        isColMetaMeasure(colMeta, dimension) &&
        isColMetaJoin(colMeta, dimension) &&
        isColMetaViewName(colMeta, dimension);
    });

    if (foundColMeta) {
      filteredColumnMetas.push(foundColMeta);
    }

    findDimensionRelatedColumnMeta(dimension.dimension);
  };

  findDimensionRelatedColumnMeta(compact.dimension);

  return filteredColumnMetas;
}

// Used to get the selected column for charts
export function columnMetaYCompactToColumnMeta(compact: ColumnMetaYCompact, columnMetas: ColumnMeta[])
  : ColumnMeta | undefined {
  return columnMetas.find(function(colMeta: ColumnMeta) {
    return (typeof colMeta.dimension === 'undefined' || compact.dimensionName === colMeta.dimension.name)
      && (typeof colMeta.measure === 'undefined' || compact.measureName === colMeta.measure.name)
      && (typeof colMeta.join === 'undefined' || compact.joinName === colMeta.join.fullJoinName)
      && (compact.viewName === colMeta.view.name)
    ;
  });
}

const processColumnMetaDimension = (columnMeta: ColumnMeta, withMeasure: boolean = false) => {
  let dimension = undefined;
  dimension = { viewName: columnMeta.view.name };
  if (columnMeta.dimension) {
    dimension = { ...dimension, dimensionName: columnMeta.dimension.name };
  }
  if (columnMeta.join) {
    dimension = { ...dimension, joinName: columnMeta.join.fullJoinName };
  }
  if (columnMeta.measure && withMeasure) {
    dimension = { ...dimension, measureName: columnMeta.measure.name };
  }
  return dimension;
};

export const columnMetaToColumnMetaXCompact = (columnMetas: Array<ColumnMeta>) => {
  let colMetaCompact = new ColumnMetaXCompact();
  columnMetas.forEach((columnMeta, i) => {
    if (i === 0) {
      const dimension = processColumnMetaDimension(columnMeta, true);
      colMetaCompact.dimension = dimension;
    } else {
      const dimension = processColumnMetaDimension(columnMeta, false);
      colMetaCompact.dimension = { ...colMetaCompact.dimension, dimension };
    }
  });

  return colMetaCompact;
};

export function columnMetaToColumnMetaYCompact(columnMeta: ColumnMeta) {
  let colMetaCompact = new ColumnMetaYCompact();
  colMetaCompact.viewName = columnMeta.view.name;
  if (columnMeta.dimension) {
    colMetaCompact.dimensionName = columnMeta.dimension.name;
  }
  if (columnMeta.join) {
    colMetaCompact.joinName = columnMeta.join.fullJoinName;
  }
  if (columnMeta.measure) {
    colMetaCompact.measureName = columnMeta.measure.name;
  }

  return colMetaCompact;
}

// This is used for Charts when selecting X axis and Y axis
// For Aggregate data X axis: Get the ColumnMeta which is used to GroupBy
// For Aggregate data Y axis: Get the ColumnMeta which is not used in the GroupBy (ie the aggregated data)
// For Individual data X axis: Get the ColumnMeta with Dimensions which can be grouped
// For Individual data Y axis: Get the ColumnMeta with numeric Dimensions (ie the aggregated data)
export function colMetaOptions(
  columnMeta: ColumnMeta[],
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES,
  groupBy: boolean
) {
  let colMetas;
  if (dataType === DATA_TYPE_DIMENSIONS && !groupBy) {
    colMetas = columnMetaForNumericDimension(columnMeta);
  } else if (dataType === DATA_TYPE_DIMENSIONS) {
    colMetas = columnMetaForGroupableDimension(columnMeta, groupBy);
  } else {
    colMetas = columnMetaForGroupBy(columnMeta, groupBy);
  }
  return colMetas;
}

export function columnMetaForGroupableDimension(columnMeta: ColumnMeta[], groupable: boolean) {
  return columnMeta.filter(function (colMeta: ColumnMeta) {
    if (colMeta.dimension) {
      return colMeta.dimension.groupable === groupable;
    }
    return false;
  });
}

export function columnMetaForNumericDimension(columnMeta: ColumnMeta[]) {
  return columnMeta.filter(function (colMeta: ColumnMeta) {
    if (colMeta.dimension) {
      return colMeta.dimension.type === DIMENSION_TYPE_NUMBER;
    }
    return false;
  });
}

export function columnMetaForGroupBy(columnMeta: ColumnMeta[], forGroupBy: boolean) {
  return columnMeta.filter(function (colMeta: ColumnMeta) {
    if (forGroupBy) {
      return colMeta.forGroupBy === forGroupBy;
    } else {
      return (typeof colMeta.forGroupBy === 'undefined');
    }

  });
}
