'use client';

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { noop } from 'lodash';
import { withErrorBoundary } from 'react-error-boundary';
import { DeprecationContextProvider } from 'src/features/report-builder';

import { AppState, JoinAndDimensions, JoinAndMeasures } from '../../redux/index';
import { Module } from '../../types/moduleModel';
import { getReportJson, loadQuery } from '../../actions/report';
import { ReportingToolPage as ReportingToolPageComponent } from '../../components/NewReportBuilder/ReportingToolPage';
import { SavedQuery } from '../../types/savedQueryModel';
import { Dimension } from '../../types/dimensionModel';
import { Measure } from '../../types/measureModel';
import { resetReportAction } from '../../actions/reportBuilder';
import {
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES,
  FETCHED_REPORT_JOBID,
  GENERATE_ACTION,
  GENERATE_REPORT_CANCELED
} from '../../constants';
import { View } from '../../types/viewModel';
import { SuggestedReportState } from '../../redux';
import { ReportTable } from '../../types/reportTableModel';
import { Chart } from '../../types/chartModel';
import {
  ReportViewOnlyPage as ReportViewOnlyPageComponent
} from '../../components/NewReportBuilder/ReportViewOnlyPage';
import { ReportPrintPage as ReportPrintPageComponent } from '../../components/NewReportBuilder/ReportPrintPage';
import SavedReportLoading from '../../components/NewReportBuilder/ReportBuilder/SavedReportLoading/SavedReportLoading';
import { AppMenu } from '../../types/appMenuModel';
import {
  apiGetUserCapabilitiesForReport,
  UserCapabilitiesType,
  defaultUserCapabilities
} from '../../services/reportService';
import { AuthService } from '../../services/authService';
import ReportErrorBoundary from '../../components/ReportErrorBoundary';

interface ReportingToolPageProps {
  authService: AuthService;
  dispatch: Dispatch<any>;
  module: Module;
  query: string;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  selectedDimensions: Dimension[];
  selectedMeasures: Measure[];
  joinDimensions: JoinAndDimensions[];
  joinMeasures: JoinAndMeasures[];
  selectedView: View;
  report: ReportTable;
  suggestedReports: SuggestedReportState;
  generateReportStatus: GENERATE_ACTION;
  chart: Chart;
  readOnly: boolean;
  queryId: string;
  printView?: boolean;
  savedQuery?: SavedQuery;
  appMenu: AppMenu;
  reportTitle: string;
}

interface ReportingToolPageState {
  loading: boolean;
  userCapabilities: UserCapabilitiesType;
}

const mapStateToProps = (state: AppState) => ({
  authService: state.authService,
  module: state.module,
  dataType: state.dataType,
  query: state.query,
  selectedDimensions: state.dimensions,
  selectedMeasures: state.measures,
  joinDimensions: state.joinDimensions,
  joinMeasures: state.joinMeasures,
  selectedView: state.view,
  report: state.report,
  reportTitle: state.reportTitle,
  suggestedReports: state.suggestedReports,
  generateReportStatus: state.generateReportStatus,
  chart: state.chart,
  savedQuery: state.savedQuery,
  appMenu: state.appMenu,
});

class ReportingToolPageContainer extends React.Component<ReportingToolPageProps, ReportingToolPageState> {
  constructor(props: ReportingToolPageProps) {
    super(props);

    this.state = {
      loading: false,
      userCapabilities: defaultUserCapabilities,
    };
  }

  async componentDidMount() {
    if (this.props.queryId) {
      await this.loadQueryAndGenerateReport(this.props.queryId);
    } else {
      // reset the redux state
      this.props.dispatch(resetReportAction());
    }

    const user = this.props.authService.getUserData();

    try {
      if (user) {
        const userCapabilities = await apiGetUserCapabilitiesForReport(user.id);
        this.setState({ userCapabilities, loading: true });
      }
    } finally {
      this.onRequestFinally();
    }
  }

  componentWillUnmount() {
    this.onRequestFinally = noop;
  }

  async loadQueryAndGenerateReport(reportId: string) {
    this.setState({
      loading: true
    });

    try {
      await this.props.dispatch(loadQuery(reportId));
      await this.props.dispatch(getReportJson(false, reportId));
    } finally {
      this.onRequestFinally();
    }
  }

  onRequestFinally = () => {
    this.setState({ loading: false });
  }

  render() {
    const { query, queryId, readOnly, printView } = this.props;
    const showLoading = this.state.loading;

    return (
      <div className="new-report-builder">
        {showLoading &&
          <SavedReportLoading
            queryId={queryId}
            showCancel={
              this.props.generateReportStatus === FETCHED_REPORT_JOBID ||
              this.props.generateReportStatus === GENERATE_REPORT_CANCELED
            }
            showExport={!!query && !!queryId}
            isOpened={showLoading}
          />
        }
        <div className={(showLoading ? 'd-none' : '')}>
          {readOnly && !printView && this.renderReadOnly()}
          {!readOnly && !printView && this.renderReportTool()}
          {printView && this.renderPrintView()}
        </div>
      </div>
    );
  }

  renderReportTool() {
    return (
      <DeprecationContextProvider>
        <ReportingToolPageComponent
          loadedQuery={this.props.savedQuery}
          encodedQuery={this.props.query}
          selectedView={this.props.selectedView}
          suggestionsOpen={this.props.suggestedReports.isOpen}
          generateReportStatus={this.props.generateReportStatus}
          reportResult={this.props.report}
          chart={this.props.chart}
          queryId={this.props.queryId}
          appMenu={this.props.appMenu}
          reportTitle={this.props.reportTitle}
          userCapabilities={this.state.userCapabilities}
          selectedDimensions={this.props.selectedDimensions}
        />
      </DeprecationContextProvider>
    );
  }

  renderReadOnly() {
    return (
      <ReportViewOnlyPageComponent
        loadedQuery={this.props.savedQuery}
        encodedQuery={this.props.query}
        selectedView={this.props.selectedView}
        suggestionsOpen={false}
        generateReportStatus={this.props.generateReportStatus}
        reportResult={this.props.report}
        chart={this.props.chart}
        queryId={this.props.queryId}
        reportTitle={this.props.reportTitle}
      />
    );
  }

  renderPrintView() {
    return (
      <ReportPrintPageComponent
        loadedQuery={this.props.savedQuery}
        encodedQuery={this.props.query}
        selectedView={this.props.selectedView}
        suggestionsOpen={false}
        generateReportStatus={this.props.generateReportStatus}
        reportResult={this.props.report}
        chart={this.props.chart}
      />
    );
  }
}

const ReportingToolPage = connect(
  mapStateToProps,
)(ReportingToolPageContainer);

export default withErrorBoundary(ReportingToolPage, {
  fallback: <ReportErrorBoundary />
});