import * as React from 'react';
import { Radio, RadioButtons } from 'elmo-elements';
import { SCHEDULE_DISPATCH_TYPE_OUTBOUND, SCHEDULE_DISPATCH_TYPE_USERS } from '../../../constants/addSchedule';

type Props = {
  isOutbound: boolean;
  onChange: (value: any)  => void;
};

const ScheduleDispatchOption = [
  SCHEDULE_DISPATCH_TYPE_USERS,
  SCHEDULE_DISPATCH_TYPE_OUTBOUND
];

function OutboundToggle(props: Props) {
  const {
    onChange,
    isOutbound
  } = props;

  const selected = isOutbound ? SCHEDULE_DISPATCH_TYPE_OUTBOUND : SCHEDULE_DISPATCH_TYPE_USERS;

  return (
    <RadioButtons
      type="solid"
      selected={selected}
      onChange={onChange}
      id="schedule-outbound-toggle"
    >
      {ScheduleDispatchOption.map((label: string, key: number) => {
        return (
          <Radio
            value={label}
            key={key}
          >
            {label}
          </Radio>
        );
      })}
    </RadioButtons>
  );
}

export default OutboundToggle;