import React from 'react';

export const ReportIllustration = () => (
  <svg
    width={156}
    height={65}
    viewBox="0 0 156 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="0.5" x2="156" y2="0.5" stroke="black" strokeOpacity="0.14" />
    <line y1="64.5" x2="156" y2="64.5" stroke="black" strokeOpacity="0.14" />
    <rect x="8" y="9" width="148" height="12" fill="black" fillOpacity="0.14" />
    <rect x="8" y="33" width="148" height="12" fill="black" fillOpacity="0.14" />
    <rect x="8" y="53" width="148" height="11" fill="black" fillOpacity="0.14" />
  </svg>
);
