import { SET_CUSTOM_COLUMNS } from '../constants/customColumns';
import { CustomColumnsAction } from '../actions/customColumn';
import { CustomColumn } from '../types/customColumnModel';

export function customColumnsReducer(state: Array<CustomColumn> = [], action: CustomColumnsAction) {
  switch (action.type) {
    case SET_CUSTOM_COLUMNS:
      return action.customColumns.map(customColumn => ({ ...customColumn }));
    default:
      return state;
  }
}
