import { PaginationComponentDataType } from '.';
import { FormsPaginationDataType } from '../../../types/forms';

export const IS_SELECT_FORM_ALERT_CLOSED = 'isSelectFormAlertClosed';

export const getIsSelectFormAlertClosed = () => {
  return !!sessionStorage.getItem(IS_SELECT_FORM_ALERT_CLOSED);
};

export const mapPaginationDataToRequestParams = (
  paginationData: PaginationComponentDataType
): FormsPaginationDataType => {
  return {
    offset: (paginationData.currentPage - 1) * paginationData.pageSize,
    limit: paginationData.pageSize,
  };
};
