import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';
import { useNotificationHandler, useNotifications } from './generic-toast-notification.hooks';

export const GenericToastNotification = () => {
  const [errorMessage, setErrorMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();
  const [dashboardItemAdded, setDashboardItemAdded] = useState({ dashboardId: '', message: toastMessage('') });

  const notifications = useNotifications();
  const handleNotification = useNotificationHandler();

  useEffect(() => {
    if (notifications.length === 0) return;

    notifications.forEach(({ dashboardId, id, text, type }) => {
      if (type === 'NOTIFICATION_ERROR' && text) {
        setErrorMessage(toastMessage(text));
      }

      if (type === 'NOTIFICATION_SUCCESS' && text) {
        setSuccessMessage(toastMessage(text));
      }

      if (type === 'NOTIFICATION_ADD_TO_DASHBOARD_SUCCESS') {
        setDashboardItemAdded({ dashboardId, message: toastMessage('Added to dashboard') });
      }

      handleNotification(id);
    });
  }, [notifications]);

  return (
    <Box>
      {errorMessage && <Toast message={errorMessage} tone="critical" />}
      {successMessage && <Toast message={successMessage} tone="positive" />}
      {dashboardItemAdded.dashboardId && (
        <Toast
          message={dashboardItemAdded.message}
          tone="positive"
          linkText="Go to dashboard"
          linkHref={`/dashboards/${dashboardItemAdded.dashboardId}`}
        />
      )}
    </Box>
  );
};
