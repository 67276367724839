import * as React from 'react';
import { SuggestedReportsPopoverRefHandler } from 'src/containers/NewReportBuilder/SuggestedReportsPopoverContext';

function useOnClickOutsideSuggestedReportsPopover<T extends HTMLElement>(
  popoverRef: React.RefObject<SuggestedReportsPopoverRefHandler>,
  treeNodeRef: React.RefObject<HTMLDivElement>,
  handler: () => void
) {
  React.useEffect(
    () => {
      const listener = (event: Event & { target: T }) => {
        if (!popoverRef.current) {
          return;
        }
        
        const { buttonRef, contentRef } = popoverRef.current;
        
        if (!buttonRef.current || !contentRef.current) {
          return;
        }
        
        // Do nothing if clicking ref's element or descendent elements
        if (
          buttonRef.current.contains(event.target) ||
          contentRef.current.contains(event.target) ||
          (treeNodeRef.current && treeNodeRef.current.contains(event.target))
        ) {
          return;
        }

        handler();
      };

      document.addEventListener('click', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('click', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new function on every render that will cause this ... 
    // ... effect callback/cleanup to run every render. It's not a big deal but to optimize you can wrap handler in ...
    // ... useCallback before passing it into this hook.
    [popoverRef, treeNodeRef, handler]
  );
}

export default useOnClickOutsideSuggestedReportsPopover;
