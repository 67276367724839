import React from 'react';

export const PieChartIllustration = () => (
  <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.9711 43.8568C39.6519 42.9028 43.9807 37.9573 43.9807 31.9999C43.9807 26.0425 39.6519 21.097 33.9711
          20.143V0C50.7287 1.03435 64 14.9658 64 32C64 49.0342 50.7287 62.9657 33.9711 64V43.8568Z"
      fill="#6280BA"
    />
    <path
      d="M0 34.0038C0.992503 50.1148 13.8718 63.0065 29.9673 64.0001V43.8568C24.9374 43.0122 20.9676 39.0385
          20.1237 34.0038H0Z"
      fill="#A8CDF0"
    />
    <path
      d="M0 29.996C0.992613 13.8851 13.8718 0.993539 29.9673 0V20.143C24.9374 20.9877 20.9675 24.9613 20.1237
          29.996L0 29.996Z"
      fill="#9B72D0"
    />
  </svg>
);
