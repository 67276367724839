import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const InfoOutlinedIcon = ({ className, width = 24, height = 24 }: IconProps) => {
  return (
    <svg 
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M11.2734 7H13.2734V9H11.2734V7ZM11.2734 11H13.2734V17H11.2734V11ZM12.2734 2C6.75344 2 2.27344 6.48 2.27344 
        12C2.27344 17.52 6.75344 22 12.2734 22C17.7934 22 22.2734 17.52 22.2734 12C22.2734 6.48 17.7934 2 12.2734 
        2ZM12.2734 20C7.86344 20 4.27344 16.41 4.27344 12C4.27344 7.59 7.86344 4 12.2734 4C16.6834 4 20.2734 7.59 
        20.2734 12C20.2734 16.41 16.6834 20 12.2734 20Z" 
      />
    </svg>
  );
};
