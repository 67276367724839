import * as React from 'react';
import SelectAllButton from '../SelectAllButton';
import { map } from 'lodash';
import ColumnSelectFilter from '../ColumnSelectFilter';
import './style.css';

export interface ColumnSelectItemGroup {
  label: string;
  items: ColumnSelectItem[];
}

export interface ColumnSelectItem {
  isDeprecated?: boolean;
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

export interface ColumnSelectProps {
  title: string;
  isAggregate: boolean;
  itemGroups: ColumnSelectItemGroup[];
  onSelectAllClick: () => void;
  allSelected: boolean;
}

interface ColumnSelectState {
  filterText: string;
}

export class ColumnSelect extends React.Component<ColumnSelectProps, ColumnSelectState> {

  constructor(props: ColumnSelectProps) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      filterText: ''
    };
  }

  renderItems(items: ColumnSelectItem[]) {
    return map(items, (item: ColumnSelectItem, key: number) => {
      if (!item.label.trim().toLowerCase().includes(this.state.filterText)) {
        return null;
      }

      let selectedClass: string = item.isSelected ? ' selected ' : '';
      return (
        <div
          className={'item d-flex flex-row ' + selectedClass}
          key={key}
          onClick={item.onClick}
          aria-label={'Select ' + item.label}
          role="checkbox"
          aria-checked={item.isSelected}
          data-testid={'column-select-' + key}
        >
          <div className="icon">{item.isSelected && <i className="fa fa-check" />}</div> {item.label}
        </div>
      );
    });
  }

  renderItemGroups() {
    return map(this.props.itemGroups, (itemGroup: ColumnSelectItemGroup, key: number) => {
      return (
        <div className="item-group" key={key} role="listbox" data-testid={'item-group-' + key}>
          {itemGroup.label &&
            <div className="title">
              {itemGroup.label}
            </div>
          }
          {
            this.renderItems(itemGroup.items)
          }
        </div>
      );
    });
  }

  onFilterUpdate = (text: string) => {
    this.setState({
      filterText: text.trim().toLowerCase()
    });
  }

  render() {
    return (
      <div className="column-select">
        <div className="header">
          <SelectAllButton
            onClick={this.props.onSelectAllClick}
            active={this.props.allSelected}
          />
          <div
            className="title"
            role="heading"
          >
            {this.props.title} {this.props.isAggregate}
          </div>
        </div>
        <div className="filter">
          <ColumnSelectFilter
            onFilterUpdate={this.onFilterUpdate}
          />
        </div>
        {
          this.renderItemGroups()
        }
      </div>
    );
  }
}