import { string, object } from 'yup';

export const NOTIFICATION_RECIPIENT_SCHEMA_USER = object().shape({
  recipientType: string().required(),
  uuid: string().uuid().required('Please select users.'),
});

export const NOTIFICATION_RECIPIENT_SCHEMA_EMAIL = object().shape({
  recipientType: string().required(),
  email: string().email('Please enter a valid email'),
});