import { BaseModel, NestedEntityMap } from './baseModel';
import { ReportCell } from './reportCellModel';

export class ReportRow extends BaseModel {
  cells: ReportCell[] = [];

  protected nestedObjects: NestedEntityMap = {
    cells: ReportCell
  };

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}