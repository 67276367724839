import * as React from 'react';
import { BasicModal, withModal } from '../../../shared-components/BasicModal';
import { MEASURE_ON_TABLE } from '../../../services/configurationService';
import { getColumnMeasureTypes, getTableMeasureTypes } from '../../../services/viewService';
import { cloneDeep, map, forEach } from 'lodash';
import { ConfigMeasure, ConfigMeasureItem } from '../../../types/config/configMeasureModel';

interface ConfigAddEditMeasureModalProps {
  measure: ConfigMeasure | null;
  sqlOptions: string[];
  onDone: (configMeasure: ConfigMeasure) => void;
  toggle: () => void;
  isOpen: boolean;
}

interface ConfigAddEditMeasureModalState {
  measure: ConfigMeasure | null;
  measureItems: Map<string, ConfigMeasureItem>;
  editing: boolean;
}

class ConfigAddEditMeasureModal extends
  React.Component<ConfigAddEditMeasureModalProps, ConfigAddEditMeasureModalState> {

  constructor(props: ConfigAddEditMeasureModalProps) {
    super(props);

    this.state = {
      measure: null,
      measureItems: new Map(),
      editing: false
    };

  }

  /**
   * @param {ConfigAddEditMeasureModalProps} prevProps
   */
  componentDidUpdate(prevProps: ConfigAddEditMeasureModalProps) {
    // when the measure to add/edit changes
    if (this.props.measure && this.props.measure !== prevProps.measure) {
      let editing: boolean = true;
      // if there is no "sql" set for the measure, we are creating a new Measure. Use the first sql option
      if (!this.props.measure.sql) {
        this.props.measure.sql = this.props.sqlOptions[0];
        editing = false;
      }

      // create a map of the measure type and measureItem
      let measureItems: Map<string, ConfigMeasureItem> = new Map();
      forEach(this.props.measure.items, (item: ConfigMeasureItem) => {
        measureItems.set(item.type, item);
      });

      this.setState({
        measure: cloneDeep(this.props.measure), // clone the object so the original is not altered.
        measureItems: measureItems,
        editing: editing
      });
    }
  }

  /**
   * Toggle the show/hide state of the modal
   */
  public toggle = () => {
    this.props.toggle();
  }

  /**
   * Called when the ok button is clicked to dismiss the modal
   */
  onDone = () => {
    // should not happen, but if the measure is null, do nothing
    if (!this.state.measure) {
      return;
    }

    let selectedMeasures: ConfigMeasureItem[] = [];

    // get selected measure types
    this.state.measureItems.forEach((value, key) => {
      if (value.selected) {
        selectedMeasures.push(value);
      }
    });

    // create a new Measure object with the updated details
    let result: ConfigMeasure = {
      sql: this.state.measure.sql,
      items: selectedMeasures
    };

    this.props.onDone(result);
    this.toggle();
  }

  /**
   * Called when the measure select is changed
   *
   * @param {string} property
   * @returns {(event: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => undefined}
   */
  handleInputChange = (property: string) => (event: React.FormEvent<HTMLSelectElement>) => {
    if (!this.state.measure) {
      return;
    }

    let measureCopy = this.state.measure;
    measureCopy[property] = event.currentTarget.type === 'checkbox' ?
      event.currentTarget['checked'] : event.currentTarget.value;

    this.setState({
      measure: measureCopy
    });
  }

  /**
   * Called when a measure item is edited
   *
   * @param {ConfigMeasureItem} item
   * @param {string} property
   * @returns {(event: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => undefined}
   */
  handleMeasureInputChange = (item: ConfigMeasureItem, property: string) =>
    (event: React.FormEvent<HTMLSelectElement|HTMLInputElement>) => {
    if (!item) {
      return;
    }

    item[property] = event.currentTarget.type === 'checkbox'  ?
      event.currentTarget['checked'] : event.currentTarget.value;

    let measureItemsCopy = new Map(this.state.measureItems);
    measureItemsCopy.set(item.type, item);

    this.setState({
      measureItems: measureItemsCopy
    });
  }

  /**
   * Render the measure types for the measure
   * @returns {any}
   */
  renderMeasureItems() {
    let measureTypes: string[] = [];
    if (!this.state.measure) {
      return null;
    } else if (this.state.measure.sql === MEASURE_ON_TABLE) {
      measureTypes = getTableMeasureTypes();
    } else {
      measureTypes = getColumnMeasureTypes();
    }

    return map(measureTypes, (typeOption: string, key: number) => {
      // get the item for this data
      let item = this.state.measureItems.get(typeOption);

      if (!item) {
        item = {
          type: typeOption,
          label: '',
          drill_fields: [],
          drill_filter: '',
          name: typeOption,
          selected: false,
          visible: false
        };
      }

      return (
        <div
          className="form-group row"
          key={key}
        >
          <div className="col-1">
            <input
              type="checkbox"
              id={'type_' + typeOption}
              onChange={this.handleMeasureInputChange(item, 'selected')}
              checked={item.selected}
            />
          </div>
          <label htmlFor={'type_' + typeOption} className="col-3">{typeOption}</label>
          <label className="col-1">Label:</label>
          <input
            type="text"
            name="label"
            id="label"
            className="form-control col-3"
            value={item ? item.label : ''}
            onChange={this.handleMeasureInputChange(item, 'label')}
          />
          <label htmlFor="visible" className="col-1">Visible: </label>
          <div className="form-check col-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={item.visible}
              onChange={this.handleMeasureInputChange(item, 'visible')}
              id="visible"
            />
          </div>
        </div>
      );
    });
  }

  renderContent() {
    if (!this.state.measure) {
      return null;
    }

    return (
      <form>
        <div className="form-group">
          <label htmlFor="dimension">Dimension: </label>
          <select
            className="form-control"
            value={this.state.measure.sql}
            onChange={this.handleInputChange('sql')}
            disabled={this.state.editing}
          >
            {map(this.props.sqlOptions, (sqlOption: string, key: number) => {
              return (
                <option key={key} value={sqlOption}>
                  {sqlOption.replace('%alias%.', '')}
                </option>
              );
            })}
          </select>
        </div>
        {this.renderMeasureItems()}
      </form>
    );
  }

  render() {
    return (
      <div className="config-add-measure">
        <BasicModal
          title="Add Measure"
          className="config-add-measure-modal"
          confirmButtonText="OK"
          onConfirmClicked={this.onDone}
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          size="lg"
        >
          {this.renderContent()}
        </BasicModal>
      </div>
    );
  }
}

export default withModal(ConfigAddEditMeasureModal);