import { useEffect, useState } from 'react';
import { ColumnSort } from '@eds/table';
import { PaginationState } from '@eds/pagination';
import { DateTime } from 'luxon';
import { get, orderBy, truncate } from 'lodash';

import { truncateOptions } from 'src/features/common';

import { DownloadData } from './downloads-list.queries';

type FilterData = {
  search: string;
};

type TableColumn = {
  columnName: 'created' | 'expires' | 'report' | 'scheduleName' | 'status';
  format: (value: string) => string;
  label: string;
  key: Exclude<keyof DownloadData, 'id' | 'userId'>;
};

const DISPLAY_DATE_TIME_FORMAT = 'd LLLL yyyy, h:mm';

export const defaultTableColumns: TableColumn[] = [
  { columnName: 'report', format: value => truncate(value, truncateOptions), label: 'Report', key: 'report' },
  {
    columnName: 'created',
    label: 'Created',
    format: value => {
      const created = DateTime.fromISO(value);
      return created.isValid
        ? `${created.toFormat(DISPLAY_DATE_TIME_FORMAT)} ${created.toFormat('a').toLowerCase()}`
        : '-';
    },
    key: 'created',
  },
  {
    columnName: 'expires',
    format: value => {
      const expires = DateTime.fromISO(value);
      return expires.isValid
        ? `${expires.toFormat(DISPLAY_DATE_TIME_FORMAT)} ${expires.toFormat('a').toLowerCase()}`
        : '-';
    },
    label: 'Expires',
    key: 'expires',
  },
  { columnName: 'status', label: 'Status', format: value => value, key: 'status' },
];

export const tableColumnsWithScheduleName: TableColumn[] = [
  {
    columnName: 'scheduleName',
    format: value => truncate(value, truncateOptions),
    label: 'Schedule Name',
    key: 'scheduleName',
  },
  ...defaultTableColumns,
];

const getFilteredItems = (displayScheduleName: boolean, items: DownloadData[], { search }: FilterData) => {
  if (!search) return items;

  return items.filter(({ report, scheduleName }) => {
    if (displayScheduleName)
      return (
        scheduleName.toLowerCase().includes(search.toLowerCase()) || report.toLowerCase().includes(search.toLowerCase())
      );

    return report.toLowerCase().includes(search.toLowerCase());
  });
};

const getPaginatedItems = (
  items: DownloadData[],
  { currentItemsPerPage, rangeStart }: Pick<PaginationState, 'currentItemsPerPage' | 'rangeStart'>
) => items.slice(rangeStart - 1, rangeStart + currentItemsPerPage - 1);

const getSortedItems = (items: DownloadData[], { columnName, sortDirection }: ColumnSort) => {
  if (sortDirection === 'default') {
    return items;
  }

  const tableColumn = tableColumnsWithScheduleName.find(item => item.columnName === columnName);

  if (!tableColumn) {
    return items;
  }

  const sortedItems = orderBy(
    items,
    item => get(item, tableColumn.key, ''),
    sortDirection === 'ascending' ? 'asc' : 'desc'
  );

  return sortedItems;
};

export const useDownloadsList = (displayScheduleName: boolean, loading: boolean, downloadsData?: DownloadData[]) => {
  const [downloads, setDownloads] = useState<DownloadData[]>([]);
  const [filterData, setFilterData] = useState<FilterData>({ search: '' });
  const [paginationData, setPaginationData] = useState<
    Pick<PaginationState, 'currentPage' | 'currentItemsPerPage' | 'rangeStart'>
  >({
    currentPage: 1,
    currentItemsPerPage: 10,
    rangeStart: 1,
  });
  const [sortData, setSortData] = useState<ColumnSort>({ columnName: '', sortDirection: 'default' });
  const [totalItems, setTotalItems] = useState(0);

  const processDownloads = ({
    filter,
    pagination,
    sort,
  }: Partial<{ filter: FilterData; pagination: PaginationState; sort: ColumnSort }> = {}) => {
    const filteredItems = getFilteredItems(displayScheduleName, downloadsData || [], filter || filterData);
    const sortedItems = getSortedItems(filteredItems, sort || sortData);
    const paginatedItems = getPaginatedItems(sortedItems, pagination || paginationData);

    setDownloads(paginatedItems);
    setTotalItems(filteredItems.length);
  };

  const onPageUpdate = (newPaginationData: PaginationState) => {
    setPaginationData(newPaginationData);
    processDownloads({ pagination: newPaginationData });
  };

  const onSearch = (value: string) => {
    setFilterData({ search: value });
    processDownloads({ filter: { search: value } });
  };

  const onSort = (sort: ColumnSort) => {
    setSortData(sort);
    processDownloads({ sort });
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    processDownloads();
  }, [loading, downloadsData]);

  return {
    downloads,
    onPageUpdate,
    onSearch,
    onSort,
    paginationData,
    search: filterData.search,
    sortData,
    totalItems,
  };
};
