import React, { ComponentProps, PropsWithChildren } from 'react';
import { Radio as EDSRadio } from '@eds/radio';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type RadioProps<TFieldValues extends FieldValues> = Omit<ComponentProps<typeof EDSRadio>, 'checked' | 'onChange'> &
  UseControllerProps<TFieldValues>;

export const Radio = <TFieldValues extends FieldValues>({
  children,
  control,
  name,
  ...props
}: PropsWithChildren<RadioProps<TFieldValues>>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <EDSRadio onChange={onChange} checked={props.value === value} {...props}>
      {children}
    </EDSRadio>
  );
};
