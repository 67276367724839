import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { getUserUuid } from 'src/features/utils';
import { dashboardsKeys } from 'src/features/dashboards';

type ShareDashboardUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string | null;
  role: string;
};

export type ShareDashboardUserOption = {
  avatar: string;
  email: string;
  id: string;
  label: string;
  role: string;
  value: string;
};

type ShareDashboardUsersParam = {
  ids?: string[];
  text?: string;
};

export const shareDashboardKeys = {
  all: ['share-dashboard'] as const,
  dashboards: () => [...shareDashboardKeys.all, 'dashboard'] as const,
  dashboard: (id: string) => [...shareDashboardKeys.dashboards(), { id }] as const,
  users: (filters: ShareDashboardUsersParam) => [...shareDashboardKeys.all, 'users', { filters }] as const,
};

const fetchShareDashboardDetails = async (id: string) => {
  const response = await axios.get<{ sharedWith: string[] }>(`/api/share-dashboard/${id}`);
  return response.data;
};

const fetchShareDashboardUsers = async (params?: ShareDashboardUsersParam) => {
  const response = await axios.get<ShareDashboardUser[]>('/api/share-dashboard/users', { params });
  return response.data;
};

const updateDashboardSharing = async (id: string, sharedWith: string[]) => {
  const response = await axios.post(`/api/share-dashboard/${id}`, { sharedWith });
  return response.data;
};

const userRoles = {
  ROLE_CRT_SUPER_ADMIN: 'ELMO Super Admin',
  ROLE_SUPER: 'Company Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_EMPLOYEE: 'Employee',
};

export const useShareDashboardDetailsQuery = (id: string) =>
  useQuery({
    enabled: !!id,
    queryKey: shareDashboardKeys.dashboard(id),
    queryFn: () => fetchShareDashboardDetails(id),
  });

export const useShareDashboardUsersQuery = (enabled: boolean, shareDashboardUsersParam: ShareDashboardUsersParam) =>
  useQuery({
    enabled,
    queryKey: shareDashboardKeys.users(shareDashboardUsersParam),
    queryFn: () => fetchShareDashboardUsers(shareDashboardUsersParam),
    select: data =>
      data
        .map<ShareDashboardUserOption>(user => ({
          avatar: user.profileImage || '',
          email: user.email,
          id: user.id,
          label: `${user.firstName} ${user.lastName}`.trim(),
          role: userRoles[user.role],
          value: user.id,
        }))
        .filter(({ id }) => id !== getUserUuid()),
  });

export const useShareDashboardMutation = (callback: {
  onError: () => void;
  onSettled: () => void;
  onSuccess: () => void;
}) => {
  const queryClient = useQueryClient();
  const dashboardListsQuery = queryClient.getQueryCache().findAll(dashboardsKeys.lists(), { type: 'active' }).shift();
  const shareDashboardQuery = queryClient
    .getQueryCache()
    .findAll(shareDashboardKeys.dashboards(), { type: 'active' })
    .shift();

  return useMutation<unknown, unknown, { id: string; targetUsers: string[] }>({
    mutationFn: ({ id, targetUsers }) => {
      return updateDashboardSharing(id, targetUsers);
    },
    onError: callback.onError,
    onSettled: () => {
      dashboardListsQuery && queryClient.invalidateQueries({ queryKey: dashboardListsQuery.queryKey });
      callback.onSettled();
    },
    onSuccess: () => {
      shareDashboardQuery && queryClient.refetchQueries({ queryKey: shareDashboardQuery.queryKey });
      callback.onSuccess();
    },
  });
};
