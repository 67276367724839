import * as React from 'react';
import { forwardRef, MouseEventHandler, useImperativeHandle, useRef } from 'react';
import Popover from 'reactstrap/lib/Popover';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import classnames from 'classnames';

import { SuggestedReportState } from '../../../redux';
import { SavedQuery } from '../../../types/savedQueryModel';
import { LightBulbIcon } from '../Icons/LightBulbIcon';
import SuggestedReportsContentComponent from '../SuggestedReportsContent';
import { SuggestedReportsPopoverRefHandler } from '../../../containers/NewReportBuilder/SuggestedReportsPopoverContext';
import { CloseIcon } from 'elmo-elements';
import ElmoButton from '../ElmoButton';
import { LocalStorageService } from '../../../services/localStorageService';
import { SUGGESTED_REPORT_CLICKED } from '../../../constants';

import './style.css';

export interface SuggestedReportsPopoverComponentProps {
  suggestedReports: SuggestedReportState;
  onSuggestionClick: (suggestion: SavedQuery) => void;
  toggle: (event: any) => void;
  loading: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  contentRef?: React.RefObject<HTMLDivElement>;
}

const SuggestedReportsPopoverComponent = (props: SuggestedReportsPopoverComponentProps) => {
  const { buttonRef, contentRef, loading, suggestedReports } = props;

  const onSuggestionClick = (suggestion: SavedQuery) => {
    props.onSuggestionClick(suggestion);
  };

  const toggle: MouseEventHandler<HTMLButtonElement> = event => {
    const hasClickedSuggestedReport: boolean = LocalStorageService.getItem(SUGGESTED_REPORT_CLICKED);

    if (!hasClickedSuggestedReport) {
      LocalStorageService.setItem(SUGGESTED_REPORT_CLICKED, 1);
    }

    props.toggle(event);
  };

  return (
    <div className="suggestions-container-nrb">
      {/* TODO: Improve this button implementation */}
      <button
        data-tracking="suggested-reports-popover-trigger"
        id="suggestions-popover-target"
        onClick={toggle}
        className={
          classnames('elmo-btn elmo-btn--override elmo-btn--default elmo-btn--icon', {
            'active': suggestedReports.isOpen
          })
        }
        ref={buttonRef}
        role="button" 
        tabIndex={0}
      >
        <span className="elmo-btn__icon" data-testid="elmo-btn-icon-default">
          <span data-testid="elmo-icon" className="elmo-icon elmo-icon--size-md">
            <LightBulbIcon />
          </span>
        </span>
        <span className="elmo-btn__hover" />
        <span className="elmo-btn__focused" />
        <span className="elmo-btn__pressed" />
      </button>
      <Popover
        placement="right-start"
        isOpen={suggestedReports.isOpen}
        target="suggestions-popover-target"
        className="suggestions-popover-nrb"
        modifiers={{ offset: { offset: '-26, 9' } }}
      >
        <div ref={contentRef}>
          <PopoverHeader>
            <h2>Current Selection</h2>
            <ElmoButton 
              type="text" 
              icon={<CloseIcon />} 
              isIcon={true} 
              onClick={toggle} 
              data-testid="suggestions-close"
            />
          </PopoverHeader>
          <PopoverBody>
            <SuggestedReportsContentComponent
              suggestedReports={suggestedReports}
              onSuggestionClick={onSuggestionClick}
              loading={loading}
            />
          </PopoverBody>
        </div>
      </Popover>
    </div>
  );
};

export default forwardRef<SuggestedReportsPopoverRefHandler, SuggestedReportsPopoverComponentProps>(
  (props, ref) => {
    const buttonRef = useRef(null);
    const contentRef = useRef(null);

    useImperativeHandle(ref, () => ({ buttonRef, contentRef }));

    return <SuggestedReportsPopoverComponent {...props} contentRef={contentRef} buttonRef={buttonRef} />;
  }
);
