// This section of state is an Array of JoinAndDimensions which are essentially an object with a Join object and
// an Array of Dimensions
import { JoinDimensionsAction } from '../actions/joinColumn';
import { ADD_JOIN_DIMENSIONS, REMOVE_JOIN_DIMENSIONS } from '../constants';
import { JoinAndDimensions } from '../redux';
import * as _ from 'lodash';
import { Join } from '../types/joinModel';
import { ReportBuilderAction } from '../actions/reportBuilder';

function findJoinAndDimensionsIndex(join: Join, joinAndDimensions: JoinAndDimensions[]): number {
  return joinAndDimensions.findIndex((jd: JoinAndDimensions) => {
    return Join.equals(jd.join, join);
  });
}

export function joinDimensionsReducer(state: JoinAndDimensions[] = [],
                                      action: JoinDimensionsAction | ReportBuilderAction): JoinAndDimensions[] {
  switch (action.type) {
    case ADD_JOIN_DIMENSIONS: {
      let newState = state.slice();
      const index = findJoinAndDimensionsIndex(action.join, state);

      // If join found, append the new dimensions
      if (newState[index]) {
        // copy the object so the array of selected dimensions is not modified for the previous state
        let copy = Object.assign({}, newState[index]);
        copy.dimensions = [...newState[index].dimensions.concat(action.columns)];
        newState[index] = copy;
        return newState;
      }

      // If join not found, add to the state
      return [...state, {
        join: action.join,
        dimensions: action.columns.slice()
      }];
    }

    case REMOVE_JOIN_DIMENSIONS: {
      let newState = state.slice();
      const index = findJoinAndDimensionsIndex(action.join, state);

      if (newState[index]) {
        let copy = Object.assign({}, newState[index]);
        copy.dimensions = _.differenceBy(newState[index].dimensions, action.columns, 'name');
        newState[index] = copy;
      }

      // if none left, remove from state
      if (newState[index] && newState[index].dimensions.length === 0) {
        newState.splice(index, 1);
      }

      return newState;
    }

    default:
      return state;
  }
}