import React from 'react';
import { SelectInput as Input, SelectProps } from '@eds/select-input';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type SelectInputProps<Item, TFieldValues extends FieldValues> = Omit<SelectProps<Item>, 'onChange' | 'value'> &
  UseControllerProps<TFieldValues>;

export const SelectInput = <Item, TFieldValues extends FieldValues>({
  control,
  isCreatable,
  isMulti,
  isValidNewOption,
  limit,
  name,
  onChangeMulti,
  ...props
}: SelectInputProps<Item, TFieldValues>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  if (isMulti) {
    return isCreatable ? (
      <Input
        isCreatable
        isMulti
        isValidNewOption={isValidNewOption}
        limit={limit}
        onChangeMulti={onChange}
        value={value}
        {...props}
      />
    ) : (
      <Input isMulti limit={limit} onChangeMulti={onChange} value={value} {...props} />
    );
  }

  return isCreatable ? (
    <Input isCreatable isValidNewOption={isValidNewOption} onChange={onChange} value={value} {...props} />
  ) : (
    <Input onChange={onChange} value={value} {...props} />
  );
};
