import { CRT_TOKEN_KEY } from 'src/services/authService';

import { base64ToBytes } from './base64';

type Roles = ['ROLE_ELMO_SUPER'];

type TokenData = {
  user: {
    branding: {
      'profile-image': string;
    };
    firstName: string;
    id: number;
    lastName: string;
    permissions: {
      configuration: {
        canImportDynamicConfiguration: boolean;
        canViewSecuredForm: boolean;
      };
      dashboard: {
        canCreateSuggestedDashboard: boolean;
      };
      report: {
        canRunScheduleReport: boolean;
        canSaveSuggestedReport: boolean;
      };
    };
    roles: Roles;
    uuid: string;
  };
};

const getToken = (): TokenData | null => {
  const token = sessionStorage.getItem(CRT_TOKEN_KEY);
  return token ? JSON.parse(new TextDecoder().decode(base64ToBytes(token))) : null;
};

export const getPermissions = (): TokenData['user']['permissions'] =>
  getToken()?.user.permissions || {
    configuration: { canImportDynamicConfiguration: false, canViewSecuredForm: false },
    dashboard: { canCreateSuggestedDashboard: false },
    report: { canRunScheduleReport: false, canSaveSuggestedReport: false },
  };

export const getUserFullName = () =>
  `${getToken()?.user.firstName || ''}${getToken()?.user.lastName ? ` ${getToken()!.user.lastName}` : ''}`;

export const getUserId = () => getToken()?.user.id || 0;

export const getUserProfileImage = () => `${getToken()?.user.branding['profile-image'] || ''}`;

export const getUserUuid = () => getToken()?.user.uuid;

export const isElmoSuperAdmin = () => getToken()?.user.roles.includes('ROLE_ELMO_SUPER');
