/***
 * Translations
 */

export class TranslationRuleItem {
  dimension: string;
  operator: OperatorType;
  currentValue: string;
  futureValue: string;
}

export enum OperatorType {
  IS_BLANK = 'IS_BLANK',
  NOT_BLANK = 'NOT_BLANK',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT EQUALS',
  GREATER_THAN = 'GREATER THAN',
  LESS_THAN = 'LESS THAN',
}

export enum OperatorLabels {
  IS_BLANK = 'IS BLANK',
  NOT_BLANK = 'NOT BLANK',
  EQUALS = 'EQUALS',
  'NOT EQUALS' = 'NOT EQUALS',
  'GREATER THAN' = 'GREATER THAN',
  'LESS THAN' = 'LESS THAN',
}

export enum DefaultValueType {
  BLANK = 1,
  FIELD = 2,
  TEXT = 3,
}

export class TranslationConfiguration {
  dimension: string;
  defaultValue: {
    type?: DefaultValueType;
    value?: string;
  } = {};
  rules: Array<TranslationRuleItem> = [];
}

export interface TranslationConfigurations {
  id: string;
  type: ConfigurationType.Translation;
  configuration: Array<TranslationConfiguration>;
}

/***
 * Datetime Handling
 */

export enum DateFormats {
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'DD/MM/YY'   = 'DD/MM/YY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'MM/DD/YY'   = 'MM/DD/YY',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
  'YY/MM/DD'   = 'YY/MM/DD',
  'YYYY-MM-DD' = 'YYYY-MM-DD'
}

export enum DatetimeFormats {
  'DD/MM/YYYY HH:MM' = 'DD/MM/YYYY HH24:MI',
  'DD/MM/YY HH:MM'   = 'DD/MM/YY HH24:MI',
  'MM/DD/YYYY HH:MM' = 'MM/DD/YYYY HH24:MI',
  'MM/DD/YY HH:MM'   = 'MM/DD/YY HH24:MI',
  'YYYY/MM/DD HH:MM' = 'YYYY/MM/DD HH24:MI',
  'YY/MM/DD HH:MM'   = 'YY/MM/DD HH24:MI',
}

export enum DatetimeWithSecondsFormats {
  'DD/MM/YYYY HH:MM:SS' = 'DD/MM/YYYY HH24:MI:SS',
  'DD/MM/YY HH:MM:SS'   = 'DD/MM/YY HH24:MI:SS',
  'MM/DD/YYYY HH:MM:SS' = 'MM/DD/YYYY HH24:MI:SS',
  'MM/DD/YY HH:MM:SS'   = 'MM/DD/YY HH24:MI:SS',
  'YYYY/MM/DD HH:MM:SS' = 'YYYY/MM/DD HH24:MI:SS',
  'YY/MM/DD HH:MM:SS'   = 'YY/MM/DD HH24:MI:SS',
}

export class DatetimeConfiguration {
  dimension: string;
  format: DateFormats & DatetimeFormats & DatetimeWithSecondsFormats;
}

export interface DatetimeConfigurations {
  id: string;
  type: ConfigurationType.Datetime;
  configuration: Array<DatetimeConfiguration>;
}

/**
 * General transformation
 */
export enum ConfigurationType {
  Translation = 1,
  Datetime = 2,
  Concatenation = 3,
}

export type Transformation = TranslationConfigurations | DatetimeConfigurations;
