import { ViewJoinAndDimensionOrMeasure } from 'src/redux';
import { Join } from 'src/types/joinModel';
import { View } from 'src/types/viewModel';

export const composeCustomFilterOption = (
  availableViews: View[],
  currentView: View,
  value: string,
  viewName?: string
): ViewJoinAndDimensionOrMeasure => {
  // NOTE: Value format is `${view}.${dimension}` or `${join}.${join}.${dimension}`
  const selectedDimension = value.split('.').pop();
  const foundView = availableViews.find(({ name }) => viewName === name);
  const foundDimension = foundView?.dimensions.find(({ name }) => selectedDimension === name);
  const foundJoin = currentView.joins.find(({ viewName }) => viewName === foundView?.name);
  // NOTE: Remove the dimension part of the value to get `${join}` or `${join.join}`
  const fullJoinName = value.split('.').slice(0, -1).join('.');

  return {
    dimension: foundDimension,
    // NOTE: If this is the same as the current view, then this is a base view and not a join
    join: currentView.name === foundView?.name ? undefined : ({ ...foundJoin, fullJoinName } as Join),
    view: foundView as View,
  };
};
