import * as React from 'react';
import './style.css';
import ClearButton from '../../components/ClearButton';

export interface ColumnSelectFilterProps {
  onFilterUpdate: (text: string) => void;
}

interface ColumnSelectFilterState {
  filterText: string;
}

/**
 * The grey header with checkbox, title and caret icon, and a collapse which displays the children
 */
class ColumnSelectFilter extends React.Component<ColumnSelectFilterProps, ColumnSelectFilterState> {

  constructor(props: ColumnSelectFilterProps) {
    super(props);

    this.state = {
      filterText: ''
    };
  }

  onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let text: string = event.currentTarget.value;

    this.props.onFilterUpdate(text);

    this.setState({
      filterText: text
    });
  }

  onFilterClear = () => {
    this.setState({
      filterText: ''
    });

    this.props.onFilterUpdate('');
  }
  render() {
    return (
      <div className="column-select-filter">
        <input
          type="text"
          className="search-box form-control form-control-sm"
          placeholder="&#61442; Search"
          onChange={this.onFilterChange}
          value={this.state.filterText}
        />
        {this.state.filterText.length > 0 &&
        <ClearButton
          onClick={this.onFilterClear}
        />}
      </div>
    );
  }
}

export default ColumnSelectFilter;