import * as React from 'react';
import { Dropdown } from 'elmo-elements';
import { DropdownItemProps } from 'elmo-elements/Dropdown';
import './style.css';

const ElmoDropdownItem: React.FC<DropdownItemProps> = ({ children, ...props }) => {
  return (
    <Dropdown.Item
      {...props}
    >
      <div className="elmo-dropdown-item--override">
        {children}
      </div>
    </Dropdown.Item>
  );
};

export default ElmoDropdownItem;
