import * as React from 'react';
import classnames from 'classnames';
import { Search } from 'elmo-elements';
import { SearchProps } from 'elmo-elements/Search';
import './style.css';

interface Props {
  isSubmitDisabled?: boolean;
  isVisible?: boolean;
}

export type ElmoSearchProps = Pick<SearchProps, Exclude<keyof SearchProps, 'isVisible'>> & Props;

const ElmoSearch = ({ 
  className,
  isVisible = true, 
  isSubmitDisabled, 
  isSubmitEnabledAlways = false, 
  ...props 
}: ElmoSearchProps) => {
  return (
    <div 
      className={
        classnames('elmo-search--override', className, { 
          'elmo-search__btn-submit--is-disabled': isSubmitDisabled 
        })
      }
    >
      <Search {...props} isSubmitEnabledAlways={isSubmitDisabled || isSubmitEnabledAlways} isVisible={isVisible} />
    </div>
  );
};

export default ElmoSearch;
