import axios from 'axios';
import { DateTime } from 'luxon';
import { MutationOptions, useMutation, useQuery } from '@tanstack/react-query';

import {
  OutboundNotificationRecipient,
  ScheduledReportFieldValues,
  ScheduledReportOccurrence,
  scheduledReportsKeys,
} from 'src/features/scheduled-reports';
import { bytesToBase64 } from 'src/features/utils';

interface OutboundDestination {
  id: string;
  host: string;
  provider: string;
  title: string;
}

interface ScheduledReportPayload {
  calendarDays: Array<number>;
  daysOfTheWeek: Array<number>;
  encryptionKey?: string;
  endDate: string | null;
  filenameTokens?: Array<string>;
  isEncryptionEnabled?: boolean;
  isFilenameCustomisable?: boolean;
  lastDayOfTheMonth: boolean;
  name: string;
  notificationRecipients?: Array<OutboundNotificationRecipient>;
  occurrence: ScheduledReportOccurrence;
  outboundDestinationId?: string;
  queryId: string;
  scheduleId: string;
  startDate: string;
  targetUserIds?: Array<string>;
  time: string;
}

export interface ScheduledReportsFilenameToken {
  description: string;
  id: string;
  token: string;
  type: 'Date' | 'Separator' | 'Time';
}

interface ScheduledReportsUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string | null;
  role: string;
}

export interface ScheduledReportsUsersPayload {
  ids?: Array<string>;
  text?: string;
}

export interface ScheduledReportsUserOption {
  avatar: string;
  email: string;
  id: string;
  label: string;
  role: string;
  type: 'user';
  value: string;
}

const createScheduledReport = async (data: ScheduledReportPayload) => {
  const response = await axios.post('/api/schedule', data);
  return response.data;
};

const fetchOutboundDestinations = async () => {
  const response = await axios.get<Array<OutboundDestination>>('/api/outbound/providers');
  return response.data;
};

const fetchScheduledReportsFilenameTokens = async () => {
  const response = await axios.get<Array<ScheduledReportsFilenameToken>>('/api/schedules/filename/tokens');
  return response.data;
};

const fetchScheduledReportsUsers = async (scheduledReportsUsersPayload: ScheduledReportsUsersPayload) => {
  const response = await axios.post<Array<ScheduledReportsUser>>('/api/scheduler-users', scheduledReportsUsersPayload);
  return response.data;
};

const updateScheduledReport = async (data: ScheduledReportPayload) => {
  const response = await axios.put(`/api/schedule/${data.scheduleId}`, data);
  return response.data;
};

export const useOutboundDestinationsQuery = () =>
  useQuery({
    queryKey: scheduledReportsKeys.outboundProviders(),
    queryFn: fetchOutboundDestinations,
    select: data =>
      data.map(({ id, provider, title }) => ({
        label: `${title} (${provider.toUpperCase()})`,
        value: id,
      })),
  });

export const useScheduledReportMutation = (
  mode: 'create' | 'edit',
  onSuccess: MutationOptions<unknown, unknown, ScheduledReportFieldValues>['onSuccess']
) =>
  useMutation<unknown, unknown, ScheduledReportFieldValues>({
    mutationFn: ({
      calendarDay,
      customFilename,
      customFilenameEnabled,
      daysOfTheWeek,
      destination,
      encryptionEnabled,
      encryptionKey,
      endDate,
      id,
      name,
      occurrence,
      outboundDestination,
      outboundNotificationRecipient,
      queryId,
      startDate,
      targetUsers,
      time,
    }) => {
      const data: ScheduledReportPayload = {
        calendarDays: [],
        daysOfTheWeek: [],
        endDate: null,
        lastDayOfTheMonth: false,
        name,
        occurrence: occurrence.value,
        queryId,
        scheduleId: id,
        startDate: DateTime.fromJSDate(startDate!).toFormat('yyyy-LL-dd'),
        time: time!.value,
      };

      if (endDate && occurrence.value !== 'once') {
        data.endDate = DateTime.fromJSDate(endDate).toFormat('yyyy-LL-dd');
      }

      if (occurrence.value === 'weekly' && daysOfTheWeek) {
        data.daysOfTheWeek = daysOfTheWeek.map(({ value }) => value);
      }

      if (occurrence.value === 'monthly' && calendarDay) {
        if (calendarDay.value === -1) {
          data.lastDayOfTheMonth = true;
        } else {
          data.calendarDays = [calendarDay.value];
        }
      }

      if (destination === 'users' && targetUsers) {
        data.targetUserIds = targetUsers.map(({ value }) => value);
        return mode === 'create' ? createScheduledReport(data) : updateScheduledReport(data);
      }

      if (outboundDestination) {
        data.outboundDestinationId = outboundDestination.value;
      }

      if (outboundNotificationRecipient) {
        const isUser = outboundNotificationRecipient.type === 'user';
        data.notificationRecipients = [
          isUser
            ? { recipientType: 'user', uuid: outboundNotificationRecipient.value }
            : { recipientType: 'email', email: outboundNotificationRecipient.value },
        ];
      }

      if (encryptionEnabled && encryptionKey) {
        data.isEncryptionEnabled = true;
        data.encryptionKey = bytesToBase64(new TextEncoder().encode(encryptionKey));
      }

      if (customFilenameEnabled && customFilename?.length) {
        data.isFilenameCustomisable = true;
        data.filenameTokens = customFilename;
      }

      return mode === 'create' ? createScheduledReport(data) : updateScheduledReport(data);
    },
    onSuccess,
  });

export const useScheduledReportsFilenameTokensQuery = (enabled: boolean) =>
  useQuery({
    enabled,
    queryKey: scheduledReportsKeys.filenameTokens(),
    queryFn: fetchScheduledReportsFilenameTokens,
  });

const userRoles = {
  ROLE_CRT_SUPER_ADMIN: 'ELMO Super Admin',
  ROLE_SUPER: 'Company Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_EMPLOYEE: 'Employee',
};

export const useScheduledReportsUsersQuery = (
  enabled: boolean,
  scheduledReportsUsersPayload: ScheduledReportsUsersPayload
) =>
  useQuery({
    enabled,
    queryKey: scheduledReportsKeys.users(scheduledReportsUsersPayload),
    queryFn: () => fetchScheduledReportsUsers(scheduledReportsUsersPayload),
    select: data =>
      data.map<ScheduledReportsUserOption>(user => ({
        avatar: user.profileImage || '',
        id: user.id,
        email: user.email,
        label: `${user.firstName} ${user.lastName}`.trim(),
        value: user.id,
        role: userRoles[user.role],
        type: 'user',
      })),
  });
