import * as React from 'react';
import { DropdownItemData } from '../Dropdown';
import Button from 'reactstrap/lib/Button';

type Props = {
  buttonItems: DropdownItemData[];
  size?: string;
};

export function SplitButtonDropdownButtons({ buttonItems, size }: Props) {
  return (
    <>
      {buttonItems.map((buttonItem: DropdownItemData, index: number) => {
        return (
          <Button
            key={index}
            id={buttonItem.id}
            color={buttonItem.color ? buttonItem.color : 'primary'}
            {...buttonItem.href && {href: buttonItem.href}}
            {...buttonItem.onClick && {onClick: buttonItem.onClick}}
            size={size ? size : ''}
            disabled={buttonItem.disabled}
            className={buttonItem.className}
          >
            {buttonItem.label}
          </Button>
        );
      })}
    </>
  );
}