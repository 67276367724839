import * as React from 'react';
import { Layout } from 'react-grid-layout';
import * as Highcharts from 'highcharts';
import { LockOutlinedIcon } from 'elmo-elements';

import { WIDGET_TYPE_CHART, WIDGET_TYPE_VIEW } from '../../services/widgetService';
import { Widget } from '../../types/widgetModel';
import ReportWidget from '../../components/Widget/ReportWidget';
import ChartWidget from '../../components/Widget/ChartWidget';

import './style.css';

export interface WidgetProps {
  widget: Widget;
  layout?: Layout;
  handleChangeRefChart?: (hcRef: Highcharts.Chart) => void;
  currentGridWidth?: number;
  updateWidgetStatus: (widgetId: string, status: boolean) => void;
}

const WidgetComponent = (props: WidgetProps) => {
  React.useEffect(
    () => {
      if (!props.widget.readAccess) {
        props.updateWidgetStatus(props.widget.widgetId, true);
      }
    },
    [props.widget.readAccess]
  );

  if (!props.widget.readAccess) {
    return (
      <div className="missing-permission-container">
        <LockOutlinedIcon className="lock-icon" />
        You don’t have the right permissions to access this chart
      </div>
    );
  } else if (props.widget.chart && props.widget.type === WIDGET_TYPE_CHART) {
    return (
      <ChartWidget
        widget={props.widget}
        layout={props.layout}
        handleChangeRefChart={props.handleChangeRefChart}
        currentGridWidth={props.currentGridWidth}
        updateWidgetStatus={props.updateWidgetStatus}
      />
    );
  } else if (props.widget.type === WIDGET_TYPE_VIEW && props.widget.query) {
    return (
      <ReportWidget
        savedQuery={props.widget.query}
        widgetId={props.widget.widgetId}
        updateWidgetStatus={props.updateWidgetStatus}
      />
    );
  } else {
    return (
      <div key={props.widget.widgetId}>There was an issue displaying the Widget.</div>
    );
  }
};

export default WidgetComponent;
