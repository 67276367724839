import { Filter } from '../types/filterModel';
import { REPLACE_FILTER_GROUPS } from '../constants';

export interface FilterGroupsAction {
  type: REPLACE_FILTER_GROUPS;
  filterGroups: Array<Array<Filter>>;
}

export function replaceFilterGroups(filterGroups: Array<Array<Filter>>): FilterGroupsAction {
  return {
    type: REPLACE_FILTER_GROUPS,
    filterGroups,
  };
}