import React from 'react';
import { FilterBar } from '@eds/filtering';
import { Flex } from '@eds/flex';
import { SelectDropdown } from '@eds/select-dropdown';
import { css } from '@emotion/css';

import {
  ScheduledReportOccurrence,
  ScheduledReportOccurrenceLabel,
  ScheduledReportStatus,
  occurrences,
} from 'src/features/scheduled-reports';
import { cssOverrides } from 'src/features/ui';

import { FilterData } from './scheduled-reports-list.types';

const statusLabels: Array<ScheduledReportStatus> = ['Active', 'Ended', 'Paused'];
const statuses = statusLabels.map(label => ({ label, value: label }));

type Item<L = string, V = string> = { label: L; value: V };

type ScheduledReportsListFiltersProps = {
  filterData: FilterData;
  loading: boolean;
  onFilter: ({ search, status, occurrence }: Partial<FilterData>) => void;
};

export const ScheduledReportsListFilters = ({ filterData, loading, onFilter }: ScheduledReportsListFiltersProps) => {
  const selectedStatuses = filterData.status.map(item => statuses.find(({ value }) => value === item));
  const selectedOccurrences = filterData.occurrence.map(item => occurrences.find(({ value }) => value === item));

  const handleStatusChange = (item: Array<Item<ScheduledReportStatus, ScheduledReportStatus>> | null) => {
    onFilter({ status: item?.map(({ value }) => value) || [] });
  };

  const handleOccurrenceChange = (
    item: Array<Item<ScheduledReportOccurrenceLabel, ScheduledReportOccurrence>> | null
  ) => {
    onFilter({ occurrence: item?.map(({ value }) => value) || [] });
  };

  const canClearFilters = filterData.occurrence.length + filterData.search.length + filterData.status.length > 0;

  return (
    <Flex className={css(cssOverrides.button('neutral'))} data-tracking="scheduled-reports-list-filters">
      <FilterBar
        clearable={canClearFilters}
        keywordFilter={{
          onChange: e => onFilter({ search: e.target.value }),
          onClear: () => onFilter({ search: '' }),
          placeholder: 'Search by schedule name or report...',
          value: filterData.search,
          width: '20rem',
        }}
        loading={loading}
        onClear={() => onFilter({ occurrence: [], search: '', status: [] })}
      >
        <SelectDropdown
          clearButton
          hideSearchInput
          isMulti
          items={statuses}
          label="Status"
          onChangeMulti={handleStatusChange}
          value={selectedStatuses}
        />
        <SelectDropdown
          clearButton
          hideSearchInput
          isMulti
          items={occurrences}
          label="Occurrence"
          onChangeMulti={handleOccurrenceChange}
          value={selectedOccurrences}
        />
      </FilterBar>
    </Flex>
  );
};
