import React from 'react';
import { TextAreaInput as Input, TextAreaInputProps as InputProps } from '@eds/text-area-input';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type TextAreaInputProps<TFieldValues extends FieldValues> = Omit<InputProps, 'onChange' | 'value'> &
  UseControllerProps<TFieldValues>;

export const TextAreaInput = <TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: TextAreaInputProps<TFieldValues>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return <Input onChange={onChange} defaultValue={value} {...props} />;
};
