import {
  ConfigurationType,
  DatetimeConfiguration,
  Transformation,
  TranslationConfiguration
} from '../types/transformationModel';
import { TransformationsAction } from '../actions/transformations';
import {
  ADD_CONCATENATION_CONFIGURATION,
  UPDATE_CONCATENATION_CONFIGURATION,
  SET_TRANSLATION_CONFIGURATION,
  SET_DATETIME_CONFIGURATION,
  DELETE_TRANSFORMATION,
} from '../constants/transformations';
import * as uuid from 'uuid';
import { Reducer } from 'react';

// @ts-ignore TODO: Fix this type
export const transformationsReducer: Reducer<Array<Transformation>, TransformationsAction> = (state = [], action) => {
  switch (action.type) {
    case ADD_CONCATENATION_CONFIGURATION: {
      return [...state, action.concatenationConfiguration];
    }
    case UPDATE_CONCATENATION_CONFIGURATION: {
      return state.map(transformation =>
        transformation.id === action.concatenationConfiguration.id ? action.concatenationConfiguration : transformation
      );
    }
    case SET_TRANSLATION_CONFIGURATION: {
      /**
       * If there is no translation transformation at all, we just create a new
       * transformation object
       */
      if (!state.some((transformation) => transformation.type === ConfigurationType.Translation)) {
        return [
          ...state,
          {
            id: uuid.v4(),
            type: ConfigurationType.Translation,
            configuration: [action.translationConfiguration]
          },
        ] as Array<Transformation>;
      }

      /**
       * Otherwise, we will need to find the existing translation transformation
       * from all of the transformations
       */
      return state.map((transformation) => {
        /**
         * Find the transformation with type equalling "translation"
         */
        if (transformation.type === ConfigurationType.Translation) {
          /**
           * If a configuration already exists for this dimension then we replace this
           * configuration with teh updated one
           */
          if (transformation.configuration.some((configuration) =>
            configuration.dimension === action.translationConfiguration.dimension
          )) {
            return {
              ...transformation,
              configuration: transformation.configuration.map((configuration) => {
                if (configuration.dimension === action.translationConfiguration.dimension) {
                  return action.translationConfiguration;
                }

                return configuration;
              })
            };
          }

          /**
           * Otherwise, just add it to the configuration array
           */
          return {
            ...transformation,
            configuration: [
              ...transformation.configuration,
              action.translationConfiguration,
            ]
          };
        }
        return transformation;
      });
    }
    case SET_DATETIME_CONFIGURATION: {
      /**
       * If there is no datetime transformation at all, we just create a new
       * transformation object
       */
      if (!state.some((transformation) => transformation.type === ConfigurationType.Datetime)) {
        return [
          ...state,
          {
            id: uuid.v4(),
            type: ConfigurationType.Datetime,
            configuration: [action.datetimeConfiguration]
          },
        ] as Array<Transformation>;
      }

      /**
       * Otherwise, we will need to find the existing datetime transformation
       * from all of the transformations
       */
      return state.map((transformation) => {
        /**
         * Find the transformation with type equalling "datetime"
         */
        if (transformation.type === ConfigurationType.Datetime) {
          /**
           * If a configuration already exists for this dimension then we replace this
           * configuration with teh updated one
           */
          if (transformation.configuration.some((configuration) =>
            configuration.dimension === action.datetimeConfiguration.dimension
          )) {
            return {
              ...transformation,
              configuration: transformation.configuration.map((configuration) => {
                if (configuration.dimension === action.datetimeConfiguration.dimension) {
                  return action.datetimeConfiguration;
                }

                return configuration;
              })
            };
          }

          /**
           * Otherwise, just add it to the configuration array
           */
          return {
            ...transformation,
            configuration: [
              ...transformation.configuration,
              action.datetimeConfiguration,
            ]
          };
        }
        return transformation;
      });
    }
    case DELETE_TRANSFORMATION: {
      const transformationForDeletion = state.find(({ id }) => id === action.transformationId);

      if (!transformationForDeletion) {
        return state;
      }

      const updatedTransformation = {
        ...transformationForDeletion,
        configuration: (
          transformationForDeletion.configuration as Array<TranslationConfiguration | DatetimeConfiguration>
        ).filter(({ dimension }) => dimension !== action.dimension),
      };

      if (updatedTransformation.configuration.length === 0) {
        return state.filter(({ id }) => id !== action.transformationId);
      }

      return state.map(transformation =>
        transformation.id === action.transformationId ? updatedTransformation : transformation
      );
    }
    default:
      return state;
  }
};
