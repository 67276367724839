import React from 'react';
import { Box } from '@eds/box';
import { ChevronRightIcon } from '@eds/icon';
import { CustomButton } from '@eds/button';
import { Flex } from '@eds/flex';
import { Heading } from '@eds/heading';
import { Text } from '@eds/text';
import { css } from '@emotion/css';

import { AuthService } from 'src/services/authService';
import { cssOverrides } from 'src/features/ui';
import { CRT_ANALYTICS_CAPABILITIES_KEY, ELMO_PREDICTIVE_ANALYTICS_DOMAIN } from 'src/constants/permissions';

import { PredictiveAnalyticsIllustration } from './predictive-analytics-illustration.component';
import { backgroundColor, borderColor } from '@eds/core';

const getPredictiveAnalyticsUrl = (domain?: string | null) => {
  try {
    return new URL(`${window.location.protocol}//${domain || ''}`).href;
  } catch {
    return;
  }
};

export const PredictiveAnalytics = () => {
  const authService = AuthService.getInstance();
  const isEnabled = authService.canViewPredictiveAnalytics();
  const predictiveAnalyticsUrl = getPredictiveAnalyticsUrl(
    authService.getCapabilityValue(CRT_ANALYTICS_CAPABILITIES_KEY, ELMO_PREDICTIVE_ANALYTICS_DOMAIN)
  );

  const onClick = () => {
    if (predictiveAnalyticsUrl) {
      window.location.assign(predictiveAnalyticsUrl);
    }
  };

  return isEnabled ? (
    <Flex
      borderColor="neutralSubtle"
      borderRadius="xlarge"
      borderStyle="solid"
      borderWidth="thin"
      flexDirection={{
        xsmall: 'column',
        medium: 'row',
      }}
      gap="small"
      padding="medium"
    >
      <Flex justifyContent="center" flexBasis="33%" flexDirection="column" gap="xsmall">
        <Heading as="h4" level={6}>
          Predictive People Analytics
        </Heading>
        <Text fontSize="small" lineHeight="tight">
          Insights based on trends unique to your organisation, powered by machine learning
        </Text>
      </Flex>
      <Flex className={css(cssOverrides.button('ghost'))}>
        <CustomButton
          className={css({
            borderRadius: '8px',
            borderWidth: '2px',
            height: '100%',
            width: '100%',
            '&:active > div': {
              backgroundColor: backgroundColor.neutral,
              borderColor: borderColor.informative,
            },
            '&:not(:disabled):active': {
              borderColor: borderColor.informative,
            },
            '&:not(:disabled):hover': {
              borderColor: borderColor.informative,
            },
          })}
          onClick={onClick}
          paddingLeft="none"
          paddingRight="none"
          tone="ghost"
        >
          <Flex
            alignItems="center"
            backgroundColor="informativeSubtle"
            borderRadius="large"
            flex={1}
            paddingRight="xsmall"
          >
            <Box paddingLeft="medium" paddingTop="xxsmall">
              <PredictiveAnalyticsIllustration />
            </Box>
            <Flex flex={1} padding="medium">
              <Text
                className={css({
                  textWrap: 'wrap',
                })}
                fontSize="small"
                fontWeight="medium"
                textAlign="left"
              >
                Predict potential flight risk of employees
              </Text>
            </Flex>
            <ChevronRightIcon />
          </Flex>
        </CustomButton>
      </Flex>
    </Flex>
  ) : null;
};
