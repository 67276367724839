import * as React from 'react';
import { DropdownItemData } from '../Dropdown';
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown';
import { SplitButtonDropdownButtons } from './SplitButtonDropdownButtons';
import { SplitButtonDropdownToggle } from './SplitButtonDropdownToggle';
import { SplitButtonDropdownItems } from './SplitButtonDropdownItems';

interface SplitButtonDropdownProps {
  buttonItems: DropdownItemData[];
  dropdownItems: DropdownItemData[];
  size?: string;
  disabled?: boolean;
  right?: boolean;
  className?: string;
}

interface SplitButtonDropdownState {
  isOpen: boolean;
}

class SplitButtonDropdown extends React.Component<SplitButtonDropdownProps, SplitButtonDropdownState> {
  constructor(props: SplitButtonDropdownProps) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { className, buttonItems, size, disabled, dropdownItems, right } = this.props;

    const toggleColour = buttonItems[0].color;

    return (
      <ButtonDropdown
        className={className}
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        disabled={disabled}
      >
        <SplitButtonDropdownButtons
          buttonItems={buttonItems}
          size={size}
        />
        {dropdownItems.length > 0 &&
          <>
            <SplitButtonDropdownToggle
              color={toggleColour}
              size={size}
              disabled={disabled}
            />
            <SplitButtonDropdownItems
              dropdownItems={dropdownItems}
              right={right}
            />
          </>
        }
      </ButtonDropdown>

    );
  }
}

export default SplitButtonDropdown;
