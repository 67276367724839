import * as React from 'react';
import { Modal, Button, Search, Alert, DataTable, Checkbox, Loader, Link, Tooltip } from 'elmo-elements';
import { useSelector, useDispatch } from 'react-redux';

import { FormsListType, FormType } from '../../types/forms';
import { getIsSelectFormAlertClosed, mapPaginationDataToRequestParams, IS_SELECT_FORM_ALERT_CLOSED } from './helpers';
import { getForms, importForms } from '../../services/formsApiService';
import { AppState } from '../../redux';
import { getViewsAndStore } from '../../actions/report';
import { NotificationService } from '../../services/notificationService';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants';

import './style.scss';

export type SelectFormsModalProps = {
  isOpened: boolean;
  toggleModal: () => void;
};

const t1Cols =   [
  {
    title: '',
    width: '45px',
  },
  {
    title: 'Forms name',
    textAlign: 'left'
  },
  {
    title: 'Category',
    textAlign: 'left'
  }
];

const MAX_SELECTED_FORMS_NUMBER = 20;

export type PaginationComponentDataType = {
  currentPage: number;
  pageSize: number;
  offset: number; // number of pages
  totalItems: number;
};

export const defaultFormsPagination: PaginationComponentDataType = {
  currentPage: 1,
  pageSize: 10,
  offset: 0,
  totalItems: 0,
};

function SelectFormsModal({
  isOpened,
  toggleModal,
}: SelectFormsModalProps) {
  const dispatch = useDispatch();
  const selectedModule = useSelector((state: AppState) => state.module);
  const notificationService: NotificationService | null = useSelector((state: AppState) => state.notificationService);
  const [searchText, setSearchText] = React.useState('');
  const [currentForms, setCurrentForms] = React.useState<FormsListType>([]);
  const [selectedForms, setSelectedForms] = React.useState<FormsListType>([]);
  const [isFormsListLoading, setIsFormsListLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<PaginationComponentDataType>(defaultFormsPagination);
  const [isDisplayAllItems, setIsDisplayAllItems] = React.useState<boolean>(true);
  const [isAlertClosed, setIsAlertClosed] = React.useState<boolean>(getIsSelectFormAlertClosed());
  const [isFormsImporting, setIsFormsImporting] = React.useState<boolean>(false);

  const closeModal = () => {
    toggleModal();
  };

  const closeAlert = () => {
    sessionStorage.setItem(IS_SELECT_FORM_ALERT_CLOSED, 'true');
    setIsAlertClosed(true);
  };

  const getFormsData = async (text?: string | null, paginationData?: PaginationComponentDataType) => {
    setIsFormsListLoading(true);
    const newPagination = paginationData ? paginationData : pagination;
    const searchParam = text === null || !!text ? text : searchText;
    const formsData = await getForms({ text: searchParam, ...mapPaginationDataToRequestParams(newPagination) });
    setIsFormsListLoading(false);
    setCurrentForms(formsData.forms);
    setPagination({ ...newPagination, totalItems: formsData.total });
  };

  const onSearchChange = (value: string) => {
    setSearchText(value);
    if (!value && value !== searchText) {
      getFormsData(null, { ...pagination, offset: 0, currentPage: 1 });
    }
  };

  const onSearchSubmit = async () => {
    if (isFormsListLoading) {
      return;
    }
    await getFormsData(searchText, { ...pagination, offset: 0, currentPage: 1 });
  };

  const onPageChange = (pageNumber: number, startItem: number) => {
    const newPaginationData = { ...pagination, offset: startItem - 1, currentPage: pageNumber };
    setPagination(newPaginationData);
    getFormsData(null, newPaginationData);
  };

  const onPageSizeChange = (pageSize: number) => {
    const newPaginationData = { ...pagination, pageSize, offset: 0, currentPage: 1 };
    setPagination(newPaginationData);
    getFormsData(null, newPaginationData);
  };

  const onFormSelect = (form: FormType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedForms([...selectedForms, form]);
    }

    if (!e.target.checked) {
      setSelectedForms(selectedForms.filter((selectedForm) => selectedForm.id !== form.id));
    }

    setCurrentForms(currentForms.map(currentForm => ({
      ...currentForm, isChecked: e.target.checked,
    })));
  };

  const onFormsImportClick = async () => {
    if (isFormsImporting) {
      return;
    }
    const formIds = selectedForms.map((selectedForm) => selectedForm.id);

    try {
      setIsFormsImporting(true);
      await importForms({ module: selectedModule.name, formIds });
      setIsFormsImporting(false);
      if (notificationService) {
        notificationService.addNotification(NOTIFICATION_SUCCESS, 'Forms imported successfully');
      }
      closeModal();
      dispatch(getViewsAndStore(true));
    } catch (e) {
      if (notificationService) {
        notificationService.addNotification(NOTIFICATION_ERROR, 'Forms import error');
      }
    }
  };

  const getPrimaryButton = () => (
    <Button
      type="primary"
      onClick={onFormsImportClick}
      isUppercase={false}
      isLoading={isFormsImporting}
      isDisabled={isFormsImporting}
    >
      Import
    </Button>
  );

  const isSelectedFormsLimitReached = selectedForms.length === MAX_SELECTED_FORMS_NUMBER;

  const renderRow = () => {
    const targetForms = isDisplayAllItems ? currentForms : selectedForms;
    return targetForms.map(form => {
      const isFormSelected = !!selectedForms.find((selectedForm) => selectedForm.id === form.id);
      const getRowCheckboxComponent = () => (
        <DataTable.Td textAlign="center">
          <Checkbox
            id={form.id.toString()}
            isChecked={isFormSelected}
            isDisabled={form.isImported || (isSelectedFormsLimitReached && !isFormSelected)}
            onChange={onFormSelect(form)}
          />
        </DataTable.Td>
      );

      const getRowCheckBoxWithTooltip = (isSelectedFormsLimitReached && !isFormSelected) ? (
        <Tooltip title="You've reached maximum limit of form selection" placement="right">
          {getRowCheckboxComponent()}
        </Tooltip>
        ) : getRowCheckboxComponent();

      return (
        <DataTable.Tr key={form.id} className={form.isImported ? 'importedForm' : 'notImportedForm'}>
          {getRowCheckBoxWithTooltip}
          <DataTable.Td>{form.title}</DataTable.Td>
          <DataTable.Td>{form.category}</DataTable.Td>
        </DataTable.Tr>
      );
    });
  };

  const onToggleViewMode = () => {
    setIsDisplayAllItems(!isDisplayAllItems);
  };

  React.useEffect(() => { getFormsData(); }, []);

  return (
    <Modal
      className="selectFormModal"
      type="x-large"
      title="Select Form"
      ariaLabel="Select Form Modal"
      isOpened={isOpened}
      closeModal={closeModal}
      primaryButton={getPrimaryButton()}
    >
      {!isAlertClosed &&
      <Alert
        children={
          <Button
            type="primary"
            onClick={closeAlert}
            isUppercase={false}
          >
            OK, I got it
          </Button>
        }
        heading="Once a form has been selected from the list below, the form view
        and fields will be available for you to build a report"
        type="info"
        isCloseable={false}
      />}
      <div className="panelContainer">
        <div>{`${selectedForms.length}/${MAX_SELECTED_FORMS_NUMBER} Forms Selected`}</div>
        {selectedForms.length > 0 &&
          <Link
            onClick={onToggleViewMode}
            className="viewBtn"
          >
            {isDisplayAllItems ? 'View selected' : 'View all'}
          </Link>
        }
        {isDisplayAllItems && 
        <Search
          isVisible={true}
          placeholder={'Search by name or category...'}
          onChange={onSearchChange}
          value={searchText}
          onSubmit={onSearchSubmit}
          isSubmitEnabledAlways={true}
        />}
      </div>
      {isFormsListLoading && <Loader type="spinner" />}
      {currentForms.length > 0 && !isFormsListLoading &&
        <DataTable
          id="t1"
          isFullWidth={true}
        >
          <DataTable.Header columns={t1Cols} className="tableHeader"/>
          <DataTable.Body>{renderRow()}</DataTable.Body>
          {isDisplayAllItems && 
          <DataTable.Pagination
            pageSize={pagination.pageSize}
            currentPage={pagination.currentPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            totalResults={pagination.totalItems}
          />}
        </DataTable>
      }
    </Modal>
  );
}

export default SelectFormsModal;
