import { useEffect } from 'react';
import { UseFormReset, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ShareDashboardFieldValues, shareDashboardSchema } from './share-dashboard.validation';

const defaultValues: ShareDashboardFieldValues = { id: '', targetUsers: [] };

export const useShareDashboardForm = () =>
  useForm<ShareDashboardFieldValues>({ defaultValues, resolver: yupResolver(shareDashboardSchema) });

export const useFormValuesInitializer = (
  reset: UseFormReset<ShareDashboardFieldValues>,
  id: string,
  targetUsers: string[]
) =>
  useEffect(
    () => (id ? reset({ id, targetUsers: targetUsers.map(value => ({ label: '', value })) }) : reset(defaultValues)),
    [id, targetUsers.join()]
  );
