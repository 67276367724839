import * as React from 'react';

type IconProps = {
  color?: string;
  className?: string;
  width?: number;
  height?: number;
};

export const ExportPdfIcon = ({ color = 'black', className, width = 24, height = 24 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 17L20 15V20C20 21.1 19.1 22 18 22H6C4.9 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2H14L19.3 7.3L18.6 8L17.7
        9H13V4H6V20H18V17ZM7.63372 16.2857C7.28391 16.2857 7 16.0018 7 15.652V11.8497C7 11.4998 7.28391 11.2159
        7.63372 11.2159H8.68992C9.62191 11.2159 10.3798 11.9739 10.3798 12.9059C10.3798 13.8379 9.62191 14.5958
        8.68992 14.5958H8.26744V15.652C8.26744 16.0018 7.98353 16.2857 7.63372 16.2857ZM8.26744
        13.3283H8.68992C8.92313 13.3283 9.1124 13.1391 9.1124 12.9059C9.1124 12.6727 8.92313 12.4834 8.68992
        12.4834H8.26744V13.3283ZM10.81 15.652C10.81 16.0018 11.0939 16.2857 11.4437 16.2857H12.7112C13.5266
        16.2857 14.1899 15.6224 14.1899 14.807V12.6946C14.1899 11.8792 13.5266 11.2159 12.7112 11.2159H11.4437C11.0939
        11.2159 10.81 11.4998 10.81 11.8497V15.652ZM12.7112 15.0183H12.0775V12.4834H12.7112C12.8278 12.4834 12.9224
        12.578 12.9224 12.6946V14.807C12.9224 14.9236 12.8278 15.0183 12.7112 15.0183ZM15.2541 16.2857C14.9043
        16.2857 14.6204 16.0018 14.6204 15.652V13.9848C14.6201 13.9772 14.62 13.9696 14.62 13.962C14.62 13.9544
        14.6201 13.9468 14.6204 13.9393V12.6946C14.6204 11.8792 15.2837 11.2159 16.0991 11.2159H17.3665C17.7163
        11.2159 18.0002 11.4998 18.0002 11.8497C18.0002 12.1995 17.7163 12.4834 17.3665 12.4834H16.0991C15.9824
        12.4834 15.8878 12.578 15.8878 12.6946V13.3283H17.3661C17.7159 13.3283 17.9998 13.6122 17.9998
        13.962C17.9998 14.3118 17.7159 14.5957 17.3661 14.5957H15.8878V15.652C15.8878 16.0018 15.6039 16.2857
        15.2541 16.2857Z"
        fill={color}
        fillOpacity="0.670588"
      />
    </svg>
  );
};
