import * as React from 'react';
import { head } from 'lodash';
import { useSelector } from 'react-redux';

import Page from '../../shared-components/Page';
import Dashboard from '../../components/Dashboard';
import { AppState } from '../../redux';
import { DashboardService } from '../../services/dashboardService';
import { Dashboard as DashboardModel } from '../../types/dashboardModel';
import { User } from '../../types/userModel';
import { AuthService } from '../../services/authService';
import { redirect } from '../../services/utilityService';
import { errorRoutes } from '../../routes/error';

type DashboardPageProps = {
  match: any;
};

const DEFAULT_DASHBOARD = 'default';

const DashboardPage = (props: DashboardPageProps) => {
  const authService: AuthService | null = useSelector((state: AppState) => state.authService);
  const [ dashboard, setDashboard ] = React.useState<DashboardModel>(new DashboardModel());
  const [ dashboards, setDashboards ] = React.useState<DashboardModel[]>([]);

  const canViewPage = () => authService && authService.canManageDashboard();

  const enableDashboardLazyLoading = () => !!authService && authService.enableDashboardLazyLoading();

  const initialLoad = async () => {
    const userData: User | null = authService && authService.getUserData();
    let dashboardId = props.match.params.dashboardId;
    let initDashboards: DashboardModel[] = [];

    if (!canViewPage()) {
      return redirect(errorRoutes.fatalError.path);
    }

    if (dashboardId === DEFAULT_DASHBOARD) {
      // Retrieve the default dashboard
      if (userData) {
        initDashboards = await DashboardService.apiGetDashboards(userData);
        // TODO: replace when we have UI to set default dashboard
        const defaultDashboard = head(initDashboards);
        if (defaultDashboard) {
          dashboardId = defaultDashboard.dashboardId;
        }
        setDashboards(initDashboards);
      }
    }

    const initDashboard = await DashboardService.apiGetDashboard(dashboardId);
    setDashboard(initDashboard);

    if (userData) {
      DashboardService.apiGetDashboards(userData).then((dashs) => {
        setDashboards(dashs);
      });
    }
  };

  React.useEffect(
    () => {
      initialLoad();
    },
    [props.match.params.dashboardId]
  );

  return (
    <Page title="Dashboards" className={'dashboard'}>
      <div className="row no-gutters">
        <Dashboard
          dashboard={dashboard}
          dashboards={dashboards}
          isEditable={dashboard.permissions && dashboard.permissions.canEdit}
          enableDashboardLazyLoading={enableDashboardLazyLoading()}
        />
      </div>
    </Page>
  );
};

export default DashboardPage;
