import { MeasuresAction } from '../actions/measure';
import { GroupByAction } from '../actions/groupBy';
import {
  ADD_DIMENSIONS,
  ADD_GROUP_BY, ADD_JOIN_DIMENSIONS, ADD_JOIN_MEASURES, ADD_MEASURES, FETCHED_REPORT, REMOVE_DIMENSIONS,
  REMOVE_GROUP_BY,
  REMOVE_JOIN_DIMENSIONS, REMOVE_JOIN_MEASURES,
  REMOVE_MEASURES,
  SOURCE_WIZARD
} from '../constants';
import { ReportTable } from '../types/reportTableModel';
import { ReportAction } from '../actions/report';
import { JoinDimensionsAction, JoinMeasuresAction } from '../actions/joinColumn';
import { DimensionsAction } from '../actions/dimension';

export function reportReducer(state: ReportTable = new ReportTable(),
                              action: ReportAction | DimensionsAction | JoinDimensionsAction |
                              MeasuresAction | JoinMeasuresAction | GroupByAction): ReportTable {
  let addingColumns: boolean = [ADD_DIMENSIONS,
    ADD_JOIN_DIMENSIONS,
    ADD_MEASURES,
    ADD_JOIN_MEASURES,
    ADD_GROUP_BY
  ].indexOf(action.type) !== -1;

  let removingColumnsFromWizard: boolean = ([REMOVE_DIMENSIONS,
      REMOVE_JOIN_DIMENSIONS,
      REMOVE_MEASURES,
      REMOVE_JOIN_MEASURES,
      REMOVE_GROUP_BY
    ].indexOf(action.type) !== -1) &&
    action.type !== FETCHED_REPORT &&
    action.source === SOURCE_WIZARD;

  // clear the report if adding columns or removing columns from wizard
  if (addingColumns || removingColumnsFromWizard) {
    return new ReportTable();
  }

  switch (action.type) {
    case FETCHED_REPORT:
      return action.report;
    default:
      return state;
  }
}