import { ColumnMeta, ColumnMetaXCompact, ColumnMetaYCompact } from './columnMetaModel';
import { CHART_TYPE } from '../constants';
import { SavedQuery } from '../types/savedQueryModel';
import { BaseModel, NestedEntityMap } from '../types/baseModel';
import { ChartData } from '../types/chartDataModel';

export class Chart extends BaseModel {
  protected properties: string[] = [
    'chartId',
    'queryId',
    'type',
    'selectedX',
    'selectedY',
    'created',
    'modified',
    'title',
    'query',
    'selectedXCompact',
    'selectedYCompact',
    'deleted',
    'chartData',
    'limit',
  ];
  protected nestedObjects: NestedEntityMap = {
    query: SavedQuery,
    chartData: ChartData
  };

  chartId: string;
  queryId: string;
  type: CHART_TYPE;
  selectedX: Array<ColumnMeta>;
  selectedY?: ColumnMeta;
  created?: Date;
  modified?: Date;
  title: string;
  displayType?: CHART_TYPE;
  selectedXCompact?: ColumnMetaXCompact;
  selectedYCompact?: ColumnMetaYCompact;
  saved: boolean;
  shouldGenerateChart: boolean; // has Generate Chart been clicked from the modal? If it has, generate the chart again
  query?: SavedQuery;
  chartData?: ChartData;
  deleted: boolean;
  limit?: number;

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}
