import React, { useState } from 'react';
import { Action, ActionsDropdown } from '@eds/actions';
import { Badge, BadgeProps } from '@eds/badge';
import { Box } from '@eds/box';
import { ColumnSort, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@eds/table';
import { DeleteIcon, EditIcon, PlayIcon } from '@eds/icon';
import { EmptyFolderSpot } from '@eds/illustration';
import { EmptyState } from '@eds/empty-state';
import { Flex } from '@eds/flex';
import { Toast, toastMessage } from '@eds/toast';
import { css } from '@emotion/css';
import { get } from 'lodash';

import { ReportingRoutes } from 'src/constants/routes';
import { ScheduledReport, ScheduledReportStatus } from 'src/features/scheduled-reports';
import { cssOverrides } from 'src/features/ui';
import { getPermissions } from 'src/features/utils';

import { TableColumn, TableColumnConfig } from './scheduled-reports-list.types';
import { useRunScheduledReportMutation } from './scheduled-reports-list.queries';

const ACTIONS_STRING = 'actions';

const columnSettings = {
  occurrence: { width: '8rem' },
  status: { width: '5rem' },
  nextScheduleDate: { width: '15%' },
  endDate: { width: '15%' },
  actions: { width: '3.5rem' },
};

const tone: Record<ScheduledReportStatus, BadgeProps['tone']> = {
  Active: 'positive',
  Paused: 'caution',
  Ended: 'neutral',
};

type ScheduledReportsListTableProps = {
  onDelete: (scheduledReportId: string) => void;
  onEdit: (scheduledReportId: string) => void;
  onSort: (sort: ColumnSort) => void;
  scheduledReports: Array<ScheduledReport>;
  sortData: ColumnSort;
  tableColumnEntries: Array<[TableColumn, TableColumnConfig]>;
};

export const ScheduledReportsListTable = ({
  onDelete,
  onEdit,
  onSort,
  scheduledReports,
  sortData,
  tableColumnEntries,
}: ScheduledReportsListTableProps) => {
  const [processingMessage, setProcessingMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { mutate } = useRunScheduledReportMutation(() => {
    setSuccessMessage(toastMessage('Success'));
  });

  const canRunScheduledReport = getPermissions().report.canRunScheduleReport;

  return (
    <Box className={css({ ...cssOverrides.theadButton(), ...cssOverrides.tableScrollWrapButton() })}>
      <Table columnSettings={columnSettings} minWidth="50rem" tableBorder truncate width="100%">
        <TableHead>
          <TableRow>
            {tableColumnEntries.map(([columnName, { label }]) => (
              <TableHeaderCell key={columnName} columnName={columnName} onSortChange={onSort} sort={sortData}>
                {label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell columnName={ACTIONS_STRING}>{}</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduledReports.length > 0 ? (
            scheduledReports.map(item => {
              const tableActions: Action[] = [
                {
                  icon: EditIcon,
                  label: 'Edit',
                  onClick: () => onEdit(item.id),
                },
                {
                  icon: PlayIcon,
                  label: 'Run now',
                  onClick: () => {
                    setProcessingMessage(toastMessage('Running scheduled report'));
                    mutate(item.id);
                  },
                },
                {
                  icon: DeleteIcon,
                  label: 'Delete',
                  onClick: () => onDelete(item.id),
                  tone: 'critical',
                },
              ];

              const availableActions = canRunScheduledReport ? tableActions : [tableActions[0], tableActions[2]];

              return (
                <TableRow key={item.id}>
                  {tableColumnEntries.map(([columnName, { format, key }]) => (
                    <TableCell key={`${item.id}-${columnName}`} columnName={columnName}>
                      {columnName === 'status' ? (
                        <Flex>
                          <Badge
                            tone={tone[item.status] || 'neutral'}
                            label={item.status}
                            weight={item.status === 'Active' ? 'bold' : 'subtle'}
                          />
                        </Flex>
                      ) : (
                        format(get(item, key))
                      )}
                    </TableCell>
                  ))}
                  <TableCell columnName={ACTIONS_STRING}>
                    <Box
                      className={css(cssOverrides.button('ghost'))}
                      data-tracking="scheduled-reports-list-actions"
                      minHeight="2rem"
                    >
                      <ActionsDropdown actions={availableActions} size="small" tone="ghost" />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Box marginY="large">
                  <EmptyState
                    description="Try a different filter or search value."
                    spotIllustration={EmptyFolderSpot}
                    title="No results found"
                  />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {processingMessage && <Toast message={processingMessage} tone="positive" />}
      {successMessage && (
        <Toast
          linkHref={ReportingRoutes.reportDownloads}
          linkText="Go to downloads"
          message={successMessage}
          tone="positive"
        />
      )}
    </Box>
  );
};
