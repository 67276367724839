import React, { Dispatch, SetStateAction } from 'react';
import { Flex } from '@eds/flex';
import { FilterBar } from '@eds/filtering';
import { SelectDropdown } from '@eds/select-dropdown';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';
import { isElmoSuperAdmin } from 'src/features/utils';

import { DashboardType } from './dashboards-list.queries';

type DashboardsListFiltersProps = {
  dashboardTypeFilter: DashboardType[];
  keywordFilter: string;
  loading: boolean;
  setDashboardTypeFilter: Dispatch<SetStateAction<DashboardType[]>>;
  setKeywordFilter: Dispatch<SetStateAction<string>>;
};

const defaultDashboardTypeFilterOptions = [
  {
    label: 'Created by you',
    value: 2 as const,
  },
  {
    label: 'Suggested by ELMO',
    value: 8 as const,
  },
  {
    label: 'Shared with you',
    value: 16 as const,
  },
  {
    label: 'Shared by you',
    value: 32 as const,
  },
];

export const DashboardsListFilters = ({
  dashboardTypeFilter,
  keywordFilter,
  loading,
  setDashboardTypeFilter,
  setKeywordFilter,
}: DashboardsListFiltersProps) => {
  const canClearFilters = keywordFilter.length + dashboardTypeFilter.length > 0;

  const onDashboardTypeChange = (option: { label: string; value: DashboardType }[] | null) => {
    setDashboardTypeFilter(option?.map(({ value }) => value) || []);
  };

  const dashboardTypeFilterOptions = isElmoSuperAdmin()
    ? [defaultDashboardTypeFilterOptions[0], defaultDashboardTypeFilterOptions[1]]
    : defaultDashboardTypeFilterOptions;

  const selectedDashboardTypes = dashboardTypeFilter.map(dashboardType =>
    dashboardTypeFilterOptions.find(({ value }) => dashboardType === value)
  );

  return (
    <Flex className={css(cssOverrides.button('neutral'))}>
      <FilterBar
        clearable={canClearFilters}
        keywordFilter={{
          placeholder: 'Search by name or description...',
          value: keywordFilter,
          onChange: event => setKeywordFilter(event.target.value),
          onClear: () => setKeywordFilter(''),
          width: '20rem',
        }}
        loading={loading}
        onClear={() => {
          setKeywordFilter('');
          setDashboardTypeFilter([]);
        }}
      >
        <SelectDropdown
          clearButton
          hideSearchInput
          isMulti
          items={dashboardTypeFilterOptions}
          label="Type"
          onChangeMulti={onDashboardTypeChange}
          value={selectedDashboardTypes}
        />
      </FilterBar>
    </Flex>
  );
};
