import { SET_ROUTES } from '../constants/routes';
import { RoutesAction } from '../actions/routes';

export function routesReducer(state: any = null, action: RoutesAction) {
  switch (action.type) {
    case SET_ROUTES: {
      return action.routes;
    }
    default: {
      return state;
    }
  }
}