import { Measure } from './measureModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES, NUM_PAGES } from '../constants';
import { GroupByForQuery } from '../services/reportService';
import { Dimension } from './dimensionModel';
import { Filter } from './filterModel';
import { OrderBy } from './orderByModel';
import { CustomColumn } from './customColumnModel';
import { Transformation } from './transformationModel';

/**
 * Used only when POSTing payloads to the backend.
 * This is a condensed version of ColumnMeta, which determines the order in which the generated report columns
 * are displayed.
 */
export class ColumnMetaQuery {
  dimensionName?: string;
  measureName?: string;
  baseViewName: string;
  joinName?: string;
  forGroupBy?: boolean;
}

export class Query {
  module: string;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  view: string;
  joins: string[];
  dimensions: string[];
  joinDimensions: {[joinName: string]: Dimension[]}[];
  measures: string[];
  joinMeasures: {[joinName: string]: Measure[]}[];
  groupBys: GroupByForQuery[];
  filterGroups: Array<Array<Filter>>;
  filters: Filter[];
  orderBys: OrderBy[];
  limit: number;
  offset: number;
  columnOrder: ColumnMetaQuery[];
  labelAliases?: Record<string, string>;
  customColumns?: Array<CustomColumn>;
  transformations?: Array<Transformation>;

  constructor() {
    Object.assign(this, {
      module: { id: '', name: '' },
      dataType: '',
      view: '',
      joins: [],
      dimensions: [],
      joinDimensions: [],
      measures: [],
      joinMeasures: [],
      groupBys: [],
      filterGroups: [],
      filters: [],
      orderBys: [],
      limit: NUM_PAGES,
      offset: 0,
      columnOrder: []
    });
  }
}
