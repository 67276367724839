import axios, { AxiosResponse, CancelToken } from 'axios';
import { SavedQuery } from '../types/savedQueryModel';

export type ShareReportResponseType = {
  reportId: string,
  sharedWith: Array<string>;
  sharedBy: string;
};
export async function apiShareReport(queryId: string, sharedWith: Array<string>): Promise<ShareReportResponseType> {
  const response: AxiosResponse = await axios.post(`/api/share-report/${queryId}`, { sharedWith });
  return response.data;
}

export type ShareReportConfigType = {
  reportId: string;
  sharedWith: Array<string>;
  sharedBy: string;
};
export async function apiGetShareReportConfig(queryId: string): Promise<ShareReportConfigType> {
  const response = await axios.get(`/api/share-report/${queryId}`);

  return response.data;
}

export type ShareReportUserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string | null;
  role: string;
};
export type ShareReportUsersListType = Array<ShareReportUserType>;
export type ShareReportUsersListParamsType = {
  text?: string;
  ids?: string[];
};
export async function apiGetShareReportUsersList(
  params?: ShareReportUsersListParamsType,
  cancelToken?: CancelToken,
): Promise<ShareReportUsersListType> {
  const response = await axios.get('/api/share-report/users', { params, cancelToken });

  return response.data;
}

export async function apiDeleteSharedReport(queryId: string) {
  const response: AxiosResponse = await axios.delete(`api/share-report/${queryId}`);
  return response.data;
}

export function reportSharedWithMe (savedQuery: SavedQuery): boolean {
  return !!savedQuery.sharedBy && !savedQuery.permissions.canShare;
}

export function reportSharedByMe (savedQuery: SavedQuery): boolean {
  return !!savedQuery.sharedWith && savedQuery.sharedWith.length > 0 && !!savedQuery.permissions.canShare;
}
