import * as React from 'react';
import classnames from 'classnames';
import { Flex } from '@eds/flex';
import { Button } from '@eds/button';
import { Fieldset } from '@eds/fieldset';
import { yupResolver } from '@hookform/resolvers/yup';

import CollapseCard from '../../CollapseCard';

import './style.scss';
import { SelectInputField } from '../../formComponents/selectInputField';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Box } from '@eds/box';
import {
  DateFormats,
  DatetimeConfiguration,
  DatetimeConfigurations,
  DatetimeFormats,
  DatetimeWithSecondsFormats,
} from '../../../../types/transformationModel';
import datetimeConfigurationValidationSchema from './validation';
import { useDispatch, useSelector } from 'react-redux';
import { setDatetimeConfiguration } from '../../../../actions/transformations';
import { getReportJson } from '../../../../actions/report';
import { AppState } from '../../../../redux';
import { FieldColumns } from '@eds/field';

interface Props {
  isOpen: boolean;
  isCreateNew?: boolean;
  reportId: string | null;
  readOnly?: boolean;
  fieldOptions: Array<{
    label: string;
    type: string;
    value: string;
  }>;
  toggle: () => void;
  data?: DatetimeConfiguration;
}

const DatetimeConfigurationCreateEdit = ({
  isOpen,
  isCreateNew,
  data: _data,
  fieldOptions,
  reportId,
  toggle,
}: Props) => {
  const dispatch = useDispatch();

  /**
   * For new form submission we always want undefined for data,
   * as no data should exist
   */
  const data = isCreateNew ? undefined : _data;

  const { transformations } = useSelector((state: AppState) => state);

  const usedDimensions = transformations.reduce(
    (result, transformation: DatetimeConfigurations) => {
      return [
        ...result,
        ...transformation.configuration.map((configuration) => configuration.dimension),
      ];
    },
    [] as Array<string>,
  );
  const availableFieldOptions = fieldOptions
    .filter((item) => (data && data.dimension === item.value) || !usedDimensions.includes(item.value));

  const {
    control,
    formState: {
      errors: clientSideErrors,
      isSubmitting,
    },
    handleSubmit,
    watch,
    reset,
  } = useForm<DatetimeConfiguration>({
    mode: 'onBlur',
    defaultValues: isCreateNew ? new DatetimeConfiguration() : undefined,
    resolver: yupResolver(datetimeConfigurationValidationSchema),
  });

  const formValues = watch();

  useEffect(
    () => {
      if (isOpen) {
        reset(data || new DatetimeConfiguration());
      }
    },
    [reset, data, isOpen],
  );

  return (
    <CollapseCard className={classnames('report-transformation', { 'is-open': isOpen })} isOpen={isOpen}>
      <form
        onSubmit={handleSubmit(async (submission) => {
            dispatch(setDatetimeConfiguration(submission));
            dispatch(getReportJson(false, reportId as any));
            reset();
            toggle();
        })}
      >
        <Flex flexDirection="column" padding="large">
          <Fieldset legend="Date time" legendStyle="group">
            <Box width="66%">
              <FieldColumns>
                <SelectInputField
                  required={true}
                  id="dimension"
                  label="Field to apply date time configuration to"
                  control={control}
                  errors={clientSideErrors}
                  items={availableFieldOptions}
                />
                { formValues.dimension && (
                  <SelectInputField
                    required={true}
                    // @ts-expect-error
                    id="format"
                    label="Date format"
                    control={control}
                    errors={clientSideErrors}
                    placeholder="Select..."
                    items={[
                      {
                        label: 'Date',
                        options: Object.keys(DateFormats)
                          .map((format) => ({ label: format, value: DateFormats[format] }))
                      },
                      {
                        label: 'Date time',
                        options: Object.keys(DatetimeFormats)
                          .map((format) => ({ label: format, value: DatetimeFormats[format] }))
                      },
                      {
                        label: 'Date time with seconds',
                        options: Object.keys(DatetimeWithSecondsFormats)
                          .map((format) => ({ label: format, value: DatetimeWithSecondsFormats[format] }))
                      }
                    ]}
                  />
                )}
              </FieldColumns>
            </Box>
          </Fieldset>
          <Flex gap="small" justifyContent="start" marginTop="small">
              <Button
                label="Cancel"
                tone="neutral"
                size="small"
                onClick={() => {
                  reset();
                  toggle();
                }}
                disabled={isSubmitting}
              />
              <Button
                label="Apply"
                type="submit"
                size="small"
                loading={isSubmitting}
              />
          </Flex>
        </Flex>
      </form>
    </CollapseCard>
  );
};

export default DatetimeConfigurationCreateEdit;
