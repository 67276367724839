import { GroupByAction } from '../actions/groupBy';
import { GroupBy } from '../types/groupByModel';
import { ADD_GROUP_BY, REMOVE_GROUP_BY } from '../constants';
import { ReportBuilderAction } from '../actions/reportBuilder';

export function groupBysReducer(state: GroupBy[] = [], action: GroupByAction | ReportBuilderAction): GroupBy[] {
  switch (action.type) {
    case ADD_GROUP_BY:
      return [...state, action.groupBy];
    case REMOVE_GROUP_BY:
      return state.filter((gb: GroupBy) => {
        return !GroupBy.groupByIsEqual(gb, action.groupBy);
      });
    default:
      return state;
  }
}