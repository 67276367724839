import { ColumnMeta } from '../../../types/columnMetaModel';
import { CustomColumn } from '../../../types/customColumnModel';

export const itemIdPrefix = 'draggable-item_';

export function isCustomColumn (object: ColumnMeta | CustomColumn): object is CustomColumn {
  return 'id' in object;
}

export function isColumnMeta(object: ColumnMeta | CustomColumn): object is ColumnMeta {
  return !isCustomColumn(object);
}

export function getColumnId({ dimension, join, measure, view }: ColumnMeta) {
  const leftKey = join ? join.fullJoinName : view.name;
  const rightKey = (dimension && dimension.name) || (measure && measure.name) || '';

  return `${leftKey}.${rightKey}`;
}