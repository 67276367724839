import * as React from 'react';
import { Tooltip } from 'elmo-elements';

import DataTypeSelect from '../DataTypeSelect';
import GenerateChart from '../GenerateChart';
import { SavedQuery } from '../../../types/savedQueryModel';
import ActionButtons from '../ActionButtons';
import ExportButton from '../ExportButton';
import SaveQuery from '../SaveQuery';
import { EditIcon } from '../../../components/NewReportBuilder/Icons/EditIcon';
import { reportSharedWithMe } from '../../../services/shareReportService';
import { REPORT_TYPE_SUGGESTION } from '../../../constants';

import './style.css';

type ReportBuilderPanelProps = {
  title?: string;
  encodedQuery?: string;
  showGenerateEditChartButton?: boolean;
  loadedQuery?: SavedQuery;
  queryId: string;
  readOnly?: boolean;
};

export default function ReportBuilderPanel(props: ReportBuilderPanelProps) {
  const { title, encodedQuery, showGenerateEditChartButton = false, loadedQuery, queryId, readOnly = false } = props;
  const [saveAs, setSaveAs] = React.useState(false);
  const [isSaveQueryModalOpened, setIsSaveQueryModalOpened] = React.useState(false);

  const firstSave = !!encodedQuery && !loadedQuery;
  const isReportSharedWithMe = !!loadedQuery && reportSharedWithMe(loadedQuery);
  const showSaveButton = !isReportSharedWithMe && loadedQuery && loadedQuery.permissions &&
    loadedQuery.permissions.canSave;
  const showEditButton = showSaveButton && !readOnly;
  const isSuggestedReport = !!loadedQuery && loadedQuery.reportType === REPORT_TYPE_SUGGESTION;

  const reportTitle = title || 'Untitled';

  const onEditTitle = () => {
    setIsSaveQueryModalOpened(true);
    setSaveAs(firstSave);
  };

  const openSaveQueryModal = () => {
    setIsSaveQueryModalOpened(true);
    setSaveAs(true);
  };

  const renderReportTitle = () => {
    if (reportTitle.length > 40) {
      return (
        <Tooltip title={reportTitle}>
          <h1 role="heading">
            {reportTitle}
          </h1>
        </Tooltip>
      );
    }

    return (
      <h1 role="heading">
        {reportTitle}
      </h1>
    );
  };

  return (
    <div className="row no-gutters">
      <div className="col-12">
        <div className="builderPanel" data-testid="page-title">
          <div className="titleWrapper">
            {renderReportTitle()}
            {isSuggestedReport &&
              <div className="suggested-tag">
                Suggested
              </div>
            }
            {showEditButton &&
              <button
                onClick={onEditTitle}
                className="btn edit-title-btn"
                disabled={!encodedQuery}
              >
                <EditIcon />
              </button>
            }
          </div>
          <div className="controlWrapper">
            {!readOnly &&
              <DataTypeSelect disabled={!!encodedQuery}/>
            }
            {showGenerateEditChartButton &&
              <GenerateChart disabled={!encodedQuery} />
            }
            <ActionButtons
              disabled={!encodedQuery}
              queryId={queryId}
              loadedQuery={loadedQuery}
              openSaveQueryModal={openSaveQueryModal}
              readOnly={readOnly}
            />
            <ExportButton disabled={!encodedQuery} queryId={queryId}/>
            <SaveQuery
              loadedQuery={loadedQuery}
              disabled={!encodedQuery}
              showModal={isSaveQueryModalOpened}
              saveAs={saveAs}
              setShowModal={(showModal, isSaveAs) => {
                setIsSaveQueryModalOpened(showModal);
                setSaveAs(isSaveAs);
              }}
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
