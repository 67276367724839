import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SelectPropsOptions, SelectPropsOption } from 'elmo-elements/Select';

import { fetchModules } from '../../services/reportService';
import { fetchedViews, storeModule } from '../../actions/module';
import { AppState } from '../../redux';
import { getViewsAndStore } from '../../actions/report';
import { Module } from '../../types/moduleModel';
import { SuggestedReportService } from '../../services/suggestedReportService';
import { setSuggestionsOpen } from '../../actions/suggestions';
import { AuthService } from '../../services/authService';
import ElmoSelect from '../../components/NewReportBuilder/ElmoSelect';
import { LocalStorageService } from '../../services/localStorageService';
import { SUGGESTED_REPORT_CLICKED } from '../../constants';

export interface ModuleSelectProps {
  dispatch: Dispatch<any>;
  selectedModule: Module;
  modules: Module[];
  onModuleSelect?: (module: Module) => void;
  reportId?: string;
  authService: AuthService | null;
}

const mapStateToProps = (state: AppState) => ({
  selectedModule: state.module,
  modules: state.modules,
  authService: state.authService,
});

export interface ModuleSelectState {
  modulesOptions: SelectPropsOptions;
  selectedValue: SelectPropsOption | null;
}

class ModuleSelectComponent extends React.Component<ModuleSelectProps, ModuleSelectState> {
  protected suggestedReportService: SuggestedReportService;

  timer: number;

  constructor(props: ModuleSelectProps) {
    super(props);

    this.state = {
      modulesOptions: [],
      selectedValue: null,
    };

    this.suggestedReportService = SuggestedReportService.getInstance();
  }

  componentDidUpdate(prevProps: ModuleSelectProps) {
    const { modules, selectedModule } = this.props;
    const isCrtSuperAdmin = (this.props.authService) ? this.props.authService.isCrtSuperAdmin() : false;

    // if the modules list has updated, create the tree
    const isModulesUpdated = modules !== prevProps.modules;
    const isSelectedModuleChanged = selectedModule.name !== prevProps.selectedModule.name;
    const shouldRebuildTree = isModulesUpdated || isSelectedModuleChanged;
    if (shouldRebuildTree) {
      let selectedValue = null;

      const options = this.props.modules.map(module => {
        const isNewModule = module.type === 'redshift';
        const option = {
          value: module.name,
          label: isCrtSuperAdmin && isNewModule ? `${module.label} - new` : module.label
        };
        if (this.props.selectedModule.name === module.name) {
          selectedValue = option;
        }
        return option;
      });

      this.setState({
        modulesOptions: options,
        selectedValue,
      });
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchModules(this.props.reportId));
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onModuleSelect = (value: SelectPropsOption) => {
    const module = this.props.modules.find(v => v.name === value.value);

    if (module) {
      this.props.dispatch(storeModule(module));
      // clear the views in the store as they are no longer relevant, since the selected module has changed
      this.props.dispatch(fetchedViews([]));
      this.props.dispatch(getViewsAndStore());
    }

    // When a module is clicked, always open the suggestions modal and get the suggestions from API
    // Due to the existence of SuggestedReportsPopoverController, we have to run this on next event cycle
    this.timer = setTimeout(() => {
      const hasClickedSuggestedReport: boolean = LocalStorageService.getItem(SUGGESTED_REPORT_CLICKED);

      if (!hasClickedSuggestedReport) {
        this.props.dispatch(setSuggestionsOpen(true));
      }

      this.props.dispatch(this.suggestedReportService.getSuggestionsModule());
    });

    if (this.props.onModuleSelect && module) {
      this.props.onModuleSelect(module);
    }
  }

  render() {
    return (
      <div className="module-select-container">
        <ElmoSelect
          className="module-select"
          placeholder="Select module"
          options={this.state.modulesOptions}
          onChange={this.onModuleSelect}
          value={this.state.selectedValue}
        />
      </div>
    );
  }
}

const ModuleSelect = connect(
  mapStateToProps
)(ModuleSelectComponent);

export default ModuleSelect;
