import { Record, List } from 'immutable';
import { ConfigJoin } from '../../types/config/configJoinModel';

export class ConfigFilter {
  dimensionName: string;
  value: any;
  type: string;
}

export interface ConfigRule {
  filters: List<Record<ConfigFilter>>;
}

export const ConfigFilterRecord =
  Record<ConfigFilter>({
    dimensionName: '',
    value: '',
    type: ''
  });

export class ConfigRelation {
  base: boolean;
  joins: List<Record<ConfigJoin>>;
  label: string;
  tableName: string;
  derivedTable?: {
    sql: string;
  };
  rules: ConfigRule;

}

// Immutable factories to create Record objects
export const ConfigRelationRecord =
  Record<ConfigRelation>({
    base: false,
    joins: List<Record<ConfigJoin>>(),
    label: '',
    tableName: '',
    derivedTable: {
      sql: ''
    },
    rules: {
      filters: List<Record<ConfigFilter>>()
    }
  });
