import * as React from 'react';
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { Menu } from 'elmo-elements';
import { redirect } from '../../services/utilityService';
import { MenuService } from '../../services/menuService';
import { AuthService } from '../../services/authService';

import { ElmoMenuData, SubmenuItemType } from '../../types/elmoMenuModel';

interface Props extends RouteComponentProps<any> {
  navigation: ElmoMenuData;
  isLoading: boolean;
  updateAppMenuStatus: () => void;
  setCurrentSubmenuItem: (submenuItem: SubmenuItemType) => void;
}

class ElmoMenuWrapper extends React.Component<Props> {
  protected appMenuAdminKey = 'app.menu.admin';

  onLogoClicked = () => {
    const { navigation } = this.props;
    if (!navigation || !navigation.branding) {
      return;
    }

    redirect(navigation.branding.logoLink);
  }

  /**
   * Add custom handler for the "Switch to my user" link.
   * Clear token to request a new token for previous logged in user.
   *
   * @see CRT-2526
   */
  handleSwitchbackCallback = (item: any) => () => {
    AuthService.getInstance().removeToken();
    redirect(item.url);
  }

  hadleLogoutCallback = () => () => {
    AuthService.getInstance().logout();
  }

  /**
   * Handle click to submenu item:
   *  - custom handler for dashboard link
   *  - open external link in new tab
   *  - for the rest link open with location.href
   *
   * @param {SubmenuItemType} item
   */
  handleSubmenuItemClick = (item: SubmenuItemType) => () => {
    // all link that contains http schema should open in new tab.
    if (item.url.includes('http')) {
      return window.open(item.url, '_blank');
    }
    return location.href = item.url;
  }

  /**
   * Source: from elmo-navigation-menu project
   */
  getUserMenu() {
    const { navigation } = this.props;
    if (!navigation) {
      return [];
    }

    const {
      navigation: {
        menu: { user }
      }
    } = this.props;

    // remove admin item from the menu, we are presenting the admin info in the user details object
    const userMenu = user.filter((n: any) => n.key !== this.appMenuAdminKey);

    return userMenu.map((item: any) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
      handleClick: item.key === 'user.action.switchBack' ?
        this.handleSwitchbackCallback(item) :
        item.key === 'app.menu.signOut' ?
        this.hadleLogoutCallback() :
        undefined,
    }));
  }

  /**
   * Source: from elmo-navigation-menu project
   */
  getMainItems() {
    const { navigation } = this.props;
    if (!navigation) {
      return [];
    }

    const {
      navigation: {
        menu: { modules },
        status: { location }
      }
    } = this.props;

    return modules.map((item: any) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
      isSelected: item.key === (location && location.key)
    }));
  }

  checkIfSubmenuItemSelected = (submenuItemUrl: string, exact: boolean) => {
    const currentLocation = this.props.location.pathname;

    return !!matchPath(currentLocation, {
      path: submenuItemUrl,
      exact,
    } as any);
  }

  getCurrentSubmenuItem = (subModules: SubmenuItemType[]) => {
    return subModules.find(item => {
      const relatedMenuServiceItem = MenuService.MENU_ITEMS[item.icon as string];

      return this.checkIfSubmenuItemSelected(item.url, !!relatedMenuServiceItem && !!relatedMenuServiceItem.exact);
    });
  }

  mapMenuSubmodules = (subModules: SubmenuItemType[]) => {
    return subModules.map((item) => {
      const relatedMenuServiceItem = MenuService.MENU_ITEMS[item.icon as string];
      return {
        title: item.title,
        url: item.url,
        icon: relatedMenuServiceItem && relatedMenuServiceItem.icon,
        isSelected: this.checkIfSubmenuItemSelected(
          item.url,
          !!relatedMenuServiceItem && !!relatedMenuServiceItem.exact
        ),
        handleClick: this.handleSubmenuItemClick(item),
      };
    });
  }

  getSubMenuItems = () => {
    const { navigation } = this.props;
    if (!navigation) {
      return [];
    }
    const subModules = navigation.menu.subModules;

    return subModules ? this.mapMenuSubmodules(subModules) : [];
  }

  /**
   * Source: from elmo-navigation-menu project
   */
  getAdminInfo() {
    const {
      navigation: {
        menu: { user }
      }
    } = this.props;
    return user.filter((n: any) => n.key === this.appMenuAdminKey).shift();
  }

  /**
   * Source: from elmo-navigation-menu project
   */
  getUserInfo() {
    const { navigation } = this.props;
    if (!navigation) {
      return undefined;
    }

    const {
      navigation: {
        user,
        status: { location }
      }
    } = this.props;

    const { name, img } = user;

    // handle admin information
    const adminInfo = this.getAdminInfo();
    let isAdmin = false;
    let adminUrl = '';
    let isAdminSelected = false;

    if (adminInfo && 'url' in adminInfo) {
      isAdmin = true;
      adminUrl = adminInfo.url;
      isAdminSelected = (location && location.key) === adminInfo.key;
    }

    return {
      name,
      avatar: img,
      isAdmin,
      adminUrl,
      isAdminSelected
    };
  }

  onMenuToggle = () => {
    this.props.updateAppMenuStatus();
  }

  storeCurrentSubmenuItem = () => {
    const { navigation, setCurrentSubmenuItem } = this.props;
    const currentSubmenuItem = this.getCurrentSubmenuItem(navigation.menu.subModules);

    if (!!currentSubmenuItem) {
      setCurrentSubmenuItem(currentSubmenuItem);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { navigation } = this.props;

    if (navigation !== prevProps.navigation && !!navigation) {
      this.storeCurrentSubmenuItem();
    }
  }

  componentDidMount() {
    const { navigation } = this.props;

    if (!!navigation) {
      this.storeCurrentSubmenuItem();
    }
  }

  render() {
    const {
      navigation,
      isLoading
    } = this.props;

    const logo = navigation && navigation.branding ? navigation.branding.logo : null;
    const theme = navigation && navigation.branding ? navigation.branding.theme : undefined;
    const mobileLogo = navigation && navigation.branding ? navigation.branding.smallLogo : null;

    return (
      <Menu
        logo={logo}
        asSubMenu={true}
        menuItems={this.getMainItems()}
        subMenuItems={this.getSubMenuItems()}
        userDetails={this.getUserInfo()}
        userMenu={this.getUserMenu()}
        theme={theme}
        isLoading={isLoading}
        logoHref={this.onLogoClicked}
        mobileLogo={mobileLogo}
        menuOpened={this.onMenuToggle}
        menuClosed={this.onMenuToggle}
        footerMessage={navigation && navigation.footerMessage}
      />
    );
  }
}

export default withRouter(ElmoMenuWrapper);
