import { useState } from 'react';

export const useScheduledReportsPage = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [scheduledReportToDelete, setScheduledReportToDelete] = useState('');
  const [scheduledReportToEdit, setScheduledReportToEdit] = useState('');

  const startDeleting = (scheduledReportId: string) => {
    setIsDeleting(true);
    setScheduledReportToDelete(scheduledReportId);
  };

  const startEditing = (scheduledReportId: string) => {
    setIsEditing(true);
    setScheduledReportToEdit(scheduledReportId);
  };

  const stopDeleting = () => {
    setIsDeleting(false);
    setScheduledReportToDelete('');
  };

  const stopEditing = () => {
    setIsEditing(false);
    setScheduledReportToEdit('');
  };

  return {
    isDeleting,
    isEditing,
    scheduledReportToDelete,
    scheduledReportToEdit,
    startDeleting,
    startEditing,
    stopDeleting,
    stopEditing,
  };
};
