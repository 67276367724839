import * as React from 'react';
import classnames from 'classnames';
import {css} from '@emotion/css';

import {AppContext} from 'src/features/common';

import {SavedQuery} from '../../../types/savedQueryModel';
import {ReportTable} from '../../../types/reportTableModel';
import {Chart} from '../../../types/chartModel';
import {AppMenu} from '../../../types/appMenuModel';
import {View} from '../../../types/viewModel';
import {Dimension} from '../../../types/dimensionModel';
import {reportSharedWithMe} from '../../../services/shareReportService';
import {
  EVENT_REDRAW,
  FETCH_REPORT_JOBID_LONG_TIMEOUT,
  FETCHED_REPORT_JOBID,
  GENERATE_ACTION,
  GENERATE_REPORT_CANCELED,
  GENERATE_REPORT_EXPORT_MESSAGE,
  GENERATE_REPORT_FINISHED,
  GENERATE_REPORT_LONG_MESSAGE,
  GENERATE_REPORT_LONG_TIMEOUT,
  GENERATE_REPORT_MESSAGE,
  GENERATE_REPORT_START,
} from '../../../constants';
import ReportWizard from '../../../containers/NewReportBuilder/ReportWizard/ReportWizard';
import Report from '../../../containers/NewReportBuilder/Report';
import ChartSelect from '../../../containers/NewReportBuilder/ChartSelect';
import SelectFormModal from '../SelectFormsModal';
import {CollapseReportBuilderOpenedIcon} from '../Icons/CollapseReportBuilderOpenedIcon';
import {CollapseReportBuilderClosedIcon} from '../Icons/CollapseReportBuilderClosedIcon';

import './style.css';
import ReportBuilderPanel from '../../../containers/NewReportBuilder/ReportBuilderPanel';
import SavedReportLoading from '../ReportBuilder/SavedReportLoading/SavedReportLoading';
import ElmoButton from '../ElmoButton';
import {UserCapabilitiesType} from '../../../services/reportService';
import SuggestedReportsPopoverController from '../../../containers/NewReportBuilder/SuggestedReportsPopoverController';
import {SuggestedReportsPopoverProvider} from '../../../containers/NewReportBuilder/SuggestedReportsPopoverContext';
import ReportDataProcessing from '../ReportDataProcessing';

interface ReportingToolPageProps {
  loadedQuery?: SavedQuery;
  encodedQuery: string | undefined;
  selectedView: View;
  suggestionsOpen: boolean;
  generateReportStatus: GENERATE_ACTION;
  reportResult: ReportTable;
  chart: Chart;
  queryId: string;
  appMenu: AppMenu;
  reportTitle: string;
  userCapabilities: UserCapabilitiesType;
  selectedDimensions: Dimension[];
}

interface ReportingToolPageState {
  wizardOpen: boolean;
  exportMessage: GENERATE_REPORT_MESSAGE | GENERATE_REPORT_LONG_MESSAGE | GENERATE_REPORT_EXPORT_MESSAGE;
  showCancel: boolean;
  showExport: boolean;
  isSelectFormsModalOpened: boolean;
}
export class ReportingToolPage extends React.Component<ReportingToolPageProps, ReportingToolPageState> {
  static contextType = AppContext;
  context: React.ContextType<typeof AppContext>;

  protected filterSelectRef: any = React.createRef();
  protected reportVisualisationContainerRef = React.createRef<HTMLDivElement>();
  protected exportMessageTimeout: any;
  protected exportButtonTimeout: any;
  protected wizardOpeningDelay: number = 500;

  constructor(props: ReportingToolPageProps) {
    super(props);
    this.exportMessageTimeout = null;

    this.state = {
      wizardOpen: true,
      exportMessage: GENERATE_REPORT_MESSAGE,
      showCancel: false,
      showExport: false,
      isSelectFormsModalOpened: false,
    };
  }

  componentDidUpdate(prevProps: ReportingToolPageProps, prevState: ReportingToolPageState) {
    const { generateReportStatus, appMenu } = this.props;
    const { generateReportStatus: prevGenerateReportStatus, appMenu: prevAppMenu } = prevProps;
    const { wizardOpen } = this.state;

    if ((wizardOpen !== prevState.wizardOpen) || (appMenu.isOpened !== prevAppMenu.isOpened)) {
      // used setTimeout to catch chart container width after it set, between opening - closing wizard panel
      // Depends on transition-duration scss property (0.5s)
      setTimeout(() => this.redrawChart(), this.wizardOpeningDelay);
    }

    if (prevProps.reportResult.rows.length === 0
      && (prevProps.reportResult.rows.length !== this.props.reportResult.rows.length)) {
      this.setState({
        wizardOpen: false
      });
    }

    // If Generate Report has been clicked, start the clock for 60s. After 60s has elapsed, display a different message.
    if (prevGenerateReportStatus !== generateReportStatus && generateReportStatus === GENERATE_REPORT_START) {
      this.startExportMessageTimer();
    }

    // TODO: We need better mechanism to wait until we get the jobId from the queue
    // If Job Id has been fetched, start the clock for 2s. After 2s has elapsed, display a different message.
    if (prevGenerateReportStatus !== generateReportStatus && generateReportStatus === FETCHED_REPORT_JOBID) {
      this.setState({
        showCancel: true
      })

      this.startExportButtonTimer();
    }

    // If Report Generation completed, then reset the message and clear the timeout.
    if (prevGenerateReportStatus !== generateReportStatus && generateReportStatus === GENERATE_REPORT_FINISHED) {
      this.setState({
        showCancel: false
      });

      this.stopExportMessageTimer();
      this.stopExportButtonTimer();
    }
  }

  componentWillUnmount() {
    // If the component unmounted, clear the timeout.
    this.stopExportMessageTimer();
  }

  toggleSelectFormModal = () => {
    this.setState({
      isSelectFormsModalOpened: !this.state.isSelectFormsModalOpened,
    });
  }

  startExportMessageTimer = () => {
    this.exportMessageTimeout = setTimeout(
      () => {
        this.setState({
          exportMessage: GENERATE_REPORT_LONG_MESSAGE
        });
      },
      GENERATE_REPORT_LONG_TIMEOUT);
  }

  stopExportMessageTimer = () => {
    if (this.exportMessageTimeout) {
      clearTimeout(this.exportMessageTimeout);
      this.exportMessageTimeout = null;
      this.setState({
        exportMessage: GENERATE_REPORT_MESSAGE
      });
    }
  }

  startExportButtonTimer = () => {
    this.exportButtonTimeout = setTimeout(
        () => {
          this.setState({
            exportMessage: GENERATE_REPORT_EXPORT_MESSAGE,
            showExport: true
          });
        },
        FETCH_REPORT_JOBID_LONG_TIMEOUT);
  }

  stopExportButtonTimer = () => {
    if (this.exportButtonTimeout) {
      clearTimeout(this.exportButtonTimeout);
      this.exportButtonTimeout = null;
      this.setState({
        showExport: false
      });
    }
  }

  toggleWizard = () => {
    this.setState({
      wizardOpen: !this.state.wizardOpen
    });
  }

  onResetClicked = () => {
    let componentRef = this.filterSelectRef.current;
    if (componentRef && componentRef.clearDisplayFilters) {
      componentRef.clearDisplayFilters();
    }
  }

  renderToggleWizardButton() {
    const { wizardOpen } = this.state;

    const buttonIcon = wizardOpen ?
      <CollapseReportBuilderOpenedIcon /> :
      <CollapseReportBuilderClosedIcon />;

    const className = classnames(
      'collapseReportBuilderButton',
      this.context?.isModalOpen &&
        css({
          // NOTE: Lowest specificity that can override z-index set by 'collapseReportBuilderButton'
          '&&&&&&&': {
            zIndex: 0,
          }
        })
    );

    return (
      <ElmoButton
        className={className}
        type={wizardOpen ? 'default' : 'primary'}
        icon={buttonIcon}
        onClick={this.toggleWizard}
      />
    );
  }

  onGenerateSuccess = () => {
    this.setState({
      wizardOpen: false,
    });
  }

  renderReportOverlay(generateReportStatus: GENERATE_ACTION) {
    if (generateReportStatus !== GENERATE_REPORT_START &&
        generateReportStatus !== FETCHED_REPORT_JOBID &&
        generateReportStatus !== GENERATE_REPORT_CANCELED
    ) {
      return null;
    }

    return (
      <SavedReportLoading
        queryId={this.props.queryId}
        message={this.getExportMessage(generateReportStatus)}
        isOpened={
            generateReportStatus === GENERATE_REPORT_START ||
            generateReportStatus === FETCHED_REPORT_JOBID ||
            generateReportStatus === GENERATE_REPORT_CANCELED
        }
        showCancel={this.state.showCancel}
        showExport={this.state.showExport}
      />
    );
  }

  getExportMessage(generateReportStatus: GENERATE_ACTION): React.ReactElement {
    const { exportMessage } = this.state;

    const defaultMessage = (
      <div className="exportReportMessageContainer">
        <h2 className="title">Report is being generated...</h2>
      </div>
    );

    if (generateReportStatus !== FETCHED_REPORT_JOBID) {
      return defaultMessage;
    }

    switch (exportMessage) {
      case GENERATE_REPORT_MESSAGE: {
        return defaultMessage;
        break;
      }
      case GENERATE_REPORT_LONG_MESSAGE: {
        return (
          <div className="exportReportMessageContainer">
            <h2 className="title">Your report is taking longer than expected to generate</h2>
            <p className="body">We would encourage you to apply a filter to limit the report generation time</p>
          </div>
        );
        break;
      }
      case GENERATE_REPORT_EXPORT_MESSAGE: {
        return (
          <div className="exportReportMessageContainer">
            <h2 className="title">Report is being generated...</h2>
            <p className="body">Taking a while? Try exporting it instead</p>
          </div>
        );
        break;
      }

      default: {
        return defaultMessage;
      }
    }
  }

  getChartListenerElement = () => {
    return !!this.reportVisualisationContainerRef.current ? this.reportVisualisationContainerRef.current : undefined;
  }

  redrawChart = () => {
    // create and fire custom redraw event for manual chart redrawing
    if (this.reportVisualisationContainerRef.current) {
      const redrawEvent = new CustomEvent(EVENT_REDRAW, {
        bubbles: true,
        detail: {},
      });

      this.reportVisualisationContainerRef.current.dispatchEvent(redrawEvent);
    }
  }

  render() {
    const { generateReportStatus, encodedQuery, loadedQuery, reportTitle, userCapabilities } = this.props;
    const canManageFilter = !loadedQuery || (!!loadedQuery && loadedQuery.permissions &&
      loadedQuery.permissions.canManageFilter);
    const showGenerateEditChartButton = !loadedQuery || (!!loadedQuery && !reportSharedWithMe(loadedQuery) &&
      loadedQuery.permissions && loadedQuery.permissions.canManageChart);

    let wizardDisplayClass: string = this.state.wizardOpen ? '' : 'closed';
    let reportWizardWidthClass: string = this.props.selectedView.name ?
      'twoCols left-col ' :
      'oneCol left-col ';

    let reportWizardClass = reportWizardWidthClass + wizardDisplayClass;

    // TODO: Improve this, we don't want to add more deps only to display a border
    const hasSelectedDimensions = !!(this.props.selectedDimensions && this.props.selectedDimensions.length);

    return (
      <div className="reporting-tool-page">
        <ReportBuilderPanel
          title={reportTitle}
          encodedQuery={encodedQuery}
          showGenerateEditChartButton={showGenerateEditChartButton}
          queryId={this.props.queryId}
          loadedQuery={loadedQuery}
        />
        <div className="row no-gutters report-builder-content">
          <div className={reportWizardClass}>
            <SuggestedReportsPopoverProvider>
              <SuggestedReportsPopoverController>
                <ReportWizard
                  onResetClicked={this.onResetClicked}
                  onGenerateSuccess={this.onGenerateSuccess}
                  toggleSelectFormModal={this.toggleSelectFormModal}
                  reportId={this.props.queryId}
                  userCapabilities={userCapabilities}
                />
              </SuggestedReportsPopoverController>
            </SuggestedReportsPopoverProvider>
          </div>
          <div className="col right-col" ref={this.reportVisualisationContainerRef}>
            {this.renderToggleWizardButton()}
            {this.renderReportOverlay(generateReportStatus)}
            <div className="scroll">
              <ReportDataProcessing
                userCapabilities={userCapabilities}
                canManageFilter={canManageFilter}
              />
              <div className={classnames('reportArea', { 'has-dimensions': hasSelectedDimensions })}>
                {this.props.chart.chartId &&
                  <ChartSelect loadedQuery={loadedQuery} chartListenerElement={this.getChartListenerElement()} />
                }
                <Report
                  loadedQuery={loadedQuery}
                  readOnly={false}
                  userCapabilities={userCapabilities}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.isSelectFormsModalOpened &&
          <SelectFormModal
            toggleModal={this.toggleSelectFormModal}
            isOpened={this.state.isSelectFormsModalOpened}
          />
        }
      </div>
    );
  }
}
