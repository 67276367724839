import * as React from 'react';
import { FormItem, Input } from 'elmo-elements';

import { ChartForm } from './types';

type ChartTitleInputProps = {
  formData: ChartForm;
  handleFormDataChange: (formData: ChartForm) => void;
};

const ChartTitleInput = (props: ChartTitleInputProps) => {
  const handleFormChange = (event: React.FormEvent<HTMLSelectElement|HTMLInputElement>) => {
    const formData: ChartForm = props.formData;
    formData.title = event.currentTarget.type === 'checkbox' ?
      event.currentTarget['checked'] : event.currentTarget.value;
    props.handleFormDataChange(formData);
  };

  return (
    <FormItem label="Chart Title">
      <Input
        htmlType="text"
        ariaLabel="Group By"
        name="title"
        id="title"
        placeholder="Name your chart"
        onChange={handleFormChange}
        value={props.formData.title}
        data-testid="chart-title"
      />
    </FormItem>
  );
};

export default ChartTitleInput;
