import * as React from 'react';
import { Flex } from '@eds/flex';
import { Tooltip } from '@eds/tooltip';
import { VisuallyHidden } from '@eds/a11y';
import { tokens } from '@eds/core';
import { css } from '@emotion/css';
import SelectAllButton from '../SelectAllButton';
import { map } from 'lodash';
import classnames from 'classnames';
import ColumnSelectFilter from '../ColumnSelectFilter';
import { SelectFieldIcon } from '../Icons/SelectFieldIcon';
import { DeprecationIcon, GENERIC_FIELD_DEPRECATION_MESSAGE } from 'src/features/report-builder';
import './style.css';

export interface ColumnSelectItemGroup {
  label: string;
  items: ColumnSelectItem[];
}

export interface ColumnSelectItem {
  label: string;
  isDeprecated?: boolean;
  isSelected: boolean;
  onClick: () => void;
}

export interface ColumnSelectProps {
  title: string;
  isAggregate: boolean;
  itemGroups: ColumnSelectItemGroup[];
  onSelectAllClick: () => void;
  allSelected: boolean;
}

interface ColumnSelectState {
  filterText: string;
}

export class ColumnSelect extends React.Component<ColumnSelectProps, ColumnSelectState> {

  constructor(props: ColumnSelectProps) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      filterText: ''
    };
  }

  renderItems(items: ColumnSelectItem[]) {
    return map(items, (item: ColumnSelectItem, key: number) => {
      if (!item.label.trim().toLowerCase().includes(this.state.filterText)) {
        return null;
      }

      return (
        <div
          className={`item d-flex flex-row ${item.isSelected ? 'selected' : ''} ${css(
            item.isDeprecated && {
              '&&&&&&': { 
                backgroundColor: tokens.backgroundColor.cautionSubtle,
                color: tokens.color.neutralBold,
              },
            }
          )}`}
          key={key}
          onClick={item.onClick}
          aria-label={'Select ' + item.label}
          role="checkbox"
          aria-checked={item.isSelected}
          data-testid={'column-select-' + key}
        >
          {item.label}
          {item.isSelected &&
            (item.isDeprecated ? (
              <Flex position="relative">
                <Tooltip label={GENERIC_FIELD_DEPRECATION_MESSAGE} nonInteractiveChild ariaHidden={false} side="right">
                  <DeprecationIcon size="xsmall" />
                </Tooltip>
  
                <VisuallyHidden>{GENERIC_FIELD_DEPRECATION_MESSAGE}</VisuallyHidden>
              </Flex>
            ) : (
              <SelectFieldIcon />
            ))}
        </div>
      );
    });
  };

  renderItemGroups() {
    return map(this.props.itemGroups, (itemGroup: ColumnSelectItemGroup, key: number) => {
      return (
        <div 
          className={
            classnames('item-group', { 
              'empty': !itemGroup.items.length,
              'single': itemGroup.items.length === 1
            })
          }
          key={key}
          role="listbox"
          data-testid={'item-group-' + key}
        >
          {itemGroup.label &&
            <div className="title">
              {itemGroup.label}
            </div>
          }
          {
            this.renderItems(itemGroup.items)
          }
        </div>
      );
    });
  }

  onFilterUpdate = (text: string) => {
    this.setState({
      filterText: text.trim().toLowerCase()
    });
  }

  render() {
    return (
      <div className="column-select-nrb">
        <div className="header" data-tracking="select-all-fields">
          <SelectAllButton
            onClick={this.props.onSelectAllClick}
            active={this.props.allSelected}
            label={this.props.title}
          />
        </div>
        <div className="filter">
          <ColumnSelectFilter
            onFilterUpdate={this.onFilterUpdate}
          />
        </div>
        {
          this.renderItemGroups()
        }
      </div>
    );
  }
}
