import { NotificationService } from '../../services/notificationService';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../constants';

export const saveLayoutSuccessCallback = (notificationService: NotificationService | null) => {
  if (notificationService) {
    notificationService.addNotification(
      NOTIFICATION_SUCCESS,
      'Dashboard saved successfully',
    );
  }
};

export const saveLayoutFailedCallback = (notificationService: NotificationService | null) => {
  if (notificationService) {
    notificationService.addNotification(
      NOTIFICATION_SUCCESS,
      'Dashboard was unable to save. Please try again, and if problem persists please refresh the page.',
    );
  }
};

export const changeWidgetTitleSuccessCallback = (notificationService: NotificationService | null) => {
  if (notificationService) {
    notificationService.addNotification(
      NOTIFICATION_SUCCESS,
      'Widget has been updated successfully',
    );
  }
};

export const changeWidgetTitleFailedCallback = (notificationService: NotificationService | null) => {
  if (notificationService) {
    notificationService.addNotification(
      NOTIFICATION_ERROR,
      'Widget has been updated failure',
    );
  }
};

export const removeWidgetSuccessCallback = (notificationService: NotificationService | null) => {
  if (notificationService) {
    notificationService.addNotification(
      NOTIFICATION_SUCCESS,
      'Widget removed successfully',
    );
  }
};

export const refreshDashboardCacheFailedCallback = (notificationService: NotificationService | null) => {
  if (notificationService) {
    notificationService.addNotification(
      NOTIFICATION_ERROR,
      'Unable to refresh dashboard',
    );
  }
};
