import * as React from 'react';
import { map } from 'lodash';
import { SavedQuery } from '../../types/savedQueryModel';
import LoadingIcon from '../../shared-components/LoadingIcon';
import { SuggestedReportState } from '../../redux';
import './style.css';
import Tooltip from '../../shared-components/Tooltip';

interface SuggestedReportsContentProps {
  suggestedReports: SuggestedReportState;
  onSuggestionClick: (suggestion: SavedQuery) => void;
  loading: boolean;
}

interface SuggestedReportsContentState {
  selected: SavedQuery | null;
}

class SuggestedReportsContentComponent
  extends React.Component<SuggestedReportsContentProps, SuggestedReportsContentState> {

  constructor(props: SuggestedReportsContentProps) {
    super(props);

    this.state = {
      selected: null
    };
  }

  render() {
    return (
      <div className="suggestion-content">
        <h2>Current Selection</h2>
        <div className="selection">
          {this.renderCurrentSelection()}
        </div>
        {this.props.suggestedReports.tags.length > 0 &&
          <React.Fragment>
            <hr />
            <h2 role="heading">Suggested Reports</h2>
            {this.renderSuggestions()}
          </React.Fragment>}
      </div>
    );
  }

  renderCurrentSelection() {
    if (this.props.suggestedReports.tags.length === 0) {
      return (
        <span>
          Select a table or column
        </span>
      );
    }

    return map(this.props.suggestedReports.tagLabels, (tag: string, key: number) => {
      return (
        <span
          key={key}
          className="badge"
        >
          {tag}
        </span>
      );
    });
  }

  /**
   * When the tooltip is clicked (the tooltip behaviour for ipad) stop event propagation so the suggestion is
   * not selected
   * @param {React.MouseEvent<HTMLElement>} e
   */
  onTooltipClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }

  renderSuggestions() {
    if (this.props.suggestedReports.loading || this.props.loading) {
      return (
        <LoadingIcon/>
      );
    } else if (this.props.suggestedReports.suggestions.length === 0) {
      return (
        <div>
          No suggestions found.
        </div>
      );
    }

    return (
      <ul className="list-group">
        {this.props.suggestedReports.suggestions.map((suggestion: SavedQuery, key: number) => {
          let isActive: boolean = !!this.state.selected && (suggestion.id === this.state.selected.id);
          let activeClass = isActive ? ' suggestion-active ' : '';
          if ( suggestion.errorMessage && suggestion.errorMessage.trim().length >= 0) {
            return null;
          }
          return (
            <li
              key={key}
              className={'list-group-item' + activeClass}
              onClick={this.onSuggestionClick(suggestion)}
              role="link"
            >
              <div className="suggestion-item">
                <span className="icon">
                  {isActive &&
                    <i className="fa fa-check"/>
                  }
                </span>
                <div className="suggestion-title-with-tooltip">
                  {suggestion.name}
                  {suggestion.description &&
                  <Tooltip
                    id={'SuggestionTooltip' + key}
                    text={suggestion.description}
                    placement="top"
                    onTooltipClick={this.onTooltipClick}
                  />
                  }
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  onSuggestionClick = (suggestion: SavedQuery) => () => {
    this.setState({
      selected: suggestion
    });

    this.props.onSuggestionClick(suggestion);
  }
}

export default SuggestedReportsContentComponent;