import * as React from 'react';
import { Filter } from '../../types/filterModel';
import { ViewJoinAndDimensionOrMeasure } from '../../redux';
import {
  DIMENSION_TYPE_DATETIME,
  DIMENSION_TYPE_NUMBER,
  DIMENSION_TYPE_STRING,
  DIMENSION_TYPE_BOOLEAN,
  DIMENSION_TYPE_DATEINTERVAL,
  MEASURE_TYPE_AVERAGE,
  MEASURE_TYPE_COUNT,
  MEASURE_TYPE_CUSTOM,
  MEASURE_TYPE_SUM
} from '../../constants';
import {
  FILTER_TYPE,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_NOT_BLANK,
  FILTER_TYPE_NOT_EQUALS,
} from '../../constants/filters';
import CollapseCard from '../../shared-components/CollapseCard';
import { ReactNode } from 'react';
import './style.css';
import {
  createFilterFromFilterOption,
  defaultFilterFromTypeChange,
  getColumnType,
  getIndexInFilterOptions
} from '../../services/filterService';
import GenerateReportButton from '../../containers/GenerateReportButton';
import { Moment } from '../../constants/moment';
import FilterValueInput from './FilterValueInput';
import { AuthService } from '../../services/authService';
import { SavedQuery } from '../../types/savedQueryModel';

export interface FilterSelectProps {
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
  filterOptions: ViewJoinAndDimensionOrMeasure[];
  removeFilter: (index: number) => void;
  beforeFilter: () => void;
  canManageFilter?: boolean;
  loadedQuery?: SavedQuery;
}

interface FilterState {
  isOpen: boolean;
  startDate: Moment | null;
  endDate: Moment | null;
}

export const FILTERS_FOR_NUMBERS = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK,
];

export const FILTERS_FOR_STRING = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK,
];

export const FILTERS_FOR_DATETIME = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK
];

export const FILTERS_FOR_INTERVAL = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK
];

export const FILTERS_FOR_TIME_CONTEXT = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN
];

export const FIELD_TYPE_TO_FILTER = {
  [DIMENSION_TYPE_NUMBER]: FILTERS_FOR_NUMBERS,
  [DIMENSION_TYPE_STRING]: FILTERS_FOR_STRING,
  [DIMENSION_TYPE_DATETIME]: FILTERS_FOR_DATETIME,
  [DIMENSION_TYPE_DATEINTERVAL]: FILTERS_FOR_DATETIME,
  [DIMENSION_TYPE_BOOLEAN]: FILTERS_FOR_STRING,
  [MEASURE_TYPE_AVERAGE]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_COUNT]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_SUM]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_CUSTOM]: FILTERS_FOR_NUMBERS,
};

class FilterSelect extends React.Component<FilterSelectProps, FilterState> {

  constructor(props: FilterSelectProps) {
    super(props);
    this.state = {
      isOpen: false,
      startDate: null,
      endDate: null
    };
  }

  renderCollapseBody(): ReactNode {
    return (
      <div>
        {
          this.props.displayFilters.map((displayFilter: Filter, i: number) => {
            return this.renderFilter(this.props.filterOptions, i, displayFilter);
          })
        }

        {this.props.canManageFilter &&
          <div className="form-group row">
            <div
              className="col"
              data-testid="filter-buttons"
            >
              <button
                className="btn btn-outline-dark mr-2"
                onClick={this.newFilter}
                data-testid="new-filter-btn"
              >
                <i className="fa fa-plus" /> New Filter
              </button>
              <GenerateReportButton
                className="btn btn-primary"
                onGenerateStart={this.props.beforeFilter}
                isDisabled={false}
                loadedQuery={this.props.loadedQuery}
              >
                <i className="fa fa-check" /> Apply
              </GenerateReportButton>
            </div>
          </div>
        }
      </div>
    );
  }

  render() {
    if (!this.props.filterOptions || this.props.filterOptions.length === 0) {
      return null;
    }

    return (
      <div className="filter-select-container">
        <CollapseCard
          title="Filters"
        >
          {this.renderCollapseBody()}
        </CollapseCard>
      </div>
    );
  }

  /**
   * Called when the New Filter button is clicked
   */
  newFilter = () => {
    // save an additional filter to state...
    let defaultFilterOption = this.props.filterOptions[0];
    let filter: Filter = createFilterFromFilterOption(defaultFilterOption);
    this.props.saveDisplayFilter(filter, this.props.displayFilters.length);
  }

  /**
   * Triggered when the filter dimension is changed.
   *
   * @param {React.FormEvent<HTMLSelectElement>} event
   * @param {number} index
   */
  handleSelectChange(event: React.FormEvent<HTMLSelectElement>, index: number) {
    let filterOption: ViewJoinAndDimensionOrMeasure = this.props.filterOptions[event.currentTarget.value];
    let filter: Filter = createFilterFromFilterOption(filterOption);
    this.props.saveDisplayFilter(filter, index);
  }

  handleTypeChange(event: React.FormEvent<HTMLSelectElement>,
                   index: number,
                   filterOption: ViewJoinAndDimensionOrMeasure) {
    const columnType = getColumnType(filterOption);
    let filter = this.props.displayFilters[index];
    filter.type = event.currentTarget.value as FILTER_TYPE;

    filter = defaultFilterFromTypeChange(columnType, filter);

    this.props.saveDisplayFilter(filter, index);
  }

  renderFilter(filterOptions: ViewJoinAndDimensionOrMeasure[], key: number, displayFilter: Filter) {
    let displayFilterIndexInFilterOptions = getIndexInFilterOptions(filterOptions, displayFilter);
    const filterOption = filterOptions[displayFilterIndexInFilterOptions];
    let filterOptionDimension = filterOptions[displayFilterIndexInFilterOptions].dimension;
    let filterOptionMeasure = filterOptions[displayFilterIndexInFilterOptions].measure;
    let filterOptionField = (filterOptionMeasure) ? filterOptionMeasure : filterOptionDimension;
    // disable filter only when they are manager
    // TODO : replace this once BE feature flag is ready
    const disableFilterClassname = AuthService.getInstance().isManager() ? ' disable-filters' : '';
    return (
       <div
           className={
             this.props.canManageFilter ? 'filter-row form-group row'
                 : 'filter-row form-group row' + disableFilterClassname}
           key={key}
       >
        <div className="col-sm-3 filter-on">
          <select
            className="form-control"
            name="field"
            onChange={(e) => { this.handleSelectChange(e, key); }}
            value={displayFilterIndexInFilterOptions}
          >
            {
              filterOptions.map((option: ViewJoinAndDimensionOrMeasure, i: number) => {
                return (
                  <option key={i} value={i}>
                    {option.join ? option.join.label + ' / ' : null}
                    {(option.measure) ? option.measure.label : null}
                    {(option.dimension) ? option.dimension.label : null}
                  </option>
                );
              })
            }
          </select>
        </div>
        <div className="col-sm-2 filter-type">
          <select
            className="form-control"
            name="type"
            onChange={(e) => { this.handleTypeChange(e, key, filterOption); }}
            value={displayFilter.type}
            disabled={!this.props.canManageFilter}
          >
            {
              (filterOptionDimension || filterOptionMeasure) && FIELD_TYPE_TO_FILTER[filterOptionField!.type]
                .map((val: FILTER_TYPE, k: number) => {
                return (
                  <option key={k} value={val}>
                    {val}
                  </option>
                );
              })
            }
          </select>
        </div>
        <FilterValueInput
          displayFilter={displayFilter}
          filterIndex={key}
          displayFilters={this.props.displayFilters}
          saveDisplayFilter={this.props.saveDisplayFilter}
          filterOption={filterOptions[displayFilterIndexInFilterOptions]}
        />
        {this.props.canManageFilter &&
          <div className="col-sm-1 filter-remove">
            <button
              className="btn btn-outline-dark"
              onClick={() => {
               this.removeFilter(key);
              }}
            >
              <i className="fa fa-times"/>
            </button>
          </div>
        }
       </div>
    );
  }

  removeFilter = (key: number) => {
    this.props.removeFilter(key);
  }
}

export default FilterSelect;
