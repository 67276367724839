import React from 'react';
import { ControlsProps } from '@eds/modal';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';

import { Checkbox, ContentModal, TextAreaInput, TextInput } from 'src/features/ui';
import { getPermissions } from 'src/features/utils';

import { DashboardCreateEditFieldValues } from './dashboard-create-edit.validation';
import { useDashboardCreateEditForm, useFormValuesInitializer } from './dashboard-create-edit.hooks';
import { useDashboardCreateEditMutation } from './dashboard-create-edit.queries';

type CreateMode = {
  mode: 'create';
  description?: never;
  id?: never;
  name?: never;
};

type EditMode = {
  mode: 'edit';
  description: string;
  id: string;
  name: string;
};

type DashboardCreateEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
} & (CreateMode | EditMode);

export const DashboardCreateEdit = ({
  description = '',
  id = '',
  isOpen,
  mode,
  name = '',
  onClose,
  onError,
  onSuccess,
}: DashboardCreateEditProps) => {
  const { control, formState, handleSubmit, reset } = useDashboardCreateEditForm();
  const { errors } = formState;

  const { isLoading: loading, mutate } = useDashboardCreateEditMutation(mode, {
    onError,
    onSettled: onClose,
    onSuccess,
  });

  const onSubmit = (values: DashboardCreateEditFieldValues) => mutate(values);

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: { label: 'Save', loading, onClick: handleSubmit(onSubmit), tone: 'primary' },
  };

  useFormValuesInitializer({ description, id, isOpen, isSuggested: false, mode, name, reset });

  return (
    <ContentModal
      controls={controls}
      header={mode === 'edit' ? 'Edit dashboard' : 'Create dashboard'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex as="form" flexDirection="column">
        <Field invalid={!!errors.name} label="Name" message={errors.name?.message} required>
          <TextInput control={control} name="name" placeholder="Dashboard name" />
        </Field>
        <Field label="Description">
          <TextAreaInput
            control={control}
            defaultValue={description}
            name="description"
            placeholder="Briefly describe your dashboard"
          />
        </Field>
        {mode === 'create' && getPermissions().dashboard.canCreateSuggestedDashboard && (
          <Checkbox control={control} name="isSuggested">
            Set as 'Suggested Dashboard'
          </Checkbox>
        )}
      </Flex>
    </ContentModal>
  );
};
