/**
 * Important note: This component is temporary and should be replaced by EDS4 <PageHeader />
 */
import React from 'react';
import { ActionsGroup } from '@eds/actions';
import { AddIcon } from '@eds/icon';
import { Button } from '@eds/button';
import { Flex } from '@eds/flex';
import { GridOnIcon, ListIcon } from 'elmo-elements';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';
import { getUserId } from 'src/features/utils';
import { useDashboardsListUserCapabilitiesQuery } from 'src/features/dashboards';

import { useDashboardHeaderContext } from './dashboard-header-context.component';

export const dashboardHeaderOverrides = css({
  '& .elmo-header': {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
  },
  '& .elmo-header__base': {
    order: -1,
  },
});

export const DashboardHeader = () => {
  const { setViewMode, startCreating, viewMode } = useDashboardHeaderContext();

  const { data: userCapabilities } = useDashboardsListUserCapabilitiesQuery(getUserId());

  const displayDashboardsList = userCapabilities?.canViewDashboardList;

  return displayDashboardsList ? (
    <Flex alignItems="center" paddingX={{ large: 'xxlarge', small: 'large', xsmall: 'medium' }}>
      <ActionsGroup>
        <Flex>
          <Flex className={css(cssOverrides.button(viewMode === 'grid' ? 'primary' : 'neutral'))}>
            <Button
              icon={GridOnIcon}
              iconOnly
              label="Grid"
              onClick={() => setViewMode('grid')}
              size="small"
              tone={viewMode === 'grid' ? 'primary' : 'neutral'}
            />
          </Flex>
          <Flex className={css(cssOverrides.button(viewMode === 'list' ? 'primary' : 'neutral'))}>
            <Button
              icon={ListIcon}
              iconOnly
              label="List"
              onClick={() => setViewMode('list')}
              size="small"
              tone={viewMode === 'list' ? 'primary' : 'neutral'}
            />
          </Flex>
        </Flex>
        {userCapabilities.canCreateDashboard && (
          <Flex className={css(cssOverrides.button('primary'))}>
            <Button icon={AddIcon} label="Add new" onClick={startCreating} size="small" />
          </Flex>
        )}
      </ActionsGroup>
    </Flex>
  ) : null;
};
