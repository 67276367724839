import * as React from 'react';

type IconProps = {
  width?: number;
  height?: number;
};

export const EditChartIcon = ({ width = 20, height = 20 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5V19H19V11H21V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11V5H5ZM15 
        17V13H17V17H15ZM13 17H11V14.5H13V17ZM9 7.5V17H7V7.5H9ZM19.1607 3.19333L20.7207 4.75333C20.9807 5.01333 20.9807
        5.43333 20.7207 5.69333L19.5007 6.91333L17.0007 4.41333L18.2207 3.19333C18.3474 3.06667 18.5141 3 18.6874 
        3C18.8607 3 19.0274 3.06 19.1607 3.19333ZM12 12V9.49997L16.5007 4.99997L19.0007 7.49997L14.5 12H12ZM14.0001
        10.5H13.3867V9.88665L16.3875 6.88671L17.0008 7.50004L14.0001 10.5Z"
      />
    </svg>
  );
};
