import { BaseModel, NestedEntityMap } from '../types/baseModel';
import { Widget } from '../types/widgetModel';
import { Layout } from 'react-grid-layout';

export type DashboardRefreshType = {
  canRefresh: boolean; // shows can be updated dashboard or no
  updatedAt: number; // shows last updated time of cache
  refreshTimeout: number; // shows update time interval in minutes
};

export class Dashboard extends BaseModel {
  protected properties: string[] = [
    'dashboardId',
    'created',
    'modified',
    'title',
    'description',
    'layout',
    'status',
    'type',
    'cache',
    'permissions'
  ];
  protected nestedObjects: NestedEntityMap = {
    widgets: Widget
  };

  dashboardId: string;
  created?: Date;
  modified?: {
    date: Date;
    timezone: string;
    timezone_type: number;
  };
  title: string;
  description: string;
  widgets: Widget[];
  layout?: Layout[];
  status: number;
  type: number;
  cache: DashboardRefreshType;
  permissions: Record<'canAddWidgets' | 'canClone' | 'canDelete' | 'canEdit' | 'canShare', boolean>;

  constructor(data?: any) {
    super(data);
    this.hydrate(data);
  }
}
