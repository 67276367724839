import * as React from 'react';
import { DropdownItemData } from '../Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

type Props = {
  dropdownItems: DropdownItemData[];
  right?: boolean;
};

export function SplitButtonDropdownItems({ dropdownItems, right }: Props) {
  const items = dropdownItems.map((item: DropdownItemData, index: number) => {
    return (
      <DropdownItem
        key={index}
        {...item}
      >
        {item.label}
      </DropdownItem>
    );
  });

  return (
    <DropdownMenu right={right}>
      {items}
    </DropdownMenu>
  );
}