import React, { useEffect, useState } from 'react';
import { Loader } from 'elmo-elements';
import { Route, Switch } from 'react-router';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { AppState } from 'src/redux';
import { HTTP_UNAUTHORIZED_STATUS_CODE } from 'src/constants';
import { PageNotFound } from 'src/features/ui';
import PrivateRoute from 'src/shared-components/PrivateRoute';
import { Route as RouteType } from 'src/services/routingService';

export const AppRouter = () => {
  const { authService, routes } = useSelector((state: AppState) => state);
  const [isCheckTokenLoading, setIsCheckTokenLoading] = useState(true);

  useEffect(() => {
    if (!authService) return;

    authService
      .isLoggedIn()
      .then(() => setIsCheckTokenLoading(false))
      .catch(error => {
        /**
         * Comment from original component:
         * // as we handle all 401 errors in the apiService by redirecting to secure front-end url (login page),
         * // here 401 is handled by displaying loading spinner (initial app loading).
         */
        if (error.response.status !== HTTP_UNAUTHORIZED_STATUS_CODE) {
          setIsCheckTokenLoading(false);
        }
      });
  }, []);

  return (
    <Switch>
      {map(routes, (routeProps: RouteType, routeName: string) =>
        routeProps.private ? (
          <PrivateRoute key={routeName} {...routeProps} />
        ) : (
          <Route key={routeName} {...routeProps} />
        )
      )}
      {isCheckTokenLoading ? <Loader type="spinner" /> : <Route component={PageNotFound} />}
    </Switch>
  );
};
