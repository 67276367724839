import * as React from 'react';
import { Select, FormItem, Spinner } from 'elmo-elements';
import { debounce } from 'lodash';

import { UserSelectOption } from '../../../types/scheduler/userSelectOption';

import SelectedUsers from './SelectedUsers';

import './style.css';

type Props = {
  isLoading: boolean;
  isUserListTyping: boolean;
  userList: UserSelectOption[];
  selectedUsers: UserSelectOption[];
  onSelect: (user: UserSelectOption) => void;
  onResetUserList: () => void;
  onUserSelectChange: (text: string) => void;
  onDeselect: (user: UserSelectOption) => () => void;
  errors: any;
};

const DEBOUNCE_INPUT_CHANGE_TIMEOUT = 500;

function UserSelect(props: Props) {
  const {
    errors, onSelect, selectedUsers, onDeselect, isLoading, userList, onUserSelectChange, onResetUserList,
    isUserListTyping,
  } = props;

  return (
    <FormItem
      label="Users"
      message={!!errors['targetUserIds'] ? errors['targetUserIds'] : undefined}
      status={!!errors['targetUserIds'] ? 'error' : undefined}
      data-testid="schedule-user-select"
      labelAddon={isUserListTyping ? <Spinner size="m" /> : undefined}
    >
      <Select
        options={userList}
        value={null}
        onChange={onSelect}
        onInputChange={debounce(onUserSelectChange, DEBOUNCE_INPUT_CHANGE_TIMEOUT)}
        onBlur={onResetUserList}
        isDisabled={isLoading}
        placeholder={isLoading ? 'Loading...' : 'Select a user'}
        filterOption={() => true}
      />
      <SelectedUsers
        selectedUsers={selectedUsers}
        onDeselect={onDeselect}
      />
    </FormItem>
  );
}

export default UserSelect;
