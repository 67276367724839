import { ColumnMeta } from '../types/columnMetaModel';
import { STORE_COLUMN_META } from '../constants/index';

export interface ColumnMetasAction {
  type: STORE_COLUMN_META;
  columnMetas: ColumnMeta[];
}

export function storeColumnMetas(columnMetas: ColumnMeta[]): ColumnMetasAction {
  return {
    type: STORE_COLUMN_META,
    columnMetas: columnMetas,
  };
}