import {
  STORE_FILTER, STORE_FILTER_OPTIONS, REPLACE_FILTERS
} from '../constants';
import { Filter } from '../types/filterModel';

export interface FilterAction {
  type: STORE_FILTER;
  filter: Filter;
  index?: number;
}

export interface FiltersAction {
  type: REPLACE_FILTERS;
  filters: Filter[];
}

export interface FilterOptionsAction {
  type: STORE_FILTER_OPTIONS;
}

export function storeFilterOptions() {
  return {
    type: STORE_FILTER_OPTIONS,
  };
}

export function replaceFilters(filters: Filter[]): FiltersAction {
  return {
    type: REPLACE_FILTERS,
    filters: filters,
  };
}