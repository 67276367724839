import { STORE_OFFSET, STORE_REPORT_LIMIT } from '../constants';
import { Dispatch } from 'redux';

export interface OffsetAction {
  type: STORE_OFFSET;
  offset: number;
}

export function storeOffsetAction(offset: number): OffsetAction {
  return {
    type: STORE_OFFSET,
    offset: offset
  };
}

export function storeOffset(offset: number): (dispatch: Dispatch<any>) => void {
  return (dispatch) => {
    dispatch(storeOffsetAction(offset));
  };
}

export interface ReportLimitAction {
  type: STORE_REPORT_LIMIT;
  limit: number;
}

export function storeReportLimitAction(limit: number): ReportLimitAction {
  return {
    type: STORE_REPORT_LIMIT,
    limit: limit
  };
}