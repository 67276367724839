import * as React from 'react';

export function renderDownloadsButton() {
  return (
    <button
      className="btn btn-primary"
      onClick={navigateToDownloads}
      style={{marginTop: 5}}
    >
      Downloads
    </button>
  );
}

function navigateToDownloads() {
  window.open('/downloads', '_self');
}