import React, { ChangeEventHandler, useState } from 'react';
import { AddIcon } from '@eds/icon';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { Divider } from '@eds/divider';
import { EmptyState } from '@eds/empty-state';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { LoadingSpinner } from '@eds/loading';
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@eds/table';
import { Tag } from '@eds/tag';
import { Text } from '@eds/text';
import { TextInput } from '@eds/text-input';
import { space, useWindowSize } from '@eds/core';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/css';
import { ValidationError } from 'yup';

import { Toggle, cssOverrides } from 'src/features/ui';

import { ScheduledReportFieldValues, textWithSeparatorsSchema } from './scheduled-reports-modal.validation';
import {
  ScheduledReportsFilenameToken,
  useScheduledReportsFilenameTokensQuery,
} from './scheduled-reports-modal.queries';
import {
  useCustomFilenameString,
  useSampleFilename,
  useSortedFilenameTokens,
  useTokenizedSeparatorsWithStaticText,
} from './outbound-filename.hooks';

/**
 * NOTE: Based on the following criteria
 * - The composed filename field above the table should be visible when scrolled all the way down (whenever possible)
 * - The table should have a minimum of 3 visible rows
 *  */
const WINDOW_HEIGHT_OFFSET = 480;
const TABLE_MIN_HEIGHT = 160;

const columnSettings = {
  actions: { width: '2.875rem' },
};

type FilenameTokenRowProps = {
  onClick: () => void;
  rowSpan: number;
} & ScheduledReportsFilenameToken;

/**
 * FilenameTokenRow - Renders a table row representing a filename token with its description, tag, and an "Add" button
 * for interaction. The first table cell spans multiple rows based on the `rowSpan` prop.
 */
const FilenameTokenRow = ({ description, onClick, rowSpan, token, type }: FilenameTokenRowProps) => (
  <TableRow>
    {!!rowSpan && (
      <TableCell rowSpan={rowSpan} verticalAlign="top">
        {type}
      </TableCell>
    )}
    <TableCell>{description}</TableCell>
    <TableCell>{<Tag label={token} size="small" />}</TableCell>
    <TableCell>
      <Box className={css({ ...cssOverrides.button('ghost'), margin: '-0.375rem' })} width={space.xlarge}>
        <Button icon={AddIcon} iconOnly label="Add" onClick={onClick} size="small" tone="ghost" />
      </Box>
    </TableCell>
  </TableRow>
);

type OutboundFilenameProps = {
  errors: FieldErrors<ScheduledReportFieldValues>;
  isSubmitted: boolean;
} & Pick<UseFormReturn<ScheduledReportFieldValues>, 'control' | 'setValue' | 'watch'>;

export const OutboundFilename = ({ control, errors, isSubmitted, setValue, watch }: OutboundFilenameProps) => {
  const [staticText, setStaticText] = useState('');
  const [staticTextError, setStaticTextError] = useState('');
  const [staticTextSubmitted, setStaticTextSubmitted] = useState(false);

  const { data: filenameTokens = [], isLoading } = useScheduledReportsFilenameTokensQuery(
    watch('customFilenameEnabled')
  );

  const { dates, separators, times } = useSortedFilenameTokens(filenameTokens);

  const { windowHeight = 0 } = useWindowSize();
  const computedMaxHeight = windowHeight - WINDOW_HEIGHT_OFFSET;

  const customFilename = watch('customFilename') as Array<string>;
  const customFilenameString = useCustomFilenameString(customFilename, filenameTokens);
  const sampleFilename = useSampleFilename(customFilename, filenameTokens);

  const onCustomFilenameChange = (tokenOrTextWithSeparators: string, isStaticText?: boolean) => {
    if (!tokenOrTextWithSeparators) return;

    if (!isStaticText || ![' ', '!', '_', '-'].some(value => tokenOrTextWithSeparators.includes(value))) {
      // NOTE: `tokenOrTextWithSeparators` is either a token id or static text without separators
      setValue('customFilename', [...customFilename, tokenOrTextWithSeparators], { shouldValidate: isSubmitted });
      return;
    }

    // NOTE: `tokenOrTextWithSeparators` contains separators
    const tokenized = useTokenizedSeparatorsWithStaticText(tokenOrTextWithSeparators);
    setValue('customFilename', [...customFilename, ...tokenized], { shouldValidate: isSubmitted });
  };

  const onStaticTextChange: ChangeEventHandler<HTMLInputElement> = e => {
    setStaticText(e.target.value);
    if (!staticTextSubmitted) return;

    try {
      textWithSeparatorsSchema.validateSync(e.target.value);
      setStaticTextError('');
    } catch (e) {
      setStaticTextError((e as ValidationError).message);
    }
  };

  const onAddStaticTextClick = () => {
    setStaticTextSubmitted(true);

    try {
      textWithSeparatorsSchema.validateSync(staticText);
      setStaticTextError('');
      onCustomFilenameChange(staticText, true);
    } catch (e) {
      setStaticTextError((e as ValidationError).message);
    }
  };

  return (
    <Flex
      borderColor="neutralSubtle"
      borderRadius="large"
      borderStyle="solid"
      borderWidth="thin"
      flexDirection="column"
    >
      <Flex
        className={css({ ...cssOverrides.button('toggle'), '& > span': { paddingTop: 0, paddingBottom: 0 } })}
        data-tracking="filename-toggle"
        flexDirection="column"
        margin="large"
      >
        <Toggle control={control} label="Filename" name="customFilenameEnabled" />
        <Text color="neutralSubtle" fontSize="small" marginLeft="xxlarge" paddingLeft="xsmall">
          Create a custom filename template to replace the default one
        </Text>
      </Flex>
      {watch('customFilenameEnabled') && (
        <>
          <Flex flexDirection="column" gap="large" paddingX="large">
            <Text color="neutralSubtle" fontSize="small">
              Customize the filename with dynamic date and time by clicking the <AddIcon size="xsmall" /> buttons next
              to token tags. You can include static text and spaces, but symbols require the corresponding separator
              token.
            </Text>
            <Field
              description={`Formatted output: ${sampleFilename}.csv`}
              invalid={!!errors.customFilename}
              label="Your filename template"
              message={errors.customFilename?.message}
              required
            >
              <Flex className={css(cssOverrides.button())} gap="small">
                <Box flex={1}>
                  <TextInput adornmentEnd=".csv" disabled value={customFilenameString} />
                </Box>
                <Button
                  label="Reset"
                  tone="neutral"
                  onClick={() => setValue('customFilename', [], { shouldValidate: isSubmitted })}
                />
              </Flex>
            </Field>
          </Flex>
          <Divider />
          <Box
            backgroundColor="neutralSubtle"
            className={css({
              borderBottomLeftRadius: space.xsmall,
              borderBottomRightRadius: space.xsmall,
            })}
            padding="large"
          >
            <Field invalid={!!staticTextError} label="Static text" message={staticTextError}>
              <Flex className={css(cssOverrides.button())} gap="medium">
                <Box flex={1}>
                  <TextInput
                    onChange={onStaticTextChange}
                    placeholder="Enter alphanumeric text and spaces..."
                    value={staticText}
                  />
                </Box>
                <Button icon={AddIcon} iconOnly label="Add" onClick={onAddStaticTextClick} tone="neutral" />
              </Flex>
            </Field>
            <Table
              columnSettings={columnSettings}
              maxHeight={computedMaxHeight > TABLE_MIN_HEIGHT ? computedMaxHeight : TABLE_MIN_HEIGHT}
              stickyHeader
              tableBorder
            >
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Type</TableHeaderCell>
                  <TableHeaderCell>Description</TableHeaderCell>
                  <TableHeaderCell>Token</TableHeaderCell>
                  <TableHeaderCell columnName="actions">
                    <></>
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!filenameTokens.length ? (
                  <>
                    {dates.map((date, index) => (
                      <FilenameTokenRow
                        key={date.id}
                        onClick={() => onCustomFilenameChange(date.id)}
                        rowSpan={index === 0 ? dates.length : 0}
                        {...date}
                      />
                    ))}
                    {times.map((time, index) => (
                      <FilenameTokenRow
                        key={time.id}
                        onClick={() => onCustomFilenameChange(time.id)}
                        rowSpan={index === 0 ? times.length : 0}
                        {...time}
                      />
                    ))}
                    {separators.map((separator, index) => (
                      <FilenameTokenRow
                        key={separator.id}
                        onClick={() => onCustomFilenameChange(separator.id)}
                        rowSpan={index === 0 ? separators.length : 0}
                        {...separator}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box marginY="large">
                        {isLoading ? (
                          <Flex justifyContent="center">
                            <LoadingSpinner />
                          </Flex>
                        ) : (
                          <EmptyState description="Please try again later." title="No tokens available" />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
    </Flex>
  );
};
