import * as React from 'react';
import moment from 'moment';
import { FormItem } from 'elmo-elements';

import { OCCURRENCE_ONCE, OccurrenceOption } from '../../../constants/addSchedule';
import Datepicker from '../../../shared-components/Datepicker';
import { Moment } from '../../../constants/moment';

type Props = {
  occurrence: OccurrenceOption;
  onDateChange: (isStartDate: boolean) => (d: Moment | null) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  errors: any;
};

function DateSelect({ occurrence, onDateChange, startDate, endDate, errors }: Props) {
  const isEndDateVisible = (occurrence !== OCCURRENCE_ONCE);
  const startDateLabel: string = isEndDateVisible ? 'Start Date' : 'Date';
  const placeholder: string = 'DD/MM/YYYY';

  return (
    <>
      <FormItem
        label={`${startDateLabel}:`}
        message={!!errors['startDate'] ? errors['startDate'] : undefined}
        status={!!errors['startDate'] ? 'error' : undefined}
        data-testid="schedule-start-date"
      >
        <Datepicker
          value={startDate}
          onChange={onDateChange(true)}
          placeholder={placeholder}
          isEDSStyle={true}
        />
      </FormItem>

      {isEndDateVisible &&
        <FormItem
          label="End Date:"
          message={!!errors['endDate'] ? errors['endDate'] : undefined}
          status={!!errors['endDate'] ? 'error' : undefined}
          data-testid="schedule-end-date"
        >
          <Datepicker
            value={endDate}
            onChange={onDateChange(false)}
            placeholder={placeholder}
            isEDSStyle={true}
          />
        </FormItem>
      }
    </>
  );
}

export default DateSelect;
