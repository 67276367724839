import { ADD_DIMENSIONS, REMOVE_DIMENSIONS } from '../constants';
import { Dimension } from '../types/dimensionModel';
import { DimensionsAction } from '../actions/dimension';
import * as _ from 'lodash';
import { ReportBuilderAction } from '../actions/reportBuilder';

export function dimensionsReducer(state: Dimension[] = [],
                                  action: DimensionsAction | ReportBuilderAction): Dimension[] {
  switch (action.type) {
    case ADD_DIMENSIONS:
      return state.concat(action.dimensions).slice();
    case REMOVE_DIMENSIONS:
      return _.differenceBy(state, action.dimensions, 'name').slice();
    default:
      return state;
  }
}
