import * as React from 'react';
import { findKey } from 'lodash';
import { default as PrivateRouteComponent } from '../shared-components/PrivateRoute';
import { Switch } from 'react-router';
import { routes } from '../routes';
import ReportPrintPage from '../containers/ReportPrintPage';
import { BrowserRouter as Router } from 'react-router-dom';
import { getAdminReportingRoutes, getManagerReportingRoutes, scheduleRoutes } from '../routes/reporting';
import { AuthService } from './authService';

export type Route = {
  /** The relative pathname to match to show this component */
  path: string;
  /** The component to use for rendering */
  component: React.ComponentType;
  /** Is the route authenticated? true | false */
  private: boolean;
  /** React-Router prop. Should the route match the pathname exactly? */
  exact?: boolean;
  /** Hide the page header and notifications, show only the component. */
  hideHeader?: boolean;
};

/**
 * These pages are all shown without a header
 * @param pathname
 */
export function getPageWithoutHeader(pathname: string, currentRoutes: any) {
  const key = findKey(currentRoutes, (val: Route) => {
    return val.hideHeader && val.path === '/' + pathname;
  });

  if (!key) {
    return null;
  }

  const Component: React.ComponentType = currentRoutes[key].component;
  return <Component />;
}

export function getPrintPage() {
  return (
    <Router>
      <Switch>
        <PrivateRouteComponent path="/print/:queryId" component={ReportPrintPage} />
      </Switch>
    </Router>
  );
}

export function getPathname() {
  let pathname = window.location.pathname;
  return pathname.replace('/', '').toLowerCase();
}

export function getRoutesForUser(authService: AuthService) {
  const isAdmin = authService.isSuperAdmin() || authService.isCompanyAdmin() || authService.hasCreateCapability();
  const isNewReportBuilderEnabled = authService.enableNewReportBuilder();
  const enableDashboardSharing = authService.canManageDashboard();
  const enableReportBuilderForManager = authService.canAccessReportBuilder();
  
  let reportingRoutes = isAdmin ?
    getAdminReportingRoutes(isNewReportBuilderEnabled) :
    getManagerReportingRoutes(isNewReportBuilderEnabled, enableDashboardSharing, enableReportBuilderForManager);
    
  if (authService.isSchedulerEnabled() && isAdmin) {
    reportingRoutes = {...reportingRoutes, ...scheduleRoutes};
  }
  return {
    ...reportingRoutes,
    ...routes
  };
}
