import * as React from 'react';
import { Divider } from 'elmo-elements';
import { DividerProps } from 'elmo-elements/Divider';

import './style.css';

interface Props {
  width?: string;
  height?: string;
}

export type ElmoDividerProps = DividerProps & Props;

const ElmoDivider = ({ width = '1', height = '1', ...props }: ElmoDividerProps) => {
  return (
    <div className="elmo-divider--override" style={{ width: `${width}px`, height: `${height}px` }}>
      <Divider {...props} />
    </div>
  );
};

export default ElmoDivider;
