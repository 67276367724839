import React from 'react';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { ControlsProps } from '@eds/modal';
import { Flex } from '@eds/flex';
import { useBreakpointSize } from '@eds/core';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

interface CustomFooterProps {
  controls: ControlsProps;
  dataTracking?: string;
  type?: 'alert' | 'content';
}

export const CustomFooter = ({ controls: { cancel, confirm }, dataTracking, type = 'content' }: CustomFooterProps) => {
  const isAlert = type === 'alert';
  const { isSmall } = useBreakpointSize();
  const buttonsList = cancel ? [cancel, confirm] : [confirm];
  const buttons = isSmall ? buttonsList.reverse() : buttonsList;

  return (
    <Flex
      flexDirection={{ xsmall: 'column', small: 'row' }}
      gap="xsmall"
      justifyContent="flex-end"
      padding="large"
      paddingTop={isAlert ? 'none' : 'large'}
    >
      {buttons.map(({ label, tone, onClick, loading }) => {
        const isConfirm = label === confirm.label;

        return (
          <Box
            className={css(cssOverrides.button(tone))}
            data-tracking={`${dataTracking}-${isConfirm ? 'confirm' : 'cancel'}`}
            key={label}
          >
            <Button
              block
              label={label}
              loading={loading}
              onClick={onClick}
              size={isAlert ? 'small' : 'medium'}
              tone={tone}
              {...(isAlert && isConfirm ? { 'data-autofocus': true } : {})}
            />
          </Box>
        );
      })}
    </Flex>
  );
};
