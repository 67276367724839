export const ADD_CONCATENATION_CONFIGURATION = 'ADD_CONCATENATION_CONFIGURATION';
export type ADD_CONCATENATION_CONFIGURATION = typeof ADD_CONCATENATION_CONFIGURATION;

export const UPDATE_CONCATENATION_CONFIGURATION = 'UPDATE_CONCATENATION_CONFIGURATION';
export type UPDATE_CONCATENATION_CONFIGURATION = typeof UPDATE_CONCATENATION_CONFIGURATION;

export const SET_TRANSLATION_CONFIGURATION = 'SET_TRANSLATION_CONFIGURATION';
export type SET_TRANSLATION_CONFIGURATION = typeof SET_TRANSLATION_CONFIGURATION;

export const SET_DATETIME_CONFIGURATION = 'SET_DATETIME_CONFIGURATION';
export type SET_DATETIME_CONFIGURATION = typeof SET_DATETIME_CONFIGURATION;

export const DELETE_TRANSFORMATION = 'DELETE_TRANSFORMATION';
export type DELETE_TRANSFORMATION = typeof DELETE_TRANSFORMATION;
