import { UPDATE_APP_MENU_COLLAPSE_STATUS, SET_CURRENT_SUBMENU_ITEM } from '../constants';
import { UpdateAppMenuCollapseStatusActionType, setCurrentSubmenuItemActionType } from '../actions/appMenu';
import { AppMenu } from '../types/appMenuModel';

export function appMenuReducer(
  state: AppMenu = new AppMenu(),
  action: UpdateAppMenuCollapseStatusActionType | setCurrentSubmenuItemActionType,
) {
  switch (action.type) {
    case UPDATE_APP_MENU_COLLAPSE_STATUS: {
      return {
        ...state,
        isOpened: action.status,
      };
    }
    case SET_CURRENT_SUBMENU_ITEM: {
      return {
        ...state,
        currentSubmenuItem: action.submenuItem,
      };
    }
    default: {
      return state;
    }
  }
}
