import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Paragraph, AddIcon } from 'elmo-elements';

import { NoDataImage } from './NoDataImage';
import { ReportingRoutes } from '../../../../constants/routes';
import './style.scss';

export type Props = {};

const NoDataComponent = (props: Props) => {
  const history = useHistory();

  const createReportButtonClick = () => {
    history.push(ReportingRoutes.reportingTool);
  };

  return (
    <div className="noDataComponent">
      <NoDataImage />
      <Paragraph className="noDataMessage">
        No data available. We'll first need to add some data!
      </Paragraph>
      <Button
        type="primary"
        icon={<AddIcon />}
        onClick={createReportButtonClick}
        isUppercase={false}
      >
        Create a Report
      </Button>
    </div>
  );
};

export default NoDataComponent;
