import * as React from 'react';
import { Button, FormItem, Input, Modal, Loader } from 'elmo-elements';

export type Props = {
  currentWidgetTitle: string;
  showModal: boolean;
  closeModal: () => void;
  saveWidgetTitle: (title: string) => void;
  editWidgetTitleLoading: boolean;
};

const EditChartTitleModal = (props: Props) => {
  const [ widgetTitle, setWidgetTitle ] = React.useState<string>('');

  React.useEffect(
    () => {
      setWidgetTitle(props.currentWidgetTitle);
    },
    [props.currentWidgetTitle]
  );

  return (
    <Modal
      id="ModalDialog"
      type="medium"
      className="elmo-modal--fs16"
      isOpened={props.showModal}
      title="Edit Title"
      closeLabel="Close"
      closeModal={props.closeModal}
      isBackgroundGrey={true}
      primaryButton={(
        <Button
          type="primary"
          onClick={() => widgetTitle && props.saveWidgetTitle(widgetTitle)}
        >
          {props.editWidgetTitleLoading ? <Loader type="spinner" /> : 'Save'}
        </Button>
      )}
    >
      <FormItem
        label={'Chart Title'}
        message={!widgetTitle ? 'Widget title cannot be blank' : undefined}
        status={!widgetTitle ? 'error' : undefined}
      >
        <Input
          value={widgetTitle}
          id="title"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWidgetTitle(event.currentTarget.value)}
          placeholder="My New Dashboard"
        />
      </FormItem>
    </Modal>
  );
};

export default EditChartTitleModal;
