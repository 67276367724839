import { SET_TRANSLATIONS } from '../constants/translations';
import { TranslationsAction } from '../actions/translations';

export function translationsReducer(state: any = null, action: TranslationsAction) {
  switch (action.type) {
    case SET_TRANSLATIONS: {
      return action.translations;
    }
    default: {
      return state;
    }
  }
}