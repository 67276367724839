import * as React from 'react';
import { Select, FormItem } from 'elmo-elements';
import { debounce } from 'lodash';

import { UserSelectOption } from '../../../types/scheduler/userSelectOption';

type Props = {
  isLoading: boolean;
  userList: UserSelectOption[];
  selectedUsers: UserSelectOption[];
  onSelect: (user: UserSelectOption) => void;
  onResetUserList: () => void;
  onUserSelectChange: (text: string) => void;
  errors: any;
};

function NotificationRecipientSelect(props: Props) {
  const { errors, onSelect, selectedUsers, isLoading, userList, onUserSelectChange, onResetUserList } = props;

  return (
    <FormItem
      label="Recipient User"
      message={!!errors['notificationRecipients'] ? errors['notificationRecipients'] : undefined}
      status={!!errors['notificationRecipients'] ? 'error' : undefined}
      data-testid="recipient-user-select"
    >
      <Select
        options={userList}
        value={selectedUsers[0]}
        onChange={onSelect}
        onInputChange={debounce(onUserSelectChange, 500)}
        onBlur={onResetUserList}
        isDisabled={isLoading}
        placeholder={isLoading ? 'Loading...' : 'Select a user'}
        filterOption={() => true}
      />
    </FormItem>
  );
}

export default NotificationRecipientSelect;
