import * as React from 'react';
import {
  DATETIME_FORMAT
} from '../../constants';
import { Filter } from '../../types/filterModel';
import moment from 'moment';
import { Moment } from '../../constants/moment';
import Datepicker from '../../shared-components/Datepicker';

type FilterDatepickerInputProps = {
  displayFilter: Filter;
  filterIndex: number;
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
};

class FilterDatepickerInput extends React.Component<FilterDatepickerInputProps> {
  handleDateChange = (index: number) => (d: Moment | null) => {
    const filter = this.props.displayFilters[index];
    filter.value = d ? d.format(DATETIME_FORMAT) : '';
    this.props.saveDisplayFilter(filter, index);
  }

  render() {
    const { displayFilter, filterIndex } = this.props;
    const momentValue = moment(displayFilter.value as string | number, DATETIME_FORMAT);

    return (
      <Datepicker
        value={momentValue}
        onChange={this.handleDateChange(filterIndex)}
        isClearable={false}
      />
    );
  }
}

export default FilterDatepickerInput;
