import { AccessDenied, ServerError } from 'src/features/ui';
import { Route } from '../services/routingService';

// NOTE: fatalError could be renamed but currently I'm trying to avoid unnecessary files
export const errorRoutes: { fatalError: Route; forbidden: Route } = {
  fatalError: {
    path: '/500',
    component: ServerError,
    exact: true,
    private: false,
  },
  forbidden: {
    path: '/403',
    component: AccessDenied,
    exact: true,
    private: false,
  },
};
