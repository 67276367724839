import { CONFIG_STATUS, CONFIG_STATUS_DRAFT, CONFIG_STATUS_PUBLISHED } from '../../constants';
import { ConfigUser } from '../../types/config/configUserModel';

export class ConfigData {
  id: string;
  relations: any;
  views: any;
  translations: any;
  module: string;
  clientName: string;
  status: CONFIG_STATUS;
  user: ConfigUser | null;

  public getStatusString() {
    return this.status === CONFIG_STATUS_PUBLISHED ? 'Published' :
      (this.status === CONFIG_STATUS_DRAFT ? 'Draft' : 'Invalid Status');
  }
}