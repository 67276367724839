import axios, { AxiosResponse } from 'axios';
import { Widget } from '../types/widgetModel';

export const WIDGET_TYPE_VIEW = 1;
export const WIDGET_TYPE_CHART = 2;

export class WidgetService {

  static async removeWidget(dashboardId: string, widgetId: string) {
    let response: AxiosResponse = await axios.delete('/api/dashboards/' + dashboardId + '/widgets/' + widgetId);
    return response;
  }

  static async editWidget(widget: Widget, title: string) {
    let response: AxiosResponse = await axios.put(`/api/widgets/${widget.widgetId}`, {
      ...widget,
      title,
    });
    return response;
  }

}
