import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const TextChartTypeIcon = ({ className, width = 20, height = 15, color = '#424448' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0V3H5.5V15H8.5V3H13.5V0H0.5ZM19.5 5H10.5V8H13.5V15H16.5V8H19.5V5Z" fill={color}/>
    </svg>
  );
};
