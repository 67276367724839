import axios from 'axios';

export async function apiCheckConfiguration(moduleName: string,
                                            viewsFile: File,
                                            relationsFile: File,
                                            translationsFile: File) {
  let formData: FormData = new FormData();
  formData.append('moduleName', moduleName);
  formData.append('views', viewsFile);
  formData.append('relations', relationsFile);
  formData.append('translations', translationsFile);

  let response = await axios.post('/api/configurations/check', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data['errors'];
}

export async function apiCheckExistingConfiguration(configurationId: string) {
  let response = await axios.get('/api/configurations/' + configurationId + '/check');
  return response.data['errors'];
}