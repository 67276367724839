import * as React  from 'react';
import useFitText from 'use-fit-text';

import './style.css';

export interface TextWidgetProps {
  title: string;
  value: string;
}

const TextWidget = (props: TextWidgetProps) => {
  const { fontSize, ref } = useFitText({ maxFontSize: 600, resolution: 1 });

  return (
    <div className="text-widget" ref={ref} style={{ fontSize }}>
      <div className="text-widget-value">
        {props.value}
      </div>
    </div>
  );
};

export default TextWidget;
