import * as React from 'react';
import { getClassNames } from '../../../services/utilityService';

type LoadingIconProps = {
  isSmall?: boolean;
  iconOnly?: boolean;
};

export function LoadingIcon({ isSmall, iconOnly }: LoadingIconProps ) {
  const icon = (
    <i
      className={getClassNames('fa fa-circle-o-notch fa-spin', {
        'fa-2x': !isSmall
      })}
    />
  );

  if (iconOnly) {
    return icon;
  }

  return (
    <div
      className={getClassNames('text-center loading-icon', {
        'p-5': !isSmall
      })}
    >
      {icon}
    </div>
  );
}

export default LoadingIcon;
