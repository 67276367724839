import {
  CLEAR_REPORT_DRILLDOWN,
  FETCHED_REPORT_DETAILS,
  FETCHED_REPORT,
  RESET_REPORT_JOBID,
  FETCHED_REPORT_JOBID,
  FETCHED_REPORT_DRILLDOWN,
  GENERATE_REPORT_START,
  HYDRATE_STORE,
  REPORT_TYPE_USER,
  REPORT_TYPE_SUGGESTION,
  REPORT_TYPE,
  REPORT_TYPE_DASHBOARD,
  STORE_REPORT_TITLE,
  GENERATE_ACTION,
  GENERATE_REPORT_CANCELED,
  GENERATE_REPORT_FINISHED,
  CANCEL,
  STORE_CANCEL_SOURCE,
  REPORT_TYPE_TEMP,
  FETCHED_REPORT_PAGINATION_DATA,
} from '../constants';
import {
  getEncodedQuery,
  apiSaveQuery,
  apiGetQuery,
  getQuery,
  queryHasSelectedColumns,
  getQueryForApiByAppState,
  apiGetReportWithJobId, pollGenerateReportStatus
} from '../services/reportService';
import { Dispatch } from 'redux';
import { AppState, QueryForApi, ReportDetails } from '../redux';
import { fetchedViews, storeQuery } from './module';
import { AppError } from '../types/AppError';
import { Filter } from '../types/filterModel';
import { View } from '../types/viewModel';
import { SaveQueryFormData } from '../components/SaveQuery';
import { SavedQuery } from '../types/savedQueryModel';
import {
  saveQueryError,
  loadQueryStart,
  loadQueryError, saveQueryStart, saveQuerySuccess, storeTempQuery
} from './savedQueries';
import uuidv4 from 'uuid/v4';
import { Query } from '../types/queryModel';
import { memoizeApiGetViews, apiGetViews } from '../services/viewApiService';
import { User } from '../types/userModel';
import { QueryLoaderService } from '../services/queryLoaderService';
import { ChartService } from '../services/chartService';
import { map, head, filter } from 'lodash';
import { storeChart } from './chart';
import { Chart } from '../types/chartModel';
import axios, { CancelTokenSource } from 'axios';
import { ReportTable } from '../types/reportTableModel';
import { apiQueueDrilldownCsv, apiQueueReportDrilldown } from '../services/drilldownService';
import { getViewFromJoin } from '../services/viewService';
import { ReportRow } from '../types/reportRowModel';
import { createFiltersForDrilldownQuery } from '../services/drilldownService';
import { ColumnMeta } from '../types/columnMetaModel';
import { ReportPaginationData } from 'src/types/reportPaginationDataModel';
import { setTranslations } from './translations';
import {
  FILTER_TYPE,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_IN,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_NOT_IN
} from '../constants/filters';
import { SupportedCountriesType } from '../services/authService';

export interface ReportAction {
  type: FETCHED_REPORT;
  report: ReportTable;
}

function fetchedReport(data: ReportTable): ReportAction {
  return {
    type: FETCHED_REPORT,
    report: data
  };
}

function fetchedReportWithJobId(data: ReportTable): ReportAction {
  return {
    type: FETCHED_REPORT,
    report: data
  }
}

export interface ReportJobAction {
  type: FETCHED_REPORT_JOBID | RESET_REPORT_JOBID;
  report: ReportTable;
}

function queuedReport(data: ReportTable): ReportJobAction {
  return {
    type: FETCHED_REPORT_JOBID,
    report: data
  };
}

function resetReport(): ReportJobAction {
  return {
    type: RESET_REPORT_JOBID,
    report: new ReportTable()
  };
}

export interface ReportDrilldownAction {
  type: FETCHED_REPORT_DRILLDOWN | CLEAR_REPORT_DRILLDOWN;
  report: ReportTable;
  rowIndex: number;
  cellIndex: number;
  limit: number;
  offset: number;
}

export interface GenerateAction {
  type: GENERATE_ACTION;
}

export interface StoreCancelSourceAction {
  type: STORE_CANCEL_SOURCE | CANCEL;
  cancelTokenSource: CancelTokenSource | null;
  message?: string;
}

/**
 * Get report results when the query is not yet built. Includes getting query from redux store.
 * @param noCache
 */
export function getReportJson(noCache: boolean = false, reportId?: string):
  (dispatch: Dispatch<any>, getState: () => AppState) => Promise<ReportTable | null> {
  return (dispatch, getState) => {
    let queryForApi = getQueryForApiByAppState(getState());
    // modify filter here
    const finalQueryForApi = modifyFilters(queryForApi);
    let query: Query = getQuery(finalQueryForApi);

    // Record report query data for analytics
    return dispatchGetReport(query, dispatch, noCache, reportId);
  };
}

export async function dispatchGetReport(query: Query,
                                        dispatch: Dispatch<any>,
                                        noCache: boolean = false,
                                        reportId?: string) {
  // if the query has no selected columns, clear the report results and don't run the report.
  if (!queryHasSelectedColumns(query)) {
    dispatch(fetchedReport(new ReportTable()));
    return Promise.resolve(null);
  }

  dispatch(generateReportStart());

  // Encoded Query object is sent to the API
  // For caching purposes we have opted to send the limit and offset separately as headers
  let baseQuery: Partial<Query> = Object.assign({}, query);
  delete baseQuery.limit;
  delete baseQuery.offset;
  let encodedQuery = getEncodedQuery(baseQuery as Query);

  // Moved store query here in order to enable Export simultaneously
  dispatch(storeQuery(encodedQuery));

  // Allow the API call to be cancelled via its cancel token
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  dispatch(storeCancelSource(source));

  try {
    // reset previous jobId
    dispatch(resetReport());
    const reportData: ReportTable = await apiGetReportWithJobId(encodedQuery, query, reportId);
    // update state with jobId from here
    dispatch(queuedReport(reportData));
    dispatch(fetchedReportWithJobId(reportData));

    // @ts-ignore
    const data: ReportTable = await dispatch(pollGenerateReportStatus(reportData.jobId ? reportData.jobId : 0));
    dispatch(fetchedReport(data));

    // Dispatch prev/next data to redux store
    // @ts-ignore
    if (data.links) {
      // @ts-ignore
      dispatch(fetchedReportPagination(data.links));
    }

    return data;
  } finally {
    dispatch(generateReportFinished());
  }

  return null;
}

export function getDrilldown(rowIndex: number,
                             cellIndex: number,
                             offset: number,
                             limit: number,
                             columnMeta: ColumnMeta[],
                             report: ReportTable,
                             views: View[],
                             query: Query) {
  const {
    encodedQuery,
    measure,
    measureView,
    measureJoin
  } = getParamsForDrilldown(query, rowIndex, cellIndex,  columnMeta, report, views, offset, limit);

  return apiQueueReportDrilldown(
    encodedQuery,
    measure,
    measureView,
    measureJoin,
    report.jobId
  );
}

export function queueDrilldownExport(rowIndex: number,
                                     colIndex: number,
                                     columnMeta: ColumnMeta[],
                                     report: ReportTable,
                                     views: View[],
                                     query: Query,
                                     drilldownTitle?: string) {
  const {
    encodedQuery,
    measure,
    measureView,
    measureJoin
  } = getParamsForDrilldown(query, rowIndex, colIndex, columnMeta, report, views);
  return apiQueueDrilldownCsv(encodedQuery, measure, measureView, measureJoin, drilldownTitle, report.jobId);
}

export function saveQueryAndChart(saveAs: boolean,
                                  loadedQueryToSave: SavedQuery | undefined,
                                  chartToSave: Chart,
                                  formData: SaveQueryFormData): any {
  return (dispatch: any, getState: any) => {
    let appState = getState();

    // get the query to save
    let queryForApi = getQueryForApiByAppState(appState);
    let query: Query = getQuery(queryForApi);

    // add the user id to save for
    let userId: number = 0;
    if (appState.authService) {
      let userData: User | null = appState.authService.getUserData();

      if (userData) {
        userId = userData.id;
      }
    }

    if (userId === null) {
      throw new AppError('Invalid user');
    }

    const supportedCountries = appState.selectedCountries;

    return dispatchSaveQueryAndChart(
      saveAs,
      loadedQueryToSave,
      chartToSave,
      formData,
      userId,
      query,
      supportedCountries,
      dispatch
    );
  };
}

export async function dispatchSaveQueryAndChart(
  saveAs: boolean,
  loadedQueryToSave: SavedQuery | undefined,
  chartToSave: Chart,
  formData: SaveQueryFormData,
  userId: number,
  query: Query,
  supportedCountries: SupportedCountriesType,
  dispatch: Dispatch<any>): Promise<SavedQuery | undefined> {

  dispatch(saveQueryStart());

  // Check whether save or save as
  let loadedQuery: SavedQuery | undefined;
  if (!saveAs) {
    loadedQuery = loadedQueryToSave;
  }

  // When no chart and saving, remove associated charts
  if (!chartToSave.type && !saveAs && loadedQuery) {
    loadedQuery.charts = [];
  }

  // Save the queries. Multiple queries because user may save a My Report and a Suggested Report
  const reportBindedCountries = loadedQueryToSave && loadedQueryToSave.countries ?
    loadedQueryToSave.countries : supportedCountries;
  let savedQueries: SavedQuery[] =
    await dispatchSaveQuery(
      query,
      formData,
      dispatch,
      userId,
      reportBindedCountries,
      loadedQuery,
      loadedQueryToSave?.id
    );

  // Store a temp query. This is a query that needs to be persisted temporarily (use case: when user presses print
  // and they are navigated to a print version of a report)
  let tempQuery: undefined | SavedQuery = undefined;
  if (savedQueries.length > 0) {
    tempQuery = head(savedQueries);
    if (tempQuery && tempQuery.reportType === REPORT_TYPE_TEMP) {
      dispatch(storeTempQuery(tempQuery));
    }
  }

  if (chartToSave.type) {
    // iterate through the saved queries and save the chart assigned to it
    let chartRequests: Promise<void>[] =
      map(savedQueries, async (savedQuery: SavedQuery, index: number) => {
        let chart = Object.assign({}, chartToSave);

        // If the the query being saved is temporary, then need to make a new temporary chart as well.
        // If there are multiple savedQueries, then a new chart is required for each
        const newChart = (tempQuery && tempQuery.reportType === REPORT_TYPE_TEMP) || index > 0 || saveAs;
        if (newChart) {
          chart.chartId = uuidv4();
        }

        // Link report and chart by query id
        chart.queryId = savedQuery.id;
        // no blank titles
        chart.title = chart.title ? chart.title : 'My Report';

        // Dispatch chart with saved flag so we know its saved
        chart.saved = true;
        dispatch(storeChart(chart));

        return ChartService.apiSaveChart(chart);
      });

    await Promise.all(chartRequests);
  }

  dispatch(saveQuerySuccess());

  if (savedQueries.length > 1) {
    // After a save return the "My Report" rather than the Suggested Report
    // so if the user continues to make any further changes it will be to their own report only
    return getMySavedQuery(savedQueries);
  }
  return head(savedQueries);
}

function getMySavedQuery(savedQueries: SavedQuery[]): SavedQuery | undefined {
  savedQueries = filter(savedQueries, function(sq: SavedQuery) {
    return sq.reportType === REPORT_TYPE_USER;
  });
  return head(savedQueries);
}

export function dispatchSaveQuery(query: Query,
                                  formData: SaveQueryFormData,
                                  dispatch: Dispatch<any>,
                                  userId: number,
                                  supportedCountries: SupportedCountriesType,
                                  savedQuery?: SavedQuery,
                                  /** The original id of the saved report */
                                  currentReportId?: string): Promise<SavedQuery[]> {

  let postData: SavedQuery[] = [];
  let updatedQuery: SavedQuery;

  // formData.tempReport = true is for Print and PDF functionality so will never be an existing saved query.
  const isExistingReport: boolean = !!(savedQuery && !formData.tempReport);
  if (isExistingReport) {
    // savedQuery exists which means we are doing a save/update (as opposed to a save as)
    // formData.suggestedReport and formData.userReport are both false as it is not allowed to be updated
    // The backend API enforces this rule so it does not matter if REPORT_TYPE is sent or not
    updatedQuery = Object.assign({}, savedQuery);
    updatedQuery.name = formData.name;
    updatedQuery.description = formData.description;
    updatedQuery.query = JSON.stringify(query);
    updatedQuery.countries = supportedCountries;
    postData.push(updatedQuery);
  } else { // Saving new reports

    if (formData.tempReport) {
      postData.push({
        ...createSavedQueryPostData(query, formData, userId, supportedCountries, REPORT_TYPE_TEMP),
        reportId: currentReportId,
      } as SavedQuery);
    }

    if (!formData.tempReport && formData.suggestedReport) {
      postData.push({
        ...createSavedQueryPostData(query, formData, userId, supportedCountries, REPORT_TYPE_SUGGESTION),
        reportId: currentReportId,
      } as SavedQuery);
    }

    if (!formData.tempReport && formData.userReport) {
      postData.push({
        ...createSavedQueryPostData(query, formData, userId, supportedCountries, REPORT_TYPE_USER),
        reportId: currentReportId,
      } as SavedQuery);
    }

    if (!formData.tempReport && formData.dashboardReport) {
      postData.push({
        ...createSavedQueryPostData(query, formData, userId, supportedCountries, REPORT_TYPE_DASHBOARD),
        reportId: currentReportId,
      } as SavedQuery);
    }
  }

  return apiSaveQuery(postData).then(
    (res: SavedQuery[]) => {

      if (updatedQuery) {
        dispatch(storeReportTitle(updatedQuery.name));
      }
      return res;
    },
    () => {
      dispatch(saveQueryError());
      return [];
    });
}

function createSavedQueryPostData(query: Query,
                                  formData: SaveQueryFormData,
                                  userId: number,
                                  supportedCountries: SupportedCountriesType,
                                  reportType: REPORT_TYPE): SavedQuery {
  return Object.assign(new SavedQuery(), {
    id: uuidv4(),
    query: JSON.stringify(query),
    name: formData.name,
    description: formData.description,
    moduleName: query.module,
    userId: userId,
    reportType: reportType,
    countries: supportedCountries,
  });
}

export function loadQuery(queryId: string): any {
  return (dispatch: any, getState: any) => {
    return dispatchLoadQuery(queryId, dispatch, getState);
  };
}

export function dispatchLoadQuery(queryId: string,
                                  dispatch: Dispatch<any>,
                                  getState: () => AppState): Promise<SavedQuery|void> {
  dispatch(loadQueryStart());

  return apiGetQuery(queryId).then(
    (savedQuery: SavedQuery) => {
      let queryLoaderService: QueryLoaderService = new QueryLoaderService();
      return queryLoaderService.loadQueryIntoAppState(savedQuery, dispatch, getState);
    },
    (error: Error) => {
      dispatch(loadQueryError(error));
      throw error;
    }
  );
}

export interface ReportDetailsAction {
  type: FETCHED_REPORT_DETAILS;
  reportDetails: ReportDetails;
}

export function fetchedReportDetails(numPages: number, numResults: number): ReportDetailsAction {
  return {
    type: FETCHED_REPORT_DETAILS,
    reportDetails: {
      numPages: numPages,
      numResults: numResults
    }
  };
}

export function generateReportStart() {
  return {
    type: GENERATE_REPORT_START
  };
}

export function generateReportCanceled() {
  return {
    type: GENERATE_REPORT_CANCELED
  }
}

export function generateReportFinished() {
  return {
    type: GENERATE_REPORT_FINISHED
  };
}

export interface HydrateStoreAction {
  type: HYDRATE_STORE;
  appState: AppState;
}

export function hydrateStore(state: AppState) {
  return {
    type: HYDRATE_STORE,
    appState: state
  };
}

export function getViewsAndStore(withoutMemo?: boolean):
  (dispatch: Dispatch<any>, getState: () => AppState) => Promise<void> {
    return async (dispatch, getState) => {
      const appState = getState();
      const loadingModule = appState.module.name;
      const supportedCountries = appState.selectedCountries;

      const data: View[] = withoutMemo ?
        await apiGetViews(loadingModule, supportedCountries) :
        await memoizeApiGetViews(loadingModule, supportedCountries);

      const selectedModule = getState().module.name;

      // Only render the views if they are for the currently selected module.
      // This solves the following issue:
      // 1. User clicks on Module A, sends request to get views for A
      // 2. User clicks on Module B, sends request to get views for B. The currently selected module is B.
      // 3. Views for B returns first, dispatches
      // 4. Views for A return, dispatches, this overrides the correct views that should be displayed.
      if (loadingModule === selectedModule) {
        dispatch(fetchedViews(data['views']));
        dispatch(setTranslations(data['translations']));
      }
  };
}

export interface ReportTitleAction {
  type: STORE_REPORT_TITLE;
  reportTitle: string;
}

export function storeReportTitle(reportTitle: string) {
  return {
    type: STORE_REPORT_TITLE,
    reportTitle: reportTitle
  };
}

export function storeCancelSource(cancelTokenSource: CancelTokenSource): StoreCancelSourceAction {
  return {
    type: STORE_CANCEL_SOURCE,
    cancelTokenSource: cancelTokenSource
  };
}

export function doCancel(message: string = ''): StoreCancelSourceAction {
  return {
    type: CANCEL,
    cancelTokenSource: null,
    message: message
  };
}

export function getParamsForDrilldown(query: Query,
                                      rowIndex: number,
                                      cellIndex: number,
                                      columnMeta: ColumnMeta[],
                                      report: ReportTable,
                                      views: View[],
                                      offset?: number,
                                      limit?: number) {

  let measure = columnMeta[cellIndex].measure;
  if (!measure) {
    throw new AppError('Should not be able to get here without an associated Measure.');
  }

  let measureView = columnMeta[cellIndex].view;

  // The backend requires Join information in order to determine JoinDimension ie which Join the Dimension
  // is associated with
  let measureJoin = (columnMeta[cellIndex].join);

  if (measureJoin) {
    let foundView = getViewFromJoin(views, measureJoin);

    if (foundView) {
      measureView = foundView;
    }
  }

  let currentRowData: ReportRow = report.rows[rowIndex];

  // Add the group bys as filters
  let filterData: Filter[] = createFiltersForDrilldownQuery(currentRowData, columnMeta) as Filter[];

  if (!measure) {
    throw new AppError('A drill down report was requested for a non-aggregate.');
  }

  let drilldownQuery: Query = new Query();
  Object.assign(drilldownQuery, {
    module: query.module,
    dataType: query.dataType,
    view: query.view,
    measures: [],
    joinMeasures: query.joinMeasures,
    joins: query.joins,
    filters: removeMeasuresFilters(filterData.concat(query.filters)),
    dimensions: [], // Unused in drilldown queries so remove from request
    joinDimensions: [], // Unused
    groupBys: [], // Unused
    orderBys: [], // Unused
    ...offset !== undefined ? { offset: offset } : {},
    ...limit !== undefined ? { limit: limit } : {},
  });

  // Encoded Query object is sent to the API
  let encodedQuery = getEncodedQuery(drilldownQuery);

  return {
    encodedQuery,
    measure,
    measureView,
    measureJoin
  };
}

export interface ReportPaginationDataAction {
  type: FETCHED_REPORT_PAGINATION_DATA;
  pagination: ReportPaginationData;
}

function fetchedReportPagination(data: ReportPaginationData|undefined): ReportPaginationDataAction {
  return {
    type: FETCHED_REPORT_PAGINATION_DATA,
    pagination: data
  } as ReportPaginationDataAction;
}

/**
 * Convert multi-value from FILTER to comma separated value to support IN
 * @param queryForApi
 */
export function modifyFilters(queryForApi: QueryForApi) {
  const filterMapper = (f: Filter) => {
    let newValue = f.value;
    // if it is array , they are from select 2 , refine them first
    if ([FILTER_TYPE_EQUALS, FILTER_TYPE_NOT_EQUALS].includes(f.type) && Array.isArray(f.value)) {
      newValue = f.value.map((fv: any) => {
        return fv.value;
      });
      return {
        ...f,
        type: (f.type === FILTER_TYPE_EQUALS ? FILTER_TYPE_IN : FILTER_TYPE_NOT_IN) as FILTER_TYPE,
        value: newValue
      };
    } else {
      return f;
    }
  };

  const newFilters = queryForApi.filters.map(filterMapper);
  const newFilterGroups = queryForApi.filterGroups.map(filters => filters.map(filterMapper));

  return {
    ...queryForApi,
    filters: newFilters,
    filterGroups: newFilterGroups
  };
}

/**
 * Remove measure filter from drill filters
 * @param FilterData
 */
function removeMeasuresFilters(filterData: object) {
  return Object.values(filterData).filter(val => val && val.dimensionName);
}
