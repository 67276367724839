import * as React from 'react';
import classnames from 'classnames';
import { Badge } from 'elmo-elements';
import { BadgeProps } from 'elmo-elements/Badge';

import './style.scss';

interface Props {
  className?: string;
  type?: BadgeProps['type'];
  variant?: 'default' | 'light';
}

export type ElmoBadgeProps = Props & Pick<BadgeProps, Exclude<keyof BadgeProps, 'type'>>;

const ElmoBadge: React.FC<ElmoBadgeProps> = ({ children, className, type = 'info', variant = 'default', ...props }) => {
  return (
    <Badge
      className={
        classnames('elmo-badge--override', className, {
          'elmo-badge--default': variant === 'default',
          'elmo-badge--light': variant === 'light'
        })
      }
      type={type}
      {...props}
    >
      {children}
    </Badge>
  );
};

export default ElmoBadge;