import React, { useEffect, useState } from 'react';
import { Flex } from '@eds/flex';
import { useDebounce } from '@eds/utils';

import { AuthService } from 'src/services/authService';

import { ReportsListFilters } from './reports-list-filters.component';
import { ReportsListPagination } from './reports-list-pagination.component';
import { ReportsListTable } from './reports-list-table.component';
import { useReportsList } from './reports-list.hooks';
import {
  ReportsListQueryParams,
  useReportsListQuery,
  useReportsListUserCapabilitiesQuery,
} from './reports-list.queries';

type ReportsListProps = {
  onDelete: (id: string) => void;
  onExport: (id: string) => void;
  onSchedule: (id: string, name: string) => void;
  onShare: (id: string, name: string, sharedWith?: string[]) => void;
  showLegacyReportInfo: () => void;
};

export const ReportsList = (props: ReportsListProps) => {
  const {
    keywordFilter,
    moduleFilter,
    onSort,
    rangeStart,
    reportTypeFilter,
    setKeywordFilter,
    setModuleFilter,
    setReportTypeFilter,
    sortData,
    ...paginationProps
  } = useReportsList();
  const { currentItemsPerPage, currentPage } = paginationProps;
  const userId = AuthService.getInstance().getUserData()?.id || 0;

  const [query, setQuery] = useState<ReportsListQueryParams>({
    currentItemsPerPage,
    currentPage,
    keywordFilter,
    moduleFilter,
    rangeStart,
    reportTypeFilter,
    sortData,
  });
  const [debouncedQuery] = useDebounce(query, 300);

  const {
    data = { count: 0, queries: [] },
    isFetching,
    isLoading,
    isPreviousData,
  } = useReportsListQuery(debouncedQuery);

  const { data: userCapabilities } = useReportsListUserCapabilitiesQuery(userId);

  const dependencies = 
    `${currentItemsPerPage}${currentPage}${keywordFilter}${moduleFilter.join()}${rangeStart}${reportTypeFilter
      .join()}${sortData.columnName}${sortData.sortDirection}`;

  useEffect(() => {
    setQuery({
      currentItemsPerPage,
      currentPage,
      keywordFilter,
      moduleFilter,
      rangeStart,
      reportTypeFilter,
      sortData,
    });
  }, [dependencies]);

  return (
    <Flex
      width="100%"
      gap="medium"
      flexDirection="column"
      backgroundColor="neutral"
      padding="xxlarge"
    >
      <ReportsListFilters
        enableReportTypeFilter={userCapabilities?.canFilterReportsByType}
        keywordFilter={keywordFilter}
        loading={isLoading || (isFetching && isPreviousData)}
        moduleFilter={moduleFilter}
        reportTypeFilter={reportTypeFilter}
        setKeywordFilter={setKeywordFilter}
        setModuleFilter={setModuleFilter}
        setReportTypeFilter={setReportTypeFilter}
      />
      <ReportsListTable onSort={onSort} reports={data.queries} sortData={sortData} userId={userId} {...props} />
      <ReportsListPagination totalItems={data.count} {...paginationProps} />
    </Flex>
  );
};
