import * as React from 'react';
import * as Highcharts from 'highcharts';
import { get } from 'lodash';
import { Layout } from 'react-grid-layout';
import { Spinner } from 'elmo-elements';
import { noop } from 'lodash';

import { AppState } from '../../redux';
import { getQueryForApiByAppState } from '../../services/reportService';
import { ReportTable } from '../../types/reportTableModel';
import Chart from '../../components/Chart';
import { QueryLoaderService } from '../../services/queryLoaderService';
import { Widget } from '../../types/widgetModel';
import { SOURCE_TYPE_DASHBOARD } from '../../constants/dashboard';

type ChartWidgetProps = {
  widget: Widget;
  layout?: Layout;
  handleChangeRefChart?: (hcRef: Highcharts.Chart) => void;
  currentGridWidth?: number;
  updateWidgetStatus: (widgetId: string, status: boolean) => void;
};

const ChartWidget = (props: ChartWidgetProps) => {
  const [ isLoaded, setIsLoaded ] = React.useState<boolean>(false);
  const [ subState, setSubState ] = React.useState<AppState | null>(null);

  // This is used to determine a manual re-render eg when Chart visualisation is resized
  const listenerElement = () => {
    const widgetCell = document.querySelector('[data-widget=\'' + props.widget.widgetId + '\']');
    if (!widgetCell) {
      return;
    }
    return widgetCell;
  };

  React.useEffect(
     () => {
       async function fetchQuery() {
         let queryLoaderService: QueryLoaderService = new QueryLoaderService();
         let initSubState: AppState | null = null;
         const {widget} = props;
         let query = widget.getQueryFromWidget();
         const chartQuery = get(widget, 'chart.query');
         const chartType = get(widget, 'chart.chartData.type');

         // Widget can have an attached Chart (Chart Widget) or SavedQuery (for Table Widget)
         if (!!chartQuery && chartQuery.id && query && !chartType) {
           const chart = Object.assign({}, widget.chart, {});
           initSubState = await queryLoaderService.loadQueryIntoWidget(query, chart);
         } else if (widget.chart && query) {
           initSubState = await queryLoaderService.loadQueryIntoWidget(query, widget.chart);
         } else if (query) {
           initSubState = await queryLoaderService.loadQueryIntoWidget(query, null);
         }

         if (!initSubState) {
           return;
         }

         setIsLoaded(true);
         setSubState(initSubState);
       }

       fetchQuery();
    },
     []
  );

  if (!isLoaded) {
    return (
      <div className="widget-loading-container">
        <Spinner size="m" />
      </div>
    );
  }

  if (!subState) {
    return null;
  }

  let updatedQuery, updatedLoadedQuery;
  if (props.widget.chart && props.widget.chart.query) {
    updatedLoadedQuery = props.widget.chart.query;
    updatedQuery = props.widget.chart.query.query;
  } else if (props.widget.query) {
    updatedLoadedQuery = props.widget.query;
    updatedQuery = props.widget.query.query;
  }

  return (
    <Chart
      query={updatedQuery}
      report={new ReportTable()}
      columnMeta={subState.columnMeta}
      queryForApi={getQueryForApiByAppState(subState)}
      dispatchChart={noop}
      chart={subState.chart}
      onChartCreated={noop}
      readOnly={true}
      loadedQuery={updatedLoadedQuery}
      listenerElement={listenerElement()}
      layout={props.layout}
      source={SOURCE_TYPE_DASHBOARD}
      handleChangeRefChart={props.handleChangeRefChart}
      currentGridWidth={props.currentGridWidth}
      widgetId={props.widget.widgetId}
      updateWidgetStatus={props.updateWidgetStatus}
      isDashboardPage={true}
    />
  );
};

export default ChartWidget;
