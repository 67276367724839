import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, QueryForApi } from '../redux';
import Chart from '../components/Chart';
import { ColumnMeta } from '../types/columnMetaModel';
import { SavedQuery } from '../types/savedQueryModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES } from '../constants';
import { getQueryForApiByAppState } from '../services/reportService';
import { Chart as ChartModel } from '../types/chartModel';
import { setShouldGenerateChart, storeChart } from '../actions/chart';
import { ReportTable } from '../types/reportTableModel';
import { NotificationService } from '../services/notificationService';
import { DashboardService } from '../services/dashboardService';
import { Dashboard } from '../types/dashboardModel';
import { User } from '../types/userModel';
import { AuthService } from '../services/authService';

interface ChartPageProps {
  query: string;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  report: ReportTable;
  columnMeta: ColumnMeta[];
  loadedQuery?: SavedQuery;
  queryForApi: QueryForApi;
  dispatch: Dispatch<any>;
  chart: ChartModel;
  readOnly?: boolean;
  isDashboardPage?: boolean;
  notificationService: NotificationService;
  authService: AuthService;
  printView?: boolean;
  chartListenerElement?: Element;
}

interface ChartPageState {
  loading: boolean;
  dashboards: Dashboard[];
}

const mapStateToProps = (state: AppState) => ({
  query: state.query,
  dataType: state.dataType,
  report: state.report,
  columnMeta: state.columnMeta,
  queryForApi: getQueryForApiByAppState(state),
  chart: state.chart,
  notificationService: state.notificationService,
  authService: state.authService
});

class ChartSelectContainer extends React.Component<ChartPageProps, ChartPageState> {
  constructor(props: ChartPageProps) {
    super(props);

    this.state = {
      loading: false,
      dashboards: []
    };
  }

  render() {
    let shareEnabled = this.props.authService.canViewShareChartButton();
    return (
      <Chart
        query={this.props.query}
        report={this.props.report}
        columnMeta={this.props.columnMeta}
        loadedQuery={this.props.loadedQuery}
        queryForApi={this.props.queryForApi}
        dispatchChart={this.dispatchChart}
        chart={this.props.chart}
        onChartCreated={this.onChartCreated}
        readOnly={this.props.readOnly}
        isDashboardPage={this.props.isDashboardPage}
        shareEnabled={shareEnabled}
        printView={this.props.printView}
        listenerElement={this.props.chartListenerElement}
      />
    );
  }

  dispatchChart = (chart: ChartModel) => {
    this.props.dispatch(storeChart(chart));
  }

  onChartCreated = () => {
    // after the chart data is created, set shouldGenerateChart to false as the
    // next time the Generate Button is clicked, it will be set to true and a change
    // will be detected
    this.props.dispatch(setShouldGenerateChart(false));
  }

  componentDidMount() {
    this.getDashboards();
  }

  getDashboards = async () => {
    let userData: User | null = this.props.authService.getUserData();
    if (userData) {
      let dashboards = await DashboardService.apiGetDashboards(userData);
      this.setState({dashboards: dashboards});
    }
  }

}

const ChartSelect = connect(
  mapStateToProps,
)(ChartSelectContainer);

export default ChartSelect;
