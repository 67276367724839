import { SET_ROUTES } from '../constants/routes';

export type RoutesAction = {
  type: SET_ROUTES;
  routes: any;
};

export function setRoutes(routes: any) {
  return {
    type: SET_ROUTES,
    routes: routes
  };
}