import * as React from 'react';
import { Datepicker as ElmoDatepicker } from 'elmo-elements';
import moment from 'moment';
import { Moment } from '../../constants/moment';
import './style.css';
import { DATETIME_FORMAT } from '../../constants';

type DatepickerProps = {
  value: Moment | null | undefined;
  onChange: (value: Moment | null) => void;
  placeholder?: string;
  id?: string;
  testId?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isEDSStyle?: boolean;
};

class Datepicker extends React.Component<DatepickerProps> {
  onChange = (d: Date | null) => {
    const date = d ? moment(d) : null;
    this.props.onChange(date);
  }

  render() {
    const {
      value,
      placeholder,
      id,
      isClearable,
      isDisabled,
      isEDSStyle,
    } = this.props;
    const dateNative: Date | null = value ? value.toDate() : null;

    return (
      <ElmoDatepicker
        className={isEDSStyle ? undefined : 'crt-datepicker'}
        value={dateNative}
        onChange={this.onChange}
        format={DATETIME_FORMAT}
        placeholder={placeholder}
        id={id}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isInline={false}
      />
    );
  }
}

export default Datepicker;
