import { useEffect } from 'react';
import { UseFormReset, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DashboardCreateEditFieldValues, dashboardCreateEditSchema } from './dashboard-create-edit.validation';

const defaultValues: DashboardCreateEditFieldValues = { description: '', id: '', isSuggested: false, name: '' };

export const useDashboardCreateEditForm = () =>
  useForm<DashboardCreateEditFieldValues>({ defaultValues, resolver: yupResolver(dashboardCreateEditSchema) });

export const useFormValuesInitializer = ({
  description,
  id,
  isOpen,
  isSuggested,
  mode,
  name,
  reset,
}: DashboardCreateEditFieldValues & {
  isOpen: boolean;
  mode: 'create' | 'edit';
  reset: UseFormReset<DashboardCreateEditFieldValues>;
}) =>
  useEffect(
    () => (mode === 'edit' && isOpen ? reset({ description, id, isSuggested, name }) : reset(defaultValues)),
    [isOpen]
  );
