import React, { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react';

interface AppContextType {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppContextProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const value = { isModalOpen, setIsModalOpen };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }

  return context;
};
