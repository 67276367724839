import React from 'react';
import { Avatar } from '@eds/avatar';
import { Button } from '@eds/button';
import { CloseCircleIcon } from '@eds/icon';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { fontSize } from '@eds/core';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

interface SelectedItemCardProps {
  avatar: string;
  avatarName: string;
  email: string;
  name: string;
  onClick: () => void;
  role: string;
}

export const SelectedItemCard = ({ avatar, avatarName, email, name, onClick, role }: SelectedItemCardProps) => (
  <Flex alignItems="center" gap="medium" className={css(cssOverrides.button('ghost'))}>
    <Avatar name={avatarName} src={avatar} size="medium" />
    <Flex
      flex={1}
      flexDirection="column"
      className={css({
        span: {
          lineHeight: fontSize.medium,
        },
      })}
    >
      {name && (
        <Text fontSize="small" fontWeight="bold">
          {`${name}${role ? ', ' : ''}`}
          <Text fontSize="small">{role}</Text>
        </Text>
      )}
      <Text fontSize="small" color="neutralSubtle">
        {email}
      </Text>
    </Flex>
    <Button label="Deselect" icon={CloseCircleIcon} iconOnly size="small" tone="ghost" onClick={onClick} />
  </Flex>
);
