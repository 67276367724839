import { STORE_ORDER_BY } from '../constants';
import { OrderBy } from '../types/orderByModel';

export interface OrderByAction {
  type: STORE_ORDER_BY;
  orderBy: OrderBy;
}

export function storeOrderBy(orderBy: OrderBy): OrderByAction {
  return {
    type: STORE_ORDER_BY,
    orderBy: orderBy
  };
}