import * as React from 'react';
import ElmoCheckbox from '../ElmoCheckbox';

export interface SelectAllButtonProps {
  onClick: () => void;
  active: boolean;
  label: string;
}

class SelectAllButton extends React.Component<SelectAllButtonProps> {

  constructor(props: SelectAllButtonProps) {
    super(props);
  }

  onClick = () => {
    this.props.onClick();
  }

  render() {

    return (
      <ElmoCheckbox
        id="check-button"
        label="Select All"
        ariaLabel="Select All"
        onChange={this.onClick}
        isChecked={this.props.active}
      />
    );
  }
}

export default SelectAllButton;
