import * as React from 'react';
import Collapse from 'reactstrap/lib/Collapse';
import './style.css';

export interface CollapseCardProps {
  title: string | JSX.Element;
  defaultOpen?: boolean;
}

export interface CollapseCardState {
  isOpen: boolean;
}

class CollapseCard extends React.Component<CollapseCardProps, CollapseCardState> {
  constructor(props: CollapseCardProps) {
    super(props);

    this.state = {
      isOpen: this.props.defaultOpen ? this.props.defaultOpen : false
    };
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className="collapse-card card">
        <div
          className="card-header"
          onClick={() => { this.toggleCollapse(); }}
        >
          <span className="float-left">
              {this.state.isOpen &&
              <i className="fa fa-chevron-down" />
              }
            {!this.state.isOpen &&
            <i className="fa fa-chevron-right"/>
            }
          </span>
          <span className="float-left">{this.props.title}</span>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {this.state.isOpen &&
            <div className="card-body">
              {this.props.children}
            </div>
          }
        </Collapse>

      </div>
    );
  }
}

export default CollapseCard;