import * as React from 'react';
import PageTitle from '../PageTitle';
import './style.css';

export interface PageProps {
  title: string;
  id?: string;
  className?: string;
}

class Page extends React.Component<PageProps> {
  constructor(props: PageProps) {
    super(props);
  }

  render() {
    let className: string = this.props.className ? this.props.className : '';
    let id: string = this.props.id ? this.props.id : '';
    return (
      <div
        className={'page ' + className}
        role="main"
        id={id}
      >
        <PageTitle title={this.props.title} />
        <div className="content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Page;
