import { ReactNode } from 'react';
import { SaveAltIcon, DashboardOutlinedIcon, MessagingOutlinedIcon } from 'elmo-elements';

import { ReportBuilderIcon, ScheduledReportsIcon, MyReportsIcon } from '../components/ElmoMenu/Icons';

export type MenuItem = {
  /** The label of the menu item */
  label?: string;
  /** The label of the menu item */
  id?: string;
  /** The link of the menu item */
  link?: string;
  /** Should the path be an exact match? Passed through to react-router. */
  exact?: boolean;
  /** The icon of the menu item */
  icon: ReactNode | string;
};

export class MenuService {
  static MENU_ITEMS: Record<string, MenuItem> = {
    CrtIcon: {
      exact: true,
      icon: MyReportsIcon,
    },
    TimesheetIcon: {
      exact: true,
      icon: ScheduledReportsIcon
    },
    AddCourseOutlinedIcon: {
      exact: false,
      icon: ReportBuilderIcon,
    },
    SaveAltIcon: {
      exact: false,
      icon: SaveAltIcon,
    },
    DashboardOutlinedIcon: {
      exact: false,
      icon: DashboardOutlinedIcon,
    },
    MessagingIcon: {
      exact: false,
      icon: MessagingOutlinedIcon,
    },
  };
}
