import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DashboardData, dashboardsKeys } from 'src/features/dashboards';

const deleteDashboard = async (id: string) => {
  const response = await axios.delete(`api/dashboards/${id}`);
  return response.data;
};

const removeCurrentUserFromSharedDashboard = async (id: string) => {
  const response = await axios.delete(`/api/share-dashboard/${id}`);
  return response.data;
};

export const useDeleteDashboardMutation = (callback: { onError: () => void; onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const query = queryClient.getQueryCache().findAll(dashboardsKeys.lists(), { type: 'active' }).shift();

  return useMutation<unknown, unknown, { id: string; unsharingMode: boolean }>({
    mutationFn: ({ id, unsharingMode }) => {
      if (query) {
        // NOTE: If there's any existing request to fetch the list, it must be cancelled to avoid race condition
        queryClient.cancelQueries({ queryKey: query.queryKey });
        // NOTE: Immediately remove the report being deleted as we are implementing optimistic update
        queryClient.setQueriesData<{ dashboards: DashboardData[]; total: number }>(query.queryKey, previous => {
          const data = {
            dashboards: previous?.dashboards.filter(({ dashboardId }) => dashboardId !== id) || [],
            total: (previous?.total ?? 1) - 1,
          };
          return data;
        });
      }

      return unsharingMode ? removeCurrentUserFromSharedDashboard(id) : deleteDashboard(id);
    },
    onError: callback.onError,
    onSettled: () => queryClient.invalidateQueries({ queryKey: dashboardsKeys.lists() }),
    onSuccess: callback.onSuccess,
  });
};
