import * as React from 'react';
import { BlockIcon } from 'elmo-elements';

type Props = {
  className: string;
};

const NoDataFound = ({ className }: Props) => {
  return (
    <div className={className}>
      <BlockIcon className="block-icon" /> No data found to display this chart
    </div>
  );
};

export default NoDataFound;
