import * as React from 'react';
import { Modal } from 'elmo-elements';
import ExportCsvButton from '../../../../containers/NewReportBuilder/ExportCsvButton';
import spinnerWithGradientImage from '../../../../assets/image/spinnerWithGradient.png';

import './style.css';
import CancelReportButton from "../../../../containers/NewReportBuilder/CancelReportButton";

type SavedReportLoadingProps = {
  isOpened: boolean;
  queryId?: string;
  showExport?: boolean;
  showCancel?: boolean;
  message?: React.ReactElement | string;
};

function SavedReportLoading({showCancel, showExport, queryId, isOpened, message }: SavedReportLoadingProps) {
  const defaultMessage = (
    <div className="exportReportMessageContainer">
      <h2 className="title">Report is being generated...</h2>
      {showExport && <p className="body">Taking a while? Try exporting it instead</p>}
    </div>
  );

  return (
    <div className="saved-report-loading">
      <Modal
        className="generateReportModal"
        type="small"
        isOpened={isOpened}
      >
        <div className="spinnerImageContainer">
          <img src={spinnerWithGradientImage} />
        </div>
        {message || defaultMessage}
        {showCancel &&
          <CancelReportButton/>
        }
        {showExport &&
          <ExportCsvButton
            queryId={queryId || ''}
          />
        }
      </Modal>
    </div>
  );
}

export default SavedReportLoading;
