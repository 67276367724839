
import * as yup from 'yup';
import { DefaultValueType, OperatorType } from '../../../../types/transformationModel';

const schema = yup.object({
  dimension: yup.string().required('Field is required'),
  defaultValue: yup.object({
    type: yup
      .number()
      .oneOf(Object.values(DefaultValueType) as Array<number>)
      .required('A default behaviour needs to be specified'),
    value: yup.string().when('type', {
      is: (type) => type === DefaultValueType.TEXT || type === DefaultValueType.FIELD,
      then: yup.string().required('Future value is required'),
    }),
  }).required(),
  rules: yup.array(yup.object({
    dimension: yup.string()
      .required(({ path }) => `Field is required for row ${Number(path!.split('[')[1].split(']')[0]) + 1}`)
      .nullable(),
    operator: yup.string()
      .oneOf(Object.values(OperatorType))
      .required(({ path }) => `Operator is required for row ${Number(path!.split('[')[1].split(']')[0]) + 1}`)
      .nullable(),
    currentValue: yup.string()
      .when('operator', {
        is: (operator) => operator !== OperatorType.IS_BLANK && operator !== OperatorType.NOT_BLANK,
        then: yup.string()
          .required(({ path }) => `Current value is required for row ${Number(path!.split('[')[1].split(']')[0]) + 1}`),
      }),
    futureValue: yup.string()
      .required(({ path }) => `Future value is required for row ${Number(path!.split('[')[1].split(']')[0]) + 1}`),
  })).required('At least one translation rule is required'),
}).required();

export default schema;
