import * as React from 'react';
import { Text } from 'elmo-elements';

import './style.scss';

export interface UserListCardProps {
  fullName: string;
  email: string;
  role?: string;
  avatarUrl: string;
  rightPanel?: string | React.ReactNode;
  className?: string;
}

export const UserListCard = (props: UserListCardProps) => {
  return (
    <div className={`userListCard ${props.className ? props.className : ''}`}>
      <div className="leftPanel">
        <div className="avatar">
          <img src={props.avatarUrl} />
        </div>
        <div>
          <div>
            <Text size="sm" isBold={true}>
              {props.fullName}
            </Text>
            {!!props.role &&
            <Text size="xs">
              {`, ${props.role}`}
            </Text>}
          </div>
          <div>
            <Text className="email" size="sm">{props.email}</Text>
          </div>
        </div>
      </div>
      {!!props.rightPanel &&
      <div className="rightPanel">
        {props.rightPanel}
      </div>}
    </div>
  );
};
