import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { FilterBar } from '@eds/filtering';
import { Flex } from '@eds/flex';
import { SelectDropdown } from '@eds/select-dropdown';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';
import { isElmoSuperAdmin } from 'src/features/utils';
import { useModulesListQuery } from 'src/features/report-builder';

import { ReportType } from './reports-list.queries';

type ReportsListFiltersProps = {
  enableReportTypeFilter?: boolean;
  keywordFilter: string;
  loading: boolean;
  moduleFilter: string[];
  reportTypeFilter: ReportType[];
  setKeywordFilter: Dispatch<SetStateAction<string>>;
  setModuleFilter: Dispatch<SetStateAction<string[]>>;
  setReportTypeFilter: Dispatch<SetStateAction<ReportType[]>>;
};

const defaultReportTypeFilterOptions = [
  {
    label: 'Created by you',
    value: 1 as const,
  },
  {
    label: 'Suggested by ELMO',
    value: 2 as const,
  },
  {
    label: 'Shared with you',
    value: 6 as const,
  },
  {
    label: 'Shared by you',
    value: 5 as const,
  },
];

export const ReportsListFilters = ({
  enableReportTypeFilter,
  keywordFilter,
  loading,
  moduleFilter,
  reportTypeFilter,
  setKeywordFilter,
  setModuleFilter,
  setReportTypeFilter,
}: ReportsListFiltersProps) => {
  const canClearFilters = keywordFilter.length + moduleFilter.length + reportTypeFilter.length > 0;
  const { data = [] } = useModulesListQuery();

  const moduleFilterOptions = data.map(({ id, label, type }) => ({
    label: `${label}${type === 'tms' ? ' - TMS' : ''}`,
    value: id,
  }));

  const onReportTypeChange = (option: { label: string; value: ReportType }[] | null) => {
    setReportTypeFilter(option?.map(({ value }) => value) || []);
  };

  const reportTypeFilterOptions = isElmoSuperAdmin()
    ? [defaultReportTypeFilterOptions[0], defaultReportTypeFilterOptions[1]]
    : defaultReportTypeFilterOptions;

  const selectedReportTypes = reportTypeFilter.map(reportType =>
    reportTypeFilterOptions.find(({ value }) => reportType === value)
  );

  let filterBarChildren: ReactElement | [ReactElement, ReactElement] = (
    <SelectDropdown
      hideSearchInput
      clearButton
      label="Module"
      value={moduleFilterOptions.filter(({ value }) => moduleFilter.includes(value))}
      isMulti
      onChangeMulti={option => setModuleFilter(option?.map(({ value }) => value) || [])}
      items={moduleFilterOptions}
    />
  );

  if (enableReportTypeFilter) {
    filterBarChildren = [
      <SelectDropdown
        clearButton
        hideSearchInput
        isMulti
        items={reportTypeFilterOptions}
        label="Report type"
        onChangeMulti={onReportTypeChange}
        value={selectedReportTypes}
      />,
      filterBarChildren,
    ];
  }

  return (
    <Flex className={css(cssOverrides.button('neutral'))}>
      <FilterBar
        clearable={canClearFilters}
        keywordFilter={{
          placeholder: 'Search by report or description...',
          value: keywordFilter,
          onChange: event => setKeywordFilter(event.target.value),
          onClear: () => setKeywordFilter(''),
          width: '20rem',
        }}
        loading={loading}
        onClear={() => {
          setKeywordFilter('');
          setModuleFilter([]);
          setReportTypeFilter([]);
        }}
      >
        {filterBarChildren}
      </FilterBar>
    </Flex>
  );
};
