import axios, { AxiosResponse } from 'axios';
import { map } from 'lodash';
import { Dashboard } from '../types/dashboardModel';
import { Widget } from '../types/widgetModel';
import { User } from '../types/userModel';
import uuidv4 from 'uuid/v4';
import { WIDGET_TYPE_CHART, WIDGET_TYPE_VIEW } from '../services/widgetService';
import { Chart } from '../types/chartModel';

export type dashboardsRequestParamsType = {
  group: string;
};
export class DashboardService {

  static async apiGetDashboards(user: User, params?: dashboardsRequestParamsType) {
    let response: AxiosResponse = await axios.get(
      '/api/users/' + user.id + '/dashboards', { params });

    let dashboards = map(response.data, (data: any) => {
      return new Dashboard(data);
    });

    return dashboards;
  }

  static async apiGetDashboard(dashboardId: string) {
    let response: AxiosResponse = await axios.get('/api/dashboards/' + dashboardId, {});
    return new Dashboard(response.data);
  }

  static async apiDeleteDashboardCache(dashboardId: string) {
    return await axios.delete(`/api/dashboards/${dashboardId}/cache`, {});
  }

  static async saveToDashboard(widget: Widget) {
    const chartId = (widget.chart) ? widget.chart.chartId : null;
    return await axios.post(
      '/api/dashboards/' + widget.dashboardId + '/widgets/' + widget.widgetId,
      {'title': widget.title, 'type': widget.type, 'chart_id': chartId});
  }

  static async saveTableToDashboard(widget: Widget, queryId: string) {
    return await axios.post(
      '/api/dashboards/' + widget.dashboardId + '/widgets/' + widget.widgetId,
      {'title': widget.title, 'type': widget.type, 'query_id': queryId});
  }

  static async createDashboard(
      user: User, dashboard: { title: string, description?: string, type?: number, status?: number }
  ): Promise<any> {
    return await axios.post(
      '/api/users/' + user.id + '/dashboards',
      {
        'dashboardId': uuidv4(),
        'title': dashboard.title,
        'description': dashboard.description,
        'type': dashboard.type,
        'status': dashboard.status
      });
  }

  static async apiSaveDashboard(dashboard: Dashboard) {
    let response: AxiosResponse = await axios.post('/api/dashboards/' + dashboard.dashboardId, dashboard);
    return response.data;
  }

  static async apiDeleteDashboard(dashboardId: string) {
    let response: AxiosResponse = await axios.delete('/api/dashboards/' + dashboardId);
    return response.data;
  }

  static sendToDashboard = async (
    dashboard: Dashboard, title: string, chart?: Chart, queryId?: string
  ) => {
    let widget = new Widget();
    widget.widgetId = uuidv4();
    widget.title = title;
    if (chart) {
      widget.type = WIDGET_TYPE_CHART;
    } else {
      widget.type = WIDGET_TYPE_VIEW;
    }

    widget.dashboardId = dashboard.dashboardId;
    let result;
    if (chart) {
      widget.chart = chart;
      result = await DashboardService.saveToDashboard(widget);
    } else if (queryId) {
      result = await DashboardService.saveTableToDashboard(widget, queryId);
    }

    return result;
  }

}
