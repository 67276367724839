import * as React from 'react';
import {
  apiGenerateViews, apiGetExistingViews, apiGetPublishedConfig, apiGetTransformers, apiSaveView,
  downloadData, formatViewsPhpArray, getUnselectedConfigOptions, MEASURE_ON_TABLE
} from '../../../services/configurationService';
import { map, find, findIndex, forEach, differenceWith } from 'lodash';
import CollapseCard from '../../../shared-components/CollapseCard';
import ConfigAddViewButtonModal from '../../../components/Config/ConfigAddViewModal';
import ConfigEditDimensionModal from '../../../components/Config/ConfigEditDimensionModal';
import ConfigAddEditMeasureModal from '../../../components/Config/ConfigAddEditMeasureModal';
import './style.css';
import { CONFIG_STATUS_DRAFT, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../../constants';
import LoadingIcon from '../../../shared-components/LoadingIcon';
import { NotificationService } from '../../../services/notificationService';
import ConfigMenu from '../../../components/Config/ConfigMenu';
import ConfigDetails from '../../../components/Config/ConfigDetails';
import { ConfigView } from '../../../types/config/configViewModel';
import { ConfigDimension } from '../../../types/config/configDimensionModel';
import { ConfigMeasure } from '../../../types/config/configMeasureModel';
import { ConfigData } from '../../../types/config/configDataModel';
import EditTranslationModal from '../../../components/Config/EditTranslationModal';
import {
  ConfigColumnTranslationRecord,
  ConfigViewTranslationRecord,
  ConfigColumnTranslationRecordFactory, ConfigTranslationModel, ConfigViewTranslationsRecordFactory
} from '../../../types/config/configTranslationsModel';
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { formatTranslationsPhpArray } from '../../../services/configurationExportService';
import { Transformer } from '../../../types/transformerModel';
import Page from '../../../shared-components/Page';

interface EditConfigViewsPageProps {
  configId: string;
  notificationService: NotificationService;
}

interface EditConfigViewsPageState {
  availableConfigViews: ConfigView[];
  selectedConfigViews: ConfigView[];
  showEditColumnsModal: boolean;

  // Populated when a dimension is clicked on to have its details edited
  currentlyEditingDimension: ConfigDimension | null;
  currentlyEditingMeasure: ConfigMeasure | null;
  currentlyEditingView: ConfigView | null;
  editMeasureModalSqlOptions: string[];
  currentlyEditingTranslation: ConfigColumnTranslationRecord | null;
  config: ConfigData | null;
  transformers: Transformer[];
}

export class EditConfigViewsPage extends React.Component<EditConfigViewsPageProps, EditConfigViewsPageState> {
  protected editDimensionModalRef: any; // ref to the Edit dimension/ modal
  protected editMeasureModalRef: any; // ref to the Edit measure modal
  protected editTranslationsModalRef: any;
  protected sqlOptionsForAddEditMeasure: Map<string, string[]>; // array of all dimensions' sql options
  protected translations: ImmutableMap<string, ConfigViewTranslationRecord>;

  constructor(props: EditConfigViewsPageProps) {
    super(props);

    this.state = {
      availableConfigViews: [],
      selectedConfigViews: [],
      showEditColumnsModal: false,
      currentlyEditingDimension: null,
      currentlyEditingView: null,
      currentlyEditingMeasure: null,
      editMeasureModalSqlOptions: [],
      config: null,
      currentlyEditingTranslation: null,
      transformers: []
    };

    this.editDimensionModalRef = React.createRef();
    this.editMeasureModalRef = React.createRef();
    this.editTranslationsModalRef = React.createRef();
  }

  async componentDidMount() {
    this.loadConfig(this.props.configId, false);
    this.loadTransformers();
  }

  async loadTransformers() {
    const { notificationService } = this.props;
    try {
      let transformers = await apiGetTransformers();

      this.setState({
        transformers: transformers
      });
    } catch (e) {
      notificationService.addNotification(NOTIFICATION_ERROR, 'An error occurred while loading transformers.');
      throw e;
    }
  }

  async loadConfig(configId: string, loadPublished: boolean) {
    const { notificationService } = this.props;

    try {
      // get the array of views that have been already selected and their dimensions / measures
      let existingConfig = await apiGetExistingViews(configId);

      this.translations = existingConfig.translations;

      let existingViews = existingConfig.views;

      // get the array of *all* available configs, regardless of whether they have been chosen or not
      let availableViews: ConfigView[] = await apiGenerateViews(configId);

      this.sqlOptionsForAddEditMeasure = new Map();
      forEach(availableViews, (config: ConfigView) => {
        let sqlOptions = map(config.dimensions, 'sql');
        sqlOptions.push(MEASURE_ON_TABLE);
        // get all the dimensions available
        this.sqlOptionsForAddEditMeasure.set(config.tableName, sqlOptions);
      });

      // get the array of dimensions/measures that have not been selected.
      // this array contains ALL the views, regardless of whether it has any dimensions / measures selected
      // If it does not have any selected dimensions / measures, its dimensions / measures arrays wil be empty.
      let availableViewConfig: ConfigView[] = getUnselectedConfigOptions(existingViews, availableViews);

      this.setState({
        availableConfigViews: availableViewConfig,
        selectedConfigViews: existingViews,
        config: !loadPublished ? existingConfig.config : this.state.config
      });
    } catch (e) {
      notificationService.addNotification(NOTIFICATION_ERROR, 'An error occurred while loading configurations.');
      throw e;
    }
  }

  /**
   * When views have been added via the modal
   * @param {ConfigView[]} views
   */
  onAddViews = (views: ConfigView[]) => {
    this.setState({
      selectedConfigViews: this.state.selectedConfigViews.concat(views)
    });
  }

  onLoadPublishedConfigClick = async () => {
    if (!this.state.config) {
      this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'No configuration loaded.');
      return;
    }

    let publishedConfig = await apiGetPublishedConfig(this.state.config.module, this.state.config.clientName);
    if (!publishedConfig) {
      return;
    }

    this.loadConfig(publishedConfig.id, true);
  }

  onSaveClick = async () => {
    if (!this.state.config) {
      this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'No configuration loaded.');
      return;
    }

    // convert the selected views data into a PHP array format
    await apiSaveView(this.state.config.id, this.state.selectedConfigViews, this.translations);
    this.props.notificationService.addNotification(NOTIFICATION_SUCCESS, 'Configuration saved successfully.');
  }

  render() {
    if (!this.state.config) {
      return <LoadingIcon />;
    }

    return (
      <Page title="Edit Views Configuration" className="configuration-layout edit-module-config-page">
        <ConfigMenu />
        {this.renderButtons()}
        <ConfigDetails config={this.state.config} />

        {this.state.selectedConfigViews && this.renderViews(this.state.selectedConfigViews)}
        <ConfigEditDimensionModal
          column={this.state.currentlyEditingDimension}
          onDone={this.onEditDimensionDone}
          ref={this.editDimensionModalRef}
        />
        <ConfigAddEditMeasureModal
          measure={this.state.currentlyEditingMeasure}
          ref={this.editMeasureModalRef}
          sqlOptions={this.state.editMeasureModalSqlOptions}
          onDone={this.onEditMeasureDone}
        />
        <EditTranslationModal
          ref={this.editTranslationsModalRef}
          translation={this.state.currentlyEditingTranslation}
          transformers={this.state.transformers}
          onDone={this.onEditTranslationDone}
        />
      </Page>
    );
  }

  renderButtons() {
    if (!this.state.config) {
      return null;
    }

    return (
      <div className="mb-2">
        <ConfigAddViewButtonModal
          availableViews={this.state.availableConfigViews}
          selectedViews={this.state.selectedConfigViews}
          onAddViews={this.onAddViews}
        />
        <button
          className="btn btn-primary mr-2"
          onClick={this.clearViewsClick}
        >
          <i className="fa fa-times" /> Clear All
        </button>
        <button
          className="btn btn-primary mr-2"
          onClick={this.onExportViewsClick}
        >
          <i className="fa fa-columns" /> Export Views
        </button>
        <button
          className="btn btn-primary mr-2"
          onClick={this.onExportTranslationsClick}
        >
          <i className="fa fa-globe" /> Export Translations
        </button>
        {this.state.config.status === CONFIG_STATUS_DRAFT &&
        <React.Fragment>
          <button
            className="btn btn-primary mr-2"
            onClick={this.onLoadPublishedConfigClick}
          >
            <i className="fa fa-repeat" /> Load Existing Published Config
          </button>
          <button
            className="btn btn-primary mr-2"
            onClick={this.onSaveClick}
          >
            <i className="fa fa-save" /> Save to Database
          </button>
        </React.Fragment>}
      </div>
    );
  }

  onEditTranslationDone = (translationValues: ImmutableList<ConfigTranslationModel>, transformerKey: string) => {
    if (!this.state.currentlyEditingView || !this.state.currentlyEditingTranslation) {
      return;
    }

    // update the edited translation object with the new values
    let editedTranslation = this.state.currentlyEditingTranslation;
    let editedTranslationDimension = editedTranslation.get('columnName', '');

    editedTranslation = editedTranslation
      .set('transformerKey', transformerKey)
      .set('values', translationValues);

    let viewTranslation: ConfigViewTranslationRecord = this.translations.get(
      this.state.currentlyEditingView.tableName,
      new ConfigViewTranslationsRecordFactory({viewName: this.state.currentlyEditingView.tableName}));

    let dimensionTranslations = viewTranslation.get(
      'dimensionTranslations',
      new Map<string, ConfigColumnTranslationRecord>());

    dimensionTranslations = dimensionTranslations.set(editedTranslationDimension, editedTranslation);
    viewTranslation = viewTranslation.set('dimensionTranslations', dimensionTranslations);
    this.translations = this.translations.set(this.state.currentlyEditingView.tableName, viewTranslation);
  }

  onEditMeasureDone = (configMeasure: ConfigMeasure) => {
    if (!this.state.currentlyEditingView || !this.state.currentlyEditingMeasure) {
      return;
    }

    // look for the view which the modified column belongs to
    let modifiedViewIndex = findIndex(
      this.state.selectedConfigViews,
      {tableName: this.state.currentlyEditingView.tableName});

    let selectedConfigViews: ConfigView[] = this.state.selectedConfigViews.slice();

    if (modifiedViewIndex !== -1) {
      // look for the column which has been edited
      let modifiedMeasureIndex = findIndex(
        this.state.selectedConfigViews[modifiedViewIndex].measures,
        {sql: configMeasure.sql});

      if ((configMeasure.items.length === 0) && (modifiedMeasureIndex !== -1)) {
        // if no measures have been selected, then remove the configMeasure
        selectedConfigViews[modifiedViewIndex].measures.splice(modifiedMeasureIndex, 1);
      } else if ((configMeasure.items.length > 0) && (modifiedMeasureIndex !== -1)) {
        // update an existing measure
        selectedConfigViews[modifiedViewIndex].measures[modifiedMeasureIndex] = configMeasure;
      } else if (configMeasure.items.length > 0) {
        // add a new measure
        selectedConfigViews[modifiedViewIndex].measures.push(configMeasure);
      }
    }

    this.setState({
      currentlyEditingMeasure: null,
      selectedConfigViews: selectedConfigViews
    });
  }

  /**
   * After column details have been edited via modal
   * @param {ConfigDimension} column
   */
  onEditDimensionDone = (column: ConfigDimension) => {
    if (!this.state.currentlyEditingView || !this.state.currentlyEditingDimension) {
      return;
    }

    // look for the view which the modified column belongs to
    let modifiedViewIndex = findIndex(
      this.state.selectedConfigViews,
      {tableName: this.state.currentlyEditingView.tableName});

    if (modifiedViewIndex !== -1) {
      // look for the column which has been edited
      let modifiedColumnIndex = findIndex(
        this.state.selectedConfigViews[modifiedViewIndex].dimensions,
        {name: column.name});

      if (modifiedColumnIndex !== -1) {
        let selectedConfigViews: ConfigView[] = this.state.selectedConfigViews.slice();

        // update the column data
        selectedConfigViews[modifiedViewIndex].dimensions[modifiedColumnIndex] = column;

        this.setState({
          selectedConfigViews: selectedConfigViews
        });
      }
    }

    this.setState({
      currentlyEditingDimension: null
    });
  }

  /**
   * When the Export Views button has been clicked
   */
  onExportViewsClick = () => {
    if (!this.state.selectedConfigViews) {
      return;
    }

    // convert the selected views data into a PHP array format
    let data = formatViewsPhpArray(this.state.selectedConfigViews);

    // send it to the browser
    downloadData(data, 'application/octet-stream');
  }

  clearViewsClick = () => {
    let selected: ConfigView[] = this.state.selectedConfigViews;
    let available: ConfigView[] = this.state.availableConfigViews;
    while (selected.length > 0) {
      let viewToRemove: ConfigView = selected[0];
      this.removeView(viewToRemove, selected, available);
    }

    this.setState({
      selectedConfigViews: selected,
      availableConfigViews: available
    });
  }

  /**
   * When a view has been removed
   * @param {ConfigView} view
   * @returns {() => void}
   */
  onRemoveView = (view: ConfigView) => () => {
    this.removeView(view, this.state.selectedConfigViews, this.state.availableConfigViews);

    // re-render
    this.setState({
      selectedConfigViews: this.state.selectedConfigViews,
      availableConfigViews: this.state.availableConfigViews
    });
  }

  removeView = (view: ConfigView, selectedViews: ConfigView[], availableViews: ConfigView[]) => {
    let selectedViewIndex = findIndex(selectedViews, {tableName: view.tableName});

    if (selectedViewIndex !== -1 && selectedViews) {
      // remove from the list of selectable columns
      selectedViews.splice(selectedViewIndex, 1);
    }

    // Add the dimensions / measures of the removed view to the available view.
    let availableView = find(availableViews, {tableName: view.tableName});

    if (availableView) {
      availableView.dimensions = availableView.dimensions.concat(view.dimensions);
      availableView.measures = availableView.measures.concat(view.measures);
    }
  }

  renderViews(views: ConfigView[]) {
    return map(views, (v: ConfigView, key: number) => {
      return (
        <div className="mb-2" key={key}>
          <CollapseCard
            title={v.tableName}
          >
            <div>
              <button
                className="btn btn-sm btn-primary mb-2"
                onClick={this.onRemoveView(v)}
              >
                Remove View
              </button>
            </div>
            <div>
              <h4>Selected Dimensions:</h4>
            </div>
            <div className="row">
              {this.renderSelectedDimensions(v.dimensions, v)}
            </div>
            <div>
              <h4>Available Dimensions:</h4>
            </div>
            <div className="row">
              {this.renderAvailableDimensions(v)}
            </div>
            <div>
              <h4>Selected Measures:</h4>
              <button
                className="btn btn-sm btn-primary mb-2"
                onClick={this.showEditMeasuresDetailsModal(null, v)}
              >
                Add Measure
              </button>
            </div>
            <div className="row">
              {this.renderSelectedMeasures(v.measures, v)}
            </div>
          </CollapseCard>
        </div>
      );
    });
  }

  /**
   * When a column has been added
   *
   * @param {string} tableName
   * @param {number} availableViewIndex
   * @param {ConfigColumn} column
   * @returns {() => void}
   */
  addDimension = (tableName: string, availableViewIndex: number, column: ConfigDimension) => () => {

    let availableView = find(this.state.availableConfigViews, {tableName: tableName});

    if (availableView) {
      // remove it from the list of available columns
      availableView.dimensions.splice(availableViewIndex, 1);
    }

    // add it to the list of selected columns
    let selectedView = find(this.state.selectedConfigViews, {tableName: tableName});

    if (selectedView) {
      selectedView.dimensions.push(column);
    }

    // re-render
    this.setState({
      selectedConfigViews: this.state.selectedConfigViews
    });
  }

  /**
   * When a column has been removed
   *
   * @param {string} tableName
   * @param {number} selectedViewIndex
   * @param {ConfigDimension} column
   * @returns {() => void}
   */
  removeColumn = (tableName: string,
                  selectedViewIndex: number,
                  column: ConfigDimension) => () => {

    // find the view to remove the column
    let selectedView = find(this.state.selectedConfigViews, {tableName: tableName});

    if (selectedView) {
      // remove from the list of selectable columns
      selectedView.dimensions.splice(selectedViewIndex, 1);
    }

    // add to the list of available columns
    let availableView = find(this.state.availableConfigViews, {tableName: tableName});

    if (availableView) {
      availableView.dimensions.push(column);
    }

    // re-render
    this.setState({
      selectedConfigViews: this.state.selectedConfigViews
    });
  }

  /**
   * Render the columns for a specific view
   *
   * @param {ConfigDimension[]} columns
   * @param {ConfigView} view
   * @returns {any[]}
   */
  renderSelectedDimensions(columns: ConfigDimension[], view: ConfigView) {
    return map(columns, (column: ConfigDimension, key: number) => {
      return (
        <div
          key={key}
          className="col-md-3"
        >
          {column.label}
          <span className="pl-2" onClick={this.removeColumn(view.tableName, key, column)}>
              <i className="fa fa-times" />
            </span>
            <span className="pl-2" onClick={this.showEditDimensionsDetailsModal(column, view)}>
              <i className="fa fa-pencil" />
            </span>
          <span className="pl-2" onClick={this.showTranslationsModal(column.name, view)}>
              <i className="fa fa-globe" />
            </span>
        </div>
      );
    });
  }

  formatMeasureSqlForDisplay(sql: string | undefined) {
    if (!sql) {
      return;
    }

    // remove %alias%. and `
    return sql.replace(/(%alias%\.)|`/gi, '');
  }

  renderSelectedMeasures(columns: ConfigMeasure[], view: ConfigView) {
    return map(columns, (column: ConfigMeasure, key: number) => {
      return (
        <div
          key={key}
          className="col-md-3"
        >
          {column.sql === MEASURE_ON_TABLE ? view.tableName + ' (table)' : this.formatMeasureSqlForDisplay(column.sql)}
          <span className="pl-2" onClick={this.showEditMeasuresDetailsModal(column, view)}>
              <i className="fa fa-pencil" />
            </span>
        </div>
      );
    });
  }

  /**
   * When the column is clicked on, show the column edit modal
   *
   * @param {ConfigColumn} column
   * @param {ConfigView} view
   * @returns {() => void}
   */
  showEditDimensionsDetailsModal = (column: ConfigDimension, view: ConfigView) => () => {
    this.setState({
      currentlyEditingDimension: column,
      currentlyEditingView: view
    });

    this.editDimensionModalRef.current.toggle();
  }

  /**
   * When the column is clicked on, show the column edit modal
   *
   * @param {ConfigColumn} column
   * @param {ConfigView} view
   * @returns {() => void}
   */
  showEditMeasuresDetailsModal = (column: ConfigMeasure | null, view: ConfigView) => () => {
    // get the array of dimensions sql to display in the dropdown
    let dimensionsOptions: string[] = [];
    if (column && column.sql) {
      dimensionsOptions = [column.sql];
    } else {
      let options: string[] = this.sqlOptionsForAddEditMeasure.get(view.tableName) ?
        this.sqlOptionsForAddEditMeasure.get(view.tableName) as string[] : [];

      // remove the already chosen measures from the view
      dimensionsOptions = differenceWith(options, map(view.measures, 'sql'), (o1, o2) => {
        if (!o1 || !o2) {
          return false;
        }

        return o1 === o2;
      });
    }

    // find the available dimensions SQL for this view
    this.setState({
      currentlyEditingMeasure: column ? column : {items: []},
      currentlyEditingView: view,
      editMeasureModalSqlOptions: dimensionsOptions ? dimensionsOptions : []
    });

    this.editMeasureModalRef.current.toggle();
  }

  /**
   * Render the array of available columns
   *
   * @param {ConfigView} view
   * @param {COLUMN_ARRAY_KEY} columnType
   * @returns {any}
   */
  renderAvailableDimensions(view: ConfigView) {
    // look for the view in the availableViews
    let availableView = find(this.state.availableConfigViews, {tableName: view.tableName});

    if (!availableView) {
      return null;
    }

    return map(availableView.dimensions, (c: ConfigDimension, key: number) => {
      return (
        <div
          key={key}
          className="col-md-3"
        >
          <span
            className="badge badge-pill badge-primary"
            onClick={this.addDimension(view.tableName, key, c)}
          >
            {c.label}
          </span>
        </div>
      );
    });
  }

  showTranslationsModal = (columnName: string, view: ConfigView) => () => {
    let viewTranslation = this.translations.get(view.tableName);
    let dimensionTranslations: ConfigColumnTranslationRecord = new ConfigColumnTranslationRecordFactory({
      columnName: columnName
    });

    if (viewTranslation) {
      let dimensionTranslationsOnView = viewTranslation.get(
        'dimensionTranslations',
        ImmutableMap<string, ConfigColumnTranslationRecord>());

      dimensionTranslations = dimensionTranslationsOnView.get(
        columnName,
        new ConfigColumnTranslationRecordFactory({
          columnName: columnName
        }));
    }

    this.setState({
      currentlyEditingView: view,
      currentlyEditingTranslation: dimensionTranslations
    });

    this.editTranslationsModalRef.current.toggle();
  }

  onExportTranslationsClick = () => {
    let translations = formatTranslationsPhpArray(this.translations);
    // send it to the browser
    downloadData(translations, 'application/octet-stream');
  }
}