import * as React from 'react';
import { BasicModal, withModal } from '../../../shared-components/BasicModal';
import { ConfigColumnTranslationRecord, ConfigTranslationModel } from '../../../types/config/configTranslationsModel';
import { map } from 'lodash';
import { List } from 'immutable';
import { Transformer } from '../../../types/transformerModel';

interface EditTranslationModalProps {
  toggle: () => void;
  isOpen: boolean;
  translation: ConfigColumnTranslationRecord | null;
  transformers: Transformer[];
  onDone: (translations: List<ConfigTranslationModel>, transformerKey: string) => void;
}

interface EditTranslationModalState {
  transformerKey: string;
  translations: ConfigTranslationModel[];
}

class EditTranslationModalComponent extends React.Component<EditTranslationModalProps, EditTranslationModalState> {

  constructor(props: EditTranslationModalProps) {
    super(props);

    this.state = {
      transformerKey: '',
      translations: []
    };
  }

  componentDidUpdate(prevProps: EditTranslationModalProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.populateFormData();
    }
  }

  populateFormData() {
    let currTransformerKey = this.props.translation ?
      this.props.translation.get('transformerKey', '') : '';

    let currTranslations = this.props.translation ?
      this.props.translation.get('values', []).toArray() : [];

    this.setState({
      transformerKey: currTransformerKey,
      translations: currTranslations
    });
  }

  public toggle = () => {
    this.props.toggle();
  }

  onDone = () => {
    this.props.onDone(List<ConfigTranslationModel>(this.state.translations), this.state.transformerKey);
    this.toggle();
  }

  onTransformerSelect = (event: React.FormEvent<HTMLSelectElement>) => {
    let value = event.currentTarget.value;
    this.setState({
      transformerKey: value
    });
  }

  renderModalContent() {
    if (!this.props.translation) {
      return null;
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <h4>Translations</h4>
            <button
              className="btn btn-default mb-2"
              onClick={this.addTranslation}
            >
              Add Translation
            </button>
          </div>
        </div>
        {this.renderTranslations(this.state.translations)}
        <hr />
        <div className="row">
          <div className="col">
            <h4>Transformer</h4>
          </div>
        </div>
        {this.renderTransformers(this.state.transformerKey)}
      </div>);
  }

  renderTransformers(transformerKey: string) {
    return (
      <select
        className="form-control form-control-sm"
        onChange={this.onTransformerSelect}
        value={transformerKey}
      >
        <option
          value=""
        >
          None
        </option>
        {this.props.transformers.map((transformer: Transformer, key: number) => {
          return (
            <option
              value={transformer.name}
              key={key}
            >
              {transformer.label}
            </option>
          );
        })}
      </select>
    );
  }

  onTranslationChange = (index: number, prop: string) => (event: React.FormEvent<HTMLInputElement>) => {
    if (prop !== 'value' && prop !== 'translated') {
      return;
    }

    let val = event.currentTarget.value;
    let translations = this.state.translations.slice();
    translations[index][prop] = val;

    this.setState({
      translations: translations
    });
  }

  renderTranslations(translations: ConfigTranslationModel[]) {
    return map(translations, (translationObject: ConfigTranslationModel, index: number) => {
      let translationFrom = translationObject.value;
      let translationTo = translationObject.translated;
      return (
        <div
          className="row mb-2"
          key={index}
        >
          <div className="col">
            <input
              type="text"
              value={translationFrom}
              className="form-control form-control-sm"
              onChange={this.onTranslationChange(index, 'value')}
              placeholder="Translate from..."
            />
          </div>
          <div className="col">
            <input
              type="text"
              value={translationTo}
              className="form-control form-control-sm"
              onChange={this.onTranslationChange(index, 'translated')}
              placeholder="Translate to..."
            />
          </div>
          <div className="col">
            <button
              className="btn btn-default"
              onClick={this.removeTranslation(index)}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        </div>
      );
    });
  }

  render() {
    if (!this.props.translation) {
      return null;
    }

    let title: string = 'Edit Translation for: ' + this.props.translation.get('columnName', '');

    return (
      <div className="config-edit-translation">
        <BasicModal
          title={title}
          className="config-edit-translation-modal"
          confirmButtonText="OK"
          onConfirmClicked={this.onDone}
          toggle={this.props.toggle}
          isOpen={this.props.isOpen}
          size="lg"
        >
          {this.renderModalContent()}
        </BasicModal>
      </div>
    );
  }

  addTranslation = () => {
    let translations = this.state.translations.slice();
    translations.push({
      value: '',
      translated: ''
    });

    this.setState({
      translations: translations
    });
  }

  removeTranslation = (index: number) => () => {
    let translations = this.state.translations.slice();
    translations.splice(index, 1);

    this.setState({
      translations: translations
    });
  }
}

const EditTranslationModal = withModal(EditTranslationModalComponent);
export default EditTranslationModal;