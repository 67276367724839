import * as React from 'react';
import { connect } from 'react-redux';
import { EditConfigViewsPage as EditConfigViewsPageComponent } from '../../components/Config/EditConfigViewsPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NotificationService } from '../../services/notificationService';
import { AppState } from '../../redux';

export interface EditConfigViewsPageProps extends RouteComponentProps<any> {
  notificationService: NotificationService;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService
});

class EditConfigViewsPage extends React.Component<EditConfigViewsPageProps> {
  constructor(props: EditConfigViewsPageProps) {
    super(props);
  }

  render() {
    return (
      <EditConfigViewsPageComponent
        configId={this.props.match.params.configId}
        notificationService={this.props.notificationService}
      />
    );
  }
}

const EditConfigViewsPageContainer = withRouter(connect(
  mapStateToProps)(EditConfigViewsPage));

export default EditConfigViewsPageContainer;
