import axios, { AxiosResponse } from 'axios';
import { keys, map, differenceWith, forEach, find, isNil } from 'lodash';
import { Record, List, Map } from 'immutable';
import { jsObjectToPhpArray } from 'js-object-to-php-array';
import { ConfigData } from '../types/config/configDataModel';
import { ConfigDimension } from '../types/config/configDimensionModel';
import { ConfigMeasure, ConfigMeasureItem } from '../types/config/configMeasureModel';
import { ConfigView } from '../types/config/configViewModel';
import {
  ConfigFilter,
  ConfigFilterRecord, ConfigRelation, ConfigRelationRecord,
  ConfigRule
} from '../types/config/configRelationModel';
import { ConfigJoin, ConfigJoinRecord } from '../types/config/configJoinModel';
import { ConfigTranslationsHydrator, ConfigViewTranslationRecord } from '../types/config/configTranslationsModel';
import { Map as ImmutableMap } from 'immutable';
import { formatTranslationsJson } from '../services/configurationExportService';
import { Transformer } from '../types/transformerModel';
import { bytesToBase64 } from 'src/features/utils';

export interface GenerateData {
  selectedTables: string[];
}
export interface ConnectionDetails {
  dbname: string;
  user: string;
  password: string;
  host: string;
  port: number;
}

export type ConfigColumn = ConfigDimension | ConfigMeasure;

export const MEASURE_ON_TABLE = 'table';

export function isConfigDimension(c: ConfigColumn): c is ConfigDimension {
  return c['groupable'] !== undefined;
}

export function isConfigMeasure(c: ConfigColumn): c is ConfigMeasure {
  return c['groupable'] === undefined;
}

export async function apiGetTables(connectionDetails: ConnectionDetails) {
  let encodedConnectionDetails = bytesToBase64(new TextEncoder().encode(JSON.stringify(connectionDetails)));
  let url = '/api/tables?connection=' + encodedConnectionDetails;
  let response: AxiosResponse = await axios.get(url);

  return response.data;
}

export async function apiGenerateConfig(connectionDetails: ConnectionDetails, generateData: GenerateData) {
  let data = {
    connectionDetails: connectionDetails,
    selectedTables: generateData.selectedTables
  };

  let response: AxiosResponse = await axios.post('/api/configuration', data);
  return response.data;
}

export function downloadData(data: any, mimetype: string) {
  let blobData = new Blob([data], {type: 'application/octet-stream'});

  window.location.href = window.URL.createObjectURL(blobData);
}

export function getSelectedTablesFromExistingConfig(existingViewConfig: any): string[] {
  return keys(existingViewConfig);
}

export async function apiGetConfigs(): Promise<ConfigData[]> {
  let response: AxiosResponse = await axios.get('/api/configurations');
  return map(
    response.data,
    (d: any) => {
      return hydrateConfigData(d);
    }
  );
}

export async function apiGetPublishedConfig(moduleName: string, clientName: string): Promise<ConfigData> {
  let search: any = {
    moduleName: moduleName,
    clientName: clientName
  };

  const response: AxiosResponse = await axios.get(
    `/api/configurations?search=${bytesToBase64(new TextEncoder().encode(JSON.stringify(search)))}`
  );
  return hydrateConfigData(response.data);
}

export async function apiGenerateViews(configId: string): Promise<ConfigView[]> {
  let response: AxiosResponse = await axios.get('/api/configurations/' + configId + '/views/generate');

  return hydrateConfigViews(response.data);
}

export async function apiGenerateRelations(configId: string): Promise<any> {
  let response: AxiosResponse = await axios.get('/api/configurations/' + configId + '/relations/generate');
  return hydrateConfigRelations(response.data);
}

export async function apiGetExistingViews(configId: string):
  Promise<{views: ConfigView[], config: ConfigData, translations: Map<string, ConfigViewTranslationRecord>}> {

  let response: AxiosResponse = await apiGetExistingConfig(configId);
  return {
    views: hydrateConfigViews(response.data.views),
    config: hydrateConfigData(response.data.config),
    translations: ConfigTranslationsHydrator.hydrateTranslationsMap(response.data.translations)
  };
}

export async function apiGetExistingRelations(configId: string):
  Promise<{config: ConfigData, relations: List<Record<ConfigRelation>>}> {

  let response: AxiosResponse = await apiGetExistingConfig(configId);
  return {
    relations: hydrateConfigRelations(response.data.relations),
    config: hydrateConfigData(response.data.config)
  };
}

export async function apiGetExistingConfig(configId: string): Promise<AxiosResponse> {
  return axios.get('/api/configurations/' + configId);
}

export function hydrateConfigViews(data: any): ConfigView[] {
  return map(data, (configViewData: any) => {
    return hydrateConfigView(configViewData);
  });
}

function hydrateConfigView(data: any): ConfigView {
  return {
    tableName: data['tableName'],
    dimensions: hydrateConfigDimensions(data['dimension']),
    measures: hydrateConfigMeasures(data['measure'])
  };
}

function hydrateConfigDimensions(dimensions: any): ConfigDimension[] {
  return map(dimensions, (data: any, name: string) => {
    return {
      sql: data['sql'],
      type: data['type'],
      label: data['label'],
      groupable: data['groupable'],
      name: name,
      visible: isNil(data['visible']) ? true : data['visible']
    };
  });
}

function hydrateConfigMeasures(measures: any): ConfigMeasure[] {
  let result = {};

  // keyed by the sql and the ConfigMeasureItems data, same as the backend configuration file
  forEach(measures, (data: any, name: string) => {
    // if the measure does not have an "sql" (e.g. %alias%.id) then it is defined for the whole view
    // e.g. Count is not specifically for a dimension
    let measureSql: string = data['sql'] ? data['sql'] : MEASURE_ON_TABLE;
    if (!result[measureSql]) {
      result[measureSql] = [];
    }

    result[measureSql].push({
      type: data['type'],
      label: data['label'],
      drill_fields: data['drill_fields'],
      name: name,
      selected: true,
      drill_filter: data['drill_filter'],
      visible: isNil(data['visible']) ? true : data['visible']
    });
  });

  // return an object which contains the dimension's sql (e.g. "%alias%.cost"),
  // and the types of measures (e.g. sum, avg, etc)
  let r = map(result, (data: Array<ConfigMeasureItem>, sql: string) => {
    return {
      sql: sql,
      items: data
    };
  });

  return r;
}

export function getUnselectedConfigOptions(selected: ConfigView[], all: ConfigView[]): ConfigView[] {
  let unselected: ConfigView[] = [];

  forEach(all, (allView: ConfigView) => {
    // look for the selected view with the same name
    let selectedView = find(selected, {tableName: allView.tableName});

    if (!selectedView) {
      unselected.push(allView);
      return;
    }

    // get the dimensions which have not been selected
    let unselectedDimensions = differenceWith(
      allView.dimensions,
      selectedView.dimensions,
      (d1: ConfigDimension, d2: ConfigDimension) => {
        return d1.sql === d2.sql;
      }
    );

    // get the measures which have not been selected
    let unselectedMeasures = differenceWith(
      allView.measures,
      selectedView.measures,
      (d1: ConfigMeasure, d2: ConfigMeasure) => {
        return d1.sql === d2.sql;
      }
    );

    unselected.push({
      tableName: allView.tableName,
      dimensions: unselectedDimensions,
      measures: unselectedMeasures
    });
  });

  return unselected;
}

/**
 * Convert views data to php array format as this is what is used
 * @param {ConfigView[]} data
 * @returns {any}
 */
export function formatViewsPhpArray(data: ConfigView[]) {
  let result = formatViewsJson(data);
  return '<?php \nreturn ' + jsObjectToPhpArray(result) + ';';
}

export function formatViewsJson(data: ConfigView[]) {
  let result: any = {};
  forEach(data, (view: ConfigView) => {
    let dimensions: any = formatDimensionsDataForExport(view.dimensions);
    let measures: any = formatMeasuresDataForExport(view.measures);
    result[view.tableName] = {
      dimension: dimensions,
      measure: measures
    };
  });

  return result;
}

function hydrateConfigRules(rules: any[]): ConfigRule {
  if (!rules) {
    return {
      filters: List<Record<ConfigFilter>>()
    };
  }

  let filters = List(map(rules['filters'], (filter: any) => {
    return new ConfigFilterRecord({
      dimensionName: filter['dimensionName'],
      value: filter['value'],
      type: filter['type']
    });
  }));

  return {
    filters: filters
  };
}

function hydrateConfigRelations(data: any): List<Record<ConfigRelation>> {
  return List(map(data, (relation: any) => {
    return new ConfigRelationRecord({
      base: relation['base'],
      label: relation['label'],
      tableName: relation['tableName'],
      joins: hydrateConfigJoins(relation['joins']),
      derivedTable: relation['derived_table'],
      rules: hydrateConfigRules(relation['rules'])
    });
  }));
}

function hydrateConfigJoins(data: any[]): List<Record<ConfigJoin>> {
  return List(map(data, (join: any) => {
    return new ConfigJoinRecord({
      inverse: join['inverse'],
      label: join['label'],
      name: join['name'],
      sqlOn: join['sql_on'],
      type: join['type'],
      view: join['view'],
      parents: join['parents']
    });
  }));
}

/**
 * Format the dimensions data for exporting to php array format
 * @param {ConfigDimension[]} dimensions
 * @returns {any}
 */
function formatDimensionsDataForExport(dimensions: ConfigDimension[]) {
  let result: any = {};
  forEach(dimensions, (dimension: ConfigDimension) => {
    result[dimension.name] = {
      sql: dimension.sql,
      type: dimension.type,
      label: dimension.label,
      groupable: dimension.groupable ? dimension.groupable : false,
      visible: dimension.visible
    };
  });

  return result;
}

/**
 * Format the measures data for exporting to php array format
 * @param {ConfigMeasure[]} measures
 * @returns {any}
 */
function formatMeasuresDataForExport(measures: ConfigMeasure[]) {
  let result: any = {};
  forEach(measures, (measure: ConfigMeasure) => {
    forEach(measure.items, (measureItem: ConfigMeasureItem) => {
      let r = {
        type: measureItem.type,
        label: measureItem.label,
        drill_fields: measureItem.drill_fields ? measureItem.drill_fields : [],
        visible: measureItem.visible
      };

      if (measureItem.drill_filter) {
        r['drill_filter'] = measureItem.drill_filter;
      }

      // measures on a "table" level don't specify the "sql" property as the result doesn't change regardless
      // of what dimension it is on.
      if (measure.sql && measure.sql !== MEASURE_ON_TABLE) {
        r['sql'] = measure.sql;
      }

      result[measureItem.name] = r;
    });
  });

  return result;
}

/**
 * Create a new config
 * @param {{moduleId: string; client: string}} data
 * @returns {Promise<any>}
 */
export async function apiCreateNewConfig(data: {moduleId: string, client: string}): Promise<any> {
  let response: AxiosResponse = await axios.post('/api/configurations', data);
  return response.data;
}

export function formatRelationsJson(relations: List<Record<ConfigRelation>>) {
  let result: any = {};

  relations.forEach((relation: Record<ConfigRelation>, key: number) => {
    let formattedRelation = {
      label: relation.get('label', ''),
      base: relation.get('base', ''),
      joins: formatJoinsForExport(relation.get('joins', [])),
    };

    let derived = relation.get('derivedTable', '');
    if (derived && derived.sql) {
      formattedRelation['derived_table'] = {
        sql: derived.sql
      };
    }

    let rule = formatRulesForExport(relation.get('rules', null));
    if (rule) {
      formattedRelation['rules'] = rule;
    }

    result[relation.get('tableName', key)] = formattedRelation;
  });

  return {
    views: result
  };
}

function formatRulesForExport(rule: ConfigRule | null) {
  if (!rule) {
    return null;
  }

  let filters = rule.filters;

  if (filters.size === 0) {
    return null;
  }

  let formattedFilters: any = [];

  filters.forEach((filter: Record<ConfigFilter>, key: number) => {
    let dimensionName: string = filter.get('dimensionName', '');
    let type: string = filter.get('type', '');
    let value: any = filter.get('value', '');
    formattedFilters.push({
      dimensionName: dimensionName,
      type: type,
      value: value
    });
  });

  return {
    filters: formattedFilters
  };
}

export function formatRelationsForExport(relations: List<Record<ConfigRelation>>) {
  let result = formatRelationsJson(relations);
  return '<?php\n return ' + jsObjectToPhpArray(result) + ';';
}

function formatJoinsForExport(joins: List<Record<ConfigJoin>>) {
  let result: any[] = [];

  // use a forEach as we don't need an immutable List for this.
  joins.forEach((join: Record<ConfigJoin>, key: number) => {
    let formattedJoin = {
      label: join.get('label', ''),
      name: join.get('name', ''),
      sql_on: join.get('sqlOn', ''),
      type: join.get('type', ''),
      view: join.get('view', '')
    };

    let inverse = join.get('inverse', '');
    if (inverse) {
      formattedJoin['inverse'] = inverse;
    }

    let parents = join.get('parents', '');
    if (parents) {
      formattedJoin['parents'] = parents;
    }

    result.push(formattedJoin);
  });

  return result;
}

function hydrateConfigData(data: any): ConfigData {
  let configData = new ConfigData();

  Object.assign(configData, {
    id: data['id'],
    relations: data['relations'],
    views: data['views'],
    translations: data['translations'],
    module: data['module'],
    clientName: data['clientName'],
    status: data['status'],
    user: data['user'] ? { id: data['user']['id'] } : null
  });

  return configData;
}

/**
 * Save view configuration to the DB
 *
 * @param {string} configId
 * @param {ConfigView[]} views
 * @returns {Promise<any>}
 */
export async function apiSaveView(configId: string,
                                  views: ConfigView[],
                                  translations: ImmutableMap<string, ConfigViewTranslationRecord>): Promise<any> {
  let data = {
    views: JSON.stringify(formatViewsJson(views)),
    translations: JSON.stringify(formatTranslationsJson(translations))
  };

  let response: AxiosResponse = await axios.post('/api/configurations/' + configId, data);
  return response.data;
}

/**
 * Save relations configuration to the DB
 *
 * @param {string} configId
 * @param {List<Record<ConfigRelation>>} relations
 * @returns {Promise<any>}
 */
export async function apiSaveRelations(configId: string, relations: List<Record<ConfigRelation>>): Promise<any> {
  let data = {
    relations: JSON.stringify(formatRelationsJson(relations))
  };

  let response: AxiosResponse = await axios.post('/api/configurations/' + configId, data);
  return response.data;
}

export async function apiGetTransformers(): Promise<Transformer[]> {
  let response: AxiosResponse = await axios.get('/api/transformers');
  return response.data;
}