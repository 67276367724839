import { ValidationError } from 'yup';

export function getValidationMessagesByPath(errors: ValidationError) {
  const result = {};
  errors.inner.forEach((error: ValidationError) => {
    result[error.path] = error.message;
  });

  return result;
}
