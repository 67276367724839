export const OCCURRENCE_ONCE = 'once';
export type OCCURRENCE_ONCE = typeof OCCURRENCE_ONCE;
export const OCCURRENCE_DAILY = 'daily';
export type OCCURRENCE_DAILY = typeof OCCURRENCE_DAILY;
export const OCCURRENCE_DAILY_WEEKDAY = 'daily_weekday';
export type OCCURRENCE_DAILY_WEEKDAY = typeof OCCURRENCE_DAILY_WEEKDAY;
export const OCCURRENCE_WEEKLY = 'weekly';
export type OCCURRENCE_WEEKLY = typeof OCCURRENCE_WEEKLY;
export const OCCURRENCE_FORTNIGHTLY = 'fortnightly';
export type OCCURRENCE_FORTNIGHTLY = typeof OCCURRENCE_FORTNIGHTLY;
export const OCCURRENCE_MONTHLY = 'monthly';
export type OCCURRENCE_MONTHLY = typeof OCCURRENCE_MONTHLY;
export const OCCURRENCE_QUARTERLY = 'quarterly';
export type OCCURRENCE_QUARTERLY = typeof OCCURRENCE_QUARTERLY;
export const SCHEDULE_DISPATCH_TYPE_USERS = 'Users';
export const SCHEDULE_DISPATCH_TYPE_OUTBOUND = 'Outbound';
export const SCHEDULE_NOTIFICATION_TYPE_USERS = 'User';
export const SCHEDULE_NOTIFICATION_TYPE_EMAIL = 'Email';

export const SCHEDULE_VALIDATION_TYPE_USERS = 'targetUserIds';
export const SCHEDULE_VALIDATION_TYPE_OUTBOUND_DESTINATION = 'outboundDestinationId';
export const SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS = 'notificationRecipients';
export const SCHEDULE_VALIDATION_TYPE_NOTIFICATION_RECIPIENTS_EMAIL = 'notificationRecipients[0].email';
export const SCHEDULE_VALIDATION_TYPE_START_DATE = 'startDate';
export const SCHEDULE_VALIDATION_TYPE_END_DATE = 'endDate';
export const SCHEDULE_VALIDATION_TYPE_TIME = 'time';
export const SCHEDULE_VALIDATION_TYPE_NAME = 'name';
export const SCHEDULE_VALIDATION_TYPE_DAYS_OF_THE_WEEK = 'daysOfTheWeek';
export const SCHEDULE_VALIDATION_TYPE_CALENDAR_DAYS = 'calendarDays';

export const CRT_SCHEDULE_CREATED = 'CRT_SCHEDULE_CREATED';
export type CRT_SCHEDULE_CREATED = typeof CRT_SCHEDULE_CREATED;
export const CRT_SCHEDULE_UPDATED = 'CRT_SCHEDULE_UPDATED';
export type CRT_SCHEDULE_UPDATED = typeof CRT_SCHEDULE_UPDATED;
export const CRT_SCHEDULE_DELETED = 'CRT_SCHEDULE_DELETED';
export type CRT_SCHEDULE_DELETED = typeof CRT_SCHEDULE_DELETED;

export const CRT_SCHEDULE_USERS_LABEL = 'Users';
export type CRT_SCHEDULE_USERS_LABEL = typeof CRT_SCHEDULE_USERS_LABEL;
export const CRT_SCHEDULE_OUTBOUND_LABEL = 'Outbound';
export type CRT_SCHEDULE_OUTBOUND_LABEL = typeof CRT_SCHEDULE_OUTBOUND_LABEL;

export const CRT_SCHEDULE_LOCATION_REPORT_BUILDER = 'Report Builder';
export type CRT_SCHEDULE_LOCATION_REPORT_BUILDER = typeof CRT_SCHEDULE_LOCATION_REPORT_BUILDER;
export const CRT_SCHEDULE_LOCATION_MY_REPORTS = 'My Reports';
export type CRT_SCHEDULE_LOCATION_MY_REPORTS = typeof CRT_SCHEDULE_LOCATION_MY_REPORTS;
export const CRT_SCHEDULE_LOCATION_SCHEDULE_LIST = 'Schedule List';
export type CRT_SCHEDULE_LOCATION_SCHEDULE_LIST = typeof CRT_SCHEDULE_LOCATION_SCHEDULE_LIST;

export type OccurrenceOption =
  OCCURRENCE_ONCE |
  OCCURRENCE_DAILY |
  OCCURRENCE_DAILY_WEEKDAY |
  OCCURRENCE_WEEKLY |
  OCCURRENCE_FORTNIGHTLY |
  OCCURRENCE_MONTHLY |
  OCCURRENCE_QUARTERLY;
