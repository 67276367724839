import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { loadSuggestions, storeSuggestions } from '../actions/suggestions';
import { Join } from '../types/joinModel';
import { map, isEqual } from 'lodash';
import { AppState } from '../redux';
import { SavedQuery } from '../types/savedQueryModel';
import memoize from 'memoizee';
import { AuthService } from '../services/authService';
import { CRT_ADMIN_KEY, CRT_CAPABILITIES_KEY } from '../constants/permissions';
import { bytesToBase64 } from 'src/features/utils';

export class SuggestedReportService {
  protected static instance: SuggestedReportService | null = null;
  public memoizeApiGetSuggestions: (tagString: string) => Promise<SavedQuery[]>;

  /**
   * Get an instance of the SuggestedReportService.
   * @returns {SuggestedReportService}
   */
  public static getInstance(): SuggestedReportService {
    if (!SuggestedReportService.instance) {
      SuggestedReportService.instance = new SuggestedReportService();
    }

    return SuggestedReportService.instance;
  }

  protected constructor() {
    this.memoizeApiGetSuggestions = memoize(this.apiGetSuggestions, {promise: true, maxAge: 60 * 60 * 1000});
  }

  /**
   * Sends request to get suggestions
   * @param {string} tagString
   * @returns {Promise<SavedQuery[]>}
   */
  protected async apiGetSuggestions(tagString: string): Promise<SavedQuery[]> {
    let response: AxiosResponse = await axios.get('/api/queries/suggested?search=' + tagString);
    return map(response.data, (suggestion: any) => {
      return Object.assign(new SavedQuery(), {
        id: suggestion.id,
        name: suggestion.name,
        description: suggestion.description,
        query: suggestion.query,
        moduleName: suggestion.moduleName,
        userId: suggestion.userId,
        created: suggestion.created.date,
        modified: suggestion.modified.date,
        reportType: suggestion.reportType,
        errorMessage: suggestion.errorMessage,
        permissions: suggestion.permissions,
      });
    });
  }

  /**
   * Get suggestions when a join is clicked
   * @param {Join} join
   * @returns {(dispatch: Dispatch<any>, getState: () => AppState) => void}
   */
  getSuggestionsJoin(join: Join):
  (dispatch: Dispatch<any>, getState: () => AppState) => void {
    return async (dispatch, getState) => {
      let appState: AppState = getState();

      let tags: string[] = [join.viewName];
      let parentViewNames = map(join.parents, 'viewName');
      tags = tags.concat(parentViewNames);
      tags.push(appState.module.name);
      tags.push(appState.view.name);

      if (this.isEqualToTagsInStore(tags, appState)) {
        return;
      }

      let tagLabels: string[] = [appState.module.label, appState.view.label];
      let parentJoinLabels = map(join.parents, 'label');
      tagLabels = tagLabels.concat(parentJoinLabels);
      tagLabels.push(join.label);
      dispatch(this.getSuggestions(tags, tagLabels));
    };
  }

  /**
   * Get suggestions when a module is clicked
   * @returns {(dispatch: Dispatch<any>, getState: () => AppState) => void}
   */
  getSuggestionsModule():
  (dispatch: Dispatch<any>, getState: () => AppState) => void {
    return async (dispatch, getState) => {
      let appState: AppState = getState();
      let tags: string[] = [appState.module.name];

      if (this.isEqualToTagsInStore(tags, appState)) {
        return;
      }

      dispatch(this.getSuggestions(tags, [appState.module.label]));
    };
  }

  /**
   * Get suggestions when a base view is clicked
   * @returns {(dispatch: Dispatch<any>, getState: () => AppState) => void}
   */
  getSuggestionsBaseView():
  (dispatch: Dispatch<any>, getState: () => AppState) => void {
    return async (dispatch, getState) => {
      let appState: AppState = getState();

      let tags = [
        appState.module.name,
        appState.view.name
      ];

      if (this.isEqualToTagsInStore(tags, appState)) {
        return;
      }

      dispatch(this.getSuggestions(
        tags,
        [
          appState.module.label,
          appState.view.label
        ]
      ));
    };
  }

  /**
   * Do api request to get suggestions based on the existing tags in the redux store.
   * @returns {(dispatch: Dispatch<any>, getState: () => AppState) => void}
   */
  getSuggestionsForTagsInStore():
  (dispatch: Dispatch<any>, getState: () => AppState) => void {
    return async (dispatch, getState) => {
      let appState: AppState = getState();

      dispatch(this.getSuggestions(
        appState.suggestedReports.tags,
        appState.suggestedReports.tagLabels
      ));
    };
  }

  /**
   * Returns true if the tags array passed in is the same as those in the redux store
   *
   * @param {string[]} newTags
   * @param {AppState} appState
   * @returns {boolean}
   */
  protected isEqualToTagsInStore(newTags: string[], appState: AppState) {
    let currentTags = appState.suggestedReports.tags;
    return (currentTags.length === newTags.length && isEqual(currentTags, newTags));
  }

  /**
   * Get suggestions for tags and labels passed in
   * @param {string[]} tags
   * @param {string[]} tagLabels
   * @param {boolean} doRequest
   * @returns {(dispatch: Dispatch<any>) => void}
   */
  protected getSuggestions(tags: string[], tagLabels: string[], doRequest: boolean = true):
  (dispatch: Dispatch<any>) => void {
    return async (dispatch) => {
      if (tags.length === 0) {
        return;
      }

      // store the tags in the redux store regardless of whether the request is actually done or not
      dispatch(loadSuggestions(tags, tagLabels));
      let result: SavedQuery[] = [];
      try {
        let tagString = bytesToBase64(new TextEncoder().encode(JSON.stringify(tags)));
        result = doRequest ? await this.memoizeApiGetSuggestions(tagString) : [];
      }
      finally {
        dispatch(storeSuggestions(result, tags));
      }
    };
  }

  public hasSuggestionsCapabilities(authService: AuthService) {
    return authService.hasCapability(CRT_CAPABILITIES_KEY, CRT_ADMIN_KEY);
  }
}
