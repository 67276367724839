import { View } from '../types/viewModel';
import {
  DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES, FETCHED_VIEWS, STORE_DATA_TYPE, STORE_MODULE, STORE_MODULES,
  STORE_QUERY
} from '../constants';
import { ViewsAction } from './view';
import { Module } from '../types/moduleModel';

export interface ModuleAction {
  type: STORE_MODULE;
  module: Module;
}

export interface QueryAction {
  type: STORE_QUERY;
  query: string;
}

export interface DataTypeAction {
  type: STORE_DATA_TYPE;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
}

export interface ModulesAction {
  type: STORE_MODULES;
  modules: Module[];
}

export function fetchedViews(views: View[]): ViewsAction {
  return {
    type: FETCHED_VIEWS,
    views: views
  };
}

export function storeModule(module: Module): ModuleAction {
  return {
    type: STORE_MODULE,
    module: module
  };
}

export function storeQuery(query: string): QueryAction {
  return {
    type: STORE_QUERY,
    query: query,
  };
}

export function storeDataType(dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES): DataTypeAction {
  return {
    type: STORE_DATA_TYPE,
    dataType: dataType
  };
}

export function storeModules(modules: Module[]): ModulesAction {
  return {
    type: STORE_MODULES,
    modules: modules
  };
}