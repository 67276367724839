import { useState } from 'react';

const defaultDashboardToDelete = { id: '', isUnsharing: false, name: '' };
const defaultDashboardToEdit = { description: '', id: '', name: '' };
const defaultDashboardToShare = { id: '', name: '' };

export const useDashboardsPage = () => {
  const [dashboardToCopy, setDashboardToCopy] = useState('');
  const [dashboardToDelete, setDashboardToDelete] = useState(defaultDashboardToDelete);
  const [dashboardToEdit, setDashboardToEdit] = useState(defaultDashboardToEdit);
  const [dashboardToShare, setDashboardToShare] = useState(defaultDashboardToShare);

  return {
    dashboardToCopy,
    dashboardToDelete,
    dashboardToEdit,
    dashboardToShare,
    startCopying: setDashboardToCopy,
    startDeleting: setDashboardToDelete,
    startEditing: setDashboardToEdit,
    startSharing: setDashboardToShare,
    stopCopying: () => setDashboardToCopy(''),
    stopDeleting: () => setDashboardToDelete(defaultDashboardToDelete),
    stopEditing: () => setDashboardToEdit(defaultDashboardToEdit),
    stopSharing: () => setDashboardToShare(defaultDashboardToShare),
  };
};
