import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { Button } from '@eds/button';
import { lineHeight } from '@eds/core';
import { AddIcon } from '@eds/icon';
import {
  Table, TableHeaderCell, TableRow,
} from '@eds/table';
import { Text } from '@eds/text';
import * as React from 'react';
import {
  ArrayPath,
  Control, FieldValues, Path, useFieldArray, UseFormWatch, UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import TranslationRule from './TranslationRule';
import { TranslationRuleItem } from '../../../../types/transformationModel';
import * as _ from 'lodash';

type TranslationRulesProps<T extends FieldValues> = {
  control: Control<T>;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  formValues: T;
  fieldOptions: Array<{
    label: string;
    type: string;
    value: string;
  }>;
  errors: Record<string, unknown>;
  watch: UseFormWatch<T>;
  id: Path<T>;
  disabled?: boolean;
};

const TranslationRules = <T extends FieldValues, >({
   control,
   register,
   setValue,
   formValues,
   fieldOptions,
   errors,
   id,
   disabled = false,
   watch,
 }: TranslationRulesProps<T>) => {
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: id as ArrayPath<T>,
    rules: {
      minLength: 1,
      maxLength: 10,
    },
  });

  const watchFieldArray = watch().rules;
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  const noRemove = fields.length <= 1;

  const error = errors[id] as Array<Record<string, { message: string }>> || [];
  const errorItems = _.isArray(error) ? error : [{ rules: error }];
  const errorMessages = errorItems.reduce(
    (result, rule) => {
      if (!rule) {
        return result;
      }

      const messages = Object.keys(rule)
        .reduce((_result, key) => [..._result, `• ${rule[key].message}`], [] as Array<string>);
      return [...result, ...messages];
    },
    [] as Array<string>,
  );

  return (
    <Flex marginBottom="medium" flexDirection="column" overflow="visible" id="no_overflow">
      { errorMessages.length > 0 && errorMessages.map((msg) => (
        <Text
          key={id}
          display="block"
          fontSize="xsmall"
          fontWeight="medium"
          lineHeight="tight"
          minHeight={`${lineHeight.tight}rem`}
          color="critical"
          id={id}
        >
          {msg}
        </Text>
      ))}
      <Table
        tableBorder={true}
        columnSettings={{
          delete: { width: '5rem', horizontalAlign: 'middle' },
        }}
      >
        <TableRow key="header">
          <TableHeaderCell columnName="field">Field</TableHeaderCell>
          <TableHeaderCell columnName="operator">Operator</TableHeaderCell>
          <TableHeaderCell columnName="currentValue">Current Value</TableHeaderCell>
          <TableHeaderCell columnName="futureValue">Future Value</TableHeaderCell>
          {(!noRemove && !disabled) && <TableHeaderCell columnName="delete">Remove</TableHeaderCell>}
        </TableRow>
        {controlledFields.map((field, index) => (
          <TranslationRule
            key={field.id}
            id={`${id}.${index}` as Path<T>}
            fieldOptions={fieldOptions}
            control={control}
            register={register}
            setValue={setValue}
            formValues={formValues}
            errors={(errors[id] as Record<string, unknown> || {})[index] as Record<string, unknown>}
            disabled={disabled}
            noRemove={noRemove}
            index={index}
            data={field}
            remove={() => remove(index)}
          />
        ))}
        {!disabled && (
          <TableRow>
            <Box margin="xxsmall">
              <Button
                size="small"
                label="Add new"
                tone="ghost"
                icon={AddIcon}
                // @ts-expect-error
                onClick={() => append(new TranslationRuleItem())}
              />
            </Box>
          </TableRow>
        )}
      </Table>
    </Flex>
  );
};

export default TranslationRules;
