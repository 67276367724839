import React from 'react';

export const BarChartIllustration = () => (
  <svg
    width={108}
    height={56}
    viewBox="0 0 108 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 40L49 40C50.1046 40 51 40.8954 51 42V46C51 47.1046 50.1046 48 49 48L1 48L1 40Z" fill="#1EB3E2" />
    <path d="M1 24L106 24C107.105 24 108 24.8954 108 26V30C108 31.1046 107.105 32 106 32L1 32L1 24Z" fill="#1EB3E2" />
    <path d="M1 8L79 8C80.1046 8 81 8.89543 81 10V14C81 15.1046 80.1046 16 79 16L1 16L1 8Z" fill="#1EB3E2" />
    <line x1="0.5" y1="2.18557e-08" x2="0.499998" y2="56" stroke="black" strokeOpacity="0.14" />
  </svg>
);
