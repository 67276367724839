import { LOAD_SUGGESTIONS, STORE_SUGGESTIONS, TOGGLE_SUGGESTIONS } from '../constants';
import { SuggestionsAction, SuggestionsModalAction } from '../actions/suggestions';
import { getInitialSuggestedReportsState, SuggestedReportState } from '../redux';
import { isEqual } from 'lodash';
export function suggestedReportsReducer(state: SuggestedReportState = getInitialSuggestedReportsState(),
                                        action: SuggestionsAction | SuggestionsModalAction) {
  switch (action.type) {
    case STORE_SUGGESTIONS: {
      /**
       * If the results that come back are for a tag list that is not the current tag list, reject them.
       * This is to cater for the race condition situation where:
       * 1. User clicks on "Course"
       * 2. User clicks on "Course Sections"
       * 3. Result for "Course" comes back. They should be rejected as the current selection is for "Course Sections"
       * 4. Result for "Course Sections" comes back. Accept these results.
       */
      if ((action.tags.length !== state.tags.length) && (!isEqual(action.tags, state.tags))) {
        return state;
      }

      return Object.assign({}, state, {
        suggestions: action.suggestions,
        loading: false
      });
    }
    case LOAD_SUGGESTIONS: {
      return Object.assign({}, state, {
        suggestions: [],
        loading: true,
        tags: action.tags,
        tagLabels: action.tagLabels
      });
    }

    case TOGGLE_SUGGESTIONS: {
      let nextState = Object.assign({}, state);

      if (action.isOpen !== undefined) {
        nextState.isOpen = action.isOpen;
      } else {
        nextState.isOpen = !state.isOpen;
      }

      return nextState;
    }

    default:
      return state;
  }
}