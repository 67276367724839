import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../redux';
import { NotificationService } from '../services/notificationService';
import { GenerateChart as GenerateChartComponent } from '../components/GenerateChart';
import { Chart as ChartModel, Chart } from '../types/chartModel';
import { ColumnMeta } from '../types/columnMetaModel';
import { storeChart } from '../actions/chart';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES } from '../constants';
import { ReportTable } from '../types/reportTableModel';
import { AuthService } from '../services/authService';

interface GenerateChartButtonProps {
  dispatch: Dispatch<any>;
  notificationService: NotificationService;
  chart: Chart;
  columnMeta: ColumnMeta[];
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  report: ReportTable;
  disabled?: boolean;
  authService: AuthService; // TODO: remove, added this for feature flags
}

interface GenerateChartButtonState {
  showModal: boolean;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  chart: state.chart,
  columnMeta: state.columnMeta,
  dataType: state.dataType,
  report: state.report,
  authService: state.authService, // TODO: remove, added this for feature flags
});

class GenerateChartContainer extends React.Component<GenerateChartButtonProps, GenerateChartButtonState> {
  constructor(props: GenerateChartButtonProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    if (this.props.dataType === DATA_TYPE_DIMENSIONS) {
      return null;
    }
    return (
      <GenerateChartComponent
        setShowModal={this.setShowModal}
        showModal={this.state.showModal}
        columnMeta={this.props.columnMeta}
        dispatchChart={this.dispatchChart}
        chart={this.props.chart}
        dataType={this.props.dataType}
        disabled={this.props.disabled}
      />
    );
  }

  setShowModal = (showModal: boolean) => {
    this.setState({
      showModal: showModal
    });
  }

  dispatchChart = (chart: ChartModel) => {
    this.props.dispatch(storeChart(chart));
  }
}

const GenerateChart = connect(
  mapStateToProps
)(GenerateChartContainer);

export default GenerateChart;
