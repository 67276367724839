import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const ShareIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.22222 6.55556V3L2 9.22222L8.22222 15.4444V11.8C12.6667 11.8 15.7778 13.2222 18 16.3333C17.1111 11.8889
        14.4444 7.44444 8.22222 6.55556Z"
        fill="#212121"
      />
    </svg>
  );
};
