import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
export interface WrappedBasicModalProps {
  title: string | React.ReactNode;
  className?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirmClicked?: () => void;
  disableConfirmButton?: boolean;
  loading?: boolean;
  size?: string;
}

interface WithBasicModalProps {
  isOpen: boolean;
  toggle: () => void;
}

interface WithBasicModalState {
  isOpen: boolean;
}

interface BasicModalProps extends WrappedBasicModalProps {
  isOpen: boolean;
  toggle: () => void;
}

export class BasicModal extends React.Component<BasicModalProps> {
  constructor(props: BasicModalProps) {
    super(props);
  }

  render() {
    let showFooter: boolean = (!!this.props.cancelButtonText || !!this.props.confirmButtonText) && !this.props.loading;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size={this.props.size ? this.props.size : 'md'}
      >
        <ModalHeader toggle={this.props.toggle}>
          {this.props.title}
        </ModalHeader>
        <ModalBody>
          {this.props.children}
        </ModalBody>
        {showFooter &&
          <ModalFooter>
            {this.props.cancelButtonText &&
            <button
              className="btn btn-outline-dark"
              data-testid="modal-cancel"
              onClick={this.props.toggle}
            >
              {this.props.cancelButtonText}
            </button>}
            {this.props.confirmButtonText &&
            <button
              className="btn btn-primary"
              data-testid="modal-confirm"
              onClick={this.props.onConfirmClicked}
              disabled={this.props.disableConfirmButton}
            >
              {this.props.confirmButtonText}
            </button>}
          </ModalFooter>
        }
      </Modal>
    );
  }
}

/**
 * Higher order component to add isOpen state and toggle function to a component
 * @param {React.ComponentType<P extends WithBasicModalProps>} WrappedComponent
 * @returns {React.ComponentClass<any>}
 */
export const withModal = <P extends WithBasicModalProps>(WrappedComponent: React.ComponentType<P>)
  : React.ComponentClass<any> => {
  return class extends React.Component<P, WithBasicModalState> {
    constructor(props: P & WithBasicModalProps) {
      super(props);

      this.state = {
        isOpen: false
      };
    }

    toggle = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    render() {
      return (
        <WrappedComponent
          // @ts-expect-error
          toggle={this.toggle}
          // @ts-expect-error
          isOpen={this.state.isOpen}
          {...this.props}
        />
      );
    }
  };
};
