import React, { FC, useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Layout } from 'elmo-elements';
import { css } from '@emotion/css';
import { matchPath, useLocation } from 'react-router';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { APP_MENU_CURRENT_ITEM_STORAGE_KEY } from 'src/constants';
import { AppState } from 'src/redux';
import { cssOverrides } from 'src/features/ui';
import { DashboardHeader, DashboardHeaderContextProvider, dashboardHeaderOverrides } from 'src/features/dashboards';
import ElmoMenu from 'src/containers/ElmoMenu';
import { errorRoutes } from 'src/routes/error';
import LayoutHeader from 'src/containers/Layout/ElmoLayout/LayoutHeader';
import { LocalStorageService } from 'src/services/localStorageService';
import { ReportingRoutes } from 'src/constants/routes';
import { Route } from 'src/services/routingService';

import './app-layout.scss';

export const AppLayout: FC = ({ children }) => {
  const {
    appMenu: { currentSubmenuItem },
    authService,
    routes,
  } = useSelector((state: AppState) => state);
  const location = useLocation();
  const [isValidAppRoute, setIsValidAppRoute] = useState(true);

  let headerTitle = !!currentSubmenuItem ? currentSubmenuItem.title : '';

  if (!headerTitle) {
    const storedSubmenuItem = LocalStorageService.getItem(APP_MENU_CURRENT_ITEM_STORAGE_KEY);
    headerTitle = !!storedSubmenuItem ? storedSubmenuItem.title : '';
  }

  const { dashboardsList, reportDownloads, reportingTool, root, scheduledReports } = ReportingRoutes;
  const reportBuilderRoutes = [reportingTool, '/view/'];
  const isReportBuilder = reportBuilderRoutes.some(route => location.pathname.includes(route));
  const isNewReportBuilder = !!authService && authService.enableNewReportBuilder();

  const isDashboardsListPage = !!matchPath(location.pathname, { path: dashboardsList, exact: true });

  const overrideContent =
    [reportDownloads, root, scheduledReports].includes(currentSubmenuItem?.url || '') || isDashboardsListPage;

  const isErrorRoute = map(errorRoutes, (route: Route) => route).some(({ exact, path }) =>
    matchPath(location.pathname, { path, exact, strict: false })
  );

  useEffect(() => {
    const hasMatchingRoute = map(routes, (route: Route) => route).some(({ exact, path }) =>
      matchPath(location.pathname, { path, exact, strict: false })
    );

    if (!hasMatchingRoute) setIsValidAppRoute(false);
  }, [location.pathname, routes]);

  return (
    <Layout menu={<ElmoMenu />}>
      <DashboardHeaderContextProvider>
        {isReportBuilder && isNewReportBuilder ? (
          <div className="newBuilderTrick" />
        ) : (
          isValidAppRoute &&
          !isErrorRoute && (
            <LayoutHeader
              isLoading={!headerTitle}
              title={headerTitle}
              className={isDashboardsListPage ? dashboardHeaderOverrides : ''}
            >
              {isDashboardsListPage && <DashboardHeader />}
            </LayoutHeader>
          )
        )}
        {overrideContent && <Box className={css(cssOverrides.content())} />}
        <Layout.Content>
          {isValidAppRoute && !isErrorRoute ? <div className={'container-fluid'}>{children}</div> : children}
        </Layout.Content>
      </DashboardHeaderContextProvider>
    </Layout>
  );
};
