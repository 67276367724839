import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { resetReportAction } from '../actions/reportBuilder';

export interface ResetReportButtonProps {
  dispatch: Dispatch<any>;
  onClick?: () => void;
  className?: string;
}

class ResetReportButton extends React.Component<ResetReportButtonProps> {
  onResetClicked = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    this.props.dispatch(resetReportAction());
  }

  render() {
    return (
      <button
        className={this.props.className}
        onClick={this.onResetClicked}
        id="report-builder-btn-clear-all"
      >
        Clear All
      </button>
    );
  }
}

export default connect(
)(ResetReportButton);