import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { ControlsProps } from '@eds/modal';
import { Divider } from '@eds/divider';
import { Field } from '@eds/field';
import { Fieldset } from '@eds/fieldset';
import { Flex } from '@eds/flex';
import { OptionProps } from '@eds/select-dropdown';
import { Text } from '@eds/text';
import { Toast, toastMessage } from '@eds/toast';
import { useDebounce } from '@eds/utils';

import { ContentModal, MenuItemCard, SelectInput, SelectedItemCard } from 'src/features/ui';
import { DEBOUNCE_TIMEOUT_MILLISECONDS } from 'src/features/common/values';

import { ShareReportFieldValues } from './share-report.validation';
import { ShareReportUserOption, useShareReportMutation, useShareReportUsersQuery } from './share-report.queries';
import { useFormValuesInitializer, useShareReportForm } from './share-report.hooks';

type ShareReportProps = {
  onClose: () => void;
  reportId: string;
  reportName: string;
  sharedWith: string[];
};

const description1 = 'The data displayed in the report will be accessible by all selected users.';
const description2 = `To unshare a report, remove users from the selection and click 'Save'`;

const CustomItem = ({ data }: OptionProps<ShareReportUserOption>) => (
  <MenuItemCard
    avatar={data.avatar}
    avatarName={data.label}
    email={data.email}
    key={`menu-item-card_${data.id}`}
    name={data.label}
    role={data.role}
  />
);

export const ShareReport = ({ onClose, reportId, reportName, sharedWith }: ShareReportProps) => {
  const { control, getValues, handleSubmit, reset, setValue, watch } = useShareReportForm();

  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { mutate, isLoading: loading } = useShareReportMutation(() => {
    onClose();
    setSuccessMessage(toastMessage('Sharing updated'));
  });

  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, DEBOUNCE_TIMEOUT_MILLISECONDS);

  const { data: users = [], isLoading } = useShareReportUsersQuery(!!watch('id'), {
    text: inputValue ? debouncedInputValue || undefined : undefined,
  });

  const { data: selectedUsers = [] } = useShareReportUsersQuery(!!watch('id'), {
    ids: watch('targetUsers').length > 0 ? getValues('targetUsers').map(targetUser => targetUser.value) : undefined,
  });

  const onSubmit = ({ id, targetUsers }: ShareReportFieldValues) =>
    mutate({ id, targetUsers: targetUsers.map(({ value }) => value) });

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: { label: 'Save', loading, onClick: handleSubmit(onSubmit), tone: 'primary' },
  };

  useEffect(() => {
    if (selectedUsers.length === 0) return;

    const reconciledValues = getValues('targetUsers').map(user => {
      if ((user as ShareReportUserOption).id) return user;

      const selectedUser = selectedUsers.find(({ id }) => id === user.value);
      return selectedUser || user;
    });

    setValue('targetUsers', reconciledValues);
  }, [selectedUsers]);

  useFormValuesInitializer(reset, reportId, sharedWith);

  return (
    <Box>
      <ContentModal controls={controls} header="Share report" isOpen={!!reportId} onClose={onClose} preventAutoClose>
        <Flex flexDirection="column" gap="large">
          <Box backgroundColor="neutralSubtle" borderRadius="large" padding="medium">
            <Flex flexDirection="column" gap="xxsmall">
              <Text color="neutralSubtle" fontSize="small" fontWeight="medium">
                Report
              </Text>
              <Text>{reportName}</Text>
            </Flex>
          </Box>
          <Divider />
          <Fieldset
            description={`${description1} ${description2}`}
            legend="Set-up sharing"
            legendStyle="group"
            removeSpaceForLastFieldMessage
          >
            <Field label="Select users">
              <SelectInput<ShareReportUserOption, ShareReportFieldValues>
                control={control}
                filterOption={() => !isLoading}
                inputValue={inputValue}
                isLoading={isLoading}
                isMulti
                itemOverride={CustomItem}
                items={users}
                limit={1}
                menuPlacement="top"
                name="targetUsers"
                onInputChange={value => setInputValue(value)}
                placeholder="Search by name or email..."
              />
            </Field>
            {watch('targetUsers').length > 0 && (
              <Field label="Shared with">
                <Box
                  borderColor="neutralSubtle"
                  borderRadius="large"
                  borderStyle="solid"
                  borderWidth="thin"
                  padding="medium"
                >
                  {watch('targetUsers').map((targetUser, index) => {
                    const { avatar, email, id, label, role, value } = targetUser as ShareReportUserOption;
                    return (
                      <Fragment key={`selected-item_${value}`}>
                        {index > 0 && (
                          <Box paddingY="small">
                            <Divider />
                          </Box>
                        )}
                        <SelectedItemCard
                          avatar={avatar}
                          avatarName={id ? label : ''}
                          email={id ? email : ''}
                          name={id ? label : ''}
                          onClick={() => {
                            setValue(
                              'targetUsers',
                              getValues('targetUsers').filter(user => user.value !== value)
                            );
                          }}
                          role={id ? role : ''}
                        />
                      </Fragment>
                    );
                  })}
                </Box>
              </Field>
            )}
          </Fieldset>
        </Flex>
      </ContentModal>
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
