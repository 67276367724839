import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { isNumeric } from 'src/features/utils';

import {Filter, FILTER_DATE_PERIODS, FILTER_DATE_PERIODS_LIMITED} from '../types/filterModel';
import { ViewJoinAndDimensionOrMeasure } from '../redux';
import {
  DATETIME_FORMAT,
  DATE_DIMENSION_TYPES,
  DIMENSION_TYPE_DAY_NUMBER,
  DIMENSION_TYPE_MONTH_NUMBER,
  DIMENSION_TYPE_DATEINTERVAL,
  DIMENSION_TYPE_DATETIME
} from '../constants';
import {
  FILTER_DATE_CONTEXT_CURRENT,
  FILTER_PERIOD_MONTH,
  FILTER_PERIOD_WEEK,
  FILTER_TYPE, FILTER_TYPE_BETWEEN,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK
} from '../constants/filters';
import { FILTERS_FOR_TIME_CONTEXT } from '../components/FilterSelect';
import moment from 'moment';
import {RELATIVE_DATE_PERIODS} from "../features/report-builder";

/**
 * Is Filter equal to filterOption
 * @param {ViewJoinAndDimensionOrMeasure} filterOption
 * @param {Filter} filter
 * @returns {boolean}
 */
export function filterOptionEqualsFilter(filterOption: ViewJoinAndDimensionOrMeasure, filter: Filter): boolean {
  // are the joins equal - either both do not have a join, or if they both have a join,
  // then they have the same join name
  let joinsAreEqual: boolean = (!filterOption.join && !filter.joinName) ||
    (!!filter.joinName && !!filterOption.join && (filterOption.join.fullJoinName === filter.joinName));

  // are the views equal
  let viewsAreEqual: boolean = filterOption.view.name === filter.viewName;

  let selected = false;

  // filter options are considered equal if the joins, views, and dimension/measure names are the same
  if (filterOption.dimension && (filterOption.dimension.name === filter.dimensionName)
    && viewsAreEqual && joinsAreEqual) {
    selected = true;
  } else if (filterOption.measure && filterOption.measure.name === filter.measureName
    && viewsAreEqual && joinsAreEqual) {
    selected = true;
  }

  return selected;
}

/**
 * Get filters which are in filterOptions
 * @param {ViewJoinAndDimensionOrMeasure[]} filterOptions
 * @param {Filter[]} filters
 * @returns {Filter[]}
 */
export function getFiltersInFilterOptions(filterOptions: ViewJoinAndDimensionOrMeasure[], filters: Filter[]) {
  return filters.filter((displayFilter: Filter) => {
    return getIndexInFilterOptions(filterOptions, displayFilter) !== -1;
  });
}

/**
 * Find the index of filter in filterOptions array
 * @param {ViewJoinAndDimensionOrMeasure[]} filterOptions
 * @param {Filter} filter
 * @returns {number}
 */
export function getIndexInFilterOptions(filterOptions: ViewJoinAndDimensionOrMeasure[], filter: Filter) {
  return filterOptions.findIndex((filterOption: ViewJoinAndDimensionOrMeasure) => {
    return filterOptionEqualsFilter(filterOption, filter);
  });
}

/**
 * Is the filter valid
 * @param {Filter} filter
 * @returns {boolean}
 */
export function filterIsValid(filter: Filter): boolean {
  let valueIsValid = (filter.high ? filter.high.toString().length > 0 : false) ||
    (filter.low ? filter.low.toString().length > 0 : false) ||
    (filter.value ? filter.value.toString().length > 0 : false) ||
    ((filter.type === FILTER_TYPE_IS_BLANK || filter.type === FILTER_TYPE_NOT_BLANK) && !filter.value);

  const MAX_PERIOD_COUNT_VALUE = 999;

  if (
    typeof filter.periodCount === 'string' &&
    // NOTE: must be a canonical positive integer
    (!isNumeric(filter.periodCount) ||
      Math.floor(Number(filter.periodCount)).toString() !== filter.periodCount ||
      Number(filter.periodCount) < 1 ||
      Number(filter.periodCount) > MAX_PERIOD_COUNT_VALUE)
  ) {
    valueIsValid = false;
  }

  return (filter.dimensionName !== undefined || filter.measureName !== undefined)
    && filter.viewName.length > 0
    && filter.type.length > 0
    && valueIsValid;
}

/**
 * Convert filterOptions (created when a new dimension/group by is added) to Filter objects,
 * used for the filter selection and query.
 * Dictates the default values that show up when a new Filter is added by the UI
 *
 * @param {ViewJoinAndDimensionOrMeasure} filterOption
 * @returns {Filter}
 */
export function createFilterFromFilterOption(filterOption: ViewJoinAndDimensionOrMeasure): Filter {
  const columnType = getColumnType(filterOption);

  return {
    viewName: filterOption.view.name,
    dimensionName: (filterOption.dimension) ? filterOption.dimension.name : undefined,
    dimensionOrMeasureType: (filterOption.dimension) ? filterOption.dimension.type : (filterOption.measure) ? filterOption.measure.type : undefined,
    measureName: (filterOption.measure) ? filterOption.measure.name : undefined,
    type: FILTER_TYPE_EQUALS,
    value: [DIMENSION_TYPE_DATETIME, DIMENSION_TYPE_DATEINTERVAL].includes(columnType)
        ? FILTER_PERIOD_WEEK
        : [DIMENSION_TYPE_DAY_NUMBER, DIMENSION_TYPE_MONTH_NUMBER].includes(columnType)
            ? FILTER_PERIOD_MONTH
            : '',
    joinName: filterOption.join ? filterOption.join.fullJoinName : '',
    relativeDate: DATE_DIMENSION_TYPES.includes(columnType)
        ? FILTER_DATE_CONTEXT_CURRENT : undefined
  };
}

export function getColumnType(filterOption: ViewJoinAndDimensionOrMeasure): string {
  if (filterOption.dimension) {
    return filterOption.dimension.type;
  } else if (filterOption.measure) {
    return filterOption.measure.type;
  }

  return '';
}

export function getColumnName(filterOption: ViewJoinAndDimensionOrMeasure): string {
  const column = filterOption.dimension || filterOption.measure;
  return column!.name;
}

export function getColumnViewName(filterOption: ViewJoinAndDimensionOrMeasure): string {
  return filterOption.join ? filterOption.join.viewName : filterOption.view.name;
}

export function defaultFilterValue(columnType: string, filterType: FILTER_TYPE) {
  if (DATE_DIMENSION_TYPES.includes(columnType)
      && FILTERS_FOR_TIME_CONTEXT.includes(filterType)) {
    return FILTER_PERIOD_WEEK;
  }

  return '';
}

export function defaultFilterFromTypeChange(columnType: string, filter: Filter) {
  // Special case for BETWEEN Filter, there is a low and high instead of a value
  if (filter.type === FILTER_TYPE_BETWEEN) {
    filter.low = moment().format(DATETIME_FORMAT);
    filter.high = moment().format(DATETIME_FORMAT);
  } else {
    filter.low = undefined;
    filter.high = undefined;
  }

  if (DATE_DIMENSION_TYPES.includes(columnType)
      && FILTERS_FOR_TIME_CONTEXT.includes(filter.type)) {
    filter.relativeDate = FILTER_DATE_CONTEXT_CURRENT;
  } else {
    filter.relativeDate = undefined;
  }

  filter.value = defaultFilterValue(columnType, filter.type);
  filter.nowAsDefaultReferencePoint = undefined;
  filter.periodCount = undefined;

  return filter;
}

export function getFilterDatePeriodOptions(relativeDate?: string, dimensionOrMeasureType?: string) {
  const dayOrMonthNumber = !!dimensionOrMeasureType &&
      [DIMENSION_TYPE_DAY_NUMBER, DIMENSION_TYPE_MONTH_NUMBER].includes(dimensionOrMeasureType);

  if (dayOrMonthNumber) {
    return [].concat.apply(
        [],
        FILTER_DATE_PERIODS_LIMITED.map((value) => [
          {
            label: value.toLowerCase(),
            nowAsDefaultReferencePoint: false,
            value,
          },
        ])
    );
  } else {
    return [].concat.apply(
        [],
        FILTER_DATE_PERIODS.map((value, index) => [
          {
            label: value.toLowerCase(),
            nowAsDefaultReferencePoint: false,
            value,
          },
          {
            label: `${value.toLowerCase()} ${relativeDate === FILTER_DATE_CONTEXT_CURRENT ? 'so far' : 'from now'}`,
            nowAsDefaultReferencePoint: true,
            value: RELATIVE_DATE_PERIODS[index],
          },
        ])
    );
  }
}

export type GetFilterValuesPayloadType = {
  moduleName: string;
  viewName: string;
  dimensionName: string;
  search?: string;
};

export type FilterValueType = {
  label: string;
  value: string;
};

export type GetFilterValuesResponseType = Array<FilterValueType>;

let getSchedulerUsersSource: CancelTokenSource | null = null;

export async function apiGetFilterValues({
  moduleName,
  viewName,
  dimensionName,
  search
}: GetFilterValuesPayloadType): Promise<GetFilterValuesResponseType> {
  if (getSchedulerUsersSource) {
    getSchedulerUsersSource.cancel('Cancelled');
  }

  const CancelToken = axios.CancelToken;
  getSchedulerUsersSource = CancelToken.source();

  const response: AxiosResponse<GetFilterValuesResponseType> = await axios.get(
    `/api/modules/${moduleName}/view/${viewName}/dimension/${dimensionName}/values`,
    { params: { search }, cancelToken: getSchedulerUsersSource.token },
  );
  return response.data;
}
