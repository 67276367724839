import * as React from 'react';
import UserSelect from '../UserSelect';
import { getClassNames } from '../../../services/utilityService';
import { Alert } from 'elmo-elements';
import { UserSelectOption } from '../../../types/scheduler/userSelectOption';

type Props = {
  userList: UserSelectOption[];
  selectedUsers: UserSelectOption[];
  onUserSelect: (user: UserSelectOption) => void;
  onUserSelectChange: (text: string) => void;
  onUserDeselect: (user: UserSelectOption) => () => void;
  onResetUserList: () => void;
  errors: any;
  isVisible: boolean;
  isLoading: boolean;
  isUserListTyping: boolean;
};

function UserSelectForm(props: Props) {
  const {
    selectedUsers,
    onUserSelect,
    onUserDeselect,
    errors,
    isVisible,
    isLoading,
    userList,
    onUserSelectChange,
    onResetUserList,
    isUserListTyping,
  } = props;

  const classNames = getClassNames({'d-none': !isVisible});

  return (
    <div className={classNames}>
      <Alert
        className="addScheduleModal__alert"
        message="Selected users will receive .csv files that include all data displayed in the report results."
        type="info"
        isCloseable={false}
        id="user-select-alert"
      />
      <UserSelect
        selectedUsers={selectedUsers}
        onSelect={onUserSelect}
        onUserSelectChange={onUserSelectChange}
        onDeselect={onUserDeselect}
        onResetUserList={onResetUserList}
        errors={errors}
        isLoading={isLoading}
        isUserListTyping={isUserListTyping}
        userList={userList}
      />
    </div>
  );
}

export default UserSelectForm;
