import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { OptionProps } from '@eds/select-dropdown';
import { Text } from '@eds/text';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { useDebounce } from '@eds/utils';

import { DEBOUNCE_TIMEOUT_MILLISECONDS } from 'src/features/common/values';
import { MenuItemCard, SelectInput } from 'src/features/ui';

import { ScheduledReportFieldValues, emailSchema } from './scheduled-reports-modal.validation';
import { ScheduledReportsUserOption, useScheduledReportsUsersQuery } from './scheduled-reports-modal.queries';

const CustomItem = ({ data }: OptionProps<ScheduledReportsUserOption>) => (
  <MenuItemCard
    avatar={data.avatar}
    avatarName={data.type === 'user' ? data.label : ''}
    email={data.type === 'user' ? data.email : data.value}
    key={`menu-item-card_${data.id}`}
    name={data.label}
    role={data.role}
  />
);

type OutboundNotificationProps = {
  errors: FieldErrors<ScheduledReportFieldValues>;
} & Pick<UseFormReturn<ScheduledReportFieldValues>, 'control' | 'getValues' | 'setValue' | 'watch'>;

export const OutboundNotification = ({ control, errors, getValues, setValue, watch }: OutboundNotificationProps) => {
  const [outboundNotificationRecipientInput, setOutboundNotificationRecipientInput] = useState('');
  const [debouncedOutboundNotificationRecipientInput] = useDebounce(
    outboundNotificationRecipientInput,
    DEBOUNCE_TIMEOUT_MILLISECONDS
  );

  const { data: users = [], isLoading: isUsersLoading } = useScheduledReportsUsersQuery(true, {
    text: outboundNotificationRecipientInput ? debouncedOutboundNotificationRecipientInput || undefined : undefined,
  });

  const { data: selectedUsers = [] } = useScheduledReportsUsersQuery(
    watch('outboundNotificationRecipient')?.type === 'user',
    {
      ids:
        getValues('outboundNotificationRecipient.type') === 'user'
          ? getValues(['outboundNotificationRecipient.value'])
          : undefined,
    }
  );

  useEffect(() => {
    if (selectedUsers.length === 1) setValue('outboundNotificationRecipient', selectedUsers[0]);
  }, [selectedUsers]);

  return (
    <Flex
      borderColor="neutralSubtle"
      borderRadius="large"
      borderStyle="solid"
      borderWidth="thin"
      flexDirection="column"
      padding="large"
      paddingBottom="none"
    >
      <Text>Notification</Text>
      <Text color="neutralSubtle" fontSize="small">
        Once the file is dispatched to the outbound destination, the following user will be notified via email. For
        users not listed, please input their email addresses manually.
      </Text>
      <Box paddingTop="large" />
      <Field
        invalid={!!errors.outboundNotificationRecipient}
        label="Select recipient"
        message={errors.outboundNotificationRecipient?.message}
        required
      >
        <SelectInput<ScheduledReportsUserOption, ScheduledReportFieldValues>
          control={control}
          filterOption={() => !isUsersLoading}
          inputValue={outboundNotificationRecipientInput}
          isLoading={isUsersLoading}
          itemOverride={CustomItem}
          items={
            emailSchema.isValidSync(debouncedOutboundNotificationRecipientInput)
              ? [
                  ...users,
                  {
                    label: debouncedOutboundNotificationRecipientInput,
                    value: debouncedOutboundNotificationRecipientInput,
                  } as ScheduledReportsUserOption,
                ]
              : users
          }
          menuPlacement="top"
          name="outboundNotificationRecipient"
          onInputChange={inputValue => setOutboundNotificationRecipientInput(inputValue)}
          placeholder="Search by name or email..."
        />
      </Field>
    </Flex>
  );
};
