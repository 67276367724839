import React, { useState } from 'react';
import { Box } from '@eds/box';
import { ControlsProps } from '@eds/modal';
import { Toast, toastMessage } from '@eds/toast';

import { AlertModal } from 'src/features/ui';

import { useDeleteScheduledReportMutation, useScheduledReportNameQuery } from './delete-scheduled-report.queries';

interface DeleteScheduledReportProps {
  isOpen: boolean;
  onClose: () => void;
  scheduledReportId: string;
}

export const DeleteScheduledReport = ({ isOpen, onClose, scheduledReportId }: DeleteScheduledReportProps) => {
  const [successMessage, setSuccessMessage] = useState<symbol>();

  // NOTE: This query uses stale data. We are more interested in refetch-ing in case of errors
  const { data: scheduleName = '', refetch } = useScheduledReportNameQuery(scheduledReportId);

  const { mutate } = useDeleteScheduledReportMutation({
    onSettled: () => refetch(),
    onSuccess: () => setSuccessMessage(toastMessage('Schedule deleted')),
  });

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: {
      label: 'Yes, delete',
      onClick: () => {
        mutate(scheduledReportId);
        onClose();
      },
      tone: 'critical',
    },
  };

  return (
    <Box>
      <AlertModal
        controls={controls}
        description={`This action will delete ${scheduleName || 'the schedule'} permanently.`}
        isOpen={isOpen}
        onClose={onClose}
        title="Delete schedule?"
      />
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
