import * as React from 'react';
import { useSelector } from 'react-redux';
import { AddIcon, DeleteIcon } from '@eds/icon';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { Grid } from '@eds/grid';
import { Text } from '@eds/text';
import { SelectInput } from '@eds/select-input';
import { css } from '@emotion/css';
import { space } from '@eds/core';

import { cssOverrides } from 'src/features/ui';
import {
  FIELD_DEPRECATION_MESSAGE,
  composeCustomFilterOption,
  useUnselectedFieldOptions,
  useViews
} from 'src/features/report-builder';

import {
  DIMENSION_TYPE_DATETIME,
  DIMENSION_TYPE_NUMBER,
  DIMENSION_TYPE_STRING,
  DIMENSION_TYPE_BOOLEAN,
  DIMENSION_TYPE_DATEINTERVAL,
  MEASURE_TYPE_AVERAGE,
  MEASURE_TYPE_COUNT,
  MEASURE_TYPE_CUSTOM,
  MEASURE_TYPE_SUM,
  DIMENSION_TYPE_DAY_NUMBER,
  DIMENSION_TYPE_MONTH_NUMBER,
  MEASURE_TYPE_MAX,
  MEASURE_TYPE_MIN,
} from '../../../constants';
import {
  FILTER_TYPE,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_CONTAINS,
  FILTER_TYPE_NOT_CONTAINS,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_NOT_BLANK,
  FILTER_TYPE_NOT_EQUALS,
} from '../../../constants/filters';

import { AppState, ViewJoinAndDimensionOrMeasure } from '../../../redux';
import { Dimension } from '../../../types/dimensionModel';
import { Filter } from '../../../types/filterModel';
import { Measure } from '../../../types/measureModel';
import {
  createFilterFromFilterOption,
  defaultFilterFromTypeChange,
  getColumnType,
} from '../../../services/filterService';
import useDimensionLabel from '../../hooks/useDimensionLabel';

import FilterValueInput from './FilterValueInput';

export const FILTERS_FOR_NUMBERS = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK,
];

export const FILTERS_FOR_STRING = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_NOT_EQUALS,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_NOT_LIKE,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK,
];

export const FILTERS_FOR_DATETIME = [
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_BETWEEN,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
  FILTER_TYPE_IS_BLANK,
  FILTER_TYPE_NOT_BLANK,
];

export const FILTERS_FOR_DAY_MONTH_NUMBER = [FILTER_TYPE_EQUALS, FILTER_TYPE_GREATER_THAN, FILTER_TYPE_LESS_THAN];

export const FIELD_TYPE_TO_FILTER = {
  [DIMENSION_TYPE_NUMBER]: FILTERS_FOR_NUMBERS,
  [DIMENSION_TYPE_STRING]: FILTERS_FOR_STRING,
  [DIMENSION_TYPE_DATETIME]: FILTERS_FOR_DATETIME,
  [DIMENSION_TYPE_DATEINTERVAL]: FILTERS_FOR_DATETIME,
  [DIMENSION_TYPE_DAY_NUMBER]: FILTERS_FOR_DAY_MONTH_NUMBER,
  [DIMENSION_TYPE_MONTH_NUMBER]: FILTERS_FOR_DAY_MONTH_NUMBER,
  [DIMENSION_TYPE_BOOLEAN]: FILTERS_FOR_STRING,
  [MEASURE_TYPE_AVERAGE]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_COUNT]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_SUM]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_CUSTOM]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_MAX]: FILTERS_FOR_NUMBERS,
  [MEASURE_TYPE_MIN]: FILTERS_FOR_NUMBERS,
};

interface FilterRuleProps {
  canManageFilter: boolean;
  displayFilter: Filter;
  displayFilterCount?: number;
  displayFilterIndexInFilterOptions: number;
  displayFilters: Filter[];
  filterIndex: number;
  filterOption: ViewJoinAndDimensionOrMeasure;
  newFilter: () => void;
  removeFilter: (index: number) => void;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
}

type Option = {
  deprecated?: boolean;
  label: string;
  value: string;
  viewName?: string;
};

const FilterRule = ({
  canManageFilter,
  displayFilter,
  displayFilterCount = 0,
  displayFilterIndexInFilterOptions,
  displayFilters,
  filterIndex,
  filterOption,
  newFilter,
  removeFilter,
  saveDisplayFilter,
}: FilterRuleProps) => {
  const { filterOptions } = useSelector((state: AppState) => state);
  const { availableViews, currentView } = useViews();

  const additionalOptions = useUnselectedFieldOptions(currentView, availableViews);
  const getDimensionLabel = useDimensionLabel();

  const handleSelectChange = (option: Option | null) => {
    if (!option) {
      return;
    }

    const matchingfilterOption: ViewJoinAndDimensionOrMeasure | undefined = filterOptions[option.value];

    if (matchingfilterOption) {
      saveDisplayFilter(createFilterFromFilterOption(matchingfilterOption), filterIndex);
      return;
    }

    const customFilterOption = composeCustomFilterOption(availableViews, currentView, option.value, option.viewName);
    saveDisplayFilter(createFilterFromFilterOption(customFilterOption), filterIndex);
  };

  const handleTypeChange = (option: Option | null) => {
    if (!option) {
      return;
    }

    const columnType = getColumnType(filterOption);
    let filter = { ...displayFilter };

    filter.type = option.value as FILTER_TYPE;

    filter = defaultFilterFromTypeChange(columnType, filter);

    saveDisplayFilter(filter, filterIndex);
  };

  const filterOnOptions = filterOptions
    .map(({ join, dimension, measure }, index) => {
      const dimensionIdentifier = `${join ? `${join.fullJoinName}.` : ''}${measure ? measure.name : dimension!.name}`;
      const [name, alias] = getDimensionLabel(dimensionIdentifier);

      const option: Option = {
        deprecated: dimension?.deprecated,
        value: `${index}`,
        label: alias || name || 'Label Missing',
      }

      return option;
    })
    .concat(additionalOptions);

  const typeToLabelMap: { [FILTER_TYPE_NOT_LIKE]: string; [FILTER_TYPE_LIKE]: string } = {
    [FILTER_TYPE_LIKE]: FILTER_TYPE_CONTAINS.toLowerCase(),
    [FILTER_TYPE_NOT_LIKE]: FILTER_TYPE_NOT_CONTAINS.toLowerCase(),
  };

  const getFilterTypeOptions = (filterOptionField?: Dimension | Measure) => {
    if (!filterOptionField) {
      return [];
    }

    return FIELD_TYPE_TO_FILTER[filterOptionField.type].map((value: FILTER_TYPE) => {
      const label = typeToLabelMap[value] || value.toLowerCase();
      return { value, label };
    });
  };

  const filterTypeOptions = getFilterTypeOptions(filterOption.measure || filterOption.dimension);
  const filterTypeValue = filterTypeOptions.find(option => option.value === displayFilter.type);

  // NOTE: Our filters require fine tuning so that contents will still be legible in a smaller screen, and its more
  // coherent to use a <Grid> for this
  const gridTemplateColumns =
    'minmax(3rem, 5rem) minmax(9rem, 1fr) minmax(9rem, 1fr) minmax(7rem, 1fr) minmax(15rem, 1fr) 5rem';

  const fieldValue: Option | undefined =
    displayFilterIndexInFilterOptions === -1
      ? additionalOptions.find(
          ({ value }) => value === `${displayFilter.joinName || displayFilter.viewName}.${displayFilter.dimensionName}`
        )
      : filterOnOptions[displayFilterIndexInFilterOptions];

  return (
    <Grid gap="medium" gridTemplateColumns={gridTemplateColumns}>
      <Flex alignItems="center" justifyContent="center">
        {filterIndex === 0 && (
          <Text fontSize="small" fontWeight="medium" color="neutralBold">
            IF
          </Text>
        )}
        {filterIndex !== 0 && (
          <Text fontSize="small" fontWeight="medium" color="neutralSubtle">
            AND
          </Text>
        )}
      </Flex>
      <Field
        caution={fieldValue?.deprecated}
        keepSpaceForMessage={false}
        label="Field"
        message={fieldValue?.deprecated ? FIELD_DEPRECATION_MESSAGE : ''}
      >
        <SelectInput
          isClearable={false}
          items={filterOnOptions}
          onChange={handleSelectChange}
          size="small"
          value={fieldValue}
        />
      </Field>
      <Field label="Operator" keepSpaceForMessage={false}>
        <SelectInput
          disabled={!canManageFilter}
          isClearable={false}
          items={filterTypeOptions}
          onChange={handleTypeChange}
          size="small"
          value={filterTypeValue}
        />
      </Field>
      <Grid gridColumnStart={4} gridColumnEnd={6}>
        <FilterValueInput
          displayFilter={displayFilter}
          filterIndex={filterIndex}
          saveDisplayFilter={saveDisplayFilter}
          filterOption={filterOption}
          isDisabled={!canManageFilter}
        />
      </Grid>
      {canManageFilter && (
        <Flex
          className={css({ ...cssOverrides.button('neutral'), paddingTop: '21.5px' })}
          gap="medium"
          justifyContent="flex-end"
        >
          {(displayFilters.length > 1 || displayFilterCount > 1) && (
            <Button
              iconOnly
              icon={DeleteIcon}
              label="Delete filter"
              onClick={() => removeFilter(filterIndex)}
              size="small"
              tone="neutral"
            />
          )}
          <Box data-tracking="add-filter" width={space.xlarge}>
            {filterIndex === displayFilters.length - 1 && (
              <Button iconOnly icon={AddIcon} label="Add filter" onClick={newFilter} size="small" tone="neutral" />
            )}
          </Box>
        </Flex>
      )}
    </Grid>
  );
};

export default FilterRule;
