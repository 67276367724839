import * as React from 'react';
import { getClassNames } from '../../../services/utilityService';
import NotificationRecipientSelect from '../NotificationRecipientSelect';
import { UserSelectOption } from '../../../types/scheduler/userSelectOption';

type Props = {
  errors: any;
  isVisible: boolean;
  userList: UserSelectOption[];
  selectedUsers: UserSelectOption[];
  onUserSelect: (user: UserSelectOption) => void;
  onResetUserList: () => void;
  onUserSelectChange: (text: string) => void;
  isLoading: boolean;
};

function NotificationRecipientSelectForm(props: Props) {
  const {
    errors,
    isVisible,
    selectedUsers,
    onUserSelect,
    isLoading,
    userList,
    onUserSelectChange,
    onResetUserList,
  } = props;

  const className = getClassNames({
                                    'd-none': !isVisible
                                  });

  return (
    <div className={className}>
      <NotificationRecipientSelect
        selectedUsers={selectedUsers}
        onSelect={onUserSelect}
        onUserSelectChange={onUserSelectChange}
        onResetUserList={onResetUserList}
        errors={errors}
        isLoading={isLoading}
        userList={userList}
      />
    </div>
  );
}

export default NotificationRecipientSelectForm;
