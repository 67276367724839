import React, { useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';

import { ReportingRoutes } from 'src/constants/routes';
import {
  ScheduledReportFieldValues,
  ScheduledReportsModal,
  useScheduledReportMutation,
} from 'src/features/scheduled-reports';

interface CreateScheduledReportProps {
  isOpen: boolean;
  onClose: () => void;
  reportId: string;
  reportName: string;
}

export const CreateScheduledReport = ({ isOpen, onClose, reportId, reportName }: CreateScheduledReportProps) => {
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { isLoading: loading, mutate } = useScheduledReportMutation('create', () => {
    onClose();
    setSuccessMessage(toastMessage('Schedule created'));
  });

  const onSubmit = (values: ScheduledReportFieldValues) => mutate(values);

  return (
    <Box>
      <ScheduledReportsModal
        isOpen={isOpen}
        loading={loading}
        mode="create"
        onClose={onClose}
        onSubmit={onSubmit}
        reportInfo={isOpen ? { reportId, reportName } : undefined}
      />
      {successMessage && (
        <Toast
          message={successMessage}
          tone="positive"
          linkText="See schedules"
          linkHref={ReportingRoutes.scheduledReports}
        />
      )}
    </Box>
  );
};
