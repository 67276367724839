import { FETCHED_CHART, SHOULD_GENERATE_CHART } from '../constants';
import { ChartAction } from '../actions/chart';
import { Chart } from '../types/chartModel';

export function chartReducer(state: Chart = new Chart(), action: ChartAction) {
  switch (action.type) {
    case FETCHED_CHART:
      return action.chart;
    case SHOULD_GENERATE_CHART:
      return Object.assign(state, {
        shouldGenerateChart: action.chart.shouldGenerateChart
      });
    default:
      return state;
  }
}
