import React from 'react';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/css';

import { TextAreaInput, Toggle, cssOverrides } from 'src/features/ui';

import { ScheduledReportFieldValues } from './scheduled-reports-modal.validation';

type OutboundEncryptionProps = {
  errors: FieldErrors<ScheduledReportFieldValues>;
} & Pick<UseFormReturn<ScheduledReportFieldValues>, 'control' | 'watch'>;

export const OutboundEncryption = ({ control, errors, watch }: OutboundEncryptionProps) => {
  return (
    <Flex
      borderColor="neutralSubtle"
      borderRadius="large"
      borderStyle="solid"
      borderWidth="thin"
      flexDirection="column"
      gap="large"
      padding="large"
      paddingBottom={watch('encryptionEnabled') ? 'none' : 'large'}
    >
      <Flex
        className={css({ ...cssOverrides.button('toggle'), '& > span': { paddingTop: 0, paddingBottom: 0 } })}
        data-tracking="encryption-toggle"
        flexDirection="column"
      >
        <Toggle control={control} label="Encryption" name="encryptionEnabled" />
        <Text color="neutralSubtle" fontSize="small" marginLeft="xxlarge" paddingLeft="xsmall">
          Configure scheduled file transfers to support encryption using GPG or PGP methods.
        </Text>
      </Flex>
      {watch('encryptionEnabled') && (
        <Field
          description="Paste your public key into the text area below"
          invalid={!!errors.encryptionKey}
          label="Encryption key"
          labelVisible={false}
          message={errors.encryptionKey?.message}
        >
          <TextAreaInput control={control} name="encryptionKey" placeholder="Add public key here..." rows={6} />
        </Field>
      )}
    </Flex>
  );
};
