import * as React from 'react';

export type DrilldownIconProps = {
  onClick: () => void;
};

function DrilldownIcon({ onClick }: DrilldownIconProps) {
  return (
    <a
      href="#"
      className="pl-2"
      title="Drill down into this aggregate"
      onClick={onClick}
      aria-label="Drill down into this aggregate"
      data-testid="drilldown-icon"
    >
      <span className="fa fa-caret-right" />
    </a>
  );
}

export default DrilldownIcon;