export abstract class Column {
  name: string;
  label: string;

  public static equals(c1: Column, c2: Column): boolean {
    return c1.name === c2.name;
  }

  public static arrayEquals(c1: Column[], c2: Column[]): boolean {
    if (c1.length !== c2.length) {
      return false;
    }

    for (let i: number = 0; i < c1.length; i++) {
      if (!Column.equals(c1[i], c2[i])) {
        return false;
      }
    }

    return true;
  }
}