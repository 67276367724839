/**
 * Please do not use this component directly, instead use the Report component (Report/index.tsx)
 */
import * as React from 'react';
import moment from 'moment';
import { BlockIcon, Heading } from 'elmo-elements';
import { map } from 'lodash';
import { Dispatch } from 'redux';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { ReportDetails } from '../../redux';
import { showDrilldownModal } from '../../actions/drilldownModal';
import { ColumnMeta } from '../../types/columnMetaModel';
import { OrderBy } from '../../types/orderByModel';
import { SavedQuery } from '../../types/savedQueryModel';
import { ReportTable } from '../../types/reportTableModel';
import { ReportRow } from '../../types/reportRowModel';
import { ReportCell } from '../../types/reportCellModel';
import { ORDER_ASC, ORDER_DESC } from '../../constants';
import { getColumnMetaWithLabels } from '../../services/columnMetaService';
import { orderByEqualsColumnMeta } from '../../services/reportService';
import { reportSharedWithMe } from '../../services/shareReportService';
import { ReorderFunction, ReportSortFunction } from '../../containers/Report';
import GenerateReportButton from '../../containers/GenerateReportButton';
import ShareButton from '../../containers/ShareButton';
import DrilldownIcon from '../ReportBuilder/DrilldownIcon';
import { withReportContext } from './ReportContextConsumer';

import 'font-awesome/css/font-awesome.min.css';
import './style.css';
import { View } from '../../types/viewModel';
import { Query } from '../../types/queryModel';
import { ReportHeader } from '../../types/reportHeaderModel';
import { isValidUuid } from '../../utils';

export type ReportProps = {
  sort: ReportSortFunction;
  orderBys: OrderBy[];
  reorder: ReorderFunction;
  removeColumn: (columnMeta: ColumnMeta) => void;
  reportDetails: ReportDetails;
  loadedQuery?: SavedQuery;
  reportTitle: string;
  readOnly: boolean;
  isDashboardPage?: boolean;
  printView?: boolean;
  renderPagination: () => any;
  dispatch: Dispatch<any>;

  // From ReportContext
  columnMeta: ColumnMeta[];
  // From ReportContext
  report: ReportTable;
  // From ReportContext
  views: View[];
  // From ReportContext
  query: Query;
};

class ReportComponent extends React.Component<ReportProps> {

  constructor(props: ReportProps) {
    super(props);
  }

  render() {
    return (
      <div className="report-container">
        {!this.props.isDashboardPage &&
          <div className="report-header-container">
            <Heading htmlTag="h4" data-testid="report-results-title">
              {this.props.reportTitle ? this.props.reportTitle : 'Report Title'}
            </Heading>
            {!this.props.readOnly && this.renderHeaderButtons()}
          </div>
        }
        {this.renderReport()}
        {this.props.report.rows.length > 0 && !this.props.printView &&
          <div className="row no-gutters">
            <div className="col">
              <div className="pull-right">
                {this.props.renderPagination()}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  renderHeaderButtons() {
    const { loadedQuery, readOnly, report} = this.props;
    const showShareButton = !readOnly && (!loadedQuery || (!!loadedQuery && !reportSharedWithMe(loadedQuery) &&
      loadedQuery.permissions && loadedQuery.permissions.canAddToDashboard));
    return report.rows.length > 0 ? (
      <div className="report-header-buttons">
        {/*!this.props.readOnly && this.renderCachedTime()*/}
        {showShareButton &&
        <span className="report-header-share-button">
          <ShareButton
            loadedQuery={loadedQuery}
          />
        </span>
        }
      </div>
    ) : null;
  }

  /**
   * Reorders the columnMeta array so that the item in startIndex is moved to endIndex.
   * @param {ColumnMeta[]} list
   * @param dragStartIndex
   * @param dragEndIndex
   * @returns {ColumnMeta[]}
   */
  reorder = (list: ColumnMeta[], dragStartIndex: number, dragEndIndex: number): ColumnMeta[] => {
    const result = Array.from(list);

    // remove the item from result
    const [draggedItem] = result.splice(dragStartIndex, 1);

    // add the item in the drag end position
    result.splice(dragEndIndex, 0, draggedItem);

    return result;
  }

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const columnMeta = this.reorder(
      this.props.columnMeta,
      result.source.index,
      result.destination.index
    );

    this.props.reorder(columnMeta);
  }

  getItemStyle = (isDragging: any, draggableStyle: any, numItems: number) => {
    return {
      ...draggableStyle,
    };
  }

  getListStyle = () => ({
    background: 'none',
    display: 'flex',
    padding: 0,
    overflow: 'visible',
  })

  sort = (columnMeta: ColumnMeta) => () => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    this.props.sort(columnMeta, this.props.orderBys, reportId);
  }

  renderHeader(columnMetas: ColumnMeta[]): any {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={this.getListStyle()}
              className={'header-row'}
              {...provided.droppableProps}
              role="rowheader"
              data-testid="header-row"
            >
              {
                columnMetas.map((cm: ColumnMeta, index: number) => {
                  return (
                    <Draggable key={'header' + index} draggableId={'header' + index} index={index}>
                      {(providedDraggable, snapshotDraggable) => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          style={this.getItemStyle(
                            snapshotDraggable.isDragging,
                            providedDraggable.draggableProps.style,
                            columnMetas.length
                          )}
                          className={'header-col'}
                        >
                          {this.renderSortStatus(this.props.orderBys, cm)}
                          <span
                            className="column-title"
                            onClick={this.sort(cm)}
                            role="columnheader"
                            data-testid={'column-title-' + index}
                          >
                            {cm.displayLabel}
                          </span>
                          {this.renderRemoveColumnButton(cm)}
                        </div>
                      )}
                    </Draggable>
                  );
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  renderReport() {
    const { columnMeta, report } = this.props;
    const columnMetaWithLabels: ColumnMeta[] = getColumnMetaWithLabels(columnMeta, report.headers);

    if (columnMetaWithLabels.length === 0) {
      return (
        <React.Fragment>
          <div className="empty" />
          <div className="no-result">Please make some selections on the left.</div>
        </React.Fragment>
      );
    }

    return (
      <div className="report-result" role="table">
        { this.props.readOnly ? this.renderReadOnlyHeader(columnMetaWithLabels, report.headers)
          : this.renderHeader(columnMetaWithLabels)}
        {this.props.report.rows.length > 0 ?
          map(this.props.report.rows, (row: ReportRow, key: number) => {
            return this.renderRow(row, key);
          }) :
          <div className="no-data-found">
            {this.props.report.jobId && this.props.report.links &&
              <><BlockIcon className="block-icon"/> No data found to display this report</>
            }
          </div>
        }
        {this.props.reportDetails && (this.props.reportDetails.numResults === 0) &&
          <div className="no-result">
            There is no data for this view.
          </div>
        }
      </div>
    );
  }

  removeColumn = (columnMeta: ColumnMeta) => () => {
    this.props.removeColumn(columnMeta);
  }

  renderRemoveColumnButton(columnMeta: ColumnMeta) {
    return (
      <span
        className="remove-column-btn"
        onClick={this.removeColumn(columnMeta)}
        role="button"
        aria-roledescription="Removes the Column"
      >
        <i className="icon icon-closed" />
      </span>
    );
  }

  renderSortStatus(orderBys: OrderBy[], columnMeta: ColumnMeta) {
    let orderBy = orderBys.find((ob: OrderBy) => {
      return orderByEqualsColumnMeta(ob, columnMeta);
    });

    if (orderBy) {
      return (
        <span
          className="order"
          role="button"
          aria-roledescription="Sorts the Column"
        >
        {orderBy.direction === ORDER_ASC &&
          <i className="fa fa-chevron-up" />
        }
        {orderBy.direction === ORDER_DESC &&
        <i className="fa fa-chevron-down" />
        }
        </span>
      );
    }

    return null;
  }

  renderRow(row: ReportRow, rowIndex: number) {

    return (
      <div className="result-row" key={rowIndex} role="row">
      {
        map(row.cells, (cell: ReportCell, columnIndex: number) => {
          return this.renderCell(cell, rowIndex, columnIndex);
        })
      }
      </div>
    );
  }

  renderCell = (cell: ReportCell, rowIndex: number, columnIndex: number) => {
    const measure = (this.props.columnMeta[columnIndex]) ? this.props.columnMeta[columnIndex].measure : null;
    const cellIsEmpty = (cell.data.value === null || cell.data.value === '');
    return (
      <div
        key={columnIndex}
        className="result-col"
        role="cell"
      >
        {cell.data.value}
        {this.canDrillDown(measure) && !cellIsEmpty &&
          <DrilldownIcon
            onClick={() => {
              this.props.dispatch(showDrilldownModal({
                showDrilldownModal: true,
                currentDrilldownCol: columnIndex,
                currentDrilldownRow: rowIndex,
                currentDrilldownValue: Number(cell.data.value),
                columnMeta: this.props.columnMeta,
                report: this.props.report,
                views: this.props.views,
                query: this.props.query,
                reportTitle: this.props.reportTitle,
              }));
            }}
          />
        }
      </div>
    );
  }

  canDrillDown(measure: any) {
    return measure && measure.type && measure.hasDrilldown;
  }

  renderReadOnlyHeader(columnMetas: ColumnMeta[], headers: Array<ReportHeader>): any {
    // this will probably always become false, but just in case this is used in initial
    // report creation (where report data does not yet exist)
    const useColumnMeta = headers.length === 0;
    const columnMetasCopy = [...columnMetas];

    return (
      <div
        style={this.getListStyle()}
        className={'header-row'}
        role="rowheader"
        data-testid="header-row"
      >
        {
          useColumnMeta && columnMetas.map((cm: ColumnMeta, index: number) => {
            return (
              <div
                key={index}
                className={'header-col'}
                data-testid={'header-col-' + index}
              >
                {this.renderSortStatus(this.props.orderBys, cm)}
                <span
                  className="column-title"
                  onClick={this.sort(cm)}
                  role="columnheader"
                  data-testid={'column-title-' + index}
                >
                  {cm.displayLabel}
                </span>
              </div>
            );
          })
        }
        {
          !useColumnMeta && headers.map((header, index) => {
            const isCustomColumn = isValidUuid(header.dimensionName);
            let cm;

            if (!isCustomColumn) {
              // assuming that the headers are correctly sorted
              cm = columnMetasCopy.shift();
            }

            const onClick = isCustomColumn ? undefined : cm && this.sort(cm);

            return (
              <div
                key={index}
                className={'header-col'}
                data-testid={'header-col-' + index}
              >
                {cm && this.renderSortStatus(this.props.orderBys, cm)}
                <span
                  className="column-title"
                  onClick={onClick}
                  role="columnheader"
                  data-testid={'column-title-' + index}
                >
                  {isCustomColumn && header.displayName}
                  {cm && (cm.alias || cm.displayLabel)}
                </span>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderCachedTime = () => {

    if (!this.hasReport() && !this.props.report.cachedDate) {
      return null;
    }

    let message = <>Up to date</>;
    if (this.props.report.cachedDate) {
      // If we want consistency we can switch to format 'D-M-YYYY, h:mma'
      const cachedDate = moment(this.props.report.cachedDate).fromNow();
      message = (
        <>
          Cached at {cachedDate}
          &nbsp;
          <GenerateReportButton noCache={true} className="refresh-report-btn btn btn-outline-dark">
            <i className="fa fa-refresh" />
          </GenerateReportButton>
        </>
      );
    }

    return (
      <span className={'cached-time'}>
        {message}
      </span>
    );
  }

  hasReport(): boolean {
    return this.props.report && this.props.report.rows.length > 0;
  }

}

export default withReportContext(ReportComponent);
