import * as React from 'react';
import './style.css';
import { UncontrolledTooltip } from 'reactstrap';

export interface TooltipProps {
  id: string;
  text: string;
  placement: Popper.Placement;
  onTooltipClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

class Tooltip extends React.Component<TooltipProps> {
  constructor(props: TooltipProps) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="tooltip-wrapper"
          id={this.props.id}
          onClick={this.props.onTooltipClick}
        >
          <i className="icon-tooltips" />
        </div>
        <UncontrolledTooltip
          placement={this.props.placement}
          target={this.props.id}
        >
          {this.props.text}
        </UncontrolledTooltip>
      </React.Fragment>
    );
  }
}

export default Tooltip;
