import { Join } from '../types/joinModel';
import { View } from '../types/viewModel';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { GroupBy } from '../types/groupByModel';
import { Module } from '../types/moduleModel';
import { Filter } from '../types/filterModel';
import { OrderBy } from '../types/orderByModel';
import { ColumnMeta } from '../types/columnMetaModel';
import { SavedQuery } from '../types/savedQueryModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES, GENERATE_ACTION } from '../constants';
import { NotificationService } from '../services/notificationService';
import { AuthService } from '../services/authService';
import { Download } from '../types/downloadModel';
import { Chart } from '../types/chartModel';
import { CancelTokenSource } from 'axios';
import { ReportTable } from '../types/reportTableModel';
import { ReportPaginationData } from 'src/types/reportPaginationDataModel';
import { User } from '../types/userModel';
import { AppMenu } from '../types/appMenuModel';
import { DrilldownModalType } from '../types/drilldownModalModel';
import { SupportedCountriesType } from '../services/authService';
import { CustomColumn } from '../types/customColumnModel';
import { Transformation } from '../types/transformationModel';

import { AppNotification } from 'src/features/api-feedback';

export interface QueryForApi {
  module: Module;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  view: View;
  dimensions: Dimension[];
  joinDimensions: JoinAndDimensions[];
  measures: Measure[];
  joinMeasures: JoinAndMeasures[];
  groupBys: GroupBy[];
  filterGroups: Array<Array<Filter>>;
  filters: Filter[];
  orderBys: OrderBy[];
  offset: number;
  columnMeta: ColumnMeta[];
  reportLimit: number;
  customColumns: Array<CustomColumn>;
  transformations: Array<Transformation>;
}

export interface AppState extends QueryForApi {
  appMenu: AppMenu;
  modules: Module[];
  query: string;
  views: View[];
  report: ReportTable;
  filterOptions: ViewJoinAndDimensionOrMeasure[];
  saveQuery: SaveQueryState;
  reportDetails: ReportDetails | null;
  notifications: AppNotification[];
  notificationService: NotificationService | null;
  authService: AuthService | null;
  downloads: Download[];
  chart: Chart;
  suggestedReports: SuggestedReportState;
  reportTitle: string;
  generateReportStatus: GENERATE_ACTION;
  storeCancelSource: CancelTokenSource | null;
  tempQuery: SavedQuery | null;
  savedQuery: SavedQuery | null;
  selectedCountries: SupportedCountriesType;
  reportPaginationData: ReportPaginationData;
  routes: any;
  user: User | null;
  translations: any;
  drilldownModal: DrilldownModalType;
}

export interface SaveQueryState {
  saving: boolean;
  showSaveError: boolean;
}

export function getInitialSaveQueryState(): SaveQueryState {
  return {
    saving: false,
    showSaveError: false
  };
}

export interface JoinAndDimensions {
  join: Join;
  dimensions: Dimension[];
}

export interface JoinAndMeasures {
  join: Join;
  measures: Measure[];
}

export interface ReportRow {
  columnData: Array<string | number>;
}

/**
 * @TODO: Move this out to Models
 */
export interface DrilldownReport {
  rowIndex: number;
  cellIndex: number;
  report: ReportTable;
  offset: number;
  limit: number;
}

export interface ViewJoinAndDimensionOrMeasure {
  view: View;
  dimension?: Dimension;
  measure?: Measure;
  join?: Join;
}

export interface ReportDetails {
  numPages: number;
  numResults: number;
}

export interface SuggestedReportState {
  tags: string[];
  tagLabels: string[];
  suggestions: SavedQuery[];
  loading: boolean;
  isOpen: boolean;
}

export function getInitialSuggestedReportsState() {
  return {
    tags: [],
    tagLabels: [],
    suggestions: [],
    loading: false,
    isOpen: false
  };
}

export function getInitialReportPaginationDataState(): ReportPaginationData {
  return {
    next: false,
    prev: false
  };
}
