import { BaseModel, NestedEntityMap } from './baseModel';
import { ReportRow } from './reportRowModel';
import { ReportPaginationData } from 'src/types/reportPaginationDataModel';
import { ReportHeader } from './reportHeaderModel';

export class ReportTable extends BaseModel {
  rows: ReportRow[];
  headers: Array<ReportHeader>;
  cachedDate?: string;
  links?: ReportPaginationData;
  jobId?: number;

  protected nestedObjects: NestedEntityMap = {
    rows: ReportRow
  };

  constructor(data?: any, cachedDate?: string, links?: ReportPaginationData, jobId ?: number) {
    super(data);
    this.rows = [];
    this.headers = [];
    this.hydrate(data);

    if (cachedDate) {
      this.cachedDate = cachedDate;
    }

    if (links) {
      this.links = links;
    }

    if (jobId) {
      this.setJobId(jobId) ;
    }

    if (data && data.headers) {
      this.headers = data.headers;
    }
  }

  setJobId(jobId?: number) {
    this.jobId = jobId;

    return this;
  }
}