// Filter types
export const FILTER_TYPE_EQUALS = 'EQUALS';
export type FILTER_TYPE_EQUALS = typeof FILTER_TYPE_EQUALS;
export const FILTER_TYPE_GREATER_THAN = 'GREATER THAN';
export type FILTER_TYPE_GREATER_THAN = typeof FILTER_TYPE_GREATER_THAN;
export const FILTER_TYPE_LESS_THAN = 'LESS THAN';
export type FILTER_TYPE_LESS_THAN = typeof FILTER_TYPE_LESS_THAN;
export const FILTER_TYPE_BETWEEN = 'BETWEEN';
export type FILTER_TYPE_BETWEEN = typeof FILTER_TYPE_BETWEEN;
export const FILTER_TYPE_CONTAINS = 'CONTAINS';
export const FILTER_TYPE_NOT_CONTAINS = 'NOT CONTAINS';
export const FILTER_TYPE_LIKE = 'LIKE';
export type FILTER_TYPE_LIKE = typeof FILTER_TYPE_LIKE;
export const FILTER_TYPE_NOT_LIKE = 'NOT LIKE';
export type FILTER_TYPE_NOT_LIKE = typeof FILTER_TYPE_NOT_LIKE;
export const FILTER_TYPE_NOT_EQUALS = 'NOT EQUALS';
export type FILTER_TYPE_NOT_EQUALS = typeof FILTER_TYPE_NOT_EQUALS;
export const FILTER_TYPE_IS_BLANK = 'IS BLANK';
export type FILTER_TYPE_IS_BLANK = typeof FILTER_TYPE_IS_BLANK;
export const FILTER_TYPE_NOT_BLANK = 'NOT BLANK';
export type FILTER_TYPE_IN = typeof FILTER_TYPE_IN;
export const FILTER_TYPE_IN = 'IN';
export type FILTER_TYPE_NOT_IN = typeof FILTER_TYPE_NOT_IN;
export const FILTER_TYPE_NOT_IN = 'NOT IN';

export type FILTER_TYPE_NOT_BLANK = typeof FILTER_TYPE_NOT_BLANK;
export type FILTER_TYPE =
  | FILTER_TYPE_EQUALS
  | FILTER_TYPE_GREATER_THAN
  | FILTER_TYPE_LESS_THAN
  | FILTER_TYPE_BETWEEN
  | FILTER_TYPE_LIKE
  | FILTER_TYPE_NOT_LIKE
  | FILTER_TYPE_NOT_EQUALS
  | FILTER_TYPE_IS_BLANK
  | FILTER_TYPE_NOT_BLANK
  | FILTER_TYPE_IN
  | FILTER_TYPE_NOT_IN;

export const FILTER_DATE_CONTEXT_LAST = 'LAST';
export type FILTER_DATE_CONTEXT_LAST = typeof FILTER_DATE_CONTEXT_LAST;
export const FILTER_DATE_CONTEXT_CURRENT = 'CURRENT';
export type FILTER_DATE_CONTEXT_CURRENT = typeof FILTER_DATE_CONTEXT_CURRENT;
export const FILTER_DATE_CONTEXT_NEXT = 'NEXT';
export type FILTER_DATE_CONTEXT_NEXT = typeof FILTER_DATE_CONTEXT_NEXT;
export const FILTER_DATE_CONTEXT_DATE = 'DATE';
export type FILTER_DATE_CONTEXT_DATE = typeof FILTER_DATE_CONTEXT_DATE;
export type FILTER_DATE_CONTEXT = FILTER_DATE_CONTEXT_LAST | FILTER_DATE_CONTEXT_CURRENT | FILTER_DATE_CONTEXT_NEXT
  | FILTER_DATE_CONTEXT_DATE;

export const FILTER_PERIOD_DAY = 'DAY';
export type FILTER_PERIOD_DAY = typeof FILTER_PERIOD_DAY;
export const FILTER_PERIOD_WEEK = 'WEEK';
export type FILTER_PERIOD_WEEK = typeof FILTER_PERIOD_WEEK;
export const FILTER_PERIOD_MONTH = 'MONTH';
export type FILTER_PERIOD_MONTH = typeof FILTER_PERIOD_MONTH;
export const FILTER_PERIOD_QUARTER = 'QUARTER';
export type FILTER_PERIOD_QUARTER = typeof FILTER_PERIOD_QUARTER;
export const FILTER_PERIOD_YEAR = 'YEAR';
export type FILTER_PERIOD_YEAR = typeof FILTER_PERIOD_YEAR;
export const FILTER_SOURCE_DRILLDOWN = 'SOURCE_DRILLDOWN';
