import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const SelectFieldIcon = ({ className, width = 12, height = 9, color = '#1765AD' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.145 0.933238L4.13557 8.08573L0.859375 4.74274L1.81165 3.80949L4.13556 6.18078L10.1927 0L11.145 0.933238Z"
        fill={color}
      />
    </svg>
  );
};
