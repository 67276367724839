import Report from '../../containers/Report';
import * as React from 'react';
import './style.css';
import FilterSelect from '../../containers/FilterSelect';
import { SavedQuery } from '../../types/savedQueryModel';
import ChartSelect from '../../containers/ChartSelect';
import PageTitle from '../../shared-components/PageTitle';
import { View } from '../../types/viewModel';
import { GENERATE_ACTION } from '../../constants';
import { ReportTable } from '../../types/reportTableModel';
import { Chart } from '../../types/chartModel';
import SaveQuery from '../../containers/SaveQuery';
import ActionButtons from '../../containers/ActionButtons';

interface ReportViewOnlyPageProps {
  loadedQuery?: SavedQuery;
  encodedQuery: string | undefined;
  selectedView: View;
  suggestionsOpen: boolean;
  generateReportStatus: GENERATE_ACTION;
  reportResult: ReportTable;
  chart: Chart;
  queryId: string;
}

export class ReportViewOnlyPage extends React.Component<ReportViewOnlyPageProps> {

  protected filterSelectRef: any;

  bindFilterSelectRef = (instance: any) => {
    this.filterSelectRef = instance;
  }

  render() {
    const { encodedQuery, queryId, loadedQuery, chart } = this.props;

    return (
      <div className="reporting-read-only-page">
        <div className="row">
          <div className="col-12">
            <PageTitle title="Report Builder" />
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col right-col">
            <div className="buttons">
              <div className="left"/>
              <div className="right">
                <SaveQuery disabled={!encodedQuery} />
                <ActionButtons
                  disabled={!encodedQuery}
                  queryId={queryId}
                  loadedQuery={loadedQuery}
                />
              </div>
            </div>

            <div className="scroll">
              <FilterSelect
                queryIsLoaded={!!loadedQuery}
                ref={this.bindFilterSelectRef}
              />
              {chart.chartId &&
                <ChartSelect loadedQuery={loadedQuery} readOnly={true}/>
              }
              <Report
                loadedQuery={loadedQuery}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
