import * as React from 'react';
import ElmoSearch from '../ElmoSearch';

export interface ColumnSelectFilterProps {
  onFilterUpdate: (text: string) => void;
}

interface ColumnSelectFilterState {
  filterText: string;
}

/**
 * The grey header with checkbox, title and caret icon, and a collapse which displays the children
 */
class ColumnSelectFilter extends React.Component<ColumnSelectFilterProps, ColumnSelectFilterState> {

  constructor(props: ColumnSelectFilterProps) {
    super(props);

    this.state = {
      filterText: ''
    };
  }

  onFilterChange = (value: string) => {
    this.props.onFilterUpdate(value);

    this.setState({
      filterText: value
    });
  }

  onFilterClear = () => {
    this.setState({
      filterText: ''
    });

    this.props.onFilterUpdate('');
  }

  render() {
    return (
      <ElmoSearch
        isSubmitDisabled={true}
        onChange={this.onFilterChange}
        value={this.state.filterText}
        placeholder="Search..."
      />
    );
  }
}

export default ColumnSelectFilter;
