import { Field } from '@eds/field';
import { SelectInput } from '@eds/select-input';
import {
 Control, Controller, FieldValues, Path,
} from 'react-hook-form';
import * as React from 'react';
import * as _ from 'lodash';

type SelectInputFieldProps<T extends FieldValues> = {
    control: Control<T>;
    errors: Record<string, unknown>;
    id: Path<T>;
    label: string;
    items: any[];
    initialValue?: T[keyof T];
    isClearable?: boolean;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    dangerouslyIgnoreLastPass?: boolean;
};

export const SelectInputField = <T extends FieldValues, >({
     control,
     errors,
     id,
     label,
     items,
     initialValue,
     isClearable = false,
     placeholder,
     required = false,
     disabled = false,
     dangerouslyIgnoreLastPass = false,
}: SelectInputFieldProps<T>) => {
    const error = _.get(errors, id) as Error;
    const isInvalid = !!error;
    const errorMessage = error && error.message.toString();

    const allItems = items.reduce(
      (result, item) => {
        return [
          ...result,
          ...item.options || [item],
        ];
      },
      [] as Array<any>,
    );

    return (
        <Field label={label} id={id} invalid={isInvalid} message={errorMessage} required={required} disabled={disabled}>
            <Controller
                name={id}
                control={control}
                defaultValue={initialValue}
                render={({ field: { onChange, value } }) => (
                    <SelectInput
                        invalid={isInvalid}
                        items={items}
                        size="small"
                        placeholder={placeholder}
                        onChange={(item) => onChange(item.value)}
                        value={allItems.find((item: { value: string }) => item.value === value)}
                        isClearable={isClearable}
                        dangerouslyIgnoreLastPass={dangerouslyIgnoreLastPass}
                    />
                )}
            />
        </Field>
    );
};
