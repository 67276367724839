import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, DrilldownReport } from '../../redux';
import DrilldownDisplayModal from '../../components/DrilldownDisplayModal';
import { getDrilldown, queueDrilldownExport } from '../../actions/report';
import {
  DRILLDOWN_DEFAULT_LIMIT, GENERATE_REPORT_POLL_DELAY,
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS
} from '../../constants';
import { renderDownloadsButton } from '../../components/DownloadsButton';
import { NotificationService } from '../../services/notificationService';
import { apiGetQueuedReportStatus } from '../../services/reportService';
import { JOB_STATUS_SUCCESS } from '../../constants/jobStatus';
import { sleep } from '../../services/utilityService';
import { DrilldownModalType } from '../../types/drilldownModalModel';
import { closeDrilldownModal } from '../../actions/drilldownModal';

export interface DrilldownModalProps {
  dispatch: Dispatch<any>;
  // From redux store
  drilldownModal: DrilldownModalType;
  // From redux store
  notificationService: NotificationService;
}

export interface DrilldownModalState {
  offset: number;
  limit: number;
  drilldownReport: DrilldownReport | null;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  drilldownModal: state.drilldownModal,
});

class DrilldownModalContainer extends React.Component<DrilldownModalProps, DrilldownModalState> {
  protected shouldPollForStatus: boolean = false;

  constructor(props: DrilldownModalProps) {
    super(props);

    this.state = {
      offset: 0,
      limit: DRILLDOWN_DEFAULT_LIMIT,
      drilldownReport: null
    };
  }

  componentDidUpdate(prevProps: DrilldownModalProps) {
    const { showDrilldownModal } = this.props.drilldownModal;
    const { showDrilldownModal: prevShowDrilldownModal } = prevProps.drilldownModal;

    if (showDrilldownModal && (showDrilldownModal !== prevShowDrilldownModal)) {
      this.setState(
      {
        offset: 0,
        limit: DRILLDOWN_DEFAULT_LIMIT,
        drilldownReport: null
      },
      () => {
        this.getDrilldown();
      });
    }

    if (!showDrilldownModal && (showDrilldownModal !== prevShowDrilldownModal)) {
      this.shouldPollForStatus = false;
    }
  }

  componentWillUnmount() {
    this.shouldPollForStatus = false;
  }

  render() {
    const { showDrilldownModal } = this.props.drilldownModal;
    const { drilldownReport } = this.state;
    const hasFanOut = this.hasFanOut();

    return (
      <DrilldownDisplayModal
        data={drilldownReport}
        showModal={showDrilldownModal}
        toggleModal={() => this.props.dispatch(closeDrilldownModal())}
        onPageChange={this.onPageChange}
        showFanOutMessage={hasFanOut}
        export={this.queueDrilldownExport}
      />
    );
  }

  queueDrilldownExport = async () => {
    const {
      notificationService,
      drilldownModal: {
        query,
        report,
        views,
        columnMeta,
        reportTitle,
        currentDrilldownCol,
        currentDrilldownRow
      }
    } = this.props;

    const drilldownTitle = reportTitle ? reportTitle + ' drilldown' : undefined;

    const result = await queueDrilldownExport(
      currentDrilldownRow, currentDrilldownCol, columnMeta, report, views, query, drilldownTitle);

    if (result) {
      const drilldownDisplayTitle = reportTitle ? '<' + reportTitle + ' drilldown>'
        : '<untitled drilldown>';
      notificationService.addNotification(
        NOTIFICATION_SUCCESS,
        'Your ' + drilldownDisplayTitle + ' report is being created. To see its progress go to the downloads page.',
        renderDownloadsButton()
      );
    } else {
      notificationService.addNotification(
        NOTIFICATION_ERROR,
        'There was an issue creating the report drilldown.'
      );
    }
  }

  hasFanOut = (): boolean => {
    const { currentDrilldownValue } = this.props.drilldownModal;
    const { drilldownReport } = this.state;
    return drilldownReport !== null
      && currentDrilldownValue > 0
      && drilldownReport.report.rows.length > currentDrilldownValue;
  }

  onPageChange = (offset: number, limit: number) => {
    this.setState(
      {
        offset: offset,
        limit: limit
      },
      () => {
        this.getDrilldown();
      }
    );
  }

  getDrilldown = async () => {
    const { currentDrilldownRow, currentDrilldownCol, columnMeta, views, report, query } = this.props.drilldownModal;
    const { offset, limit } = this.state;

    // Send request to get drilldown data
    const jobId = await getDrilldown(
      currentDrilldownRow,
      currentDrilldownCol,
      offset,
      limit,
      columnMeta,
      report,
      views,
      query
    );

    if (!this.props.drilldownModal.showDrilldownModal) {
      return;
    }

    this.shouldPollForStatus = true;

    let drilldownReport = null;
    // poll for a response
    while (this.shouldPollForStatus && this.props.drilldownModal.showDrilldownModal) {
      const queueResult = await apiGetQueuedReportStatus(jobId);

      if (queueResult.status === JOB_STATUS_SUCCESS && queueResult.reportTable) {
        // Check that the result has come back for the correct row, col and query.
        const isResultForSameDrilldown =
          (currentDrilldownRow === this.props.drilldownModal.currentDrilldownRow) &&
          (currentDrilldownCol === this.props.drilldownModal.currentDrilldownCol);

        if (isResultForSameDrilldown && this.props.drilldownModal.showDrilldownModal) {
          this.shouldPollForStatus = false;
          drilldownReport = queueResult.reportTable;
        }
        break;
      }

      await sleep(GENERATE_REPORT_POLL_DELAY);
    }

    if (!drilldownReport) {
      return;
    }

    this.setState({
      drilldownReport: {
        rowIndex: currentDrilldownRow,
        cellIndex: currentDrilldownCol,
        report: drilldownReport,
        offset: offset,
        limit: limit
      }
    });
  }
}

const DrilldownModal = connect(
  mapStateToProps)(DrilldownModalContainer);
export default DrilldownModal;
