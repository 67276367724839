import { useEffect } from 'react';
import { UseFormReset, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ShareReportFieldValues, shareReportSchema } from './share-report.validation';

const defaultValues: ShareReportFieldValues = { id: '', targetUsers: [] };

export const useShareReportForm = () =>
  useForm<ShareReportFieldValues>({ defaultValues, resolver: yupResolver(shareReportSchema) });

export const useFormValuesInitializer = (
  reset: UseFormReset<ShareReportFieldValues>,
  id: string,
  targetUsers: string[]
) =>
  useEffect(() => {
    if (!id) return reset(defaultValues);

    const values: ShareReportFieldValues = { id, targetUsers: targetUsers.map(value => ({ label: '', value })) };
    reset(values);
  }, [id, targetUsers]);
