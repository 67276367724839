import * as React from 'react';
import { AppState } from '../../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Toggle from '../../shared-components/Toggle';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES } from '../../constants';
import { storeDataType } from '../../actions/module';

interface DataTypeSelectProps {
  dispatch: Dispatch<any>;
  selected: string;
  disabled: boolean;
}

const mapStateToProps = (state: AppState) => ({
  selected: state.dataType
});

class DataTypeSelect extends React.Component<DataTypeSelectProps> {
  render() {
    let toggleLabel = (
      <React.Fragment>
        Aggregate Data
      </React.Fragment>
    );

    return (
      <Toggle
        label={toggleLabel}
        onChange={this.onChange}
        checked={this.props.selected === DATA_TYPE_MEASURES}
        disabled={this.props.disabled}
        switchSize="small"
      />
    );
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES =
      e.target.checked ? DATA_TYPE_MEASURES : DATA_TYPE_DIMENSIONS;
    this.props.dispatch(storeDataType(dataType));
  }
}

export default connect(
  mapStateToProps)(DataTypeSelect);
