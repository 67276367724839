import React from 'react';
import { ControlsProps } from '@eds/modal';

import { AlertModal } from 'src/features/ui';
import { Text } from '@eds/text';

type LegacyReportInfoProps = {
  isOpen: boolean;
  onClose: () => void;
};

const description = `This report is no longer supported as it was created before key upgrades to our analytics engine
  were applied. To take advantage of ongoing improvements such as enhanced field availability, improved filtering, and
  faster load times, we suggest generating a new report and deleting this one after it becomes redundant.`;

export const LegacyReportInfo = ({ isOpen, onClose }: LegacyReportInfoProps) => {
  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: {
      label: 'Recreate this report',
      onClick: () => window.location.assign('/report'),
      tone: 'primary',
    },
  };

  return (
    <AlertModal
      controls={controls}
      description={
        <Text hyphens="none" overflowY="auto" color="neutralSubtle" lineHeight="loose">
          {description}
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      title="Action required"
    />
  );
};
