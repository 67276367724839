import React from 'react';
import { Box } from '@eds/box';
import { DateValidation } from '@eds/date-input';
import { Divider } from '@eds/divider';
import { Field } from '@eds/field';
import { Fieldset } from '@eds/fieldset';
import { Flex } from '@eds/flex';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/css';

import { AuthService } from 'src/services/authService';
import { DateInput, SelectInput, TextInput, cssOverrides } from 'src/features/ui';
import { occurrences } from 'src/features/scheduled-reports';

import { ScheduledReportFieldValues } from './scheduled-reports-modal.validation';
import { calendarDays, daysOfTheWeek, timeOptions } from './scheduled-reports-modal.values';

type SchedulerSectionProps = {
  errors: FieldErrors<ScheduledReportFieldValues>;
  isSubmitted: boolean;
  legend: string;
  timezone?: string;
} & Pick<UseFormReturn<ScheduledReportFieldValues>, 'control' | 'setValue' | 'trigger' | 'watch'>;

export const SchedulerSection = ({
  control,
  errors,
  isSubmitted,
  legend,
  setValue,
  timezone = AuthService.getInstance().getUserTimezone() || '',
  trigger,
  watch,
}: SchedulerSectionProps) => {
  const onBlur = ({ validationError }: DateValidation) => {
    setValue('shouldValidateEndDate', !!validationError);
    if (isSubmitted) trigger('endDate');
  };

  return (
    <Fieldset legend={legend} legendStyle="group">
      <Field invalid={!!errors.name} label="Schedule name" message={errors.name?.message} required>
        <TextInput control={control} name="name" placeholder="Schedule name" />
      </Field>
      <Field label="Occurrence" required>
        <SelectInput control={control} isClearable={false} items={occurrences} name="occurrence" />
      </Field>
      <Flex flexDirection={{ container: { xsmall: 'column', small: 'row' } }} gap={{ container: { small: 'large' } }}>
        <Box flex={1} className={css(cssOverrides.button('date-input'))}>
          <Field
            invalid={!!errors.startDate}
            label={watch('occurrence').value === 'once' ? 'Date' : 'Start date'}
            message={errors.startDate?.message}
            required
          >
            <DateInput control={control} name="startDate" />
          </Field>
        </Box>
        <Box flex={1} className={css(cssOverrides.button('date-input'))}>
          <Box display={watch('occurrence').value === 'once' ? 'none' : undefined}>
            <Field invalid={!!errors.endDate} label="End date" message={errors.endDate?.message}>
              <DateInput control={control} name="endDate" onBlur={onBlur} />
            </Field>
          </Box>
        </Box>
      </Flex>
      <Flex flexDirection={{ container: { xsmall: 'column', small: 'row' } }} gap={{ container: { small: 'large' } }}>
        <Box flex={1}>
          <Field
            invalid={!!errors.time}
            label={`Time${timezone && ` (${timezone})`}`}
            message={errors.time?.message}
            required
          >
            <SelectInput control={control} items={timeOptions} name="time" placeholder="hh:mm" />
          </Field>
        </Box>
        <Box flex={1}>
          <Box display={watch('occurrence').value !== 'monthly' ? 'none' : undefined}>
            <Field
              invalid={!!errors.calendarDay}
              label="Day of the month"
              message={errors.calendarDay?.message}
              required
            >
              <SelectInput control={control} items={calendarDays} name="calendarDay" />
            </Field>
          </Box>
          <Box display={watch('occurrence').value !== 'weekly' ? 'none' : undefined}>
            <Field
              invalid={!!errors.daysOfTheWeek}
              label="Day(s) of the week"
              message={errors.daysOfTheWeek?.message}
              required
            >
              <SelectInput control={control} isMulti items={daysOfTheWeek} limit={1} name="daysOfTheWeek" />
            </Field>
          </Box>
        </Box>
      </Flex>
      <Divider />
    </Fieldset>
  );
};
