import * as React from 'react';

type Props = {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
};

function ModalTitle({ title, subtitle }: Props) {
  return (
    <div className="modal-title">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );
}

export default ModalTitle;