import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import debounce from 'lodash/debounce';
import { AppState } from '../redux';
import { NotificationService } from './notificationService';
import { NOTIFICATION_ERROR } from '../constants';
import { AuthService } from './authService';
import { forEach } from 'lodash';
import { generateReportFinished } from '../actions/report';
import { redirect } from './utilityService';
import { errorRoutes } from '../routes/error';

const DEBOUNCE_WAIT_MILLISECONDS = 500;
const AUTH_URL = '/oauth2-client/auth/check-token';

export class ApiService {
  protected notificationService: NotificationService;
  protected authService: AuthService;
  constructor(notificationService: NotificationService, authService: AuthService) {
    this.notificationService = notificationService;
    this.authService = authService;
  }

  public getNotificationService() {
    return this.notificationService;
  }

  protected refreshTokenData = debounce(() => this.authService.apiIsBackendLoggedIn(true), DEBOUNCE_WAIT_MILLISECONDS, {
    leading: true,
    trailing: false,
  });

  protected requestInterceptorOnFulfilled = (store: Store<AppState>) => async (config: AxiosRequestConfig) => {

    config.withCredentials = true;

    // send X-Requested-With header by default as OAuth2 library uses this to identify API requests
    if (!config.headers) {
      config.headers = {}; // if no headers, set an empty object so we can use Object.assign to set X-Requested-With
    }

    // add the X-Requested-With to the header, used by the OAuth library
    if (config.headers && !config.headers['X-Requested-With']) {
      config.headers = Object.assign(config.headers, {
        'X-Requested-With': 'XMLHttpRequest'
      });
    }

    if (config.url === AUTH_URL) {
      return config;
    }

    const tokenData = this.authService.getToken();

    if (!tokenData) {
      return config;
    }

    if (tokenData.loginExpiry < Math.round(Date.now() / 1000)) {
      await this.refreshTokenData();
    }

    return config;
  }

  protected requestInterceptorOnError = (error: any) => {
    return Promise.reject(error);
  }

  protected responseInterceptorOnFulfilled = (store: Store<AppState>) => (response: AxiosResponse) => {
    return response;
  }

  protected responseInterceptorOnError = (store: Store<AppState>) => (error: any) => {
    if (axios.isCancel(error)) {
      // don't show any error notifications
      store.dispatch(generateReportFinished());

      // throw the error so that axios.isCancel can be used in the calling function's "catch" block.
      throw error;
    } else if (error.response && (error.response.status === 401)) {
      // if an unauthorized error occurs,
      // or if a CORS happens (TMS SSO way of saying you're now logged out)
      // redirect to the login page
      this.authService.clearTokenAndRedirectToLoginPage();
      return Promise.reject(error);
    } else if (!error.response && !axios.isCancel(error)) {
      redirect(errorRoutes.fatalError.path);
    } else if (error.response && (error.response.status === 403)) {
      redirect(errorRoutes.forbidden.path);
      return Promise.reject(error);
    } else if (error.response && error.response.status === 503) {
      redirect(errorRoutes.fatalError.path);
    }

    this.createErrorNotification(error);

    return Promise.reject(error);
  }

  /**
   * Create notification from error object
   * @param error
   */
  createErrorNotification(error: any) {
    let errorString: string = error.response ? error.response.data['message'] : 'An unknown error has occurred.';

    let isJson: boolean = true;
    let errorJson = null;

    try {
      errorJson = JSON.parse(errorString);
    } catch (e) {
      isJson = false;
    }

    if (isJson && errorJson) {
      errorString = '';
      forEach(errorJson, (value: string, key: string) => {
        if (key) {
          errorString += key + ' : ';
        }
        errorString += value;
      });
    }

    this.notificationService.addNotification(NOTIFICATION_ERROR, errorString);
  }

  /**
   * Intercept the request and response.
   * @param {Store<AppState>} store
   */
  public addApiInterceptors(store: Store<AppState>) {
    axios.interceptors.request.use(
      this.requestInterceptorOnFulfilled(store),
      this.requestInterceptorOnError
    );

    /**
     * The response interceptor comes in BEFORE it goes to the then/catch block of the calling function
     * e.g. This will run first, before it does the "then" in apiGetReport.
     */
    axios.interceptors.response.use(
      this.responseInterceptorOnFulfilled(store),
      this.responseInterceptorOnError(store)
    );
  }
}
