import * as React from 'react';
import classnames from 'classnames';
import { Button } from 'elmo-elements';
import { ButtonProps } from 'elmo-elements/Button';
import './style.css';

interface Props {
  isActive?: boolean;
  isIcon?: boolean;
  type?: 'text' | ButtonProps['type'];
}

export type ElmoButtonProps = Pick<ButtonProps, Exclude<keyof ButtonProps, 'type'>> & Props;

const ElmoButton: React.FC<ElmoButtonProps> = ({
  children,
  className,
  isActive = false,
  isIcon = false,
  isUppercase = false,
  type = 'default',
  ...props
}) => {
  return (
    <Button
      className={
        classnames('elmo-btn--override', className, {
          'elmo-btn--text': type === 'text',
          'active': isActive,
          'icon-btn': isIcon
        })
      }
      isUppercase={isUppercase}
      type={type === 'text' ? 'default' : type}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ElmoButton;
