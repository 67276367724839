import { backgroundColor } from '@eds/core';

export const content = () => {
  return {
    '& + .elmo-layout__main-content-wrapper .elmo-layout__main-content': {
      height: '100%',
      padding: 0,
    },
    '& + .elmo-layout__main-content-wrapper .elmo-layout__main-content .container-fluid': {
      backgroundColor: backgroundColor.neutral,
      height: '100%',
      padding: 0,
    },
  };
};

export const errorPage = {
  height: '100vh',
  marginTop: '-20px',
  marginBottom: '-20px',
};
