import * as React from 'react';
import { useEffect, useState } from 'react';

import { useUnselectedFieldOptions, useViews } from 'src/features/report-builder';

import { AppState } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import FilterSelectComponent from '../../components/NewReportBuilder/FilterSelect';
import { Filter } from '../../types/filterModel';
import { replaceFilters } from '../../actions/filter';
import { filterIsValid, getIndexInFilterOptions } from '../../services/filterService';
import usePrevious from '../../components/hooks/usePrevious';

interface FilterSelectProps {
  canManageFilter?: boolean;
  isOpen: boolean;
  toggle: () => void;
}

const FilterSelect = ({ canManageFilter = false, isOpen, toggle }: FilterSelectProps) => {
  const { filterOptions, filters, module, savedQuery } = useSelector((state: AppState) => state);
  const { availableViews, currentView: view } = useViews();
  const dispatch = useDispatch();

  const [displayFilters, setDisplayFilters] = useState<Array<Filter>>([]);

  const prevSavedQuery = usePrevious(savedQuery);
  const prevModuleName = usePrevious(module.name);
  const prevViewName = usePrevious(view.name);
  const prevFilterOptions = usePrevious(filterOptions) || [];

  const clearDisplayFilters = () => setDisplayFilters([]);

  const options = useUnselectedFieldOptions(view, availableViews);

  useEffect(
    () => {
      // when a query is loaded, load the display filters from it
      if (!prevSavedQuery && savedQuery) {
        setDisplayFilters(filters);
        return;
      }

      // if the selected module or view has changed, then clear all the display filters
      const moduleChanged = prevModuleName !== module.name;
      const viewChanged = prevViewName !== view.name;
      
      if ((moduleChanged || viewChanged) && !savedQuery) {
        clearDisplayFilters();
      }

      // if the filter options change, find the removed filter options
      if (prevFilterOptions !== filterOptions && prevFilterOptions.length > filterOptions.length) {
        const newDisplayFilters = displayFilters.filter(filter => {
          const dimensionIdentifier = `${filter.joinName ? `${filter.joinName}.` : `${filter.viewName}.`}${
            filter.measureName || filter.dimensionName
          }`;

          return (
            getIndexInFilterOptions(filterOptions, filter) !== -1 ||
            options.some(({ value }) => dimensionIdentifier === value)
          );
        });

        setDisplayFilters(newDisplayFilters);
        dispatch(replaceFilters(newDisplayFilters.filter(filterIsValid)));
      }
    },
    [savedQuery, module.name, view.name, filterOptions]
  );

  const saveDisplayFilter = (filter: Filter, index: number, store: boolean = true) => {
    const newDisplayFilters = [...displayFilters];

    newDisplayFilters[index] = filter;
    setDisplayFilters(newDisplayFilters);

    if (store) {
      dispatch(replaceFilters(newDisplayFilters.filter(filterIsValid)));
    }
  };

  const removeFilter = (index: number) => {
    const newDisplayFilters = [...displayFilters];
    
    newDisplayFilters.splice(index, 1);
    setDisplayFilters(newDisplayFilters);
    dispatch(replaceFilters(newDisplayFilters.filter(filterIsValid)));
  };

  return (
    <FilterSelectComponent
      isOpen={isOpen}
      toggle={toggle}
      saveDisplayFilter={saveDisplayFilter}
      displayFilters={displayFilters}
      removeFilter={removeFilter}
      canManageFilter={canManageFilter}
    />
  );
};

export default FilterSelect;