import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const EditIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2563 4.69167L15.3063 2.74167C15.1396 2.575 14.9313 2.5 14.7146 2.5C14.4979 2.5 14.2896 2.58333 14.1313
         2.74167L12.6063 4.26667L15.7313 7.39167L17.2563 5.86667C17.5813 5.54167 17.5813 5.01667 17.2563
          4.69167ZM2.49805 14.375V17.5H5.62305L14.8397 8.28334L11.7147 5.15834L2.49805 14.375ZM4.16471
          15.8333H4.93138L12.4814 8.28332L11.7147 7.51666L4.16471 15.0667V15.8333Z"
        fill="#212121"
      />
    </svg>
  );
};
