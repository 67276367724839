import * as React from 'react';
import { FormItem, Heading, Row, Col, Select } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';

import { CHART_TYPE_TEXT } from '../../../constants';
import { ChartForm, FormError } from './types';

import './style.css';

type ConfigurationGroupProps = {
  formData: ChartForm;
  formError: FormError;
  handleFormDataChange: (formData: ChartForm) => void;
};

const limitOptions: Array<number> = [0, 5, 10, 15, 20];

const ConfigurationGroup = (props: ConfigurationGroupProps) => {
  const renderLimitOptions = () => {
    return limitOptions.map((option) => {
      return { 
        value: option.toString(),
        label: option === 0 ? 'No limit' : option.toString()
      };
    });
  };

  const getLimitOptionValue = () => {
    const limitOption = limitOptions.find((option) => option === props.formData.limit);
    return limitOption ? { value: limitOption.toString(), label: limitOption.toString() } : renderLimitOptions()[0];
  };

  const changeLimitOption = (event: SelectPropsOption) => {
    const formData = {
      ...props.formData,
      limit: parseInt(event.value, 10),
    };
    props.handleFormDataChange(formData);
  };

  if (props.formData.type === CHART_TYPE_TEXT) {
    return null;
  }

  return (
    <Row>
      <Col>
        <Heading htmlTag="h4" type="title" size="sm">Configuration</Heading>
      </Col>
      <Col>
        <FormItem
          label="Limit"
          message={!!props.formError.limit ? props.formError.limit : undefined}
          status={!!props.formError.limit ? 'error' : undefined}
        >
          <Select
            ariaLabel="Limit"
            name="limit"
            onChange={changeLimitOption}
            options={renderLimitOptions()}
            value={getLimitOptionValue()}
            placeholder="Choose limit"
            data-testid="chart-limit-select"
          />
        </FormItem>
      </Col>
    </Row>
  );
};

export default ConfigurationGroup;
