
import axios, { AxiosResponse } from 'axios';
import { merge } from 'lodash';
import * as highcharts from 'highcharts';

import { Chart } from '../types/chartModel';
import { columnMetaToColumnMetaYCompact, columnMetaToColumnMetaXCompact } from './columnMetaService';
import {
  CHART_TYPE,
  CHART_TYPE_BAR,
  CHART_TYPE_COLUMN,
  CHART_TYPE_LINE,
  CHART_TYPE_PIE,
  CHART_TYPE_TEXT
} from '../constants';
import { ChartData, HighChartSeriesRecord, SeriesDataSlice, CategoryItem } from '../types/chartDataModel';
import { truncateTextWithEllipsis } from '../utils/common';

highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

const borderRadiusStyles = {
  borderRadiusTopLeft: 3,
  borderRadiusTopRight: 3,
};

const tooltipHeaderFormat = '<div style="font-size: 12px;">{point.key}</div>';
const getTooltipPointFormat = (chartType: string) => {
  const pointColorFormat = chartType === CHART_TYPE_PIE ? '{point.color}' : '{series.color}';
  const pointValue = chartType === CHART_TYPE_PIE ? '{point.y} ({point.percentage:.1f}%)' : '{point.y}';
  return (`
  <div style="
    display: flex;
    align-items: center;
    margin-top: 6px;"
  >
    <div style="
      width: 8px;
      height: 8px;
      background-color: ${pointColorFormat};
      border: 1px solid ${pointColorFormat};
      border-radius: 4px">
    </div>
    <div style="margin-left: 8px;">
      {series.name}:
      <span style="font-weight: 700;">${pointValue}</span>
    </div>
  </div>
  `);
};

const fontColorGray800 = 'rgba(0, 0, 0, 0.87)';
const fontColorGray700 = 'rgba(0, 0, 0, 0.67)';

const commonFontStyle = {
  fontFamily: '"Open Sans", sans-serif;',
  fontSize: '12px',
  fontWeight: 'normal',
  color: fontColorGray800,
};

const GROUPING_CATEGORIES_BORDER_LINE_ID = 'grouping-categories-border-line';

const createGroupedCategoriesBorderLinesConfig = (categories: Array<string | CategoryItem>) => {
  if (typeof categories[0] !== 'string') {
    let lineIndex = 0;
    return categories.map((item, index, items) => {
      const isFirstIndex = index === 0;
      const isLastParentCategory = index === items.length - 1;
      const LINE_WIDTH = 1;
      const ZERO_LINE_WIDTH = 0;
      const LINE_RANGE_DELTA = 0.5;
      const mainIndex = lineIndex + (item as CategoryItem).categories.length;
      lineIndex = isFirstIndex ? mainIndex - LINE_RANGE_DELTA : mainIndex;
      return {
        color: '#e6e6e6',
        width: isLastParentCategory ? ZERO_LINE_WIDTH : LINE_WIDTH,
        value: lineIndex,
        zIndex: 10,
        id: GROUPING_CATEGORIES_BORDER_LINE_ID,
      };
    });
  }
  return ([]);
};

const drawGroupingCategoriesBorderSubline = function(chart: any) {
  if (chart) {
    setTimeout(
      () => {
        const chartObject = {...chart};
        const plotLines = [...chartObject.xAxis[0].plotLinesAndBands];
        const groupingCategoriesLineId = GROUPING_CATEGORIES_BORDER_LINE_ID;
        const lineLength = chart.xAxis[0].labelOffset;
        const axisHeight = chart.xAxis[0].height;

        plotLines.forEach((line: any) => {
          if (line.id === groupingCategoriesLineId && line.svgElem) {
            const path = line.svgElem.attr('d').split(' ');
            path[5] = String(axisHeight + lineLength - 1);
            const newPath = path.join(' ');
            line.svgElem.attr('d', newPath);
          }
        });
      },
      20
    );
  }
};

const getTooltipConfig = (chartType: string) => {
  return {
    enabled: true,
    useHTML: true,
    pointFormat: getTooltipPointFormat(chartType),
    headerFormat: tooltipHeaderFormat,
    footerFormat: '',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#19579f',
    padding: 8,
    backgroundColor: '#fff',
    outside: true,
    shape: 'rect',
    shared: true,
  };
};

const barColumnHoverAreaConfig = {
  crosshair: {
    color: 'rgba(0, 0, 0, 0.07)',
  },
};

const hoverStatesConfig = {
  inactive: {
    enabled: false,
  },
  hover: {
    brightness: -0.2,
  },
};

export class ChartService {

  public static chartSeriesColors = [
    '#6280BA',
    '#A8CDF0',
    '#9B72D0',
    '#D2CDF4',
    '#44A48D',
    '#91CAB8',
    '#EBA844',
    '#FFE799',
    '#D2546B',
    '#FFC2DD',
  ];

  public static primaryChartColors = {
    [CHART_TYPE_BAR]: '#1EB3E2',
    [CHART_TYPE_COLUMN]: '#3883DA',
    [CHART_TYPE_LINE]: '#656DB7',
  };

  static getChartColorPalette(chartType: string, chartData: Array<any>) {
    if (this.primaryChartColors[chartType] && !this.checkIsChartSeries(chartData)) {
      return [this.primaryChartColors[chartType]];
    } else {
      return this.chartSeriesColors;
    }
  }

  static checkIsChartSeries = (data: Array<any>) => {
    return data.length > 1; // series - if not single data item
  }

  static isGroupedCategories(categories: Array<string | CategoryItem>): boolean {
    return !(typeof categories[0] === 'string');
  }

  static getGroupedCategoriesLabelsConfig(xAxisCategories: Array<string | CategoryItem>) {
    return this.isGroupedCategories(xAxisCategories) ? {
      labels: {
        groupedOptions: [{
          rotation: 0,
          align: 'center',
        }],
        align: 'right',
        y: 5,
        rotation: -90,
      }
    } : {};
  }

  // The following functions create objects which can be used for HighCharts configuration
  static configForColumn(
    title: string,
    xAxisCategories: Array<string | CategoryItem>,
    aggregateDataByColumn: HighChartSeriesRecord[],
    isDashboardChart: boolean,
  ) {
    const plotLinesConfig = createGroupedCategoriesBorderLinesConfig(xAxisCategories);
    return merge({}, this.configForChart(title, CHART_TYPE_COLUMN, aggregateDataByColumn), {
      chart: {
        type: CHART_TYPE_COLUMN,
        events: {
          load(this: any) {
            drawGroupingCategoriesBorderSubline(this);
          },
          redraw(this: any) {
            drawGroupingCategoriesBorderSubline(this);
          }
        }
      },
      legend: {
        itemStyle: {
          cursor: isDashboardChart ? 'default' : 'pointer',
        }
      },
      tooltip: getTooltipConfig(CHART_TYPE_COLUMN),
      xAxis: {
        plotLines: plotLinesConfig,
        categories: xAxisCategories,
        tickWidth: 0,
        ...this.getGroupedCategoriesLabelsConfig(xAxisCategories),
        title: {
          text: '',
          margin: 20,
        },
        ...barColumnHoverAreaConfig,
      },
      yAxis: {
        title: {
          text: '',
          margin: 20,
        },
      },
      plotOptions: {
        series: {
          animation: false,
        },
        column: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            align: 'center',
            padding: 0,
            y: -4,
            style: {
              ...commonFontStyle,
            }
          },
          states: hoverStatesConfig,
        },
      },
      series: aggregateDataByColumn.map(item => ({ ...item, ...borderRadiusStyles })),
    });
  }

  static configForBar(
    title: string,
    xAxisCategories: Array<string | CategoryItem>,
    aggregateDataByColumn: HighChartSeriesRecord[],
    isDashboardChart: boolean,
  ) {
    return merge({}, this.configForChart(title, CHART_TYPE_BAR, aggregateDataByColumn), {
      chart: {
        type: CHART_TYPE_BAR,
      },
      legend: {
        itemStyle: {
          cursor: isDashboardChart ? 'default' : 'pointer',
        }
      },
      tooltip: getTooltipConfig(CHART_TYPE_BAR),
      xAxis: {
        categories: xAxisCategories,
        title: {
          text: '',
          margin: 20,
        },
        ...barColumnHoverAreaConfig,
      },
      yAxis: {
        title: {
          text: '',
          margin: 20,
        },
      },
      plotOptions: {
        series: {
          animation: false,
        },
        bar: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            align: 'left',
            verticalAlign: 'middle',
            padding: 0,
            y: 0,
            x: 5,
            style: {
              ...commonFontStyle,
            }
          },
          states: hoverStatesConfig,
        },
      },
      series: aggregateDataByColumn.map(item => ({ ...item, ...borderRadiusStyles })),
    });
  }

  static configForPie(
    title: string,
    seriesData: SeriesDataSlice[],
    seriesName: string,
  ) {
    return merge({}, this.configForChart(title, CHART_TYPE_PIE, seriesData), {
      chart: {
        type: CHART_TYPE_PIE,
      },
      tooltip: getTooltipConfig(CHART_TYPE_PIE),
      xAxis: {
        width: 0,
      },
      yAxis: {
        width: 0,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.y} ({point.percentage:.1f}%)',
            style: {
              ...commonFontStyle,
            },
            padding: 0,
            distance: 10,
          },
          showInLegend: true,
          states: hoverStatesConfig,
          innerSize: '45%',
        },
        series: {
          animation: false,
          cursor: 'pointer',
        }
      },
      series: [{
        name: seriesName,
        data: seriesData,
      }]
    });
  }

  static configForLine(
    title: string,
    xAxisCategories: Array<string | CategoryItem>,
    aggregateDataByColumn: HighChartSeriesRecord[],
  ) {
    return Object.assign(this.configForChart(title, CHART_TYPE_LINE, aggregateDataByColumn), {
      chart: {
        type: CHART_TYPE_LINE,
      },
      tooltip: getTooltipConfig(CHART_TYPE_LINE),
      xAxis: {
        categories: xAxisCategories,
        title: {
          text: '',
          margin: 20,
        },
        crosshair: {
          color: 'rgba(0, 0, 0, 0.27)',
          width: 1,
        },
      },
      yAxis: {
        title: {
          text: '',
          margin: 20,
        },
      },
      series: aggregateDataByColumn,
      plotOptions: {
        line: {
          states: hoverStatesConfig,
        },
        series: {
          animation: false,
          lineWidth: 3,
          marker: {
            radius: 4,
            symbol: 'circle',
            states: {
              hover: {
                lineWidth: 0,
                radius: 4,
              }
            }
          },
          cursor: 'pointer',
        }
      },
    });
  }

  static configForChart(title: string, chartType: string, chartData: Array<any>) {
    return {
      chart: {
        style: {
          ...commonFontStyle,
        },
      },
      title: {
        text: title
      },
      colors: this.getChartColorPalette(chartType, chartData),
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          ...commonFontStyle,
          color: fontColorGray700,
        }
      },
      xAxis: {
        title: {
          text: '',
          margin: 0,
        },
        labels: {
          style: {
            ...commonFontStyle,
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          margin: 0,
        },
        labels: {
          style: {
            ...commonFontStyle,
          }
        }
      },
      credits: false,
      exporting: {
        enabled: false,
      },
    };
  }

  static populateColumnMetaCompactForChart(chart: Chart) {
    if (chart.selectedX) {
      chart.selectedXCompact = columnMetaToColumnMetaXCompact(chart.selectedX);
    }
    if (chart.selectedY) {
      chart.selectedYCompact = columnMetaToColumnMetaYCompact(chart.selectedY);
    }

    return chart;
  }

  static async apiSaveChart(chart: Chart) {
    // The API requires a compacted form of ColumnMeta for saving
    this.populateColumnMetaCompactForChart(chart);
    let response: AxiosResponse = await axios.post('/api/queries/' + chart.queryId + '/charts', chart);
    return response.data;
  }

  // This sends a partial Chart to the API in order to get a full Chart object with ChartData
  static async apiNewChart(chart: Chart): Promise<Chart> {
    // The API requires a compacted form of ColumnMeta
    this.populateColumnMetaCompactForChart(chart);
    let response: AxiosResponse = await axios.post('/api/charts/new', chart);
    return response.data;
  }

  static chartDataToConfiguration(title: string, type: CHART_TYPE, chartData: ChartData, isDashboardChart: boolean) {
    let config = null;
    const { seriesData = [], seriesName = '', aggregateDataByColumn = [], xAxis = undefined } = chartData;
    const isSeriesDataExists = seriesData.length > 0 && seriesName;
    const isChartDataExists = !!aggregateDataByColumn && aggregateDataByColumn.length > 0 &&
      xAxis && !!xAxis.categories && xAxis.categories.length;

    const categories: Array<string | CategoryItem> = xAxis && !!xAxis.categories ? xAxis.categories : [];
    const processedCategories = categories.map((item: string | CategoryItem) => {
      const stringLength = 30;
      return typeof item === 'string' ?
        truncateTextWithEllipsis(item, stringLength) :
        ({
          name: truncateTextWithEllipsis(item.name, stringLength),
          categories: item.categories.map((subItem: string) => {
            return truncateTextWithEllipsis(subItem, stringLength);
          }),
        });
    });

    if (type === CHART_TYPE_PIE && isSeriesDataExists) {
      config = this.configForPie(title, seriesData, seriesName);
    } else if (type === CHART_TYPE_COLUMN && isChartDataExists) {
      config = this.configForColumn(title, processedCategories, aggregateDataByColumn, isDashboardChart);
    } else if (type === CHART_TYPE_BAR && isChartDataExists) {
      config = this.configForBar(title, categories, aggregateDataByColumn, isDashboardChart);
    } else if (type === CHART_TYPE_LINE && isChartDataExists) {
      config = this.configForLine(title, categories, aggregateDataByColumn);
    } else if (type === CHART_TYPE_TEXT) {
      config = chartData;
    }
    return config;
  }

  static async apiSaveChartTitle(title: string, chart?: Chart) {
    if (chart && chart.chartId) {
      let response: AxiosResponse = await axios.patch(
        `/api/charts/${chart.chartId}`,
        { title },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        });
      return response.data;
    }
    return Promise.reject('ChartId is undefined');
  }
}
