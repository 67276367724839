import React from 'react';

export const LineChartIllustration = () => (
  <svg
    width={108}
    height={49}
    viewBox="0 0 108 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.7071 1.70711C93.0976 1.31658 93.0976 0.683417 92.7071 0.292892C92.3166 -0.0976321 91.6834 -0.0976321
        91.2929 0.292892L67 24.5858L58.2071 15.7929C57.8166 15.4024 57.1834 15.4024 56.7929 15.7929L41.5 31.0858L33.7071
         23.2929C33.3166 22.9024 32.6834 22.9024 32.2929 23.2929L15.2929 40.2929C14.9024 40.6834 14.9024 41.3166 15.2929
          41.7071C15.6834 42.0976 16.3166 42.0976 16.7071 41.7071L33 25.4142L40.7929 33.2071C41.1834 33.5976 41.8166
           33.5976 42.2071 33.2071L57.5 17.9142L66.2929 26.7071C66.6834 27.0976 67.3166 27.0976 67.7071 26.7071L92.7071
            1.70711ZM0 49H108V48H0V49Z"
      fill="#656DB7"
    />
  </svg>
);
