// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem

export const base64ToBytes = (base64: string) => {
  const binString = atob(base64);
  return Uint8Array.from(binString, m => m.codePointAt(0) as number);
};

export const bytesToBase64 = (bytes: Uint8Array) => {
  const binString = Array.from(bytes, x => String.fromCodePoint(x)).join('');
  return btoa(binString);
};
