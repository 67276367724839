import * as React from 'react';
import { Spinner } from 'elmo-elements';

export type ReactSelectProps = {
  label?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
};

const FormField = ({label, isLoading, children}: ReactSelectProps) => {
  return (
    <div className="filters-form-field">
      <div className="label-container">
        <div className="label">{label}</div>
        {isLoading && <Spinner size="s" />}
      </div>
      {children}
    </div>
  );
};

export default FormField;
