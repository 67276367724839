import { useEffect, useState } from 'react';
import { ColumnSort } from '@eds/table';
import { PaginationState } from '@eds/pagination';
import { DateTime } from 'luxon';
import { get, orderBy, startCase } from 'lodash';

import { ScheduledReport } from 'src/features/scheduled-reports';

import { FilterData, TableColumn, TableColumnConfig } from './scheduled-reports-list.types';

const BLANK_DATE_STRING = '-';
const DISPLAY_DATE_FORMAT = 'd LLLL yyyy';

const tableColumnEntries: Array<[TableColumn, TableColumnConfig]> = [
  [
    'scheduleName',
    {
      key: 'scheduleName',
      label: 'Schedule Name',
      format: (text: string) => text,
    },
  ],
  [
    'report',
    {
      key: 'query.name',
      label: 'Report',
      format: (text: string) => text,
    },
  ],
  [
    'occurrence',
    {
      key: 'occurrence',
      label: 'Occurrence',
      format: (text: string) => startCase(text),
    },
  ],
  [
    'status',
    {
      key: 'status',
      label: 'Status',
      format: (text: string) => text,
    },
  ],
  [
    'nextScheduleDate',
    {
      key: 'nextScheduleDate',
      label: 'Next Send Date',
      format: (date: string) => (date ? DateTime.fromSQL(date).toFormat(DISPLAY_DATE_FORMAT) : BLANK_DATE_STRING),
    },
  ],
  [
    'endDate',
    {
      key: 'endDate',
      label: 'End Date',
      format: (date: string) => (date ? DateTime.fromSQL(date).toFormat(DISPLAY_DATE_FORMAT) : BLANK_DATE_STRING),
    },
  ],
];

const getFilteredItems = (items: Array<ScheduledReport>, { search, status, occurrence }: FilterData) => {
  let filteredItems = items;

  if (search) {
    filteredItems = filteredItems.filter(
      item =>
        item.scheduleName.toLowerCase().includes(search.toLowerCase()) ||
        item.query.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  if (status.length) {
    filteredItems = filteredItems.filter(item => status.includes(item.status));
  }

  if (occurrence.length) {
    filteredItems = filteredItems.filter(item => occurrence.includes(item.occurrence));
  }

  return filteredItems;
};

const getPaginatedItems = (
  items: Array<ScheduledReport>,
  { currentItemsPerPage, rangeStart }: Pick<PaginationState, 'currentItemsPerPage' | 'rangeStart'>
) => items.slice(rangeStart - 1, rangeStart + currentItemsPerPage - 1);

const getSortedItems = (items: Array<ScheduledReport>, { columnName, sortDirection }: ColumnSort) => {
  if (sortDirection === 'default') {
    return items;
  }

  const sortedTableColumnConfig = tableColumnEntries.find(([tableColumn]) => tableColumn === columnName);

  if (!sortedTableColumnConfig) {
    return items;
  }

  const sortedItems = orderBy(
    items,
    item => get(item, sortedTableColumnConfig[1].key) as string,
    sortDirection === 'ascending' ? 'asc' : 'desc'
  );

  return sortedItems;
};

export const useScheduledReportsList = (isLoading: boolean, scheduledReportsData?: Array<ScheduledReport>) => {
  const [filterData, setFilterData] = useState<FilterData>({
    search: '',
    status: [],
    occurrence: [],
  });

  const [paginationData, setPaginationData] = useState<
    Pick<PaginationState, 'currentPage' | 'currentItemsPerPage' | 'rangeStart'>
  >({
    currentPage: 1,
    currentItemsPerPage: 10,
    rangeStart: 1,
  });

  const [scheduledReports, setScheduledReports] = useState<Array<ScheduledReport>>([]);

  const [sortData, setSortData] = useState<ColumnSort>({
    columnName: '',
    sortDirection: 'default',
  });

  const [totalItems, setTotalItems] = useState(0);

  const updateScheduledReports = ({
    filter,
    sort,
    pagination,
  }: Partial<{ filter: FilterData; sort: ColumnSort; pagination: PaginationState }> = {}) => {
    const filteredItems = getFilteredItems(scheduledReportsData || [], filter || filterData);
    const sortedItems = getSortedItems(filteredItems, sort || sortData);
    const paginatedItems = getPaginatedItems(sortedItems, pagination || paginationData);

    setScheduledReports(paginatedItems);
    setTotalItems(filteredItems.length);
  };

  const onFilter = ({ search, status, occurrence }: Partial<FilterData>) => {
    const newFilterData: Partial<FilterData> = {};

    if (typeof search === 'string') {
      newFilterData.search = search;
    }

    if (status) {
      newFilterData.status = status;
    }

    if (occurrence) {
      newFilterData.occurrence = occurrence;
    }

    setFilterData({ ...filterData, ...newFilterData });
    updateScheduledReports({ filter: { ...filterData, ...newFilterData } });
  };

  const onPageUpdate = (newPaginationData: PaginationState) => {
    setPaginationData(newPaginationData);
    updateScheduledReports({ pagination: newPaginationData });
  };

  const onSort = (sort: ColumnSort) => {
    setSortData(sort);
    updateScheduledReports({ sort });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    updateScheduledReports();
  }, [isLoading, scheduledReportsData]);

  return {
    filterData,
    onFilter,
    onPageUpdate,
    onSort,
    paginationData,
    scheduledReports,
    sortData,
    tableColumnEntries,
    totalItems,
  };
};
