import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button } from 'elmo-elements';
import { AppState } from '../../redux';
import { NotificationService } from '../../services/notificationService';
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS
} from '../../constants';
import { noop } from 'lodash';
import { ExportService } from '../../services/exportService';
import { ReportTable } from '../../types/reportTableModel';

interface ExportCsvButtonProps {
  dispatch: Dispatch<any>;
  query: string;
  notificationService: NotificationService;
  disabled?: boolean;
  buttonColourClass?: string;
  reportTitle: string;
  queryId: string;
  report: ReportTable;
}

interface ExportCsvButtonState {
  downloading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  query: state.query,
  notificationService: state.notificationService,
  reportTitle: state.reportTitle,
  report: state.report
});

class ExportCsvButtonComponent extends React.Component<ExportCsvButtonProps, ExportCsvButtonState> {
  constructor(props: ExportCsvButtonProps) {
    super(props);

    this.state = {
      downloading: false
    };
  }

  protected doExport = async () => {
    const { dispatch, query, reportTitle, queryId } = this.props;
    this.setState({
      downloading: true
    });

    try {
      const result = await ExportService.handleDownload(
        dispatch,
        query,
        reportTitle,
        queryId,
        this.props.report.jobId);
      this.onExportSuccess(!!result);
    } finally {
      this.onExportFinally();
    }
  }

  onExportSuccess = (result: boolean) => {
    if (result) {
      let reportTitle = (this.props.reportTitle) ? '<' + this.props.reportTitle + '>'
        : '<untitled>';
      this.props.notificationService.addNotification(
        NOTIFICATION_SUCCESS,
        'Your ' + reportTitle + ' report is being created. To see its progress go to the downloads page.',
        this.renderDownloadsButton()
      );
    } else {
      this.props.notificationService.addNotification(
        NOTIFICATION_ERROR,
        'There was an issue creating the report.'
      );
    }

  }

  onExportFinally = () => {
    this.setState({
      downloading: false
    });
  }

  componentWillUnmount() {
    this.onExportFinally = noop;
  }

  render() {
    return (
      <Button
        className="exportReportButton"
        onClick={this.doExport}
        isDisabled={this.state.downloading || this.props.disabled}
        isUppercase={false}
      >
        {!this.state.downloading && 'Export Report'
        }
        {this.state.downloading && 'Exporting...'
        }
      </Button>
    );
  }

  renderDownloadsButton() {
    return (
      <button
        className="btn btn-primary"
        onClick={() => window.open('/downloads', '_self')}
        style={{marginTop: 5}}
      >
        Downloads
      </button>
    );
  }

}

const ExportCsvButton = connect(
  mapStateToProps
)(ExportCsvButtonComponent);
export default ExportCsvButton;
