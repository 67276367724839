import * as React from 'react';
import './style.css';

interface PageTitleProps {
  title: string;
}

class PageTitle extends React.Component<PageTitleProps> {
  constructor(props: PageTitleProps) {
    super(props);
  }

  render() {
    return (
      <div className="page-title" data-testid="page-title">
        <h1 role="heading">
          {this.props.title}
        </h1>
      </div>
    );
  }
}

export default PageTitle;
