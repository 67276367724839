import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const CollapseReportBuilderOpenedIcon = ({
  width = 20,
  height = 21
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_731_5353)">
        <path
          d="M9.50898 14.79L6.52565 11.7984L18.334 11.7984L18.334 10.1317L6.52565 10.1317L9.51732
          7.14003L8.33399 5.96503L3.33398 10.965L8.33398 15.965L9.50898 14.79ZM2.50065 15.965L2.50065
          5.96503L0.833985 5.96503L0.833984 15.965L2.50065 15.965Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_731_5353">
          <rect width={width} height={height} fill="white" transform="translate(0 0.965027)" />
        </clipPath>
      </defs>
    </svg>
  );
};
