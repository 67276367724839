import * as React from 'react';

type Props = {
  name: string;
  email: string;
};

function UserSelectLabel({ name, email }: Props) {
  return (
    <div className="user-select-label d-flex">
      <div className="name">
        {name}&nbsp;-&nbsp;
      </div>
      <div className="email">
        {email}
      </div>
    </div>
  );
}

export default UserSelectLabel;
