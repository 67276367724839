// polyfills for IE compatibility. We are only supporting IE11, added polyfill up to IE9 in case.
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'element-closest/browser';
import './polyfills';
// end polyfills

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux';
import reportingApp, { getInitialState } from './reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Core } from '@eds/core';
import 'elmo-elements/main.css';
import './styles/main.css';
import { ApiService } from './services/apiService';
import { NotificationService } from './services/notificationService';
import { setNotificationService } from './actions/notification';
import { AuthService } from './services/authService';
import { setAuthService } from './actions/auth';
import { unregister } from './registerServiceWorker';
import { noop } from 'lodash';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

let composeEnhancers;
// process.env.NODE_ENV is set by create-react-app.
if (process.env.NODE_ENV === 'production') {
  // disable react devtools in the browser https://github.com/facebook/react-devtools/issues/191
  if (typeof window['__REACT_DEVTOOLS_GLOBAL_HOOK__'] === 'object') {
    for (let [key, value] of Object.entries(window['__REACT_DEVTOOLS_GLOBAL_HOOK__'])) {
      window['__REACT_DEVTOOLS_GLOBAL_HOOK__'][key] = typeof value === 'function' ? noop : null;
    }
  }
  composeEnhancers = compose;
} else {
  composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
}

// Configure redux with thunk
const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(thunk))(createStore);

// Create a store
const store = createStoreWithMiddleware(reportingApp, getInitialState());

let notificationService: NotificationService = new NotificationService(store.dispatch);
let authService: AuthService = AuthService.getInstance();
authService.dispatch = store.dispatch;
authService.configureForUser();

store.dispatch(setNotificationService(notificationService));
store.dispatch(setAuthService(authService));

let apiService: ApiService = new ApiService(notificationService, authService);
apiService.addApiInterceptors(store);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Core>
        <App />
      </Core>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

// unregister any existing service worker
unregister();
