import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const SecureFormIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg 
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20" 
      fill="currentColor" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M4.99992 5.32496V9.24163C4.99992 12.0166 6.46659 14.6083 8.60825 15.95C8.89992 16.85 9.40825 17.6666 10.0666
        18.3166C10.0468 18.3166 10.0322 18.3218 10.0146 18.3281C10.01 18.3298 10.0051 18.3316 9.99992 18.3333C6.17492 
        17.375 3.33325 13.45 3.33325 9.24163V4.16663L9.99992 1.66663L16.6666 4.16663V8.89996C16.1499 8.65829 15.5916 
        8.48329 14.9999 8.39996V5.32496L9.99992 3.44996L4.99992 5.32496ZM14.9999 11.6666V12.5C15.4583 12.5 15.8333 
        12.875 15.8333 13.3333V15.8333C15.8333 16.2916 15.4583 16.6666 14.9999 16.6666H11.6666C11.2083 16.6666 10.8333 
        16.2916 10.8333 15.8333V13.3333C10.8333 12.875 11.2083 12.5 11.6666 12.5V11.6666C11.6666 10.75 12.4166 9.99996 
        13.3333 9.99996C14.2499 9.99996 14.9999 10.75 14.9999 11.6666ZM12.4999 11.6666V12.5H14.1666V11.6666C14.1666 
        11.2083 13.7916 10.8333 13.3333 10.8333C12.8749 10.8333 12.4999 11.2083 12.4999 11.6666Z"
      />
    </svg>
  );
};
