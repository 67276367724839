import { View } from '../types/viewModel';
import { ColumnSelectItem, ColumnSelectItemGroup } from '../components/ColumnSelect';
import { findIndex, forEach, map } from 'lodash';
import { Measure } from '../types/measureModel';
import { Dimension } from '../types/dimensionModel';
import { GroupBy } from '../types/groupByModel';
import { Join } from '../types/joinModel';
import { SOURCE_WIZARD } from '../constants';
import { addGroupBy, removeGroupBy } from '../actions/groupBy';
import { storeFilterOptions } from '../actions/filter';
import { Dispatch } from 'redux';

export class MeasureSelectService {
  static createMeasureItems(view: View,
                            selectedMeasures: Measure[],
                            onMeasureAdd: (measure: Measure) => () => void,
                            onMeasureRemove: (measure: Measure) => () => void): ColumnSelectItemGroup {
    let items: ColumnSelectItem[] = map(view.measures, (measure: Measure) => {
      let selected: boolean = this.isMeasureSelected(measure, selectedMeasures);

      return {
        label: measure.label,
        isSelected: selected,
        onClick: selected ? onMeasureRemove(measure) : onMeasureAdd(measure)
      };
    });

    return {
      label: '',
      items: items
    };
  }

  static isMeasureSelected(measure: Measure, selectedMeasures: Measure[]): boolean {
    return (findIndex(selectedMeasures, ['name', measure.name]) !== -1);
  }

  static createGroupByItems(joinView: View,
                            baseView: View,
                            selectedGroupBys: GroupBy[],
                            dispatch: Dispatch<any>,
                            join?: Join): ColumnSelectItemGroup {
    let items: ColumnSelectItem[] = [];

    forEach(joinView.dimensions, (dimension: Dimension) => {
      if (!dimension.groupable) {
        return;
      }

      let groupBy: GroupBy = {
        view: joinView,
        join: join,
        dimension: dimension,
        name: dimension.name,
        label: dimension.label,
      };

      let selected: boolean = this.isGroupBySelected(groupBy, selectedGroupBys);

      // NOTE: Remove deprecated dimensions that are not selected
      if (!selected && dimension.deprecated) {
        return;
      }

      items.push({
        isDeprecated: dimension.deprecated,
        label: groupBy.label,
        isSelected: selected,
        onClick: selected ?
          this.onGroupByRemove(groupBy, baseView, dispatch) :
          this.onGroupByAdd(groupBy, baseView, dispatch)
      });
    });

    return {
      label: 'Group By',
      items: items
    };
  }

  static isGroupBySelected(groupBy: GroupBy, selectedGroupBys: GroupBy[]) {
    return (findIndex(selectedGroupBys, (g: GroupBy) => {
      return GroupBy.groupByIsEqual(groupBy, g);
    }) !== -1);
  }

  static onGroupByAdd = (item: GroupBy, baseView: View, dispatch: Dispatch<any>) => () => {
    dispatch(addGroupBy(item, baseView, SOURCE_WIZARD));
    dispatch(storeFilterOptions());
  }

  static onGroupByRemove = (item: GroupBy, baseView: View, dispatch: Dispatch<any>) => () => {
    dispatch(removeGroupBy(item, baseView, SOURCE_WIZARD));
    dispatch(storeFilterOptions());
  }
}