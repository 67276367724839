import React from 'react';
import { Action, ActionsDropdown, ActionsGroup } from '@eds/actions';
import { Avatar } from '@eds/avatar';
import { Box } from '@eds/box';
import { ColumnSort, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@eds/table';
import { EmptyFolderSpot } from '@eds/illustration';
import { EmptyState } from '@eds/empty-state';
import { Flex } from '@eds/flex';
import { Link } from '@eds/link';
import { Text } from '@eds/text';
import { css } from '@emotion/css';
import { DateTime } from 'luxon';

import { cssOverrides } from 'src/features/ui';
import { getUserUuid } from 'src/features/utils';

import { DashboardData } from './dashboards-list.queries';
import { TableColumn, tableColumns } from './dashboards-list.hooks';

type DashboardsListTableViewProps = {
  dashboards: DashboardData[];
  getActions: (dashboard: DashboardData) => Action[];
  onSort: (sort: ColumnSort) => void;
  sortData: ColumnSort;
};

const DISPLAY_DATE_TIME_FORMAT = 'dd/LL/yyyy';

const columnSettings = {
  actions: { width: '5rem' },
  dashboard: { width: '100%' },
  description: { width: '100%' },
  modified: { width: '8rem' },
  owner: { width: '16rem' },
};

const composeTableCell = ({ columnName }: TableColumn, dashboard: DashboardData, isCurrentUser: boolean) => {
  if (columnName === 'owner') {
    return dashboard.type === 2 ? (
      <Flex alignItems="center" gap="xsmall">
        <Avatar name="ELMO" size="small" />
        <Text fontSize="small">ELMO</Text>
      </Flex>
    ) : (
      <Flex alignItems="center" gap="xsmall">
        <Avatar size="small" src={dashboard.creator.profilePicture || ''} />
        <Text fontSize="small">{isCurrentUser ? 'Me' : dashboard.creator.name}</Text>
      </Flex>
    );
  }

  if (columnName === 'description') {
    return dashboard.description;
  }

  if (columnName === 'modified') {
    const modified = DateTime.fromJSDate(new Date(dashboard.modified.date));
    return modified.isValid ? modified.toFormat(DISPLAY_DATE_TIME_FORMAT) : '-';
  }

  return (
    <Text fontSize="small">
      <Link href={`/dashboards/${dashboard.dashboardId}`} label={dashboard.title} standalone />
    </Text>
  );
};

export const DashboardsListTableView = ({ dashboards, getActions, onSort, sortData }: DashboardsListTableViewProps) => {
  const dashboardCreatedByCurrentUser = (uuid: string) => uuid === getUserUuid();

  return (
    <Box className={css({ ...cssOverrides.theadButton(), ...cssOverrides.tableScrollWrapButton() })}>
      <Table columnSettings={columnSettings} minWidth="50rem" tableBorder truncate width="100%">
        <TableHead>
          <TableRow>
            {tableColumns.map(({ columnName, label }) => (
              <TableHeaderCell
                key={columnName}
                columnName={columnName}
                onSortChange={columnName === 'owner' ? undefined : onSort}
                sort={columnName === 'owner' ? undefined : sortData}
              >
                {label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell columnName="actions">Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboards.length > 0 ? (
            dashboards.map(item => (
              <TableRow key={item.dashboardId}>
                {tableColumns.map(tableColumn => (
                  <TableCell key={`${item.dashboardId}-${tableColumn.columnName}`} columnName={tableColumn.columnName}>
                    {composeTableCell(tableColumn, item, dashboardCreatedByCurrentUser(item.creator.uuid))}
                  </TableCell>
                ))}
                <TableCell columnName="actions">
                  <Box className={css(cssOverrides.button('ghost'))} minHeight="2rem">
                    <ActionsGroup>
                      <ActionsDropdown actions={getActions(item)} size="small" tone="ghost" />
                    </ActionsGroup>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="100%">
                <Box marginTop="large" marginBottom="large">
                  <EmptyState
                    description="Try a different filter or search value."
                    spotIllustration={EmptyFolderSpot}
                    title="No results found"
                  />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
