import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AppState, ViewJoinAndDimensionOrMeasure } from 'src/redux';
import { getColumnMetaWithLabels } from 'src/services/columnMetaService';
import { isValidUuid } from 'src/utils';
import { Join } from 'src/types/joinModel';

const useDimensionLabel = () => {
  const { columnMeta, customColumns, filterOptions, report } = useSelector((state: AppState) => state);
  const columnMetaWithLabels = getColumnMetaWithLabels(columnMeta, report.headers);

  return useCallback<(dimensionIdentifier: string) => Array<string | undefined>>(
    (dimensionIdentifier = '') => {
      if (isValidUuid(dimensionIdentifier)) {
        const customColumn = customColumns.find(column => column.id === dimensionIdentifier);

        return customColumn ? [customColumn.title, customColumn.alias] : [];
      }

      if (dimensionIdentifier.includes('.')) {
        const fullJoinName = dimensionIdentifier.substring(0, dimensionIdentifier.lastIndexOf('.'));
        const dimensionName = dimensionIdentifier.split(`${fullJoinName}.`).pop();

        const joinColumn = columnMetaWithLabels
          .filter(({ join }) => join && join.fullJoinName === fullJoinName)
          .find(({ dimension, measure }) => {
            if (measure) {
              return measure.name === dimensionName;
            }

            return dimension && dimension.name === dimensionName;
          });

        // NOTE: Find the label in filterOptions as last resort
        if (!joinColumn) {
          const filterOption = filterOptions
            .filter(({ join }) => !!join && join.fullJoinName === fullJoinName)
            .find(({ dimension, measure }) => {
              if (measure) {
                return measure.name === dimensionName;
              }

              return dimension && dimension.name === dimensionName;
            }) as (ViewJoinAndDimensionOrMeasure & { join: Join }) | undefined;

          return filterOption
            ? [`${filterOption.join.label} / ${filterOption.measure?.label || filterOption.dimension?.label}`]
            : [];
        }

        return [joinColumn.displayLabel, joinColumn.alias];
      }

      const baseViewColumn = columnMetaWithLabels
        .filter(({ join }) => !join)
        .find(({ dimension, measure }) => {
          if (measure) {
            return measure.name === dimensionIdentifier;
          }

          return dimension && dimension.name === dimensionIdentifier;
        });

      // NOTE: Find the label in filterOptions as last resort
      if (!baseViewColumn) {
        const filterOption = filterOptions
          .filter(({ join }) => !join)
          .find(({ dimension, measure }) => {
            if (measure) {
              return measure.name === dimensionIdentifier;
            }

            return dimension && dimension.name === dimensionIdentifier;
          });

        return filterOption
          ? [`${filterOption.view.label} / ${filterOption.measure?.label || filterOption.dimension?.label}`]
          : [];
      }

      return [baseViewColumn.displayLabel, baseViewColumn.alias];
    },
    [columnMeta, customColumns, report.headers]
  );
}

export default useDimensionLabel;
