import * as React from 'react';
import cn from 'classnames';
import { Layout } from 'react-grid-layout';
import {
  DeleteOutlinedIcon,
  DragIndicatorIcon,
  Dropdown,
  EditOutlinedIcon,
  MoreHorizIcon,
  SaveAltIcon,
} from 'elmo-elements';

import { redirect } from '../../../services/utilityService';
import { Widget } from '../../../types/widgetModel';
import { EditChartIcon } from '../../../components/Icons/EditChartIcon';
import WidgetComponent from '../../../components/Widget';
import useOnElementOnScreen from '../../../components/hooks/useOnElementOnScreen';

type Props = {
  isEditable: boolean;
  removeWidget: (widget: Widget) => void;
  openEditWidgetTitleModal: (widget: Widget) => void;
  widget: Widget;
  layout: Layout;
  currentGridWidth?: number;
  enableDashboardLazyLoading: boolean;
  updateWidgetStatus: (widgetId: string, status: boolean) => void;
};

const WidgetWithButtons = (props: Props) => {
  // NOTE: if enableDashboardLazyLoading true a value should be false
  const initRenderChart = !props.enableDashboardLazyLoading;
  const [ refChart, setRefChart ] = React.useState<Highcharts.Chart | null>(null);
  const [ isMounted, setIsMounted ] = React.useState<boolean>(false);
  const [ isRenderChart, setIsRenderChart ] = React.useState<boolean>(initRenderChart);
  const { containerRef, isVisible } = props.enableDashboardLazyLoading ? useOnElementOnScreen({
    root: document.querySelector('.elmo-layout__main-content-wrapper'),
    rootMargin: '100%', // can be '0px' or another px for example '100px'
    threshold: 0.1,
  }) : { containerRef: null, isVisible: false };
  const chart = props.widget.chart;
  let query = props.widget.query;
  if (chart) {
    query = chart.query;
  }

  if (!query) {
    // Query was not attached to chart
    return null;
  }

  const widgetHeaderTitle = props.widget ? props.widget.title : '';
  const { canEdit, canDelete } = props.widget;
  const isNotTextChart = chart && chart.displayType !== 'text';
  const isDropdownVisible = canEdit || canDelete || isNotTextChart;
  const isDividerVisible = (canEdit || canDelete) && isNotTextChart;
  const isChartActionsVisible = isNotTextChart;

  const renderChartRemoved = () => (<>The associated chart has been removed</>);
  const renderReportRemoved = () => (<>The associated report has been removed</>);
  const dropdownItemClicked = (link: string) => () => {
    redirect(link);
  };
  const handleChangeRefChart = (hcRef: Highcharts.Chart) => {
    setRefChart(hcRef);
  };

  React.useEffect(
    () => {
      setTimeout(
        () => { 
          setIsMounted(true);
        },
        500
      );
    },
    []
  );

  React.useEffect(
    () => {
      if (!isRenderChart && isVisible && isMounted) {
        setIsRenderChart(isVisible);
        props.updateWidgetStatus(props.widget.widgetId, false);
      }
    },
    [isVisible, isMounted]
  );

  return (
    <div key={props.widget.widgetId} className={'widget-container'} ref={containerRef}>
      <div className="widget-drag-container">
        {props.isEditable && (<DragIndicatorIcon className="widget-drag-icon"/>)}
      </div>

      <div className="widget-header">
        <h6>{widgetHeaderTitle}</h6>
        {isDropdownVisible ? (
          <Dropdown
            icon={<MoreHorizIcon/>}
            ariaLabel="Widget dropdown"
            position={'bottom-end'}
            className="dropdown-button"
          >
            {canEdit ?
              <>
                <Dropdown.Item
                  ariaLabel="Edit Title"
                  value={2}
                  onClick={() => props.openEditWidgetTitleModal(props.widget)}
                >
                  <EditOutlinedIcon /> Edit Title
                </Dropdown.Item>
                <Dropdown.Item
                  ariaLabel="Edit Chart"
                  value={2}
                  onClick={dropdownItemClicked('/report/' + query.id)}
                >
                  <EditChartIcon className="edit-chart-icon"/> Edit Chart
                </Dropdown.Item>
              </>
              : <></>
            }
            {props.isEditable && canDelete ?
              <Dropdown.Item
                ariaLabel="Remove Widget"
                value={3}
                onClick={() => props.removeWidget(props.widget)}
              >
                <DeleteOutlinedIcon /> Remove
              </Dropdown.Item>
              : <></>
            }
            {isDividerVisible ? <Dropdown.Divider/> : <></>}
            {isChartActionsVisible ?
              <>
                <Dropdown.Item
                  ariaLabel="Download PNG chart"
                  value={5}
                  onClick={() => {
                    if (refChart) {
                      refChart.exportChart({ type: 'image/png' }, {});
                    }
                  }}
                  isDisabled={!refChart}
                >
                  <SaveAltIcon /> Download PNG
                </Dropdown.Item>
                <Dropdown.Item
                  ariaLabel="Download JPEG chart"
                  value={6}
                  onClick={() => {
                    if (refChart) {
                      refChart.exportChart({ type: 'image/jpeg' }, {});
                    }
                  }}
                  isDisabled={!refChart}
                >
                  <SaveAltIcon /> Download JPEG
                </Dropdown.Item>
                <Dropdown.Item
                  ariaLabel="Download PDF chart"
                  value={7}
                  onClick={() => {
                    if (refChart) {
                      refChart.exportChart({ type: 'application/pdf' }, {});
                    }
                  }}
                  isDisabled={!refChart}
                >
                  <SaveAltIcon /> Download PDF
                </Dropdown.Item>
                <Dropdown.Item
                  ariaLabel="Download CSV chart"
                  value={8}
                  onClick={() => {
                    if (refChart) {
                      refChart.downloadCSV();
                    }
                  }}
                  isDisabled={!refChart}
                >
                  <SaveAltIcon /> Download CSV
                </Dropdown.Item>
              </>
              : <></>
            }
          </Dropdown>
        ) : null}
      </div>
      <div className={cn('visualisation-container', { 'report-style': !chart && props.widget.readAccess})}>
        {query && query.deleted && renderReportRemoved()}
        {chart && chart.deleted && renderChartRemoved()}
        {isRenderChart &&
          <WidgetComponent
            widget={props.widget}
            layout={props.layout}
            handleChangeRefChart={handleChangeRefChart}
            currentGridWidth={props.currentGridWidth}
            updateWidgetStatus={props.updateWidgetStatus}
          />
        }
      </div>
    </div>
  );
};

export default WidgetWithButtons;
