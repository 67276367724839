import { Dispatch } from 'redux';

import { NotificationType } from 'src/features/api-feedback';

import { addDashboardItemAddedNotification, addNotification, removeNotification } from '../actions/notification';

export class NotificationService {
  protected notificationId: number = 0;
  protected dispatch: Dispatch<any>;

  constructor(dispatch: Dispatch<any>) {
    this.dispatch = dispatch;
  }

  addNotification(notificationType: NotificationType, textOrDashboardId: string, children?: React.ReactNode) {
    /**
     * NOTE: The add to dashboard notification logic is tightly coupled with redux. To deal with it for the meantime,
     * we use a custom action for this specific purpose.
     */
    if (notificationType === 'NOTIFICATION_ADD_TO_DASHBOARD_SUCCESS') {
      this.dispatch(addDashboardItemAddedNotification(this.notificationId, textOrDashboardId));
    } else {
      this.dispatch(addNotification(notificationType, textOrDashboardId, this.notificationId, children));
    }
    this.notificationId++;
  }

  removeNotification(id: number) {
    this.dispatch(removeNotification(id));
  }
}