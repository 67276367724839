import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type ConcatenationConfigDelimiter = {
  id: string;
  token: string;
};

const concatenationConfigKeys = {
  all: ['concatenation'] as const,
  delimiterTokens: () => [...concatenationConfigKeys.all, 'delimiter-tokens'] as const,
};

const fetchConcatenationConfigDelimiterTokens = async () => {
  const response = await axios.get<ConcatenationConfigDelimiter[]>('/api/public/concatenation/tokens');
  return response.data;
};

export const useConcatenationConfigDelimiterTokensQuery = (enabled: boolean) =>
  useQuery({
    enabled,
    queryKey: concatenationConfigKeys.delimiterTokens(),
    queryFn: fetchConcatenationConfigDelimiterTokens,
    select: data =>
      data.map(({ id, token }) => ({
        label: token,
        value: id,
      })),
  });
