import { useQuery } from '@tanstack/react-query';

import { fetchScheduledReportsList, scheduledReportsKeys } from 'src/features/scheduled-reports';

export const useScheduledReportDetailsQuery = (scheduledReportId: string) =>
  useQuery({
    enabled: !!scheduledReportId,
    queryKey: scheduledReportsKeys.lists(),
    queryFn: ({ signal }) => fetchScheduledReportsList(signal),
    select: data => data.find(scheduledReport => scheduledReportId === scheduledReport.id),
  });
