import * as React from 'react';
import { AddIcon } from 'elmo-elements';
import Collapse from 'reactstrap/lib/Collapse';
import './style.css';
import { TreeItem } from '../Tree';
import { isFunction } from 'lodash';

export interface TreeNodeProps {
  item: TreeItem;
  depth: number; // starts with 1
  dontIndentFirstChildren?: boolean;
  testId?: string;
}

export interface TreeNodeState {
  isOpen: boolean;
}

class TreeNode extends React.Component<TreeNodeProps, TreeNodeState> {
  constructor(props: TreeNodeProps) {
    super(props);

    this.state = {
      isOpen: !!props.item.isOpen
    };
  }

  componentDidUpdate() {
    if ((this.state.isOpen !== this.props.item.isOpen) && (this.props.item.isOpen !== undefined)) {
      this.setState({
        isOpen: this.props.item.isOpen
      });
    }
  }

  toggleCollapse() {
    if (this.props.item.isOpen !== undefined) {
      this.setState({
        isOpen: this.props.item.isOpen
      });
      return;
    }

    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onTreeNodeClick = () => {
    if (this.props.item.disabled) {
      return;
    }

    // collapse the node
    this.toggleCollapse();

    // run whatever needs to be run when the item is clicked
    if (this.props.item.onItemClick) {
      this.props.item.onItemClick(this.props.item);
    }
  }

  renderChildren(): JSX.Element[] {
    return this.props.item.children.map((child: TreeItem, key: number) => {
      return (
        <TreeNode
          item={child}
          key={key + '_' + child.renderKey}
          depth={this.props.depth + 1}
          dontIndentFirstChildren={this.props.dontIndentFirstChildren}
          testId={key + '_' + child.renderKey}
        />
      );
    });
  }

  render() {
    /**
     * Calculate the left padding for the component.
     * Depth starts from 1, the depth of the root node is 1
     * When dontIndentFirstChildren is true, then the root node and the first child have the same padding.
     * (paddingLeft = 10px)
     *
     * When dontIndentFirstChild is false, then the root node has a paddingLeft of 10px and the first child is indented
     * and has a padding of 20px.
     */
    let paddingLeft = this.props.dontIndentFirstChildren ? 10 + (Math.max(this.props.depth - 2, 0) * 10) :
      10 + ((this.props.depth - 1) * 10);

    let itemStyle = {
      paddingLeft: paddingLeft + 'px'
    };

    let itemSelectedClass = this.props.item.isSelected ? ' selected ' : '';
    let itemDisabledClass = this.props.item.disabled ? ' disabled ' : '';
    let showOpenStateIcon: boolean = (this.props.item.children.length > 0) || !!this.props.item.alwaysShowOpenStateIcon;
    let itemClass = this.props.item.itemClassName ? this.props.item.className : '';
    let itemClasses = itemSelectedClass + itemDisabledClass + ' ' + itemClass;

    let nodeClassNames = this.props.item.className ? this.props.item.className : '';

    return (
      <div className={'tree-node tree-node-' + this.props.depth + nodeClassNames} role="treeitem">
        <div
          className={'item d-flex flex-row align-items-center ' + itemClasses}
          onClick={this.onTreeNodeClick}
          style={itemStyle}
          role="menuitemradio"
          data-testid={this.props.testId ? 'tree-item-' + this.props.testId : null}
        >
            <div className="tree-icon">
              {
                this.props.item.showAddIcon &&
                <AddIcon size="xs" className="addIcon" />
              }
              {this.state.isOpen && showOpenStateIcon &&
              <i className="fa fa-chevron-down" />
              }
              {!this.state.isOpen && showOpenStateIcon &&
                <i className="fa fa-chevron-right" />
              }
              {this.props.item.isSelected && !showOpenStateIcon && this.props.item.showSelectedIcon &&
                <i className="fa fa-check" />
              }
            </div>
          {isFunction(this.props.item.content) && this.props.item.content(this.props.item)}
          {!isFunction(this.props.item.content) && this.props.item.content}
        </div>
        {(this.props.item.children.length > 0) &&
          <Collapse isOpen={this.state.isOpen}>
            {this.state.isOpen && this.renderChildren()}
          </Collapse>
        }
      </div>
    );
  }
}

export default TreeNode;
