import { SET_AUTH_SERVICE, SET_USER } from '../constants';
import { AuthService } from '../services/authService';
import { User } from '../types/userModel';

export interface AuthServiceAction {
  type: SET_AUTH_SERVICE;
  authService: AuthService;
}

export type UserAction = {
  type: SET_USER;
  user: User;
};

export function setAuthService(authService: AuthService) {
  return {
    type: SET_AUTH_SERVICE,
    authService: authService
  };
}

export function setUser(user: User) {
  return {
    type: SET_USER,
    user: user
  };
}