import * as React from 'react';
import './style.css';

export interface ClearButtonProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

class ClearButton extends React.Component<ClearButtonProps> {

  constructor(props: ClearButtonProps) {
    super(props);
  }

  onClick = (e: React.MouseEvent<HTMLElement>) => {
    this.props.onClick(e);
  }

  render() {
    let className = this.props.className ? this.props.className : '';

    return (
      <button
        className={'clear-btn btn btn-sm ' + className}
        onClick={this.onClick}
      >
        <i className="icon icon-closed" />
      </button>
    );
  }
}

export default ClearButton;