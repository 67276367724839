import {
  OccurrenceOption
} from '../../constants/addSchedule';
import { getValidationMessagesByPath } from '../../services/formService';
import { SCHEDULE_OPTIONS_VALIDATION_SCHEMA } from './scheduleOptionsValidationSchema';

export type ScheduleOptions = {
  scheduleId: string;
  name: string;
  occurrence: OccurrenceOption;
  queryId: string;
  targetUserIds?: any[];
  daysOfTheWeek: number[];
  calendarDays: number[];
  lastDayOfTheMonth: boolean;
  startDate: string | null;
  endDate: string | null;
  time: string | null;
  outboundDestinationId?: string | null;
};

export async function isScheduleOptionsValid(
    data: ScheduleOptions,
    isOutbound: boolean,
    isOutboundEmailNotification: boolean): Promise<any> {
  const validationOptions = {
    abortEarly: false,
    stripUnknown: true,
    context: {
      isOutbound: isOutbound,
      isOutboundEmailNotification: isOutboundEmailNotification
    }
  };

  try {
    await SCHEDULE_OPTIONS_VALIDATION_SCHEMA.validate(data, validationOptions);
  } catch (errors) {
    return getValidationMessagesByPath(errors);
  }

  return {};
}
