import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  EditConfigRelationsPage as EditConfigRelationsPageComponent
} from '../../components/Config/EditConfigRelationsPage';
import { NotificationService } from '../../services/notificationService';
import { AppState } from '../../redux';

export interface EditConfigRelationsPageProps extends RouteComponentProps<any> {
  notificationService: NotificationService;
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService
});

class EditConfigRelationsPage extends React.Component<EditConfigRelationsPageProps> {
  constructor(props: EditConfigRelationsPageProps) {
    super(props);
  }

  render() {
    return (
      <EditConfigRelationsPageComponent
        configId={this.props.match.params.configId}
        notificationService={this.props.notificationService}
      />
    );
  }
}

const EditConfigViewsPageContainer = withRouter(connect(
  mapStateToProps)(EditConfigRelationsPage));

export default EditConfigViewsPageContainer;
