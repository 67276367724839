import React, { ChangeEvent } from 'react';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { SearchInput } from '@eds/search-input';
import { Text } from '@eds/text';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

type DownloadsListFiltersProps = {
  displayScheduleName: boolean;
  headerDescription: string;
  onSearch: (value: string) => void;
  search: string;
};

export const DownloadsListFilters = ({ displayScheduleName, headerDescription, onSearch, search }: DownloadsListFiltersProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => onSearch(e.target.value);
  const onClear = () => onSearch('');

  return (
    <Flex
      className={css(cssOverrides.button('neutral'))}
      containerType="inline-size"
      gap="xsmall"
      flexWrap={{ container: { xsmall: 'wrap', small: 'nowrap' } }}
    >
      <Flex alignItems="center">
        <Text>{headerDescription}</Text>
      </Flex>
      <Box marginLeft="auto" width={{ container: { xsmall: '100%', small: '20rem' } }}>
        <SearchInput
          onChange={onChange}
          onClear={onClear}
          placeholder={displayScheduleName ? 'Search by schedule name or report...' : 'Search for reports...'}
          size="small"
          value={search}
        />
      </Box>
    </Flex>
  );
};
