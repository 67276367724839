import * as React from 'react';
import { apiGetConfigs } from '../../services/configurationService';
import Table from '../../shared-components/Table';
import { map } from 'lodash';
import ConfigAddConfigButtonModal from '../../components/Config/ConfigAddConfigButtonModal';
import LoadingIcon from '../../shared-components/LoadingIcon';
import { ConfigData } from '../../types/config/configDataModel';
import Page from '../../shared-components/Page';
import ModulesListing from './ModulesListing';

interface ModulesListingPageProps {

}

interface ModulesListingPageState {
  configurations: ConfigData[];
  loading: boolean;
}

export class ModulesListingPage extends React.Component<ModulesListingPageProps, ModulesListingPageState> {

  constructor(props: ModulesListingPageProps) {
    super(props);

    this.state = {
      configurations: [],
      loading: false
    };
  }

  async componentDidMount() {
    this.getConfigs();
  }

  async getConfigs() {
    this.setState({
      loading: true
    });

    try {
      let result = await apiGetConfigs();
      this.setState({
        configurations: result,
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  }

  renderHeader = () => {
    return (
      <tr>
        <th>Module</th>
        <th>Client</th>
        <th>Status</th>
        <th>Created By</th>
        <th>Configuration</th>
      </tr>
    );
  }

  renderRow = () => {
    return map(this.state.configurations, (configuration: ConfigData, key: number) => {
      return (
        <tr key={key}>
          <td>
            {configuration.module}
          </td>
          <td>
            {configuration.clientName ? configuration.clientName : 'All clients'}
          </td>
          <td>{configuration.getStatusString()}</td>
          <td>
            {configuration.user ? configuration.user.id : ''}
          </td>
          <td>
            <a
              href={'/config/' + configuration.id + '/edit-views'}
              data-testid={'edit-views-' + key}
            >
              Edit Views
            </a>
            &nbsp;|&nbsp;
            <a
              href={'/config/' + configuration.id + '/edit-relations'}
              data-testid={'edit-relations-' + key}
            >
              Edit Relations
            </a>
            &nbsp;|&nbsp;
            <a
              href={'/check-config/' + configuration.id}
              data-testid={'check-config-' + key}
            >
              Validate
            </a>
          </td>
        </tr>
      );
    });
  }

  onAddSuccess = () => {
    this.getConfigs();
  }

  render() {
    return (
      <Page title="Config Admin" className="configuration-layout module-listing-page">
        <div>
          <ModulesListing />
        </div>
        <h2>Configurations</h2>
        <div className="d-flex flex-row">
          <ConfigAddConfigButtonModal
            onAddSuccess={this.onAddSuccess}
          />
          <a
            href="/check-config"
            className="btn btn-primary"
          >
            Validate Configuration
          </a>
        </div>
        {this.state.loading &&
          <LoadingIcon/>
        }
        {!this.state.loading &&
          <Table
            renderTableHeaders={this.renderHeader}
            renderTableData={this.renderRow}
          />
        }
      </Page>
    );
  }
}