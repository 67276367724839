import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { ControlsProps } from '@eds/modal';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { Toast, toastMessage } from '@eds/toast';
import { space } from '@eds/core';
import { css } from '@emotion/css';

import { AlertModal } from 'src/features/ui';

import { useDeleteReportMutation, useReportDetailsQueryData, useReportRelationsQuery } from './delete-report.queries';

interface DeleteScheduledReportProps {
  onClose: () => void;
  reportId: string;
}

export const DeleteReport = ({ onClose, reportId }: DeleteScheduledReportProps) => {
  const [reportIsSharedWithCurrentUser, setReportIsSharedWithCurrentUser] = useState(false);
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const report = useReportDetailsQueryData(reportId);
  const { data: relations = { dashboards: [], schedules: [] }, isLoading } = useReportRelationsQuery(
    report?.id || '',
    !!report?.sharedBy && report.permissions.canShare === false
  );

  const { mutate } = useDeleteReportMutation(reportIsSharedWithCurrentUser, {
    onSettled: () => setReportIsSharedWithCurrentUser(false),
    onSuccess: () => setSuccessMessage(toastMessage('Report deleted')),
  });

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: {
      label: `Yes, ${reportIsSharedWithCurrentUser ? 'proceed' : 'delete'}`,
      loading: isLoading && !reportIsSharedWithCurrentUser,
      onClick: () => {
        mutate(reportId);
        // NOTE: Optimistic update - close the modal immediately without waiting for response
        onClose();
      },
      tone: 'critical',
    },
  };

  useEffect(() => {
    if (report?.id) setReportIsSharedWithCurrentUser(!!report.sharedBy && report.permissions.canShare === false);
  }, [report?.id]);

  return (
    <Box>
      <AlertModal
        controls={controls}
        description={
          <Flex flexDirection="column" gap="small">
            {reportIsSharedWithCurrentUser ? (
              <Text overflowY="auto" color="neutralSubtle" lineHeight="loose">
                {`This action will remove your access to ${
                  report?.name || 'the report'
                } by unsharing it. The report itself will not be deleted and will remain accessible to other users.`}
              </Text>
            ) : (
              <Flex flexDirection="column" gap="small">
                <Text overflowY="auto" color="neutralSubtle" lineHeight="loose">
                  {`This action will delete ${report?.name || 'the report'} permanently.`}
                </Text>
                {report?.sharedWith && report.sharedWith.length > 0 && (
                  <Text overflowY="auto" color="neutralSubtle" lineHeight="loose">
                    This report is currently being shared, and deleting it will restrict shared users from accessing it.
                  </Text>
                )}
                {relations.dashboards.length > 0 && (
                  <Flex flexDirection="column" color="neutralSubtle">
                    <Text overflowY="auto" lineHeight="loose">
                      This report is currently being used in saved dashboards, and deleting it will also will remove it
                      from the following dashboards:
                    </Text>
                    {relations.dashboards.map(({ dashboardId, title }) => (
                      <li className={css({ marginLeft: space.medium })} key={`${dashboardId}-${title}`}>
                        <Text overflowY="auto" lineHeight="loose">
                          {title}
                        </Text>
                      </li>
                    ))}
                  </Flex>
                )}
                {relations.schedules.length > 0 && (
                  <Flex flexDirection="column" color="neutralSubtle">
                    <Text overflowY="auto" lineHeight="loose">
                      This report is currently being scheduled, and deleting it will also delete the following
                      schedules:
                    </Text>
                    {relations.schedules.map(({ id, scheduleName }) => (
                      <li className={css({ marginLeft: space.medium })} key={`${id}-${scheduleName}`}>
                        <Text overflowY="auto" lineHeight="loose">
                          {scheduleName}
                        </Text>
                      </li>
                    ))}
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        }
        isOpen={!!reportId}
        onClose={onClose}
        title="Delete report?"
      />
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
