// https://stackoverflow.com/a/1830844
export const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

export const getCsvExpiryHoursText = (csvExpiryHours: string|null): string => {
    let csvExpiryHoursInt = 72;
    if (csvExpiryHours) {
        csvExpiryHoursInt = parseInt(csvExpiryHours, 10);
    }
    const csvExpiryInDays = csvExpiryHoursInt / 24;

    return `Reports can be downloaded within a ${csvExpiryInDays} days window, and this page will refresh automatically.`
}
