import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, JoinAndDimensions, JoinAndMeasures } from '../redux/index';
import { Module } from '../types/moduleModel';
import { getReportJson, loadQuery } from '../actions/report';
import { ReportingToolPage as ReportingToolPageComponent } from '../components/ReportingToolPage';
import { SavedQuery } from '../types/savedQueryModel';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { resetReportAction } from '../actions/reportBuilder';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES, GENERATE_ACTION } from '../constants';
import { View } from '../types/viewModel';
import { SuggestedReportState } from '../redux';
import { noop } from 'lodash';
import { ReportTable } from '../types/reportTableModel';
import { Chart } from '../types/chartModel';
import { ReportViewOnlyPage as ReportViewOnlyPageComponent } from '../components/ReportViewOnlyPage';
import { ReportPrintPage as ReportPrintPageComponent } from '../components/ReportPrintPage';
import SavedReportLoading from '../components/ReportBuilder/SavedReportLoading/SavedReportLoading';
import { AppMenu } from '../types/appMenuModel';

interface ReportingToolPageProps {
  dispatch: Dispatch<any>;
  module: Module;
  query: string;
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  selectedDimensions: Dimension[];
  selectedMeasures: Measure[];
  joinDimensions: JoinAndDimensions[];
  joinMeasures: JoinAndMeasures[];
  selectedView: View;
  report: ReportTable;
  suggestedReports: SuggestedReportState;
  generateReportStatus: GENERATE_ACTION;
  chart: Chart;
  readOnly: boolean;
  queryId: string;
  printView?: boolean;
  savedQuery?: SavedQuery;
  appMenu: AppMenu;
}

interface ReportingToolPageState {
  loading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  module: state.module,
  dataType: state.dataType,
  query: state.query,
  selectedDimensions: state.dimensions,
  selectedMeasures: state.measures,
  joinDimensions: state.joinDimensions,
  joinMeasures: state.joinMeasures,
  selectedView: state.view,
  report: state.report,
  suggestedReports: state.suggestedReports,
  generateReportStatus: state.generateReportStatus,
  chart: state.chart,
  savedQuery: state.savedQuery,
  appMenu: state.appMenu,
});

class ReportingToolPageContainer extends React.Component<ReportingToolPageProps, ReportingToolPageState> {
  constructor(props: ReportingToolPageProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    if (this.props.queryId) {
      this.loadQueryAndGenerateReport(this.props.queryId);
    } else {
      // reset the redux state
      this.props.dispatch(resetReportAction());
    }
  }

  componentWillUnmount() {
    this.onRequestFinally = noop;
  }

  async loadQueryAndGenerateReport(reportId: string) {
    this.setState({
      loading: true
    });

    try {
      await this.props.dispatch(loadQuery(reportId));
      await this.props.dispatch(getReportJson(false, reportId));
    } finally {
      this.onRequestFinally();
    }
  }

  onRequestFinally = () => {
    this.setState({ loading: false });
  }

  render() {
    const { query, queryId, readOnly, printView } = this.props;
    const showLoading = this.state.loading;

    return (
      <>
        {showLoading &&
          <SavedReportLoading
            queryId={queryId}
            showExport={!!query}
          />
        }
        <div className={(showLoading ? 'd-none' : '')}>
          {readOnly && !printView && this.renderReadOnly()}
          {!readOnly && !printView && this.renderReportTool()}
          {printView && this.renderPrintView()}
        </div>
      </>
    );
  }

  renderReportTool() {
    return (
      <ReportingToolPageComponent
        loadedQuery={this.props.savedQuery}
        encodedQuery={this.props.query}
        selectedView={this.props.selectedView}
        suggestionsOpen={this.props.suggestedReports.isOpen}
        generateReportStatus={this.props.generateReportStatus}
        reportResult={this.props.report}
        chart={this.props.chart}
        queryId={this.props.queryId}
        appMenu={this.props.appMenu}
      />
    );
  }

  renderReadOnly() {
    return (
      <ReportViewOnlyPageComponent
        loadedQuery={this.props.savedQuery}
        encodedQuery={this.props.query}
        selectedView={this.props.selectedView}
        suggestionsOpen={false}
        generateReportStatus={this.props.generateReportStatus}
        reportResult={this.props.report}
        chart={this.props.chart}
        queryId={this.props.queryId}
      />
    );
  }

  renderPrintView() {
    return (
      <ReportPrintPageComponent
        loadedQuery={this.props.savedQuery}
        encodedQuery={this.props.query}
        selectedView={this.props.selectedView}
        suggestionsOpen={false}
        generateReportStatus={this.props.generateReportStatus}
        reportResult={this.props.report}
        chart={this.props.chart}
      />
    );
  }
}

const ReportingToolPage = connect(
  mapStateToProps,
)(ReportingToolPageContainer);

export default ReportingToolPage;
