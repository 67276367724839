import * as React from 'react';
import classnames from 'classnames';
import { DataTable } from 'elmo-elements';
import { DataTableProps } from 'elmo-elements/DataTable';

import './style.scss';

const ElmoDataTable: React.FC<DataTableProps> = ({ children, className, ...props }) => {
  return <DataTable className={classnames('elmo-datatable--override', className)} {...props}>{children}</DataTable>;
};

export default ElmoDataTable;