import * as React from 'react';

interface TableProps {
  renderTableHeaders: () => {};
  renderTableData: () => {};
}

class Table extends React.Component<TableProps> {
  constructor(props: TableProps) {
    super(props);
  }

  render() {
    return (
      <table className="table table-hover " role="table">
        <thead>
          {this.props.renderTableHeaders()}
        </thead>
        <tbody>
          {this.props.renderTableData()}
        </tbody>
      </table>
    );
  }
}

export default Table;