import * as React from 'react';
import moment from 'moment';
import { Timepicker, FormItem, Input, Text } from 'elmo-elements';
import { useSelector } from 'react-redux';

import { AppState } from '../../../redux';
import { AuthService } from '../../../services/authService';
import { Moment } from '../../../constants/moment';
import { OccurrenceOption } from '../../../constants/addSchedule';
import OccurrenceSelect from '../../../components/AddScheduleModal/OccurrenceSelect';
import DaysOfWeekSelect from '../../../components/AddScheduleModal/DaysOfWeekSelect';
import DaysOfMonthSelect from '../../../components/AddScheduleModal/DaysOfMonthSelect';
import DateSelect from '../../../components/AddScheduleModal/DateSelect';
import { SavedQuery } from '../../../types/savedQueryModel';

import './style.css';

type Props = {
  loadedQuery: SavedQuery
  scheduleName: string;
  scheduleTimezone: string;
  onScheduleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onDateChange: (isStartDate: boolean) => (d: Moment | null) => void;
  time: moment.Moment | undefined;
  onTimeChange: (d: Moment | null) => void;
  occurrence: OccurrenceOption;
  onOccurrenceSelect: (option: OccurrenceOption) => () => void;
  daysOfWeek: boolean[];
  onDaysOfWeekToggle: (option: number) => () => void;
  dayOfMonth: string;
  onDayOfMonthSelect: (option: string) => () => void;
  errors: any;
};

function AddScheduleForm(props: Props) {
  const {
    loadedQuery,
    occurrence,
    onOccurrenceSelect,
    scheduleName,
    scheduleTimezone,
    onScheduleNameChange,
    daysOfWeek,
    onDaysOfWeekToggle,
    dayOfMonth,
    onDayOfMonthSelect,
    startDate,
    endDate,
    onDateChange,
    time,
    onTimeChange,
    errors
  } = props;

  const authService: AuthService | null = useSelector((state: AppState) => state.authService);
  const enableScheduleTime = !!authService && authService.enableScheduleTime();
  const userTimezone = !!authService && authService.getUserTimezone();
  const timezone = scheduleTimezone ? scheduleTimezone : userTimezone;
  const timepickerMinuteStep = 60;

  return (
    <>
      <FormItem label="Report Name:">
        <div data-testid="schedule-name">
          <Text size="rg">{loadedQuery.name}</Text>
        </div>
      </FormItem>

      <FormItem
        label="Schedule name:"
        message={!!errors['name'] ? errors['name'] : undefined}
        status={!!errors['name'] ? 'error' : undefined}
        data-testid="schedule-end-date"
      >
        <Input
          // @ts-ignore
          type="text"
          className="form-control"
          id="schedule-name"
          data-testid="schedule-name"
          value={scheduleName}
          onChange={onScheduleNameChange}
          required={true}
        />
      </FormItem>

      <OccurrenceSelect
        onSelect={onOccurrenceSelect}
        selectedItem={occurrence}
        errors={errors}
      />

      <DaysOfWeekSelect
        occurrence={occurrence}
        selected={daysOfWeek}
        onToggle={onDaysOfWeekToggle}
        errors={errors}
      />

      <DaysOfMonthSelect
        occurrence={occurrence}
        selected={dayOfMonth}
        onSelect={onDayOfMonthSelect}
        errors={errors}
      />

      <DateSelect
        occurrence={occurrence}
        onDateChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        errors={errors}
      />

      {enableScheduleTime &&
        <FormItem
          label={`Time(${timezone}):`}
          message={!!errors['time'] ? errors['time'] : undefined}
          status={!!errors['time'] ? 'error' : undefined}
          data-testid="schedule-time"
        >
          <Timepicker
            onChange={onTimeChange}
            name={'time'}
            value={time}
            minuteStep={timepickerMinuteStep}
          />
        </FormItem>
      }
    </>
  );
}

export default AddScheduleForm;
