import * as React from 'react';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { AddIcon, FilterIcon } from '@eds/icon';
import { Box } from '@eds/box';
import { Button, CustomButton } from '@eds/button';
import { Flex } from '@eds/flex';
import { NumberBadge } from '@eds/badge';
import { SelectDropdown } from '@eds/select-dropdown';
import { css } from '@emotion/css';

import { 
  ConcatenationConfigSetup,
  ConcatenationConfiguration,
  DeprecationIcon,
  useDeprecationContext,
} from 'src/features/report-builder';
import { cssOverrides } from 'src/features/ui';

import { AuthService } from '../../../services/authService';
import FilterGroupSelect from '../../../containers/NewReportBuilder/FilterGroupSelect';
import FilterSelect from '../../../containers/NewReportBuilder/FilterSelect';
import { AppState } from '../../../redux';
import { UserCapabilitiesType } from '../../../services/reportService';
import { DATA_TYPE_DIMENSIONS, MODULE_TYPES } from '../../../constants';
import ElmoDivider from '../ElmoDivider';
import ReportTransformation from '../ReportTransformation';
import TranslationConfigurationCreateEdit from '../ReportTransformationCreateEdit/TranslationConfigurationCreateEdit';
import DatetimeConfigurationCreateEdit from '../ReportTransformationCreateEdit/DatetimeConfigurationCreateEdit';
import useDimensionLabel from '../../hooks/useDimensionLabel';
import { 
  ConfigurationType,
  DatetimeConfiguration,
  TranslationConfiguration
} from '../../../types/transformationModel';

import './style.scss';

type ReportDataProcessingSection =
  | 'filter'
  | 'transformation'
  | 'translationConfigurationCreateEditOpen'
  | 'datetimeConfigurationEditOpen'
  | 'concatenationConfigSetupOpen';

const isValidConcatenationConfig = (
  data: ConcatenationConfiguration | DatetimeConfiguration | TranslationConfiguration
): data is ConcatenationConfiguration => (data as ConcatenationConfiguration).type === ConfigurationType.Concatenation;

const isValidDatetimeConfig = (
  data: ConcatenationConfiguration | DatetimeConfiguration | TranslationConfiguration
): data is DatetimeConfiguration => !!(data as DatetimeConfiguration).format;

const isValidTranslationConfig = (
  data: ConcatenationConfiguration | DatetimeConfiguration | TranslationConfiguration
): data is TranslationConfiguration => Array.isArray((data as TranslationConfiguration).rules);

interface Props {
  canManageFilter?: boolean;
  userCapabilities?: UserCapabilitiesType;
}

const ReportDataProcessing = ({ canManageFilter, userCapabilities }: Props) => {
  const [collapseToggle, setCollapseToggle] = React.useState<Record<string, boolean>>({});
  const [transformationData, setTransformationData] = React.useState<
    undefined | TranslationConfiguration | DatetimeConfiguration | ConcatenationConfiguration
  >();

  const {
    dataType,
    filterOptions,
    module,
    columnMeta,
    transformations,
    savedQuery,
  } = useSelector((state: AppState) => state);

  const getDimensionLabel = useDimensionLabel();
  const { deprecatedConfigurations = [], hasDeprecatedFilters } = useDeprecationContext() || {};

  const [_translationFieldOptions, _datetimeFieldOptions] = _.partition(
    columnMeta.filter((column) => column.dimension),
    (column) => column.dimension!.type !== 'datetime',
  );

  const translationFieldOptions = _translationFieldOptions.map(({ join, dimension }) => {
    const dimensionIdentifier = `${join ? `${join.fullJoinName}.` : ''}${dimension!.name}`;
    const [name, alias] = getDimensionLabel(dimensionIdentifier);

    return {
      value: dimensionIdentifier,
      type: dimension!.type,
      label: alias || name || 'Label Missing'
    };
  });

  const datetimeFieldOptions = _datetimeFieldOptions.map(({ join, dimension }) => {
    const dimensionIdentifier = `${join ? `${join.fullJoinName}.` : ''}${dimension!.name}`;
    const [name, alias] = getDimensionLabel(dimensionIdentifier);

    return {
      value: dimensionIdentifier,
      type: dimension!.type,
      label: alias || name || 'Label Missing'
    };
  });

  const displayManageColumnTransformation =
    userCapabilities &&
    userCapabilities.report.canManageColumnTransformation &&
    module.type === MODULE_TYPES.redshift &&
    dataType === DATA_TYPE_DIMENSIONS;

  // We use this blanket condition to determine if the user has selected any field while creating the report
  // Could be improved to reflect more direct logic instead of relying on filerOptions
  const displayDataProcessingSection = filterOptions && !!filterOptions.length;

  const isFilterOpen = collapseToggle['filter'];
  const isTransformationOpen = collapseToggle['transformation'];
  const isTranslationConfigurationCreateEditOpen = collapseToggle['translationConfigurationCreateEditOpen'];
  const isDatetimeConfigurationEditOpen = collapseToggle['datetimeConfigurationEditOpen'];
  const isConcatenationConfigSetupOpen = collapseToggle['concatenationConfigSetupOpen'];

  const toggleCollapse = (section: ReportDataProcessingSection) => () => {
    if (collapseToggle[section]) {
      setCollapseToggle({});
      return;
    }

    setCollapseToggle({ [section]: true });
  };

  const toggleCollapseOpen = (section: ReportDataProcessingSection) => () => {
    setCollapseToggle({ [section]: true });
  };

  const transformationConfigCount = transformations.reduce(
    (configCount, { configuration }) => configCount + configuration.length,
    0
  );

  const enableAndOrFilter = AuthService.getInstance().enableAndOrFilter();

  const filterGroupsEnabled = 
    enableAndOrFilter && module.type === MODULE_TYPES.redshift && dataType === DATA_TYPE_DIMENSIONS;

  const configurationsButtonDisabled = transformations.length === 0;

  return (
    <>
      <div
        className={classnames('report-data-processing', { 'is-open': isFilterOpen })}
        data-tracking="report-data-processing"
      >
        {displayDataProcessingSection &&
          <Flex className={css(cssOverrides.button())}>
            <Button
              icon={hasDeprecatedFilters ? DeprecationIcon : FilterIcon}
              label="Filter"
              onClick={toggleCollapse('filter')}
              size="small"
              tone="neutral"
            />
          </Flex>
        }
        { displayDataProcessingSection &&
          displayManageColumnTransformation &&
          <>
            <ElmoDivider height="30" />
            <Flex className={css(cssOverrides.button('neutral', configurationsButtonDisabled))}>
              {deprecatedConfigurations.length > 0 ? (
                <Button
                  disabled={configurationsButtonDisabled}
                  icon={DeprecationIcon}
                  label="Configurations"
                  onClick={transformations.length > 0 ? toggleCollapse('transformation') : undefined}
                  size="small"
                  tone="neutral"
                />
              ) : (
                <CustomButton
                  disabled={configurationsButtonDisabled}
                  onClick={transformations.length > 0 ? toggleCollapse('transformation') : undefined}
                  size="small"
                  tone="neutral"
                >
                  <Flex gap="xsmall" alignItems="center">
                    Configurations
                    <NumberBadge number={transformationConfigCount} weight="subtle" />
                  </Flex>
                </CustomButton>
              )}
            </Flex>
            <Box className={css(cssOverrides.button('neutral'))}>
              <SelectDropdown
                hideSearchInput
                trigger={ref => (
                  <Button
                    data-tracking="create-configuration"
                    ref={ref}
                    size="small"
                    label="Create configuration"
                    icon={AddIcon}
                    tone="neutral"
                  />
                )}
                items={[
                  { label: 'Translation', value: 'translation' },
                  { label: 'Date time', value: 'datetime' },
                  { label: 'Concatenation', value: 'concatenation' },
                ]}
                onChange={(value: { value: string }) => {
                  switch (value.value) {
                    case 'translation': {
                      setTransformationData(undefined);
                      toggleCollapseOpen('translationConfigurationCreateEditOpen')();
                      break;
                    }
                    case 'datetime': {
                      setTransformationData(undefined);
                      toggleCollapseOpen('datetimeConfigurationEditOpen')();
                      break;
                    }
                    case 'concatenation': {
                      setTransformationData(undefined);
                      toggleCollapseOpen('concatenationConfigSetupOpen')();
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }}
              />
            </Box>
          </>
        }
      </div>
      {filterGroupsEnabled &&
        <FilterGroupSelect
          isOpen={isFilterOpen}
          toggle={toggleCollapse('filter')}
          canManageFilter={canManageFilter}
        />
      }
      {!filterGroupsEnabled &&
        <FilterSelect
          isOpen={isFilterOpen}
          toggle={toggleCollapse('filter')}
          canManageFilter={canManageFilter}
        />
      }
      <TranslationConfigurationCreateEdit
        isCreateNew={!transformationData}
        data={transformationData && isValidTranslationConfig(transformationData) ? transformationData : undefined}
        reportId={savedQuery && savedQuery.id}
        fieldOptions={translationFieldOptions}
        isOpen={isTranslationConfigurationCreateEditOpen}
        toggle={toggleCollapse('translationConfigurationCreateEditOpen')}
      />
      <DatetimeConfigurationCreateEdit
        isCreateNew={!transformationData}
        data={transformationData && isValidDatetimeConfig(transformationData) ? transformationData : undefined}
        reportId={savedQuery && savedQuery.id}
        fieldOptions={datetimeFieldOptions}
        isOpen={isDatetimeConfigurationEditOpen}
        toggle={toggleCollapse('datetimeConfigurationEditOpen')}
      />
      <ConcatenationConfigSetup
        data={transformationData && isValidConcatenationConfig(transformationData) ? transformationData : undefined}
        onClose={toggleCollapse('concatenationConfigSetupOpen')}
        open={isConcatenationConfigSetupOpen}
        reportId={savedQuery?.id}
      />
      <ReportTransformation
        isOpen={isTransformationOpen}
        toggle={toggleCollapse('transformation')}
        onEdit={(type, data, transformationId) => {
          switch (type) {
            case ConfigurationType.Datetime: {
              setTransformationData(data as DatetimeConfiguration);
              toggleCollapseOpen('datetimeConfigurationEditOpen')();
              break;
            }
            case ConfigurationType.Translation: {
              setTransformationData(data as TranslationConfiguration);
              toggleCollapseOpen('translationConfigurationCreateEditOpen')();
              break;
            }
            case ConfigurationType.Concatenation: {
              setTransformationData({
                configuration: [data] as ConcatenationConfiguration['configuration'],
                id: transformationId,
                type,
              });
              
              toggleCollapseOpen('concatenationConfigSetupOpen')();
              break;
            }
            default: {
              break;
            }
          }
        }}
      />
    </>
  );
};

export default ReportDataProcessing;
