import { DataTypeAction, ModuleAction, QueryAction } from '../actions/module';
import { ViewAction, ViewsAction } from '../actions/view';
import {
  AppState,
  getInitialReportPaginationDataState,
  getInitialSaveQueryState,
  getInitialSuggestedReportsState,
  ReportDetails,
  SaveQueryState,
  ViewJoinAndDimensionOrMeasure
 } from '../redux';
import {
  CANCEL,
  DATA_TYPE_DIMENSIONS,
  DATA_TYPE_MEASURES,
  DEFAULT_REPORT_LIMIT,
  FETCHED_REPORT_DETAILS,
  FETCHED_REPORT_JOBID,
  FETCHED_REPORT_PAGINATION_DATA,
  FETCHED_VIEWS,
  GENERATE_ACTION,
  GENERATE_REPORT_FINISHED,
  GENERATE_REPORT_START,
  GENERATE_REPORT_SUCCESS,
  HYDRATE_STORE,
  REPLACE_FILTERS,
  RESET_REPORT_BUILDER,
  RESET_REPORT_BUILDER_COLUMNS,
  SAVE_QUERY_ERROR,
  SAVE_QUERY_START,
  SAVE_QUERY_SUCCESS,
  STORE_CANCEL_SOURCE,
  STORE_DATA_TYPE,
  STORE_FILTER,
  STORE_FILTER_OPTIONS,
  STORE_MODULE,
  STORE_OFFSET,
  STORE_ORDER_BY,
  STORE_QUERY,
  STORE_REPORT_LIMIT,
  STORE_REPORT_TITLE,
  STORE_SAVED_QUERY,
  STORE_TEMP_QUERY,
  STORE_SELECTED_COUNTRY,
  STORE_VIEW, GENERATE_REPORT_CANCELED
} from '../constants';
import { combineReducers } from 'redux';
import {
  GenerateAction,
  HydrateStoreAction,
  ReportDetailsAction,
  ReportPaginationDataAction,
  ReportTitleAction,
  StoreCancelSourceAction
 } from '../actions/report';
import { View } from '../types/viewModel';
import { Filter } from '../types/filterModel';
import { FilterAction, FilterOptionsAction, FiltersAction } from '../actions/filter';
import { getViewJoinAndDimensionOrMeasureObjs } from '../services/viewService';
import { OrderBy } from '../types/orderByModel';
import { OrderByAction } from '../actions/orderBys';
import { SavedQueryAction, SaveQueryAction, TempQueryAction, SelectCountryAction } from '../actions/savedQueries';
import { ReportBuilderAction } from '../actions/reportBuilder';
import { joinDimensionsReducer } from './joinDimensions';
import { joinMeasuresReducer } from './joinMeasures';
import { dimensionsReducer } from './dimensions';
import { measuresReducer } from './measures';
import { notificationServiceReducer, notificationsReducer } from './notifications';
import { authServiceReducer, userReducer } from './auth';
import { downloadsReducer } from './downloads';
import { columnMetaReducer } from './columnMeta';
import { Module } from '../types/moduleModel';
import { Chart } from '../types/chartModel';
import { suggestedReportsReducer } from './suggestedReports';
import { moduleReducer, modulesReducer } from './modules';
import { OffsetAction, ReportLimitAction } from '../actions/reportOffsetLimit';
import { CancelTokenSource } from 'axios';
import { ReportTable } from '../types/reportTableModel';
import { reportReducer } from './report';
import { chartReducer } from './chart';
import { groupBysReducer } from '../reducers/groupBys';
import { resetReportBuilderColumns } from '../reducers/rootReducers';
import { SavedQuery } from '../types/savedQueryModel';
import { ReportPaginationData } from 'src/types/reportPaginationDataModel';
import { routesReducer } from './routes';
import { translationsReducer } from '../reducers/translations';
import { AppMenu } from '../types/appMenuModel';
import { appMenuReducer } from '../reducers/appMenu';
import { drilldownModalReducer } from '../reducers/drilldownModal';
import { initialDrilldownModalState } from '../types/drilldownModalModel';
import { SupportedCountriesType } from '../services/authService';
import { customColumnsReducer } from './customColumns';
import { transformationsReducer } from './transformations';
import { filterGroupsReducer } from './filterGroups';
import { getUnselectedFieldOptions } from 'src/features/report-builder/filters';

export function getInitialModuleState(): Module {
  return {
    name: '',
    canImport: false,
    id: '',
    label: '',
    type: '',
  };
}

export function getInitialState(): AppState {
  return {
    appMenu: new AppMenu(),
    modules: [],
    module: getInitialModuleState(),
    dataType: DATA_TYPE_DIMENSIONS,
    query: '',
    view: new View(),
    report: new ReportTable(),
    views: [],
    measures: [],
    dimensions: [],
    joinDimensions: [],
    joinMeasures: [],
    groupBys: [],
    filterGroups: [],
    filters: [],
    filterOptions: [],
    orderBys: [],
    columnMeta: [],
    saveQuery: getInitialSaveQueryState(),
    offset: 0,
    notifications: [],
    notificationService: null,
    reportDetails: null,
    authService: null,
    downloads: [],
    chart: new Chart(),
    suggestedReports: getInitialSuggestedReportsState(),
    reportTitle: '',
    reportLimit: DEFAULT_REPORT_LIMIT,
    generateReportStatus: GENERATE_REPORT_FINISHED,
    storeCancelSource: null,
    tempQuery: null,
    savedQuery: null,
    selectedCountries: [],
    reportPaginationData: getInitialReportPaginationDataState(),
    routes: null,
    translations: null,
    user: null,
    drilldownModal: initialDrilldownModalState,
    customColumns: [],
    transformations: []
  };
}

const notDeleteKeysDuringResetState = ['appMenu', 'selectedCountries'];

export function getPartialResetState(state: AppState, notDeleteKeys: Array<string>) {
  const notDeleteItems = {};
  notDeleteKeys.forEach(key => {
    notDeleteItems[key] = state[key];
  });
  return Object.assign({}, getResetState(), notDeleteItems);
}

export function getResetState() {
  // Doesn't reset these:
  let resetState = Object.assign({}, getInitialState());

  const toDelete = [
    'notifications',
    'notificationService',
    'authService',
    'modules',
    'routes',
    'user',
    'translations'
  ];

  toDelete.forEach((keyName: string) => {
    delete resetState[keyName];
  });

  return resetState;
}

export function query(state: string = '',
                      action: QueryAction): string {
  switch (action.type) {
    case STORE_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function dataType(state: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES = DATA_TYPE_DIMENSIONS,
                         action: DataTypeAction): DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES {
  switch (action.type) {
    default:
      return state;
  }
}

export function views(state: View[] = [], action: ViewsAction): View[] {
  switch (action.type) {
    case FETCHED_VIEWS:
      return action.views;
    default:
      return state;
  }
}

export function view(state: View = new View(), action: ViewAction): View {
  switch (action.type) {
    case STORE_VIEW:
      return action.view;
    case FETCHED_VIEWS:
      return new View();
    default:
      return state;
  }
}

export function filters(state: Filter[] = [], action: FilterAction | FiltersAction): Filter[] {
  switch (action.type) {
    case STORE_FILTER:
      return [...state, action.filter];
    case REPLACE_FILTERS:
      return [...action.filters];
    default:
      return state;
  }
}

export function filterOptions(state: ViewJoinAndDimensionOrMeasure[] = [], action: FilterOptionsAction)
  : ViewJoinAndDimensionOrMeasure[] {
  return state;
}

export function orderBys(state: OrderBy[] = [], action: OrderByAction): OrderBy[] {
  switch (action.type) {
    case STORE_ORDER_BY:
      return [action.orderBy];
    default:
      return state;
  }
}

export function saveQuery(state: SaveQueryState = getInitialSaveQueryState(), action: SaveQueryAction) {
  switch (action.type) {
    case SAVE_QUERY_START:
      return {...state, saving: true, showSaveError: false};
    case SAVE_QUERY_SUCCESS:
      return {...state, saving: false, showSaveError: false};
    case SAVE_QUERY_ERROR:
      return {...state, saving: false, showSaveError: true};
    default:
      return state;
  }
}

export function offset(state: number = 0, action: OffsetAction) {
  switch (action.type) {
    case STORE_OFFSET:
      return action.offset;
    default:
      return state;
  }
}

export function reportLimit(state: number = 0, action: ReportLimitAction) {
  switch (action.type) {
    case STORE_REPORT_LIMIT:
      return action.limit;
    default:
      return state;
  }
}

export function reportDetails(state: ReportDetails | null = null, action: ReportDetailsAction) {
  switch (action.type) {
    case FETCHED_REPORT_DETAILS:
      return action.reportDetails;
    default:
      return state;
  }
}

export function reportTitle(state: string = '', action: ReportTitleAction) {
  switch (action.type) {
    case STORE_REPORT_TITLE:
      return action.reportTitle;
    default:
      return state;
  }
}

export function generateReportStatus(state: GENERATE_ACTION = GENERATE_REPORT_FINISHED, action: GenerateAction) {
  switch (action.type) {
    case GENERATE_REPORT_START:
      return GENERATE_REPORT_START;
    case GENERATE_REPORT_SUCCESS:
      return GENERATE_REPORT_SUCCESS;
    case GENERATE_REPORT_CANCELED:
      return GENERATE_REPORT_CANCELED;
    case GENERATE_REPORT_FINISHED:
      return GENERATE_REPORT_FINISHED;
    case FETCHED_REPORT_JOBID:
      return FETCHED_REPORT_JOBID;
    default:
      return state;
  }
}

export function storeCancelSource(state: CancelTokenSource | null = null, action: StoreCancelSourceAction) {
  switch (action.type) {
    case STORE_CANCEL_SOURCE:
      return action.cancelTokenSource;
    case CANCEL:
      if (state) {
        state.cancel(action.message);
      }
      return null;
    default:
      return state;
  }
}

export function tempQuery(state: SavedQuery | null = null, action: TempQueryAction) {
  switch (action.type) {
    case STORE_TEMP_QUERY:
      return action.tempQuery;
    default:
      return state;
  }
}

export function savedQuery(state: SavedQuery | null = null, action: SavedQueryAction) {
  switch (action.type) {
    case STORE_SAVED_QUERY:
      return action.savedQuery;
    default:
      return state;
  }
}

export function selectCountry(state: SupportedCountriesType = [], action: SelectCountryAction) {
  switch (action.type) {
    case STORE_SELECTED_COUNTRY:
      return action.selectedCountries;
    default:
      return state;
  }
}

export function reportPaginationData(state: ReportPaginationData = getInitialReportPaginationDataState(),
                                     action: ReportPaginationDataAction) {
  switch (action.type) {
    case FETCHED_REPORT_PAGINATION_DATA:
      return action.pagination;
    default:
      return state;
  }
}

const reportingApp = combineReducers<AppState>({
  appMenu: appMenuReducer,
  modules: modulesReducer,
  module: moduleReducer,
  query: query,
  dataType: dataType,
  view: view,
  views: views,
  report: reportReducer,
  dimensions: dimensionsReducer,
  joinDimensions: joinDimensionsReducer,
  measures: measuresReducer,
  joinMeasures: joinMeasuresReducer,
  groupBys: groupBysReducer,
  filterGroups: filterGroupsReducer,
  filters: filters,
  filterOptions: filterOptions,
  orderBys: orderBys,
  columnMeta: columnMetaReducer,
  saveQuery: saveQuery,
  offset: offset,
  reportLimit: reportLimit,
  reportDetails: reportDetails,
  notifications: notificationsReducer,
  notificationService: notificationServiceReducer,
  authService: authServiceReducer,
  downloads: downloadsReducer,
  chart: chartReducer,
  suggestedReports: suggestedReportsReducer,
  reportTitle: reportTitle,
  generateReportStatus: generateReportStatus,
  storeCancelSource: storeCancelSource,
  tempQuery: tempQuery,
  savedQuery: savedQuery,
  selectedCountries: selectCountry,
  reportPaginationData: reportPaginationData,
  routes: routesReducer,
  user: userReducer,
  translations: translationsReducer,
  drilldownModal: drilldownModalReducer,
  customColumns: customColumnsReducer,
  transformations: transformationsReducer
});

// Added this as a way to reset parts of state. In general we should not use a catch all reducer
const rootReducer =
  (state: AppState,
   action: ModuleAction |
           ViewAction |
           FilterOptionsAction |
           ReportBuilderAction |
           DataTypeAction |
           HydrateStoreAction) => {

  if (action.type === RESET_REPORT_BUILDER) {
    Object.assign(state, getPartialResetState(state, notDeleteKeysDuringResetState));
  }

  if (action.type === STORE_DATA_TYPE) {
    const { name, type } = state.module;
    let stateViews = state.views;
    let stateView = state.view;
    let suggestionModalOpen: boolean = state.suggestedReports.isOpen;
    Object.assign(state, getPartialResetState(state, notDeleteKeysDuringResetState));
    // NOTE: Not sure what is the intention of the original logic, why should the selected module be reset as well?
    // Anyway we need the name and type so preserve these values
    state.module.name = name;
    state.module.type = type;
    state.view = stateView;
    state.views = stateViews;
    state.dataType = action.dataType;
    state.suggestedReports.isOpen = suggestionModalOpen;
  }

  /**
   * When module has been changed
   */
  if (action.type === STORE_MODULE) {
    let suggestionModalOpen: boolean = state.suggestedReports.isOpen;
    let keepDataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES = state.dataType;
    Object.assign(state, getPartialResetState(state, notDeleteKeysDuringResetState));
    state.module = action.module;
    state.dataType = keepDataType;
    state.suggestedReports.isOpen = suggestionModalOpen;
  }

  if (action.type === STORE_VIEW) {
    let keepModule = state.module;
    let keepViews = state.views;
    let keepDataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES = state.dataType;
    let suggestionModalOpen: boolean = state.suggestedReports.isOpen;
    Object.assign(state, getPartialResetState(state, notDeleteKeysDuringResetState));
    state.module = keepModule;
    state.view = action.view;
    state.views = keepViews;
    state.dataType = keepDataType;
    state.suggestedReports.isOpen = suggestionModalOpen;
  }

  /**
   * When updating filterOptions, we aim to avoid removing items as much as possible. If an item is added after fields
   * are added, we allow it to remain in the list even if the added field is removed.
   * 
   * NOTE: Further modifications to the filter logic are not recommended. This is a technical debt that needs to be
   * addressed on its own.
   */
  if (action.type === STORE_FILTER_OPTIONS) {
    const currentFilterOptions = [...state.filterOptions];

    /**
     * NOTE: This is the original function that returns only the filterOptions for the currently selected fields. When
     * a field is deselected, the filter for that field will also be removed. The filter function has since been
     * improved, allowing filters to be added even when the field is not selected.
     */
    state.filterOptions = getViewJoinAndDimensionOrMeasureObjs(
      state.dimensions,
      state.joinDimensions,
      state.measures,
      state.joinMeasures,
      state.view,
      state.views,
      state.groupBys
    );

    const supportedFilters = getUnselectedFieldOptions({
      baseView: state.view,
      columnMeta: state.columnMeta,
      currentView: state.view,
      dataType: state.dataType,
      filterOptions: state.filterOptions,
      views: state.views,
    });

    /**
     * NOTE: At this point, we want to re-add the removed filterOptions, but only if they are valid — that is, they are
     * not already in the list and are within 2 levels of the view tree (i.e., included in 'supportedFilters').
     */
    state.filterOptions = [
      ...state.filterOptions,
      ...currentFilterOptions
        .filter(({ dimension, join, measure, view }) => {
          const dimensionIdentifier = `${join ? `${join.fullJoinName}.` : `${view.name}.`}${
            measure ? measure.name : dimension!.name
          }`;

          return supportedFilters.find(({ value }) => dimensionIdentifier === value);
        })
        // NOTE: Make sure that there are no dupes
        .reduce<ViewJoinAndDimensionOrMeasure[]>((filterOptions, current) => {
          const { dimension, join, measure, view } = current;
          const dimensionIdentifier = `${join ? `${join.fullJoinName}.` : `${view.name}.`}${
            measure ? measure.name : dimension!.name
          }`;

          if (
            filterOptions.find(
              item =>
                dimensionIdentifier ===
                `${item.join ? `${item.join.fullJoinName}.` : `${item.view.name}.`}${
                  item.measure ? item.measure.name : item.dimension!.name
                }`
            )
          ) {
            return filterOptions;
          }

          return [...filterOptions, current];
        }, []),
    ];
  }

  if (action.type === HYDRATE_STORE) {
    Object.assign(state, action.appState);
  }

  if (action.type === RESET_REPORT_BUILDER_COLUMNS) {
    return reportingApp(resetReportBuilderColumns(state), action);
  }

  return reportingApp(state, action);
};

export default rootReducer;
