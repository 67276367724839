export const CRT_CAPABILITIES_KEY = 'crt';
export const CRT_ADMIN_KEY = 'admin';
export const ANALYTICS_CRT_REPORT_CREATE = 'analytics.crt.report.create';
export const CRT_ANALYTICS_CAPABILITIES_KEY = 'crtAnalytics';
export const ELMO_CRT_ANALYTICS_CAPABILITY = 'elmo_crt_analytics_enable';
export const ELMO_PREDICTIVE_ANALYTICS_CAPABILITY = 'elmo_predictive_analytics_enable';
export const ELMO_PREDICTIVE_ANALYTICS_DOMAIN = 'elmo_predictive_analytics_domain';
export const VISITOR_ROLES = {
  roleElmoSuper: 'ROLE_ELMO_SUPER',
  roleElmoAdmin: 'ROLE_ELMO_ADMIN',
  roleSuper: 'ROLE_SUPER',
  roleManager: 'ROLE_MANAGER',
  roleEmployee: 'ROLE_EMPLOYEE',
};
