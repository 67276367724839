import * as React from 'react';
import { Dashboard } from '../types/dashboardModel';
import { AppState } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ShareButtonComponent from '../components/ShareButton';
import { DashboardService } from '../services/dashboardService';
import { SavedQuery } from '../types/savedQueryModel';
import { User } from '../types/userModel';
import { AuthService } from '../services/authService';
import { NotificationService } from '../services/notificationService';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../constants';
import { Chart } from '../types/chartModel';
import { saveQueryAndChart, storeReportTitle } from '../actions/report';
import { storeSavedQuery } from '../actions/savedQueries';

export interface ShareButtonContainerProps {
  dispatch: Dispatch<any>;
  disabled?: boolean;
  reportTitle: string;
  loadedQuery?: SavedQuery;
  authService: AuthService;
  notificationService: NotificationService;
  chart?: Chart;
}

interface ShareButtonContainerState {
  showShareModal: boolean;
  dashboards: Dashboard[];
}

const mapStateToProps = (state: AppState) => ({
  notificationService: state.notificationService,
  authService: state.authService,
  reportTitle: state.reportTitle,
});

class ShareButtonContainer extends React.Component<ShareButtonContainerProps, ShareButtonContainerState> {

  constructor(props: ShareButtonContainerProps) {
    super(props);

    this.state = {
      showShareModal: false,
      dashboards: []
    };
  }

  render() {
    // Feature flag for Dashboards
    const enableDashboards = this.props.authService.canViewShareChartButton();
    if (!enableDashboards) {
      return null;
    }
    const widgetType = !!this.props.chart ? 'chart' : 'report';
    return (
      <ShareButtonComponent
        share={this.sendToDashboard}
        setShowModal={this.setShowShareModal}
        showModal={this.state.showShareModal}
        dashboards={this.state.dashboards}
        createDashboard={this.createDashboard}
        shareNotification={this.shareNotification}
        shareErrorNotification={this.shareErrorNotification}
        widgetType={widgetType}
      />
    );
  }

  // CRT-2398 Create report if not exist to be able to share widget.
  saveQuery = async (chart: Chart = new Chart(), loadedQuery: SavedQuery|undefined): Promise<SavedQuery|undefined> =>  {
    try {
      const formData = {
        name: `Autogenerated report ${Date.now()}`,
        description: '',
        userReport: false,
        suggestedReport: false,
        dashboardReport: true
      };

      let query: SavedQuery = await this.props.dispatch(
          saveQueryAndChart(false, loadedQuery, chart, formData)
      );

      // Populate the report title with the inputted title
      this.props.dispatch(storeReportTitle(formData.name));
      this.props.dispatch(storeSavedQuery(query));
      return query;
    } catch (e) {
      return;
    }
  }

  sendToDashboard = async (dashboard: Dashboard) => {
    let { chart, loadedQuery } = this.props;
    const needToSaveQuery = !loadedQuery || !loadedQuery.id || (chart && !chart.queryId);

    if (needToSaveQuery) {
      loadedQuery = await this.saveQuery(chart, loadedQuery);
    }

    if (!loadedQuery) {
      return this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'Could not save report.');
    }

    await DashboardService.sendToDashboard(
      dashboard, this.props.reportTitle, chart, loadedQuery.id
    );

    this.setShowShareModal(false);
  }

  setShowShareModal = (showShareModal: boolean) => {
    this.setState({
      showShareModal: showShareModal
    });
  }

  createDashboard = async (
      title: string, description?: string, type?: number, status?: number
  ): Promise<Dashboard | null> => {
    let userData: User | null = this.props.authService.getUserData();
    const dashboardData = { title, description, type, status };
    if (userData) {
      let response = await DashboardService.createDashboard(userData, dashboardData);
      await this.getDashboards();
      return response.data;
    }
    return null;
  }

  shareNotification = (dashboard: Dashboard) => {
    if (dashboard) {
      this.props.notificationService.addNotification(
        NOTIFICATION_SUCCESS,
        'Successfully shared to dashboard',
        this.renderDashboardLink(dashboard)
      );
    } else {
      this.props.notificationService.addNotification(
        NOTIFICATION_ERROR,
        'We could not share the chart to the dashboard. Trying saving the report and reloading the page.'
      );
    }
  }

  renderDashboardLink(dashboard: Dashboard) {
    return (
      <button
        className="btn btn-primary"
        onClick={this.onGoToDashboardClick('/dashboards/' + dashboard.dashboardId)}
        style={{marginTop: 5}}
      >
        Go to Dashboard
      </button>
    );
  }

  shareErrorNotification = () => {
    this.props.notificationService.addNotification(
      NOTIFICATION_ERROR,
      'We could not share the chart to the dashboard. Trying saving the report and reloading the page.'
    );
  }

  onGoToDashboardClick = (link: string) => (e: React.MouseEvent<any>) =>  {
    e.preventDefault();
    window.open(link, '_self');
  }

  componentDidMount() {
    this.getDashboards();
  }

  getDashboards = async () => {
    // Feature flag for Dashboards
    const user = this.props.authService.getUserData();
    const enableDashboards = this.props.authService.canViewShareChartButton();
    const dashboardRequestUrlParams = {
      group: 'share-widget-list',
    };
    if (enableDashboards && user) {
      let dashboards = await DashboardService.apiGetDashboards(user, dashboardRequestUrlParams);
      this.setState({dashboards: dashboards});
    }
  }

}

const ShareButton = connect(
  mapStateToProps)(ShareButtonContainer);

export default ShareButton;
