import { InferType, array, boolean, date, number, object, string } from 'yup';

import {
  OUTBOUND_FILENAME_SPACE_TOKEN_ID,
  ScheduledReportOccurrence,
  ScheduledReportOccurrenceLabel,
} from 'src/features/scheduled-reports';

export type ScheduledReportFieldValues = InferType<typeof scheduledReportSchema>;

interface OccurrenceOption {
  label: ScheduledReportOccurrenceLabel;
  value: ScheduledReportOccurrence;
}

export const scheduledReportSchema = object({
  calendarDay: object({ label: string().required(), value: number().required() })
    .nullable()
    .when('occurrence', {
      is: (occurrence: OccurrenceOption) => occurrence.value === 'monthly',
      then: object({ label: string().required(), value: number().required() })
        .required('Please select a day of the month')
        .nullable(),
    }),
  customFilename: array<string>().when('customFilenameEnabled', {
    is: true,
    then: array<string>()
      .required('Please provide a filename')
      .test('whitespace', 'Please remove any extra spaces at the beginning or end of the filename', function (value) {
        return value &&
          (value[0] === OUTBOUND_FILENAME_SPACE_TOKEN_ID ||
            value[value.length - 1] === OUTBOUND_FILENAME_SPACE_TOKEN_ID)
          ? this.createError()
          : true;
      }),
  }),
  customFilenameEnabled: boolean().required(),
  daysOfTheWeek: array<{ label: string; value: number }>().when('occurrence', {
    is: (occurrence: OccurrenceOption) => occurrence.value === 'weekly',
    then: array<{ label: string; value: number }>().required('Please select at least one day of the week'),
  }),
  destination: string<'users' | 'outbound'>().required(),
  encryptionEnabled: boolean().required(),
  encryptionKey: string().when('encryptionEnabled', {
    is: true,
    then: string().required('Please enter your public key'),
  }),
  endDate: date().when(
    ['occurrence', 'shouldValidateEndDate', 'startDate'],
    (occurrence: OccurrenceOption, shouldValidateEndDate: boolean, startDate: Date) => {
      if (occurrence.value === 'once') return date();
      if (startDate && shouldValidateEndDate)
        return date()
          .required('Please enter a valid date')
          .min(startDate, 'Please enter a date succeeding the start date');
      if (startDate) return date().min(startDate, 'Please enter a date succeeding the start date');
      return date();
    }
  ),
  id: string().required(),
  name: string().required('Please enter a schedule name'),
  occurrence: object({
    label: string<ScheduledReportOccurrenceLabel>().required(),
    value: string<ScheduledReportOccurrence>().required(),
  }).required(),
  outboundDestination: object({ label: string().required(), value: string().required() })
    .nullable()
    .when('destination', {
      is: 'outbound',
      then: object({ label: string().required(), value: string().required() })
        .required('Please select outbound destination')
        .nullable(),
    }),
  outboundNotificationRecipient: object({
    label: string().required(),
    type: string<'email' | 'user'>(),
    value: string().required(),
  })
    .nullable()
    .when('destination', {
      is: 'outbound',
      then: object({ label: string().required(), type: string<'email' | 'user'>(), value: string().required() })
        .required('Please select a recipient')
        .nullable(),
    }),
  queryId: string().required(),
  // NOTE: This accounts for invalid date strings because DateInput only returns either valid date values or undefined
  shouldValidateEndDate: boolean().required(),
  // NOTE: startDate must be allowed to be initialized with undefined otherwise the DateInput will break
  startDate: date().when('id', {
    is: () => true,
    then: date().required('Please enter a valid date'),
  }),
  targetUsers: array<{ label: string; value: string }>().when('destination', {
    is: 'users',
    then: array<{ label: string; value: string }>().required('Please select at least one recipient'),
  }),
  time: object({ label: string().required(), value: string().required() }).required('Please select a time').nullable(),
}).required();

export const emailSchema = string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

export const textWithSeparatorsSchema = string().matches(
  /^[\w\s!-]*$/,
  'Please enter alphanumeric text and valid separators only'
);
