import * as React from 'react';
import { SavedQuery } from '../../types/savedQueryModel';
import Popover from 'reactstrap/lib/Popover';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import { SuggestedReportState } from '../../redux';
import './style.css';
import SuggestedReportsContentComponent from '../SuggestedReportsContent';

interface SuggestedReportsPopoverComponentProps {
  suggestedReports: SuggestedReportState;
  onSuggestionClick: (suggestion: SavedQuery) => void;
  toggle: () => void;
  setOpen: (isOpen: boolean) => () => void;
  loading: boolean;
}

class SuggestedReportsPopoverComponent
  extends React.Component<SuggestedReportsPopoverComponentProps> {

  constructor(props: SuggestedReportsPopoverComponentProps) {
    super(props);
  }

  render() {
    return (
      <div className="suggestions-container">
        <button
          id="suggestions-popover-target"
          onClick={this.props.toggle}
          className="btn btn-outline-dark suggestions-btn"
        >
          <i className="icon icon-lightbulb" />
        </button>
        <Popover
          placement="right-start"
          isOpen={this.props.suggestedReports.isOpen}
          target="suggestions-popover-target"
          className="suggestions-popover"
        >
          <PopoverHeader>
            <button
              className="btn btn-outline-dark pull-right"
              onClick={this.props.toggle}
              data-testid="suggestions-close"
            >
              <i className="icon icon-closed" />
            </button>
            <div className="clearfix" />
          </PopoverHeader>
          <PopoverBody>
            <SuggestedReportsContentComponent
              suggestedReports={this.props.suggestedReports}
              onSuggestionClick={this.onSuggestionClick}
              loading={this.props.loading}
            />
          </PopoverBody>
        </Popover>
      </div>
    );
  }

  onSuggestionClick = (suggestion: SavedQuery) => {
    this.props.onSuggestionClick(suggestion);
  }
}

export default SuggestedReportsPopoverComponent;