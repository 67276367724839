import * as React from 'react';
import { AppState } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { View } from '../types/viewModel';
import { Dimension } from '../types/dimensionModel';
import { addDimension, addDimensions, removeDimension, removeDimensions } from '../actions/dimension';
import { storeFilterOptions } from '../actions/filter';
import { SOURCE_WIZARD } from '../constants';
import { ColumnSelect, ColumnSelectItem, ColumnSelectItemGroup } from '../components/ColumnSelect';
import { map, findIndex } from 'lodash';
import { Column } from '../types/columnModel';

export interface DimensionSelectProps {
  view: View;
  checkedDimensions: Dimension[];
  dispatch: Dispatch<any>;
}

const mapStateToProps = (state: AppState) => ({
  checkedDimensions: state.dimensions,
  view: state.view
});

interface DimensionSelectState {
  itemGroup: ColumnSelectItemGroup;
  allSelected: boolean;
}

class DimensionSelectComponent extends React.Component<DimensionSelectProps, DimensionSelectState> {
  constructor(props: DimensionSelectProps) {
    super(props);

    this.state = {
      itemGroup: {
        label: '',
        items: []
      },
      allSelected: false
    };
  }

  componentDidMount() {
    this.createColumnSelectItems(this.props.view, this.props.checkedDimensions);
  }

  componentDidUpdate(prevProps: DimensionSelectProps) {
    if ((this.props.view.name !== prevProps.view.name) ||
      !Column.arrayEquals(this.props.checkedDimensions, prevProps.checkedDimensions)) {
      this.createColumnSelectItems(this.props.view, this.props.checkedDimensions);
    }
  }

  createColumnSelectItems(view: View, selectedDimensions: Dimension[]) {
    let items: ColumnSelectItem[] = map(view.dimensions, (dimension: Dimension) => {
      let selected: boolean = this.isSelected(dimension, selectedDimensions);

      return {
        label: dimension.label,
        isSelected: selected,
        onClick: selected ? this.onRemove(dimension) : this.onAdd(dimension)
      };
    });

    this.setState({
      allSelected: selectedDimensions.length === view.dimensions.length,
      itemGroup: {
        label: '',
        items: items
      }
    });
  }

  isSelected(dimension: Dimension, selectedDimensions: Dimension[]) {
    return findIndex(selectedDimensions, {name: dimension.name}) !== -1;
  }

  onSelectAllClick = () => {
    if (this.state.allSelected) {
      this.setState({
        allSelected: false
      });

      this.props.dispatch(removeDimensions(this.props.checkedDimensions, this.props.view, SOURCE_WIZARD));
      this.props.dispatch(storeFilterOptions());

    } else {
      this.setState({
        allSelected: true
      });

      let toAdd: Dimension[] = this.props.view.dimensions.filter((d: Dimension) => {
        return (findIndex(this.props.checkedDimensions, ['name', d.name]) === -1);
      });

      this.props.dispatch(addDimensions(toAdd, this.props.view, SOURCE_WIZARD));
      this.props.dispatch(storeFilterOptions());
    }
  }

  render() {
    return (
      <ColumnSelect
        title={this.props.view.label}
        isAggregate={false}
        itemGroups={[this.state.itemGroup]}
        onSelectAllClick={this.onSelectAllClick}
        allSelected={this.state.allSelected}
      />
    );
  }

  protected onAdd = (item: Dimension) => () => {
    this.props.dispatch(addDimension(item, this.props.view, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onRemove = (item: Dimension) => () => {
    this.props.dispatch(removeDimension(item, this.props.view, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());

    this.setState({
      allSelected: false
    });
  }
}

const DimensionSelect = connect(
  mapStateToProps
)(DimensionSelectComponent);

export default DimensionSelect;
