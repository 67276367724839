import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { UseFormReset, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import uuidv4 from 'uuid/v4';

import { ReportInfo, ScheduledReport, occurrences } from 'src/features/scheduled-reports';
import { base64ToBytes } from 'src/features/utils';

import { ScheduledReportFieldValues, scheduledReportSchema } from './scheduled-reports-modal.validation';
import { calendarDays, daysOfTheWeek } from './scheduled-reports-modal.values';

const TIME_LABEL_FORMAT = 'HH:mm';
const TIME_VALUE_FORMAT = 'HH:mm:ss';

const defaultValues: ScheduledReportFieldValues = {
  calendarDay: null,
  customFilename: [],
  customFilenameEnabled: false,
  daysOfTheWeek: [],
  destination: 'users',
  encryptionEnabled: false,
  encryptionKey: '',
  id: '',
  name: '',
  occurrence: occurrences[0],
  outboundDestination: null,
  outboundNotificationRecipient: null,
  queryId: '',
  shouldValidateEndDate: false,
  targetUsers: [],
  time: null,
};

export const useScheduledReportForm = () =>
  useForm<ScheduledReportFieldValues>({ defaultValues, resolver: yupResolver(scheduledReportSchema) });

export const useFormValuesInitializer = (
  reset: UseFormReset<ScheduledReportFieldValues>,
  reportInfo?: ReportInfo,
  scheduledReport?: ScheduledReport
) =>
  useEffect(() => {
    if (reportInfo) {
      const thisDayAndHour = DateTime.now().set({ minute: 0, second: 0, millisecond: 0 });
      const values: ScheduledReportFieldValues = {
        ...defaultValues,
        endDate: thisDayAndHour.set({ hour: 0 }).plus({ years: 1 }).toJSDate(),
        id: uuidv4(),
        queryId: reportInfo.reportId,
        startDate: thisDayAndHour.set({ hour: 0 }).toJSDate(),
        time: {
          label: thisDayAndHour.plus({ hours: 1 }).toFormat(TIME_LABEL_FORMAT),
          value: thisDayAndHour.plus({ hours: 1 }).toFormat(TIME_VALUE_FORMAT),
        },
      };

      reset(values);
      return;
    }

    if (!scheduledReport) {
      reset(defaultValues);
      return;
    }

    const values: ScheduledReportFieldValues = {
      ...defaultValues,
      destination: scheduledReport.outboundDestinationId ? 'outbound' : 'users',
      id: scheduledReport.id,
      name: scheduledReport.scheduleName,
      occurrence: occurrences.find(({ value }) => scheduledReport.occurrence === value) || occurrences[0],
      queryId: scheduledReport.query.id,
      // NOTE: Truncating the time from the startDate ensures precise date-based validation with the endDate
      startDate: DateTime.fromSQL(scheduledReport.startDate.slice(0, 10)).toJSDate(),
      time: {
        label: DateTime.fromSQL(scheduledReport.startDate).toFormat(TIME_LABEL_FORMAT),
        value: DateTime.fromSQL(scheduledReport.startDate).toFormat(TIME_VALUE_FORMAT),
      },
    };

    if (scheduledReport.endDate && scheduledReport.occurrence !== 'once') {
      values.endDate = DateTime.fromSQL(scheduledReport.endDate).toJSDate();
    }

    if (scheduledReport.lastDayOfTheMonth) {
      values.calendarDay = calendarDays.find(({ value }) => value === -1);
    } else if (scheduledReport.calendarDays[0] !== 0) {
      values.calendarDay = calendarDays.find(({ value }) => scheduledReport.calendarDays[0] === value);
    }

    if (scheduledReport.occurrence === 'weekly') {
      values.daysOfTheWeek = daysOfTheWeek.filter(({ value }) => scheduledReport.daysOfTheWeek.includes(value));
    }

    if (values.destination === 'users') {
      values.targetUsers = (scheduledReport.targetUserIds || []).map(userId => ({
        label: '',
        value: userId,
      }));

      reset(values);
      return;
    }

    values.outboundDestination = {
      label: '',
      value: scheduledReport.outboundDestinationId || '',
    };

    const [outboundNotificationRecipient] = scheduledReport.notificationRecipients || [];
    const isUser = outboundNotificationRecipient.recipientType === 'user';

    values.outboundNotificationRecipient = {
      label: isUser ? '' : outboundNotificationRecipient.email,
      type: isUser ? 'user' : 'email',
      value: isUser ? outboundNotificationRecipient.uuid : outboundNotificationRecipient.email,
    };

    values.encryptionEnabled = scheduledReport.isEncryptionEnabled;
    values.encryptionKey = scheduledReport.encryptionKey
      ? new TextDecoder().decode(base64ToBytes(scheduledReport.encryptionKey))
      : '';

    values.customFilenameEnabled = scheduledReport.isFilenameCustomisable;
    values.customFilename = scheduledReport.filenameTokens;

    reset(values);
  }, [`${reportInfo?.reportId || ''}${reportInfo?.reportName || ''}`, scheduledReport]);
