import * as React from 'react';
import { Dispatch } from 'redux';
import { AppState } from '../redux/index';
import { connect } from 'react-redux';
import { AuthService } from '../services/authService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ElmoMenuWrapper from '../components/ElmoMenu/ElmoMenuWrapper';
import { AppMenu } from '../types/appMenuModel';
import { updateAppMenuCollapseStatus, setCurrentSubmenuItem } from '../actions/appMenu';
import { SubmenuItemType } from '../types/elmoMenuModel';
import { APP_MENU_STORAGE_KEY, APP_MENU_CURRENT_ITEM_STORAGE_KEY } from '../constants';
import { LocalStorageService } from '../services/localStorageService';

export interface Props extends RouteComponentProps<any> {
  authService: AuthService;
  appMenu: AppMenu;
  dispatch: Dispatch<any>;
}

export interface State {
  isLoggedIn: boolean;
  navigationData: any;
  isLoading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  authService: state.authService,
  appMenu: state.appMenu,
});

class ElmoMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoggedIn: this.props.authService ? this.props.authService.isTokenValid() : false,
      navigationData: null,
      isLoading: false
    };
  }

  async componentDidMount() {
    const { authService } = this.props;
    this.setState({
      isLoading: true
    });
    const loggedIn = await authService.isLoggedIn();
    const user = authService.getUserData();

    if (!user) {
      // due to side effect of getUserData, the session should invalidate
      return;
    }

    let navigationData;
    // check if menu is on local storage
    navigationData = LocalStorageService.getItem(APP_MENU_STORAGE_KEY)
      ? LocalStorageService.getItem(APP_MENU_STORAGE_KEY)[`${user.uuid}_${user.id}`]
      : undefined;

    if (!navigationData) {
      navigationData = await authService.getMenuItems();
      LocalStorageService.setItem(APP_MENU_STORAGE_KEY, { [`${user.uuid}_${user.id}`]: navigationData });
    }

    this.setState({
      isLoggedIn: loggedIn,
      navigationData,
      isLoading: false
    });
  }

  updateAppMenuStatus = () => {
    const { dispatch, appMenu } = this.props;

    dispatch(updateAppMenuCollapseStatus(!appMenu.isOpened));
  }

  setCurrentSubmenuItem = (submenuItem: SubmenuItemType) => {
    const { dispatch } = this.props;

    dispatch(setCurrentSubmenuItem(submenuItem));
    LocalStorageService.setItem(APP_MENU_CURRENT_ITEM_STORAGE_KEY, submenuItem);
  }

  render() {
    const { isLoggedIn, navigationData, isLoading } = this.state;
    if (isLoggedIn) {
      return (
        <>
          <ElmoMenuWrapper
            navigation={navigationData}
            isLoading={isLoading}
            updateAppMenuStatus={this.updateAppMenuStatus}
            setCurrentSubmenuItem={this.setCurrentSubmenuItem}
          />
        </>
      );
    }
    return null;
  }
}

export default withRouter(connect(
  mapStateToProps)(ElmoMenu));
