import { Dimension } from './dimensionModel';
import { View } from './viewModel';
import { Join } from './joinModel';
import { Measure } from './measureModel';

export class ColumnMeta {
  dimension?: Dimension;
  view: View;
  join?: Join;
  measure?: Measure;
  label?: string;
  cellDataIndex?: number;
  displayLabel?: string;
  forGroupBy?: boolean; // is this columnMeta linked to a groupBy
  alias?: string;

  constructor() {
    Object.assign(this, {
      view: new View()
    });
  }
}

export type ColumnDimensionType = {
  dimensionName?: string;
  viewName: string;
  joinName?: string;
  measureName?: string;
};

export type ColumnXDimensionType = ColumnDimensionType &  {
  dimension?: Partial<ColumnXDimensionType>;
};

export class ColumnMetaXCompact {
  dimension: ColumnXDimensionType;
}

export class ColumnMetaYCompact {
  dimensionName: string;
  viewName: string;
  joinName: string;
  measureName: string;
}
