import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AddIcon, Dropdown } from 'elmo-elements';
import uuidv4 from 'uuid/v4';

import ElmoButton from '../ElmoButton';
import ElmoDropdown from '../ElmoDropdown';
import ElmoDropdownItem from '../ElmoDropdownItem';
import { ColumnMeta } from '../../../types/columnMetaModel';
import { getColumnMetaWithLabels } from '../../../services/columnMetaService';
import ElmoModal from '../ElmoModal';
import { AppState } from '../../../redux';
import { UserCapabilitiesType } from '../../../services/reportService';
import { CustomColumn, CustomColumnType } from '../../../types/customColumnModel';
import { setCustomColumns } from '../../../actions/customColumn';
import { DATA_TYPE_DIMENSIONS, MODULE_TYPES } from '../../../constants';
import useMap from '../../../components/hooks/useMap';

import ColumnCardContainer from './ColumnCardContainer';
import { getColumnId, isColumnMeta, isCustomColumn } from './hooks';

import './style.css';

type ManageColumnsModalPropsType = {
  showModal: boolean;
  columnMetas: ColumnMeta[];
  toggle: () => void;
  manageColumns: (reorderedColumns: ColumnMeta[], removedColumns?: ColumnMeta[]) => void;
  userCapabilities: UserCapabilitiesType;
};

const ManageColumnsModal = (props: ManageColumnsModalPropsType) => {
  const { useState } = React;
  const dispatch = useDispatch();
  
  const { showModal, columnMetas, toggle, manageColumns, userCapabilities } = props;
  const { customColumns, dataType, module, report } = useSelector((state: AppState) => state);
  
  const managedColumnMeta = getColumnMetaWithLabels(columnMetas, report.headers);
  const initialConsolidatedColumns: Array<ColumnMeta | CustomColumn> = [...managedColumnMeta];
  customColumns.forEach(column => initialConsolidatedColumns.splice(column.index || 0, 0, column));
  
  const [consolidatedColumns, setConsolidatedColumns] = useState([...initialConsolidatedColumns]);
  const currentCustomColumns = consolidatedColumns.filter(isCustomColumn);
  
  const [isChanged, setIsChanged] = useState(false);
  const [renamedCustomColumns, { set: setRenamedCustomColumns }] = useMap<string, string>([
    ...(customColumns.map(({ id, alias }) => [id, alias]).filter(([, value]) => value) as Array<[string, string]>)
  ]);

  const manageCustomColumnEnabled =
    userCapabilities.report.canManageCustomColumn &&
    module.type === MODULE_TYPES.redshift &&
    dataType === DATA_TYPE_DIMENSIONS;

  const trimAlias = (columnMetaList: ColumnMeta[]) => {
    return columnMetaList.map((cm): ColumnMeta => {
      const alias = cm.alias && cm.alias.trim();
  
      return alias ? { ...cm, alias } : { ...cm, alias: '' };
    });
  };

  const onPrimaryButtonClick = () => {
    if (manageCustomColumnEnabled) {
      const indexedCurrentCustomColumns = currentCustomColumns
        .filter(({ type, value }: CustomColumn) => type === CustomColumnType.Empty || value)
        .map(customColumn => {
          const index = consolidatedColumns
            .filter(column => {
              if (isColumnMeta(column)) {
                return true;
              }

              return column.type === CustomColumnType.Empty || column.value;
            })
            .findIndex(column => {
              if (isColumnMeta(column)) {
                return false;
              }

              return column.id === customColumn.id;
            });

          const renamedTitle = renamedCustomColumns.get(customColumn.id) || '';
          customColumn.alias = renamedTitle.trim() || undefined;

          return { ...customColumn, index };
        });

      dispatch(setCustomColumns(indexedCurrentCustomColumns));
    }

    const currentColumnMeta = consolidatedColumns.filter(isColumnMeta);
    
    if (currentColumnMeta.length === managedColumnMeta.length) {
      if (isChanged) {
        manageColumns(trimAlias(currentColumnMeta));
      }

      toggle();
      return;
    }

    const remaining = currentColumnMeta.map(cm => getColumnId(cm));
    const removedColumns = managedColumnMeta.filter(cm => !remaining.includes(getColumnId(cm)));
    
    manageColumns(trimAlias(currentColumnMeta), removedColumns);

    toggle();
  };

  const onCustomColumnDropdownSelect = (type: number) => {
    const customColumnPrefix = 'Custom Column ';

    const customColumnIndexes = [
      0,
      ...currentCustomColumns
        .filter(({ title }) => title.includes(customColumnPrefix))
        .map(({ title }) => Number(title.split(customColumnPrefix).pop())),
    ].filter(possibleNaN => !Number.isNaN(possibleNaN));

    const newCustomColumnIndex = Math.max(...customColumnIndexes) + 1;

    const customColumn: CustomColumn = {
      id: uuidv4(),
      title: `${customColumnPrefix}${newCustomColumnIndex}`,
      type,
      value: type === CustomColumnType.String ? '' : null
    };

    setConsolidatedColumns([customColumn, ...consolidatedColumns]);
    setIsChanged(true);
  };

  return (
    <ElmoModal
      id="manageColumnsModal"
      className="manageColumnsModal"
      type="large"
      isOpened={showModal}
      title={
        <div className="manage-columns-header">
          <div className="title">Manage Columns</div>
          {manageCustomColumnEnabled &&
            <ElmoDropdown
              text="Add New"
              ariaLabel="Add New"
              position="bottom-end"
              icon={<AddIcon />}
              leadingIcon={true}
            >
              <Dropdown.Label>
                <p className="elmo-typography text-size-sm custom-column__default-value">DEFAULT VALUE</p>
              </Dropdown.Label>
              <ElmoDropdownItem
                ariaLabel="Text"
                value={CustomColumnType.String}
                onClick={onCustomColumnDropdownSelect}
              >
                Text
              </ElmoDropdownItem>
              <ElmoDropdownItem
                ariaLabel="Field"
                value={CustomColumnType.Dimension}
                onClick={onCustomColumnDropdownSelect}
              >
                Field
              </ElmoDropdownItem>
              <ElmoDropdownItem
                ariaLabel="Blank"
                value={CustomColumnType.Empty}
                onClick={onCustomColumnDropdownSelect}
              >
                Blank
              </ElmoDropdownItem>
            </ElmoDropdown>
          }
        </div>
      }
      closeModal={toggle}
      autoScroll={true}
      primaryButton={(
        <>
          <ElmoButton type="text" onClick={toggle}>Cancel</ElmoButton>
          <ElmoButton type="primary" onClick={onPrimaryButtonClick}>Save Changes</ElmoButton>
        </>
      )}
      headerCloseDisabled={manageCustomColumnEnabled}
    >
      <ColumnCardContainer
        managedColumnMeta={managedColumnMeta}
        consolidatedColumns={consolidatedColumns}
        setConsolidatedColumns={setConsolidatedColumns}
        setIsChangedTrue={() => setIsChanged(true)}
        renamedCustomColumns={renamedCustomColumns}
        setRenamedCustomColumns={setRenamedCustomColumns}
      />
    </ElmoModal>
  );
};

export default ManageColumnsModal;
