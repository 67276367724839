import React from 'react';
import { Box } from '@eds/box';

import { CreateScheduledReport } from 'src/features/report-builder';
import { DeleteReport, ExportReport, LegacyReportInfo, ReportsList, ShareReport } from 'src/features/reports';

import { useReportsPage } from './reports-page.hooks';

export const ReportsPage = () => {
  const {
    hideLegacyReportInfo,
    legacyReportInfoToggle,
    reportToDelete,
    reportToExport,
    reportToSchedule,
    reportToShare,
    showLegacyReportInfo,
    startDeleting,
    startExporting,
    startScheduling,
    startSharing,
    stopDeleting,
    stopExporting,
    stopScheduling,
    stopSharing,
  } = useReportsPage();

  return (
    <Box>
      <ReportsList
        onDelete={startDeleting}
        onExport={startExporting}
        onSchedule={startScheduling}
        onShare={startSharing}
        showLegacyReportInfo={showLegacyReportInfo}
      />
      <ExportReport id={reportToExport} onSettled={stopExporting} />
      <ShareReport
        onClose={stopSharing}
        reportId={reportToShare.id}
        reportName={reportToShare.name}
        sharedWith={reportToShare.sharedWith}
      />
      <CreateScheduledReport
        isOpen={!!reportToSchedule.id}
        onClose={stopScheduling}
        reportId={reportToSchedule.id}
        reportName={reportToSchedule.name}
      />
      <DeleteReport reportId={reportToDelete} onClose={stopDeleting} />
      <LegacyReportInfo isOpen={legacyReportInfoToggle} onClose={hideLegacyReportInfo} />
    </Box>
  );
};
