import * as React from 'react';
import {Field} from '@eds/field';
import {SelectInput} from '@eds/select-input';

import {
  FILTER_DATE_CONTEXT,
  FILTER_DATE_CONTEXT_DATE,
  FILTER_DATE_CONTEXT_LAST,
  FILTER_DATE_CONTEXT_NEXT,
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_LESS_THAN,
} from 'src/constants/filters';

import {Filter, FILTER_DATE_CONTEXTS} from '../../../types/filterModel';
import {DIMENSION_TYPE_DATEINTERVAL, DIMENSION_TYPE_DATETIME} from "../../../constants";

interface FilterTextInputProps {
  displayFilter: Filter;
  filterIndex: number;
  isDisabled: boolean;
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
}

interface Option {
  label: string;
  value: string;
}

const FilterRelativeDateInput = (props: FilterTextInputProps) => {
  const { displayFilter, filterIndex, isDisabled, saveDisplayFilter } = props;

  const onChange = (option: Option) => {
    const filter = { ...displayFilter };
    filter.relativeDate = option.value;
    filter.periodCount = undefined;

    if (
      [FILTER_TYPE_EQUALS, FILTER_TYPE_GREATER_THAN, FILTER_TYPE_LESS_THAN].includes(filter.type) &&
      [FILTER_DATE_CONTEXT_LAST, FILTER_DATE_CONTEXT_NEXT].includes(filter.relativeDate || '')
    ) {
      filter.periodCount = displayFilter.periodCount ?? '1';
    }

    saveDisplayFilter(filter, filterIndex);
  };

  const options = FILTER_DATE_CONTEXTS.map((val: FILTER_DATE_CONTEXT) => {
      return {
        value: val,
        label: val.toLowerCase(),
      };
  });

  if (displayFilter.dimensionOrMeasureType && [DIMENSION_TYPE_DATETIME, DIMENSION_TYPE_DATEINTERVAL].includes(displayFilter.dimensionOrMeasureType)) {
    options.push({
      value: FILTER_DATE_CONTEXT_DATE,
      label: FILTER_DATE_CONTEXT_DATE.toLowerCase(),
    });
  }

  return (
    <Field label="Criteria" keepSpaceForMessage={false}>
      <SelectInput
        disabled={isDisabled}
        isClearable={false}
        items={options}
        onChange={onChange}
        size="small"
        value={options.find(option => option.value === displayFilter.relativeDate)}
      />
    </Field>
  );
};

export default FilterRelativeDateInput;
