import { isObject, assign, pick, forIn, isArray, map, isNil } from 'lodash';

export type BaseModelType = new (data?: any) => BaseModel;

export interface NestedEntityMap {
  [key: string]: BaseModelType;
}

export abstract class BaseModel {
  protected properties: string[];
  protected nestedObjects: NestedEntityMap;

  constructor(data?: any) {
    this.properties = [];
    this.nestedObjects = {};
  }

  protected hydrate(data: any) {
    if (!data || !isObject(data)) {
      return;
    }

    // assign the flat properties
    assign(this, pick(data, this.properties));

    // assign the nested properties
    forIn(this.nestedObjects, (ctor: BaseModelType, nestedProperty: string) => {
      if (!this.isBaseModel(ctor) || isNil(data[nestedProperty])) {
        return;
      }

      let nestedData = this[nestedProperty];

      if (isArray(data[nestedProperty])) {
        nestedData = map(
          data[nestedProperty],
          (d: any) => {
            return this.hydrateNested(d, ctor);
          });
      } else if (this.isBaseModel(ctor)) {
        nestedData = this.hydrateNested(data[nestedProperty], ctor);
      }

      this[nestedProperty] = nestedData;
    });
  }

  protected isBaseModel(entity: any) {
    return entity && entity.prototype && entity.prototype instanceof BaseModel;
  }

  protected hydrateNested = (data: any, ctor: BaseModelType) => {
    return new ctor(data);
  }
}
