import * as React from 'react';
import { Dropdown, FormItem } from 'elmo-elements';

import {
  OCCURRENCE_ONCE,
  OCCURRENCE_DAILY,
  OCCURRENCE_DAILY_WEEKDAY,
  OCCURRENCE_WEEKLY,
  OCCURRENCE_MONTHLY,
  OccurrenceOption
} from '../../../constants/addSchedule';

import './style.css';

const options = {
  [OCCURRENCE_ONCE]: {
    label: 'Once'
  },
  [OCCURRENCE_DAILY]: {
    label: 'Daily'
  },
  [OCCURRENCE_DAILY_WEEKDAY]: {
    label: 'Daily - Monday to Friday'
  },
  [OCCURRENCE_WEEKLY]: {
    label: 'Weekly'
  },
  [OCCURRENCE_MONTHLY]: {
    label: 'Monthly'
  }
};

type Props = {
  onSelect: (item: OccurrenceOption) => () => void;
  selectedItem: OccurrenceOption;
  errors: any;
};

function OccurrenceSelect({ onSelect, selectedItem, errors }: Props) {
  const optionsItems = Object.keys(options).map((optionKey: any) => {
    const value: OccurrenceOption = optionKey as OccurrenceOption;
    const option = options[optionKey];
    return (
      <Dropdown.Item
        key={value}
        value={value}
        onClick={onSelect(value)}
      >
        {option.label}
      </Dropdown.Item>
    );
  });

  const dropdownLabel = selectedItem ? options[selectedItem].label : options[OCCURRENCE_ONCE].label;
  return (
    <FormItem
      label="Occurrence:"
      message={!!errors['occurrence'] ? errors['occurrence'] : undefined}
      status={!!errors['occurrence'] ? 'error' : undefined}
      data-testid="schedule-occurrence"
    >
      <Dropdown
        text={dropdownLabel}
        isBordered={true}
        data-testid="occurrence-dropdown"
      >
        {optionsItems}
      </Dropdown>
    </FormItem>
  );
}

export default OccurrenceSelect;
