import * as React from 'react';
import ReportContext from './ReportContext';
import { ColumnMeta } from '../../types/columnMetaModel';
import { ReportTable } from '../../types/reportTableModel';
import { View } from '../../types/viewModel';
import { Query } from '../../types/queryModel';

export type ReportContextProps = Partial<{
  columnMeta: ColumnMeta[];
  views: View[];
  report: ReportTable;
  query: Query;
  reportTitle: string;
}>;

export const withReportContext = <P extends ReportContextProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentClass<any> => {
  return class extends React.Component<P> {
    render() {
      return (
        <ReportContext.Consumer>
          {(reportContextProps: any) => (
            <WrappedComponent
              {...reportContextProps}
              {...this.props}
            />
          )}
        </ReportContext.Consumer>
      );
    }
  };
};
