import { SHOW_DRILLDOWN_MODAL, CLOSE_DRILLDOWN_MODAL } from '../constants';
import { DrilldownModalType } from '../types/drilldownModalModel';

export interface DrilldownModalAction {
  type: SHOW_DRILLDOWN_MODAL | CLOSE_DRILLDOWN_MODAL;
  drilldownModal: DrilldownModalType;
}

export function showDrilldownModal(drilldownModalData: DrilldownModalType): DrilldownModalAction {
  return {
    type: SHOW_DRILLDOWN_MODAL,
    drilldownModal: drilldownModalData
  };
}

export function closeDrilldownModal() {
  return {
    type: CLOSE_DRILLDOWN_MODAL,
  };
}
