import { MAX_DEPTH_RELATED_JOINS } from '../constants';
import { Join } from '../types/joinModel';
import { View } from '../types/viewModel';
import { getViewFromJoin } from './viewService';
import { JoinAndDimensions, JoinAndMeasures } from '../redux';
import { map, uniq, intersection } from 'lodash';

/**
 * Get new copy of related joins for rendering
 *
 * This is called recursively in that each join renders its own related joins,
 * which in turn renders their related joins.
 * @returns {Join[]}
 */
export function getRelatedJoins(join: Join,
                                views: View[],
                                baseView: View,
                                currentNestingLevel: number = 0,
                                maxDepthToRender: number = MAX_DEPTH_RELATED_JOINS): Join[] {
  let view = getViewFromJoin(views, join);
  if (!view) {
    return [];
  }

  // stop returning related children if greater than MAX_DEPTH_RELATED_JOINS
  if (currentNestingLevel >= maxDepthToRender) {
    return [];
  }

  // calculate the parents of this join's related joins
  let parents = join.parents ? [...join.parents, join] : [join];
  let relatedJoins: Join[] = [];

  view.joins.forEach((relatedJoin: Join) => {
    // make a copy of the related join and set its parents.
    // A copy is required instead of just using the relatedJoin, as relatedJoin is a reference to the Join object.
    // so setting the parents for the relatedJoin will affect the single instance of the Join object
    let joinCopy = new Join();
    Object.assign(joinCopy, relatedJoin);
    joinCopy.parents = parents;

    // check that the related join does not exist in the array of parents of this join,
    // skip rendering it if it exists.
    if (parents.findIndex((parent: Join) => {
        return parent.name === relatedJoin.name;
      }) !== -1) {

      return;
    }

    // We introduced inverse joins to prevent a View from doing a Join back to itself via a different View.
    if (join.inverse && join.inverse === relatedJoin.name) {
      return;
    }

    // each foreign key has to exist in the join.parents array or base view name for this join to be valid
    if (!shouldDisplayJoinBasedOnParents(joinCopy, baseView)) {
      return;
    }

    relatedJoins.push(joinCopy);
  });

  return relatedJoins;
}

/**
 * Get involved join names for a join
 *
 * e.g. for a join A.B.C, te join names returned will be A, A.B, A.B.C
 * @param {Join} join
 * @returns {string[]}
 */
export function getInvolvedJoinNamesForJoin(join: Join): string[] {
  let joinNames = join.parentJoinNamesArray;
  joinNames.push(join.name);
  let current: string = '';
  let involvedJoinNames: string[] = [];
  joinNames.map((name: string, index: number) => {
    if (index > 0) {
      current += '.';
    }

    current += name;
    involvedJoinNames.push(current);
  });

  return involvedJoinNames;
}

export function isJoinValid(join: Join,
                            baseView: View,
                            selectedFullJoinNames: string[],
                            selectedJoinNames: string[]): boolean {
  return shouldDisplayJoinBasedOnParents(join, baseView) &&
    joinIsUnused(join, selectedFullJoinNames, selectedJoinNames);
}

/**
 * Returns true if the join can be added
 * @param {Join} join
 * @param {string[]} selectedFullJoinNames
 * @param {string[]} selectedJoinNames
 * @returns {boolean}
 */
export function joinIsUnused(join: Join, selectedFullJoinNames: string[], selectedJoinNames: string[]): boolean {
  // if nothing has been selected, don't disable
  if (selectedFullJoinNames.length === 0) {
    return true;
  }

  // if this join has already been selected (exact match), don't disable
  let isSelectedJoin = selectedFullJoinNames.indexOf(join.fullJoinName);
  if (isSelectedJoin !== -1) {
    return true;
  }

  // if no parts of this join (parent names + join name) have been selected, don't disable
  // e.g. if A and B have been selected, try to select C.D, it's fine
  let joinNames: string[] = join.parentJoinNamesArray.concat([join.name]);
  if (intersection(joinNames, selectedJoinNames).length === 0) {
    return true;
  }

  // if parts of this join has been selected, only allow it if the new join names added have not been used yet
  // e.g. if A and B have been selected, try to select A.C, it's fine because C has not been used yet
  //
  // How this works:
  // If A and B have been selected, then selected join names is [A, B]
  // So when we try to select A.C, the addedParent is A, the remainingJoinsToCheck is C.
  // C doesn't exist, so it an be added.
  let joinsToAdd: string[] = getInvolvedJoinNamesForJoin(join);
  let addedParent = '';

  // iterate through all the joins to add.
  // so if trying to add A.B.C.D, the joins to try adding are: A, A.B, A.B.C, A.B.C.D
  joinsToAdd.forEach((joinName: string, index: number) => {
    // get the previous largest join that has already been selected.
    // e.g. if A.B has been selected, and trying to add A.B.C.D, then A.B is the "addedParent".
    if (selectedFullJoinNames.indexOf(joinName) !== -1) {
      addedParent = joinName;
    }
  });

  // get the remaining pieces that we are trying to add.
  // e.g. if A.B has been selected, and trying to add A.B.C.D, then [C, D] is the "remainingJoinsToCheck".
  let remainingJoinsToCheck = join.fullJoinName.slice(addedParent.length).split('.');

  // none of the remaining joins should be selected for it to be a valid join
  // e.g. if A.B has been selected, and trying to add A.B.C.D, then C and D cannot have been selected.
  for (let i: number = 0; i < remainingJoinsToCheck.length; i++) {
    // if the join name has already been selected, it is invalid, return right away
    let matchedIndex: number = selectedJoinNames.indexOf(remainingJoinsToCheck[i]);
    if (remainingJoinsToCheck[i] && matchedIndex !== -1) {
      return false;
    }
  }

  return true;
}

/**
 * Should the join be displayed depending on its parent viewnames?
 * @param {Join} join
 * @param {View} baseView
 * @returns {boolean}
 */
export function shouldDisplayJoinBasedOnParents(join: Join, baseView: View) {
  // No conditions for the join to be shown
  if ((!join.showUnder || join.showUnder.length === 0) &&
    (!join.hideUnder || join.hideUnder.length === 0)) {
    return true;
  }

  let selectedViewNames = map(join.parents, 'viewName');
  selectedViewNames.push(baseView.name);

  if (join.hideUnder && (join.hideUnder.length > 0)) {
    // if the parents of this join contain one of the items in hideUnder, then hide the join.
    let shouldHide = intersection(selectedViewNames, join.hideUnder);

    if (shouldHide.length !== 0) {
      return false;
    }
  }

  if (join.showUnder && (join.showUnder.length > 0)) {
    // all items in showUnder have to be present in the parents selected for the join to show.
    let shouldShow = intersection(selectedViewNames, join.showUnder);

    if (shouldShow.length !== join.showUnder.length) {
      return false;
    }
  }

  return true;
}

/**
 * Returns unique selected join names
 *
 * e.g. if A, A.B is selected, returns [A, B]
 * @param {Array<JoinAndDimensions | JoinAndMeasures>} selectedDimensionsOrMeasures
 * @returns {string[]}
 */
export function generateSelectedJoinNames
  (selectedDimensionsOrMeasures: Array<JoinAndDimensions | JoinAndMeasures>) {
  let selectedJoinNames: string[] = [];

  selectedDimensionsOrMeasures.forEach((selected: JoinAndDimensions | JoinAndMeasures) => {
    selectedJoinNames = selectedJoinNames.concat(selected.join.parentJoinNamesArray);
    selectedJoinNames.push(selected.join.name);
  });

  selectedJoinNames = uniq(selectedJoinNames);

  return selectedJoinNames;
}

/**
 * Returns selected full join names
 *
 * e.g. if A.B.C is selected, then [A, A.B, A.C] is returned
 * @param {Array<JoinAndDimensions | JoinAndMeasures>} selectedDimensionsOrMeasures
 * @returns {string[]}
 */
export function generateSelectedFullJoinNames
  (selectedDimensionsOrMeasures: Array<JoinAndDimensions | JoinAndMeasures>) {
  let selectedFullJoinNames: string[] = [];

  selectedDimensionsOrMeasures.forEach((selected: JoinAndDimensions | JoinAndMeasures) => {
    selectedFullJoinNames =
      selectedFullJoinNames.concat(getInvolvedJoinNamesForJoin(selected.join));
  });

  selectedFullJoinNames = uniq(selectedFullJoinNames);

  return selectedFullJoinNames;
}