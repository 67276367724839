import * as React from 'react';
import { AppState } from '../redux/index';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { View } from '../types/viewModel';
import { Module } from '../types/moduleModel';
import { SuggestedReportService } from '../services/suggestedReportService';
import { Join } from '../types/joinModel';
import { Tree, TreeItem } from '../components/Tree';
import { map, filter, find } from 'lodash';
import { storeView } from '../actions/view';
import { getRelatedJoins } from '../services/joinService';
import { JoinAndDimensions, JoinAndMeasures, SuggestedReportState } from '../redux';
import { Dimension } from '../types/dimensionModel';
import { Measure } from '../types/measureModel';
import { DATA_TYPE_DIMENSIONS, DATA_TYPE_MEASURES } from '../constants';
import { GroupBy } from '../types/groupByModel';
import ClearButton from '../components/ClearButton';
import { resetReportColumnsAction } from '../actions/reportBuilder';

export interface ViewSelectProps {
  views: View[];
  selectedView: View;
  dispatch: Dispatch<any>;
  module: Module;
  onBaseViewSelect: (view: View) => void;
  onJoinSelect: (selectedJoin: Join) => void;
  selectedDimensions: Dimension[];
  selectedJoinDimensions: JoinAndDimensions[];
  selectedMeasures: Measure[];
  selectedJoinMeasures: JoinAndMeasures[];
  selectedGroupBys: GroupBy[];
  dataType: DATA_TYPE_DIMENSIONS | DATA_TYPE_MEASURES;
  suggestedReports: SuggestedReportState;
  toggleSelectFormModal: () => void;
}

interface ViewSelectState {
  joins: Map<string, Join>;
  rootNode: TreeItem;
  currSelectedNode: TreeItem | null;
  prevSelectedNode: TreeItem | null;
}

const mapStateToProps = (state: AppState) => ({
  views: state.views,
  selectedView: state.view,
  module: state.module,
  selectedDimensions: state.dimensions,
  selectedJoinDimensions: state.joinDimensions,
  selectedMeasures: state.measures,
  selectedJoinMeasures: state.joinMeasures,
  selectedGroupBys: state.groupBys,
  dataType: state.dataType,
  suggestedReports: state.suggestedReports
});

class ViewSelectComponent extends React.Component<ViewSelectProps, ViewSelectState> {
  protected suggestedReportService: SuggestedReportService;

  constructor(props: ViewSelectProps) {
    super(props);

    // Create the tree for the currently selected module
    let rootNode: TreeItem = this.createTree(this.props.views, this.props.selectedView, this.props.module);

    this.state = {
      joins: new Map<string, Join>(),
      rootNode: rootNode,
      currSelectedNode: null,
      prevSelectedNode: null
    };

    this.suggestedReportService = SuggestedReportService.getInstance();
  }

  componentDidUpdate(prevProps: ViewSelectProps) {
    // if the modules have been changed, updating the views, then re-create the tree structure
    if (this.props.views !== prevProps.views) {
      let rootNode: TreeItem = this.createTree(this.props.views, this.props.selectedView, this.props.module);

      this.setState({
        rootNode: rootNode,
        currSelectedNode: null,
        prevSelectedNode: null
      });
    }

    let prevSelected: number = this.countSelectedColumns(prevProps);
    let currSelected: number = this.countSelectedColumns(this.props);

    /**
     * Recalculate the base view states the first time a column has been added,
     * or when the base view columns are cleared.
     */
    let firstColumnAddedOrBaseViewCleared: boolean = ((prevSelected === 0) && (currSelected > 0)) ||
      ((prevSelected > 0) && (currSelected === 0));

    if (firstColumnAddedOrBaseViewCleared) {
      this.onFirstColumnAddedOrBaseViewCleared();
    }

    if ((this.props.selectedView.name !== prevProps.selectedView.name)) {
      this.updateBaseViewNodes(this.props.selectedView);
    }
  }

  countSelectedColumns(props: ViewSelectProps) {
    return props.selectedDimensions.length + props.selectedMeasures.length +
      props.selectedJoinDimensions.length + props.selectedJoinMeasures.length +
      props.selectedGroupBys.length;
  }

  // Create tree structure for display
  // root: module name
  // level 1: base views
  // level 2: joins of base views
  // level N: joins of the joins
  createTree(views: View[], selectedView: View, module: Module): TreeItem {
    let totalSelected: number = this.getTotalColumnsSelected();
    let root: TreeItem = new TreeItem();
    const canImportForms = this.props.module.canImport;

    const selectFormsButtonOptions = {
      data: 'selectFormButton',
      content: this.renderBaseViewLabel('', '', true),
      children: [],
      onItemClick: this.onFormsViewButtonClick,
      alwaysShowOpenStateIcon: false,
      parent: root,
      renderKey: 0,
      className: '',
      isOpen: false,
      disabled: false,
      showAddIcon: true,
    };

    // create the base views
    let baseViewsItems = filter(map(views, (view: View) => {
      // if the view is not a base view, don't show it as part of the base views
      if (!view.isBaseView) {
        return null;
      }

      let baseViewTreeItem: TreeItem = new TreeItem();
      return Object.assign(baseViewTreeItem, {
        data: view.name,
        content: this.renderBaseViewLabel(view.label, view.name),
        children: selectedView.name === view.name ?
          this.createChildrenForSelectedView(selectedView, baseViewTreeItem) :
          [],
        onItemClick: this.onBaseViewSelect(view),
        alwaysShowOpenStateIcon: true,
        parent: root,
        className: (this.props.selectedView.name === view.name) && (totalSelected > 0) ? ' active ' : '',
        isOpen: (this.props.selectedView.name === view.name),
        disabled: (this.props.selectedView.name !== view.name) && (totalSelected > 0),
        showAddIcon: false,
      });
    }));

    baseViewsItems = canImportForms ? [selectFormsButtonOptions, ...baseViewsItems] : baseViewsItems;

    // create the root node containing the module name
    root = Object.assign(root, {
      data: module.name,
      content: module.label,
      children: baseViewsItems,
      isOpen: true
    });

    return root;
  }

  getTotalColumnsSelected = () => {
    return this.props.selectedDimensions.length + this.props.selectedMeasures.length +
    this.props.selectedJoinDimensions.length + this.props.selectedJoinMeasures.length +
    this.props.selectedGroupBys.length;
  }

  renderBaseViewLabel = (viewLabel: string, viewName: string, isFormsButtonRender?: boolean) => (item: TreeItem) => {
    let selectedCount: number = 0;
    if (viewName === this.props.selectedView.name) {
      switch (this.props.dataType) {
        case DATA_TYPE_DIMENSIONS:
          selectedCount = this.props.selectedDimensions.length;
          break;
        case DATA_TYPE_MEASURES:
          let selectedGroupBys: number =
            filter(
              this.props.selectedGroupBys,
              (groupBy: GroupBy) => {
                return !groupBy.join;
            }).length;
          selectedCount = this.props.selectedMeasures.length + selectedGroupBys;
          break;
        default:
          selectedCount = 0;
      }
    }

    let hasSelectedCols: boolean = (this.props.selectedView.name === viewName) &&
      (this.countSelectedColumns(this.props) > 0);
    let boldClass: string = hasSelectedCols ? ' font-weight-bold ' : '';

    return (
      <div className="label">
        <span className={boldClass}>
          {isFormsButtonRender ? 'Select Form...' : viewLabel}
        </span>

        <div className="d-flex align-items-center label-buttons">
          {selectedCount > 0 && this.renderNumberSelected(selectedCount)}
          <ClearButton
            onClick={this.onResetReportColumnsClick}
            className={!hasSelectedCols ? ' invisible ' : ''}
          />
        </div>
      </div>
    );
  }

  renderJoinLabel = (join: Join) => (item: TreeItem) => {
    let selectedCount: number = 0;

    switch (this.props.dataType) {
      case DATA_TYPE_DIMENSIONS: {
        let selected = find(
          this.props.selectedJoinDimensions,
          (s: JoinAndDimensions) => {
            return Join.equals(s.join, join);
          });

        selectedCount = selected ? selected.dimensions.length : 0;
        break;
      }

      case DATA_TYPE_MEASURES: {
        let selectedMeasures = find(
          this.props.selectedJoinMeasures,
          (s: JoinAndMeasures) => {
            return Join.equals(s.join, join);
          });

        let selectedGroupBys = filter(
          this.props.selectedGroupBys,
          (s: GroupBy) => {
            return s.join && Join.equals(s.join, join);
          }).length;

        selectedCount = selectedGroupBys + (selectedMeasures ? selectedMeasures.measures.length : 0);
        break;
      }

      default: {
        selectedCount = 0;
      }
    }

    return (
      <div className="label">
        <span>
          {join.label}
        </span>
        {selectedCount > 0 &&
          this.renderNumberSelected(selectedCount)
        }
      </div>
    );
  }

  onResetReportColumnsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.dispatch(resetReportColumnsAction());
  }

  renderNumberSelected(count: number) {
    return (
      <div className="badge badge-pill">
        {count}
      </div>
    );
  }

  // create TreeItem children for a join
  createChildrenForJoinNode(join: Join, baseView: View, nestingLevel: number, parentTreeItem: TreeItem): TreeItem[] {
    let relatedJoins: Join[] = getRelatedJoins(join, this.props.views, baseView, nestingLevel);
    return map(relatedJoins, (relatedJoin: Join) => {
      let treeItem: TreeItem = new TreeItem();
      return Object.assign(treeItem, {
        data: relatedJoin.name,
        content: this.renderJoinLabel(relatedJoin),
        children: this.createChildrenForJoinNode(relatedJoin, baseView, nestingLevel + 1, treeItem),
        onItemClick: this.onJoinSelect(relatedJoin),
        parent: parentTreeItem,
        showSelectedIcon: false
      });
    });
  }

  createChildrenForSelectedView(selectedView: View, baseViewTreeItem: TreeItem): TreeItem[] {
    return map(selectedView.joins, (j: Join) => {
      // Create a copy of the Join object as the Joins coming in are not actually Joins, they are Objects
      // compatible with the Join class.
      // Required so that we can use the Join class methods.
      let baseViewJoin: Join = new Join();
      Object.assign(baseViewJoin, j);
      baseViewJoin.parents = [];

      let treeItem: TreeItem = new TreeItem();
      return Object.assign(treeItem, {
        data: baseViewJoin.name,
        content: this.renderJoinLabel(baseViewJoin),
        children: this.createChildrenForJoinNode(baseViewJoin, selectedView, 1, treeItem),
        onItemClick: this.onJoinSelect(baseViewJoin),
        parent: baseViewTreeItem,
        showSelectedIcon: false
      });
    });
  }

  onFirstColumnAddedOrBaseViewCleared() {
    let rootNode = Object.assign(new TreeItem(), this.state.rootNode);

    map(rootNode.children, (baseViewItem: TreeItem) => {
      // disable the base views that have not been selected
      let baseViewName: string = baseViewItem.data;
      let totalSelected: number = this.getTotalColumnsSelected();

      baseViewItem.disabled = (this.props.selectedView.name !== baseViewName) && (totalSelected > 0);
      baseViewItem.className = (this.props.selectedView.name === baseViewName) && (totalSelected > 0) ?
        ' active ' : '';
    });

    this.setState({
      rootNode: rootNode
    });
  }

  // create TreeItem children for the selected base view
  updateBaseViewNodes(selectedView: View) {
    let rootNode = this.state.rootNode;
    let currSelectedNode: TreeItem | null = null;

    // clear the children of all the other base views
    map(rootNode.children, (baseViewItem: TreeItem) => {
      // get the children of the selected base views
      let baseViewName: string = baseViewItem.data;

      baseViewItem.isOpen = false;
      baseViewItem.children = [];

      if (baseViewName === selectedView.name) {
        baseViewItem.isOpen = true;
        baseViewItem.children = this.createChildrenForSelectedView(selectedView, baseViewItem);

        // update the current selected node to this cloned node, because the currentSelectedNode
        // is pointing to the reference to the old base view
        currSelectedNode = baseViewItem;
      }
    });

    this.setState({
      currSelectedNode: currSelectedNode,
    });
  }

  onFormsViewButtonClick = () => {
    this.props.toggleSelectFormModal();
  }

  onBaseViewSelect = (selectedView: View) => (item: TreeItem) => {
    this.props.onBaseViewSelect(selectedView);
    this.onTreeItemSelected(item);

    // recalculate the joins and clear join selections only if the selected base view is different
    if (selectedView.name !== this.props.selectedView.name) {
      this.props.dispatch(storeView(selectedView));
    }

    this.props.dispatch(this.suggestedReportService.getSuggestionsBaseView());

  }

  onJoinSelect = (selectedJoin: Join) => (item: TreeItem) => {
    this.onTreeItemSelected(item);
    this.props.dispatch(this.suggestedReportService.getSuggestionsJoin(selectedJoin));
    this.props.onJoinSelect(selectedJoin);
  }

  /**
   * When an item is selected, set its isSelected to true, and set the isSelected of the previous select item to false
   * @param {TreeItem} item
   */
  onTreeItemSelected(item: TreeItem) {
    item.isSelected = true;
    let prevItem: TreeItem | null = this.state.currSelectedNode;

    if (prevItem && (prevItem !== item)) {
      prevItem.isSelected = false;
    }

    this.setState({
      prevSelectedNode: prevItem,
      currSelectedNode: item
    });
  }

  render() {
    return (
      <div className="view-select-container" role="tree">
        <Tree rootNode={this.state.rootNode}/>
      </div>
    );
  }
}

const ViewSelect = connect(
  mapStateToProps)(ViewSelectComponent);

export default ViewSelect;
