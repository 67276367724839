import * as React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './style.css';
import { getClassNames } from '../../../services/utilityService';
import { FirstPageIcon, LastPageIcon, ChevronLeftIcon, ChevronRightIcon } from 'elmo-elements';

/**
 * Pagination Component
 *
 * Page number starts from 1
 */
export interface PaginationProps {
  handlePageClick: (pageNumber: number, offset: number, limit: number) => void;
  handleLimitChange?: (limit: number) => void;
  limit: number;
  offset: number;
  nextEnabled?: boolean;
  prevEnabled?: boolean;
  showLast?: boolean;
  totalItems?: number;
}

export const DIRECTION_NEXT = 'DIRECTION_NEXT';
export type DIRECTION_NEXT = typeof DIRECTION_NEXT;
export const DIRECTION_PREV = 'DIRECTION_PREV';
export type DIRECTION_PREV = typeof DIRECTION_PREV;
export type DIRECTION = DIRECTION_NEXT | DIRECTION_PREV;

class SimplePagination extends React.Component<PaginationProps> {
  public static readonly FIRST_PAGE: number = 1;

  /**
   * Called when page clicked
   * @param pageNumber
   */
  onPageClick = (pageNumber: number) => () => {
    const { limit, handlePageClick } = this.props;
    let offset = ((pageNumber - 1) * limit);
    handlePageClick(pageNumber, offset, limit);
  }

  /**
   * Renders first/last page buttons
   * @param pageNumber
   * @param key
   * @param label
   */
  renderPageButton(pageNumber: number, key: string) {
    return (
      <li
        className={'page-item'}
        onClick={this.onPageClick(pageNumber)}
        key={'page-' + key}
      >
        <a
          className="page-link"
          tabIndex={0}
        >
          {key === 'first' && <FirstPageIcon />}
          {key === 'last' && <LastPageIcon />}
        </a>
      </li>
    );
  }

  /**
   * Renders prev/next buttons
   * @param key
   * @param direction
   */
  renderPageDirection(key: string, direction: DIRECTION) {
    const isDirectionDisabled = (direction === DIRECTION_NEXT && !this.props.nextEnabled)
      || (direction === DIRECTION_PREV && !this.props.prevEnabled);

    const currentPage = this.getCurrentPage();
    // the number of the page that we will be on when the direction button is clicked
    const futurePage = direction === DIRECTION_NEXT ? currentPage + 1 : currentPage - 1;
    const isPageDisabled = this.hasCount() && !this.isValidPage(futurePage);
    const isDisabled = isDirectionDisabled || isPageDisabled;

    return (
      <li
        className={getClassNames('page-item', {disabled: isDisabled})}
        key={'page-' + key}
        {...!isDisabled ? { onClick: this.onPageClick(futurePage) } : {}}
      >
        <a
          className="page-link"
          tabIndex={0}
        >
          {direction === DIRECTION_PREV && <ChevronLeftIcon />}
          {direction === DIRECTION_NEXT && <ChevronRightIcon />}
        </a>
      </li>
    );
  }

  /**
   * Get total number of pages
   */
  getNumPages() {
    const { totalItems, limit } = this.props;
    return totalItems !== undefined ? Math.ceil(totalItems / limit) : 0;
  }

  /**
   * Get current page
   */
  getCurrentPage() {
    const { offset, limit } = this.props;
    return Math.floor(offset / limit) + 1;
  }

  /**
   * Check if pageNumber is within the total number of pages
   * @param pageNumber
   */
  isValidPage(pageNumber: number) {
    return pageNumber <= this.getNumPages() && pageNumber > 0;
  }

  /**
   * Returns true if totalItems has been passed in
   */
  hasCount() {
    return this.props.totalItems !== undefined;
  }

  render() {
    const { showLast } = this.props;
    return (
      <div className="new-pagination-wrapper" role="navigation">
        <nav className="pagination-component" aria-label="Report pagination">
          <ul className="pagination mb-0">
            {this.renderPageButton(SimplePagination.FIRST_PAGE, 'first')}
            {this.renderPageDirection('prev', DIRECTION_PREV)}
            {this.renderPageDirection('next', DIRECTION_NEXT)}
            {showLast && this.hasCount() && this.renderPageButton(this.getNumPages(), 'last')}
          </ul>
        </nav>
      </div>
    );
  }
}

export default SimplePagination;
