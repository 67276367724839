import * as React from 'react';
import { Module } from '../../../types/moduleModel';
import { memoizeApiGetModules } from '../../../services/reportService';
import { handleFormChange } from '../../../services/formUtilService';
import { map } from 'lodash';

export interface ModuleSelectInputProps {
  selectedModule: Module | null;
  onChange: (module: Module) => void;
  onLoading: (isLoading: boolean) => void;
}

export interface ModuleSelectState {
  modules: Module[];
}

class ModuleSelectInput extends React.Component<ModuleSelectInputProps, ModuleSelectState> {

  constructor(props: ModuleSelectInputProps) {
    super(props);

    this.state = {
      modules: []
    };
  }

  async componentDidMount() {
    this.props.onLoading(true);

    try {
      let modules = await memoizeApiGetModules();

      this.props.onChange(modules[0]);
      this.props.onLoading(false);
      this.setState({
        modules: modules
      });
    } catch (e) {
      this.props.onLoading(false);
    }
  }

  onChange = (val: string) => {
    let foundModule = this.state.modules.find(function(module: Module) {
      return module.id === val;
    });

    if (foundModule) {
      this.props.onChange(foundModule);
    }
  }

  render() {
    return (
      <select
        className="form-control"
        value={this.props.selectedModule ? this.props.selectedModule.id : ''}
        onChange={handleFormChange(this.onChange)}
      >
        {map(this.state.modules, (module: Module, key: number) => {
          return (
            <option key={key} value={module.id}>
              {module.name}
            </option>
          );
        })}
      </select>
    );
  }
}

export default ModuleSelectInput;