import * as React from 'react';
import classnames from 'classnames';
import { Modal } from 'elmo-elements';
import { ModalProps } from 'elmo-elements/Modal';
import './style.css';

interface Props {
  autoScroll?: boolean;
  headerCloseDisabled?: boolean;
}

export type ElmoModalProps = ModalProps & Props;

const ElmoModal: React.FC<ElmoModalProps>  = ({ 
  autoScroll,
  children, 
  className, 
  hasCloseFooterButton = false, 
  headerCloseDisabled,
  ...props
}) => {
  return (
    <Modal
      className={
        classnames(
          'elmo-modal--override',
          className,
          { 'is-auto-scroll': autoScroll, 'header-close-disabled': headerCloseDisabled }
        )
      }
      hasCloseFooterButton={hasCloseFooterButton}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default ElmoModal;
