import * as React from 'react';
import { Select, FormItem } from 'elmo-elements';

import { apiGetOutboundDestinations } from '../../../services/schedulerService';

import './style.css';

export type OutboundDestinationOption = {
  value: string;
  label: string;
};

type Props = {
  onChange: (outboundDestinationId: string) => void;
  selectedId: string | null;
  errors: any;
};

type State = {
  outboundDestinations: OutboundDestinationOption[];
  isLoading: boolean;
};

class OutboundSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      outboundDestinations: [],
      isLoading: false
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    try {
      await this.getOutboundDestinations();
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }

  getOutboundDestinations = async () => {
    const data = await apiGetOutboundDestinations();

    const outboundDestinations: OutboundDestinationOption[] = data.map((outboundDestination: any) => {
      const provider: string = outboundDestination['provider'];
      const label: string = outboundDestination['title'] +
        ' (' + provider.toUpperCase() + ')';

      return {
        value: outboundDestination['id'],
        label: label
      };
    });

    this.setState({
      outboundDestinations,
    });
  }

  onChange = (outboundDestination: OutboundDestinationOption) => {
    const { onChange } = this.props;
    onChange(outboundDestination.value);
  }

  render() {
    const { errors, selectedId } = this.props;
    const { outboundDestinations, isLoading } = this.state;

    const selected = outboundDestinations.find((outboundDestination) => {
      return outboundDestination.value === selectedId;
    });

    return (
      <FormItem
        label="Outbound"
        message={!!errors['outboundDestinationId'] ? errors['outboundDestinationId'] : undefined}
        status={!!errors['outboundDestinationId'] ? 'error' : undefined}
        data-testid="schedule-outbound-select"
      >
        <Select
          options={outboundDestinations}
          value={selected}
          onChange={this.onChange}
          isDisabled={false}
          placeholder={isLoading ? 'Loading...' : 'Select an outbound destination'}
        />
      </FormItem>
    );
  }
}

export default OutboundSelect;
