import * as React from 'react';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { SettingsOutlinedIcon } from 'elmo-elements';
import { Popover } from 'react-tiny-popover';
import { Box } from '@eds/box';
import { Divider } from '@eds/divider';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { Heading } from '@eds/heading';
import { SelectInput } from '@eds/select-input-411';
import { TextInput } from '@eds/text-input';
import { FIELD_DEPRECATION_MESSAGE, useDeprecationContext } from 'src/features/report-builder';

import ElmoButton from '../ElmoButton';
import { DeleteIcon } from '../Icons/DeleteIcon';
import { DragVerticleIcon } from '../Icons/DragVerticleIcon';
import { CustomColumn, CustomColumnType } from '../../../types/customColumnModel';
import { ColumnMeta } from '../../../types/columnMetaModel';

import { isColumnMeta, isCustomColumn, itemIdPrefix } from './hooks';

interface Props {
  provided: DraggableProvided;
  customColumn: CustomColumn;
  renameEnabled: boolean;
  index: number;
  managedColumnMeta: Array<ColumnMeta>;
  formValue: string | null | undefined;
  setFormValue: (key: string, value: string | null) => void;
  isPopoverOpen: boolean;
  openPopover: () => void;
  consolidatedColumns: Array<CustomColumn | ColumnMeta>;
  setConsolidatedColumns: Dispatch<SetStateAction<Props['consolidatedColumns']>>;
  closePopover: () => void;
  renamedTitle: string | undefined;
  setRenamedCustomColumns: (key: string, value: string) => void;
  setIsChangedTrue: () => void;
}

interface Option {
  deprecated?: boolean;
  label: string;
  value: string;
}

const CustomColumnCard = (props: Props) => {
  const {
    provided,
    renameEnabled,
    index,
    managedColumnMeta,
    setFormValue,
    isPopoverOpen,
    openPopover,
    consolidatedColumns,
    setConsolidatedColumns,
    closePopover,
    renamedTitle,
    setRenamedCustomColumns,
    setIsChangedTrue
  } = props;

  const { title, id, type, value: customColumnValue, joinName: customColumnJoinName } = props.customColumn;
  const formValue = props.formValue || '';

  const { customColumnDeprecated } = useDeprecationContext() || { };
  const isDeprecated = !!(
    type === CustomColumnType.Dimension &&
    customColumnDeprecated &&
    customColumnDeprecated(customColumnValue || '', customColumnJoinName)
  );  

  const currentCustomColumns = consolidatedColumns.filter(isCustomColumn);

  const options: Option[] = managedColumnMeta.map(cm => {
    let value = '';

    if (cm.join) {
      value = `${cm.join.fullJoinName}.`;
    }

    if (cm.dimension) {
      value = `${value}${cm.dimension.name}`;
    }

    return {
      deprecated: cm.dimension?.deprecated,
      label: cm.displayLabel || '',
      value,
    };
  });

  const currentColumn = options.find(({ value }) => customColumnValue === value);

  const onOptionSelect = ({ value }: Option) => setFormValue(id, value);

  const onTextChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => {
    setFormValue(currentTarget.name, currentTarget.value);
  };

  const resetFormValue = () => {
    const initialFormValue = currentCustomColumns.find(({ id: targetId }) => targetId === id);

    if (!initialFormValue) {
      return;
    }

    setFormValue(id, initialFormValue.value);
  };

  const onCancelCustomColumnChanges = () => {
    resetFormValue();
    closePopover();
  };

  const onConfirmCustomColumnChanges = () => {
    const value = formValue;

    if (!value) {
      onCancelCustomColumnChanges();
      return;
    }

    setConsolidatedColumns(
      consolidatedColumns.map(column => {
        if (isColumnMeta(column)) {
          return column;
        }

        if (column.id !== id) {
          return column;
        }
        
        const { joinName, ...customColumn } = column;
        let hasJoin = false;

        if (customColumn.type === CustomColumnType.Dimension) {
          hasJoin = value.includes('.');
        }

        if (hasJoin) {
          return { ...customColumn, value, joinName: value.substring(0, value.lastIndexOf('.')) };
        }

        return { ...customColumn, value };
      })
    );

    setIsChangedTrue();
    closePopover();
  };

  const onClickOutside = ({ target }: MouseEvent) => {
    const isValidHTMLTarget = (object: EventTarget | null): object is HTMLElement => {
      return !!object && 'className' in object;
    };

    if (isValidHTMLTarget(target)) {
      const { className } = target;

      // NOTE: This only works for @eds/select-input@4.1.1
      const isSelectOption = typeof className === 'string' &&
        (className.includes('custom-field-option') || className.includes('css') && className.includes('option'));

      if (isSelectOption) {
        return;
      }
    }

    onCancelCustomColumnChanges();
  };

  const onDeleteButtonClick = () => {
    const adjustedColumns = [...consolidatedColumns];
    adjustedColumns.splice(index, 1);
    setConsolidatedColumns(adjustedColumns);
    setIsChangedTrue();
  };

  const onRename: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const value = target.value.substring(0, 255);
    setRenamedCustomColumns(id, value);
    setIsChangedTrue();
  };

  const description =
    type === CustomColumnType.Empty
      ? 'Null value'
      : type === CustomColumnType.String
      ? `Default to ${customColumnValue ? `'${customColumnValue}'` : 'text'}`
      : type === CustomColumnType.Dimension
      ? `Select from ${currentColumn?.label || 'field'}`
      : '';

  const fieldDescription = 'Please enter the value you want to display in the column (must have at least 1 character)';

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className="report-column__list-item"
      id={`${itemIdPrefix}${id}`}
    >
      <div {...provided.dragHandleProps} className="report-column__list-item-handle">
        <div>
          <DragVerticleIcon />
        </div>
        <div className="report-column__list-item-content">
          <Field
            caution={isDeprecated}
            keepSpaceForMessage={false}
            label={`${title}${description ? `: ${description}` : ''}`}
            message={isDeprecated ? FIELD_DEPRECATION_MESSAGE : ''}
          >
            {renameEnabled && (
              <TextInput
                onChange={onRename}
                placeholder="Rename table column header"
                size="small"
                value={renamedTitle || ''}
              />
            )}
          </Field>
        </div>
        <Flex>
          {
            type !== CustomColumnType.Empty &&
              <Popover
                isOpen={isPopoverOpen}
                content={
                  <Box
                    backgroundColor="neutral"
                    borderRadius="large"
                    boxShadow="medium"
                    maxWidth={364}
                    width={364}
                  >
                    <Box paddingX="medium" paddingY="small">
                      <Heading level={6}>Configure</Heading>
                    </Box>
                    <Divider />
                    <Box padding="medium">
                      {type === CustomColumnType.String &&
                        <Field description={fieldDescription} keepSpaceForMessage={false} label="Value">
                          <TextInput name={id} onChange={onTextChange} size="small" value={formValue} />
                        </Field>
                      }
                      {type === CustomColumnType.Dimension &&
                        <Field
                          description="Please select the field to copy values from"
                          label="Select field"
                          keepSpaceForMessage={false}
                        >
                          <SelectInput
                            isClearable={false}
                            items={options.filter(({ deprecated }) => !deprecated)}
                            itemOverride={({ data }) => <Box className="custom-field-option">{data.label}</Box>}
                            onChange={onOptionSelect}
                            size="small"
                            value={options.find(({ value }) => formValue === value)}
                          />
                        </Field>
                      }
                    </Box>
                    <Flex paddingX="medium" paddingY="small" justifyContent="flex-end" gap="xsmall">
                      <ElmoButton type="text" onClick={onCancelCustomColumnChanges}>Cancel</ElmoButton>
                      <ElmoButton type="primary" onClick={onConfirmCustomColumnChanges} isDisabled={!formValue}>
                        Done
                      </ElmoButton>
                    </Flex>
                  </Box>
                }
                contentLocation={({ childRect: cr, popoverRect: pr }) => {
                  return { top: cr.bottom + 8, left: cr.left - pr.width + cr.width - 8 };
                }}
                onClickOutside={onClickOutside}
              >
                <Box>
                  <ElmoButton
                    className="btn-settings"
                    icon={<SettingsOutlinedIcon />}
                    isActive={isPopoverOpen}
                    isIcon={true}
                    type="text"
                    onClick={() =>
                      // wait for onClickOutside event to trigger first
                      setTimeout(() => {
                        if (isPopoverOpen) {
                          closePopover();
                          return;
                        }

                        openPopover();
                      })
                    }
                  />
                </Box>
              </Popover>
          }
          <ElmoButton
            icon={<DeleteIcon />}
            isIcon={true}
            type="text"
            onClick={onDeleteButtonClick}
          />
        </Flex>
      </div>
    </div>
  );
};

export default CustomColumnCard;
