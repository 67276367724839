import * as React from 'react';
import { AppState, SaveQueryState } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SaveQueryComponent, { SaveQueryFormData } from '../components/SaveQuery';
import { saveQueryAndChart, storeReportTitle } from '../actions/report';
import { NotificationService } from '../services/notificationService';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../constants';
import { Chart } from '../types/chartModel';
import { SavedQuery } from '../types/savedQueryModel';
import { AuthService } from '../services/authService';
import { SuggestedReportService } from '../services/suggestedReportService';
import { storeSavedQuery } from '../actions/savedQueries';
import {
  apiGetUserCapabilitiesForReport,
  UserCapabilitiesType,
  defaultUserCapabilities,
} from '../services/reportService';
export interface SaveQueryContainerProps {
  dispatch: Dispatch<any>;
  saveQueryState: SaveQueryState;
  notificationService: NotificationService;
  chart: Chart;
  loadedQuery?: SavedQuery;
  disabled?: boolean;
  authService: AuthService;
}

export interface SaveQueryContainerState {
  showModal: boolean;
  userCapabilities: UserCapabilitiesType;
}

const mapStateToProps = (state: AppState) => ({
  saveQueryState: state.saveQuery,
  notificationService: state.notificationService,
  chart: state.chart,
  authService: state.authService
});

class SaveQueryContainer extends React.Component<SaveQueryContainerProps, SaveQueryContainerState> {
  protected suggestedReportService: SuggestedReportService;

  constructor(props: SaveQueryContainerProps) {
    super(props);
    this.state = {
      showModal: false,
      userCapabilities: defaultUserCapabilities,
    };

    this.suggestedReportService = SuggestedReportService.getInstance();
  }

  onSaveClicked = async (formData: SaveQueryFormData, saveAs: boolean) => {
    try {
      let query: SavedQuery = await this.props.dispatch(
        saveQueryAndChart(saveAs, this.props.loadedQuery, this.props.chart, formData)
      );
      // Populate the report title with the inputted title
      this.props.dispatch(storeReportTitle(formData.name));
      this.props.notificationService.addNotification(NOTIFICATION_SUCCESS, 'Report saved successfully.');
      this.props.dispatch(storeSavedQuery(query));
    } catch (e) {
      this.props.notificationService.addNotification(NOTIFICATION_ERROR, 'Could not save report.');
    } finally {
      this.setShowModal(false);
    }
  }

  setShowModal = (showModal: boolean) => {
    this.setState({
      showModal: showModal
    });
  }

  async componentDidMount() {
    const user = this.props.authService.getUserData();

    try {
      if (user) {
        const userCapabilities = await apiGetUserCapabilitiesForReport(user.id);
        this.setState({ userCapabilities });
      }
    } catch (e) {
      // console.log(e);
    }
  }

  render() {
    let canSaveSuggestions: boolean = this.suggestedReportService.hasSuggestionsCapabilities(this.props.authService);
    return (
      <SaveQueryComponent
        onSaveClicked={this.onSaveClicked}
        saving={this.props.saveQueryState.saving}
        showSaveError={this.props.saveQueryState.showSaveError}
        showModal={this.state.showModal}
        setShowModal={this.setShowModal}
        disabled={this.props.disabled}
        canSaveSuggestions={canSaveSuggestions}
        loadedQuery={this.props.loadedQuery}
        userCapabilities={this.state.userCapabilities}
      />
    );
  }
}

const SaveQuery = connect(
  mapStateToProps)(SaveQueryContainer);
export default SaveQuery;
