import { ScheduledReportsUsersPayload } from 'src/features/scheduled-reports';

export type OutboundNotificationRecipient =
  | {
      recipientType: 'email';
      email: string;
      uuid?: never;
    }
  | {
      recipientType: 'user';
      email?: never;
      uuid: string;
    };

export interface ReportInfo {
  reportId: string;
  reportName: string;
}

export interface ScheduledReport {
  calendarDays: Array<number>;
  daysOfTheWeek: Array<number>;
  encryptionKey?: string;
  endDate: string | null;
  filenameTokens: Array<string>;
  id: string;
  isEncryptionEnabled: boolean;
  isFilenameCustomisable: boolean;
  lastDayOfTheMonth: boolean;
  nextScheduleDate: string;
  notificationRecipients?: Array<OutboundNotificationRecipient>;
  occurrence: ScheduledReportOccurrence;
  outboundDestinationId?: string;
  query: {
    id: string;
    name: string;
  };
  scheduleName: string;
  startDate: string;
  status: ScheduledReportStatus;
  targetUserIds?: Array<string>;
  timezone: string;
}

export type ScheduledReportOccurrence = 'once' | 'daily' | 'daily_weekday' | 'weekly' | 'monthly';
export type ScheduledReportOccurrenceLabel = 'Once' | 'Daily' | 'Daily - Monday to Friday' | 'Weekly' | 'Monthly';
export type ScheduledReportStatus = 'Active' | 'Ended' | 'Paused';

// NOTE: This should match the token id used by backend
export const OUTBOUND_FILENAME_DASH_TOKEN_ID = 'schedule-filename-token-dash';
export const OUTBOUND_FILENAME_EXCLAMATION_TOKEN_ID = 'schedule-filename-token-exclamation';
export const OUTBOUND_FILENAME_SPACE_TOKEN_ID = 'schedule-filename-token-space';
export const OUTBOUND_FILENAME_UNDERSCORE_TOKEN_ID = 'schedule-filename-token-underscore';

export const occurrenceLabels: Array<ScheduledReportOccurrenceLabel> = [
  'Once',
  'Daily',
  'Daily - Monday to Friday',
  'Weekly',
  'Monthly',
];

export const occurrenceValues: Array<ScheduledReportOccurrence> = [
  'once',
  'daily',
  'daily_weekday',
  'weekly',
  'monthly',
];

export const occurrences = occurrenceLabels.map((label, index) => ({ label, value: occurrenceValues[index] }));

export const scheduledReportsKeys = {
  all: ['scheduled-reports'] as const,
  filenameTokens: () => [...scheduledReportsKeys.all, 'filename-tokens'] as const,
  lists: () => [...scheduledReportsKeys.all, 'list'] as const,
  outboundProviders: () => [...scheduledReportsKeys.all, 'outbound-providers'] as const,
  users: (filters: ScheduledReportsUsersPayload) => [...scheduledReportsKeys.all, 'users', { filters }] as const,
};
