import React from 'react';
import { Box } from '@eds/box';
import { Pagination, PaginationState } from '@eds/pagination';
import { css } from '@emotion/css';

import { cssOverrides } from 'src/features/ui';

type DownloadsListPaginationProps = {
  onPageUpdate: ({ currentItemsPerPage, currentPage, rangeStart }: PaginationState) => void;
  paginationData: Pick<PaginationState, 'currentPage' | 'currentItemsPerPage' | 'rangeStart'>;
  totalItems: number;
};

export const DownloadsListPagination = ({ onPageUpdate, paginationData, totalItems }: DownloadsListPaginationProps) => {
  return (
    <Box className={css(cssOverrides.button('ghost'))}>
      <Pagination
        currentPage={paginationData.currentPage}
        currentItemsPerPage={paginationData.currentItemsPerPage}
        itemsCount={totalItems}
        onPageUpdate={onPageUpdate}
      />
    </Box>
  );
};
