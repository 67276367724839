import * as React from 'react';
import { apiCheckConfiguration } from '../../../services/configurationCheckService';
import { Module } from '../../../types/moduleModel';
import ModuleSelectInput from '../../../components/Config/ModuleSelectInput';
import LoadingIcon from '../../../shared-components/LoadingIcon';

interface CheckConfigFormProps {
  onCheckConfigurationDone: (errors: string) => void;
}

interface FormData {
  module: Module | null;
}

interface CheckConfigFormState {
  form: FormData;
  loading: boolean;
  checkingConfig: boolean;
}

class CheckConfigForm extends React.Component<CheckConfigFormProps, CheckConfigFormState> {
  protected viewsRef: any;
  protected relationsRef: any;
  protected translationsRef: any;

  constructor(props: CheckConfigFormProps) {
    super(props);

    this.viewsRef = React.createRef();
    this.relationsRef = React.createRef();
    this.translationsRef = React.createRef();

    this.state = {
      form: {
        module: null
      },
      loading: false,
      checkingConfig: false
    };
  }

  onModulesLoading = (isLoading: boolean) => {
    this.setState({
      loading: isLoading
    });
  }

  onModuleSelected = (module: Module) => {
    let form = this.state.form;
    form.module = module;
    this.setState({
      form: form
    });
  }

  checkConfigs = async () => {
    if (!this.state.form.module) {
      return;
    }

    this.setState({
      checkingConfig: true
    });

    let viewFile: File = this.viewsRef.current.files[0];
    let relationsFile: File = this.relationsRef.current.files[0];
    let translationsFile: File = this.translationsRef.current.files[0];
    let errors = await apiCheckConfiguration(this.state.form.module.name, viewFile, relationsFile, translationsFile);
    this.props.onCheckConfigurationDone(errors);
    this.setState({
      checkingConfig: false
    });
  }

  renderForm() {
    return (
      <div className={'check-config-form ' + (this.state.loading ? 'd-none' : '')}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <form>
              <div className="form-group">
                <label>Module:</label>
                <ModuleSelectInput
                  selectedModule={this.state.form.module}
                  onChange={this.onModuleSelected}
                  onLoading={this.onModulesLoading}
                />
              </div>
              <div className="form-group">
                <label>Views</label>
                <input
                  type="file"
                  id="viewsFile"
                  className="form-control-file"
                  ref={this.viewsRef}
                />
              </div>
              <div className="form-group">
                <label>Relations</label>
                <input
                  type="file"
                  id="relationsFile"
                  className="form-control-file"
                  ref={this.relationsRef}
                />
              </div>
              <div className="form-group">
                <label>Translations</label>
                <input
                  type="file"
                  id="translationsFile"
                  className="form-control-file"
                  ref={this.translationsRef}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.checkConfigs}
                disabled={this.state.checkingConfig}
              >
                Check Configs
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="check-config-page">
        {this.state.loading && <LoadingIcon />}
        {this.renderForm()}
      </div>
    );
  }
}

export default CheckConfigForm;