import React from 'react';
import { Box } from '@eds/box';

import { DeleteScheduledReport, EditScheduledReport, ScheduledReportsList } from 'src/features/scheduled-reports';

import { useScheduledReportsPage } from './scheduled-reports-page.hooks';

export const ScheduledReportsPage = () => {
  const {
    isDeleting,
    isEditing,
    scheduledReportToDelete,
    scheduledReportToEdit,
    startDeleting,
    startEditing,
    stopDeleting,
    stopEditing,
  } = useScheduledReportsPage();

  return (
    <Box>
      <ScheduledReportsList onDelete={startDeleting} onEdit={startEditing} />
      <EditScheduledReport isOpen={isEditing} onClose={stopEditing} scheduledReportId={scheduledReportToEdit} />
      <DeleteScheduledReport isOpen={isDeleting} onClose={stopDeleting} scheduledReportId={scheduledReportToDelete} />
    </Box>
  );
};
