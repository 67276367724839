import * as React from 'react';
import { AppState } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Join } from '../types/joinModel';
import { View } from '../types/viewModel';
import { getViewFromJoin } from '../services/viewService';
import {
  addJoinMeasure,
  addJoinMeasures,
  removeJoinMeasure,
  removeJoinMeasures
} from '../actions/joinColumn';
import { storeFilterOptions } from '../actions/filter';
import { JoinAndMeasures } from '../redux';
import { Measure } from '../types/measureModel';
import { SOURCE_WIZARD } from '../constants';
import { ColumnSelect, ColumnSelectItemGroup } from '../components/ColumnSelect';
import { GroupBy } from '../types/groupByModel';
import { MeasureSelectService } from '../services/measureSelectService';
import { Column } from '../types/columnModel';

export interface JoinMeasureSelectProps {
  dispatch: Dispatch<any>;
  join: Join;
  views: View[];
  baseView: View;
  joinMeasures: JoinAndMeasures[];
  checkedGroupBys: GroupBy[];
}

export interface JoinMeasureSelectState {
  view: View | null;
  itemGroups: ColumnSelectItemGroup[];
  allSelected: boolean;
}

const mapStateToProps = (state: AppState) => ({
  views: state.views,
  joinMeasures: state.joinMeasures,
  baseView: state.view,
  checkedGroupBys: state.groupBys
});

class JoinMeasureSelectComponent extends React.Component<JoinMeasureSelectProps, JoinMeasureSelectState> {
  constructor(props: JoinMeasureSelectProps) {
    super(props);

    this.state = {
      view: null,
      itemGroups: [],
      allSelected: false
    };
  }

  componentDidMount() {
    this.onJoinChanged(this.props.join, this.props.views, this.props.joinMeasures, this.props.checkedGroupBys);
  }

  componentDidUpdate(prevProps: JoinMeasureSelectProps) {
    if (!Join.equals(this.props.join, prevProps.join)) {
      this.onJoinChanged(this.props.join, this.props.views, this.props.joinMeasures, this.props.checkedGroupBys);
      return;
    }

    let joinMeasuresChanged: boolean = !Column.arrayEquals(
        this.getSelectedMeasures(this.props.joinMeasures),
        this.getSelectedMeasures(prevProps.joinMeasures));

    let groupBysChanged: boolean = !GroupBy.arrayEquals(this.props.checkedGroupBys, prevProps.checkedGroupBys);

    if ((joinMeasuresChanged || groupBysChanged) && this.state.view) {
      let itemGroups = this.state.itemGroups.slice();

      if (joinMeasuresChanged) {
        itemGroups[0] = this.createMeasureItems(this.state.view, this.props.joinMeasures);
      }

      if (groupBysChanged) {
        itemGroups[1] = MeasureSelectService.createGroupByItems(
          this.state.view,
          this.props.baseView,
          this.props.checkedGroupBys,
          this.props.dispatch,
          this.props.join);
      }

      this.setState({
        itemGroups: itemGroups
      });
    }
  }

  onJoinChanged(join: Join, views: View[], selectedJoinMeasures: JoinAndMeasures[], selectedGroupBys: GroupBy[]) {
    // get the view for the join, this contains the dimensions/measures
    let view = getViewFromJoin(views, join);

    if (view) {
      this.setState({
        view: view
      });

      this.createColumnSelectItems(view, selectedJoinMeasures, selectedGroupBys);
    }
  }

  createColumnSelectItems(view: View, selectedJoinMeasures: JoinAndMeasures[], selectedGroupBys: GroupBy[]) {
    let itemGroups: ColumnSelectItemGroup[] = [];
    itemGroups.push(this.createMeasureItems(view, selectedJoinMeasures));
    itemGroups.push(MeasureSelectService.createGroupByItems(
      view,
      this.props.baseView,
      selectedGroupBys,
      this.props.dispatch,
      this.props.join));

    this.setState({
      itemGroups: itemGroups
    });
  }

  createMeasureItems(view: View, selectedJoinMeasures: JoinAndMeasures[]): ColumnSelectItemGroup {
    let selectedMeasures: Measure[] = this.getSelectedMeasures(selectedJoinMeasures);

    this.setState({
      allSelected: selectedMeasures.length === view.measures.length
    });

    return MeasureSelectService.createMeasureItems(
      view,
      selectedMeasures,
      this.onMeasureAdd,
      this.onMeasureRemove);
  }

  render() {
    return (
      <ColumnSelect
        title={this.props.join.label}
        isAggregate={true}
        itemGroups={this.state.itemGroups}
        onSelectAllClick={this.toggleSelectAll}
        allSelected={this.state.allSelected}
      />
    );
  }

  toggleSelectAll = () => {
    let allSelected = !this.state.allSelected;

    if (allSelected) {
      this.onSelectAll();
    } else {
      this.onRemoveAll();
    }

    this.setState({
      allSelected: allSelected
    });
  }

  protected onMeasureAdd = (item: Measure) => () => {
    this.props.dispatch(addJoinMeasure(this.props.join, item, this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onMeasureRemove = (item: Measure) => () => {
    this.props.dispatch(removeJoinMeasure(this.props.join, item, this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onRemoveAll = () => {
    this.props.dispatch(removeJoinMeasures(
      this.props.join,
      this.getSelectedMeasures(this.props.joinMeasures),
      this.props.baseView,
      SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  protected onSelectAll = () => {
    if (!this.state.view || !this.state.view.measures) {
      return;
    }

    let selected: Measure[] = this.getSelectedMeasures(this.props.joinMeasures);

    let toAdd: Measure[] = this.state.view.measures.filter((m: Measure) => {
      return !MeasureSelectService.isMeasureSelected(m, selected);
    });

    this.props.dispatch(addJoinMeasures(this.props.join, toAdd, this.props.baseView, SOURCE_WIZARD));
    this.props.dispatch(storeFilterOptions());
  }

  /**
   * @param {JoinAndMeasures[]} joinMeasures
   * @returns {Measure[]}
   */
  protected getSelectedMeasures(joinMeasures: JoinAndMeasures[]): Measure[] {
    let selected = joinMeasures.find((j: JoinAndMeasures) => {
      // joins are only considered the same if they share the same name and parent join names
      return Join.equals(j.join, this.props.join);
    });

    return selected ? selected.measures : [];
  }
}

const JoinMeasureSelect = connect(
  mapStateToProps
)(JoinMeasureSelectComponent);

export default JoinMeasureSelect;
