import React from 'react';
import { Avatar } from '@eds/avatar';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { fontSize } from '@eds/core';
import { css } from '@emotion/css';

interface MenuItemCardProps {
  avatar: string;
  avatarName: string;
  email: string;
  name: string;
  role: string;
}

export const MenuItemCard = ({ avatar, avatarName, email, name, role }: MenuItemCardProps) => (
  <Flex gap="xsmall">
    <Avatar name={avatarName} src={avatar} size="small" />
    <Flex flexDirection="column" justifyContent="space-between">
      <Box
        className={css({
          span: {
            lineHeight: fontSize.small,
          },
        })}
      >
        {name && (
          <Text fontSize="small">
            {`${name}${role ? ', ' : ''}`}
            <Text color="neutralSubtle" fontSize="xsmall">
              {role}
            </Text>
          </Text>
        )}
      </Box>
      <Box
        className={css({
          // NOTE: No available EDS token for 0.625rem
          lineHeight: '0.625rem',
          span: {
            fontSize: '0.625rem',
            lineHeight: '0.625rem',
          },
        })}
      >
        <Text color="neutralSubtle">{email}</Text>
      </Box>
    </Flex>
  </Flex>
);
