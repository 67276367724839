import * as React from 'react';
import {
  DATETIME_FORMAT
} from '../../constants';
import DateRange from '../../shared-components/DateRange';
import { Filter } from '../../types/filterModel';
import moment from 'moment';
import { Moment } from '../../constants/moment';

type FilterDateRangeInputProps = {
  displayFilter: Filter;
  filterIndex: number;
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
};

class FilterDateRangeInput extends React.Component<FilterDateRangeInputProps> {
  /**
   * Called when datepicker values changed
   * @param {number} index
   * @param startDateChanged
   */
  handleDateRangeChange = (index: number, startDateChanged: boolean) => (d: Moment | null) => {
    const filter = this.props.displayFilters[index];
    filter.value = '';
    const newDate = d ? d.format(DATETIME_FORMAT) : '';

    if (startDateChanged) {
      filter.low = newDate;
    } else {
      filter.high = newDate;
    }

    this.props.saveDisplayFilter(filter, index);
  }

  render () {
    const { displayFilter, filterIndex } = this.props;
    return (
      <DateRange
        startDate={moment(displayFilter.low, DATETIME_FORMAT)}
        onStartDateChange={this.handleDateRangeChange(filterIndex, true)}
        endDate={moment(displayFilter.high, DATETIME_FORMAT)}
        onEndDateChange={this.handleDateRangeChange(filterIndex, false)}
        isClearable={false}
      />
    );
  }
}

export default FilterDateRangeInput;
