import React, { ComponentProps } from 'react';
import { DateInput as Input } from '@eds/date-input';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import './date-input.scss';

const TEN_YEARS_FROM_NOW = new Date().getFullYear() + 10;

type DateInputProps<TFieldValues extends FieldValues> = Omit<ComponentProps<typeof Input>, 'onChange' | 'value'> &
  UseControllerProps<TFieldValues>;

export const DateInput = <TFieldValues extends FieldValues>({
  control,
  name,
  toYear = TEN_YEARS_FROM_NOW,
  ...props
}: DateInputProps<TFieldValues>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return <Input onChange={onChange} toYear={toYear} value={value} {...props} />;
};
