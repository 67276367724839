import * as React from 'react';
import { AppState, ReportDetails } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getReportJson } from '../actions/report';
import { storeFilterOptions } from '../actions/filter';
import { storeOffset, storeReportLimitAction } from '../actions/reportOffsetLimit';
import { closeDrilldownModal } from '../actions/drilldownModal';
import { ColumnMeta } from '../types/columnMetaModel';
import { OrderBy } from '../types/orderByModel';
import { Query } from '../types/queryModel';
import { View } from '../types/viewModel';
import { DrilldownModalType } from '../types/drilldownModalModel';
import { ReportPaginationData } from '../types/reportPaginationDataModel';
import { SavedQuery } from '../types/savedQueryModel';
import { ReportTable } from '../types/reportTableModel';
import { getQuery, getQueryForApiByAppState, sort } from '../services/reportService';
import { removeColumnByColumnMeta, saveColumnMeta } from '../services/columnMetaService';
import { NotificationService } from '../services/notificationService';
import SimplePagination from '../components/SimplePagination';
import Report from '../components/Report';
import DrilldownModal from './ReportBuilder/DrilldownModal';

export type ReportSortFunction = (columnMeta: ColumnMeta, orderBys: OrderBy[], reportId?: string) => void;

export type ReorderFunction = (columnMeta: ColumnMeta[]) => void;

export interface ReportProps {
  dispatch: Dispatch<any>;
  // populated by mapStateToProps
  report: ReportTable;
  orderBys: OrderBy[];
  columnMeta: ColumnMeta[];
  query: Query;
  views: View[];
  reportDetails: ReportDetails;
  loadedQuery?: SavedQuery;
  reportTitle: string;
  readOnly: boolean;
  printView?: boolean;
  notificationService: NotificationService;
  reportOffset: number;
  reportLimit: number;
  reportPaginationData: ReportPaginationData;
  drilldownModalState: DrilldownModalType;
}

const mapStateToProps = (state: AppState) => ({
  report: state.report,
  orderBys: state.orderBys,
  columnMeta: state.columnMeta,
  query: getQuery(getQueryForApiByAppState(state)),
  views: state.views,
  reportDetails: state.reportDetails,
  reportTitle: state.reportTitle,
  notificationService: state.notificationService,
  authService: state.authService,
  reportOffset: state.offset,
  reportLimit: state.reportLimit,
  reportPaginationData: state.reportPaginationData,
  drilldownModalState: state.drilldownModal
});

class ReportContainer extends React.Component<ReportProps> {

  constructor(props: ReportProps) {
    super(props);
  }

  componentWillUnmount() {
    this.props.dispatch(closeDrilldownModal());
  }

  render() {
    return (
      <>
        <Report
          report={this.props.report}
          columnMeta={this.props.columnMeta}
          sort={this.sort}
          orderBys={this.props.orderBys}
          views={this.props.views}
          reorder={this.reorder}
          removeColumn={this.removeColumn}
          reportDetails={this.props.reportDetails}
          loadedQuery={this.props.loadedQuery}
          reportTitle={this.props.reportTitle}
          readOnly={this.props.readOnly}
          printView={this.props.printView}
          renderPagination={this.renderPagination}
          query={this.props.query}
          dispatch={this.props.dispatch}
        />
        <DrilldownModal />
      </>
    );
  }

  sort = (columnMeta: ColumnMeta, orderBys: OrderBy[]) => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    this.props.dispatch(sort(columnMeta, orderBys, reportId));
  }

  reorder = (columnMeta: ColumnMeta[]) => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    this.props.dispatch(saveColumnMeta(columnMeta));
    this.props.dispatch(getReportJson(false, reportId)); // regenerate the report when a reorder is done
  }

  removeColumn = async (columnMeta: ColumnMeta) => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    this.props.dispatch(removeColumnByColumnMeta(columnMeta));
    this.props.dispatch(storeFilterOptions());

    this.props.dispatch(getReportJson(false, reportId));
  }

  renderPagination = () => {
    return (
      <SimplePagination
        handlePageClick={this.handlePageClick}
        limit={this.props.reportLimit}
        offset={this.props.reportOffset}
        nextEnabled={this.props.reportPaginationData.next}
        prevEnabled={this.props.reportPaginationData.prev}
      />
    );
  }

  handlePageClick = (pageIndex: number, offset: number, limit: number) => {
    const reportId = this.props.loadedQuery ? this.props.loadedQuery.id : undefined;
    // store the offset and limit for pagination
    this.props.dispatch(storeOffset(offset));

    if (limit !== this.props.reportLimit) {
      this.props.dispatch(storeReportLimitAction(limit));
    }

    // generate the report
    this.props.dispatch(getReportJson(false, reportId));
  }
}

export default connect(
  mapStateToProps)(ReportContainer);
