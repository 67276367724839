import React from 'react';
import { ControlsProps } from '@eds/modal';

import { AlertModal } from 'src/features/ui';
import { Text } from '@eds/text';

type ViewsInfoProps = {
  isOpen: boolean;
  onClose: () => void;
};

const description = `A 'view' serves as your starting point for navigating through your reporting tool. Think of views
  as groups of data. Once you choose a view, you'll uncover related areas, which we refer to as 'related views' or
  'relations.'`;

export const ViewsInfo = ({ isOpen, onClose }: ViewsInfoProps) => {
  const controls: ControlsProps = {
    confirm: {
      label: 'Okay, got it',
      onClick: onClose,
      tone: 'primary',
    },
  };

  return (
    <AlertModal
      controls={controls}
      description={
        <Text hyphens="none" overflowY="auto" color="neutralSubtle" lineHeight="loose">
          {description}
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      title="Views Info"
    />
  );
};
