import * as React from 'react';

import { SavedQuery } from '../../types/savedQueryModel';
import { ReportTable } from '../../types/reportTableModel';
import { Chart } from '../../types/chartModel';
import { AppMenu } from '../../types/appMenuModel';
import { View } from '../../types/viewModel';
import { reportSharedWithMe } from '../../services/shareReportService';
import {
  FETCH_REPORT_JOBID_LONG_TIMEOUT,
  FETCHED_REPORT_JOBID,
  GENERATE_ACTION, GENERATE_REPORT_EXPORT_MESSAGE,
  GENERATE_REPORT_FINISHED,
  GENERATE_REPORT_LONG_MESSAGE,
  GENERATE_REPORT_LONG_TIMEOUT,
  GENERATE_REPORT_MESSAGE,
  GENERATE_REPORT_START,
  EVENT_REDRAW,
} from '../../constants';
import PageTitle from '../../shared-components/PageTitle';
import ReportWizard from '../../containers/ReportWizard/ReportWizard';
import Report from '../../containers/Report';
import FilterSelect from '../../containers/FilterSelect';
import SaveQuery from '../../containers/SaveQuery';
import ExportCsvButton from '../../containers/ExportCsvButton';
import ChartSelect from '../../containers/ChartSelect';
import GenerateChart from '../../containers/GenerateChart';
import ActionButtons from '../../containers/ActionButtons';
import ReportOverlay from '../ReportOverlay';
import SelectFormModal from '../../components/SelectFormsModal';

import './style.css';

interface ReportingToolPageProps {
  loadedQuery?: SavedQuery;
  encodedQuery: string | undefined;
  selectedView: View;
  suggestionsOpen: boolean;
  generateReportStatus: GENERATE_ACTION;
  reportResult: ReportTable;
  chart: Chart;
  queryId: string;
  appMenu: AppMenu;
}

interface ReportingToolPageState {
  wizardOpen: boolean;
  exportMessage: GENERATE_REPORT_MESSAGE | GENERATE_REPORT_LONG_MESSAGE | GENERATE_REPORT_EXPORT_MESSAGE;
  showExport: boolean;
  isSelectFormsModalOpened: boolean;
}
export class ReportingToolPage extends React.Component<ReportingToolPageProps, ReportingToolPageState> {
  protected filterSelectRef: any = React.createRef();
  protected reportVisualisationContainerRef = React.createRef<HTMLDivElement>();
  protected exportMessageTimeout: any;
  protected exportButtonTimeout: any;
  protected wizardOpeningDelay: number = 500;

  constructor(props: ReportingToolPageProps) {
    super(props);
    this.exportMessageTimeout = null;

    this.state = {
      wizardOpen: true,
      exportMessage: GENERATE_REPORT_MESSAGE,
      showExport: false,
      isSelectFormsModalOpened: false,
    };
  }

  componentDidUpdate(prevProps: ReportingToolPageProps, prevState: ReportingToolPageState) {
    const { generateReportStatus, appMenu } = this.props;
    const { generateReportStatus: prevGenerateReportStatus, appMenu: prevAppMenu } = prevProps;
    const { wizardOpen } = this.state;

    if ((wizardOpen !== prevState.wizardOpen) || (appMenu.isOpened !== prevAppMenu.isOpened)) {
      // used setTimeout to catch chart container width after it set, between opening - closing wizard panel
      // Depends on transition-duration scss property (0.5s)
      setTimeout(() => this.redrawChart(), this.wizardOpeningDelay);
    }

    if (prevProps.reportResult.rows.length === 0
      && (prevProps.reportResult.rows.length !== this.props.reportResult.rows.length)) {
      this.setState({
        wizardOpen: false
      });
    }

    // If Generate Report has been clicked, start the clock for 60s. After 60s has elapsed, display a different message.
    if (prevGenerateReportStatus !== generateReportStatus && generateReportStatus === GENERATE_REPORT_START) {
      this.startExportMessageTimer();
    }

    // TODO: We need better mechanism to wait until we get the jobId from the queue
    // If Job Id has been fetched, start the clock for 2s. After 2s has elapsed, display a different message.
    if (prevGenerateReportStatus !== generateReportStatus && generateReportStatus === FETCHED_REPORT_JOBID) {
      this.startExportButtonTimer();
    }

    // If Report Generation completed, then reset the message and clear the timeout.
    if (prevGenerateReportStatus !== generateReportStatus && generateReportStatus === GENERATE_REPORT_FINISHED) {
      this.stopExportMessageTimer();
      this.stopExportButtonTimer();
    }
  }

  componentWillUnmount() {
    // If the component unmounted, clear the timeout.
    this.stopExportMessageTimer();
  }

  toggleSelectFormModal = () => {
    this.setState({
      isSelectFormsModalOpened: !this.state.isSelectFormsModalOpened,
    });
  }

  startExportMessageTimer = () => {
    this.exportMessageTimeout = setTimeout(
      () => {
        this.setState({
          exportMessage: GENERATE_REPORT_LONG_MESSAGE
        });
      },
      GENERATE_REPORT_LONG_TIMEOUT);
  }

  stopExportMessageTimer = () => {
    if (this.exportMessageTimeout) {
      clearTimeout(this.exportMessageTimeout);
      this.exportMessageTimeout = null;
      this.setState({
        exportMessage: GENERATE_REPORT_MESSAGE
      });
    }
  }

  startExportButtonTimer = () => {
    this.exportButtonTimeout = setTimeout(
        () => {
          this.setState({
            exportMessage: GENERATE_REPORT_EXPORT_MESSAGE,
            showExport: true
          });
        },
        FETCH_REPORT_JOBID_LONG_TIMEOUT);
  }

  stopExportButtonTimer = () => {
    if (this.exportButtonTimeout) {
      clearTimeout(this.exportButtonTimeout);
      this.exportButtonTimeout = null;
      this.setState({
        showExport: false
      });
    }
  }

  toggleWizard = () => {
    this.setState({
      wizardOpen: !this.state.wizardOpen
    });
  }

  onResetClicked = () => {
    let componentRef = this.filterSelectRef.current;
    if (componentRef && componentRef.clearDisplayFilters) {
      componentRef.clearDisplayFilters();
    }
  }

  renderToggleWizardButton() {
    return (
      <button
        className="btn btn-outline-dark toggle-wizard-btn"
        onClick={this.toggleWizard}
        data-testid="toggle-wizard"
      >
        {this.state.wizardOpen && <i className="fa fa-chevron-left" />}
        {!this.state.wizardOpen && <i className="fa fa-chevron-right" />}
      </button>);
  }

  onGenerateSuccess = () => {
    this.setState({
      wizardOpen: false,
    });
  }

  renderReportOverlay(generateReportStatus: GENERATE_ACTION) {
    if (generateReportStatus !== GENERATE_REPORT_START && generateReportStatus !== FETCHED_REPORT_JOBID) {
      return null;
    }

    return (
      <ReportOverlay>
        {this.renderExportMessage(generateReportStatus)}
      </ReportOverlay>
    );
  }

  renderExportMessage(generateReportStatus: GENERATE_ACTION) {
    const { exportMessage, showExport } = this.state;
    let message = null;

    switch (exportMessage) {
      case GENERATE_REPORT_MESSAGE: {
        message = <h2>Report is being generated.</h2>;
        break;
      }
      case GENERATE_REPORT_LONG_MESSAGE: {
        message = (
          <>
            <h2>Your report is taking longer than expected to generate. </h2>
            <p>We would encourage you to apply a filter to limit the report generation time.</p>
          </>
        );
        break;
      }
      case GENERATE_REPORT_EXPORT_MESSAGE: {
        message = <h2>Report taking a while? Try exporting it instead.</h2>;
        break;
      }
      default: {
        message = '';
      }
    }

    if (generateReportStatus !== FETCHED_REPORT_JOBID) {
      return null;
    }
    const exportButton = <ExportCsvButton buttonColourClass="btn-primary" queryId={this.props.queryId} />;
    return (
      <>
        {message}
        {showExport ? exportButton : ''}
      </>
    );
  }

  getChartListenerElement = () => {
    return !!this.reportVisualisationContainerRef.current ? this.reportVisualisationContainerRef.current : undefined;
  }

  redrawChart = () => {
    // create and fire custom redraw event for manual chart redrawing
    if (this.reportVisualisationContainerRef.current) {
      const redrawEvent = new CustomEvent(EVENT_REDRAW, {
        bubbles: true,
        detail: {},
      });

      this.reportVisualisationContainerRef.current.dispatchEvent(redrawEvent);
    }
  }

  render() {
    const { generateReportStatus } = this.props;
    const { encodedQuery, loadedQuery } = this.props;
    const canManageFilter = !loadedQuery || (!!loadedQuery && loadedQuery.permissions &&
      loadedQuery.permissions.canManageFilter);
    const showGenerateEditChartButton = !loadedQuery || (!!loadedQuery && !reportSharedWithMe(loadedQuery) &&
      loadedQuery.permissions && loadedQuery.permissions.canManageChart);

    let wizardDisplayClass: string = this.state.wizardOpen ? '' : 'closed';
    let reportWizardWidthClass: string = this.props.selectedView.name ?
      'col-sm-12 col-md-8 col-lg-8 col-xl-5 left-col ' :
      'col-sm-12 col-md-4 col-lg-4 col-xl-3 left-col ';

    let reportWizardClass = reportWizardWidthClass + wizardDisplayClass;

    return (
      <div className="reporting-tool-page">
        <div className="row">
          <div className="col-12">
            <PageTitle title="Report Builder" />
          </div>
        </div>
        <div className="row no-gutters report-builder-content">
          <div className={reportWizardClass}>
            <ReportWizard
              onResetClicked={this.onResetClicked}
              onGenerateSuccess={this.onGenerateSuccess}
              toggleSelectFormModal={this.toggleSelectFormModal}
              reportId={this.props.queryId}
            />
          </div>
          <div className="col right-col" ref={this.reportVisualisationContainerRef}>
            {this.renderReportOverlay(generateReportStatus)}
            <div className="buttons">
              <div className="left">
                {this.renderToggleWizardButton()}
                {showGenerateEditChartButton &&
                  <GenerateChart disabled={!encodedQuery} />
                }
              </div>
              <div className="right">
                <SaveQuery loadedQuery={loadedQuery} disabled={!encodedQuery} />
                <ActionButtons
                  disabled={!encodedQuery}
                  queryId={this.props.queryId}
                  loadedQuery={loadedQuery}
                />
              </div>
            </div>

            <div className="scroll">
              <FilterSelect
                queryIsLoaded={!!loadedQuery}
                ref={this.filterSelectRef}
                canManageFilter={canManageFilter}
                loadedQuery={loadedQuery}
              />
              {this.props.chart.chartId &&
                <ChartSelect loadedQuery={loadedQuery} chartListenerElement={this.getChartListenerElement()} />
              }
              <Report
                loadedQuery={loadedQuery}
                readOnly={false}
              />
            </div>
          </div>
        </div>
        {this.state.isSelectFormsModalOpened &&
        <SelectFormModal
          toggleModal={this.toggleSelectFormModal}
          isOpened={this.state.isSelectFormsModalOpened}
        />
        }
      </div>
    );
  }
}
