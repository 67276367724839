import { FIRST_DAY_OF_MONTH, LAST_DAY_OF_MONTH } from '../../components/AddScheduleModal/DaysOfMonthSelect';
import { ScheduledQuery  } from '../../types/scheduler/scheduledQueryModel';
import { NotificationRecipient  } from '../../types/scheduler/notificationRecipient';

import {
  OCCURRENCE_FORTNIGHTLY,
  OCCURRENCE_MONTHLY, OCCURRENCE_ONCE,
  OCCURRENCE_QUARTERLY,
  OCCURRENCE_WEEKLY, OccurrenceOption
} from '../../constants/addSchedule';
import { State as AddScheduleModalState } from './index';
import { SavedQuery } from '../../types/savedQueryModel';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { UserSelectOption } from '../../types/scheduler/userSelectOption';

function hasDaysOfWeek(occurrence: OccurrenceOption) {
  return [OCCURRENCE_WEEKLY, OCCURRENCE_FORTNIGHTLY].includes(occurrence);
}

function hasDayOfMonth(occurrence: OccurrenceOption) {
  return [OCCURRENCE_MONTHLY, OCCURRENCE_QUARTERLY].includes(occurrence);
}

function hasEndDate(occurrence: OccurrenceOption) {
  return occurrence !== OCCURRENCE_ONCE;
}

export function getDaysOfWeekFromState(state: AddScheduleModalState) {
  const daysOfWeek = state.daysOfWeek;
  const selected: number[] = [];

  // only weekly and fortnightly has "days of the week"
  if (!hasDaysOfWeek(state.occurrence)) {
    return [];
  }

  // return the keys which contain the value true
  Object.keys(daysOfWeek).map((index: string) => {
    if (daysOfWeek[index]) {
      selected.push(Number(index) + 1);
    }
  });

  return selected;
}

export function getDaysOfWeekFromScheduledQuery(scheduledQuery: ScheduledQuery) {
  // only weekly and fortnightly has "days of the week"
  if (!hasDaysOfWeek(scheduledQuery.occurrence)) {
    return [];
  }

  const result: boolean[] = [];
  scheduledQuery.daysOfTheWeek.forEach((day) => {
    result[day - 1] = true;
  });

  return result;
}

export function getDayOfMonthFromScheduledQuery(scheduledQuery: ScheduledQuery) {
  if (!hasDayOfMonth(scheduledQuery.occurrence)) {
    return '';
  } else if (scheduledQuery.lastDayOfTheMonth) {
    return LAST_DAY_OF_MONTH;
  }

  const calendarDay = scheduledQuery.calendarDays[0];
  return calendarDay === 1 ? FIRST_DAY_OF_MONTH : calendarDay.toString();
}

function getEndDateFromScheduledQuery(scheduledQuery: ScheduledQuery) {
  if (!hasEndDate(scheduledQuery.occurrence) || !scheduledQuery.endDate) {
    return null;
  }

  const endDate = moment(scheduledQuery.endDate);
  return endDate ? endDate : null;
}

export function getDayOfMonthFromState(state: AddScheduleModalState) {
  const dayOfMonth = state.dayOfMonth;

  if (!hasDayOfMonth(state.occurrence)) {
    return {
      calendarDays: [],
      lastDayOfTheMonth: false,
    };
  }

  if (dayOfMonth === LAST_DAY_OF_MONTH) {
    return {
      calendarDays: [],
      lastDayOfTheMonth: true,
    };
  } else if (dayOfMonth === FIRST_DAY_OF_MONTH) {
    return {
      calendarDays: [1],
      lastDayOfTheMonth: false
    };
  }

  const calendarDays = dayOfMonth ? [ Number(dayOfMonth) ] : [];

  return {
    calendarDays: calendarDays,
    lastDayOfTheMonth: false
  };
}

function getEndDateFromState(state: AddScheduleModalState) {
  const { endDate } = state;

  if (!hasEndDate(state.occurrence)) {
    return null;
  }

  return endDate ? endDate.format('YYYY-MM-DD') : null;
}

export function getScheduleDataFromState(state: AddScheduleModalState,
                                         loadedQuery: SavedQuery,
                                         scheduledQuery?: ScheduledQuery) {
  const {
    scheduleName,
    startDate,
    occurrence,
    time,
  } = state;

  return {
    scheduleId: scheduledQuery ? scheduledQuery.id : uuidv4(),
    name: scheduleName,
    queryId: scheduledQuery ? scheduledQuery.query.id : loadedQuery.id,
    occurrence: occurrence,
    startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
    endDate: getEndDateFromState(state),
    time: time ? time.seconds(0).format('HH:mm:ss') : null,
    daysOfTheWeek: getDaysOfWeekFromState(state),
    ...getDayOfMonthFromState(state),
    ...getDispatchOptionsFromState(state)
  };
}

/**
 * Get dispatch options for the schedule (outbound or to users)
 * @param state
 */
function getDispatchOptionsFromState(state: AddScheduleModalState) {
  const {
    outboundDestinationId,
    isOutbound,
    users,
    notificationRecipients,
    recipientEmail,
    isOutboundEmailNotification
  } = state;
  if (isOutbound) {
    let recipients: object[] | null = null;
    if (isOutboundEmailNotification && recipientEmail) {
      recipients = [{ recipientType: 'email', email: recipientEmail}];
    } else if (!isOutboundEmailNotification && notificationRecipients.length > 0) {
       recipients =  getNotificationRecipientsFromNotificationUserIds(
         getUserIdsFromUserSelectOptions(notificationRecipients) as string[]);
    }
    return {
      ...recipients ? { notificationRecipients: recipients } : {},
      ...outboundDestinationId ? { outboundDestinationId: outboundDestinationId } : {}
    };
  }

  return {
    targetUserIds: getUserIdsFromUserSelectOptions(users)
  };
}

export function getNotificationRecipientsFromNotificationUserIds(notificationUserIds: (string)[]) {
  return notificationUserIds.map((user: string) => {
    return { recipientType: 'user', uuid: user };
  });
}

export function getUserIdsFromUserSelectOptions(users: UserSelectOption[]) {
  return users.map((user: UserSelectOption) => {
    return user.data ? user.data.id : null;
  });
}

export function getStateFromScheduledQuery(scheduledQuery: ScheduledQuery ) {
  if (!scheduledQuery) {
    return null;
  }

  const daysOfWeek: boolean[] = getDaysOfWeekFromScheduledQuery(scheduledQuery);
  const dayOfMonth: string = getDayOfMonthFromScheduledQuery(scheduledQuery);
  const endDate = getEndDateFromScheduledQuery(scheduledQuery);

  const isOutbound = !!scheduledQuery.outboundDestinationId;

  const outboundDestinationId = isOutbound && scheduledQuery.outboundDestinationId ?
    scheduledQuery.outboundDestinationId : null;

  return {
    scheduleName: scheduledQuery.scheduleName,
    startDate: moment(scheduledQuery.startDate),
    endDate: endDate,
    time: moment(scheduledQuery.startDate),
    timezone: scheduledQuery.timezone,
    occurrence: scheduledQuery.occurrence,
    daysOfWeek: daysOfWeek,
    dayOfMonth: dayOfMonth,
    isSaving: false,
    isOutbound: isOutbound,
    users: [],
    outboundDestinationId: outboundDestinationId,
    isOutboundEmailNotification: false,
    notificationRecipients: [],
    recipientEmail: ''
  };
}

export function getUserStateFromScheduledQuery(scheduledQuery: ScheduledQuery, userList: UserSelectOption[]) {
  const isOutbound = !!scheduledQuery.outboundDestinationId;

  if (!isOutbound && scheduledQuery.targetUserIds) {
    let users: UserSelectOption[] = [];
    scheduledQuery.targetUserIds.forEach((userId: string) => {
      const foundUser = userList.find((user: UserSelectOption) => {
        return user.data.id === userId;
      });

      if (foundUser) {
        users.push(foundUser);
      }
    });

    return users;
  }

  return [];
}

export function getRecipientUserStateFromScheduledQuery(scheduledQuery: ScheduledQuery, userList: UserSelectOption[]) {
  if (scheduledQuery.notificationRecipients && scheduledQuery.notificationRecipients.length > 0) {
    let notificationRecipient: UserSelectOption[] = [];
    scheduledQuery.notificationRecipients.forEach((recipient: NotificationRecipient) => {
      if (recipient.recipientType === 'user') {
        const foundUser = userList.find((user: UserSelectOption) => {
          return user.data.id === recipient.uuid;
        });

        if (foundUser) {
          notificationRecipient.push(foundUser);
        }
      }
    });

    return notificationRecipient;
  }

  return [];
}

export function getRecipientEmailStateFromScheduledQuery(scheduledQuery: ScheduledQuery) {
  let recipientEmail: string | null | undefined = '';
  if (scheduledQuery.notificationRecipients && scheduledQuery.notificationRecipients.length > 0) {
    scheduledQuery.notificationRecipients.every((recipient: NotificationRecipient) => {
      if (recipient.recipientType === 'email') {
        recipientEmail = recipient.email;
        return false;
      }
      return true;
    });
  }

  return recipientEmail;
}
