import React, { ComponentProps } from 'react';
import { Checkbox as EDSCheckbox } from '@eds/checkbox';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type CheckboxProps<TFieldValues extends FieldValues> = Omit<
  ComponentProps<typeof EDSCheckbox>,
  'checked' | 'onChange'
> &
  UseControllerProps<TFieldValues>;

export const Checkbox = <TFieldValues extends FieldValues>({
  children,
  control,
  name,
  ...props
}: CheckboxProps<TFieldValues>) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <EDSCheckbox checked={!!value} name={name} onChange={onChange} {...props}>
      {children}
    </EDSCheckbox>
  );
};
