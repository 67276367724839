import React from 'react';
import { Action, ActionsDropdown } from '@eds/actions';
import { AddUserIcon, CautionIcon, ClockIcon, DeleteIcon, DownloadIcon, EditIcon, ViewIcon } from '@eds/icon';
import { Avatar } from '@eds/avatar';
import { Box } from '@eds/box';
import { Button } from '@eds/button';
import { ColumnSort, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@eds/table';
import { EmptyFolderSpot } from '@eds/illustration';
import { EmptyState } from '@eds/empty-state';
import { Flex } from '@eds/flex';
import { Link } from '@eds/link';
import { Text } from '@eds/text';
import { css } from '@emotion/css';
import { get } from 'lodash';

import { cssOverrides } from 'src/features/ui';
import { getUserFullName, getUserProfileImage, isElmoSuperAdmin } from 'src/features/utils';

import { ReportData } from './reports-list.queries';
import { TableColumn, tableColumns } from './reports-list.hooks';

type ReportsListTableProps = {
  onDelete: (id: string) => void;
  onExport: (id: string) => void;
  onSchedule: (id: string, name: string) => void;
  onShare: (id: string, name: string, sharedWith?: string[]) => void;
  onSort: (sort: ColumnSort) => void;
  reports: ReportData[];
  showLegacyReportInfo: () => void;
  sortData: ColumnSort;
  userId: number;
};

const columnSettings = {
  actions: { width: '3.5rem' },
  legacyStatus: { width: '3.5rem' },
  modified: { width: '8rem' },
  module: { width: '15%' },
  owner: { width: '15%' },
};

const composeTableCell = (
  { columnName, format, key }: TableColumn,
  report: ReportData,
  isCurrentUser: boolean,
  showLegacyReportInfo: () => void
) => {
  const isLegacyReport = report.moduleType === 'tms';

  if (columnName === 'owner') {
    return report.reportType === 2 ? (
      <Flex alignItems="center" gap="xsmall">
        <Avatar name="ELMO" size="small" />
        <Text fontSize="small">ELMO</Text>
      </Flex>
    ) : (
      <Flex alignItems="center" gap="xsmall">
        <Avatar
          size="small"
          name={isCurrentUser && isElmoSuperAdmin() ? getUserFullName() : report.creator?.name}
          src={isCurrentUser && isElmoSuperAdmin() ? getUserProfileImage() : report.creator?.profilePicture || ''}
        />
        <Text fontSize="small">{isCurrentUser ? 'Me' : report.creator?.name}</Text>
      </Flex>
    );
  }

  if (columnName === 'legacyStatus') {
    return (
      isLegacyReport && (
        <Box className={css(cssOverrides.button('ghost'))}>
          <Button
            iconOnly
            icon={CautionIcon}
            label="Open legacy report info dialog"
            onClick={showLegacyReportInfo}
            size="small"
            tone="ghost"
          />
        </Box>
      )
    );
  }

  if (columnName !== 'report') {
    const value: string = get(report, key) || '';
    return format ? format(value) : value;
  }

  if (!report.permissions.canEdit && !report.permissions.canView) {
    return <Text fontSize="small">{report.name}</Text>;
  }

  return (
    <Text fontSize="small">
      <Link
        data-tracking={report.permissions.canEdit ? 'edit-report-link' : 'view-report-link'}
        href={`${report.permissions.canEdit ? '/report/' : '/view/'}${report.id}`}
        label={report.name}
        standalone
      />
    </Text>
  );
};

export const ReportsListTable = ({
  onDelete,
  onExport,
  onSchedule,
  onShare,
  onSort,
  reports,
  showLegacyReportInfo,
  sortData,
  userId,
}: ReportsListTableProps) => {
  const getActions = ({ id, name, permissions, sharedWith }: ReportData) => {
    const actions: Action[] = [];

    if (permissions.canView)
      actions.push({
        label: 'View',
        onClick: () => window.location.assign(`/view/${id}`),
        icon: ViewIcon,
      });

    if (permissions.canEdit)
      actions.push({
        label: 'Edit',
        onClick: () => window.location.assign(`/report/${id}`),
        icon: EditIcon,
      });

    if (permissions.canExport)
      actions.push({
        label: 'Export',
        onClick: () => onExport(id),
        icon: DownloadIcon,
      });

    if (permissions.canShare)
      actions.push({
        label: 'Share',
        onClick: () => onShare(id, name, sharedWith),
        icon: AddUserIcon,
      });

    if (permissions.canSchedule)
      actions.push({
        label: 'Schedule',
        onClick: () => onSchedule(id, name),
        icon: ClockIcon,
      });

    if (permissions.canDelete)
      actions.push({
        label: 'Delete',
        onClick: () => onDelete(id),
        icon: DeleteIcon,
        tone: 'critical',
      });

    return actions;
  };

  return (
    <Box className={css({ ...cssOverrides.theadButton(), ...cssOverrides.tableScrollWrapButton() })}>
      <Table columnSettings={columnSettings} minWidth="50rem" tableBorder truncate width="100%">
        <TableHead>
          <TableRow>
            {tableColumns.map(({ columnName, label }) => (
              <TableHeaderCell
                key={columnName}
                columnName={columnName}
                onSortChange={['legacyStatus', 'owner'].includes(columnName) ? undefined : onSort}
                sort={['legacyStatus', 'owner'].includes(columnName) ? undefined : sortData}
              >
                {columnName !== 'legacyStatus' && label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell columnName="actions">
              <></>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.length > 0 ? (
            (reports as Omit<ReportData, 'sharedWith'>[]).map(item => (
              <TableRow key={item.id}>
                {tableColumns.map(tableColumn => (
                  <TableCell key={`${item.id}-${tableColumn.columnName}`} columnName={tableColumn.columnName}>
                    {composeTableCell(tableColumn, item, userId === item.userId, showLegacyReportInfo)}
                  </TableCell>
                ))}
                <TableCell columnName="actions">
                  <Box
                    className={css(cssOverrides.button('ghost'))}
                    data-tracking="reports-list-actions"
                    minHeight="2rem"
                  >
                    <ActionsDropdown actions={getActions(item)} size="small" tone="ghost" />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Box marginTop="large" marginBottom="large">
                  <EmptyState
                    description="Try a different filter or search value."
                    spotIllustration={EmptyFolderSpot}
                    title="No results found"
                  />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
