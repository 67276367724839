import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Highcharts from 'highcharts';
import borderRadius from 'highcharts-border-radius';
import HighchartsGroupedCategories from 'highcharts-grouped-categories';
import { getPageWithoutHeader, getPathname, getPrintPage } from './services/routingService';
import { connect } from 'react-redux';
import { AppState } from './redux';
import { User } from './types/userModel';
import { pendoInitialization } from './services/pendoService';
import { AuthService } from './services/authService';

import { AppLayout, AppRouter } from 'src/features/legacy-rewrite';
import { AppProvider } from 'src/features/common';
import { GenericToastNotification } from 'src/features/api-feedback';

type Props = {
  routes: any;
  authService: AuthService;
};

const mapStateToProps = (state: AppState) => ({
  routes: state.routes,
  authService: state.authService,
});

// run wrapper to set separate border radius for Bars and Columns
borderRadius(Highcharts);
HighchartsGroupedCategories(Highcharts);
class App extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    document.title = 'Analytics - ELMO Cloud HR & Payroll';
  }

  render() {
    const { routes } = this.props;
    const pathname = getPathname();
    const pageWithoutHeader = getPageWithoutHeader(pathname, routes);
    const userData: User | null = this.props.authService.getUserData();

    if (pageWithoutHeader) {
      return pageWithoutHeader;
    }

    if (pathname.includes('print/')) {
      return getPrintPage();
    }

    pendoInitialization(userData);
    return (
      <Router>
        <AppProvider>
          <GenericToastNotification />
          <AppLayout>
            <AppRouter />
          </AppLayout>
        </AppProvider>
      </Router>
    );
  }
}

export default connect(
  mapStateToProps)(App);
