import { ColumnMeta } from './columnMetaModel';
import { ReportTable } from './reportTableModel';
import { View } from './viewModel';
import { Query } from './queryModel';

export type DrilldownModalType = {
  showDrilldownModal: boolean;
  currentDrilldownRow: number;
  currentDrilldownCol: number;
  currentDrilldownValue: number;
  columnMeta: ColumnMeta[];
  views: View[];
  report: ReportTable;
  query: Query;
  reportTitle: string;
};

export const initialDrilldownModalState: DrilldownModalType = {
  showDrilldownModal: false,
  currentDrilldownRow: -1,
  currentDrilldownCol: -1,
  currentDrilldownValue: -1,
  columnMeta: [],
  report: new ReportTable(),
  views: [],
  query: new Query(),
  reportTitle: '',
};
