import * as React from 'react';
import { Select } from 'elmo-elements';

import { AuthService } from '../../../services/authService';
import { Dashboard } from '../../../types/dashboardModel';

type StatusSelectProps = {
  dashboard: Dashboard;
  saveStatus: (status: number) => void;
};

const STATUS_NAMES = ['INCORRECT', 'DRAFT', 'PUBLISHED'];
const STATUS_SELECT_OPTIONS = [
  {value: '1', label: 'DRAFT'},
  {value: '2', label: 'PUBLISHED'}
];
const DASHBOARD_TYPE = {
  MY: 1,
  SUGGESTED: 2,
};

const StatusSelect = (props: StatusSelectProps) => {
  const statusName = STATUS_NAMES[props.dashboard.status] || STATUS_NAMES[0];
  const isSuggested = props.dashboard.type === DASHBOARD_TYPE.SUGGESTED;

  return AuthService.getInstance().isCrtSuperAdmin() && isSuggested ?
    (
      <div className="status-container">
        <span>Status: <span className="status-name">{statusName}</span></span>
        <Select
          className="status-select"
          placeholder="ELMO Admin Actions"
          options={STATUS_SELECT_OPTIONS}
          onChange={(e) => {
            props.saveStatus(parseInt(e.value, 10));
          }}
        />
      </div>
    ) : null;
};

export default StatusSelect;
