import * as React from 'react';
import './style.css';

interface AlertProps {
  type: string;
}

class Alert extends React.Component<AlertProps> {
  constructor(props: AlertProps) {
    super(props);
  }

  render() {
    return (
      <div
        className={'alert-box alert alert-' + this.props.type}
        role="alert"
      >
        {this.props.children}
      </div>
    );
  }
}

export default Alert;
