import { backgroundColor, color } from '@eds/core';

export const theadButton = () => {
  return {
    thead: {
      button: {
        color: color.neutralBold,
        borderRadius: '0',
      },
      'button:hover': {
        backgroundColor: 'transparent',
      },
      'button:active': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      'button:focus': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        outline: 'none',
      },
      'button:focus-visible': {
        outline: 'var(--eds-focus-ring-default)',
      },
    },
  };
};

export const tableScrollWrapButton = () => {
  return {
    'div + button': {
      color: color.neutralBold,
    },
    'div + button:hover': {
      backgroundColor: backgroundColor.neutral,
    },
    'div + button:active': {
      backgroundColor: backgroundColor.neutral,
    },
    'div + button:focus': {
      backgroundColor: backgroundColor.neutral,
      outline: 'none',
    },
  };
};
