import * as React from 'react';
import { Filter, FILTER_DATE_PERIODS } from '../../types/filterModel';

type FilterTextInputProps = {
  displayFilter: Filter;
  filterIndex: number;
  displayFilters: Filter[];
  saveDisplayFilter: (filter: Filter, index: number, store?: boolean) => void;
};

class FilterDatePeriodInput extends React.Component<FilterTextInputProps> {

  render() {
    const { displayFilter } = this.props;
    return (
      <select
        name="filter-date-period-select"
        onChange={this.selectDatePeriodFilter(this.props.filterIndex)}
        className="form-control"
        value={displayFilter.value as string | number}
        data-testid="filter-date-period-select"
      >
        {this.renderOptions()}
      </select>
    );
  }

  renderOptions() {
    return FILTER_DATE_PERIODS.map(function(value: string, index: number) {
      return (
        <option value={value} key={index}>{value}</option>
      );
    });
  }

  selectDatePeriodFilter = (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filter = this.props.displayFilters[index];
    filter.value = event.currentTarget.value;
    this.props.saveDisplayFilter(filter, index);
  }
}

export default FilterDatePeriodInput;
