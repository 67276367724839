// Module Actions
export const STORE_MODULE = 'STORE_MODULE';
export type STORE_MODULE = typeof STORE_MODULE;
export const STORE_QUERY = 'STORE_QUERY';
export type STORE_QUERY = typeof STORE_QUERY;
export const STORE_MODULES = 'STORE_MODULES';
export type STORE_MODULES = typeof STORE_MODULES;

// Module types
export const MODULE_TYPES = {
    tms: 'tms',
    redshift: 'redshift',
};

// View Actions
export const STORE_VIEW = 'STORE_VIEW';
export type STORE_VIEW = typeof STORE_VIEW;
export const FETCHED_VIEWS = 'FETCHED_VIEWS';
export type FETCHED_VIEWS = typeof FETCHED_VIEWS;

// Report Actions
export const GET_REPORT = 'GET_REPORT';
export type GET_REPORT = typeof GET_REPORT;
export const FETCHED_REPORT = 'FETCHED_REPORT';
export type FETCHED_REPORT = typeof FETCHED_REPORT;
export const RESET_REPORT_JOBID = 'RESET_REPORT_JOBID';
export type RESET_REPORT_JOBID = typeof RESET_REPORT_JOBID;
export const FETCHED_REPORT_JOBID = 'FETCHED_REPORT_JOBID';
export type FETCHED_REPORT_JOBID = typeof FETCHED_REPORT_JOBID;
export const FETCHED_REPORT_DRILLDOWN = 'FETCHED_REPORT_DRILLDOWN';
export type FETCHED_REPORT_DRILLDOWN = typeof FETCHED_REPORT_DRILLDOWN;
export const CLEAR_REPORT_DRILLDOWN = 'CLEAR_REPORT_DRILLDOWN';
export type CLEAR_REPORT_DRILLDOWN = typeof CLEAR_REPORT_DRILLDOWN;
export const FETCHED_REPORT_DETAILS = 'FETCHED_REPORT_DETAILS';
export type FETCHED_REPORT_DETAILS = typeof FETCHED_REPORT_DETAILS;
export const GENERATE_REPORT_START = 'GENERATE_REPORT_START';
export type GENERATE_REPORT_START = typeof GENERATE_REPORT_START;
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export type GENERATE_REPORT_SUCCESS = typeof GENERATE_REPORT_SUCCESS;
export const GENERATE_REPORT_CANCELED = 'GENERATE_REPORT_CANCELED';
export type GENERATE_REPORT_CANCELED = typeof GENERATE_REPORT_CANCELED;
export const GENERATE_REPORT_FINISHED = 'GENERATE_REPORT_FINISHED';
export type GENERATE_REPORT_FINISHED = typeof GENERATE_REPORT_FINISHED;
export type GENERATE_ACTION =
    GENERATE_REPORT_START | FETCHED_REPORT_JOBID | GENERATE_REPORT_SUCCESS | GENERATE_REPORT_CANCELED | GENERATE_REPORT_FINISHED;
export const CANCEL = 'CANCEL';
export type CANCEL = typeof CANCEL;
export const STORE_CANCEL_SOURCE = 'STORE_CANCEL_SOURCE';
export type STORE_CANCEL_SOURCE = typeof STORE_CANCEL_SOURCE;
export const FETCHED_REPORT_PAGINATION_DATA = 'FETCHED_REPORT_PAGINATION_DATA';
export type FETCHED_REPORT_PAGINATION_DATA = typeof FETCHED_REPORT_PAGINATION_DATA;
export const GENERATE_REPORT_LONG_TIMEOUT = 60000; // 1 minute
export const GENERATE_REPORT_MESSAGE = 1;
export type GENERATE_REPORT_MESSAGE = typeof GENERATE_REPORT_MESSAGE;
export const GENERATE_REPORT_LONG_MESSAGE = 2;
export type GENERATE_REPORT_LONG_MESSAGE = typeof GENERATE_REPORT_LONG_MESSAGE;
export const GENERATE_REPORT_EXPORT_MESSAGE = 3;
export type GENERATE_REPORT_EXPORT_MESSAGE = typeof GENERATE_REPORT_EXPORT_MESSAGE;
export const FETCH_REPORT_JOBID_LONG_TIMEOUT = 2000; // 5 seconds

// Report Labels
export const OPEN_REPORT_VIEW = 'view';
export const OPEN_REPORT_EDIT = 'edit';

// Dimension Actions
export const ADD_DIMENSIONS = 'ADD_DIMENSIONS';
export type ADD_DIMENSIONS = typeof ADD_DIMENSIONS;
export const REMOVE_DIMENSIONS = 'REMOVE_DIMENSIONS';
export type REMOVE_DIMENSIONS = typeof REMOVE_DIMENSIONS;
export const ADD_JOIN_DIMENSIONS = 'ADD_JOIN_DIMENSIONS';
export type ADD_JOIN_DIMENSIONS = typeof ADD_JOIN_DIMENSIONS;
export const REMOVE_JOIN_DIMENSIONS = 'REMOVE_JOIN_DIMENSIONS';
export type REMOVE_JOIN_DIMENSIONS = typeof REMOVE_JOIN_DIMENSIONS;

// Measure Actions
export const ADD_MEASURES = 'ADD_MEASURES';
export type ADD_MEASURES = typeof ADD_MEASURES;
export const REMOVE_MEASURES = 'REMOVE_MEASURES';
export type REMOVE_MEASURES = typeof REMOVE_MEASURES;
export const ADD_JOIN_MEASURES = 'ADD_JOIN_MEASURES';
export type ADD_JOIN_MEASURES = typeof ADD_JOIN_MEASURES;
export const REMOVE_JOIN_MEASURES = 'REMOVE_JOIN_MEASURES';
export type REMOVE_JOIN_MEASURES = typeof REMOVE_JOIN_MEASURES;

export const SOURCE_REPORT_HEADER = 'SOURCE_REPORT_HEADER';
export type SOURCE_REPORT_HEADER = typeof SOURCE_REPORT_HEADER;
export const SOURCE_WIZARD = 'SOURCE_WIZARD';
export type SOURCE_WIZARD = typeof SOURCE_WIZARD;

// Data types
export const DATA_TYPE_DIMENSIONS = 'Individual';
export type DATA_TYPE_DIMENSIONS = typeof DATA_TYPE_DIMENSIONS;
export const DATA_TYPE_MEASURES = 'Aggregate';
export type DATA_TYPE_MEASURES = typeof DATA_TYPE_MEASURES;
export const STORE_DATA_TYPE = 'STORE_DATA_TYPE';
export type STORE_DATA_TYPE = typeof STORE_DATA_TYPE;

// Group By Actions
export const ADD_GROUP_BY = 'ADD_GROUP_BY';
export type ADD_GROUP_BY = typeof ADD_GROUP_BY;
export const REMOVE_GROUP_BY = 'REMOVE_GROUP_BY';
export type REMOVE_GROUP_BY = typeof REMOVE_GROUP_BY;

// Measure types
export const MEASURE_TYPE_SUM = 'sum';
export type MEASURE_TYPE_SUM = typeof MEASURE_TYPE_SUM;
export const MEASURE_TYPE_COUNT = 'count';
export type MEASURE_TYPE_COUNT = typeof MEASURE_TYPE_COUNT;
export const MEASURE_TYPE_AVERAGE = 'avg';
export type MEASURE_TYPE_AVERAGE = typeof MEASURE_TYPE_AVERAGE;
export const MEASURE_TYPE_CUSTOM = 'custom';
export type MEASURE_TYPE_CUSTOM = typeof MEASURE_TYPE_CUSTOM;
export type MEASURE_TYPE = MEASURE_TYPE_AVERAGE | MEASURE_TYPE_COUNT | MEASURE_TYPE_SUM | MEASURE_TYPE_CUSTOM;
export const MEASURE_TYPE_MAX = 'max';
export const MEASURE_TYPE_MIN = 'min';

// Dimension types
export const DIMENSION_TYPE_NUMBER = 'number';
export type DIMENSION_TYPE_NUMBER = typeof DIMENSION_TYPE_NUMBER;
export const DIMENSION_TYPE_STRING = 'string';
export type DIMENSION_TYPE_STRING = typeof DIMENSION_TYPE_STRING;
export const DIMENSION_TYPE_DATETIME = 'datetime';
export type DIMENSION_TYPE_DATETIME = typeof DIMENSION_TYPE_DATETIME;
export const DIMENSION_TYPE_DATEINTERVAL = 'dateInterval';
export type DIMENSION_TYPE_DATEINTERVAL = typeof DIMENSION_TYPE_DATEINTERVAL;
export const DIMENSION_TYPE_DAY_NUMBER = 'dayNumber';
export type DIMENSION_TYPE_DAY_NUMBER = typeof DIMENSION_TYPE_DAY_NUMBER;
export const DIMENSION_TYPE_MONTH_NUMBER = 'monthNumber';
export type DIMENSION_TYPE_MONTH_NUMBER = typeof DIMENSION_TYPE_MONTH_NUMBER;
export const DIMENSION_TYPE_BOOLEAN = 'boolean';
export type DIMENSION_TYPE_BOOLEAN = typeof DIMENSION_TYPE_BOOLEAN;
export type DIMENSION_TYPE = DIMENSION_TYPE_NUMBER | DIMENSION_TYPE_STRING | DIMENSION_TYPE_DATETIME
                                | DIMENSION_TYPE_DATEINTERVAL | DIMENSION_TYPE_BOOLEAN;
// Filter actions
export const STORE_FILTER = 'STORE_FILTER';
export type STORE_FILTER = typeof STORE_FILTER;
export const STORE_FILTER_OPTIONS = 'STORE_FILTER_OPTIONS';
export type STORE_FILTER_OPTIONS = typeof STORE_FILTER_OPTIONS;
export const REPLACE_FILTERS = 'REPLACE_FILTERS';
export type REPLACE_FILTERS = typeof REPLACE_FILTERS;

// FilterGroup actions
export const REPLACE_FILTER_GROUPS = 'REPLACE_FILTER_GROUPS';
export type REPLACE_FILTER_GROUPS = typeof REPLACE_FILTER_GROUPS;

// Order actions
export const STORE_ORDER_BY = 'STORE_ORDER_BY';
export type STORE_ORDER_BY = typeof STORE_ORDER_BY;
export const ORDER_DESC = 'DESC';
export const ORDER_ASC = 'ASC';

// Column Meta actions
export const STORE_COLUMN_META = 'STORE_COLUMN_META';
export type STORE_COLUMN_META = typeof STORE_COLUMN_META;

// Misc
export const DATETIME_FORMAT = 'DD/MM/YYYY'; // Note this should match the date in DateTransformer in backend
export const DISPLAY_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

// Saved queries actions
export const SAVE_QUERY_START = 'SAVE_QUERY_START';
export type SAVE_QUERY_START = typeof SAVE_QUERY_START;
export const SAVE_QUERY_SUCCESS = 'SAVE_QUERY_SUCCESS';
export type SAVE_QUERY_SUCCESS = typeof SAVE_QUERY_SUCCESS;
export const SAVE_QUERY_ERROR = 'SAVE_QUERY_ERROR';
export type SAVE_QUERY_ERROR = typeof SAVE_QUERY_ERROR;
export const LOAD_QUERY_START = 'LOAD_QUERY_START';
export type LOAD_QUERY_START = typeof LOAD_QUERY_START;
export const LOAD_QUERY_SUCCESS = 'LOAD_QUERY_SUCCESS';
export type LOAD_QUERY_SUCCESS = typeof LOAD_QUERY_SUCCESS;
export const LOAD_QUERY_ERROR = 'LOAD_QUERY_ERROR';
export type LOAD_QUERY_ERROR = typeof LOAD_QUERY_ERROR;
export const HYDRATE_STORE = 'HYDRATE_STORE';
export type HYDRATE_STORE = typeof HYDRATE_STORE;
export const STORE_REPORT_TITLE = 'STORE_REPORT_TITLE';
export type STORE_REPORT_TITLE = typeof STORE_REPORT_TITLE;
export const STORE_TEMP_QUERY = 'STORE_TEMP_QUERY';
export type STORE_TEMP_QUERY = typeof STORE_TEMP_QUERY;
export const STORE_SAVED_QUERY = 'STORE_SAVED_QUERY';
export type STORE_SAVED_QUERY = typeof STORE_SAVED_QUERY;

// Report Builder actions
export const RESET_REPORT_BUILDER = 'RESET_REPORT_BUILDER';
export type RESET_REPORT_BUILDER = typeof RESET_REPORT_BUILDER;
export const RESET_REPORT_BUILDER_COLUMNS = 'RESET_REPORT_BUILDER_COLUMNS';
export type RESET_REPORT_BUILDER_COLUMNS = typeof RESET_REPORT_BUILDER_COLUMNS;
export const GENERATE_REPORT_POLL_DELAY = 3000;
export const GENERATE_WIDGET_POLL_DELAY = 10000;
export const STORE_SELECTED_COUNTRY = 'STORE_SELECTED_COUNTRY';
export type STORE_SELECTED_COUNTRY = typeof STORE_SELECTED_COUNTRY;

// Query related
export const NUM_PAGES = 100;
export const STORE_OFFSET = 'STORE_OFFSET';
export type STORE_OFFSET = typeof STORE_OFFSET;
export const STORE_REPORT_LIMIT = 'STORE_REPORT_LIMIT';
export type STORE_REPORT_LIMIT = typeof STORE_REPORT_LIMIT;
export const DEFAULT_REPORT_LIMIT = 100;
export const DRILLDOWN_DEFAULT_LIMIT = 100;

export const MAX_DEPTH_RELATED_JOINS = 10; // Maximum depth of related joins

// System notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export type ADD_NOTIFICATION = typeof ADD_NOTIFICATION;
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export type REMOVE_NOTIFICATION = typeof REMOVE_NOTIFICATION;

export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export type NOTIFICATION_SUCCESS = typeof NOTIFICATION_SUCCESS;
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export type NOTIFICATION_ERROR = typeof NOTIFICATION_ERROR;
export const NOTIFICATION_INFO = 'NOTIFICATION_INFO';
export type NOTIFICATION_INFO = typeof NOTIFICATION_INFO;
export const SET_NOTIFICATION_SERVICE = 'SET_NOTIFICATION_SERVICE';
export type SET_NOTIFICATION_SERVICE = typeof SET_NOTIFICATION_SERVICE;

export const SET_AUTH_SERVICE = 'SET_AUTH_SERVICE';
export type SET_AUTH_SERVICE = typeof SET_AUTH_SERVICE;
export const SET_USER = 'SET_USER';
export type SET_USER = typeof SET_USER;

// Download actions
export const FETCHED_DOWNLOADS = 'FETCHED_DOWNLOADS';
export type FETCHED_DOWNLOADS = typeof FETCHED_DOWNLOADS;
export const UPDATE_DOWNLOAD = 'UPDATE_DOWNLOAD';
export type UPDATE_DOWNLOAD = typeof UPDATE_DOWNLOAD;
export const JOB_STATUS_WAITING = 'STATUS_WAITING';
export type JOB_STATUS_WAITING = typeof JOB_STATUS_WAITING;
export const JOB_STATUS_RUNNING = 'STATUS_RUNNING';
export type JOB_STATUS_RUNNING = typeof JOB_STATUS_RUNNING;
export const JOB_STATUS_FAILED = 'STATUS_FAILED';
export type JOB_STATUS_FAILED = typeof JOB_STATUS_FAILED;
export const JOB_STATUS_COMPLETE = 'STATUS_COMPLETE';
export type JOB_STATUS_COMPLETE = typeof JOB_STATUS_COMPLETE;
export type JOB_STATUS = JOB_STATUS_WAITING | JOB_STATUS_RUNNING | JOB_STATUS_FAILED | JOB_STATUS_COMPLETE;

// Analytics general constants
export const CRT_VERSION_KEY = 'v1';
export const PPA_VERSION_KEY = 'v1';

// Analytics labels
export const ANALYTICS_EDIT_REPORT_PRESSED = 'EDIT_REPORT_PRESSED';
export const ANALYTICS_VIEW_REPORT_PRESSED = 'VIEW_REPORT_PRESSED';

// Analytics mappings
export const DASHBOARD_TYPES_MAPPING = {
    1: 'My Dashboard',
    2: 'Suggested Dashboard',
};

// Charts
export const CHART_TYPE_COLUMN = 'column';
export type CHART_TYPE_COLUMN = typeof CHART_TYPE_COLUMN;
export const CHART_TYPE_PIE = 'pie';
export type CHART_TYPE_PIE = typeof CHART_TYPE_PIE;
export const CHART_TYPE_BAR = 'bar';
export type CHART_TYPE_BAR = typeof CHART_TYPE_BAR;
export const CHART_TYPE_LINE = 'line';
export type CHART_TYPE_LINE = typeof CHART_TYPE_LINE;
export const CHART_TYPE_TEXT = 'text'; // Can be a Text widget
export type CHART_TYPE_TEXT = typeof CHART_TYPE_TEXT;
export type CHART_TYPE = CHART_TYPE_COLUMN | CHART_TYPE_PIE | CHART_TYPE_BAR | CHART_TYPE_LINE | CHART_TYPE_TEXT;
export const SAVE_CHART = 'SAVE_CHART';
export type SAVE_CHART = typeof SAVE_CHART;
export const FETCHED_CHART = 'FETCHED_CHART';
export type FETCHED_CHART = typeof FETCHED_CHART;
export const SHOULD_GENERATE_CHART = 'SHOULD_GENERATE_CHART';
export type SHOULD_GENERATE_CHART = typeof SHOULD_GENERATE_CHART;

// Report Types
export const REPORT_TYPE_USER = 1;
export type REPORT_TYPE_USER = typeof REPORT_TYPE_USER;
export const REPORT_TYPE_SUGGESTION = 2;
export type REPORT_TYPE_SUGGESTION = typeof REPORT_TYPE_SUGGESTION;
export const REPORT_TYPE_TEMP = 3;
export type REPORT_TYPE_TEMP = typeof REPORT_TYPE_TEMP;
export const REPORT_TYPE_DASHBOARD = 4;
export type REPORT_TYPE_DASHBOARD = typeof REPORT_TYPE_DASHBOARD;
export const REPORT_TYPE_SHARED = 5;
export type REPORT_TYPE_SHARED = typeof REPORT_TYPE_SHARED;
export type REPORT_TYPE =
    REPORT_TYPE_USER |
    REPORT_TYPE_SUGGESTION |
    REPORT_TYPE_TEMP |
    REPORT_TYPE_DASHBOARD |
    REPORT_TYPE_SHARED;

export const LOAD_SUGGESTIONS = 'LOAD_SUGGESTIONS';
export type LOAD_SUGGESTIONS = typeof LOAD_SUGGESTIONS;
export const STORE_SUGGESTIONS = 'STORE_SUGGESTIONS';
export type STORE_SUGGESTIONS = typeof STORE_SUGGESTIONS;
export const TOGGLE_SUGGESTIONS = 'TOGGLE_SUGGESTIONS';
export type TOGGLE_SUGGESTIONS = typeof TOGGLE_SUGGESTIONS;

export const CONFIG_STATUS_PUBLISHED = 1;
export type CONFIG_STATUS_PUBLISHED = typeof CONFIG_STATUS_PUBLISHED;
export const CONFIG_STATUS_DRAFT = 2;
export type CONFIG_STATUS_DRAFT = typeof CONFIG_STATUS_DRAFT;
export type CONFIG_STATUS = CONFIG_STATUS_PUBLISHED | CONFIG_STATUS_DRAFT;

// Events
export const EVENT_REDRAW = 'REDRAW';

// AppMenu
export const UPDATE_APP_MENU_COLLAPSE_STATUS = 'UPDATE_APP_MENU_COLLAPSE_STATUS';
export type UPDATE_APP_MENU_COLLAPSE_STATUS = typeof UPDATE_APP_MENU_COLLAPSE_STATUS;
export const SET_CURRENT_SUBMENU_ITEM = 'SET_CURRENT_SUBMENU_ITEM';
export type SET_CURRENT_SUBMENU_ITEM = typeof SET_CURRENT_SUBMENU_ITEM;
export const APP_MENU_STORAGE_KEY = 'appMenu';
export const APP_MENU_CURRENT_ITEM_STORAGE_KEY = 'appMenuCurrentItem';

// HTTP status codes
export const HTTP_UNAUTHORIZED_STATUS_CODE = 401;

// Drilldown Modal
export const SHOW_DRILLDOWN_MODAL = 'SHOW_DRILLDOWN_MODAL';
export type SHOW_DRILLDOWN_MODAL = typeof SHOW_DRILLDOWN_MODAL;
export const CLOSE_DRILLDOWN_MODAL = 'CLOSE_DRILLDOWN_MODAL';
export type CLOSE_DRILLDOWN_MODAL = typeof CLOSE_DRILLDOWN_MODAL;

// Data sorting
export const SORTING_ORDER_ASC = 'asc';
export const SORTING_ORDER_DESC = 'desc';
export type SORTING_ORDER_ASC_TYPE = typeof SORTING_ORDER_ASC;
export type SORTING_ORDER_DESC_TYPE = typeof SORTING_ORDER_DESC;
export type SORTING_ORDER_TYPE = SORTING_ORDER_ASC_TYPE | SORTING_ORDER_DESC_TYPE ;

// Suggested Report
export const SUGGESTED_REPORT_CLICKED = 'suggestedReportClicked';
export const DATE_DIMENSION_TYPES = [
    DIMENSION_TYPE_DATETIME,
    DIMENSION_TYPE_DATEINTERVAL,
    DIMENSION_TYPE_DAY_NUMBER,
    DIMENSION_TYPE_MONTH_NUMBER
];
