import * as React from 'react';
import { CheckButton, FormItem } from 'elmo-elements';

import { OCCURRENCE_FORTNIGHTLY, OCCURRENCE_WEEKLY, OccurrenceOption } from '../../../constants/addSchedule';

import './style.css';

type Props = {
  occurrence: OccurrenceOption
  selected: boolean[];
  onToggle: (option: number) => () => void;
  errors: any;
};

const options = [
  'M', 'T', 'W', 'T', 'F', 'S', 'S'
];

function DaysOfWeekSelect({ selected, onToggle, occurrence, errors }: Props) {
  const isVisible = (occurrence === OCCURRENCE_WEEKLY) || (occurrence === OCCURRENCE_FORTNIGHTLY);

  if (!isVisible) {
    return null;
  }

  const buttons = options.map((option: string, index: number) => {
    return (
      <CheckButton
        isChecked={selected[index]}
        key={index}
        onStatusChange={onToggle(index)}
      >
        {option}
      </CheckButton>
    );
  });

  return (
    <FormItem
      label="Day(s) of the week:"
      message={!!errors['daysOfTheWeek'] ? errors['daysOfTheWeek'] : undefined}
      status={!!errors['daysOfTheWeek'] ? 'error' : undefined}
      data-testid="schedule-days-of-week"
    >
      <div className="d-flex">
        {buttons}
      </div>
    </FormItem>
  );
}

export default DaysOfWeekSelect;
