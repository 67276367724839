import * as React from 'react';
import { ConfigData } from '../../../types/config/configDataModel';

export interface ConfigDetailsProps {
  config: ConfigData;
}

class ConfigDetails extends React.Component<ConfigDetailsProps> {

  constructor(props: ConfigDetailsProps) {
    super(props);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Details</h4>
          <div className="card-text">
            <div>Client Name: {this.props.config.clientName ? this.props.config.clientName : 'All Clients'}</div>
            <div>Module: {this.props.config.module}</div>
            <div>Status: {this.props.config.getStatusString()}</div>
            <div>Created By: {this.props.config.user ? this.props.config.user.id : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigDetails;