import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { AppState } from '../../redux';
import { connect } from 'react-redux';
import { AuthService } from '../../services/authService';
import { Loader } from 'elmo-elements';

interface PrivateRouteProps extends RouteProps {
  authService: AuthService;
}

interface PrivateRouteState {
  isLoggedIn: boolean;
}

const mapStateToProps = (state: AppState) => ({
  authService: state.authService
});

class PrivateRoute extends React.Component<PrivateRouteProps, PrivateRouteState> {
  constructor(props: PrivateRouteProps) {
    super(props);

    this.state = {
      isLoggedIn: this.props.authService ? this.props.authService.isTokenValid() : false
    };
  }

  /**
   * When a private route is accessed, potentially XHR to check
   * if the user is logged in to the backend
   */
  async componentDidMount() {
    let loggedIn = await this.props.authService.isLoggedIn();

    this.setState({
      isLoggedIn: loggedIn
    });

  }

  renderRoute = (props: any, Component: any) => () => {
    return this.state.isLoggedIn ?
      (<Component {...props} match={props.computedMatch} />) :
      (<Loader type="spinner" />);
  }

  render() {
    let {component: Component, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={this.renderRoute(this.props, Component)}
      />
    );
  }
}

export default connect(
  mapStateToProps)(PrivateRoute);
