import { FETCHED_DOWNLOADS, UPDATE_DOWNLOAD } from '../constants';
import { DownloadsAction, FetchedDownloadsAction } from '../actions/download';
import { Download } from '../types/downloadModel';

export function downloadsReducer(state: Download[] = [], action: FetchedDownloadsAction | DownloadsAction): Download[] {
  switch (action.type) {
    case FETCHED_DOWNLOADS:
      return action.downloads;
    case UPDATE_DOWNLOAD: {
      return state.map( (item: Download) => {
        if (item.id !== action.download.id) {
          return item;
        }
        
        return action.download;
      });
    }
    default:
      return state;
  }
}
