import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Divider } from '@eds/divider';
import { Field } from '@eds/field';
import { Flex } from '@eds/flex';
import { OptionProps } from '@eds/select-dropdown';
import { useDebounce } from '@eds/utils';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

import { DEBOUNCE_TIMEOUT_MILLISECONDS } from 'src/features/common/values';
import { MenuItemCard, SelectInput, SelectedItemCard } from 'src/features/ui';

import { ScheduledReportFieldValues } from './scheduled-reports-modal.validation';
import { ScheduledReportsUserOption, useScheduledReportsUsersQuery } from './scheduled-reports-modal.queries';

const CustomItem = ({ data }: OptionProps<ScheduledReportsUserOption>) => (
  <MenuItemCard
    avatar={data.avatar}
    avatarName={data.label}
    email={data.email}
    key={`menu-item-card_${data.id}`}
    name={data.label}
    role={data.role}
  />
);

type DestinationUserProps = {
  errors: FieldErrors<ScheduledReportFieldValues>;
} & Pick<UseFormReturn<ScheduledReportFieldValues>, 'control' | 'getValues' | 'setValue' | 'watch'>;

export const DestinationUser = ({ control, errors, getValues, setValue, watch }: DestinationUserProps) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, DEBOUNCE_TIMEOUT_MILLISECONDS);

  const { data: users = [], isLoading } = useScheduledReportsUsersQuery(!!watch('id'), {
    text: inputValue ? debouncedInputValue || undefined : undefined,
  });

  const { data: selectedUsers = [] } = useScheduledReportsUsersQuery(!!watch('id'), {
    ids: watch('targetUsers')?.length ? getValues('targetUsers')!.map(targetUser => targetUser.value) : undefined,
  });

  useEffect(() => {
    if (!selectedUsers.length) return;

    const reconciledValues = (getValues('targetUsers') || []).map(user => {
      if ((user as ScheduledReportsUserOption).id) return user;

      const selectedUser = selectedUsers.find(({ id }) => id === user.value);
      return selectedUser || user;
    });

    setValue('targetUsers', reconciledValues);
  }, [selectedUsers]);

  return (
    <Flex flexDirection="column">
      <Field invalid={!!errors.targetUsers} label="Select recipients" message={errors.targetUsers?.message} required>
        <SelectInput<ScheduledReportsUserOption, ScheduledReportFieldValues>
          control={control}
          filterOption={() => !isLoading}
          inputValue={inputValue}
          isLoading={isLoading}
          isMulti
          itemOverride={CustomItem}
          items={users}
          limit={1}
          menuPlacement="top"
          name="targetUsers"
          onInputChange={inputValue => setInputValue(inputValue)}
          placeholder="Search by name or email..."
        />
      </Field>
      {!!watch('targetUsers')?.length && (
        <Field label="Recipients" keepSpaceForMessage={false}>
          <Box borderColor="neutralSubtle" borderRadius="large" borderStyle="solid" borderWidth="thin" padding="medium">
            {watch('targetUsers')?.map((targetUser, index) => {
              const { avatar, email, id, label, role, value } = targetUser as ScheduledReportsUserOption;
              return (
                <Fragment key={`selected-item_${value}`}>
                  {index > 0 && (
                    <Box paddingY="small">
                      <Divider />
                    </Box>
                  )}
                  <SelectedItemCard
                    avatar={avatar}
                    avatarName={id ? label : ''}
                    email={id ? email : ''}
                    name={id ? label : ''}
                    onClick={() => {
                      setValue(
                        'targetUsers',
                        (getValues('targetUsers') || []).filter(user => user.value !== id)
                      );
                    }}
                    role={id ? role : ''}
                  />
                </Fragment>
              );
            })}
          </Box>
        </Field>
      )}
    </Flex>
  );
};
