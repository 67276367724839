import { DateTime } from 'luxon';

import {
  OUTBOUND_FILENAME_DASH_TOKEN_ID,
  OUTBOUND_FILENAME_EXCLAMATION_TOKEN_ID,
  OUTBOUND_FILENAME_SPACE_TOKEN_ID,
  OUTBOUND_FILENAME_UNDERSCORE_TOKEN_ID,
} from 'src/features/scheduled-reports';

import { ScheduledReportsFilenameToken } from './scheduled-reports-modal.queries';

/**
 * useCustomFilenameString - Generates a custom filename string by replacing tokens with their corresponding symbol
 * from a list of ScheduledReportsFilenameToken objects.
 *
 * @param {Array<string>} customFilename - An array of strings representing the custom filename template. Contains
 *                                         token id or static text
 * @param {Array<ScheduledReportsFilenameToken>} filenameTokens - An array of token data fetched from the backend.
 *                                                                If empty, the custom filename may not be presentable
 *                                                                but the original string is still returned to prevent
 *                                                                blocking.
 * @returns {string} - The final custom filename string with tokens replaced. If a token is not found, the original
 *                     string is retained.
 */
export const useCustomFilenameString = (
  customFilename: Array<string>,
  filenameTokens: Array<ScheduledReportsFilenameToken>
) =>
  customFilename
    .map(
      tokenIdOrStaticText => filenameTokens.find(({ id }) => id === tokenIdOrStaticText)?.token || tokenIdOrStaticText
    )
    .join('');

/**
 * useSampleFilename - Generates a sample filename using a custom template by replacing tokens with their corresponding
 * values derived from a list of ScheduledReportsFilenameToken objects.
 *
 * @param {Array<string>} customFilename - An array of strings representing the custom filename template. Contains
 *                                         token id or static text
 * @param {Array<ScheduledReportsFilenameToken>} filenameTokens - An array of token data fetched from the backend.
 * @returns {string} - The sample filename string with tokens replaced. Additional handling for space token is applied.
 *                     If a token is not found, the original string is retained. If the filenameTokens array is empty,
 *                     an empty string is returned.
 */
export const useSampleFilename = (
  customFilename: Array<string>,
  filenameTokens: Array<ScheduledReportsFilenameToken>
) =>
  filenameTokens.length
    ? customFilename
        .map(tokenIdOrStaticText => {
          const matchingToken = filenameTokens.find(({ id }) => id === tokenIdOrStaticText);

          if (!matchingToken) return tokenIdOrStaticText;

          if (matchingToken.type === 'Separator')
            return matchingToken.id === OUTBOUND_FILENAME_SPACE_TOKEN_ID ? ' ' : matchingToken.token;

          return DateTime.fromJSDate(new Date()).toFormat(matchingToken.token);
        })
        .join('')
    : '';

export const useSortedFilenameTokens = (filenameTokens: Array<ScheduledReportsFilenameToken>) => {
  const dates = filenameTokens.filter(({ type }) => type === 'Date');
  const separators = filenameTokens.filter(({ type }) => type === 'Separator');
  const times = filenameTokens.filter(({ type }) => type === 'Time');
  return { dates, separators, times };
};

export const useTokenizedSeparatorsWithStaticText = (input: string) => {
  return (
    input
      // For this operation, we will use the special symbol `@` as placeholder for space delimiter
      // Replace dashes with the token id and ensure there is 1 @ on either side of the token
      .replace(/-/g, `@${OUTBOUND_FILENAME_DASH_TOKEN_ID}@`)
      // Replace exclamation symbol with the token id and ensure there is 1 @ on either side of the token
      .replace(/!/g, `@${OUTBOUND_FILENAME_EXCLAMATION_TOKEN_ID}@`)
      // Replace spaces with the token id and ensure there is 1 @ on either side of the token
      .replace(/\s/g, `@${OUTBOUND_FILENAME_SPACE_TOKEN_ID}@`)
      // Replace underscores with the token id and ensure there is 1 @ on either side of the token
      .replace(/_/g, `@${OUTBOUND_FILENAME_UNDERSCORE_TOKEN_ID}@`)
      // Reduce consecutive @'s into a single space
      .replace(/@+/g, ' ')
      // Remove any trailing or leading spaces that may have been introduced in the previous operation
      .trim()
      .split(' ')
  );
};
