import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { ControlsProps } from '@eds/modal';
import { Divider } from '@eds/divider';
import { Field } from '@eds/field';
import { Fieldset } from '@eds/fieldset';
import { Flex } from '@eds/flex';
import { OptionProps } from '@eds/select-dropdown';
import { Text } from '@eds/text';
import { Toast, toastMessage } from '@eds/toast';
import { useDebounce } from '@eds/utils';

import { ContentModal, MenuItemCard, SelectInput, SelectedItemCard } from 'src/features/ui';
import { DEBOUNCE_TIMEOUT_MILLISECONDS } from 'src/features/common/values';

import { ShareDashboardFieldValues } from './share-dashboard.validation';
import {
  ShareDashboardUserOption,
  useShareDashboardDetailsQuery,
  useShareDashboardMutation,
  useShareDashboardUsersQuery,
} from './share-dashboard.queries';
import { useFormValuesInitializer, useShareDashboardForm } from './share-dashboard.hooks';

type ShareDashboardProps = {
  id: string;
  name: string;
  onClose: () => void;
};

const description1 = 'The data displayed in the dashboard will be accessible by all selected users.';
const description2 = `To unshare, remove users from the selection and click 'Save'`;

const CustomItem = ({ data }: OptionProps<ShareDashboardUserOption>) => (
  <MenuItemCard
    avatar={data.avatar}
    avatarName={data.label}
    email={data.email}
    key={`menu-item-card_${data.id}`}
    name={data.label}
    role={data.role}
  />
);

export const ShareDashboard = ({ id: dashboardId, name, onClose }: ShareDashboardProps) => {
  const { control, getValues, handleSubmit, reset, setValue, watch } = useShareDashboardForm();

  const [errorMessage, setErrorMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { mutate, isLoading: isSharing } = useShareDashboardMutation({
    onError: () =>
      setErrorMessage(toastMessage('An issue occurred during the dashboard sharing, please try again later')),
    onSettled: onClose,
    onSuccess: () => setSuccessMessage(toastMessage('Sharing updated')),
  });

  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, DEBOUNCE_TIMEOUT_MILLISECONDS);

  const { data = { sharedWith: [] }, isLoading: isSharedWithLoading } = useShareDashboardDetailsQuery(dashboardId);

  const { data: users = [], isLoading } = useShareDashboardUsersQuery(!!dashboardId, {
    text: inputValue ? debouncedInputValue || undefined : undefined,
  });

  const { data: selectedUsers = [] } = useShareDashboardUsersQuery(!!dashboardId, {
    ids: watch('targetUsers').length > 0 ? getValues('targetUsers').map(({ value }) => value) : undefined,
  });

  const onSubmit = ({ id, targetUsers }: ShareDashboardFieldValues) =>
    mutate({ id, targetUsers: targetUsers.map(({ value }) => value) });

  const controls: ControlsProps = {
    cancel: { label: 'Cancel', onClick: onClose, tone: 'ghost' },
    confirm: {
      label: 'Save',
      loading: isSharedWithLoading || isSharing,
      onClick: handleSubmit(onSubmit),
      tone: 'primary',
    },
  };

  useEffect(() => {
    if (selectedUsers.length === 0) return;

    const reconciledValues = getValues('targetUsers').map(user => {
      if ((user as ShareDashboardUserOption).id) return user;

      const selectedUser = selectedUsers.find(({ id }) => id === user.value);
      return selectedUser || user;
    });

    setValue('targetUsers', reconciledValues);
  }, [selectedUsers]);

  useFormValuesInitializer(reset, dashboardId, data.sharedWith);

  return (
    <Box>
      <ContentModal controls={controls} header="Share dashboard" isOpen={!!dashboardId} onClose={onClose}>
        <Flex flexDirection="column" gap="large">
          <Box backgroundColor="neutralSubtle" borderRadius="large" padding="medium">
            <Flex flexDirection="column" gap="xxsmall">
              <Text color="neutralSubtle" fontSize="small" fontWeight="medium">
                Dashboard
              </Text>
              <Text>{name}</Text>
            </Flex>
          </Box>
          <Divider />
          <Fieldset
            description={`${description1} ${description2}`}
            legend="Set-up sharing"
            legendStyle="group"
            removeSpaceForLastFieldMessage
          >
            <Field label="Select users">
              <SelectInput<ShareDashboardUserOption, ShareDashboardFieldValues>
                control={control}
                filterOption={() => !isLoading}
                inputValue={inputValue}
                isLoading={isLoading}
                isMulti
                itemOverride={CustomItem}
                items={users}
                limit={1}
                menuPlacement="top"
                name="targetUsers"
                onInputChange={value => setInputValue(value)}
                placeholder="Search by name or email..."
              />
            </Field>
            {watch('targetUsers').length > 0 && (
              <Field label="Shared with">
                <Box
                  borderColor="neutralSubtle"
                  borderRadius="large"
                  borderStyle="solid"
                  borderWidth="thin"
                  padding="medium"
                >
                  {watch('targetUsers').map((targetUser, index) => {
                    const { avatar, email, id, label, role, value } = targetUser as ShareDashboardUserOption;
                    return (
                      <Fragment key={`selected-item_${value}`}>
                        {index > 0 && (
                          <Box paddingY="small">
                            <Divider />
                          </Box>
                        )}
                        <SelectedItemCard
                          avatar={avatar}
                          avatarName={id ? label : ''}
                          email={id ? email : ''}
                          name={id ? label : ''}
                          onClick={() => {
                            setValue(
                              'targetUsers',
                              getValues('targetUsers').filter(user => user.value !== value)
                            );
                          }}
                          role={id ? role : ''}
                        />
                      </Fragment>
                    );
                  })}
                </Box>
              </Field>
            )}
          </Fieldset>
        </Flex>
      </ContentModal>
      {errorMessage && <Toast message={errorMessage} tone="critical" />}
      {successMessage && <Toast message={successMessage} tone="positive" />}
    </Box>
  );
};
