
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
  DragStart,
  DragUpdate,
} from 'react-beautiful-dnd';

import { DATA_TYPE_DIMENSIONS, MODULE_TYPES } from '../../../constants';
import useMap from '../../../components/hooks/useMap';
import { AppState } from '../../../redux';
import { ColumnMeta } from '../../../types/columnMetaModel';
import { CustomColumn, CustomColumnType } from '../../../types/customColumnModel';

import { getColumnId, isCustomColumn, itemIdPrefix } from './hooks';
import CustomColumnCard from './CustomColumnCard';
import PredefinedColumnCard from './PredefinedColumnCard';

interface DraggingPlaceholderPropsType {
  top: number;
  width: number;
  height: number;
}

interface Props {
  managedColumnMeta: Array<ColumnMeta>;
  consolidatedColumns: Array<ColumnMeta | CustomColumn>;
  setConsolidatedColumns: React.Dispatch<React.SetStateAction<Props['consolidatedColumns']>>;
  setIsChangedTrue: () => void;
  renamedCustomColumns: Pick<Map<string, string>, 'get'>;
  setRenamedCustomColumns: (key: string, value: string) => void;
}

const ColumnCardContainer = ({ renamedCustomColumns, setRenamedCustomColumns, ...props }: Props) => {
  const { useEffect, useState } = React;

  const { managedColumnMeta, consolidatedColumns, setConsolidatedColumns, setIsChangedTrue } = props;
  const { module: selectedModule, dataType, customColumns } = useSelector((state: AppState) => state);

  const [draggingPlaceholderProps, setDraggingPlaceholderProps] = useState<DraggingPlaceholderPropsType | null>(null);

  const [popoverToggle, setPopoverToggle] = useState<Record<string, boolean>>({});
  const [shouldClosePopover, setShouldClosePopover] = useState(false);

  const initialValues = customColumns.map(({ id, value }) => [id, value]) as Array<[string, string | null]>;
  const [formValues, { set, setAll }] = useMap(initialValues);

  const currentCustomColumns = consolidatedColumns.filter(isCustomColumn);

  const listParentElementQuery = 'data-react-beautiful-dnd-droppable';

  const getDraggedDom = (draggableId: string) => {
    const elementId = `${itemIdPrefix}${draggableId}`;
    const draggedDOM = document.getElementById(elementId);

    return draggedDOM;
  };

  const updateDraggingElementPlaceholderStyle = (
    draggableId: string,
    sourceIndex: number,
    destinationIndex?: number
  ) => {
    const draggedElement = getDraggedDom(draggableId);

    if (!draggedElement) {
      return;
    }

    const { clientHeight, clientWidth } = draggedElement;
    const parentElement = document.querySelector(`[${listParentElementQuery}]`) as HTMLElement;
    const childrenArray = Array.from(parentElement.children);
    let updatedArray = childrenArray;

    if (destinationIndex) {
      const movedItem = childrenArray[sourceIndex];
      updatedArray.splice(sourceIndex, 1);
      updatedArray.splice(destinationIndex, 0, movedItem);
    }

    const clientY =
      updatedArray
        .slice(0, destinationIndex === undefined ? sourceIndex : destinationIndex)
        .reduce(
          (total, curr) => {
            const style = getComputedStyle(curr);
            const marginBottom = parseFloat(String(style.marginBottom));
            const elementHeight = parseFloat(String(style.height));

            return total + elementHeight + marginBottom;
          },
          0
        );

    setDraggingPlaceholderProps({
      height: clientHeight,
      width: clientWidth,
      top: clientY,
    });
  };

  const onDragStart = (event: DragStart) => {
    updateDraggingElementPlaceholderStyle(event.draggableId, event.source.index);
  };

  const onDragEnd = (result: DropResult) => {
    setDraggingPlaceholderProps(null);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedColumns = [...consolidatedColumns];
    const [draggedItem] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, draggedItem);

    setConsolidatedColumns(reorderedColumns);
    setIsChangedTrue();
  };

  const onDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    updateDraggingElementPlaceholderStyle(event.draggableId, event.source.index, event.destination.index);
  };

  const renderDraggableItem = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    column: ColumnMeta | CustomColumn,
    index: number
  ) => {
    // Placement dragging item into portal is needed because of react-beautiful-dnd sets fixed positioning
    // for every item in the list. This behaviour causes wrong coordinates calculation for a dragging item,
    // when we place draggable list inside some positioned element. (e.g. Modal window here)
    // For more details: https://github.com/atlassian/react-beautiful-dnd/issues/485

    const usePortal: boolean = snapshot.isDragging;
    const renameEnabled = selectedModule.type === MODULE_TYPES.redshift && dataType === DATA_TYPE_DIMENSIONS;

    let child;
    
    if (isCustomColumn(column)) {
      const { id } = column;

      child = (
        <CustomColumnCard 
          provided={provided}
          customColumn={column}
          renameEnabled={renameEnabled}
          index={index}
          managedColumnMeta={managedColumnMeta}
          formValue={formValues.get(id)}
          setFormValue={set}
          isPopoverOpen={popoverToggle[id]}
          openPopover={() => setPopoverToggle({ [id]: true })}
          consolidatedColumns={consolidatedColumns}
          setConsolidatedColumns={setConsolidatedColumns}
          closePopover={() => setShouldClosePopover(true)}
          renamedTitle={renamedCustomColumns.get(id)}
          setRenamedCustomColumns={setRenamedCustomColumns}
          setIsChangedTrue={setIsChangedTrue}
        />
      );
    } else {
      child = (
        <PredefinedColumnCard
          provided={provided}
          columnMeta={column}
          renameEnabled={renameEnabled}
          index={index}
          consolidatedColumns={consolidatedColumns}
          setConsolidatedColumns={setConsolidatedColumns}
          setIsChangedTrue={setIsChangedTrue}
        />
      );
    }

    // if dragging - put the item in a portal
    return usePortal ? createPortal(child, document.body) : child;
  };

  useEffect(
    () => {
      if (shouldClosePopover) {
        setConsolidatedColumns(
          consolidatedColumns.filter(column => {
            if (isCustomColumn(column)) {
              return column.type === CustomColumnType.Empty || column.value;
            }
            
            return true;
          })
        );

        setPopoverToggle({});
        setShouldClosePopover(false);
      }
    },
    [shouldClosePopover]
  );

  useEffect(
    () => {
      setAll(
        currentCustomColumns.map(({ id, type, value }) => {
          if (type !== CustomColumnType.Empty && !value) {
            setPopoverToggle({ [id]: true });
          }

          return [id, value];
        }) as Array<[string, string | null]>
      );
    },
    [consolidatedColumns]
  );

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable
        droppableId="droppable"
        direction="vertical"
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            role="Columns list"
          >
            {
              consolidatedColumns.map((column, index: number) => {
                let id;

                if (isCustomColumn(column)) {
                  id = column.id;
                } else {
                  id = getColumnId(column);
                }
                
                return (
                  <Draggable
                    key={id}
                    draggableId={id}
                    index={index}
                  >
                    {(providedDraggable, snapshotDraggable) => {
                      return renderDraggableItem(providedDraggable, snapshotDraggable, column, index);
                    }
                    }
                  </Draggable>
                );
              })
            }
            {provided.placeholder}
            {
              draggingPlaceholderProps && snapshot.isDraggingOver && 
              (
                <div
                  className="dragging-element-placeholder"
                  style={{...draggingPlaceholderProps}}
                />
              )
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ColumnCardContainer;
