import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const exportReport = async (queryId: string) => {
  const response = await axios.get(`/api/user/report/${queryId}/export`);
  return response.data;
};

export const useExportReportMutation = (onSuccess: () => void) =>
  useMutation<unknown, unknown, string>({
    mutationFn: id => exportReport(id),
    onSuccess,
  });
