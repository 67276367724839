import * as React from 'react';
import moment from 'moment';
import { Moment } from '../../../constants/moment';
import { DateRange as ElmoDateRange } from 'elmo-elements';
import './style.css';
import { DATETIME_FORMAT } from '../../../constants';

type DateRangeProps = {
  startDate: Moment | null;
  onStartDateChange: (value: Moment | null) => void;
  endDate: Moment | null;
  onEndDateChange: (value: Moment | null) => void;
  isVertical?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
};

class DateRange extends React.Component<DateRangeProps> {
  onChange = (isStartDate: boolean) => (value: Date | null) => {
    const momentDate = value ? moment(value) : null;
    const { onStartDateChange, onEndDateChange } = this.props;

    if (isStartDate) {
      onStartDateChange(momentDate);
    } else {
      onEndDateChange(momentDate);
    }
  }

  render() {
    const {
      isVertical,
      isClearable,
      isDisabled,
      startDate,
      endDate
    } = this.props;

    const start = startDate ? startDate.toDate() : null;
    const end = endDate ? endDate.toDate() : null;

    return (
      <div className="crt-date-range">
        <ElmoDateRange
          isVertical={isVertical}
          isClearable={isClearable}
          isDisabled={isDisabled}
          format={DATETIME_FORMAT}
        >
          <ElmoDateRange.Start onChange={this.onChange(true)} value={start} />
          <ElmoDateRange.End onChange={this.onChange(false)} value={end} />
        </ElmoDateRange>
      </div>
    );
  }
}

export default DateRange;
