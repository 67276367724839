import { Join } from './joinModel';
import { Dimension } from './dimensionModel';
import { Measure } from './measureModel';

export class View {
  name: string;
  label: string;
  alias: string;
  joins: Join[];
  dimensions: Dimension[];
  measures: Measure[];
  groupable: Dimension[];
  isBaseView: boolean;
  isExperimental: boolean;
  description: string;

  constructor() {
    Object.assign(this, {
      name: '',
      label: '',
      alias: '',
      joins: [],
      dimensions: [],
      measures: [],
      groupable: [],
      isBaseView: false,
      isExperimental: false,
      description: '',
    });
  }
}
