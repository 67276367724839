import { Chart } from '../types/chartModel';
import { FETCHED_CHART, SHOULD_GENERATE_CHART } from '../constants';
import { Dispatch } from 'redux';

export interface ChartAction {
  type: FETCHED_CHART | SHOULD_GENERATE_CHART ;
  chart: Chart;
}

export function storeChartAction(chart: Chart): ChartAction {
  return {
    type: FETCHED_CHART,
    chart: chart
  };
}

export function storeChart(chart: Chart): (dispatch: Dispatch<any>) => void {
  return (dispatch) => {
    dispatch(storeChartAction(chart));
  };
}

export function setShouldGenerateChart(generate: boolean) {
  return {
    type: SHOULD_GENERATE_CHART,
    chart: Object.assign(
      new Chart(),
      {
        shouldGenerateChart: generate
      })
  };
}