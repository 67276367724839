import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUnselectedFieldOptions, useViews } from 'src/features/report-builder';

import { AppState } from '../../redux';
import { Filter } from '../../types/filterModel';
import FilterGroupSelectComponent from '../../components/NewReportBuilder/FilterGroupSelect';
import { filterIsValid, getIndexInFilterOptions } from '../../services/filterService';
import { replaceFilterGroups } from '../../actions/filterGroup';
import usePrevious from '../../components/hooks/usePrevious';

interface FilterGroupSelectProps {
  canManageFilter?: boolean;
  isOpen: boolean;
  toggle: () => void;
}

const FilterGroupSelect = ({ canManageFilter = false, isOpen, toggle }: FilterGroupSelectProps) => {
  const { filterOptions, filterGroups, module, savedQuery } = useSelector((state: AppState) => state);
  const { availableViews, currentView: view } = useViews();
  const dispatch = useDispatch();

  const [displayFilterGroups, setDisplayFilterGroups] = useState<Array<Array<Filter>>>([]);

  const prevSavedQuery = usePrevious(savedQuery);
  const prevModuleName = usePrevious(module.name);
  const prevViewName = usePrevious(view.name);
  const prevFilterOptions = usePrevious(filterOptions) || [];

  const clearDisplayFilters = () => setDisplayFilterGroups([]);

  const options = useUnselectedFieldOptions(view, availableViews);

  useEffect(
    () => {
      // when a query is loaded, load the display filters from it
      if (!prevSavedQuery && savedQuery) {
        setDisplayFilterGroups(filterGroups);
        return;
      }

      // if the selected module or view has changed, then clear all the display filters
      const moduleChanged = prevModuleName !== module.name;
      const viewChanged = prevViewName !== view.name;
      
      if ((moduleChanged || viewChanged) && !savedQuery) {
        clearDisplayFilters();
      }

      // if the filter options change, find the removed filter options
      if (prevFilterOptions !== filterOptions && prevFilterOptions.length > filterOptions.length) {
        const newDisplayFilterGroups: Array<Array<Filter>> = [];
        
        displayFilterGroups.forEach(displayFilters => {
          newDisplayFilterGroups.push(
            displayFilters.filter(filter => {
              const dimensionIdentifier = `${filter.joinName ? `${filter.joinName}.` : `${filter.viewName}.`}${
                filter.measureName || filter.dimensionName
              }`;

              return (
                getIndexInFilterOptions(filterOptions, filter) !== -1 ||
                options.some(({ value }) => dimensionIdentifier === value)
              );
            })
          );
        });

        setDisplayFilterGroups(newDisplayFilterGroups.filter(({ length }) => length));
        dispatch(
          replaceFilterGroups(
            newDisplayFilterGroups
              .map(filters => filters.filter(filterIsValid))
              .filter(({ length }) => length)
          )
        );
      }
    },
    [savedQuery, module.name, view.name, filterOptions]
  );

  const saveDisplayFilter = (groupIndex: number) => (filter: Filter, index: number, store: boolean = true) => {
    const newDisplayFilterGroups = [...displayFilterGroups.map(filters => [...filters])];

    if (newDisplayFilterGroups[groupIndex]) {
      newDisplayFilterGroups[groupIndex][index] = filter;
    } else {
      newDisplayFilterGroups[groupIndex] = [filter];
    }

    setDisplayFilterGroups(newDisplayFilterGroups);

    if (store) {
      dispatch(
        replaceFilterGroups(
          newDisplayFilterGroups
            .map(filters => filters.filter(filterIsValid))
            .filter(({ length }) => length)
        )
      );
    }
  };

  const removeFilter = (groupIndex: number) => (index: number) => {
    const newDisplayFilterGroups = [...displayFilterGroups.map(filters => [...filters])];    
    newDisplayFilterGroups[groupIndex].splice(index, 1);

    setDisplayFilterGroups(newDisplayFilterGroups.filter(({ length }) => length));
    dispatch(
      replaceFilterGroups(
        newDisplayFilterGroups
          .map(filters => filters.filter(filterIsValid))
          .filter(({ length }) => length)
      )
    );
  };

  return (
    <FilterGroupSelectComponent
      canManageFilter={canManageFilter}
      displayFilterGroups={displayFilterGroups}
      isOpen={isOpen}
      removeFilter={removeFilter}
      saveDisplayFilter={saveDisplayFilter}
      toggle={toggle}
    />
  );
};

export default FilterGroupSelect;
