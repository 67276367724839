import {
  ConfigColumnTranslationRecord, ConfigTranslationModel,
  ConfigViewTranslationRecord, ORDER_MAX
} from '../types/config/configTranslationsModel';
import { Map as ImmutableMap, List } from 'immutable';
import { jsObjectToPhpArray } from 'js-object-to-php-array';
import { sortBy, map } from 'lodash';

export function formatTranslationsPhpArray(data: ImmutableMap<string, ConfigViewTranslationRecord>) {
  let result = formatTranslationsJson(data);
  return '<?php \nreturn ' + jsObjectToPhpArray(result) + ';';
}

export function formatTranslationsJson(data: ImmutableMap<string, ConfigViewTranslationRecord>): Object {
  let result: any = {};

  let translationsData = data.toList().toArray();

  translationsData = sortBy(translationsData, (record: ConfigViewTranslationRecord) => {
    return record.get('order', ORDER_MAX);
  });

  translationsData.map((value: ConfigViewTranslationRecord) => {
    let viewName = value.get('viewName', '');
    if (viewName) {
      result[viewName] = formatConfigViewTranslationRecord(value);
    }
  });

  return result;
}

function formatConfigViewTranslationRecord(data: ConfigViewTranslationRecord) {
  let dimensionTranslationsMap: ImmutableMap<string, ConfigColumnTranslationRecord> =
    data.get('dimensionTranslations', ImmutableMap<string, ConfigColumnTranslationRecord>());

  let dimensionTranslationsArray = dimensionTranslationsMap.toList().toArray();
  dimensionTranslationsArray = sortBy(dimensionTranslationsArray, (record: ConfigColumnTranslationRecord) => {
    return record.get('order', ORDER_MAX);
  });

  let dimensionTranslations = {};

  dimensionTranslationsArray.map((columnTrans: ConfigColumnTranslationRecord) => {
    let columnName = columnTrans.get('columnName', '');
    if (!columnName) {
      return;
    }

    dimensionTranslations[columnName] = formatConfigColumnTranslationRecord(columnTrans);
  });

  return {
    dimension: dimensionTranslations
  };
}

function formatConfigColumnTranslationRecord(data: ConfigColumnTranslationRecord) {
  let columnName = data.get('columnName', '');
  let transformerKey = data.get('transformerKey', '');
  let values = data.get('values', List<ConfigTranslationModel>());

  if (!columnName) {
    return;
  }

  let result = {};

  if (transformerKey) {
    result['transformerKey'] = transformerKey;
  }

  if (values.size > 0) {
    result['values'] = map(values.toArray(), (transData: ConfigTranslationModel) => {
      return {
        value: isNaN(transData['value']) ? transData['value'] : Number(transData['value']),
        translated: transData['translated']
      };
    });
  }

  return result;
}