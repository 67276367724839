import React from 'react';
import { createIcon } from '@eds/icon';

export const DelimiterIcon = createIcon(
  <>
    <path d="M13.1353 4H10.9625L10.9625 20H13.1353L13.1353 4Z" />
    <path d="M15 12L19 8L23 12L19 16L15 12Z" />
    <path d="M5 8L1 12L5 16L9 12L5 8Z" />
  </>,
  'DelimiterIcon'
);
