import { SET_TRANSLATIONS } from '../constants/translations';

export type TranslationsAction = {
  type: SET_TRANSLATIONS;
  translations: any;
};

export function setTranslations(translations: any) {
  return {
    type: SET_TRANSLATIONS,
    translations: translations
  };
}