import React, { useEffect, useState } from 'react';
import { Box } from '@eds/box';
import { Toast, toastMessage } from '@eds/toast';

import { ReportingRoutes } from 'src/constants/routes';

import { useExportReportMutation } from './export-report.queries';

type ExportReportProps = {
  id: string;
  onSettled: () => void;
};

export const ExportReport = ({ id, onSettled }: ExportReportProps) => {
  const [inProgressMessage, setInProgressMessage] = useState<symbol>();
  const [successMessage, setSuccessMessage] = useState<symbol>();

  const { mutate } = useExportReportMutation(() => {
    setSuccessMessage(toastMessage('Success'));
  });

  useEffect(() => {
    if (id) {
      setInProgressMessage(toastMessage('Processing report export, it may take a moment'));
      mutate(id);
      onSettled();
    }
  }, [id]);

  return (
    <Box>
      {inProgressMessage && <Toast message={inProgressMessage} tone="positive" />}
      {successMessage && (
        <Toast
          message={successMessage}
          tone="positive"
          linkText="Go to downloads"
          linkHref={ReportingRoutes.reportDownloads}
        />
      )}
    </Box>
  );
};
