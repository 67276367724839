import * as React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const DeleteIcon = ({ className, width = 20, height = 20 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 15.8333H13.3337V7.5H6.66699V15.8333ZM4.16699 5V3.33333H7.08366L7.91699 2.5H12.0837L12.917
          3.33333H15.8337V5H4.16699ZM6.66699 17.5C6.20866 17.5 5.81644 17.3369 5.49033 17.0108C5.16366 16.6842
          5.00033 16.2917 5.00033 15.8333V5.83333H15.0003V15.8333C15.0003 16.2917 14.8373 16.6842 14.5112
          17.0108C14.1845 17.3369 13.792 17.5 13.3337 17.5H6.66699Z"
      />
    </svg>
  );
};
