import { InferType, array, boolean, mixed, object, string } from 'yup';

export type ConcatenationConfigFieldValues = InferType<typeof concatenationConfigSchema>;

export type ConcatenationConfigFieldType = 'delimiter' | 'dimension' | 'string';

export const concatenationConfigSchema = object({
  concatenatedValues: array()
    .of(
      object({
        type: string<ConcatenationConfigFieldType>().required(),
        value: mixed()
          .when('type', {
            is: 'delimiter',
            then: object({ label: string().required(), value: string().required() })
              .required('Select delimiter')
              .nullable(),
          })
          .when('type', {
            is: 'dimension',
            then: object({ label: string().required(), value: string().required() })
              .required('Select field')
              .nullable(),
          })
          .when('type', { is: 'string', then: string().matches(/^[A-Za-z0-9]+$/, 'Enter alphanumeric text') }),
      }).required()
    )
    .required('Please add concatenated values'),
  targetField: object({ custom: boolean().required(), label: string().required(), value: string().required() })
    .required('Please select a field')
    .nullable(),
}).required();
