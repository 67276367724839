import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type Module = {
  id: string;
  label: string;
  type: 'redshift' | 'tms';
};

const modulesKeys = {
  all: ['modules'] as const,
  lists: () => [...modulesKeys.all, 'list'] as const,
};

const fetchModules = async () => {
  const response = await axios.get<Module[]>('/api/modules');
  return response.data;
};

export const useModulesListQuery = () =>
  useQuery({
    queryKey: modulesKeys.lists(),
    queryFn: () => fetchModules(),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
