import * as React from 'react';
import './style.css';

type ReportOverlayProps = {
  children: React.ReactNode;
};

function ReportOverlay({children}: ReportOverlayProps) {
  return (
    <div className="overlay">
      {children &&
        <div className="overlay-content">
          {children}
        </div>
      }
    </div>
  );
}

export default ReportOverlay;