import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type DownloadStatusFromAPIResponse = 'ERROR' | 'IN_PROGRESS' | 'QUEUED' | 'SUCCESS';
type DownloadStatus = 'Failed' | 'Pending' | 'Processing' | 'Ready';

type DownloadDataFromAPIResponse = {
  created: string;
  expires: string;
  id: number;
  jobKey: string;
  scheduleName: string | null;
  status: DownloadStatusFromAPIResponse;
  title: string;
  userId: number;
};

export type DownloadData = {
  created: string;
  expires: string;
  id: number;
  jobKey: string;
  report: string;
  scheduleName: string;
  status: DownloadStatus;
  userId: number;
};

const downloadsKeys = {
  all: ['downloads'] as const,
  lists: () => [...downloadsKeys.all, 'list'] as const,
};

const downloadStatusMap: Record<DownloadStatusFromAPIResponse, DownloadStatus> = {
  ERROR: 'Failed',
  IN_PROGRESS: 'Processing',
  QUEUED: 'Pending',
  SUCCESS: 'Ready',
};

export const fetchDownloadsList = async (userId: number) => {
  const response = await axios.get<DownloadDataFromAPIResponse[]>(`/api/users/${userId}/jobs?job-type=DOWNLOAD`);
  return response.data;
};

export const useDownloadsListQuery = (userId: number) =>
  useQuery({
    queryKey: downloadsKeys.lists(),
    queryFn: () => fetchDownloadsList(userId),
    refetchInterval: 15000,
    select: data =>
      data.map(({ scheduleName, status, title, ...downloadData }) => {
        const mappedDownloadData: DownloadData = {
          ...downloadData,
          report: title || '-',
          scheduleName: scheduleName || '-',
          status: downloadStatusMap[status] || 'Pending',
        };

        return mappedDownloadData;
      }),
  });
