import { ADD_MEASURES, REMOVE_MEASURES, SOURCE_REPORT_HEADER, SOURCE_WIZARD } from '../constants';
import { Measure } from '../types/measureModel';
import { View } from '../types/viewModel';

export interface MeasuresAction {
  type: ADD_MEASURES | REMOVE_MEASURES;
  measures: Measure[];
  view: View;
  source: SOURCE_REPORT_HEADER | SOURCE_WIZARD;
}

export function addMeasure(measure: Measure,
                           view: View,
                           source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): MeasuresAction {
  return {
    type: ADD_MEASURES,
    measures: [measure],
    view: view,
    source: source
  };
}

export function removeMeasure(measure: Measure,
                              view: View,
                              source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): MeasuresAction {
  return {
    type: REMOVE_MEASURES,
    measures: [measure],
    view: view,
    source: source
  };
}

export function addMeasures(measures: Measure[],
                            view: View,
                            source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): MeasuresAction {
  return {
    type: ADD_MEASURES,
    measures: measures,
    view: view,
    source: source
  };
}

export function removeMeasures(measures: Measure[],
                               view: View,
                               source: SOURCE_REPORT_HEADER | SOURCE_WIZARD): MeasuresAction {
  return {
    type: REMOVE_MEASURES,
    measures: measures,
    view: view,
    source: source
  };
}