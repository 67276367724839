import * as React from 'react';
import { AppState, ViewJoinAndDimensionOrMeasure } from '../redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FilterSelectComponent from '../components/FilterSelect';
import { Filter } from '../types/filterModel';
import {
  replaceFilters
} from '../actions/filter';
import { Module } from '../types/moduleModel';
import { View } from '../types/viewModel';
import { filterIsValid, getFiltersInFilterOptions } from '../services/filterService';
import { storeOffset } from '../actions/reportOffsetLimit';
import { SavedQuery } from '../types/savedQueryModel';

export interface FilterSelectProps {
  dispatch: Dispatch<any>;
  filters: Filter[];
  filterOptions: ViewJoinAndDimensionOrMeasure[];
  queryIsLoaded?: boolean;
  module: Module;
  view: View;
  canManageFilter?: boolean;
  loadedQuery?: SavedQuery;
}

const mapStateToProps = (state: AppState) => ({
  filters: state.filters,
  filterOptions: state.filterOptions,
  module: state.module,
  view: state.view
});

interface FilterSelectState {
  displayFilters: Filter[];
}

class FilterSelect extends React.Component<FilterSelectProps, FilterSelectState> {

  constructor(props: FilterSelectProps) {
    super(props);

    this.state = {
      displayFilters: []
    };
  }

  componentWillReceiveProps(newProps: FilterSelectProps) {
    // when a query is loaded, load the display filters from it
    if (!this.props.queryIsLoaded && newProps.queryIsLoaded) {
      this.setState({
        displayFilters: newProps.filters
      });

      return;
    }

    // if the selected module or view has changed, then clear all the display filters
    const moduleChanged = this.props.module.name !== newProps.module.name;
    const viewChanged = this.props.view.name !== newProps.view.name;
    const hasSavedQuery = newProps.queryIsLoaded;

    if ((moduleChanged || viewChanged) && !hasSavedQuery) {
      this.clearDisplayFilters();
    }

    // if the filter options change, find the removed filter options
    if (this.props.filterOptions !== newProps.filterOptions) {
      let newDisplayFilters = getFiltersInFilterOptions(newProps.filterOptions, this.state.displayFilters);

      this.setState({
        displayFilters: newDisplayFilters
      });
    }
  }

  clearDisplayFilters() {
    this.setState({
      displayFilters: []
    });
  }

  saveDisplayFilter = (filter: Filter, index: number, store: boolean = true) => {
    let newDisplayFilters: Filter[] = this.state.displayFilters.slice();
    newDisplayFilters[index] = filter;
    let stateCallback = store ? this.saveFilters : undefined;

    this.setState(
      {
        displayFilters: newDisplayFilters },
      stateCallback);
  }

  saveFilters = () => {
    let validFilters: Filter[] = this.state.displayFilters.filter((filter: Filter) => {
      return filterIsValid(filter);
    });
    this.props.dispatch(replaceFilters(validFilters));
  }

  removeFilter = (index: number) => {
    let newDisplayFilters: Filter[] = this.state.displayFilters.slice();
    newDisplayFilters.splice(index, 1);
    this.setState({
      displayFilters: newDisplayFilters
    });

    this.props.dispatch(replaceFilters(newDisplayFilters));
  }

  // This is necessary because filter may change the amount of pages so reset back to page 1
  beforeFilter = () => {
    this.props.dispatch(storeOffset(0));
  }

  render() {
    return (
      <FilterSelectComponent
        filterOptions={this.props.filterOptions}
        saveDisplayFilter={this.saveDisplayFilter}
        displayFilters={this.state.displayFilters}
        removeFilter={this.removeFilter}
        beforeFilter={this.beforeFilter}
        canManageFilter={this.props.canManageFilter}
        loadedQuery={this.props.loadedQuery}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  {forwardRef: true}) // allows the component ref to be accessed
(FilterSelect);
